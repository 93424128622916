import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { faCircle, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import micoach from "micoach-api";

import EllipsisMenu from "Components/Common/EllipsisMenu";
import JobPositionStatusButton from "Components/Job/Position/JobPositionStatusButton";
import { getFormattedLocalDate } from "utils.js";

import styles from "Components/Job/Position/styles/JobPositionsTable.module.css";

const JobPositionsTable = (props) => {
  const {
    jobPositions,
    isSaving,
    onUpdate,
    onClickJobPositionTitle,
    onClickEdit,
  } = props;

  const menuOptions = [{ name: "Edit", icon: faPen, eventKey: "Edit" }];

  const columns = [
    {
      name: <strong>Status</strong>,
      selector: (jobPosition) => jobPosition.status,
      cell: (jobPosition) => (
        <JobPositionStatusButton
          status={jobPosition.status}
          isSaving={isSaving}
          onChange={(status) => onUpdate(jobPosition._id, { status })}
        />
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: <strong>Job Title</strong>,
      selector: (jobPosition) => jobPosition.title,
      sortable: true,
      minWidth: "240px",
      wrap: true,
      cell: (jobPosition) => (
        <Button
          className="Clickable"
          variant="link"
          onClick={() => onClickJobPositionTitle?.(jobPosition._id)}
        >
          {jobPosition.title}
        </Button>
      ),
    },
    {
      name: <strong>Priority</strong>,
      selector: (jobPosition) => jobPosition.priority,
      cell: (jobPosition) =>
        jobPosition.priority && (
          <FontAwesomeIcon icon={faCircle} className={styles.PriorityIcon} />
        ),
      sortable: true,
      wrap: true,
    },
    {
      name: <strong>Company</strong>,
      selector: (jobPosition) => jobPosition.companyName,
      sortable: true,
      minWidth: "240px",
      wrap: true,
    },
    {
      name: <strong>Seniority</strong>,
      selector: (jobPosition) => jobPosition.seniority,
      sortable: true,
      wrap: true,
    },
    {
      name: <strong>Employment</strong>,
      selector: (jobPosition) => jobPosition.employment,
      sortable: true,
      minWidth: "120px",
      wrap: true,
    },
    {
      name: <strong>Work model</strong>,
      selector: (jobPosition) => jobPosition.workModel,
      sortable: true,
      minWidth: "120px",
      wrap: true,
    },
    {
      name: <strong>Opened</strong>,
      selector: (jobPosition) => jobPosition.createdAt,
      cell: (jobPosition) => (
        <>{getFormattedLocalDate(jobPosition.createdAt)}</>
      ),
      sortable: true,
      minWidth: "110px",
      wrap: true,
    },
    {
      name: <strong>Closed</strong>,
      selector: (jobPosition) => jobPosition.closedAt || "",
      cell: (jobPosition) => (
        <>
          {jobPosition.closedAt && getFormattedLocalDate(jobPosition.closedAt)}
        </>
      ),
      sortable: true,
      minWidth: "110px",
      wrap: true,
    },
    {
      name: <strong>Deadline</strong>,
      selector: (jobPosition) => jobPosition.deadline || "",
      cell: (jobPosition) => (
        <>
          {jobPosition.deadline && getFormattedLocalDate(jobPosition.deadline)}
        </>
      ),
      sortable: true,
      minWidth: "110px",
      wrap: true,
    },
    {
      cell: (jobPosition) =>
        jobPosition.status !== micoach.PositionStatusEnum.Closed ? (
          <EllipsisMenu
            options={menuOptions}
            onSelectedOption={() => onClickEdit(jobPosition)}
          />
        ) : null,
      width: "110px",
      wrap: true,
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={jobPositions}
      pagination
      highlightOnHover
    />
  );
};

JobPositionsTable.propTypes = {
  jobPositions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      priority: PropTypes.bool,
      companyName: PropTypes.string,
      seniority: PropTypes.string,
      employment: PropTypes.string,
      workModel: PropTypes.string,
      createdAt: PropTypes.string,
      closeAt: PropTypes.string,
      deadline: PropTypes.string,
    })
  ),
  isSaving: PropTypes.bool,
  onUpdate: PropTypes.func,
  onClickJobPositionTitle: PropTypes.func,
  onClickEdit: PropTypes.func.isRequired,
};

JobPositionsTable.defaultProps = {
  isSaving: false,
};

export default JobPositionsTable;

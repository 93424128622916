import { useCallback, useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { EventApi } from "micoach-api";

export const useEvents = (defaultQueryParams = {}, currentEventId) => {
  const { keycloak } = useKeycloak();

  const [events, setEvents] = useState([]);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [queryParams, setQueryParams] = useState(defaultQueryParams);

  const loadEvents = useCallback(async () => {
    setLoadingEvents(true);

    const { upcoming, current, isTraining, limit, sort, search } = queryParams;

    try {
      let response = [];
      if (keycloak.authenticated) {
        response = await EventApi.listEvents(
          upcoming,
          current,
          isTraining,
          limit,
          sort,
          search ? encodeURIComponent(search) : undefined
        );
      } else {
        response = await EventApi.listPublicEvents(
          upcoming,
          current,
          isTraining,
          limit,
          sort,
          search ? encodeURIComponent(search) : undefined
        );
      }

      if (currentEventId) {
        setEvents(
          response.data.filter((event) => event._id !== currentEventId)
        );
        return;
      }

      setEvents(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEvents(false);
    }
  }, [queryParams, currentEventId, keycloak.authenticated]);

  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  return { events, loadEvents, loadingEvents, setQueryParams };
};

export const useEvent = (eventId) => {
  const { keycloak } = useKeycloak();

  const [event, setEvent] = useState();
  const [loadingEvent, setLoadingEvent] = useState(false);

  const loadEvent = useCallback(async () => {
    setLoadingEvent(true);
    try {
      let response;
      if (keycloak.authenticated) {
        response = await EventApi.getEvent(eventId);
      } else {
        response = await EventApi.getPublicEvent(eventId);
      }
      setEvent(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEvent(false);
    }
  }, [eventId, keycloak.authenticated]);

  useEffect(() => {
    loadEvent();
  }, [loadEvent]);

  return { event, loadEvent, loadingEvent };
};

export const useEventAttendee = (eventId, userId) => {
  const [eventAttendee, setEventAttendee] = useState(null);
  const [isRegistered, setIsRegistered] = useState(false);
  const [loadingEventAttendee, setLoadingAttendee] = useState(false);

  const loadEventAttendee = useCallback(async () => {
    setLoadingAttendee(true);

    try {
      if (eventId && userId) {
        const { data } = await EventApi.getEventAttendee(eventId, userId);

        setEventAttendee(data);
        setIsRegistered(!!data?.email);
      }
    } catch (error) {
      console.error(error);
      setEventAttendee(null);
      setIsRegistered(false);
    } finally {
      setLoadingAttendee(false);
    }
  }, [eventId, userId]);

  return {
    eventAttendee,
    loadingEventAttendee,
    isRegistered,
    loadEventAttendee,
  };
};

import PropTypes from "prop-types";

import FeedbackCard from "Components/Evaluation/Skill/FeedbackCard";

const FeedbackList = (props) => {
  const { feedback } = props;

  return (
    <>
      {feedback.map((feedbackItem) => (
        <FeedbackCard key={feedbackItem.comments} feedback={feedbackItem} />
      ))}
    </>
  );
};

FeedbackList.propTypes = {
  feedback: PropTypes.arrayOf(PropTypes.object),
};

FeedbackList.defaultProps = {
  feedback: [],
};

export default FeedbackList;

import { useContext, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { NotificationSourceEnum } from "@micoach/js-sdk";

import { NotificationsContext } from "Context/NotificationsProvider";
import { useAlert } from "Hooks/useAlert";

const ALERTS_LIMIT = 3;

const AlertContainer = () => {
  const { notifications } = useContext(NotificationsContext);
  const { showAlert, TOAST_CONTAINERS_IDS } = useAlert();

  useEffect(() => {
    for (const notification of notifications) {
      if (
        notification.source !== NotificationSourceEnum.CandidateUploadDocument
      ) {
        showAlert(notification);
      }
    }
  }, [notifications, showAlert]);

  return (
    <ToastContainer
      theme="colored"
      containerId={TOAST_CONTAINERS_IDS.ALERTS}
      position={toast.POSITION.BOTTOM_LEFT}
      limit={ALERTS_LIMIT}
      enableMultiContainer
      autoClose={false}
    />
  );
};

export default AlertContainer;

import Spinner from "react-bootstrap/Spinner";

import styles from "Components/styles/Loading.module.css";

const Loading = () => {
  return (
    <div className={styles.Loading}>
      <Spinner animation="border" role="status" />
    </div>
  );
};

export default Loading;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  EmploymentEnum,
  MeetingStatusEnum,
  WorkModelEnum,
} from "@micoach/js-sdk";
import dayjs from "dayjs";
import { HiringEventApi } from "micoach-api";
import { getZonedDate } from "utils";

import FormControlErrorMessage from "Components/Common/FormControlErrorMessage";
import { URL_HTTP_PATTERN } from "constants.js";

import styles from "Components/Interview/styles/InterviewAddEditModal.module.css";

const InterviewAddEditModal = (props) => {
  const {
    show,
    recruiters,
    interview,
    activeApplicationProcesses,
    hiringEvent,
    onClose,
    onSaved,
  } = props;

  const [isSaving, setIsSaving] = useState(false);

  const isEditing = !!interview?._id;
  const hiringEventIsAfterToday = dayjs(hiringEvent?.date).isAfter(dayjs());

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    clearErrors,
    reset,
  } = useForm({
    mode: "onChange",
  });

  const startDate = watch("startDate");
  const endDate = watch("endDate");

  const handleChangeStartTime = (value) => {
    const startDate = dayjs(value);

    if (!value || startDate > endDate) {
      setValue("endDate", null);
      clearErrors("endDate");
    }

    if (startDate < endDate) {
      clearErrors("endDate");
    }
  };

  const isEndTimeValid = (value) => {
    const formattedStartDate = dayjs(startDate);
    const endDate = dayjs(value);

    const startTime = {
      hours: formattedStartDate.hour(),
      minutes: formattedStartDate.minute(),
    };

    const endTime = {
      hours: endDate.hour(),
      minutes: endDate.minute(),
    };

    return (
      startTime.hours < endTime.hours ||
      (startTime.hours === endTime.hours && startTime.minutes < endTime.minutes)
    );
  };

  const onSubmit = async (formValues) => {
    try {
      setIsSaving(true);

      const hiringEventInterview = {
        hiringEvent: hiringEvent?._id,
        applicationProcess: isEditing
          ? interview.applicationProcess?._id
          : formValues.applicationProcess.value,
        startDate: dayjs(hiringEvent?.date)
          .tz("America/Tijuana")
          .set("hour", formValues.startDate.getHours())
          .set("minute", formValues.startDate.getMinutes())
          .set("seconds", 0),
        endDate: dayjs(hiringEvent?.date)
          .tz("America/Tijuana")
          .set("hour", formValues.endDate.getHours())
          .set("minute", formValues.endDate.getMinutes())
          .set("seconds", 0),
        meetingLink: formValues.meetingLink,
        meetingAccount: formValues.meetingAccount,
        meetingStatus: formValues.meetingStatus.value,
        companyContacts: isEditing
          ? interview?.companyContacts
          : formValues.companyContacts.map(
              (companyContact) => companyContact.value
            ),
        recruiterName: formValues.host.label,
        recruiterId: formValues.host.value,
        workModel: formValues.workModel.value,
        employment: formValues.employment.value,
      };

      let response;

      if (isEditing) {
        response = await HiringEventApi.updateHiringEventInterview(
          hiringEvent?._id,
          interview?._id,
          hiringEventInterview
        );
      } else {
        response = await HiringEventApi.createHiringEventInterview(
          hiringEvent?._id,
          hiringEventInterview
        );
      }

      toast.success(
        `Success! The Interview has been ${isEditing ? "saved" : "added"}.`
      );

      onSaved?.(response.data);
      onClose();
    } catch (error) {
      const status = error.response?.status;
      const errorMessage = error.response?.data?.errors?.[0]?.message;

      if (status === 409 && errorMessage) {
        toast.error(errorMessage);
      } else if (status === 404) {
        toast.error("Interview not found.");
      } else {
        toast.error("Error, please try again.");
      }

      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (show) {
      if (interview && isEditing) {
        setValue("applicationProcess", {
          label: interview.applicationProcess?.candidate?.name,
          value: interview.applicationProcess?._id,
        });

        ["workModel", "employment", "meetingStatus"].forEach((key) => {
          setValue(key, {
            label: interview[key],
            value: interview[key],
          });
        });

        ["meetingLink", "meetingAccount"].forEach((key) => {
          setValue(key, interview[key]);
        });

        ["startDate", "endDate"].forEach((key) => {
          setValue(key, getZonedDate(interview[key]));
        });

        setValue("host", {
          label: interview.recruiterName,
          value: interview.recruiterId,
        });
        setValue(
          "companyContacts",
          hiringEvent.company?.contacts
            ?.filter((contact) =>
              interview.companyContacts.includes(contact._id)
            )
            .map((contact) => ({
              label: contact.name,
              value: contact._id,
            }))
        );
      }
    } else {
      reset();
    }
  }, [show, interview, hiringEvent, reset, isEditing, setValue, clearErrors]);

  return (
    <Modal show={show} onHide={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className={styles.Header}></Modal.Header>
        <Modal.Body className={styles.Body}>
          <div className={styles.Title}>
            <Modal.Title>{isEditing ? "Edit" : "Create"} Interview</Modal.Title>
            <span>
              Select the information to schedule an interview with your client
              and team.
            </span>
          </div>
          {isEditing ? (
            <span className={`${styles.CandidateInfo} fs-20 fw-bold`}>
              {interview.applicationProcess?.candidate?.name} -{" "}
              {interview.applicationProcess?.position?.title}
            </span>
          ) : (
            <Form.Group
              controlId="applicationProcess"
              className="form-group required"
            >
              <Form.Label id="applicationProcess">Candidate</Form.Label>
              <Controller
                name="applicationProcess"
                control={control}
                defaultValue={null}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isClearable
                    hideSelectedOptions
                    isDisabled={isSaving || !hiringEventIsAfterToday}
                    aria-labelledby="applicationProcess"
                    classNamePrefix="ReactSelect"
                    className={errors.applicationProcess ? "is-invalid" : ""}
                    value={value}
                    options={hiringEvent?.applicationProcesses
                      ?.filter(
                        (applicationProcess) =>
                          !activeApplicationProcesses.includes(
                            applicationProcess._id
                          )
                      )
                      .map((applicationProcess) => ({
                        value: applicationProcess?._id,
                        label: applicationProcess?.candidate.name,
                      }))}
                    onChange={onChange}
                  />
                )}
              />
              <FormControlErrorMessage
                show={errors.applicationProcess?.type === "required"}
                message="The candidate is required"
              />
            </Form.Group>
          )}
          <Row>
            <Form.Group
              controlId="startDate"
              className="form-group required"
              as={Col}
              sm={6}
            >
              <Form.Label id="startDate">Start Time</Form.Label>
              <Controller
                name="startDate"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={null}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    showPopperArrow={false}
                    ariaLabelledBy="startDate"
                    placeholderText="Select a date"
                    showTimeSelect
                    showTimeSelectOnly
                    calendarClassName="RemoveDisabledTimes"
                    timeIntervals={15}
                    minTime={new Date().setHours(8, 0)}
                    maxTime={new Date().setHours(22, 0)}
                    timeCaption="Start time"
                    dateFormat="h:mm aa"
                    timeFormat="h:mm aa"
                    className={`form-control ${
                      errors.startDate && "is-invalid"
                    }`}
                    disabled={isSaving || !hiringEventIsAfterToday}
                    selected={value}
                    onChange={(value) => {
                      onChange(value);
                      handleChangeStartTime(value);
                    }}
                  />
                )}
              />
              <FormControlErrorMessage
                show={errors.startDate?.type === "required"}
                message="The start time is required"
              />
            </Form.Group>
            <Form.Group
              controlId="endDate"
              className="form-group required"
              as={Col}
              sm={6}
            >
              <Form.Label id="endDate">End Time</Form.Label>
              <Controller
                name="endDate"
                control={control}
                rules={{
                  required: true,
                  validate: (value) => isEndTimeValid(value),
                }}
                defaultValue={null}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    showPopperArrow={false}
                    ariaLabelledBy="endDate"
                    placeholderText="Select a date"
                    showTimeSelect
                    showTimeSelectOnly
                    calendarClassName="RemoveDisabledTimes"
                    timeIntervals={15}
                    minTime={new Date().setHours(8, 0)}
                    maxTime={new Date().setHours(22, 0)}
                    timeCaption="End time"
                    dateFormat="h:mm aa"
                    timeFormat="h:mm aa"
                    disabled={
                      isSaving || !startDate || !hiringEventIsAfterToday
                    }
                    className={`form-control ${errors.endDate && "is-invalid"}`}
                    selected={value}
                    onChange={onChange}
                  />
                )}
              />
              <FormControlErrorMessage
                show={errors.endDate?.type === "required"}
                message="The end time is required"
              />
              <FormControlErrorMessage
                show={errors.endDate?.type === "validate"}
                message="End time cannot be the same or prior to the start time"
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              controlId="workModel"
              className="form-group required"
              as={Col}
              sm={6}
            >
              <Form.Label id="workModel">Work Model</Form.Label>
              <Controller
                name="workModel"
                control={control}
                defaultValue={null}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isClearable
                    classNamePrefix="ReactSelect"
                    aria-labelledby="workModel"
                    className={errors.workModel ? "is-invalid" : ""}
                    value={value}
                    options={Object.keys(WorkModelEnum).map((workModel) => ({
                      label: WorkModelEnum[workModel],
                      value: WorkModelEnum[workModel],
                    }))}
                    isDisabled={isSaving || !hiringEventIsAfterToday}
                    onChange={onChange}
                  />
                )}
              />
              <FormControlErrorMessage
                show={errors.workModel?.type === "required"}
                message="The work model is required."
              />
            </Form.Group>
            <Form.Group
              controlId="employment"
              className="form-group required"
              as={Col}
              sm={6}
            >
              <Form.Label id="employment">Employment</Form.Label>
              <Controller
                name="employment"
                control={control}
                defaultValue={null}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isClearable
                    classNamePrefix="ReactSelect"
                    aria-labelledby="employment"
                    className={errors.employment ? "is-invalid" : ""}
                    value={value}
                    options={Object.keys(EmploymentEnum).map((employment) => ({
                      label: EmploymentEnum[employment],
                      value: EmploymentEnum[employment],
                    }))}
                    isDisabled={isSaving || !hiringEventIsAfterToday}
                    onChange={onChange}
                  />
                )}
              />
              <FormControlErrorMessage
                show={errors.employment?.type === "required"}
                message="The employment is required."
              />
            </Form.Group>
          </Row>

          <Form.Group controlId="meetingLink" className="form-group required">
            <Form.Label id="meetingLink">Meeting Link</Form.Label>
            <Form.Control
              type="text"
              name="meetingLink"
              {...register("meetingLink", {
                required: true,
                pattern: URL_HTTP_PATTERN,
              })}
              isInvalid={!!errors.meetingLink}
              disabled={isSaving || !hiringEventIsAfterToday}
            />
            <FormControlErrorMessage
              show={errors.meetingLink?.type === "required"}
              message="The Meeting link is required."
            />
            <FormControlErrorMessage
              show={errors.meetingLink?.type === "pattern"}
              message="Meeting Link URL must be a valid URL"
            />
            <FormControlErrorMessage
              show={errors.meetingLink?.type === "maxLength"}
              message="Max length exceeded."
            />
          </Form.Group>
          <Row>
            <Form.Group
              as={Col}
              sm={6}
              controlId="meetingAccount"
              className="form-group required"
            >
              <Form.Label id="meetingAccount">Meeting Account</Form.Label>
              <Form.Control
                type="text"
                name="meetingAccount"
                {...register("meetingAccount", {
                  required: true,
                })}
                isInvalid={!!errors.meetingAccount}
                disabled={isSaving || !hiringEventIsAfterToday}
              />
              <FormControlErrorMessage
                show={errors.meetingAccount?.type === "required"}
                message="Meeting account is required"
              />
            </Form.Group>
            <Form.Group
              as={Col}
              sm={6}
              controlId="meetingStatus"
              className="form-group required"
            >
              <Form.Label id="meetingStatus">Meeting Status</Form.Label>
              <Controller
                name="meetingStatus"
                control={control}
                defaultValue={null}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isClearable
                    classNamePrefix="ReactSelect"
                    aria-labelledby="meetingStatus"
                    className={errors.meetingStatus ? "is-invalid" : ""}
                    value={value}
                    options={Object.keys(MeetingStatusEnum).map(
                      (meetingStatus) => ({
                        label: meetingStatus,
                        value: meetingStatus,
                      })
                    )}
                    isDisabled={isSaving || !hiringEventIsAfterToday}
                    onChange={onChange}
                  />
                )}
              />
              <FormControlErrorMessage
                show={errors.meetingStatus?.type === "required"}
                message="The Meeting status is required."
              />
            </Form.Group>
          </Row>
          {!isEditing && (
            <Form.Group
              controlId="companyContacts"
              className={`${isEditing ? "" : "required"} form-group`}
            >
              <Form.Label id="companyContacts">Client Members</Form.Label>
              <Controller
                name="companyContacts"
                control={control}
                defaultValue={null}
                rules={{
                  required: true,
                  validate: (value) => value.length > 0,
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isClearable
                    isMulti
                    isDisabled={isSaving || !hiringEventIsAfterToday}
                    hideSelectedOptions
                    aria-labelledby="companyContacts"
                    classNamePrefix="ReactSelect"
                    className={errors.companyContacts ? "is-invalid" : ""}
                    value={value}
                    options={hiringEvent?.company?.contacts.map((contact) => ({
                      label: contact.name,
                      value: contact._id,
                    }))}
                    onChange={onChange}
                  />
                )}
              />
              <FormControlErrorMessage
                show={
                  errors.companyContacts?.type === "required" ||
                  errors.companyContacts?.type === "validate"
                }
                message="Company contacts are required."
              />
            </Form.Group>
          )}

          <Form.Group controlId="host" className="form-group required">
            <Form.Label id="host">Host</Form.Label>
            <Controller
              name="host"
              control={control}
              defaultValue={null}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  isClearable
                  isDisabled={isSaving || !hiringEventIsAfterToday}
                  hideSelectedOptions
                  aria-labelledby="host"
                  classNamePrefix="ReactSelect"
                  className={errors.host ? "is-invalid" : ""}
                  value={value}
                  options={recruiters.map((recruiter) => ({
                    label: recruiter.name,
                    value: recruiter._id,
                  }))}
                  onChange={onChange}
                />
              )}
            />
            <FormControlErrorMessage
              show={errors.host?.type === "required"}
              message="The host is required."
            />
          </Form.Group>
          {isEditing && (
            <Alert variant="info">
              If you modify the start time or end time check that your
              panelist&apos;s hours are not out of bound.
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="CancelButton"
            disabled={isSaving}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="SaveButton"
            disabled={isSaving || !hiringEventIsAfterToday}
          >
            {isEditing ? "Save" : "Create"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

InterviewAddEditModal.propTypes = {
  show: PropTypes.bool,
  recruiters: PropTypes.array,
  hiringEvent: PropTypes.object,
  interview: PropTypes.object,
  activeApplicationProcesses: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onSaved: PropTypes.func,
};

InterviewAddEditModal.defaultProps = {
  show: false,
  recruiters: [],
  activeApplicationProcesses: [],
};

export default InterviewAddEditModal;

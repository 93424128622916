import PropTypes from "prop-types";

import InviteFriendCard from "Components/Candidate/Actions/InviteFriendCard";
import TakeTrainingCard from "Components/Candidate/Actions/TakeTrainingCard";
import UploadResumeCard from "Components/Candidate/Actions/UploadResumeCard";

const ActionList = (props) => {
  const { documents } = props;

  return (
    <div className="ActionList">
      <UploadResumeCard documents={documents} />

      <InviteFriendCard />

      <TakeTrainingCard />
    </div>
  );
};

ActionList.propTypes = {
  documents: PropTypes.array,
};

export default ActionList;

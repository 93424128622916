import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import EarnedBadgeList from "Components/Candidate/EarnedBadge/EarnedBadgeList";
import Loading from "Components/Loading";

import styles from "Components/Candidate/EarnedBadge/styles/EarnedBadgesCard.module.css";

const EarnedBadgesCard = (props) => {
  const { achievements, loading, badgesPerRow } = props;

  const [filteredAchievements, setFilteredAchievements] = useState([]);

  const [isViewAll, setIsViewAll] = useState(false);

  const handleClickViewAll = () => {
    let newAchievements = [...achievements];

    // Before setting setIsViewAll, if true, means it is not view all
    if (isViewAll) {
      newAchievements = newAchievements.slice(-badgesPerRow);
    }

    setFilteredAchievements(newAchievements);
    setIsViewAll(!isViewAll);
  };

  useEffect(() => {
    if (achievements.length) {
      setFilteredAchievements([...achievements.slice(-badgesPerRow)]);
    }
  }, [achievements, badgesPerRow]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Card className="Card">
      <Card.Body>
        <div>
          <div className={styles.Title}>
            <h2>Earned badges</h2>
            {achievements.length > badgesPerRow && (
              <Button variant="link" onClick={handleClickViewAll}>
                {isViewAll ? "View less" : "View all"}
              </Button>
            )}
          </div>

          {achievements.length ? (
            <EarnedBadgeList
              achievements={filteredAchievements}
              badgesPerRow={badgesPerRow}
            />
          ) : (
            <div className={styles.WithoutBadges}>
              Participate in our upcoming events and start earning badges in
              miCoach.
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

EarnedBadgesCard.defaultValues = {
  achievements: [],
  badgesPerRow: 4,
};

EarnedBadgesCard.propTypes = {
  achievements: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  badgesPerRow: PropTypes.number,
};

export default EarnedBadgesCard;

import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { ReferralStatusEnum } from "@micoach/js-sdk";

import styles from "Components/Refer/styles/ReferralClaimButton.module.css";

const ReferralClaimButton = (props) => {
  const { referralStatus, onClick, isSaving } = props;

  if (
    referralStatus !== ReferralStatusEnum.Reviewing &&
    referralStatus !== ReferralStatusEnum.ReadyToClaim &&
    referralStatus !== ReferralStatusEnum.PendingPayment
  ) {
    return null;
  }

  return (
    <Button
      variant="primary"
      className={styles.ClaimButton}
      onClick={onClick}
      disabled={isSaving || referralStatus !== ReferralStatusEnum.ReadyToClaim}
    >
      Claim
    </Button>
  );
};

ReferralClaimButton.propTypes = {
  isSaving: PropTypes.bool,
  referralStatus: PropTypes.string,
  onClick: PropTypes.func,
};

ReferralClaimButton.defaultProps = {
  isSaving: false,
};

export default ReferralClaimButton;

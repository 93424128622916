import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";

import ResumeBuilderBanner from "Components/Home/ResumeBuilderBanner";
import Header from "Layouts/Header";
import { assets } from "constants.js";

import "Layouts/styles/Candidate.css";
import "Layouts/styles/Default.css";

export default function candidateLayout(WrappedComponent, userRoles) {
  // eslint-disable-next-line react/display-name
  return () => {
    return (
      <div className="LayoutContainer">
        <ResumeBuilderBanner />
        <Header userRoles={userRoles} isOffCanvas={true} />

        <Container className="CandidateContainer">
          <Row>
            <Col>
              <WrappedComponent />
            </Col>
          </Row>
        </Container>
        <footer className="FooterContainer">
          <Container>
            <Row>
              <Col>
                <Image src={assets.MICOACH_LOGO} alt="miCoach" />
                <span className="text-muted">
                  &copy; All rights reserved. Copyright{" "}
                  {new Date().getFullYear()}. Powered by ITJ{" | "}
                  <a href="/privacy-policy">Privacy Policy</a>
                </span>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    );
  };
}

import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ConfirmModal = (props) => {
  const {
    show,
    isLoading,
    title,
    cancelButtonText,
    confirmButtonText,
    children,
  } = props;

  return (
    <Modal
      show={show}
      onHide={props.onClose}
      backdrop={isLoading ? "static" : true}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={props.onClose}
          disabled={isLoading}
        >
          {cancelButtonText}
        </Button>
        <Button
          id="ConfirmAction"
          variant="primary"
          onClick={props.onConfirm}
          disabled={isLoading}
        >
          {confirmButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  show: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  children: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ConfirmModal.defaultProps = {
  show: false,
  isLoading: false,
  title: "Confirmation",
  children: <span>Are you sure you want to do this?</span>,
  cancelButtonText: "Cancel",
  confirmButtonText: "Yes",
};

export default ConfirmModal;

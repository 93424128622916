import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { useFieldArray } from "react-hook-form";

import InterviewPanelistItem from "Components/Interview/InterviewPanelistItem";

const NEW_PANELIST = {
  email: "",
  startDate: "",
  endDate: "",
};

const InterviewPanelistsList = (props) => {
  const { isSaving, formProps, defaultPanelists, interview, isDisabled } =
    props;

  const [initialSetup, setInitialSetup] = useState(false);

  const {
    fields: panelistsFields,
    append,
    remove,
  } = useFieldArray({
    control: formProps.control,
    name: "panelists",
  });

  const setupPanelists = () => {
    if (defaultPanelists?.length && !panelistsFields?.length) {
      append([...defaultPanelists]);
    }

    if (!defaultPanelists.length) {
      append(NEW_PANELIST);
    }

    setInitialSetup(true);
  };

  if (!initialSetup) {
    setupPanelists();
  }

  return (
    <>
      {panelistsFields?.map((panelist, index) => {
        return (
          <InterviewPanelistItem
            key={panelist.id}
            index={index}
            panelist={panelist}
            interview={interview}
            isSaving={isSaving}
            isDisabled={isDisabled}
            formProps={formProps}
            onClickDelete={() => remove(index)}
          />
        );
      })}
      <div className="d-flex justify-content-end">
        <Button
          variant="link"
          disabled={isSaving || isDisabled}
          onClick={() => append(NEW_PANELIST)}
        >
          Add more
        </Button>
      </div>
    </>
  );
};

InterviewPanelistsList.propTypes = {
  isSaving: PropTypes.bool,
  formProps: PropTypes.object,
  interview: PropTypes.object,
  defaultPanelists: PropTypes.array,
  isDisabled: PropTypes.bool,
};

InterviewPanelistsList.defaultProps = {
  isSaving: false,
  defaultPanelists: [],
};

export default InterviewPanelistsList;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { CandidateStatusEnum } from "@micoach/js-sdk";
import dayjs from "dayjs";
import { HiringEventApi } from "micoach-api";
import { getZonedDate } from "utils";

import FormControlErrorMessage from "Components/Common/FormControlErrorMessage";
import { useCandidates } from "Hooks/useCandidates";

import styles from "Components/HiringEvent/styles/HiringEventAddEditModal.module.css";

const HiringEventAddEditModal = (props) => {
  const {
    show,
    hiringEvent,
    companies,
    recruiters,
    activeApplicationProcesses,
    onClose,
    onSaved,
  } = props;

  const [isSaving, setIsSaving] = useState(false);
  const [companyContacts, setCompanyContacts] = useState();

  const isEditing = !!hiringEvent?._id;
  const hiringEventDisabled = dayjs().isAfter(hiringEvent?.date, "day");

  const candidatesQueryParams = {
    status: [CandidateStatusEnum.TechnicalInterview],
  };

  const {
    loadingCandidates,
    candidates,
    setQueryParams: setQueryCandidateParams,
  } = useCandidates(candidatesQueryParams);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const handleChangeCompany = (companyId, companies) => {
    setValue("companyContacts", null);

    if (companyId) {
      setCompanyContacts(
        companies
          .find((company) => company._id === companyId)
          ?.contacts?.map((contact) => ({
            value: contact._id,
            label: contact.name,
          }))
      );
    } else {
      setCompanyContacts([]);
    }
  };

  const candidatesOptions = (inputValue) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        setQueryCandidateParams({
          ...candidatesQueryParams,
          search: inputValue,
        });
        const newValues = candidates
          ?.filter(
            (candidate) =>
              !activeApplicationProcesses?.includes(
                candidate.applicationProcess._id
              )
          )
          ?.map((candidate) => {
            return {
              value: candidate.applicationProcess._id,
              label: candidate.name,
            };
          });
        resolve(newValues);
      }, 100);
    });
  };

  const onSubmit = async (formValues) => {
    try {
      setIsSaving(true);
      const hiringEventRequest = {
        company: formValues.company.value,
        date: dayjs(formValues.date).format("YYYY-MM-DD"),
        recruiterId: formValues.recruiter.value,
        recruiterName: formValues.recruiter.label,
        applicationProcesses:
          formValues.applicationProcesses?.map(
            (applicationProcess) => applicationProcess.value
          ) ?? [],
        companyContacts: formValues.companyContacts
          ? formValues.companyContacts?.map((contact) => contact.value)
          : [],
      };

      let response;

      if (isEditing) {
        response = await HiringEventApi.updateHiringEvent(
          hiringEvent?._id,
          hiringEventRequest
        );
      } else {
        response = await HiringEventApi.createHiringEvent(hiringEventRequest);
      }

      toast.success(
        `Success! The Hiring Event has been ${isEditing ? "saved" : "added"}.`
      );

      onSaved?.(response.data);
      onClose?.();
    } catch (error) {
      const status = error.response?.status;
      const errorMessage = error.response?.data?.errors?.[0]?.message;

      if (status === 409 && errorMessage) {
        toast.error(errorMessage);
      } else if (status === 404) {
        toast.error("Hiring Event not found.");
      } else {
        toast.error("Error, please try again.");
      }

      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (show) {
      if (hiringEvent && isEditing) {
        setValue(
          "applicationProcesses",
          hiringEvent.applicationProcesses.map((applicationProcess) => ({
            label: applicationProcess.candidate?.name,
            value: applicationProcess._id,
          }))
        );
        setValue(
          "companyContacts",
          hiringEvent.company?.contacts
            ?.filter((contact) =>
              hiringEvent.companyContacts.includes(contact._id)
            )
            .map((contact) => ({
              label: contact.name,
              value: contact._id,
            }))
        );
        setValue("company", {
          label: hiringEvent.company?.name,
          value: hiringEvent.company?._id,
        });
        setValue("date", getZonedDate(hiringEvent?.date));
        setValue("recruiter", {
          label: hiringEvent.recruiterName,
          value: hiringEvent.recruiterId,
        });
        setCompanyContacts(
          hiringEvent.company?.contacts?.map((contact) => ({
            label: contact.name,
            value: contact._id,
          }))
        );
      }
    } else {
      reset();
    }
  }, [show, hiringEvent, reset, isEditing, setValue]);

  return (
    <Modal show={show} onHide={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <div className={styles.Header}>
            <Modal.Title>
              {isEditing ? "Edit" : "Create"} Hiring Event
            </Modal.Title>
            <span>
              Select the information for your special day to get interviews with
              your client and team.
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form.Group
              as={Col}
              controlId="company"
              className={`${isEditing ? "" : "required"} form-group`}
              sm={6}
            >
              <Form.Label id="company">Client</Form.Label>
              <Controller
                name="company"
                control={control}
                defaultValue={null}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isClearable
                    classNamePrefix="ReactSelect"
                    aria-labelledby="company"
                    value={value}
                    isInvalid={!!errors.client}
                    options={companies.map((company) => ({
                      value: company._id,
                      label: company.name,
                    }))}
                    className={errors.company ? "is-invalid" : ""}
                    isDisabled={isSaving || hiringEventDisabled || isEditing}
                    onChange={(option) => {
                      onChange(option);
                      handleChangeCompany(option?.value, companies);
                    }}
                  />
                )}
              />
              <FormControlErrorMessage
                show={errors.company?.type === "required"}
                message="The client is required"
              />
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="date"
              className={`${isEditing ? "" : "required"} form-group`}
              sm={6}
            >
              <Form.Label id="date">Date</Form.Label>
              <Controller
                name="date"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={null}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    showPopperArrow={false}
                    ariaLabelledBy="date"
                    placeholderText="Select a date"
                    dateFormat="MMM dd, yyyy"
                    className={`form-control ${errors.date && "is-invalid"}`}
                    selected={value}
                    disabled={isSaving || hiringEventDisabled || isEditing}
                    minDate={new Date()}
                    onChange={onChange}
                  />
                )}
              />
              <FormControlErrorMessage
                show={errors.date?.type === "required"}
                message="The date is required"
              />
            </Form.Group>
          </Row>
          <Form.Group controlId="recruiter" className="required form-group">
            <Form.Label id="recruiter">Recruiter</Form.Label>
            <Controller
              name="recruiter"
              control={control}
              rules={{
                required: true,
              }}
              defaultValue={null}
              render={({ field: { onChange, value } }) => (
                <Select
                  hideSelectedOptions
                  isClearable
                  isDisabled={isSaving || hiringEventDisabled}
                  isInvalid={!!errors.client}
                  aria-labelledby="recruiter"
                  classNamePrefix="ReactSelect"
                  value={value}
                  options={recruiters.map((recruiter) => ({
                    value: recruiter._id,
                    label: recruiter.name,
                  }))}
                  className={errors.recruiter ? "is-invalid" : ""}
                  onChange={onChange}
                />
              )}
            />
            <FormControlErrorMessage
              show={errors.recruiter?.type === "required"}
              message="The recruiter is required"
            />
          </Form.Group>
          <Form.Group controlId="applicationProcesses" className="form-group">
            <Form.Label id="candidates">Candidates</Form.Label>
            <Controller
              name="applicationProcesses"
              control={control}
              defaultValue={null}
              render={({ field: { onChange, value } }) => (
                <AsyncSelect
                  isMulti
                  isClearable
                  hideSelectedOptions
                  isLoading={loadingCandidates}
                  isDisabled={isSaving || hiringEventDisabled}
                  aria-labelledby="candidates"
                  classNamePrefix="ReactSelect"
                  value={value}
                  loadOptions={candidatesOptions}
                  defaultOptions={candidates
                    ?.filter(
                      (candidate) =>
                        !activeApplicationProcesses?.includes(
                          candidate.applicationProcess?._id
                        )
                    )
                    .map((candidate) => ({
                      value: candidate.applicationProcess?._id,
                      label: candidate.name,
                    }))}
                  onChange={onChange}
                />
              )}
            />
          </Form.Group>
          <Form.Group controlId="companyContacts" className="form-group">
            <Form.Label id="companyContacts">Client Members</Form.Label>
            <Controller
              name="companyContacts"
              control={control}
              defaultValue={null}
              render={({ field: { onChange, value } }) => (
                <Select
                  isClearable
                  isMulti
                  isDisabled={
                    isSaving || !getValues("company") || hiringEventDisabled
                  }
                  hideSelectedOptions
                  aria-labelledby="companyContacts"
                  classNamePrefix="ReactSelect"
                  value={value}
                  options={companyContacts}
                  onChange={onChange}
                />
              )}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="CancelButton"
            disabled={isSaving}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="SaveButton"
            disabled={isSaving || hiringEventDisabled}
          >
            {isEditing ? "Save" : "Create"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

HiringEventAddEditModal.propTypes = {
  show: PropTypes.bool,
  companies: PropTypes.array,
  recruiters: PropTypes.array,
  hiringEvent: PropTypes.object,
  activeApplicationProcesses: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onSaved: PropTypes.func,
};

HiringEventAddEditModal.defaultProps = {
  show: false,
  companies: [],
  recruiters: [],
  activeApplicationProcesses: [],
};

export default HiringEventAddEditModal;

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import { useLocation } from "react-router-dom";

import Header from "Layouts/Header";
import { assets } from "constants.js";

import "Layouts/styles/Main.css";
import "Layouts/styles/Default.css";

export default function recruiterLayout(WrappedComponent, userRoles) {
  // eslint-disable-next-line react/display-name
  return () => {
    const location = useLocation();

    const isFluidContainer =
      !location.pathname.includes("/evaluation") &&
      !location.pathname.includes("/profile") &&
      !location.pathname.includes("/achievements") &&
      !location.pathname.includes("/coachees");

    return (
      <div className="LayoutContainer">
        <Header userRoles={userRoles} isOffCanvas={false} />
        <Container className="MainContainer" fluid={isFluidContainer}>
          <Row>
            <Col>
              <WrappedComponent />
            </Col>
          </Row>
        </Container>
        <footer className="FooterContainer">
          <Container>
            <Row>
              <Col>
                <Image src={assets.MICOACH_LOGO} alt="miCoach" />
                <span className="text-muted">
                  &copy; All rights reserved. Copyright{" "}
                  {new Date().getFullYear()}. Powered by ITJ{" | "}
                  <a href="/privacy-policy">Privacy Policy</a>
                </span>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    );
  };
}

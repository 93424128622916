import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
  faEllipsisH,
  faPen,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ConfirmModal from "Components/ConfirmModal";
import FormattedDate from "Components/FormattedDate";
import { getDurationDatesText } from "utils.js";

import "Components/Candidate/Profile/styles/ResumeViewCard.css";

const ResumeViewCard = (props) => {
  const { title, description, buttonName } = props;

  return (
    <Card className="Card ResumeViewCard">
      <Card.Body>
        <Card.Title className="Title">{title}</Card.Title>
        <Card.Text className="Text">{description}</Card.Text>
        {props.children}
      </Card.Body>
      <Card.Footer className="Footer">
        <Button variant="link" className="Edit" onClick={props.onClick}>
          <FontAwesomeIcon icon={faPlus} className="Icon" />
          Add {buttonName}
        </Button>
      </Card.Footer>
    </Card>
  );
};

const ResumeItem = (props) => {
  const {
    id,
    itemsGroupType,
    title,
    subtitle,
    showDuration,
    current,
    variantDate,
    startDate,
    endDate,
    location,
    url,
    urlTitle,
    itemId,
    description,
  } = props;

  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);

  return (
    <>
      <ConfirmModal
        title="Delete confirmation"
        confirmButtonText="Yes, delete"
        show={showDeleteItemModal}
        onConfirm={() => props.onConfirmDelete(id)}
        onClose={() => setShowDeleteItemModal(false)}
      >
        <div>
          Are you sure you want to delete
          <strong> {`${title} | ${subtitle}`} </strong>
          from your {itemsGroupType}?
          <div className="mt-3"> This action cannot be undone.</div>
        </div>
      </ConfirmModal>

      <div className="ResumeItem">
        <div className="TitleContainer">
          <div>{title}</div>
          <div>
            <DropdownButton
              variant="link"
              title={<FontAwesomeIcon icon={faEllipsisH} />}
            >
              <Dropdown.Item
                as="button"
                eventKey="editButton"
                onClick={props.onClickEdit}
              >
                <FontAwesomeIcon icon={faPen} className="Icon" />
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                eventKey="deleteButton"
                onClick={() => setShowDeleteItemModal(true)}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="Icon" />
                Delete
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
        <div className="SubtitleContainer">
          <span className="Text SubtitleItem">{subtitle}</span>
          {showDuration && (
            <div className="Text">
              {getDurationDatesText(startDate, endDate)}
            </div>
          )}
        </div>

        <div className="Text">
          {startDate && (
            <FormattedDate variant={variantDate}>{startDate}</FormattedDate>
          )}
          {current && !endDate && <span> - Present</span>}
          {endDate && (
            <>
              <span> - </span>
              <FormattedDate variant={variantDate}>{endDate}</FormattedDate>
            </>
          )}
        </div>
        <div className="Text">{location}</div>
        {itemId && <div className="Text">Id: {itemId}</div>}
        {url && urlTitle && (
          <div className="d-grid gap-2">
            <Button
              variant="link"
              className="Edit"
              size="lg"
              onClick={() => window.open(url)}
            >
              {urlTitle}
            </Button>
          </div>
        )}
        <div className="Text">{description}</div>
      </div>
    </>
  );
};

ResumeItem.propTypes = {
  id: PropTypes.string.isRequired,
  itemsGroupType: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  showDuration: PropTypes.bool,
  current: PropTypes.bool,
  variantDate: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  location: PropTypes.string,
  url: PropTypes.string,
  urlTitle: PropTypes.string,
  itemId: PropTypes.string,
  description: PropTypes.string,
  onClickEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
};

ResumeViewCard.propTypes = {
  title: PropTypes.string.isRequired,
  buttonName: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

ResumeViewCard.Item = ResumeItem;
export default ResumeViewCard;

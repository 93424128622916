import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="text-center">
      <h1 className="p-4">404 - Not Found!</h1>
      <Link to="/">Go Home</Link>
    </div>
  );
}

export default NotFound;

import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { faTrashAlt, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import FormattedDate from "Components/FormattedDate";
import { formattedSize } from "utils.js";

import "Components/Candidate/styles/DocumentCard.css";

function DocumentCard(props) {
  const {
    document,
    hasDocumentExample = false,
    hasDocumentTemplate = false,
    onClickUpload,
    onClickDelete,
    onClickExample,
    onClickDownloadTemplate,
  } = props;

  return (
    <>
      <Card className="DocumentCard Card CardShadow mb-3">
        <Card.Body>
          <Row className="pb-1">
            <Col xs={10}>
              <strong>{document.checklistItem}</strong>
            </Col>
            <Col xs={2} className="text-end">
              <Button
                variant="link"
                className="p-0"
                aria-label="Upload"
                onClick={onClickUpload}
              >
                <FontAwesomeIcon icon={faUpload} />
              </Button>
            </Col>
          </Row>

          {document.name && (
            <>
              <Row className="pb-1">
                <Col xs={10} className="d-flex align-items-center pe-0">
                  <div className="DocumentName" title={document.name}>
                    {document.name}
                  </div>
                  <span className="DocumentSize">
                    {" "}
                    | {formattedSize(document.size)}
                  </span>
                </Col>
                <Col xs={2} className="text-end">
                  <Button
                    variant="link"
                    className="p-0"
                    aria-label="Delete"
                    onClick={onClickDelete}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
                </Col>
              </Row>

              <div className="DocumentDate">
                <FormattedDate>{document.uploadDate}</FormattedDate>
              </div>
            </>
          )}

          <div className="Actions">
            {hasDocumentExample && (
              <Button variant="link" onClick={onClickExample}>
                See example
              </Button>
            )}

            {hasDocumentTemplate && (
              <Button variant="link" onClick={onClickDownloadTemplate}>
                Download template
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

DocumentCard.propTypes = {
  document: PropTypes.object.isRequired,
  hasDocumentExample: PropTypes.bool,
  hasDocumentTemplate: PropTypes.bool,
  onClickUpload: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickExample: PropTypes.func,
  onClickDownloadTemplate: PropTypes.func,
};

export default DocumentCard;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Select from "react-select";

const CompanySelect = (props) => {
  const { companies } = props;

  const { control } = useForm();

  const [companyOptions, setCompanyOptions] = useState([]);

  useEffect(() => {
    setCompanyOptions(
      companies.map((company) => {
        return {
          label: company.name,
          value: company.name,
        };
      })
    );
  }, [companies]);

  const handleCompanyChange = (option) => {
    props.onChange(option);
  };

  return (
    <Select
      name="CompanySelect"
      classNamePrefix="ReactSelect"
      placeholder="Select employer ..."
      control={control}
      options={companyOptions}
      onChange={handleCompanyChange}
      defaultValue={null}
      isClearable
    />
  );
};

CompanySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
};

export default CompanySelect;

import { useCallback, useEffect, useState } from "react";
import { EmailApi } from "micoach-api";

export const useEmailsSent = () => {
  const [emailsSent, setEmailSents] = useState([]);
  const [loadingEmailsSent, setLoadingEmailsSent] = useState(false);

  const loadEmailSents = useCallback(async (type, metadata) => {
    if (type) {
      setLoadingEmailsSent(true);
      try {
        const { data } = await EmailApi.listEmailsSent(
          type,
          JSON.stringify(metadata)
        );

        setEmailSents(
          data.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          })
        );
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        setLoadingEmailsSent(false);
      }
    }
  }, []);

  useEffect(() => {
    loadEmailSents();
  }, [loadEmailSents]);

  return { emailsSent, loadingEmailsSent, loadEmailSents };
};

import PropTypes from "prop-types";

import styles from "Components/Common/styles/StepProgressBar.module.css";

const StepProgressBar = (props) => {
  const { current, total } = props;

  return (
    <div className={styles.StepProgressBar}>
      {Array(total)
        .fill(0)
        .map((_, index) => (
          <span
            key={index}
            className={index < current ? styles.Active : ""}
            data-testid={index < current ? "active-step" : "inactive-step"}
          ></span>
        ))}
    </div>
  );
};

StepProgressBar.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
};

StepProgressBar.defaultProps = {
  current: 1,
  total: 1,
};

export default StepProgressBar;

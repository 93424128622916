import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

import UserImage from "Components/UserImage";

import styles from "Components/Candidate/styles/CoacheeCard.module.css";

const CoacheeCard = (props) => {
  const { coachee, balanceDate } = props;

  return (
    <Link
      to={`/coachees/${coachee.userId}/evaluations/skills?year=${balanceDate.year}&quarter=${balanceDate.quarter}`}
    >
      <Card className={`CardShadow ${styles.CoacheeCard}`}>
        <Card.Body>
          <UserImage
            userName={coachee.name}
            userImageUrl={coachee.userImage}
            isProfilePicture
          />
          <Card.Title className={styles.Title}>{coachee.name}</Card.Title>

          <Card.Subtitle className={styles.Subtitle}>
            {coachee.role}
          </Card.Subtitle>
        </Card.Body>
      </Card>
    </Link>
  );
};

CoacheeCard.propTypes = {
  coachee: PropTypes.object.isRequired,
  balanceDate: PropTypes.shape({
    year: PropTypes.string,
    quarter: PropTypes.string,
  }),
};

export default CoacheeCard;

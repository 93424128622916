import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useCompany } from "../Hooks/useCompanies";
import BackendService from "../Services/BackendService";

function AddContact() {
  const { handleSubmit, register } = useForm();
  const { id } = useParams();
  const history = useHistory();
  const [company] = useCompany(id);

  const onSubmit = async (formValues) => {
    await BackendService.saveContact(id, formValues);
    history.push(`/company/view/${id}`);
    toast.success("Success! The contact has been added.");
  };

  if (!company) {
    return null;
  }

  return (
    <div>
      <h1>Add new contact to {company.name}</h1>
      <hr />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="name" className="form-group">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" {...register("name")} />
        </Form.Group>
        <Form.Group controlId="email" className="form-group">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" {...register("email")} />
        </Form.Group>
        <Form.Group controlId="phone" className="form-group">
          <Form.Label>Phone number</Form.Label>
          <Form.Control type="text" {...register("phone")} />
        </Form.Group>
        <Form.Group controlId="jobTitle" className="form-group">
          <Form.Label>Job Title</Form.Label>
          <Form.Control type="text" {...register("jobTitle")} />
        </Form.Group>
        <Button type="submit">Save</Button>
      </Form>
    </div>
  );
}

export default AddContact;

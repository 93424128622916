import axios from "axios";

async function uploadFileToS3(url, document) {
  await axios.put(url, document, {
    headers: {
      "Content-Type": document.type,
    },
  });
}

const exports = {
  uploadFileToS3,
};

export default exports;

import { useContext } from "react";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import UnreadTasksContext from "Context/UnreadTasksProvider";

import styles from "Components/Task/styles/UnreadTasksCounter.module.css";

const UnreadTasksCounter = () => {
  const { unreadTasks } = useContext(UnreadTasksContext);

  return (
    <>
      <FontAwesomeIcon icon={faInbox} />
      {unreadTasks > 0 && (
        <div className={styles.TaskCounter}>{unreadTasks}</div>
      )}
    </>
  );
};

export default UnreadTasksCounter;

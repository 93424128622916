import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import { useKeycloak } from "@react-keycloak/web";
import { UserRoleEnum } from "@micoach/js-sdk";

import EllipsisMenu from "Components/Common/EllipsisMenu";
import { menuHiringEventsTable } from "constants.js";
import { getFormattedTimeZoneDate, getRoles, removeDuplicates } from "utils.js";

const HiringEventsTable = (props) => {
  const { hiringEvents, onSelectMenuOption } = props;

  const { keycloak } = useKeycloak();

  const userRoles = getRoles(keycloak);

  const isRecruiterAdmin = userRoles.some(
    (userRole) => UserRoleEnum.AdminRecruiter === userRole
  );

  const hiringEventOptions = [menuHiringEventsTable.VIEW_INTERVIEWS].concat(
    isRecruiterAdmin ? [menuHiringEventsTable.EDIT_HIRING_EVENT] : []
  );

  const getPositions = (hiringEvent) => {
    return hiringEvent.applicationProcesses?.map((aplicationProcess) => ({
      title: aplicationProcess.position.title,
    }));
  };

  const columns = [
    {
      name: <strong>Hiring Event Title</strong>,
      selector: (hiringEvent) => (
        <>{`${hiringEvent.company?.name} ${getFormattedTimeZoneDate(
          hiringEvent.date
        )} `}</>
      ),
      minWidth: "200px",
      wrap: true,
    },
    {
      name: <strong>Job Positions</strong>,
      selector: (hiringEvent) => {
        const positions = getPositions(hiringEvent);
        const removedDuplicatesPositions = removeDuplicates(positions, "title");
        return (
          <>
            {removedDuplicatesPositions.map((position) => (
              <div key={position.title}>
                {position.title}
                <br />
              </div>
            ))}
          </>
        );
      },
      width: "440px",
      wrap: true,
    },
    {
      cell: (hiringEvent) => {
        return (
          <EllipsisMenu
            options={hiringEventOptions}
            onSelectedOption={(option) =>
              onSelectMenuOption(option, hiringEvent)
            }
          />
        );
      },
    },
  ];

  return <DataTable columns={columns} data={hiringEvents} highlightOnHover />;
};

HiringEventsTable.propTypes = {
  hiringEvents: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      company: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      applicationProcesses: PropTypes.arrayOf(
        PropTypes.shape({
          position: PropTypes.shape({
            _id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
          }).isRequired,
        })
      ),
    })
  ),
  onSelectMenuOption: PropTypes.func,
};

HiringEventsTable.defaultProps = {
  hiringEvents: [],
};

export default HiringEventsTable;

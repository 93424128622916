import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApplicationProcessApi } from "micoach-api";

import FormControlErrorMessage from "Components/Common/FormControlErrorMessage";

import styles from "Components/CandidateInfo/styles/RecruiterViewEditCard.module.css";

const RecruiterViewEditCard = (props) => {
  const { recruiters, recruiter, applicationProcessId, isEditable } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [assignedRecruiter, setAssignedRecruiter] = useState(recruiter);

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (formValues) => {
    try {
      setIsSaving(true);

      await ApplicationProcessApi.updateRecruiter(applicationProcessId, {
        userId: formValues.recruiter.value,
      });

      setAssignedRecruiter({
        _id: formValues.recruiter.value,
        name: formValues.recruiter.label,
      });
      toast.success("Success! TA Member has been updated.");

      reset();
      setIsEditing(false);
    } catch (error) {
      console.error(error);
      toast.error("Error! An error occurred, please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (assignedRecruiter && isEditing) {
      setValue("recruiter", {
        label: assignedRecruiter.name,
        value: assignedRecruiter._id,
      });
    } else {
      reset();
    }
  }, [assignedRecruiter, isEditing, setValue, reset]);

  return (
    <Card className={`Card ${styles.Card}`}>
      <Card.Body>
        <div>
          <h2 className={styles.Title}>TA member in charge</h2>
        </div>

        <Form onSubmit={handleSubmit(onSubmit)}>
          {isEditing ? (
            <Form.Group controlId="recruiter" className="form-group required">
              <Form.Label id="recruiter">TA Member</Form.Label>
              <Controller
                name="recruiter"
                control={control}
                defaultValue={null}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isInvalid={!!errors.recruiter}
                    classNamePrefix="ReactSelect"
                    aria-labelledby="recruiter"
                    value={value}
                    options={recruiters?.map((recruiter) => ({
                      value: recruiter._id,
                      label: recruiter.name,
                    }))}
                    className={errors.recruiter ? "is-invalid" : ""}
                    onChange={onChange}
                  />
                )}
              />
              <FormControlErrorMessage
                show={errors.recruiter?.type === "required"}
                message="The TA Member is required"
              />
            </Form.Group>
          ) : (
            <span> {assignedRecruiter.name}</span>
          )}

          {isEditable && (
            <>
              <div className={styles.EditIcon}>
                {!isEditing && (
                  <Button
                    variant="outline-primary"
                    className="EditButton"
                    data-testid="EditButton"
                    disabled={isEditing}
                    onClick={() => setIsEditing(true)}
                  >
                    <FontAwesomeIcon icon={faPen}></FontAwesomeIcon>
                  </Button>
                )}
              </div>

              {isEditing && (
                <>
                  <hr />
                  <div className={styles.EditOptions}>
                    <Button
                      variant="secondary"
                      className={styles.Cancel}
                      disabled={isSaving}
                      onClick={() => setIsEditing(false)}
                    >
                      Cancel
                    </Button>
                    <Button variant="primary" type="submit" disabled={isSaving}>
                      Save
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

RecruiterViewEditCard.propTypes = {
  recruiters: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  recruiter: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
  }),
  applicationProcessId: PropTypes.string,
  isEditable: PropTypes.bool,
};

RecruiterViewEditCard.defaultProps = {
  recruiters: [],
  isEditable: false,
};

export default RecruiterViewEditCard;

import { useCallback, useState } from "react";
import { HiringEventApi } from "micoach-api";

export const useHiringEventInterviews = (id) => {
  const [hiringEventInterviews, setHiringEventInterviews] = useState([]);
  const [loadingHiringEventInterviews, setLoadingHiringEventInterviews] =
    useState(false);
  const [hiringEventInterviewsError, setHiringEventInterviewsError] =
    useState(false);

  const loadHiringEventInterviews = useCallback(async (id) => {
    setLoadingHiringEventInterviews(true);
    setHiringEventInterviewsError(false);
    try {
      const { data } = await HiringEventApi.listHiringEventInterviews(id);

      setHiringEventInterviews(data);
    } catch (error) {
      console.error(error);
      setHiringEventInterviewsError(error);
    } finally {
      setLoadingHiringEventInterviews(false);
    }
  }, []);

  return {
    hiringEventInterviews,
    loadingHiringEventInterviews,
    loadHiringEventInterviews,
    setHiringEventInterviews,
    hiringEventInterviewsError,
  };
};

import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isEqual } from "utils";

import BadgeList from "Components/Common/BadgeList";

const BadgeSelect = (props) => {
  const {
    options,
    defaultValues,
    maxSelection,
    label,
    icon,
    iconPosition,
    className,
    onChange,
  } = props;

  const [selectedOptions, setSelectedOptions] = useState(defaultValues || []);
  const [disabledOptions, setDisabledOptions] = useState([]);
  const [optionsAux, setOptionsAux] = useState(options);

  const findIndex = (option, options = []) => {
    return options.findIndex((selectedOption) =>
      isEqual(selectedOption, option)
    );
  };

  const updateSelectedValues = (values) => {
    setSelectedOptions(values);
    onChange?.(values);
  };

  const getDisabledOptions = useCallback(
    (options = [], selectedOptions = []) => {
      return options.filter((option) => findIndex(option, selectedOptions) < 0);
    },
    []
  );

  const handleClickOption = (option) => {
    const selectedIndex = findIndex(option, selectedOptions);

    if (selectedIndex >= 0) {
      const newSelectedOptions = selectedOptions.filter(
        (_, index) => index !== selectedIndex
      );

      updateSelectedValues(newSelectedOptions);
    } else if (!maxSelection || selectedOptions.length < maxSelection) {
      updateSelectedValues([...selectedOptions, option]);
    }
  };

  useEffect(() => {
    if (selectedOptions.length === maxSelection) {
      setDisabledOptions(getDisabledOptions(options, selectedOptions));
    } else {
      setDisabledOptions([]);
    }
    if (JSON.stringify(options) !== JSON.stringify(optionsAux)) {
      setDisabledOptions([]);
      setSelectedOptions([]);
      setOptionsAux(options);
    }
  }, [getDisabledOptions, maxSelection, options, selectedOptions, optionsAux]);

  return (
    <BadgeList
      options={options}
      selectedOptions={selectedOptions}
      disabledOptions={disabledOptions}
      label={label}
      icon={icon}
      iconPosition={iconPosition}
      className={className}
      onClick={handleClickOption}
    />
  );
};

BadgeSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  defaultValues: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  icon: PropTypes.string,
  iconPosition: PropTypes.string,
  className: PropTypes.string,
  maxSelection: PropTypes.number,
  onChange: PropTypes.func,
};

BadgeList.defaultProps = {
  options: [],
  defaultValues: [],
};

export default BadgeSelect;

import { useState } from "react";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Comments from "Components/Comments";
import FormattedDate from "Components/FormattedDate";
import StepBadge from "Components/StepBadge";

import "Components/styles/StepAccordion.css";

function Step(props) {
  const {
    applicationProcessId,
    candidateId,
    step,
    isEditable,
    defaultActiveKey,
  } = props;
  const [commentsSize, setCommentsSize] = useState(step?.comments?.length || 0);

  const handleChangeComments = (comments) => {
    setCommentsSize(comments.length);
  };

  if (!step) return null;

  return (
    <Accordion
      defaultActiveKey={defaultActiveKey ?? "0"}
      className="StepAccordion"
    >
      <Card>
        <Accordion.Item eventKey={step.step}>
          <Accordion.Header as={Card.Header}>
            <ListGroup.Item
              key={step.step}
              step={step.step}
              className="StepHeader"
            >
              <StepBadge step={step.step} />

              {commentsSize > 0 && (
                <span className="CommentsCounter">
                  <FontAwesomeIcon className="Icon" icon={faCommentAlt} />
                  <span className="Counter">{commentsSize}</span>
                </span>
              )}

              <span className="ms-2 StepDate">
                <FormattedDate>{step.updatedAt}</FormattedDate>
              </span>
            </ListGroup.Item>
          </Accordion.Header>
          <Accordion.Body className="p-0">
            <Comments
              applicationProcessId={applicationProcessId}
              candidateId={candidateId}
              stepName={step.step}
              comments={step.comments || []}
              isEditable={isEditable}
              onChange={handleChangeComments}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  );
}

Step.propTypes = {
  applicationProcessId: PropTypes.string,
  candidateId: PropTypes.string,
  step: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  defaultActiveKey: PropTypes.string,
};

export default Step;

import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { faChartPie, faTable } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SkillBalanceChart from "Components/Evaluation/Skill/SkillBalanceChart";
import SkillBalanceTable from "Components/Evaluation/Skill/SkillBalanceTable";

const SkillBalanceCard = (props) => {
  const { balanceDate, summary } = props;
  const [skillsView, setSkillsView] = useState({
    isGraph: true,
    icon: faTable,
    text: "Table View",
  });

  const handleSkillsView = () => {
    skillsView.isGraph
      ? setSkillsView({
          isGraph: false,
          icon: faChartPie,
          text: "Graph view",
        })
      : setSkillsView({
          isGraph: true,
          icon: faTable,
          text: "Table View",
        });
  };

  const skillsViewResponse = () => {
    if (skillsView.isGraph) {
      return <SkillBalanceChart summary={summary} />;
    } else {
      return <SkillBalanceTable summary={summary} />;
    }
  };

  return (
    <Card className="Card">
      <Card.Body>
        <Row>
          <Col md={9}>
            <Card.Title>
              Skills balance - {`Q${balanceDate.quarter} ${balanceDate.year}`}
            </Card.Title>
          </Col>
          <Col md={3}>
            <Button
              variant="link"
              className="float-end fs-13"
              onClick={handleSkillsView}
            >
              <FontAwesomeIcon icon={skillsView.icon} className="me-2" />
              {skillsView.text}
            </Button>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>{skillsViewResponse()}</Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

SkillBalanceCard.propTypes = {
  balanceDate: PropTypes.shape({
    year: PropTypes.number.isRequired,
    quarter: PropTypes.number.isRequired,
    nextQuarter: PropTypes.object,
  }).isRequired,
  summary: PropTypes.shape({
    userName: PropTypes.string.isRequired,
    userRole: PropTypes.string.isRequired,
    self: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      })
    ),
    requested: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      })
    ),
    expectedProficiencyLevel: PropTypes.number,
  }).isRequired,
};

export default SkillBalanceCard;

import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import SkillRatingList from "Components/Candidate/Skills/SkillRatingList";
import BadgeList from "Components/Common/BadgeList";
import { skillCategories } from "constants.js";

import "Components/Candidate/Skills/styles/SkillControl.css";

const SkillControl = (props) => {
  const {
    control,
    category,
    selectOptions,
    defaultValues,
    suggestedSkills,
    isEditing,
  } = props;

  const skillName = skillCategories[category].label;

  const handleSelectChange = (skill) => {
    !isSkillAlreadySelected(skill) &&
      props.onSelectChange?.(category, [...defaultValues, skill]);
  };

  const isSkillAlreadySelected = (skill) => {
    return defaultValues.some(
      (defaultValue) => defaultValue.name === skill.name
    );
  };

  return (
    <div className="SkillControl">
      {isEditing ? (
        <>
          <Form.Group controlId={category} className="form-group">
            <Form.Label className="Subtitle">{skillName} skills</Form.Label>
            <SkillRatingList
              skillList={defaultValues}
              isEditable={isEditing}
              onSelectValueChange={props.onSelectValueChange}
              onSelectSkillRemove={props.onSelectSkillRemove}
            />
            <Controller
              name={category}
              control={control}
              defaultValue={defaultValues}
              render={({ ref }) => (
                <Select
                  inputRef={ref}
                  options={selectOptions}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.name}
                  placeholder="Select a skill..."
                  value={[]}
                  classNamePrefix="ReactSelect"
                  onChange={(option) => {
                    handleSelectChange(option);
                  }}
                />
              )}
            />
          </Form.Group>

          {suggestedSkills.length > 0 && (
            <div className="SuggestedSkills">
              <div className="Title">
                Suggested {skillName} skills based in your profile
              </div>
              <BadgeList
                options={suggestedSkills}
                label="name"
                icon={faPlus}
                iconPosition="right"
                onClick={(skill) => props.onAddSuggestion?.(skill)}
              />
            </div>
          )}
        </>
      ) : (
        <>
          <Card.Text className="Subtitle">{skillName} skills</Card.Text>
          <SkillRatingList skillList={defaultValues} />
        </>
      )}
    </div>
  );
};

SkillControl.propTypes = {
  control: PropTypes.object.isRequired,
  category: PropTypes.string.isRequired,
  selectOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultValues: PropTypes.arrayOf(PropTypes.object),
  suggestedSkills: PropTypes.arrayOf(PropTypes.object),
  isEditing: PropTypes.bool,
  onSelectChange: PropTypes.func,
  onAddSuggestion: PropTypes.func,
  onSelectValueChange: PropTypes.func,
  onSelectSkillRemove: PropTypes.func,
};

SkillControl.defaultProps = {
  defaultValues: [],
  suggestedSkills: [],
  isEditing: false,
};

export default SkillControl;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Droppable } from "react-beautiful-dnd";
import { FixedSizeList } from "react-window";

import { columnIsStep } from "utils.js";

import "./styles/KanbanItemList.css";

import KanbanItem from "./KanbanItem";
import KanbanRow from "./KanbanRow";

const KanbanItemList = (props) => {
  const {
    step,
    candidates,
    userId,
    isExternalRecruiter,
    onClickRequestDocuments,
  } = props;

  const [height, setHeight] = useState(window.innerHeight - 470);
  const width = 348; // Column width: 380px
  const isStep = columnIsStep(step);
  const itemSize = isStep ? 120 : 90; // Padding: 1rem = 16px

  useEffect(() => {
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  });

  const updateHeight = () => {
    setHeight(window.innerHeight - 470);
  };

  return (
    <Droppable
      droppableId={step}
      mode="virtual"
      renderClone={(provided, snapshot, rubric) => (
        <KanbanItem
          step={step}
          index={rubric.source.index}
          candidate={candidates[rubric.source.index]}
          provided={provided}
          isDragging={snapshot.isDragging}
          userId={userId}
          isExternalRecruiter={isExternalRecruiter}
          onClickHire={props.onClickHire}
          onClickDiscard={props.onClickDiscard}
          onClickMessage={props.onClickMessage}
          onClickMove={props.onClickMove}
          onClickRequestDocuments={onClickRequestDocuments}
        />
      )}
    >
      {(provided, snapshot) => {
        // Add an extra item to our list to make space for a dragging item
        // Usually the DroppableProvided.placeholder does this, but that won't
        // work in a virtual list
        const itemCount = snapshot.isUsingPlaceholder
          ? candidates.length + 1
          : candidates.length;

        return (
          <FixedSizeList
            height={height}
            width={width}
            itemCount={itemCount}
            itemSize={itemSize}
            outerRef={provided.innerRef}
            itemData={{
              candidates,
              step,
              userId,
              isExternalRecruiter,
              onClickHire: props.onClickHire,
              onClickDiscard: props.onClickDiscard,
              onClickMessage: props.onClickMessage,
              onClickMove: props.onClickMove,
              onClickRequestDocuments,
            }}
            className={`KanbanItemList ${!isStep && "Small"}`}
          >
            {KanbanRow}
          </FixedSizeList>
        );
      }}
    </Droppable>
  );
};

KanbanItemList.propTypes = {
  step: PropTypes.string.isRequired,
  candidates: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  isExternalRecruiter: PropTypes.bool,
  onClickHire: PropTypes.func,
  onClickDiscard: PropTypes.func,
  onClickMessage: PropTypes.func,
  onClickMove: PropTypes.func,
  onClickRequestDocuments: PropTypes.func,
};

export default React.memo(KanbanItemList);

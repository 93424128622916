import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { customSort } from "utils";

import Loading from "Components/Loading";

const TagTable = (props) => {
  const { tags, loading } = props;

  const columns = [
    {
      name: <strong>Name</strong>,
      selector: (row) => row.label,
      sortable: true,
      sortFunction: (object1, object2) =>
        customSort({ object1, object2, property: "label" }),
    },
    {
      name: <strong>Actions</strong>,
      cell: (row) => (
        <Button
          aria-label="Edit Tag"
          variant="link"
          onClick={() => {
            props.onEdit?.(row);
          }}
        >
          <FontAwesomeIcon icon={faPen}></FontAwesomeIcon>
        </Button>
      ),
      width: "80px",
      center: true,
    },
  ];

  // TODO: Add filter by name

  return (
    <DataTable
      columns={columns}
      data={tags}
      progressPending={loading}
      progressComponent={<Loading />}
      persistTableHead
      highlightOnHover
      pagination
      defaultSortFieldId={1}
    />
  );
};

TagTable.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
};

TagTable.defaultValues = {
  tags: [],
  loading: false,
};

export default TagTable;

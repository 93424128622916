import PropTypes from "prop-types";
import { useKeycloak } from "@react-keycloak/web";
import { UserRoleEnum } from "@micoach/js-sdk";

import EllipsisMenu from "Components/Common/EllipsisMenu";
import EventPanelistsList from "Components/Interview/Event/EventPanelistsList";
import EventTitle from "Components/Interview/Event/EventTitle";
import { menuInterviewsCalendar } from "constants.js";
import { getRoles } from "utils.js";

import styles from "Components/Interview/Event/styles/InterviewCalendarEvent.module.css";

const InterviewCalendarEvent = (props) => {
  const { title, interview, onSelectMenuOption } = props;

  const { keycloak } = useKeycloak();
  const userRoles = getRoles(keycloak);

  const isClient = userRoles.some(
    (userRole) => UserRoleEnum.Client === userRole
  );

  const menuOptions = isClient
    ? [menuInterviewsCalendar.EDIT_PANELISTS]
    : [
        menuInterviewsCalendar.EDIT_INTERVIEW,
        menuInterviewsCalendar.EDIT_PANELISTS,
      ];

  return (
    <div className={styles.InterviewCalendarEvent}>
      <div className={styles.Header}>
        <EventTitle
          title={title}
          panelists={interview?.panelists}
          candidateId={interview?.applicationProcess.candidate._id}
        />

        <div className={styles.Menu}>
          <EllipsisMenu
            options={menuOptions}
            onSelectedOption={(option) => onSelectMenuOption(option, interview)}
          />
        </div>
      </div>
      <EventPanelistsList panelists={interview?.panelists} />
    </div>
  );
};

InterviewCalendarEvent.propTypes = {
  title: PropTypes.string.isRequired,
  interview: PropTypes.object,
  onSelectMenuOption: PropTypes.func,
};

InterviewCalendarEvent.defaultProps = {
  interview: {},
};

export default InterviewCalendarEvent;

import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import micoach, { CandidateApi } from "micoach-api";

import TagSelectInput from "Components/Common/TagSelectInput";
import CustomSelect from "Components/CustomSelect";
import PhoneNumberInput from "Components/PhoneNumberInput";
import { useAvailablePositions } from "Hooks/usePositions";
import { useRoles } from "Hooks/useRoles";
import {
  applicationProcessSteps,
  countries,
  EMAIL_PATTERN,
  sources,
} from "constants.js";
import { CANDIDATE_EMAIL_DUPLICATED } from "error-constants.js";
import { getIsoStartDate, isEarlyStep } from "utils.js";

import "Components/styles/CandidateAddModal.css";

const formInitialState = {
  name: "",
  email: "",
  phone: "",
  source: "",
  isTrainee: false,
};

const CandidateAddModal = (props) => {
  const { show = false, isExternalRecruiter = false } = props;

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: formInitialState,
  });
  const formProps = { register, errors, control, watch, setValue };

  const [isSaving, setIsSaving] = useState(false);
  const [showHiringProcess, setShowHiringProcess] = useState(false);
  const [showReferrerEmail, setShowReferrerEmail] = useState(false);
  const [showTechnicalInterviewDate, setShowTechnicalInterviewDate] =
    useState(false);

  const step = watch("step");

  const handleClose = () => {
    setShowHiringProcess(false);
    setShowPosition(false);
    setShowReferrerEmail(false);
    reset(formInitialState);
    props.onClose?.();
  };

  const onSubmit = async (formValues, options) => {
    if (isValid) {
      try {
        setIsSaving(true);
        const candidate = { ...formValues };
        const tags = candidate.tagSelect?.map((tag) => tag._id);

        candidate.tags = tags;
        delete candidate.tagSelect;

        if (!formValues.phone) {
          delete candidate.callingCode;
          delete candidate.phone;
        }

        if (isExternalRecruiter) candidate.source = micoach.SourceEnum.GatherHr;

        // Create the AP (If required)
        if (showHiringProcess || isExternalRecruiter) {
          candidate.status = formValues.step;
          candidate.applicationProcess = {
            steps: [{ step: formValues.step }],
            role: formValues.role,
            position: formValues.positionId,
          };

          if (formValues.technicalInterviewAt) {
            candidate.applicationProcess.technicalInterviewAt = getIsoStartDate(
              formValues.technicalInterviewAt
            );
          }
        }

        const { data: savedCandidate } =
          await CandidateApi.createCandidate(candidate);

        setShowHiringProcess(false);
        setShowPosition(false);
        setShowTechnicalInterviewDate(false);
        setShowReferrerEmail(false);

        toast.success("Success! The candidate has been added.");
        reset(formInitialState);
        props.onSaved?.(savedCandidate, options);
      } catch (error) {
        console.error(error);

        const status = error.response.status;
        const data = error.response.data;

        if (status === 409 && data.error === CANDIDATE_EMAIL_DUPLICATED) {
          toast.info(
            `Error, email "${formValues.email}" is used by another account, review the spelling.`
          );
        } else {
          toast.error("Error, please try again.");
        }
      } finally {
        setIsSaving(false);
      }
    }
  };

  // Roles
  const { roles, loadingRoles } = useRoles();
  const rolesOptions = roles.map((role) => {
    return {
      value: role.name,
      label: role.name,
    };
  });

  // Positions
  const [showPosition, setShowPosition] = useState(false);
  const [loadingPositions, positions, setQueryPositionParams] =
    useAvailablePositions();
  const positionsOptions = (inputValue) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        setQueryPositionParams({ search: inputValue, limit: 10 });
        const newValues = positions.map((position) => {
          return {
            value: position._id,
            label: `${position.title} - ${position.company?.name ?? ""}`,
          };
        });
        resolve(newValues);
      }, 100);
    });
  };

  // Step
  const stepsOptions = applicationProcessSteps.filter((step) => {
    return (
      step.value !== "BLOCKED" &&
      step.value !== "LEADS" &&
      step.value !== "DISCARDED" &&
      step.value !== "HIRED"
    );
  });

  const handleChangeStep = useCallback(
    (option) => {
      if (!option || isEarlyStep(option)) {
        setShowPosition(false);
        setShowTechnicalInterviewDate(false);
        unregister("positionId");
        unregister("technicalInterviewAt");
      } else {
        setShowPosition(true);
        setShowTechnicalInterviewDate(true);
      }
    },
    [unregister]
  );

  // Source
  const sourcesOptions = sources.map((source) => {
    return {
      value: source.key,
      label: source.name,
    };
  });

  const handleChangeSource = (option) => {
    const isReferral = option?.value === micoach.SourceEnum.Referral;

    setShowReferrerEmail(isReferral);

    if (!isReferral) {
      unregister("referrerEmail");
    }
  };

  // Get countries as options for address custom select
  const countriesOptions = countries.map((country) => {
    return {
      value: country.isoCode,
      label: country.name,
    };
  });

  const handleClickHiringProcess = () => {
    setShowHiringProcess(!showHiringProcess);
    handleChangeStep();
  };

  useEffect(() => {
    if (!showHiringProcess) {
      unregister("step");
      unregister("role");
    }
  }, [showHiringProcess, register, unregister]);

  useEffect(() => {
    handleChangeStep(step);
  }, [step, handleChangeStep]);

  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Form
        onSubmit={handleSubmit((formValues) =>
          onSubmit(formValues, { addDocuments: false })
        )}
        noValidate
      >
        <Modal.Header closeButton>
          <Modal.Title>Add candidate</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group controlId="name" className="form-group required">
            <Form.Label>Candidate name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              {...register("name", { required: true })}
              isInvalid={!!errors.name}
            />
            {errors.name && (
              <div className="invalid-feedback d-block">
                {errors.name.type === "required" &&
                  "Candidate name is required"}
              </div>
            )}
          </Form.Group>

          <Form.Group controlId="email" className="form-group required">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              {...register("email", { pattern: EMAIL_PATTERN, required: true })}
              isInvalid={!!errors.email}
            />
            {errors.email && (
              <div className="invalid-feedback d-block">
                {errors.email.type === "pattern" &&
                  "Candidate email must be a valid email"}
                {errors.email.type === "required" &&
                  "Candidate email is required"}
              </div>
            )}
          </Form.Group>

          <Form.Group controlId="phone" className="form-group">
            <PhoneNumberInput formProps={formProps} />
          </Form.Group>

          {!isExternalRecruiter && (
            <Form.Group controlId="source" className="form-group required">
              <Form.Label>Source</Form.Label>
              <CustomSelect
                name="source"
                control={control}
                isRequired={true}
                isInvalid={!!errors.source}
                options={sourcesOptions}
                onChange={handleChangeSource}
              />
              {errors.source && (
                <div className="invalid-feedback d-block">
                  {errors.source.type === "required" && "Source is required"}
                </div>
              )}
            </Form.Group>
          )}

          {showReferrerEmail && (
            <Form.Group
              controlId="referrerEmail"
              className="form-group required"
            >
              <Form.Label>Referrer email</Form.Label>
              <Form.Control
                type="email"
                name="referrerEmail"
                {...register("referrerEmail", {
                  pattern: EMAIL_PATTERN,
                  required: true,
                })}
                isInvalid={!!errors.referrerEmail}
              />
              {errors.referrerEmail && (
                <div className="invalid-feedback d-block">
                  {errors.referrerEmail.type === "pattern" &&
                    "Referrer email must be a valid email"}
                  {errors.referrerEmail.type === "required" &&
                    "Referrer email is required"}
                </div>
              )}
            </Form.Group>
          )}

          <Form.Group controlId="address.country" className="form-group">
            <Form.Label>Country</Form.Label>
            <CustomSelect
              name="address.country"
              control={control}
              options={countriesOptions}
              defaultValue={countriesOptions.find(
                (country) => country.value === "MEX"
              )}
            />
          </Form.Group>

          <TagSelectInput name="tagSelect" isRequired formProps={formProps} />

          <Form.Group controlId="isTrainee" className="form-group required">
            <Form.Label>Trainee</Form.Label>
            <div className="d-block">
              <Form.Check
                type="radio"
                name="isTrainee"
                id="isTraineeYes"
                value={true}
                inline
                label="Yes"
                {...register("isTrainee", { required: true })}
                isInvalid={!!errors.isTrainee}
              />
              <Form.Check
                type="radio"
                name="isTrainee"
                id="isTraineeNo"
                value={false}
                inline
                defaultChecked={true}
                label="No"
                {...register("isTrainee", { required: true })}
                isInvalid={!!errors.isTrainee}
              />
              {errors.isTrainee && (
                <div className="invalid-feedback d-block">
                  {errors.isTrainee.type === "required" &&
                    "This field is required."}
                </div>
              )}
            </div>
          </Form.Group>
          {!isExternalRecruiter && (
            <>
              <FontAwesomeIcon icon={faBriefcase} className="PositionIcon" />
              <Button
                variant="link"
                onClick={handleClickHiringProcess}
                className="PositionLink"
              >
                {showHiringProcess
                  ? "Cancel Hiring Process"
                  : "Start Hiring Process"}
              </Button>
            </>
          )}

          {(showHiringProcess || isExternalRecruiter) && (
            <>
              <Form.Group controlId="step" className="form-group required">
                <Form.Label>Hiring step process</Form.Label>
                <CustomSelect
                  name="step"
                  control={control}
                  isRequired
                  isInvalid={!!errors.step}
                  options={stepsOptions}
                  defaultValue={stepsOptions[0]}
                />
                {errors.step && (
                  <div className="invalid-feedback d-block">
                    {errors.step.type === "required" && "The step is required"}
                  </div>
                )}
              </Form.Group>

              <Form.Group controlId="role" className="form-group required">
                <Form.Label>Search role</Form.Label>
                <CustomSelect
                  name="role"
                  control={control}
                  isRequired
                  isInvalid={!!errors.role}
                  options={rolesOptions}
                  loading={loadingRoles}
                  placeholder="Start typing to search in roles..."
                />
                {errors.role && (
                  <div className="invalid-feedback d-block">
                    {errors.role.type === "required" && "The role is required"}
                  </div>
                )}
              </Form.Group>

              {showPosition && (
                <Form.Group
                  controlId="positionId"
                  className="form-group required"
                >
                  <Form.Label>Search position</Form.Label>
                  <CustomSelect
                    name="positionId"
                    control={control}
                    isAsync
                    isRequired
                    isInvalid={!!errors.positionId}
                    defaultOptions
                    options={positionsOptions}
                    loading={loadingPositions}
                    placeholder="Start typing to search in positions..."
                  />
                  {errors.positionId && (
                    <div className="invalid-feedback d-block">
                      {errors.positionId.type === "required" &&
                        "The position is required"}
                    </div>
                  )}
                </Form.Group>
              )}

              {showTechnicalInterviewDate && (
                <Form.Group
                  controlId="technicalInterviewAt"
                  className="form-group required"
                >
                  <Form.Label>Technical interview date</Form.Label>
                  <Controller
                    name="technicalInterviewAt"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        showPopperArrow={false}
                        selected={value}
                        onChange={onChange}
                        dateFormat="MMM dd, yyyy"
                        className={`form-control ${
                          errors.technicalInterviewAt ? "is-invalid" : ""
                        }`}
                      />
                    )}
                  />
                  {errors.technicalInterviewAt && (
                    <div className="invalid-feedback d-block">
                      {errors.technicalInterviewAt.type === "required" &&
                        "Technical interview date is required"}
                    </div>
                  )}
                </Form.Group>
              )}
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="CancelButton"
            disabled={isSaving}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="SaveButton"
            disabled={!isValid || isSaving}
          >
            Create
          </Button>

          <Button
            type="button"
            disabled={!isValid || isSaving}
            onClick={handleSubmit((formValues) =>
              onSubmit(formValues, { addDocuments: true })
            )}
          >
            Create &#38; add documents
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

CandidateAddModal.propTypes = {
  show: PropTypes.bool,
  isExternalRecruiter: PropTypes.bool,
  onClose: PropTypes.func,
  onSaved: PropTypes.func,
};

export default CandidateAddModal;

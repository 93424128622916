import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import micoach from "micoach-api";

import CommentsButton from "Components/Common/CommentsButton";
import TaskStatus from "Components/Task/TaskStatus";
import { getFormattedLocalDate } from "utils.js";

import styles from "Components/Task/styles/TaskCard.module.css";

const TaskCard = (props) => {
  const { task, selected, isClickable } = props;

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");

  useEffect(() => {
    switch (task.type) {
      case micoach.TaskTypeEnum.Watch:
        setTitle("Watch suggested training");
        break;
      case micoach.TaskTypeEnum.General:
        setTitle("Assigned task");
        break;
      default:
        setTitle("Unknown task");
    }

    switch (task.entity) {
      case micoach.TaskEntityEnum.Event:
        setSubtitle(task.event?.name);
        break;
      case micoach.TaskEntityEnum.Lecture:
        setSubtitle(task.lecture?.name);
        break;
      default:
        setSubtitle(task?.title);
    }
  }, [task]);

  return (
    <Card
      className={`Card ${styles.TaskCard} ${selected ? styles.Selected : ""} ${
        isClickable ? styles.Pointer : ""
      }`}
      onClick={isClickable ? () => props.onClick?.(task) : undefined}
    >
      <Card.Body>
        <div className={styles.Header}>
          <TaskStatus
            read={task.read}
            status={task.status}
            selected={selected}
          />
          {task.type === micoach.TaskTypeEnum.General && (
            <CommentsButton
              hasNewComments={task.hasNewCoachComments}
              tooltipMessage="Task interaction available"
            />
          )}
        </div>

        <Card.Title className={styles.Title}>{title}</Card.Title>
        <Card.Text className={styles.Subtitle}>{subtitle}</Card.Text>
        <section className={styles.Dates}>
          <span>{`Created on: ${getFormattedLocalDate(task.createdAt)}`}</span>
        </section>
      </Card.Body>
    </Card>
  );
};

TaskCard.propTypes = {
  task: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  isClickable: PropTypes.bool,
  onClick: PropTypes.func,
};

TaskCard.defaultProps = {
  isClickable: true,
};

export default TaskCard;

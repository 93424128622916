import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ListGroup from "react-bootstrap/ListGroup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BackendService from "../Services/BackendService";

import "./styles/Comments.css";

import Comment from "./Comment";
import Loading from "./Loading";

function Comments(props) {
  const { isEditable } = props;
  const [comments, setComments] = useState(props.comments || []);
  // Add form
  const [isSaving, setIsSaving] = useState(false);
  const { keycloak } = useKeycloak();
  const { register, handleSubmit, reset } = useForm();

  const handleSubmitComment = async (formValues) => {
    try {
      setIsSaving(true);

      const appIdOrCandidateId = () => {
        const id = {};
        if (props.applicationProcessId) {
          id.applicationProcessId = props.applicationProcessId;
        } else if (props.candidateId) {
          id.candidateId = props.candidateId;
        }
        return id;
      };

      let comment = {
        metadata: {
          ...appIdOrCandidateId(),
          step: props.stepName,
        },
        text: formValues.comment,
      };

      comment = await BackendService.saveComment(comment);

      const newComments = [...comments, comment];
      setComments(newComments);
      props.onChange(newComments);
      reset();
    } catch {
      toast.error("Error! The comment was not added.");
    } finally {
      setIsSaving(false);
    }
  };

  // Child events
  const handleUpdatedComment = (updatedComment) => {
    const index = comments.findIndex(
      (comment) => comment._id === updatedComment._id
    );

    const newComments = [...comments];
    newComments[index] = updatedComment;
    setComments(newComments);
  };

  const handleDeletedComment = (commentId) => {
    const newComments = comments.filter((comment) => comment._id !== commentId);
    setComments(newComments);
    props.onChange(newComments);
  };

  // Show all / less comments
  const COMMENTS_TO_SHOW = 5;
  const [isShowingAllComments, setIsShowingAllComments] = useState(false);

  const getCommentsToShow = () => {
    return comments.length < COMMENTS_TO_SHOW || isShowingAllComments
      ? comments
      : comments.slice(-COMMENTS_TO_SHOW);
  };

  return (
    <div className="Comments">
      {/* TODO: Add vertical line with dots */}
      <ListGroup variant="flush">
        {getCommentsToShow().map((comment) => (
          <Comment
            key={comment._id}
            comment={comment}
            userId={keycloak.subject}
            isEditable={isEditable}
            onUpdated={handleUpdatedComment}
            onDeleted={handleDeletedComment}
          />
        ))}
      </ListGroup>

      {isSaving && (
        <div className="text-center">
          <Loading />
        </div>
      )}

      {comments.length > COMMENTS_TO_SHOW && (
        <Button
          variant="link"
          className="w-100 mb-3 fs-13 text-center"
          onClick={() => setIsShowingAllComments(!isShowingAllComments)}
        >
          {isShowingAllComments
            ? "View less"
            : `View all ${comments.length} comments`}
        </Button>
      )}

      {isEditable && (
        <Form onSubmit={handleSubmit(handleSubmitComment)} className="mb-3">
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faCommentAlt} />
            </InputGroup.Text>
            <Form.Control
              type="text"
              name="comment"
              {...register("comment", { required: true })}
              disabled={isSaving}
            />
          </InputGroup>
        </Form>
      )}
    </div>
  );
}

Comments.propTypes = {
  applicationProcessId: PropTypes.string,
  candidateId: PropTypes.string,
  stepName: PropTypes.string.isRequired,
  comments: PropTypes.array.isRequired,
  isEditable: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Comments;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ReferralStatusEnum } from "@micoach/js-sdk";
import { ReferApi } from "micoach-api";

import FormControlErrorMessage from "Components/Common/FormControlErrorMessage";

const ReferralPaymentConfirmationModal = (props) => {
  const { show, referralId, onClose, onSaved } = props;

  const [isSaving, setIsSaving] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (formValues) => {
    try {
      setIsSaving(true);

      const { data: updatedReferral } = await ReferApi.updateReferralStatus(
        referralId,
        {
          reward: formValues.reward,
          status: ReferralStatusEnum.Claimed,
        }
      );

      toast.success("Success! The referral payment has been registered");

      onSaved?.(updatedReferral);
      onClose();
    } catch (error) {
      const status = error.response?.status;
      const errorMessage = error.response?.data?.errors?.[0]?.message;

      if (errorMessage) {
        toast.error(errorMessage);
      } else if (status === 404) {
        toast.error("Referral not found.");
      } else {
        toast.error("Error, please try again.");
      }

      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    reset();
  }, [show, reset]);

  return (
    <Modal show={show} centered onHide={onClose} backdrop="static">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Update referrer payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pb-3 fs-14">
            Set the amount or reward you stablished to thank the referral for
            their information. When you confirmed the reward, this information
            will be updated also for the referrer.
          </div>
          <Form.Group controlId="reward" className="required">
            <Form.Label>Reward</Form.Label>
            <Form.Control
              type="text"
              {...register("reward", { required: true })}
              isInvalid={!!errors.reward}
            />
            <FormControlErrorMessage
              show={errors.reward?.type === "required"}
              message="Reward is required"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="CancelButton"
            disabled={isSaving}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button type="submit" className="SaveButton" disabled={isSaving}>
            Confirm
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

ReferralPaymentConfirmationModal.propTypes = {
  show: PropTypes.bool,
  referralId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSaved: PropTypes.func,
};

ReferralPaymentConfirmationModal.defaultProps = {
  show: false,
};

export default ReferralPaymentConfirmationModal;

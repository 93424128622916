import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { SeniorityEnum } from "@micoach/js-sdk";
import { enumToSelectOptions, mapToSelectOptions } from "utils";

import FilterButton from "Components/Common/FilterButton";
import CustomSelect from "Components/CustomSelect";
import { positionStatus } from "constants.js";

import styles from "Components/Common/styles/Filters.module.css";

const JobPositionFilterButton = (props) => {
  const { filterValues, companies, onApplyFilters } = props;

  const [showPopover, setShowPopover] = useState(false);

  const { handleSubmit, reset, control } = useForm();

  const handleClickReset = () => {
    setShowPopover(false);

    onApplyFilters?.({});
  };

  const handleClickClose = () => {
    setShowPopover(false);
  };

  const handleClickApply = async (formValues) => {
    const { status, seniority, company } = formValues;
    const newFilters = {};

    if (status?.length) {
      newFilters.status = status.map((status) => {
        return { ...status, type: "status" };
      });
    }

    if (seniority?.length) {
      newFilters.seniority = seniority.map((seniority) => {
        return { ...seniority, type: "seniority" };
      });
    }

    if (company?.length) {
      newFilters.company = company.map((company) => {
        return { ...company, type: "company" };
      });
    }

    setShowPopover(false);
    onApplyFilters?.(newFilters);
  };

  useEffect(() => {
    reset({
      status: filterValues.status ?? [],
      company: filterValues.company ?? [],
      seniority: filterValues.seniority ?? [],
    });
  }, [filterValues, reset]);

  return (
    <FilterButton
      show={showPopover}
      setShowPopover={setShowPopover}
      onClickReset={handleClickReset}
    >
      <Form onSubmit={handleSubmit(handleClickApply)}>
        <Form.Group controlId="status" className="form-group">
          <Form.Label>Status</Form.Label>
          <CustomSelect
            isMulti
            name="status"
            defaultValue={filterValues?.status}
            control={control}
            options={positionStatus}
          />
        </Form.Group>
        <Form.Group controlId="company" className="form-group">
          <Form.Label>Company</Form.Label>
          <CustomSelect
            isMulti
            name="company"
            defaultValue={filterValues?.company}
            control={control}
            options={mapToSelectOptions(companies, "name", "name")}
          />
        </Form.Group>
        <Form.Group controlId="seniority" className="form-group">
          <Form.Label>Seniority</Form.Label>
          <CustomSelect
            isMulti
            name="seniority"
            defaultValue={filterValues?.seniority}
            control={control}
            options={enumToSelectOptions(SeniorityEnum)}
          />
        </Form.Group>
        <div className={styles.Buttons}>
          <Button variant="secondary" onClick={handleClickClose}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Apply
          </Button>
        </div>
      </Form>
    </FilterButton>
  );
};

JobPositionFilterButton.propTypes = {
  filterValues: PropTypes.shape({
    status: PropTypes.array,
    company: PropTypes.array,
    seniority: PropTypes.array,
  }),
  companies: PropTypes.array,
  onApplyFilters: PropTypes.func,
};

JobPositionFilterButton.defaultProps = {
  companies: [],
};

export default JobPositionFilterButton;

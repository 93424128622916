import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import micoach, { EvaluationApi } from "micoach-api";

import RecipientInputs from "Components/Evaluation/Skill/RecipientInputs";
import { MAX_EVALUATION_REQUESTS, MIN_EVALUATION_REQUESTS } from "constants.js";

import styles from "Components/Evaluation/Skill/styles/RequestAssessmentModal.module.css";

const RequestAssessmentModal = (props) => {
  const { show, evaluationRequests, userEmail, coaches } = props;
  const [numRecipients, setNumRecipients] = useState(1);
  const [isSending, setIsSending] = useState();
  const [hideAddMore, setHideAddMore] = useState();
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    register,
    reset,
  } = useForm({
    mode: "onChange",
  });

  const formProps = { register, errors, control };

  useEffect(() => {
    if (
      numRecipients >= MAX_EVALUATION_REQUESTS ||
      numRecipients >= MAX_EVALUATION_REQUESTS - evaluationRequests.length
    ) {
      setHideAddMore(true);
    } else {
      setHideAddMore(false);
    }
  }, [numRecipients, evaluationRequests]);

  const handleClose = () => {
    props.onClose();
    reset();
    setNumRecipients(1);
  };

  const onSubmit = async (formValues) => {
    if (!isValid) {
      toast.error("Error! Please check the form data.");
      return;
    }

    if (isEvaluatorEmailUserEmail(formValues)) {
      toast.error("Error! You can not send a request to your own email.");
      return;
    }

    if (isMoreThanOneCoachSelected(formValues)) {
      toast.error("Error! You can only select one Coach.");
      return;
    }

    if (!isAuthorizedCoachEmail(formValues)) {
      toast.error("Error! The email of your coach is not authorized.");
      return;
    }

    const duplicateEmailExist = checkDuplicateEmails(formValues);
    if (duplicateEmailExist) {
      toast.error("Error! Each recipient email must be unique.");
      return;
    }

    setIsSending(true);
    const requests = [];
    for (let i = 1; i < numRecipients + 1; i++) {
      const recipient = {
        evaluatorEmail: formValues["email" + i],
        evaluatorRelation: formValues["relationship" + i],
      };
      requests.push(recipient);
    }
    const data = {
      name: "Skills assessment",
      type: micoach.EvaluationTypeEnum.Skills,
      requests,
    };
    try {
      const { data: newEvaluationRequests } =
        await EvaluationApi.createEvaluationsByRequest(data);
      props.updateEvaluationRequests(newEvaluationRequests);
      toast.success("Success! The requests have been sent.");
      handleClose();
    } catch (error) {
      toast.error("Error, please try again later.");
      console.error(error);
    } finally {
      setIsSending(false);
    }
  };

  const checkDuplicateEmails = (formValues) => {
    const emails = [];
    for (let i = 1; i < numRecipients + 1; i++) {
      emails.push(formValues["email" + i]);
    }
    const storeEmails = evaluationRequests.map(
      (evaluation) => evaluation.evaluatorEmail
    );
    const newEmails = [...emails, ...storeEmails];
    return new Set(newEmails).size !== newEmails.length;
  };

  const isEvaluatorEmailUserEmail = (formValues) => {
    for (let i = 1; i < numRecipients + 1; i++) {
      const isEmailEqual = formValues["email" + i] === userEmail;
      if (isEmailEqual) {
        return true;
      }
    }
  };

  const isMoreThanOneCoachSelected = (formValues) => {
    let numCoachs = 0;

    for (let i = 1; i < numRecipients + 1; i++) {
      const isCoach =
        formValues["relationship" + i] === micoach.EvaluatorRelationEnum.Coach;
      numCoachs += isCoach ? 1 : 0;
    }

    if (numCoachs > 1) return true;

    const numPrevCoachs = evaluationRequests.filter(
      (evaluation) =>
        evaluation.evaluatorRelation === micoach.EvaluatorRelationEnum.Coach
    ).length;

    return numCoachs === 1 && numPrevCoachs >= 1;
  };

  const isAuthorizedCoachEmail = (formValues) => {
    for (let i = 1; i < numRecipients + 1; i++) {
      const isCoach =
        formValues["relationship" + i] === micoach.EvaluatorRelationEnum.Coach;
      if (isCoach) {
        if (!coaches.some((coach) => formValues["email" + i] === coach.email)) {
          return false;
        }
      }
    }
    return true;
  };

  const renderRecipientItems = () => {
    const array = [];
    for (let i = 1; i < numRecipients + 1; i++) {
      array.push(
        <RecipientInputs key={i} recipientNumber={i} formProps={formProps} />
      );
    }
    return array;
  };

  const handleClickAddRecipient = () => {
    setNumRecipients((prevRecipients) => prevRecipients + 1);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Request skills assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className={styles.Description}>
            360 assessment of your skills, helps to validate your proficiency
            level and increases the assertiveness of job opportunities and
            content in our platform we&apos;ll present you.
          </p>
          <p className={styles.Description}>
            {`When requesting 360 assessment you need to add ${MIN_EVALUATION_REQUESTS} people minimum and ${MAX_EVALUATION_REQUESTS} maximum, indicating the job relationship. Results of assessments will be displayed after a minimum of three people have submited their answers. The frequence you can request an assessment to a person is every 3 months.`}
          </p>
          <div className={styles.RecipientFields}>{renderRecipientItems()}</div>
          <div className={styles.AddRecipientContainer}>
            <Button
              hidden={hideAddMore}
              variant="link"
              onClick={handleClickAddRecipient}
              className={styles.AddRecipientButton}
            >
              Add more
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={isSending}
          >
            Cancel
          </Button>
          <Button type="submit" disabled={isSending}>
            Send request
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

RequestAssessmentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  evaluationRequests: PropTypes.array,
  userEmail: PropTypes.string.isRequired,
  coaches: PropTypes.array.isRequired,
  updateEvaluationRequests: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default RequestAssessmentModal;

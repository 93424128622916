import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { faInfoCircle, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BackendService from "../Services/BackendService";
import { DOCUMENT_REQUEST_ORIGINS } from "constants.js";

import styles from "./styles/DocumentRequestModal.module.css";

import { getChecklistItemName } from "../utils";

function DocumentRequestModal(props) {
  const {
    show = false,
    candidateId,
    origin = DOCUMENT_REQUEST_ORIGINS.MANUAL_PROCESS,
    checklist = [],
    defaultCheckedItems = [],
    onClose,
  } = props;

  const [isSaving, setIsSaving] = useState(false);
  const [items, setItems] = useState([]);
  const [existsUploadedDocuments, setExistsUploadedDocuments] = useState(false);

  const { handleSubmit, register, reset } = useForm({
    mode: "onBlur",
  });

  const onSubmit = async (formValues) => {
    try {
      setIsSaving(true);
      const candidate = await BackendService.getCandidate(candidateId);

      if (!candidate?.email) {
        toast.warning(
          "Warning! The candidate does not have an email address assigned."
        );
        return;
      }

      const documents = Object.entries(formValues)
        .filter(([key, value]) => value)
        .map(([key]) => key);

      if (documents.length) {
        const body = {
          candidateId,
          documents,
          origin,
        };

        await BackendService.requestDocuments(body);
        toast.success("Success! An email has been sent to the candidate.");
        onClose?.();
      } else {
        toast.warning("Warning! You must select at least one document.");
      }
    } catch (error) {
      toast.error("Error! Unable to send email, please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const newItems = checklist.map((item) => {
      let label;
      if (item.uploaded) {
        label = (
          <span>
            <FontAwesomeIcon
              icon={faRedo}
              className="me-2"
              aria-label="Redo icon"
            />
            {getChecklistItemName(item.key)}
          </span>
        );
        setExistsUploadedDocuments(true);
      } else {
        label = getChecklistItemName(item.key);
      }

      const isChecked = defaultCheckedItems?.includes(item.key);

      return (
        <Col
          xs={6}
          className={`pb-3 fs-13 ${item.uploaded ? styles.Uploaded : ""}`}
          key={item.key}
        >
          <Form.Check
            name={item.key}
            type="checkbox"
            id={`request-${item.key}`}
            label={label}
            defaultChecked={isChecked}
            {...register(item.key)}
          />
        </Col>
      );
    });

    setItems(newItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklist, register]);

  useEffect(() => {
    if (!show) {
      reset();
    }
  }, [show, reset]);

  return (
    <Modal show={show} onHide={onClose} className={styles.DocumentRequestModal}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Request documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.Description}>
            <span>
              Select the required document(s) needed to continue with the hiring
              process
            </span>
            <Alert variant="light" className={styles.Info}>
              <FontAwesomeIcon icon={faInfoCircle} aria-label="Info icon" />
              <span>
                If an existent document is already available, it will be
                overwritten.
              </span>
            </Alert>
          </div>
          <Row>{items}</Row>
          <br />
          {existsUploadedDocuments && (
            <Alert variant="light" className={styles.Info}>
              <FontAwesomeIcon icon={faInfoCircle} aria-label="Info icon" />
              <span>
                By requesting, an automatic email will be sent. Cancel to abort
                the request process.
              </span>
            </Alert>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="CancelButton"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button type="submit" className="SaveButton" disabled={isSaving}>
            Request
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

DocumentRequestModal.propTypes = {
  show: PropTypes.bool,
  candidateId: PropTypes.string.isRequired,
  origin: PropTypes.oneOf(Object.values(DOCUMENT_REQUEST_ORIGINS)),
  checklist: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
    })
  ),
  defaultCheckedItems: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
};

export default DocumentRequestModal;

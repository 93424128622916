import Badge from "react-bootstrap/Badge";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useKeycloak } from "@react-keycloak/web";

import StateCard from "Components/Common/StateCard";
import CoachOverviewCard from "Components/Evaluation/Skill/CoachOverviewCard";
import SkillBalanceCard from "Components/Evaluation/Skill/SkillBalanceCard";
import Loading from "Components/Loading";
import { useQuery } from "Hooks/useQuery";
import { useSkillSummary } from "Hooks/useSkillEvaluation";
import { assets } from "constants.js";

const EvaluationSkillBalance = () => {
  const { keycloak } = useKeycloak();
  const userId = keycloak.tokenParsed.sub;
  const queryParams = useQuery();
  const quarter = queryParams.get("quarter");
  const year = queryParams.get("year");

  const { summary, loadingSummary } = useSkillSummary(userId, year, quarter);

  const isPeriodCompleted = summary?.evaluationPeriod?.completed;

  const balanceDate = {
    quarter,
    year,
  };

  if (loadingSummary) {
    return <Loading />;
  }

  if (!summary.userName) {
    return (
      <StateCard
        imageUrl={assets.ZERO_STATE}
        title="Couldn't find an skills assessment for the period!"
      />
    );
  }

  return (
    <>
      <div className="d-md-flex align-items-center ScreenTitle">
        <h1>Skills proficiency</h1>
        {isPeriodCompleted && (
          <Badge pill className="GreenBadge">
            Cycle closed
          </Badge>
        )}
      </div>
      <Row>
        <Col>
          <SkillBalanceCard balanceDate={balanceDate} summary={summary} />
        </Col>
        {summary.evaluationPeriod && (
          <Col sm={4}>
            <CoachOverviewCard evaluationPeriod={summary.evaluationPeriod} />
          </Col>
        )}
      </Row>
    </>
  );
};

export default EvaluationSkillBalance;

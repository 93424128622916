import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { mapToSelectOptions, sortByProperty } from "utils";

import FilterButton from "Components/Common/FilterButton";
import CustomSelect from "Components/CustomSelect";
import { PROSPECT_PROCESS_SOURCE_FLOWS, prospectStatus } from "constants.js";

import styles from "Components/Common/styles/Filters.module.css";

const ProspectFilterButton = (props) => {
  const {
    filterValues,
    companies,
    positions,
    recruiters,
    loading,
    onApplyFilters,
  } = props;

  const [showPopover, setShowPopover] = useState(false);

  const { handleSubmit, reset, control } = useForm();

  const handleClickReset = () => {
    setShowPopover(false);

    onApplyFilters?.({});
  };

  const handleClickApply = (formValues) => {
    const newFilters = {};

    Object.keys(formValues).forEach((key) => {
      if (formValues[key]?.length) {
        newFilters[key] = formValues[key].map((item) => ({
          ...item,
          type: key,
        }));
      }
    });

    setShowPopover(false);
    onApplyFilters?.(newFilters);
  };

  useEffect(() => {
    reset({
      recruiter: filterValues.recruiter ?? [],
      company: filterValues.company ?? [],
      position: filterValues.position ?? [],
      status: filterValues.status ?? [],
    });
  }, [filterValues, reset]);

  return (
    <FilterButton
      show={showPopover}
      setShowPopover={setShowPopover}
      onClickReset={handleClickReset}
    >
      <Form onSubmit={handleSubmit(handleClickApply)}>
        <Form.Group controlId="recruiter" className="form-group">
          <Form.Label>TA Member</Form.Label>
          <CustomSelect
            isMulti
            name="recruiter"
            defaultValue={filterValues?.recruiter}
            control={control}
            options={
              recruiters &&
              sortByProperty(
                mapToSelectOptions(recruiters, "_id", "name"),
                "label",
                "asc"
              )
            }
          />
        </Form.Group>
        <Form.Group controlId="company" className="form-group">
          <Form.Label>Client</Form.Label>
          <CustomSelect
            isMulti
            name="company"
            defaultValue={filterValues?.company}
            control={control}
            options={
              companies &&
              sortByProperty(
                mapToSelectOptions(companies, "_id", "name"),
                "label",
                "asc"
              )
            }
          />
        </Form.Group>
        <Form.Group controlId="position" className="form-group">
          <Form.Label>Position</Form.Label>
          <CustomSelect
            isMulti
            name="position"
            defaultValue={filterValues?.position}
            control={control}
            options={
              positions &&
              sortByProperty(
                mapToSelectOptions(positions, "_id", "title"),
                "label",
                "asc"
              )
            }
          />
        </Form.Group>
        <Form.Group controlId="sourceFlow" className="form-group">
          <Form.Label>Source Flow</Form.Label>
          <CustomSelect
            isMulti
            name="sourceFlow"
            defaultValue={filterValues?.sourceFlow}
            control={control}
            options={PROSPECT_PROCESS_SOURCE_FLOWS}
          />
        </Form.Group>
        <Form.Group controlId="status" className="form-group">
          <Form.Label>Status</Form.Label>
          <CustomSelect
            isMulti
            name="status"
            defaultValue={filterValues?.status}
            control={control}
            options={prospectStatus}
          />
        </Form.Group>
        <div className={styles.Buttons}>
          <Button variant="secondary" onClick={() => setShowPopover(false)}>
            Close
          </Button>
          <Button disabled={loading} variant="primary" type="submit">
            Apply
          </Button>
        </div>
      </Form>
    </FilterButton>
  );
};

ProspectFilterButton.propTypes = {
  filterValues: PropTypes.shape({
    recruiter: PropTypes.array,
    company: PropTypes.array,
    position: PropTypes.array,
    status: PropTypes.array,
    sourceFlow: PropTypes.array,
  }),
  recruiters: PropTypes.array,
  companies: PropTypes.array,
  positions: PropTypes.array,
  loading: PropTypes.bool,
  onApplyFilters: PropTypes.func,
};

ProspectFilterButton.defaultProps = {
  filterValues: {},
  loading: false,
  recruiter: [],
  companies: [],
  positions: [],
};

export default ProspectFilterButton;

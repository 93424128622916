import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { sortByProperty } from "utils.js";

import styles from "./styles/SortButton.module.css";

const SortButton = (props) => {
  const { items, property } = props;
  const [orderAsc, setOrderAsc] = useState(false);
  const [initialValue, setInitialValue] = useState(true);

  const hasProperty = items.every((element) =>
    // eslint-disable-next-line no-prototype-builtins
    element.hasOwnProperty(property)
  );

  if (!hasProperty) return null;

  const handleChangeOrder = () => {
    const sortedItems = [...items];
    const newOrderAsc = !orderAsc;
    const sortOrder = newOrderAsc ? "asc" : "desc";

    setInitialValue(false);
    setOrderAsc(newOrderAsc);
    sortByProperty(sortedItems, property, sortOrder);
    props.onClick(sortedItems, sortOrder);
  };

  return (
    <div>
      <span className={styles.Title}>
        Sort by {initialValue ? "" : orderAsc ? "Oldest" : "Newest"}
      </span>
      <Button variant="link" onClick={() => handleChangeOrder()}>
        <FontAwesomeIcon
          icon={initialValue ? faSort : orderAsc ? faSortDown : faSortUp}
          size="lg"
          className={`${styles.Icon} ${
            !initialValue ? (orderAsc ? styles.Asc : styles.Desc) : ""
          }`}
        />
      </Button>
    </div>
  );
};

SortButton.propTypes = {
  items: PropTypes.array.isRequired,
  property: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

SortButton.defaultProps = {
  items: [],
};

export default SortButton;

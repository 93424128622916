import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import SkillFiltersForm from "Components/Admin/Skills/SkillFiltersForm";
import SkillModal from "Components/Admin/Skills/SkillModal";
import SkillTable from "Components/Admin/Skills/SkillTable";
import GoBackButton from "Components/GoBackButton";
import { usePublicRoles } from "Hooks/useRoles";
import { useSkills } from "Hooks/useSkills";

const Skills = () => {
  const [skillsWithRoles, setSkillsWithRoles] = useState([]);
  const [filters, setFilters] = useState({});
  const [filteredSkills, setFilteredSkills] = useState([]);
  const [resetPagination, setResetPagination] = useState(false);
  const [showSkillModal, setShowSkillModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [skill, setSkill] = useState();

  const { skills, loadSkills, loadingSkills } = useSkills();
  const { roles, loadingRoles } = usePublicRoles(true);

  const getSkillWithRoles = useCallback((skill, roles) => {
    const newSkill = { ...skill, roles: [] };
    for (const role of roles) {
      if (role.skills?.some((skillRole) => skillRole._id === skill._id)) {
        newSkill.roles.push({ _id: role._id, name: role.name });
      }
    }
    return newSkill;
  }, []);

  const getSkillsWithRoles = useCallback(
    (skills, roles) => {
      return skills.map((skill) => getSkillWithRoles(skill, roles));
    },
    [getSkillWithRoles]
  );

  const getFilteredSkills = (skills, filters) => {
    let newSkills = [...skills];

    if (filters.category) {
      newSkills = newSkills.filter((skill) =>
        filters.category.includes(skill.category)
      );
    }

    if (filters.role) {
      newSkills = newSkills.filter((skill) =>
        skill.roles?.some((skillRole) => skillRole._id === filters.role)
      );
    }

    return newSkills;
  };

  const createProficiencyLevels = () => {
    return [
      { level: 1, levelText: "Beginner", description: "" },
      { level: 2, levelText: "Practitioner", description: "" },
      { level: 3, levelText: "Specialist", description: "" },
      { level: 4, levelText: "Expert", description: "" },
    ];
  };

  const handleSubmitFilters = (filters) => {
    setFilters(filters);
    setResetPagination(!resetPagination);
  };

  const handleAddSkill = () => {
    setSkill({
      name: "",
      description: "",
      proficiencyLevels: createProficiencyLevels(),
    });
    setIsEditing(false);
    setShowSkillModal(true);
  };

  const handleEditSkill = (skill) => {
    const editableSkill = { ...skill };
    if (!skill.proficiencyLevels?.length) {
      editableSkill.proficiencyLevels = createProficiencyLevels();
    }
    setSkill(editableSkill);
    setIsEditing(true);
    setShowSkillModal(true);
  };

  const handleSavedSkill = (skill) => {
    /* TODO: I tried to make the UI optimistic, but due to the filters and the state update order 
    there are some things to consider, as a temporary solution I reload all the skills. */
    loadSkills();
  };

  useEffect(() => {
    if (skills.length && roles.length) {
      setSkillsWithRoles(getSkillsWithRoles(skills, roles));
    }
  }, [skills, roles, getSkillsWithRoles]);

  useEffect(() => {
    if (skillsWithRoles.length) {
      setFilteredSkills(getFilteredSkills(skillsWithRoles, filters));
    }
  }, [skillsWithRoles, filters]);

  return (
    <div className="Skills">
      <GoBackButton label="Admin" path="/admin" />

      <Row>
        <Col>
          <h1 className="ScreenTitle">Skills</h1>
        </Col>
        <Col className="text-end">
          <Button
            variant="secondary"
            className="AddButton"
            onClick={handleAddSkill}
          >
            Create Skill
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <Card className="Card">
            <Card.Body>
              <Card.Title className="Title">Filters</Card.Title>
              <SkillFiltersForm roles={roles} onSubmit={handleSubmitFilters} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={9}>
          <Card className="Card">
            <Card.Body>
              <SkillTable
                skills={filteredSkills}
                loading={loadingRoles || loadingSkills}
                resetPagination={resetPagination}
                onEdit={handleEditSkill}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <SkillModal
        show={showSkillModal}
        skill={skill}
        isEditing={isEditing}
        onSaved={handleSavedSkill}
        onClose={() => setShowSkillModal(false)}
      />
    </div>
  );
};

export default Skills;

import { useCallback, useEffect, useState } from "react";

import BackendService from "../Services/BackendService";

export function useIndustries() {
  const [industries, setIndustries] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadIndustries = useCallback(async () => {
    setLoading(true);
    const data = await BackendService.fetchIndustries();
    setIndustries(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    loadIndustries();
  }, [loadIndustries]);

  return [loading, industries, loadIndustries];
}

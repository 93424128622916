import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import micoach from "micoach-api";

const TaskDetailCardTitle = (props) => {
  const { type, lecture, event, title } = props;

  const getTaskTitle = (type, lecture, event, title) => {
    let taskTitle;
    if (type === micoach.TaskTypeEnum.Watch) {
      const taskName = lecture?.name ?? event?.name;
      taskTitle = `Watch the ${taskName} training`;
    } else if (type === micoach.TaskTypeEnum.General) {
      taskTitle = title;
    }

    return taskTitle;
  };

  return (
    <div>
      <Card.Subtitle>miCoach</Card.Subtitle>
      <Card.Title data-testid="taskName">
        {getTaskTitle(type, lecture, event, title)}
      </Card.Title>
    </div>
  );
};

TaskDetailCardTitle.propTypes = {
  type: PropTypes.string,
  lecture: PropTypes.object,
  event: PropTypes.object,
  title: PropTypes.string,
};

export default TaskDetailCardTitle;

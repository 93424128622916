import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { RoleApi } from "micoach-api";

import { DUPLICATED } from "error-constants.js";

const RoleModal = (props) => {
  const { show, isEditing, role } = props;

  const [isSaving, setIsSaving] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
  });

  const handleClose = () => {
    props.onClose?.();
  };

  const onSubmit = async (formValues) => {
    try {
      setIsSaving(true);

      if (isEditing) {
        const { data: updatedRole } = await RoleApi.updateRole(role._id, {
          name: formValues.name,
          description: formValues.description,
        });
        props.onUpdated?.(updatedRole);
        toast.success("Success! The role has been updated.");
      } else {
        const { data: newRole } = await RoleApi.createRole({
          name: formValues.name,
          description: formValues.description ?? "",
        });
        props.onSaved?.(newRole);
        toast.success("Success! The role has been added.");
      }
    } catch (error) {
      const { status, data } = error.response;

      if (status === 409 && data.error === DUPLICATED) {
        toast.info(`Error, role "${formValues.name}" is already been added.`);
      } else {
        toast.error("Error, please try again.");
      }
    } finally {
      setIsSaving(false);
      handleClose();
    }
  };

  useEffect(() => {
    if (show) {
      reset({
        name: isEditing ? role.name : "",
        description: isEditing ? role.description : "",
      });
    }
  }, [show, role, isEditing, reset]);

  return (
    <Modal className="RoleModal" show={show} onHide={handleClose}>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? "Edit" : "Create"} Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Add the name of the role you want to {isEditing ? "edit" : "create"}
            .
          </p>
          <Form.Group controlId="name" className="form-group required">
            <Form.Label>Role name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              disabled={isSaving}
              {...register("name", { required: true })}
              isInvalid={!!errors.name}
            />
            {errors.name && (
              <div className="invalid-feedback d-block">
                {errors.name.type === "required" && "Name is required"}
              </div>
            )}
          </Form.Group>
          <Form.Group controlId="description" className="form-group">
            <Form.Label>Role description</Form.Label>
            <Form.Control
              type="text"
              name="description"
              {...register("description")}
              disabled={isSaving}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="CancelButton"
            disabled={isSaving}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button type="submit" className="SaveButton" disabled={isSaving}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

RoleModal.propTypes = {
  role: PropTypes.object,
  show: PropTypes.bool,
  isEditing: PropTypes.bool,
  onUpdated: PropTypes.func,
  onSaved: PropTypes.func,
  onClose: PropTypes.func,
};

RoleModal.defaultProps = {
  show: false,
  isEditing: false,
};

export default RoleModal;

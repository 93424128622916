import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReferralStatusEnum } from "@micoach/js-sdk";
import { ReferApi } from "micoach-api";
import { addOrReplaceElementInArray } from "utils";

import ReferralHeader from "Components/Refer/ReferralHeader";
import ReferralModal from "Components/Refer/ReferralModal";
import ReferralProcessAccordion from "Components/Refer/ReferralProcessAccordion";
import ReferralsOverviewCard from "Components/Refer/ReferralsOverviewCard";
import { useJobs } from "Hooks/useJobs";
import { useUserReferrals } from "Hooks/useReferrals";
import { usePublicRoles } from "Hooks/useRoles";

import styles from "Screens/Refer/styles/ListMyReferrals.module.css";

const ListMyReferrals = () => {
  const [showReferModal, setShowReferModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { roles } = usePublicRoles();
  const { jobs, loadJobs } = useJobs();
  const {
    userReferrals,
    setUserReferrals,
    loadUserReferrals,
    loadingUserReferrals,
  } = useUserReferrals();

  const readyToClaim = userReferrals.filter(
    (referral) => referral.status === ReferralStatusEnum.ReadyToClaim
  ).length;

  const claimed = userReferrals.filter(
    (referral) => referral.status === ReferralStatusEnum.Claimed
  ).length;

  const referred = userReferrals.filter(
    (referral) => referral.status === ReferralStatusEnum.Referred
  ).length;

  const handleUpdateStatus = async (referralId, status) => {
    try {
      setIsSaving(true);

      const { data: updatedReferral } = await ReferApi.updateReferralStatus(
        referralId,
        {
          status,
        }
      );

      setUserReferrals(
        addOrReplaceElementInArray(userReferrals, updatedReferral)
      );

      toast.success("Success! Referral has been updated.");
    } catch (error) {
      const status = error.response?.status;
      const errorMessage = error.response?.data?.errors?.[0]?.message;

      if (status === 409 && errorMessage) {
        toast.error(errorMessage);
      } else if (status === 404) {
        toast.error("Referral not found.");
      } else {
        toast.error("Error, please try again.");
      }

      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    loadUserReferrals();
    loadJobs();
  }, [loadUserReferrals, loadJobs]);

  return (
    <>
      <div className={styles.Header}>
        <ReferralHeader />

        <Button
          variant="secondary"
          className={styles.ReferButton}
          onClick={() => setShowReferModal(true)}
        >
          <FontAwesomeIcon icon={faDollarSign} size="lg" /> Refer a friend
        </Button>
      </div>

      <ReferralProcessAccordion />

      <ReferralsOverviewCard
        summaryStatus={{
          claimed,
          readyToClaim,
          referred,
        }}
        referrals={userReferrals}
        loading={loadingUserReferrals}
        isSaving={isSaving}
        onUpdateStatus={handleUpdateStatus}
      />

      <ReferralModal
        show={showReferModal}
        roles={roles}
        jobPositions={jobs}
        isPublic={false}
        onClose={() => setShowReferModal(false)}
        onSaved={() => loadUserReferrals()}
      />
    </>
  );
};

export default ListMyReferrals;

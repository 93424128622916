import { useCallback, useEffect, useState } from "react";

import BackendService from "../Services/BackendService";

export function useApplicationProcess(id) {
  const [applicationProcess, setApplicationProcess] = useState(null);

  const loadApplicationProcess = useCallback(async () => {
    const data = await BackendService.getApplicationProcess(id);
    setApplicationProcess(data);
  }, [id]);

  useEffect(() => {
    loadApplicationProcess();
  }, [loadApplicationProcess]);

  return [applicationProcess, loadApplicationProcess];
}

export function useHistoricalApplicationProcesses(candidateId) {
  const [applicationProcesses, setApplicationProcesses] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadApplicationProcesses = useCallback(async () => {
    setLoading(true);
    const data = await BackendService.getApplicationProcesses(candidateId);
    setApplicationProcesses(data.filter((ap) => ap.status !== "ACTIVE"));
    setLoading(false);
  }, [candidateId]);

  useEffect(() => {
    loadApplicationProcesses();
  }, [loadApplicationProcesses]);

  return [loading, applicationProcesses, loadApplicationProcesses];
}

import PropTypes from "prop-types";

import JobsCard from "Components/Job/JobsCard";
import Loading from "Components/Loading";

import "Components/Job/styles/JobsByProfile.css";

const JobsByProfile = (props) => {
  const { jobs, loading } = props;

  const redirectToJobs = (jobSlug) => {
    window.location.href = `/jobs/${jobSlug}`;
  };

  return (
    <div className="JobsByProfile">
      <div className="Opportunities">
        <h2 className="Title">Job opportunities</h2>
        <a href="/jobs" className="SeeAll">
          See all
        </a>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <JobsCard jobs={jobs} setActiveJob={redirectToJobs} />
      )}
    </div>
  );
};

JobsByProfile.propTypes = {
  jobs: PropTypes.array,
  loading: PropTypes.bool,
};

export default JobsByProfile;

import { useState } from "react";
import Card from "react-bootstrap/Card";
import { useKeycloak } from "@react-keycloak/web";
import micoach from "micoach-api";

import StateCard from "Components/Common/StateCard";
import CoachSelect from "Components/Evaluation/Skill/CoachSelect";
import CompanySelect from "Components/Evaluation/Skill/CompanySelect";
import PerformanceEvaluationPeriodTable from "Components/Evaluation/Skill/PerformanceEvaluationPeriodTable";
import PeriodSelect from "Components/Evaluation/Skill/PeriodSelect";
import ReportFileSelect from "Components/Evaluation/Skill/ReportFileSelect";
import GoBackButton from "Components/GoBackButton";
import Loading from "Components/Loading";
import { useCompanies } from "Hooks/useCompanies";
import { usePerformanceEvaluationPeriod } from "Hooks/useEvaluationPeriod";
import { useCoaches } from "Hooks/useUsers";
import { assets } from "constants.js";
import { getQuarterYear, getRoles } from "utils.js";

const PerformanceEvaluationPeriod = () => {
  const { keycloak } = useKeycloak();
  const userRoles = getRoles(keycloak);

  const balanceDate = {
    year: new Date().getFullYear(),
    quarter: getQuarterYear(),
  };

  const [employer, setEmployer] = useState();
  const [coachEmail, setCoachEmail] = useState();
  const [selectedPeriod, setSelectedPeriod] = useState(balanceDate);

  const { roles, loadingPerformanceEvaluationPeriod } =
    usePerformanceEvaluationPeriod(
      selectedPeriod,
      "json",
      coachEmail,
      employer ? encodeURIComponent(employer) : undefined
    );

  const { coaches, loadingCoaches } = useCoaches();

  const handlePeriodChange = (option) => {
    const {
      value: { year, quarter },
    } = option;
    setSelectedPeriod({ year, quarter });
  };

  const [companies] = useCompanies();

  const handleChangeCompany = (option) => {
    setEmployer(option?.value);
  };

  const handleChangeCoach = (option) => {
    setCoachEmail(option?.value);
  };

  const hasRecruiterRole = userRoles?.includes(micoach.UserRoleEnum.Recruiter);

  const hasHrRole = userRoles?.includes(micoach.UserRoleEnum.HumanResources);

  const hasSiteLeaderRole = userRoles?.includes(
    micoach.UserRoleEnum.SiteLeader
  );

  return (
    <div className="PerformanceEvaluationPeriod">
      <GoBackButton />

      <div className="d-flex justify-content-between align-items-start ScreenTitle flex-wrap">
        <h1>Team performance</h1>
        <div className="d-flex">
          <ReportFileSelect
            quarter={selectedPeriod.quarter}
            year={selectedPeriod.year}
            coachEmail={coachEmail}
            employer={employer}
          />
          <div className="d-flex flex-column">
            <div style={{ minWidth: 200, margin: "4px 0" }}>
              <PeriodSelect
                balanceDate={balanceDate}
                onChange={handlePeriodChange}
              />
            </div>
            <div style={{ minWidth: 200, margin: "4px 0" }}>
              {hasHrRole && hasRecruiterRole && (
                <CompanySelect
                  companies={companies}
                  onChange={handleChangeCompany}
                />
              )}
            </div>
            {((hasHrRole && hasRecruiterRole) || hasSiteLeaderRole) &&
              (loadingCoaches ? (
                <Loading />
              ) : (
                <div className="mt-2">
                  <CoachSelect coaches={coaches} onChange={handleChangeCoach} />
                </div>
              ))}
          </div>
        </div>
      </div>

      <div>
        {loadingPerformanceEvaluationPeriod ? (
          <Loading />
        ) : !roles?.length ? (
          <StateCard
            imageUrl={assets.ZERO_STATE}
            title="Performance evaluation without results"
          />
        ) : (
          roles?.map((role) => {
            return (
              <Card className="Card CardShadow mb-4" key={role.role}>
                <Card.Body>
                  <PerformanceEvaluationPeriodTable
                    role={role.role}
                    users={role.users}
                  />
                </Card.Body>
              </Card>
            );
          })
        )}
      </div>
    </div>
  );
};

export default PerformanceEvaluationPeriod;

import { useState } from "react";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import CustomSelect from "Components/CustomSelect";
import { countries, PHONE_NUMBER_PATTERN } from "constants.js";

const PhoneNumberInput = (props) => {
  const {
    formProps: { register, errors, control, watch },
    userCallingCode,
    disabled,
    isRequired,
  } = props;

  // Get an array of calling code options
  const [callingCodeOptions] = useState(
    countries.map((country) => {
      return {
        value: country.callingCode,
        label: country.callingCode,
        flag: country.flag,
        isoCode: country.isoCode,
      };
    })
  );

  // Set profile calling code or default to one code if missing
  const getCallingCodeValue = (userCallingCode, callingCodes) => {
    if (!userCallingCode) {
      return callingCodes.find((option) => option.isoCode === "MEX");
    } else {
      return callingCodes.find((option) => option.value === userCallingCode);
    }
  };

  // Save the default calling code in a constant
  const defaultCallingCode = getCallingCodeValue(
    userCallingCode,
    callingCodeOptions
  );

  // Initialize calling code state
  const [callingCode, setCallingCode] = useState(defaultCallingCode);

  // watch phone field value
  const phoneValue = watch("phone");

  // CustomSelect onChangeHandler
  const handleChangeSelect = (option) => {
    setCallingCode(option?.value || "");
  };

  return (
    <>
      <Form.Label>Phone number</Form.Label>
      <Row>
        <Col className="CountriesCodes" xs={5} lg={4}>
          <CustomSelect
            name="callingCode"
            control={control}
            options={callingCodeOptions}
            onChange={handleChangeSelect}
            isRequired={phoneValue !== ""}
            isClearable={false}
            disabled={disabled}
            defaultValue={defaultCallingCode}
            formatOptionLabel={(option) => {
              return (
                <>
                  {option.isoCode}
                  <span className="text-muted ms-1">{option.label}</span>
                </>
              );
            }}
          />
          {phoneValue !== "" && callingCode === "" && (
            <div className="invalid-feedback d-block">
              Please select your country calling code.
            </div>
          )}
        </Col>

        <Col className="PhoneNumber" xs={7} lg={8}>
          <Form.Control
            name="phone"
            type="tel"
            isInvalid={!!errors.phone}
            {...register("phone", {
              pattern: PHONE_NUMBER_PATTERN,
              required: isRequired,
            })}
            disabled={disabled}
          />
          {errors.phone && (
            <div className="invalid-feedback d-block">
              {errors.phone.type === "required" && "Phone number is required"}
              {errors.phone.type === "pattern" &&
                "Must be a 10 digit phone number. Example: 1234567890."}
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

PhoneNumberInput.propTypes = {
  formProps: PropTypes.object.isRequired,
  userCallingCode: PropTypes.string,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
};

PhoneNumberInput.defaultProps = {
  disabled: false,
  isRequired: false,
};

export default PhoneNumberInput;

import { useKeycloak } from "@react-keycloak/web";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

import { API, CATALOG_TYPES } from "constants.js";
import { sendRequest } from "utils.js";

export const useTags = () => {
  const { keycloak } = useKeycloak();

  const {
    data: tags,
    isLoading,
    mutate,
  } = useSWR(
    API.PUBLIC_CATALOG,
    (path) =>
      sendRequest(path, {
        queryParams: { type: CATALOG_TYPES.TAG },
      }),
    {
      revalidateOnFocus: false,
      fallbackData: [],
    }
  );

  const { trigger: createTag, isMutating: isSaving } = useSWRMutation(
    API.CATALOG,
    (path, { arg }) =>
      sendRequest(path, {
        ...arg,
        method: "POST",
        accessToken: keycloak.token,
      })
  );

  const { trigger: updateTag, isMutating: isUpdating } = useSWRMutation(
    API.CATALOG,
    (path, { arg }) =>
      sendRequest(`${path}/${arg.pathParams._id}`, {
        ...arg,
        method: "PUT",
        accessToken: keycloak.token,
      })
  );

  return {
    tags,
    createTag,
    updateTag,
    isLoading,
    isSaving,
    isUpdating,
    mutate,
  };
};

import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ListGroup from "react-bootstrap/ListGroup";
import {
  faArrowRight,
  faBell,
  faEllipsisH,
  faEnvelope,
  faGraduationCap,
  faPaperclip,
  faUpload,
  faUserPlus,
  faUserTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import micoach, { NotificationApi } from "micoach-api";

import Restricted from "Components/Common/Restricted";
import FormattedDate from "Components/FormattedDate";
import SourceImage from "Components/SourceImage";
import StepBadge from "Components/StepBadge";
import UserImage from "Components/UserImage";
import { NotificationsContext } from "Context/NotificationsProvider";
import { useQuery } from "Hooks/useQuery";
import { kanbanColumns } from "constants.js";
import {
  filterCandidateStatus,
  getBooleanParamValue,
  getStepFromCandidateStatus,
} from "utils.js";

import "Components/styles/Card.css";
import "Components/styles/CandidateCard.css";

const CandidateCard = (props) => {
  const {
    candidate,
    step,
    userId,
    shadowed,
    showDropdownMenu,
    isExternalRecruiter,
    onClickHire,
    onClickDiscard,
    onClickMessage,
    onClickRequestDocuments,
    onClickMove,
  } = props;
  const [showMoveSteps, setShowMoveSteps] = useState(false);
  const [candidateStatusMenu, setCandidateStatusMenu] = useState([]);

  const queryParams = useQuery();

  const allColumns = getBooleanParamValue(
    queryParams.get("allColumns"),
    "allColumns"
  );

  const applicationProcess = candidate.applicationProcess;
  const application = candidate.application;
  let positionTitle;
  let companyName;
  let applicationDate;

  if (applicationProcess) {
    positionTitle = applicationProcess.position?.title;
    companyName = applicationProcess.position?.company?.name;
    applicationDate = applicationProcess.createdAt;
  } else if (application) {
    positionTitle = application.position?.title;
    companyName = application.position?.company?.name;
    applicationDate = application.createdAt;
  }

  const { notifications, reloadNotifications } =
    useContext(NotificationsContext);

  // Notifications

  const getUnreadNotifications = () => {
    return notifications.filter(
      (notification) =>
        !notification.read &&
        notification.metadata?.candidateId === candidate._id &&
        notification.source ===
          micoach.NotificationSourceEnum.CandidateUploadDocument
    );
  };

  const handleClickOpenCandidate = async () => {
    const notificationIds = getUnreadNotifications().map(
      (notification) => notification._id
    );

    // Update notification to be read
    try {
      await NotificationApi.updateNotifications({
        notificationIds,
        read: true,
      });
      reloadNotifications();
    } finally {
      window.open(`/candidate/view/${candidate._id}`);
    }
  };

  const hasNotification = () => {
    if (getUnreadNotifications().length) {
      return true;
    }
    return false;
  };

  // Styles
  const cardStyle = applicationProcess
    ? {
        borderLeft: `4px solid ${applicationProcess?.recruiterColor}`,
      }
    : {};

  useEffect(() => {
    if (candidate.status) {
      setCandidateStatusMenu(
        filterCandidateStatus({
          allColumns,
          isExternalRecruiter,
          currentStatus: candidate.status,
        })
      );
    }
  }, [candidate, allColumns, isExternalRecruiter]);

  return (
    <Card
      className={`Card ${shadowed ? "CardShadow" : ""} CandidateCard ${
        applicationProcess ? "ApplicationProcess" : ""
      }`}
      style={cardStyle}
    >
      <Card.Body>
        <section className="LeftSection">
          <div className="Header">
            {applicationProcess && (
              <UserImage
                userName={applicationProcess?.recruiterName}
                userImageUrl={applicationProcess?.recruiterImageUrl}
                userColor={applicationProcess?.recruiterColor}
              />
            )}
            <div
              className="RoleName"
              title={candidate.role || "No role assigned"}
            >
              {candidate.role || "No role assigned"}
            </div>
          </div>

          <div className="CandidateInfo">
            <Button
              variant="link"
              className="CandidateLink"
              onClick={handleClickOpenCandidate}
            >
              <SourceImage source={candidate.source} />
              {candidate.isTrainee && !application && (
                <FontAwesomeIcon
                  icon={faGraduationCap}
                  title="Trainee"
                  className="ms-1"
                />
              )}
              <div
                title={candidate.name}
                className={`CandidateName ${candidate.isTrainee && "Trainee"}`}
              >
                {candidate.name}
              </div>
            </Button>
          </div>

          <div className="PositionDetails">
            <ListGroup horizontal>
              {positionTitle && (
                <ListGroup.Item className="PositionTitle" title={positionTitle}>
                  {positionTitle}
                </ListGroup.Item>
              )}

              {companyName && (
                <ListGroup.Item title={companyName}>
                  {companyName}
                </ListGroup.Item>
              )}

              {applicationDate && (
                <ListGroup.Item className="ApplicationDate">
                  <FormattedDate variant="monthDay">
                    {applicationDate}
                  </FormattedDate>
                </ListGroup.Item>
              )}
            </ListGroup>
          </div>
        </section>

        <section className="RightSection">
          <div className="Actions">
            {showDropdownMenu && (
              <DropdownButton
                variant="link"
                title={
                  <FontAwesomeIcon
                    icon={faEllipsisH}
                    aria-label="ellipsis icon"
                  />
                }
                className="CandidateDropdown"
              >
                <Dropdown.Item
                  as="button"
                  eventKey="hireButton"
                  onClick={() => onClickHire?.()}
                >
                  <FontAwesomeIcon icon={faUserPlus} className="Icon me-2" />
                  Hire
                </Dropdown.Item>

                {candidate.applicationProcess.recruiter === userId && (
                  <Dropdown.Item
                    as="button"
                    eventKey="discardButton"
                    onClick={() => onClickDiscard?.()}
                  >
                    <FontAwesomeIcon icon={faUserTimes} className="Icon me-2" />
                    Reject
                  </Dropdown.Item>
                )}

                <Dropdown.Item
                  as="button"
                  eventKey="messageButton"
                  onClick={() => onClickMessage?.()}
                >
                  <FontAwesomeIcon icon={faEnvelope} className="Icon me-2" />
                  Message
                </Dropdown.Item>

                {step === kanbanColumns.OFFER_ACCEPTED.key && (
                  <Restricted roles={[micoach.UserRoleEnum.HumanResources]}>
                    <Dropdown.Item
                      as="button"
                      eventKey="requestDocumentsButton"
                      onClick={() => onClickRequestDocuments?.(candidate)}
                    >
                      <FontAwesomeIcon icon={faUpload} className="Icon me-2" />
                      Request documents
                    </Dropdown.Item>
                  </Restricted>
                )}

                <Dropdown.Item
                  onMouseEnter={() => setShowMoveSteps(true)}
                  onMouseLeave={() => setShowMoveSteps(false)}
                >
                  <DropdownButton
                    variant="link"
                    title={
                      <>
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          className="Icon me-2"
                        />
                        Move
                      </>
                    }
                    show={showMoveSteps}
                  >
                    {candidateStatusMenu.map((status) => {
                      return (
                        <Dropdown.Item
                          as="button"
                          eventKey={status}
                          key={status}
                          onClick={() => onClickMove(status)}
                        >
                          <StepBadge
                            step={getStepFromCandidateStatus(status)}
                          />
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                </Dropdown.Item>
              </DropdownButton>
            )}
          </div>

          <div className="Counters">
            <div className="Documents">
              {hasNotification() && (
                <span className="NotificationIcon" title="New documents">
                  <FontAwesomeIcon icon={faBell} />
                </span>
              )}
              <span id="TotalDocuments"> {candidate.totalDocuments ?? 0}</span>
              <span>
                <FontAwesomeIcon icon={faPaperclip} className="Icon" />
              </span>
            </div>
          </div>
        </section>
      </Card.Body>
    </Card>
  );
};

CandidateCard.propTypes = {
  candidate: PropTypes.object.isRequired,
  step: PropTypes.string.isRequired,
  shadowed: PropTypes.bool,
  userId: PropTypes.string.isRequired,
  showDropdownMenu: PropTypes.bool,
  isExternalRecruiter: PropTypes.bool,
  onClickHire: PropTypes.func,
  onClickDiscard: PropTypes.func,
  onClickMessage: PropTypes.func,
  onClickMove: PropTypes.func,
  onClickRequestDocuments: PropTypes.func,
};

CandidateCard.defaultProps = {
  shadowed: false,
  showDropdownMenu: false,
  isExternalRecruiter: false,
};

export default CandidateCard;

import { useCallback, useEffect, useState } from "react";
import { CatalogApi } from "micoach-api";

export function useCatalog(type) {
  const [catalog, setCatalog] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadCatalog = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await CatalogApi.listCatalog(type);
      setCatalog(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [type]);

  useEffect(() => {
    loadCatalog();
  }, [loadCatalog]);

  return { catalog, loading, loadCatalog };
}

import { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import TagAddEditModal from "Components/Admin/Tags/TagAddEditModal";
import TagTable from "Components/Admin/Tags/TagTable";
import GoBackButton from "Components/GoBackButton";
import SearchBar from "Components/SearchBar";
import { useTags } from "Hooks/useTags";

const Tags = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [tag, setTag] = useState();

  const { tags, isLoading, mutate } = useTags();

  const filteredTags = searchTerm
    ? tags.filter((tag) =>
        tag.label.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : tags;

  const handleClickCreateOrEdit = (tag = {}) => {
    setTag(tag);
    setShowModal(true);
  };

  return (
    <>
      <GoBackButton label="Admin" path="/admin" />

      <Row>
        <Col>
          <h1 className="ScreenTitle">Tags</h1>
        </Col>
        <Col className="text-end">
          <Button
            variant="secondary"
            className="AddButton"
            onClick={handleClickCreateOrEdit}
          >
            Create Tag
          </Button>
        </Col>
      </Row>

      <SearchBar onSearch={({ search }) => setSearchTerm(search)} />

      <Card className="Card">
        <Card.Body>
          <TagTable
            tags={filteredTags}
            loading={isLoading}
            onEdit={(tag) => handleClickCreateOrEdit(tag)}
          />
        </Card.Body>
      </Card>

      <TagAddEditModal
        tag={tag}
        show={showModal}
        onClose={() => setShowModal(false)}
        onSaved={mutate}
        onUpdated={mutate}
      />
    </>
  );
};

export default Tags;

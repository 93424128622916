import { useState } from "react";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { toast } from "react-toastify";
import {
  faDownload,
  faFileExcel,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import { EvaluationPeriodApi } from "micoach-api";

const ReportFileSelect = (props) => {
  const { quarter, year, coachEmail, employer } = props;

  const [downloadingFile, setDownloadingFile] = useState(false);

  if (!quarter && !year) {
    return null;
  }

  const handleClickDownloadPdf = async () => {
    setDownloadingFile(true);
    try {
      const { data } = await EvaluationPeriodApi.getPerformanceEvaluationPeriod(
        quarter,
        year,
        "pdf",
        coachEmail,
        employer ? encodeURIComponent(employer) : undefined,
        {
          responseType: "arraybuffer",
        }
      );

      const blob = new Blob([data]);
      const fileName = `Team Performance Q${quarter} - ${year}.pdf`;

      saveAs(blob, fileName);
    } catch (error) {
      toast.error("Error, please try again.");
    } finally {
      setDownloadingFile(false);
    }
  };

  const handleClickDownloadExcel = async () => {
    setDownloadingFile(true);
    try {
      const { data } = await EvaluationPeriodApi.getPerformanceEvaluationPeriod(
        quarter,
        year,
        "excel",
        coachEmail,
        employer ? encodeURIComponent(employer) : undefined,
        {
          responseType: "arraybuffer",
        }
      );

      const blob = new Blob([data]);
      const fileName = `Team Performance Q${quarter} - ${year}.xlsx`;

      saveAs(blob, fileName);
    } catch (error) {
      toast.error("Error, please try again.");
    } finally {
      setDownloadingFile(false);
    }
  };

  return (
    <div className="me-4">
      <DropdownButton
        variant="link"
        title={
          <div className="mt-2">
            <FontAwesomeIcon icon={faDownload} /> Download report
          </div>
        }
        disabled={downloadingFile}
      >
        <Dropdown.Item
          as="button"
          eventKey="savefilePdfButton"
          onClick={handleClickDownloadPdf}
        >
          <FontAwesomeIcon icon={faFilePdf} className="Icon  me-2" />
          PDF
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          eventKey="savefileExcelButton"
          onClick={handleClickDownloadExcel}
        >
          <FontAwesomeIcon icon={faFileExcel} className="Icon  me-2" />
          Excel
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
};

ReportFileSelect.propTypes = {
  quarter: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  coachEmail: PropTypes.string,
  employer: PropTypes.string,
};

export default ReportFileSelect;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from "react-toastify";
import { faBell as farBell } from "@fortawesome/free-regular-svg-icons";
import { faBell as fasBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import micoach, { TaskApi } from "micoach-api";

import ConfirmModal from "Components/ConfirmModal";

import styles from "Components/Task/styles/TaskStatusHandlerButton.module.css";

const TaskStatusHandlerButton = (props) => {
  const { id, status, isCoach } = props;

  const [icon, setIcon] = useState(null);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showCompleteTaskModal, setShowCompleteTaskModal] = useState(false);

  const getTooltipMessage = (currentStatus, isCoach) => {
    if (isCoach) {
      if (currentStatus === micoach.TaskStatusEnum.Verify) {
        return "When the task has been completed, mark it as done.";
      }

      return "";
    }

    const messages = {
      [micoach.TaskStatusEnum.ToDo]:
        "Let your Coach know that the task is available to review.",
      [micoach.TaskStatusEnum.Verify]: "Retrieve your Coach's pending review.",
    };

    return messages[currentStatus] ?? "";
  };

  const getConfirmMessage = (newStatus) => {
    const messages = {
      [micoach.TaskStatusEnum.Verify]:
        "Success! The task is going to be reviewed.",
      [micoach.TaskStatusEnum.ToDo]: "Success! The task is going to be normal.",
    };

    return messages[newStatus] ?? "";
  };

  const getNewStatus = (currentStatus, isCoach) => {
    if (isCoach && currentStatus === micoach.TaskStatusEnum.Verify) {
      return micoach.TaskStatusEnum.Done;
    }

    const newStatusMap = {
      [micoach.TaskStatusEnum.ToDo]: micoach.TaskStatusEnum.Verify,
      [micoach.TaskStatusEnum.Verify]: micoach.TaskStatusEnum.ToDo,
    };

    return newStatusMap[currentStatus] ?? "";
  };

  const getIcon = (status, isCoach) => {
    if (!isCoach && status === micoach.TaskStatusEnum.ToDo) {
      return <FontAwesomeIcon icon={farBell} size="lg" />;
    }

    if (status === micoach.TaskStatusEnum.Verify) {
      return (
        <FontAwesomeIcon icon={fasBell} size="lg" className={styles.Red} />
      );
    }

    return null;
  };

  const isButtonValid = (status, isCoach) => {
    if (!status || status === "" || status === micoach.TaskStatusEnum.Done) {
      return false;
    }

    if (isCoach && status !== micoach.TaskStatusEnum.Verify) {
      return false;
    }

    return true;
  };

  const updateStatus = async (isCoach) => {
    setIsLoading(true);

    try {
      const newStatus = getNewStatus(status, isCoach);
      const { data } = await TaskApi.updateTaskStatus(id, {
        status: newStatus,
      });

      props.onUpdated?.(data);
      if (!isCoach) {
        toast.success(getConfirmMessage(newStatus));
      }
    } catch (error) {
      toast.error("Error, please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmCompleteModal = async (isCoach) => {
    await updateStatus(isCoach);

    setShowCompleteTaskModal(false);
  };

  const handleClick = async () => {
    if (isCoach) {
      setShowCompleteTaskModal(true);
    } else {
      await updateStatus(isCoach);
    }
  };

  useEffect(() => {
    setMessage(getTooltipMessage(status, isCoach));
    setIcon(getIcon(status, isCoach));
  }, [status, isCoach]);

  if (!id || !isButtonValid(status, isCoach)) {
    return null;
  }

  return (
    <>
      <ConfirmModal
        title="Complete task"
        confirmButtonText="Mark as done"
        show={showCompleteTaskModal}
        isLoading={isLoading}
        onConfirm={handleConfirmCompleteModal}
        onClose={() => setShowCompleteTaskModal(false)}
      >
        <div>
          You have verified that the task has been completed, you can mark it as
          done.
          <div className="mt-3">This action cannot be undone.</div>
        </div>
      </ConfirmModal>

      {message ? (
        <OverlayTrigger overlay={<Tooltip>{message}</Tooltip>}>
          <Button
            variant="link"
            onClick={handleClick}
            className={styles.Button}
            disabled={isLoading}
          >
            {icon}
          </Button>
        </OverlayTrigger>
      ) : (
        <Button
          variant="link"
          onClick={handleClick}
          className={styles.Button}
          disabled={isLoading}
        >
          {icon}
        </Button>
      )}
    </>
  );
};

TaskStatusHandlerButton.propTypes = {
  id: PropTypes.string,
  status: PropTypes.string,
  isCoach: PropTypes.bool,
  onUpdated: PropTypes.func,
};

TaskStatusHandlerButton.defaultProps = {
  isCoach: false,
};

export default TaskStatusHandlerButton;

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "Components/Common/styles/BusinessEmailLabel.module.css";

const BusinessEmailLabel = () => {
  return (
    <div className={styles.Container}>
      <div className={styles.BusinessEmailLabel}>
        <FontAwesomeIcon
          icon={faInfoCircle}
          className={styles.InfoIcon}
          title="faInfoCircle"
        />
        <span>If you&apos;re an </span>
        <span className={styles.EmployeeLabel}>ITJ&apos;s employee, </span>
        <span>please include your corporate email.</span>
      </div>
    </div>
  );
};

export default BusinessEmailLabel;

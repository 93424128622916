import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import micoach from "micoach-api";

import CustomSelect from "Components/CustomSelect";
import { EMAIL_PATTERN } from "constants.js";

const RecipientInputs = (props) => {
  const {
    formProps: { register, errors, control },
    recipientNumber,
    showEvaluatorRelation,
  } = props;

  const evaluatorRelations = () => {
    const relations = micoach.EvaluatorRelationEnum;
    const relationLabels = Object.keys(relations);
    const relationValues = Object.values(relations);
    return relationLabels
      .map((label, index) => {
        return { label, value: relationValues[index] };
      })
      .filter((relation) => relation.value !== "SELF");
  };

  return (
    <>
      <Form.Group
        className="form-group required"
        controlId={`email${recipientNumber}`}
      >
        <Form.Label>Recipient&apos;s email {recipientNumber}</Form.Label>
        <Form.Control
          type="text"
          name={`email${recipientNumber}`}
          {...register(`email${recipientNumber}`, {
            pattern: EMAIL_PATTERN,
            required: true,
          })}
          isInvalid={!!errors["email" + recipientNumber]}
        />
        {errors["email" + recipientNumber] && (
          <Form.Control.Feedback type="invalid">
            {errors["email" + recipientNumber].type === "pattern" &&
              "Recipient email must be a valid email"}
            {errors["email" + recipientNumber].type === "required" &&
              "Recipient email  is required"}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      {showEvaluatorRelation && (
        <Form.Group
          className="required"
          controlId={`relationship${recipientNumber}`}
        >
          <Form.Label>Job relationship {recipientNumber}</Form.Label>
          <CustomSelect
            name={`relationship${recipientNumber}`}
            control={control}
            isRequired
            placeholder="Select option"
            options={evaluatorRelations()}
          />
          {errors["relationship" + recipientNumber] && (
            <div className="invalid-feedback d-block">
              {errors["relationship" + recipientNumber].type === "required" &&
                "Job relationship is required"}
            </div>
          )}
        </Form.Group>
      )}
    </>
  );
};

RecipientInputs.propTypes = {
  formProps: PropTypes.object.isRequired,
  recipientNumber: PropTypes.number.isRequired,
  showEvaluatorRelation: PropTypes.bool,
};

RecipientInputs.defaultProps = {
  showEvaluatorRelation: true,
};

export default RecipientInputs;

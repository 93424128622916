import { useCallback, useEffect, useState } from "react";
import keycloak from "keycloak";
import micoach, { EvaluationApi, RoleApi, UserApi } from "micoach-api";

export const useSkillEvaluation = (queryParams) => {
  const [evaluation, setEvaluation] = useState();
  const [skillsByRole, setSkillsByRole] = useState([]);
  const [loadingEvaluation, setLoadingEvaluation] = useState(false);
  const evaluationId = queryParams.get("id") ?? "";
  const isRequest = queryParams.get("request") === "true";

  const getSkillsByRole = useCallback(async (userRole) => {
    let skills = [];
    try {
      // Get roles
      const { data: roles } = await RoleApi.listPublicRoles(true);
      // Get the skills to evaluate for the role
      const role = roles.find((role) => role.name === userRole);
      skills =
        role.skills?.map?.((skill) => {
          return {
            name: skill.name,
            category: skill.category,
            description: skill.description,
            proficiencyLevels: skill.proficiencyLevels || [],
            value: null,
            valueText: "",
          };
        }) || [];

      setSkillsByRole(skills);
    } catch (error) {
      console.error(error);
    }
    return skills;
  }, []);

  const createEvaluation = useCallback(async () => {
    setLoadingEvaluation(true);
    if (evaluationId) {
      try {
        const { data: evaluationData } = isRequest
          ? await EvaluationApi.getPublicEvaluation(evaluationId)
          : await EvaluationApi.getEvaluation(evaluationId);

        const skills = await getSkillsByRole(evaluationData.userRole);
        for (const skill of evaluationData.skills) {
          const foundSkill = skills.find((s) => s.name === skill.name);
          skill.proficiencyLevels = foundSkill?.proficiencyLevels || [];
        }

        setEvaluation(evaluationData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingEvaluation(false);
      }
    } else {
      let skills = [];
      try {
        // Get user profile
        const response = await UserApi.getProfile();
        const userRole = response.data?.role;

        if (userRole) {
          // Get the skills to evaluate for the role
          skills = await getSkillsByRole(userRole);
        }

        const evaluation = {
          name: "Self skills assessment",
          type: micoach.EvaluationTypeEnum.Skills,
          userId: keycloak.tokenParsed.sub,
          userName: keycloak.tokenParsed.name,
          userRole,
          evaluatorUserId: keycloak.tokenParsed.sub,
          evaluatorEmail: keycloak.tokenParsed.email,
          evaluatorRelation: micoach.EvaluatorRelationEnum.Self,
          status: micoach.EvaluationStatusEnum.Open,
          completed: false,
          comments: "",
          skills,
        };
        setEvaluation(evaluation);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingEvaluation(false);
      }
    }
  }, [evaluationId, isRequest, getSkillsByRole]);

  useEffect(() => {
    createEvaluation();
  }, [createEvaluation]);
  return { evaluation, loadingEvaluation, setEvaluation, skillsByRole };
};

export const useSkillSummary = (userId, year, quarter) => {
  const [summary, setSummary] = useState({});
  const [loadingSummary, setLoadingSummary] = useState(false);

  const loadSummary = useCallback(async () => {
    try {
      setLoadingSummary(true);
      const { data } = await UserApi.getSkillEvaluationSummary(
        userId,
        year,
        quarter
      );
      setSummary(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSummary(false);
    }
  }, [userId, year, quarter]);

  useEffect(() => {
    loadSummary();
  }, [loadSummary]);

  return { summary, loadingSummary };
};

import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "Components/Evaluation/Skill/styles/SkillSummaryCard.module.css";

const SkillSummaryCard = (props) => {
  const { skill } = props;
  const isValueZero = skill.value === 0;
  const title = isValueZero ? "NA" : `PL${skill.value}`;

  const handleClick = () => {
    props.onClick?.(skill);
  };

  return (
    <Card className={`Card ${styles.SkillSummaryCard}`} onClick={handleClick}>
      <Card.Body className={styles.Body}>
        <div className={styles.Name}>{skill.name}</div>
        <div className={styles.ValueContainer}>
          {skill.value !== null ? (
            <div className={styles.Container}>
              <div>
                <div className={styles.Value}>{title}</div>
                {!isValueZero && (
                  <div className={styles.ValueText}>{skill.valueText}</div>
                )}
              </div>
              <div className={styles.Icon}>
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
            </div>
          ) : (
            <>Evaluate</>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

SkillSummaryCard.propTypes = {
  skill: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default SkillSummaryCard;

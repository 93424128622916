import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { toast } from "react-toastify";
import {
  faEllipsisH,
  faPen,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import micoach from "micoach-api";

import CommentsButton from "Components/Common/CommentsButton";
import CommentsPopover from "Components/Common/CommentsPopover";
import ConfirmModal from "Components/ConfirmModal";
import TaskDescription from "Components/Task/TaskDescription";
import TaskDueIndicator from "Components/Task/TaskDueDateIndicator";
import TaskModal from "Components/Task/TaskModal";
import TaskStatus from "Components/Task/TaskStatus";
import TaskStatusHandlerButton from "Components/Task/TaskStatusHandlerButton";
import { useTaskComments } from "Hooks/useTaskComments";
import { getFormattedLocalDate } from "utils.js";

import styles from "Components/Task/styles/TaskManagementCard.module.css";

const TaskManagementCard = (props) => {
  const { task } = props;

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);

  const {
    comments,
    loadComments,
    loadingComments,
    setComments,
    saveComment,
    savingComment,
  } = useTaskComments();

  useEffect(() => {
    switch (task.type) {
      case micoach.TaskTypeEnum.Watch:
        setTitle("Watch suggested training");
        break;
      case micoach.TaskTypeEnum.General:
        setTitle("Assigned task");
        break;
      default:
        setTitle("Unknown task");
    }

    switch (task.entity) {
      case micoach.TaskEntityEnum.Event:
        setSubtitle(task.event?.name);
        break;
      case micoach.TaskEntityEnum.Lecture:
        setSubtitle(task.lecture?.name);
        break;
      default:
        setSubtitle(task.title);
        setDescription(task.description);
    }
  }, [task]);

  const handleClickCommentsButton = async () => {
    if (task.hasNewUserComments) {
      props.onClick?.(task._id);
    }

    if (!showComments) {
      setComments([]);
      await loadComments(task?._id);
    }

    setShowComments(!showComments);
  };

  const handleAddedComment = async (commentValue) => {
    try {
      await saveComment(task._id, commentValue);
    } catch (error) {
      toast.error("Error! The comment was not added.");
    }
  };

  return (
    <>
      <ConfirmModal
        title="Delete task"
        confirmButtonText="Yes, delete"
        show={showDeleteTaskModal}
        onConfirm={() => props.onConfirmDelete(task._id)}
        onClose={() => setShowDeleteTaskModal(false)}
      >
        <div>
          Are you sure you want to delete
          <strong> {`${title} | ${subtitle}`} </strong>?
          <div className="mt-3"> This action cannot be undone.</div>
        </div>
      </ConfirmModal>

      <TaskModal
        task={task}
        show={showEditTaskModal}
        isEditing={true}
        onClose={() => setShowEditTaskModal(false)}
        onEdit={props.onEdit}
      />

      <Card className={`Card ${styles.TaskManagementCard}`}>
        <Card.Body>
          <div className={styles.Header}>
            <TaskStatus read={task.read} status={task.status} />

            <div className={styles.Actions}>
              {task.type === micoach.TaskTypeEnum.General && (
                <>
                  <TaskStatusHandlerButton
                    id={task._id}
                    status={task.status}
                    isCoach
                    onUpdated={props.onUpdated}
                  />

                  <CommentsPopover
                    comments={comments}
                    isEditable={task.status !== micoach.TaskStatusEnum.Done}
                    isLoading={loadingComments}
                    isSaving={savingComment}
                    setShowComments={setShowComments}
                    onAddComment={handleAddedComment}
                  >
                    <CommentsButton
                      hasNewComments={task.hasNewUserComments}
                      tooltipMessage="Task interaction available"
                      onClick={handleClickCommentsButton}
                    />
                  </CommentsPopover>
                </>
              )}
              <DropdownButton
                variant="link"
                title={
                  <FontAwesomeIcon title="faEllipsisH" icon={faEllipsisH} />
                }
                className={styles.Action}
                data-testid="task-menu"
              >
                {task.type === micoach.TaskTypeEnum.General &&
                  !task.completed && (
                    <Dropdown.Item
                      as="button"
                      eventKey="editButton"
                      onClick={() => setShowEditTaskModal(true)}
                    >
                      <FontAwesomeIcon
                        icon={faPen}
                        className={styles.ItemIcon}
                      />
                      <span>Edit</span>
                    </Dropdown.Item>
                  )}

                <Dropdown.Item
                  as="button"
                  eventKey="deleteButton"
                  onClick={() => setShowDeleteTaskModal(true)}
                >
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className={styles.ItemIcon}
                  />
                  <span>Delete</span>
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>

          <Card.Title className={styles.Title}>{title}</Card.Title>
          <Card.Text className={styles.Subtitle}>{subtitle}</Card.Text>
          <TaskDescription description={description} />

          <section className={styles.Dates}>
            <span>
              {`Created on: ${getFormattedLocalDate(task.createdAt)}`}
            </span>

            <TaskDueIndicator task={task} />
          </section>
        </Card.Body>
      </Card>
    </>
  );
};

TaskManagementCard.propTypes = {
  task: PropTypes.object.isRequired,
  descriptionToShow: PropTypes.number,
  onConfirmDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  onClick: PropTypes.func,
  onUpdated: PropTypes.func,
};

export default TaskManagementCard;

import { useCallback, useEffect, useState } from "react";
import { CandidateApi } from "micoach-api";

export const useActions = (candidateId, limit = 100) => {
  const [actions, setActions] = useState([]);
  const [loadingActions, setLoadingActions] = useState(false);

  const loadActions = useCallback(async () => {
    setLoadingActions(true);
    try {
      const { data } = await CandidateApi.listActions(candidateId, limit);
      setActions(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingActions(false);
    }
  }, [candidateId, limit]);

  useEffect(() => {
    loadActions();
  }, [loadActions]);

  return { actions, loadingActions };
};

import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";

import styles from "Components/Common/styles/AlertBody.module.css";

const AlertBody = (props) => {
  const { title, content, buttonText, onClick } = props;

  return (
    <div>
      {title && <p className={styles.Title}>{title}</p>}

      {content && <p>{content}</p>}

      {buttonText && (
        <div className={styles.Action}>
          <Button variant="secondary" onClick={onClick}>
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
};

AlertBody.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
};

AlertBody.defaultProps = {
  title: "",
  content: "",
};

export default AlertBody;

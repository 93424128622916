import { forwardRef } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import CommentsIcon from "Components/Common/CommentsIcon";

const CommentsButton = forwardRef(function CommentsButton(props, ref) {
  const { hasNewComments, tooltipMessage } = props;

  return (
    <div ref={ref}>
      {hasNewComments ? (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{tooltipMessage}</Tooltip>}
        >
          <Button type="button" variant="link" onClick={props.onClick}>
            <CommentsIcon hasNewComments />
          </Button>
        </OverlayTrigger>
      ) : (
        <Button type="button" variant="link" onClick={props.onClick}>
          <CommentsIcon />
        </Button>
      )}
    </div>
  );
});

CommentsButton.propTypes = {
  hasNewComments: PropTypes.bool,
  tooltipMessage: PropTypes.string,
  onClick: PropTypes.func,
};

CommentsButton.defaultProps = {
  hasNewComments: false,
  tooltipMessage: "Interaction available",
};

export default CommentsButton;

import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SkillsList from "Components/Admin/Roles/SkillsList";
import FilterInput from "Components/Common/FilterInput";
import Loading from "Components/Loading";

const RoleTable = (props) => {
  const { roles, loading, resetPagination } = props;

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    useState(resetPagination);

  const filteredRoles = roles?.filter((role) => {
    if (role.name?.toLowerCase().includes(filterText.toLowerCase()))
      return true;

    return false;
  });

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="mb-3">
        <FilterInput
          filterText={filterText}
          placeholder="Filter by name"
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: <strong>Name</strong>,
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
      maxWidth: "300px",
    },
    {
      name: <strong>Description</strong>,
      selector: (row) => row.description,
      sortable: true,
      wrap: true,
    },
    {
      name: <strong>Skills</strong>,
      selector: (row) => row.skills?.length || "",
      sortable: true,
      width: "120px",
      right: true,
      defaultExpanded: true,
    },
    {
      name: <strong>Actions</strong>,
      cell: (row) => (
        <Button
          variant="link"
          onClick={() => {
            props.onEdit?.(row);
          }}
        >
          <FontAwesomeIcon icon={faPen}></FontAwesomeIcon>
        </Button>
      ),
      width: "80px",
      center: true,
    },
  ];

  useEffect(() => {
    setResetPaginationToggle(resetPagination);
  }, [resetPagination]);

  return (
    <Card className="Card">
      <Card.Body>
        <DataTable
          columns={columns}
          data={filteredRoles}
          progressPending={loading}
          progressComponent={<Loading />}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          expandableRows
          expandableRowsComponent={SkillsList}
          expandableRowDisabled={(row) => !row.skills?.length}
        />
      </Card.Body>
    </Card>
  );
};

RoleTable.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      skills: PropTypes.arrayOf(PropTypes.object),
    })
  ),
  loading: PropTypes.bool,
  resetPagination: PropTypes.bool,
  onEdit: PropTypes.func,
};

RoleTable.defaultValues = {
  loading: false,
  resetPagination: false,
};

export default RoleTable;

import axios from "axios";

let backendUrl = "/api/micoach/public";

// You can override the value of backendUrl by creating a file
// named `.env.local` in the root of this repo and set the
// the following environment variable to your desired value.
if (process.env.REACT_APP_BACKEND_URL) {
  backendUrl = `${process.env.REACT_APP_BACKEND_URL}/public`;
}

/******************************************************************
 *              Section for uploading documents                   *
 *****************************************************************/

async function getUploadDocumentRequest(id) {
  const response = await axios.get(`${backendUrl}/document-request/${id}`);
  return response.data;
}

async function getCandidateDocumentUploadUrl(id, document) {
  const response = await axios.post(
    `${backendUrl}/document-request/${id}/document`,
    document
  );
  return response.data;
}

async function confirmUploadDocument(id, document) {
  const response = await axios.put(
    `${backendUrl}/document-request/${id}/document`,
    document
  );
  return response.data;
}

async function deleteCandidateDocument(id, documentId) {
  const response = await axios.delete(
    `${backendUrl}/document-request/${id}/document/${documentId}`
  );

  return response.data;
}

/******************************************************************
 *                      Section for jobs                          *
 *****************************************************************/

async function fetchJobs(queryParams) {
  const response = await axios.get(`${backendUrl}/job`, {
    params: queryParams,
  });
  return response.data;
}

async function getJob(id) {
  const response = await axios.get(`${backendUrl}/job/${id}`);
  return response.data;
}

async function fetchSkills(category) {
  const response = await axios.get(`${backendUrl}/skill`, {
    params: {
      category,
    },
  });
  return response.data;
}

const exports = {
  getUploadDocumentRequest,
  getCandidateDocumentUploadUrl,
  confirmUploadDocument,
  deleteCandidateDocument,

  fetchJobs,
  getJob,
  fetchSkills,
};

export default exports;

import { useState } from "react";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";

import styles from "Components/Common/styles/CustomAccordion.module.css";

const CustomAccordion = (props) => {
  const { title, children, isOpen } = props;

  const [showOpen, setShowOpen] = useState(isOpen);

  return (
    <Accordion
      defaultActiveKey={isOpen ? "0" : ""}
      className={styles.AccordionContainer}
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header
          as="div"
          className={styles.Header}
          onClick={() => setShowOpen(!showOpen)}
        >
          <span
            className={`${styles.Title} ${showOpen ? styles.Selected : ""}`}
          >
            {title}
          </span>
        </Accordion.Header>
        <Accordion.Body className="p-0">{children}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

CustomAccordion.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
};

CustomAccordion.defaultProps = {
  isOpen: false,
};

export default CustomAccordion;

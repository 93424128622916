import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import { toast } from "react-toastify";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CandidateApi } from "micoach-api";

import ConfirmModal from "Components/ConfirmModal";
import FormattedDate from "Components/FormattedDate";
import { formattedSize, getMimeTypeIcon } from "utils.js";

import "Components/styles/DocumentsList.css";

function DocumentsList(props) {
  const [showDeleteDocumentModal, setShowDeleteDocumentModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const filterDocuments = (documents) => {
    if (!props.filterDocuments || !props.filterDocuments.length) {
      return documents;
    }

    return documents.filter((document) =>
      props.filterDocuments.includes(document.checklistType)
    );
  };

  const handleClickDownload = async (document) => {
    try {
      const { data } = await CandidateApi.getDownloadDocumentUrl(
        props.candidateId,
        document._id
      );
      window.open(data.url);
    } catch (error) {
      toast.error("Error! Unable to download the document, try again.");
    }
  };

  const handleClickDelete = (document) => {
    setShowDeleteDocumentModal(true);
    setSelectedDocument(document);
  };

  const handleConfirmDelete = async () => {
    try {
      const { data } = await CandidateApi.deleteDocument(
        props.candidateId,
        selectedDocument._id
      );
      setShowDeleteDocumentModal(false);
      if (props.onDeleted) {
        props.onDeleted(data);
      }
      toast.success("Success! The document has been deleted.");
    } catch (error) {
      toast.error("Error! An error occurred, please try again.");
    }
  };

  if (!props.documents) return null;

  return (
    <>
      <ConfirmModal
        title="Delete document"
        confirmButtonText="Yes, delete"
        show={showDeleteDocumentModal}
        onConfirm={handleConfirmDelete}
        onClose={() => setShowDeleteDocumentModal(false)}
      >
        <div>
          Are you sure you want to delete the document{" "}
          <strong>{selectedDocument?.fileName}</strong>?
          <div className="mt-3"> This action cannot be undone.</div>
        </div>
      </ConfirmModal>

      <div className="DocumentsList">
        <ListGroup variant="flush">
          {filterDocuments(props.documents).map((document) => {
            return (
              <ListGroup.Item key={document._id} title={document.fileName}>
                <Row>
                  <Col xs={10}>
                    <FontAwesomeIcon
                      size="lg"
                      icon={getMimeTypeIcon(document.contentType)}
                    />
                    <Button
                      variant="link"
                      className="DocumentName"
                      title={document.fileName}
                      onClick={() => {
                        handleClickDownload(document);
                      }}
                    >
                      {document.fileName}
                    </Button>
                    <span className="DocumentSize">
                      | {formattedSize(document.size)}
                    </span>
                  </Col>
                  <Col xs={2} className="text-end">
                    <Button
                      id="DeleteDocument"
                      variant="link"
                      className="Delete"
                      onClick={() => handleClickDelete(document)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                    </Button>
                  </Col>
                </Row>
                <div className="DocumentDate">
                  <FormattedDate>{document.updatedAt}</FormattedDate>
                </div>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </div>
    </>
  );
}

DocumentsList.propTypes = {
  candidateId: PropTypes.string.isRequired,
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  filterDocuments: PropTypes.arrayOf(PropTypes.string),
  onDeleted: PropTypes.func,
};

export default DocumentsList;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AchievementForm from "Components/Admin/Achievements/AchievementForm";
import { useAchievements } from "Hooks/useAchievements";
import { useFiles, useUploadFiles } from "Hooks/useFiles";
import {
  ACHIEVEMENT_FILE_CATEGORIES,
  FILE_ENTITIES,
  SUBMIT_TYPES,
} from "constants.js";

const DEFAULT_FORM = {
  name: "",
  message: "",
  earnedDate: null,
  audience: "",
  [ACHIEVEMENT_FILE_CATEGORIES.ATTENDEES]: "",
  [ACHIEVEMENT_FILE_CATEGORIES.CERTIFICATE]: "",
  [ACHIEVEMENT_FILE_CATEGORIES.BADGE]: "",
};
const CERTIFICATE_BADGE_CATEGORIES = [
  ACHIEVEMENT_FILE_CATEGORIES.CERTIFICATE,
  ACHIEVEMENT_FILE_CATEGORIES.BADGE,
];

const AchievementAddEditModal = (props) => {
  const { show = false, onSaved, onClose } = props;

  const { createAchievement } = useAchievements();
  const { createFile } = useFiles();
  const { uploadFile, files, setFiles, dropFile, deleteFile } =
    useUploadFiles(createFile);

  const [submitType, setSubmitType] = useState(SUBMIT_TYPES.SAVE);
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const uploadAchievementFilesAndGetFileIds = async () => {
    const achievementFileTypes = Object.keys(files);
    const fileIdPropertyMap = {
      [ACHIEVEMENT_FILE_CATEGORIES.ATTENDEES]: "attendeesFileId",
      [ACHIEVEMENT_FILE_CATEGORIES.CERTIFICATE]: "certificateBackgroundFileId",
      [ACHIEVEMENT_FILE_CATEGORIES.BADGE]: "badgeImageFileId",
    };

    const fileIds = {};
    for (const achievementFileType of achievementFileTypes) {
      const isPublic =
        achievementFileType !== ACHIEVEMENT_FILE_CATEGORIES.ATTENDEES;
      const fileId = await uploadFile({
        file: files[achievementFileType],
        entity: FILE_ENTITIES.ACHIEVEMENT,
        category: achievementFileType,
        isPublic,
      });
      fileIds[fileIdPropertyMap[achievementFileType]] = fileId;
    }

    return fileIds;
  };

  const handleDropFile = (acceptedFiles, category) => {
    dropFile(acceptedFiles, category);

    if (CERTIFICATE_BADGE_CATEGORIES.includes(category)) {
      clearErrors(CERTIFICATE_BADGE_CATEGORIES);
    } else {
      clearErrors(category);
    }
  };

  const handleDeleteFile = (category, isRequired = true) => {
    deleteFile(category);

    if (!isRequired) {
      return;
    }

    if (CERTIFICATE_BADGE_CATEGORIES.includes(category)) {
      setError(ACHIEVEMENT_FILE_CATEGORIES.CERTIFICATE, { type: "required" });
      setError(ACHIEVEMENT_FILE_CATEGORIES.BADGE, { type: "required" });
    } else {
      setError(category, { type: "required" });
    }
  };

  const handleCustomSubmit = (type) => {
    setSubmitType(type);
    handleSubmit((formValues) => onSubmit(formValues, type))();
  };

  const onSubmit = async (formValues, submitType) => {
    try {
      setIsFormDisabled(true);
      const achievementFileIds = await uploadAchievementFilesAndGetFileIds();

      const { name, message, earnedDate, audience } = formValues;
      const body = {
        ...achievementFileIds,
        name,
        message,
        audience,
        ...(earnedDate && { earnedDate: earnedDate.toISOString() }),
      };

      const { _id } = await createAchievement({ body });

      onSaved?.(_id, submitType);
      onClose?.();
      toast.success("Success! The achievement draft has been created.");
    } catch (error) {
      console.error(error);
    } finally {
      setIsFormDisabled(false);
    }
  };

  useEffect(() => {
    if (show) {
      reset(DEFAULT_FORM);
      setSubmitType(SUBMIT_TYPES.SAVE);
      setFiles({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, reset]);

  return (
    <Modal show={show} onHide={onClose}>
      <Form noValidate>
        <Modal.Header closeButton>
          <Modal.Title>Create Achievement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3 fs-14">
            To create and send and achievement, upload the required assets
            below. Remember to double check the format and properties of each
            one.
          </div>

          <AchievementForm
            submitType={submitType}
            files={files}
            formProps={{ register, control, errors }}
            disabled={isFormDisabled}
            onDropFile={handleDropFile}
            onDeleteFile={handleDeleteFile}
          />

          <Alert variant="light" className="fs-13">
            <FontAwesomeIcon icon={faInfoCircle} className="BlueTextMedium" />
            <span>At least, one certificate or badge asset is required</span>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="CancelButton"
            disabled={isFormDisabled}
            onClick={() => handleCustomSubmit(SUBMIT_TYPES.SAVE)}
          >
            Save
          </Button>
          <Button
            className="SaveButton"
            disabled={isFormDisabled}
            onClick={() => handleCustomSubmit(SUBMIT_TYPES.PREVIEW)}
          >
            Preview
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

AchievementAddEditModal.propTypes = {
  show: PropTypes.bool,
  onSaved: PropTypes.func,
  onClose: PropTypes.func,
};

export default AchievementAddEditModal;

import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";

const RoleFiltersForm = (props) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = (formValues) => {
    const filters = {};

    const hasSkills = formValues.hasSkills
      ?.filter?.((hasSkills) => hasSkills)
      .map((hasSkills) => hasSkills === "true");

    if (hasSkills.length) {
      filters.hasSkills = hasSkills;
    }

    props.onSubmit?.(filters);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Form.Group controlId="hasSkills" className="form-group">
        <Form.Label className="fs-16">Has skills</Form.Label>
        {["Yes", "No"].map((label, index) => (
          <Form.Check
            key={label}
            custom
            type="checkbox"
            id={`hasSkills-${index}`}
            name={`hasSkills[${index}]`}
            label={label}
            value={label === "Yes"}
            {...register(`hasSkills[${index}]`)}
          />
        ))}
      </Form.Group>

      <Button variant="primary" type="submit" className="w-100">
        Apply filters
      </Button>
    </Form>
  );
};

RoleFiltersForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default RoleFiltersForm;

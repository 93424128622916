import PropTypes from "prop-types";
import { Card } from "react-bootstrap";

import RecentActivityList from "Components/Candidate/RecentActivity/RecentActivityList";
import Loading from "Components/Loading";

import styles from "Components/Candidate/RecentActivity/styles/RecentActivityCard.module.css";

const RecentActivityCard = (props) => {
  const { actions, loading } = props;

  if (loading) {
    return <Loading />;
  }

  if (actions?.length < 1) {
    return null;
  }

  return (
    <Card className="Card">
      <Card.Body>
        <Card.Title className={styles.Title}>Recent activity</Card.Title>
        <RecentActivityList actions={actions} />
      </Card.Body>
    </Card>
  );
};

RecentActivityCard.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      points: PropTypes.number.isRequired,
      createdAt: PropTypes.string,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default RecentActivityCard;

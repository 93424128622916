import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import { faCompressAlt, faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import micoach, { EmailApi } from "micoach-api";

import CustomSelect from "Components/CustomSelect";
import { useCompanies } from "Hooks/useCompanies";
import { useRoles } from "Hooks/useRoles";

const KeywordsAlert = () => {
  const [showKeyword, setShowKeyword] = useState(true);

  if (showKeyword) {
    return (
      <Alert
        variant="info"
        className="fs-14"
        onClose={() => setShowKeyword(false)}
        dismissible
      >
        <div>
          Keywords available (To use them, write the words in braces):
          <ul>
            <li>
              <strong>{`{name}`}</strong> User name or candidate
            </li>
          </ul>
        </div>
      </Alert>
    );
  }
};

const EmailTemplateModal = (props) => {
  const { show, emailTemplate, emailTemplates, isEditing } = props;

  const [message, setMessage] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [emailTemplateExists, setTemplateExists] = useState(false);
  const { roles } = useRoles();
  const [companies] = useCompanies();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
  });

  const roleValue = watch("role");
  const companyValue = watch("company");

  const getTemplate = (role, company, templates) => {
    const template = templates.find(
      (template) =>
        template.metadata.company === company && template.metadata.role === role
    );

    return template;
  };

  const handleChangeMessage = (text, delta, source, editor) => {
    // getLength returns 1 when the textbox is empty, in that
    // case, message is set to null to disable the submit button.
    const message = editor.getLength() > 1 ? text : null;
    setMessage(message);
  };

  const handleCloseModal = () => {
    setTemplateExists(false);
    props.onClose?.();
  };

  const onSubmit = async (formValues) => {
    if (!message) {
      toast.error("Template message is required");
      return;
    }

    try {
      setIsSaving(true);

      if (isEditing) {
        const { data: updatedEmailTemplate } =
          await EmailApi.updateEmailTemplate(emailTemplate._id, {
            message,
          });

        setMessage(updatedEmailTemplate.message);
        props.onUpdated?.(updatedEmailTemplate);
      } else {
        const newEmailTemplate = {
          type: micoach.EmailTypeEnum.FirstContact,
          metadata: { ...formValues },
          message,
        };
        const { data: createdEmailTemplate } =
          await EmailApi.createEmailTemplate(newEmailTemplate);

        props.onCreated?.(createdEmailTemplate);
      }

      toast.success("The email template has been saved succesfully");
      handleCloseModal();
    } catch (error) {
      console.error(error);
      toast.error("Error, please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (emailTemplate && show) {
      setMessage(emailTemplate.message);
    } else {
      setMessage(null);
      setFullScreen(false);
    }
  }, [emailTemplate, show]);

  useEffect(() => {
    const template = getTemplate(roleValue, companyValue, emailTemplates);
    setTemplateExists(!!template);
  }, [emailTemplates, roleValue, companyValue]);

  // Roles
  const roleOptions = [
    { value: "None", label: "None" },
    ...roles.map((role) => {
      return {
        value: role.name,
        label: role.name,
      };
    }),
  ];

  // Companies
  const companyOptions = [
    { value: "None", label: "None" },
    ...companies.map((company) => {
      return {
        value: company.name,
        label: company.name,
      };
    }),
  ];

  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      contentClassName={fullScreen && "FullScreen"}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Edit" : "Create"} Email template
          </Modal.Title>
          <button
            type="button"
            className="ActionButton"
            onClick={() => setFullScreen(!fullScreen)}
          >
            <FontAwesomeIcon icon={!fullScreen ? faExpandAlt : faCompressAlt} />
          </button>
        </Modal.Header>
        <Modal.Body>
          {isEditing ? (
            <>
              <div className="mb-2">
                <strong>Company:</strong>{" "}
                <span>{emailTemplate?.metadata?.company}</span>
              </div>
              <div className="mb-2">
                <strong>Role:</strong>{" "}
                <span>{emailTemplate?.metadata?.role}</span>
              </div>
            </>
          ) : (
            <>
              {emailTemplateExists && (
                <Alert variant="warning" className="fs-14">
                  <div>
                    {`An email template already exists for company "${
                      companyValue ?? "None"
                    }" and role "${roleValue ?? "None"}"`}
                  </div>
                </Alert>
              )}
              <Form.Group controlId="company" className="form-group required">
                <Form.Label>Company</Form.Label>
                <CustomSelect
                  name="company"
                  control={control}
                  options={companyOptions}
                  isRequired
                  isInvalid={errors.company}
                  defaultValue={companyOptions[0]}
                />
                {errors.company && (
                  <div className="invalid-feedback d-block">
                    {errors.company.type === "required" &&
                      "Company is required"}
                  </div>
                )}
              </Form.Group>

              <Form.Group controlId="role" className="form-group required">
                <Form.Label>Role</Form.Label>
                <CustomSelect
                  name="role"
                  control={control}
                  options={roleOptions}
                  isRequired
                  isInvalid={errors.role}
                  defaultValue={roleOptions[0]}
                />
                {errors.role && (
                  <div className="invalid-feedback d-block">
                    {errors.role.type === "required" && "Role is required"}
                  </div>
                )}
              </Form.Group>
            </>
          )}

          {KeywordsAlert()}

          <Form.Group controlId="message" className="form-group required">
            <Form.Label>Message template</Form.Label>
            <ReactQuill value={message} onChange={handleChangeMessage} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="CancelButton"
            onClick={handleCloseModal}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="SaveButton"
            disabled={emailTemplateExists || !message || isSaving}
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

EmailTemplateModal.propTypes = {
  show: PropTypes.bool.isRequired,
  emailTemplate: PropTypes.object,
  emailTemplates: PropTypes.array,
  isEditing: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdated: PropTypes.func,
  onCreated: PropTypes.func,
};

export default EmailTemplateModal;

import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
  faClone,
  faEllipsisH,
  faPen,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import micoach from "micoach-api";

import FormattedDate from "Components/FormattedDate";
import { getEmailTemplateSubject } from "utils.js";

import styles from "Components/Admin/styles/EmailTemplateCard.module.css";

const EmailTemplateCard = (props) => {
  const { template } = props;

  const emailType = micoach.EmailTypeEnum.FirstContact;
  const emailSubject = getEmailTemplateSubject(emailType);

  if (!template) return null;

  return (
    <Card className={`Card CardShadow ${styles.TemplateCard}`}>
      <Card.Body>
        <div className={styles.Actions}>
          <DropdownButton
            variant="link"
            title={<FontAwesomeIcon icon={faEllipsisH} />}
          >
            <Dropdown.Item
              as="button"
              eventKey="editButton"
              onClick={() => props.onEdit?.(template)}
            >
              <FontAwesomeIcon icon={faPen} className="Icon" />
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              eventKey="cloneButton"
              onClick={() => props.onClone?.(template)}
            >
              <FontAwesomeIcon icon={faClone} className="Icon" />
              Clone
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              eventKey="deleteButton"
              onClick={() => props.onDelete?.(template)}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="Icon" />
              Delete
            </Dropdown.Item>
          </DropdownButton>
        </div>

        <div>
          <Card.Title className={styles.Title}>{emailSubject}</Card.Title>
          <Card.Subtitle className={styles.Subtitle}>
            <strong>Company:</strong>{" "}
            <span
              className={`${
                template.metadata?.company === "None" ? styles.NoneValue : ""
              }`}
            >
              {template.metadata?.company}
            </span>
          </Card.Subtitle>
          <Card.Subtitle className={styles.Subtitle}>
            <strong>Role:</strong>{" "}
            <span
              className={`${
                template.metadata?.role === "None" ? styles.NoneValue : ""
              }`}
            >
              {template.metadata?.role}
            </span>
          </Card.Subtitle>
        </div>
      </Card.Body>
      <Card.Footer>
        <small className="text-muted">
          Last updated: <FormattedDate>{template.updatedAt}</FormattedDate>
        </small>
      </Card.Footer>
    </Card>
  );
};

EmailTemplateCard.propTypes = {
  template: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  onClone: PropTypes.func,
  onDelete: PropTypes.func,
};

export default EmailTemplateCard;

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

import ActionCard from "Components/Candidate/ActionCard";

const UploadResumeCard = (props) => {
  const { documents } = props;

  const existsResume = documents?.some(
    (document) => document.checklistType === "RESUME"
  );

  return (
    <div className="UploadResumeCard">
      <Link to="/profile?showUploadModal=true">
        <ActionCard
          title="Upload your Resume"
          subtitle="Go to upload resume"
          complete={existsResume}
          points={2}
          icon={faUpload}
        />
      </Link>
    </div>
  );
};

UploadResumeCard.propTypes = {
  documents: PropTypes.array,
};

export default UploadResumeCard;

import Handler from "Components/Evaluation/Skill/Handler";
import Loading from "Components/Loading";
import { useQuery } from "Hooks/useQuery";
import { useSkillEvaluation } from "Hooks/useSkillEvaluation";

const Evaluation = () => {
  const queryParams = useQuery();
  const { evaluation, loadingEvaluation, setEvaluation, skillsByRole } =
    useSkillEvaluation(queryParams);

  // Handler handlers
  const handleEvaluatedSkill = (skill) => {
    const skills = [...evaluation.skills];
    const skillIndex = skills?.findIndex(
      (s) => s.category === skill.category && s.name === skill.name
    );
    skills[skillIndex].value = skill.value;
    skills[skillIndex].valueText = skill.valueText;
    setEvaluation((evaluation) => {
      return { ...evaluation, skills };
    });
  };

  const handleSavedEvaluation = (savedEvaluation) => {
    const newEvaluation = { ...savedEvaluation };

    for (const skill of newEvaluation.skills) {
      const foundSkill = skillsByRole?.find((s) => s.name === skill.name);
      skill.proficiencyLevels = foundSkill?.proficiencyLevels || [];
    }

    setEvaluation(newEvaluation);
  };

  if (loadingEvaluation) {
    return (
      <div className="text-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="Evaluation">
      <Handler
        evaluation={evaluation}
        onEvaluatedSkill={handleEvaluatedSkill}
        onSavedEvaluation={handleSavedEvaluation}
      />
    </div>
  );
};

export default Evaluation;

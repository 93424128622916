import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";

import FilterInput from "Components/Common/FilterInput";
import Loading from "Components/Loading";

const EvaluationPeriodTable = (props) => {
  const { users, loadingEvaluationPeriod } = props;

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredUsers = users?.filter((user) => {
    if (user.userName?.toLowerCase().includes(filterText.toLowerCase()))
      return true;

    return false;
  });

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="mb-3">
        <FilterInput
          filterText={filterText}
          placeholder="Filter by user"
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: <strong>User</strong>,
      selector: (user) => user.userName,
      sortable: true,
      minWidth: "140px",
      wrap: true,
    },
    {
      name: <strong>Coach</strong>,
      selector: (user) => user.coachName,
      sortable: true,
      minWidth: "140px",
      wrap: true,
    },
    {
      name: <strong>Self</strong>,
      selector: (user) => (user.selfCompleted ? "Yes" : "No"),
      sortable: true,
      width: "80px",
    },
    {
      name: <strong>360 request</strong>,
      selector: (user) => user.totalEvaluationRequest ?? 0,
      sortable: true,
      width: "80px",
    },
    {
      name: <strong>360 answered</strong>,
      selector: (user) => user.totalEvaluationRequestAnswered ?? 0,
      sortable: true,
      width: "90px",
    },
    {
      name: <strong>Coach review</strong>,
      selector: (user) => (user.coachReview ? "Yes" : "No"),
      sortable: true,
      width: "70px",
    },
    {
      name: <strong>Agreement</strong>,
      selector: (user) => (user.reviewAgreement ? "Yes" : "No"),
      sortable: true,
      width: "100px",
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={filteredUsers}
      pagination
      paginationResetDefaultPage={resetPaginationToggle}
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      progressPending={loadingEvaluationPeriod}
      progressComponent={<Loading />}
      highlightOnHover
      pointerOnHover
      persistTableHead
    />
  );
};

EvaluationPeriodTable.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string.isRequired,
      userName: PropTypes.string.isRequired,
      coachName: PropTypes.string,
      selfCompleted: PropTypes.bool,
      totalEvaluationRequest: PropTypes.number,
      totalEvaluationRequestAnswered: PropTypes.number,
      coachReview: PropTypes.bool,
      reviewAgreement: PropTypes.bool,
    })
  ),
  loadingEvaluationPeriod: PropTypes.bool,
};

export default EvaluationPeriodTable;

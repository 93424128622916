import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useKeycloak } from "@react-keycloak/web";
import { ApplicationProcessApi, CandidateApi } from "micoach-api";

import CustomSelect from "Components/CustomSelect";
import { useAvailableCandidates } from "Hooks/useCandidates";
import { useAvailablePositions } from "Hooks/usePositions";
import { useRoles } from "Hooks/useRoles";
import { applicationProcessSteps, kanbanColumns } from "constants.js";
import { getIsoStartDate, isEarlyStep, mapToSelectOptions } from "utils.js";

const ApplicationAddModal = (props) => {
  const { candidate, show, updateAP, onClose } = props;

  const [isSaving, setIsSaving] = useState(false);
  const [candidateRole, setCandidateRole] = useState(null);
  const [changingCandidate, setChangingCandidate] = useState(false);

  const { keycloak } = useKeycloak();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
    watch,
  } = useForm({
    mode: "onChange",
  });
  const [loadingPositions, positions, , loadPositions] = useAvailablePositions({
    limit: 100,
  });
  const { roles, loadingRoles } = useRoles();
  const [loadingCandidates, candidates, setQueryCandidateParams] =
    useAvailableCandidates(candidate === undefined);

  const onSubmit = async (formValues) => {
    if (isValid) {
      try {
        setIsSaving(true);
        let response;

        if (updateAP && candidate?.applicationProcess) {
          const applicationProcess = {
            step: props.step ?? formValues.step,
          };

          if (formValues.positionId) {
            applicationProcess.position = formValues.positionId;
          }

          if (formValues.technicalInterviewAt) {
            applicationProcess.technicalInterviewAt = getIsoStartDate(
              formValues.technicalInterviewAt
            );
          }

          response = await ApplicationProcessApi.updateApplicationProcess(
            candidate?.applicationProcess._id,
            applicationProcess
          );
        } else {
          const applicationProcess = {
            step: props.step ?? formValues.step,
            positionId: formValues.positionId,
            candidateId: candidate ? candidate._id : formValues.candidateId,
            role: formValues.role,
            recruiterId: keycloak.tokenParsed.sub,
          };

          if (formValues.technicalInterviewAt) {
            applicationProcess.technicalInterviewAt = getIsoStartDate(
              formValues.technicalInterviewAt
            );
          }

          response =
            await ApplicationProcessApi.createApplicationProcess(
              applicationProcess
            );
        }

        const updatedApplicationProcess = response.data;
        updatedApplicationProcess.steps?.sort((a, b) => {
          return new Date(b.updatedAt) - new Date(a.updatedAt);
        });

        props.onSave(updatedApplicationProcess);
      } catch (error) {
        console.error(error);
        props.onError(
          "Error. Unable to link candidate to the position, try again."
        );
      } finally {
        setIsSaving(false);
      }
    }
  };

  // Roles
  const rolesOptions = mapToSelectOptions(roles, "name", "name");

  // Candidates
  const candidatesOptions = (inputValue) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        setQueryCandidateParams({ search: inputValue, limit: 10 });
        const newValues = candidates.map((candidate) => {
          return {
            value: candidate._id,
            label: candidate.name,
          };
        });
        resolve(newValues);
      }, 100);
    });
  };

  // Positions
  const selectedPositon = watch("positionId");
  const originalSelectedPosition = candidate?.applicationProcess?.position;

  const isOriginalSelectedPositionAvailable = () => {
    if (!originalSelectedPosition) {
      return true;
    }

    return positionsOptions?.some?.(
      (position) => position.value === originalSelectedPosition._id
    );
  };

  const getPositionOption = (position) => {
    if (!position) {
      return;
    }

    return {
      value: position._id,
      label: `${position.title} - ${position.company?.name ?? ""}`,
    };
  };

  const positionsOptions = positions.map((position) =>
    getPositionOption(position)
  );

  const getPositionsOptionsDefaultValue = () => {
    if (isOriginalSelectedPositionAvailable()) {
      return getPositionOption(originalSelectedPosition);
    }
    return null;
  };

  const showPosition = () => {
    const currentStep = props.step || step;

    if (!currentStep) {
      return false;
    }

    return !isEarlyStep(currentStep);
  };

  // Technical interview date
  const showTechnicalInterviewDate = () => {
    const currentStep = props.step || step;

    if (!currentStep) {
      return false;
    }

    if (
      currentStep === kanbanColumns.TECHNICAL_INTERVIEW.key ||
      (!isEarlyStep(currentStep) &&
        !candidate?.applicationProcess?.technicalInterviewAt)
    ) {
      return true;
    }

    return false;
  };

  // Steps
  const step = watch("step");

  const stepsOptions = applicationProcessSteps.filter((step) => {
    return ![
      kanbanColumns.BLOCKED.key,
      kanbanColumns.LEADS.key,
      kanbanColumns.HIRED.key,
      "DISCARDED",
    ].includes(step.value);
  });

  // Candidate role
  const handleChangeCandidate = async (option) => {
    try {
      setChangingCandidate(true);
      if (option) {
        const { data: selectedCandidate } = await CandidateApi.getCandidate(
          option.value
        );

        if (selectedCandidate.role) {
          setCandidateRole({
            value: selectedCandidate.role,
            label: selectedCandidate.role,
          });
        } else {
          setCandidateRole(null);
        }
      }
    } finally {
      setChangingCandidate(false);
    }
  };

  const getCandidateRole = () => {
    if (candidate?.role) {
      return {
        value: candidate.role,
        label: candidate.role,
      };
    }

    return candidateRole;
  };

  useEffect(() => {
    if (show) {
      loadPositions();
    }
  }, [show, loadPositions]);

  useEffect(() => {
    if (show) {
      reset({
        role: candidate?.role ?? null,
        step: props?.step ?? kanbanColumns.PIPELINE.key,
        technicalInterviewAt: candidate?.applicationProcess
          ?.technicalInterviewAt
          ? new Date(candidate.applicationProcess.technicalInterviewAt)
          : null,
      });
    }
  }, [show, candidate, props.step, reset, positions]);

  return (
    <Modal show={show} onHide={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {updateAP ? "Update" : "Start"} hiring process for &apos;
            {candidate ? candidate.name : "candidate"}&apos;
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!candidate && (
            <Form.Group className="form-group required" controlId="candidateId">
              <Form.Label>Search candidate</Form.Label>
              <CustomSelect
                name="candidateId"
                control={control}
                isAsync
                isRequired
                options={candidatesOptions}
                loading={loadingCandidates}
                placeholder="Start typing to search in candidates..."
                onChange={handleChangeCandidate}
              />
              {errors.candidateId && (
                <div className="invalid-feedback d-block">
                  {errors.candidateId.type === "required" &&
                    "The candidate is required"}
                </div>
              )}
            </Form.Group>
          )}

          {!props.step && (
            <Form.Group className="form-group required" controlId="step">
              <Form.Label>Hiring step process</Form.Label>
              <CustomSelect
                name="step"
                control={control}
                isRequired
                options={stepsOptions}
                defaultValue={stepsOptions[0]}
              />
              {errors.step && (
                <div className="invalid-feedback d-block">
                  {errors.step.type === "required" && "Step is required"}
                </div>
              )}
            </Form.Group>
          )}

          {!candidate?.applicationProcess?.role && !changingCandidate && (
            <Form.Group controlId="role" className="form-group required">
              <Form.Label>Search role</Form.Label>
              <CustomSelect
                name="role"
                control={control}
                isRequired
                options={rolesOptions}
                defaultValue={getCandidateRole()}
                loading={loadingRoles}
                placeholder="Start typing to search in roles..."
              />
              {errors.role && (
                <div className="invalid-feedback d-block">
                  {errors.role.type === "required" && "The role is required"}
                </div>
              )}
            </Form.Group>
          )}

          {showPosition() && (
            <Form.Group controlId="positionId" className="form-group required">
              <Form.Label>Search position</Form.Label>
              <CustomSelect
                name="positionId"
                control={control}
                isRequired
                isInvalid={!!errors.positionId}
                options={positionsOptions}
                loading={loadingPositions}
                defaultValue={getPositionsOptionsDefaultValue()}
                placeholder="Start typing to search in positions..."
              />
              {errors.positionId && (
                <div className="invalid-feedback d-block">
                  {errors.positionId.type === "required" &&
                    "Position is required"}
                </div>
              )}
              {!isOriginalSelectedPositionAvailable() && !selectedPositon && (
                <div className="invalid-feedback d-block">
                  The initial position is now closed. Please select an
                  alternative option.
                </div>
              )}
            </Form.Group>
          )}

          {showTechnicalInterviewDate() && (
            <Form.Group
              controlId="technicalInterviewAt"
              className="form-group required"
            >
              <Form.Label>Technical interview date</Form.Label>
              <Controller
                name="technicalInterviewAt"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    showPopperArrow={false}
                    selected={value}
                    onChange={onChange}
                    dateFormat="MMM dd, yyyy"
                    className={`form-control ${
                      errors.technicalInterviewAt ? "is-invalid" : ""
                    }`}
                  />
                )}
              />
              {errors.technicalInterviewAt && (
                <div className="invalid-feedback d-block">
                  {errors.technicalInterviewAt.type === "required" &&
                    "Technical interview date is required"}
                </div>
              )}
            </Form.Group>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="CancelButton"
            disabled={isSaving}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="SaveButton"
            disabled={!isValid || isSaving}
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

ApplicationAddModal.propTypes = {
  show: PropTypes.bool,
  updateAP: PropTypes.bool,
  step: PropTypes.string,
  candidate: PropTypes.object,
  applicationId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

export default ApplicationAddModal;

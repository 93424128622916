import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";

import { proficiencyLevels } from "constants.js";

import styles from "Components/Evaluation/Skill/styles/ProficiencyLevelsInstructionsCard.module.css";

const ProficiencyLevelsInstructionsCard = (props) => {
  const { onDashboard } = props;

  return (
    <Card
      className={`Card  ${
        !onDashboard ? styles.ProficiencyLevelsInstructions : ""
      } `}
    >
      <Card.Body>
        <h2>Skill / knowledge improvement based on job experience and time</h2>
        <div className={styles.Graph}></div>
        <div className={styles.ProficiencyLevels}>
          {proficiencyLevels.map((proficiencyLevel) => {
            return (
              <div
                key={proficiencyLevel.level}
                className={styles.ProficiencyLevel}
              >
                <span>PL{proficiencyLevel.level}</span>
                <span>{proficiencyLevel.levelText}</span>
              </div>
            );
          })}
        </div>
      </Card.Body>
    </Card>
  );
};

ProficiencyLevelsInstructionsCard.propTypes = {
  onDashboard: PropTypes.bool,
};

ProficiencyLevelsInstructionsCard.defaultProps = {
  onDashboard: false,
};

export default ProficiencyLevelsInstructionsCard;

import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";

import styles from "Components/Admin/styles/AdminItemCard.module.css";

const AdminItemCard = (props) => {
  const { title, description, imageUrl } = props;

  return (
    <Card
      className={`Card CardShadow ${styles.AdminItemCard}`}
      onClick={props.onClick}
    >
      <Card.Body>
        <Card.Title>{title}</Card.Title>

        {imageUrl && (
          <div className={styles.Image}>
            <img src={imageUrl} alt={title} />
          </div>
        )}

        <Card.Subtitle>{description}</Card.Subtitle>
      </Card.Body>
    </Card>
  );
};

AdminItemCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  onClick: PropTypes.func,
};

export default AdminItemCard;

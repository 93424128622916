import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Badge from "react-bootstrap/Badge";
import { faCheckCircle as farCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle as fasCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import micoach from "micoach-api";

import styles from "Components/Task/styles/TaskStatus.module.css";

const TaskStatus = (props) => {
  const { read, status, selected } = props;

  const [statusIcon, setStatusIcon] = useState(fasCheckCircle);

  const getStatusClass = (status) => {
    switch (status) {
      case micoach.TaskStatusEnum.ToDo:
        return styles.ToDo;
      case micoach.TaskStatusEnum.Verify:
        return styles.Verify;
      case micoach.TaskStatusEnum.Done:
        return styles.Done;
      default:
        return "";
    }
  };

  useEffect(() => {
    if (read) {
      setStatusIcon(farCheckCircle);
    }
  }, [read]);

  return (
    <div
      className={`${selected ? styles.Selected : ""} ${styles.Status} `}
      data-testid="task-status"
    >
      <FontAwesomeIcon icon={statusIcon} className={styles.StatusIcon} />
      {!!status && (
        <Badge pill className={`${styles.Badge} ${getStatusClass(status)}`}>
          {status}
        </Badge>
      )}
    </div>
  );
};

TaskStatus.propTypes = {
  read: PropTypes.bool,
  status: PropTypes.string,
  selected: PropTypes.bool,
};

TaskStatus.defaultProps = {
  read: false,
  selected: false,
};

export default TaskStatus;

import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import FormattedDate from "Components/FormattedDate";
import { getPeriodString, getQuarterYear } from "utils.js";

import styles from "Components/Evaluation/Skill/styles/EvaluationList.module.css";

const EvaluationList = (props) => {
  const {
    evaluations,
    balanceDate: { quarter: currentQuarter, year: currentYear },
  } = props;

  const completedEvaluations = evaluations
    ?.map((evaluation) => ({
      ...evaluation,
      balanceDate: {
        year: new Date(evaluation.createdAt).getFullYear(),
        quarter: getQuarterYear(evaluation.createdAt),
      },
    }))
    .filter(
      (evaluation) =>
        evaluation.completed === true &&
        evaluation.period !== getPeriodString(currentYear, currentQuarter)
    );

  return (
    completedEvaluations.length > 0 && (
      <div className={styles.EvaluationList}>
        <div className={styles.PreviousEvaluations}>
          <h2>Previous assessments</h2>
          <Card className="Card">
            <Card.Body>
              <ListGroup variant="flush">
                {completedEvaluations?.map((evaluation) => {
                  return (
                    <ListGroup.Item
                      className={styles.ListGroupContainer}
                      key={evaluation._id}
                    >
                      <div>
                        <div className={styles.EvaluationName}>
                          {evaluation.name}
                        </div>
                        <div>{evaluation.userRole}</div>
                      </div>
                      <div className={styles.EvaluationDate}>
                        <FormattedDate>{evaluation.createdAt}</FormattedDate>
                      </div>
                      <div className={styles.EvaluationLink}>
                        <Link
                          to={`/evaluation/skill/view?quarter=${evaluation.balanceDate.quarter}&year=${evaluation.balanceDate.year}`}
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faEye} className="me-2" />
                          View evaluation
                        </Link>
                      </div>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Card.Body>
          </Card>
        </div>
      </div>
    )
  );
};

EvaluationList.propTypes = {
  evaluations: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      _id: PropTypes.string,
      name: PropTypes.string,
      userRole: PropTypes.string,
    })
  ).isRequired,
  balanceDate: PropTypes.shape({
    quarter: PropTypes.number,
    year: PropTypes.number,
  }).isRequired,
};

export default EvaluationList;

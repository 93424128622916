import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import dayjs from "dayjs";

import styles from "./styles/ReferralDetailsModal.module.css";

const ReferralDetailsModal = (props) => {
  const { show = false, referral = {}, onClose } = props;

  const daysSinceReferred = dayjs(referral?.createdAt).fromNow();

  return (
    <Modal show={show} onHide={onClose} className={styles.ReferralDetailsModal}>
      <Modal.Header closeButton>
        <Modal.Title>Referred prospect information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <strong>Referred by:</strong>
          <div>
            <span>{referral?.referrerName}</span>
            <span className={styles.Email}>{referral?.referrerEmail}</span>
          </div>
        </div>
        <div>
          <strong>Referred role: </strong>
          {referral?.role}
        </div>
        <div>
          <strong>When was referred: </strong>
          {daysSinceReferred}
        </div>
        <div>
          <strong>Reason to refer prospect:</strong>
          <span>{referral?.comments}</span>
        </div>
      </Modal.Body>
    </Modal>
  );
};

ReferralDetailsModal.propTypes = {
  show: PropTypes.bool,
  referral: PropTypes.shape({
    referrerName: PropTypes.string,
    referrerEmail: PropTypes.string,
    role: PropTypes.string,
    createdAt: PropTypes.string,
    comments: PropTypes.string,
  }),
  onClose: PropTypes.func,
};

export default ReferralDetailsModal;

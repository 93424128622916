import { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import { useForm } from "react-hook-form";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "Components/styles/SearchBar.css";

const SearchBar = (props) => {
  const { myDataLabel, queryParams, children, disabled, onSearch } = props;

  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (formValues) => {
    onSearch?.(formValues);
  };

  useEffect(() => {
    const search = queryParams?.get?.("search") ?? "";
    reset({
      search,
    });
  }, [reset, queryParams]);

  return (
    <section className="SearchBar">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup>
          <InputGroup.Text className="text-primary">
            <FontAwesomeIcon icon={faSearch} size="lg" />
          </InputGroup.Text>
          <FormControl
            aria-label="Search"
            type="text"
            name="search"
            {...register("search")}
            disabled={disabled}
          />
          {children}
          {myDataLabel && (
            <div className="Switch">
              <span>{myDataLabel}</span>
              <Form.Check
                type="switch"
                name="myData"
                id="custom-switch"
                label=""
                {...register("myData")}
                disabled={disabled}
              />
            </div>
          )}
          <Button type="submit" variant="primary" disabled={disabled}>
            Search
          </Button>
        </InputGroup>
      </Form>
    </section>
  );
};

SearchBar.propTypes = {
  myDataLabel: PropTypes.string,
  queryParams: PropTypes.any,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  onSearch: PropTypes.func,
};

SearchBar.defaultProps = {
  myDataLabel: "",
  queryParams: "",
  disabled: false,
};

export default SearchBar;

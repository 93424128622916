import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";

import "Components/Common/styles/CheckboxListModal.css";

const formInitialState = {
  items: [],
};

function CheckboxListModal(props) {
  const {
    show,
    disabled,
    title,
    subtitle,
    options,
    defaultValue,
    singleColumnLimit,
    id,
    label,
    value,
    cancelButtonText,
    confirmButtonText,
    onClose,
    onConfirm,
  } = props;

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: formInitialState,
  });

  const handleClose = () => {
    reset(formInitialState);
    onClose();
  };

  const onSubmit = async (formValues) => {
    const values = Array.isArray(formValues)
      ? formValues.items
      : [formValues.items];
    onConfirm && onConfirm(values);
  };

  const renderItems = (options) => {
    return options.map((option) => {
      return (
        <Form.Check
          type="checkbox"
          name="items"
          key={option[id]}
          id={option[id]}
          value={option[value]}
          label={option[label]}
          className="mb-2 fw-bold fs-13"
          defaultChecked={defaultValue.some(
            (val) => val[value] === option[value]
          )}
          custom
          disabled={disabled}
          {...register("items", { required: true })}
        />
      );
    });
  };

  const renderColumns = () => {
    if (options.length > singleColumnLimit) {
      const middleItem = Math.round(options.length / 2);
      const optionsCol1 = options.slice(0, middleItem);
      const optionsCol2 = options.slice(middleItem, options.length);
      return (
        <Row>
          <Col>{renderItems(optionsCol1)}</Col>
          <Col>{renderItems(optionsCol2)}</Col>
        </Row>
      );
    } else {
      return renderItems(options);
    }
  };

  return (
    <Modal className="CheckboxListModal" show={show} onHide={handleClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
            {subtitle && <p className="mt-2 fs-16 fw-normal">{subtitle}</p>}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {renderColumns()}
          {errors.items && (
            <div className="invalid-feedback d-block">
              {errors.items.type === "required" && "Select at least one option"}
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="CancelButton"
            disabled={disabled}
            onClick={handleClose}
          >
            {cancelButtonText}
          </Button>
          <Button
            type="submit"
            className="SaveButton"
            disabled={!isValid || disabled}
          >
            {confirmButtonText}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

CheckboxListModal.propTypes = {
  show: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.object),
  singleColumnLimit: PropTypes.number,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
};

CheckboxListModal.defaultProps = {
  options: [],
  defaultValue: [],
  cancelButtonText: "Cancel",
  confirmButtonText: "OK",
  singleColumnLimit: 5,
};

export default CheckboxListModal;

import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ListGroup from "react-bootstrap/ListGroup";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  faCommentAlt,
  faEllipsisH,
  faPen,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BackendService from "../Services/BackendService";

import "./styles/Comment.css";

import FormattedDate from "./FormattedDate";
import UserImage from "./UserImage";

function Comment(props) {
  const { comment, userId, isEditable } = props;

  // Menu
  const [showMenu, setShowMenu] = useState(false);
  const [targetMenu, setTargetMenu] = useState(null);
  const refMenu = useRef(null);

  const handleShowMenu = (event) => {
    setShowMenu(true);
    setTargetMenu(event.target);
  };

  const handleHideMenu = () => {
    setShowMenu(false);
    setTargetMenu(null);
  };

  // Menu Items
  const handleClickDelete = async () => {
    setShowMenu(false);
    setTargetMenu(null);
    try {
      await BackendService.deleteComment(comment._id);
      props.onDeleted(comment._id);
    } catch {
      toast.error("Error! Failed to delete the comment.");
    }
  };

  const handleClickEdit = () => {
    setShowMenu(false);
    setTargetMenu(null);
    setIsEditing(true);
  };

  // Edit form
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    if (comment) {
      reset({
        comment: comment.text,
      });
    }
  }, [comment, reset]);

  const handleSubmitComment = async (formValues) => {
    try {
      setIsSaving(true);

      let updatedComment = {
        text: formValues.comment,
      };

      updatedComment = await BackendService.updateComment(
        comment._id,
        updatedComment
      );

      reset();
      setIsEditing(false);
      props.onUpdated(updatedComment);
    } catch {
      toast.error("Error! The comment was not updated.");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <ListGroup.Item className="Comment" ref={refMenu}>
      {isEditable && userId === comment.user && !isEditing && (
        <div className="MenuButton">
          <Button variant="link" onClick={handleShowMenu} className="Ellipsis">
            <FontAwesomeIcon icon={faEllipsisH} />
          </Button>

          <Overlay
            show={showMenu}
            target={targetMenu}
            placement="bottom"
            container={refMenu.current}
            rootClose={true}
            onHide={handleHideMenu}
          >
            {(props) => (
              <Popover id="popover" className="Menu" {...props}>
                <Popover.Body>
                  <Button variant="link" onClick={handleClickEdit}>
                    <FontAwesomeIcon icon={faPen} className="Icon me-2" />
                    Edit
                  </Button>
                  <Button variant="link" onClick={handleClickDelete}>
                    <FontAwesomeIcon icon={faTrashAlt} className="Icon me-2" />
                    Delete
                  </Button>
                </Popover.Body>
              </Popover>
            )}
          </Overlay>
        </div>
      )}

      {!isEditing && (
        <div className="Info">
          <UserImage className="me-2" userName={comment.userName} />
          <span className="RecruiterName">{comment.userName} </span>
          <span className="Date">
            <FormattedDate>{comment.createdAt}</FormattedDate>
          </span>
        </div>
      )}

      {isEditable && isEditing ? (
        <Form onSubmit={handleSubmit(handleSubmitComment)}>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faCommentAlt} className="Icon" />
            </InputGroup.Text>
            <Form.Control
              type="text"
              as="textarea"
              rows={6}
              name="comment"
              className="h-100 m-2"
              {...register("comment", { required: true })}
              disabled={isSaving}
            />
          </InputGroup>
          <div className="float-end">
            <Button
              variant="link"
              className="CancelButton"
              disabled={isSaving}
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="secondary"
              className="SaveButton"
              disabled={isSaving}
            >
              Save
            </Button>
          </div>
        </Form>
      ) : (
        <div className="Text">{comment.text}</div>
      )}
    </ListGroup.Item>
  );
}

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  onDeleted: PropTypes.func.isRequired,
  onUpdated: PropTypes.func.isRequired,
};

export default Comment;

import PropTypes from "prop-types";

import styles from "Components/Common/styles/ColoredCircle.module.css";

const ColoredCircle = (props) => {
  const { value, size, colorClassName } = props;

  const sizeClass = styles[size];
  const colorClass = colorClassName || styles.DefaultColor;

  return (
    <div
      data-testid="colored-circle"
      className={`${styles.Circle} ${sizeClass} ${colorClass}`}
    >
      <span className={styles.Value}>{value}</span>
    </div>
  );
};

ColoredCircle.propTypes = {
  value: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  colorClassName: PropTypes.string,
};

ColoredCircle.defaultProps = {
  value: "",
  size: "small",
};

export default ColoredCircle;

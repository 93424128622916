import PropTypes from "prop-types";

import ReferralsOverviewSummaryItem from "Components/Refer/ReferralsOverviewSummaryItem";

import styles from "Components/Refer/styles/ReferralsOverviewSummary.module.css";

const ReferralsOverviewSummary = (props) => {
  const { summaryStatus } = props;

  return (
    <div className={styles.Summary}>
      <ReferralsOverviewSummaryItem
        value={summaryStatus?.referred}
        title="Referred"
      />
      <ReferralsOverviewSummaryItem
        value={summaryStatus?.readyToClaim}
        title="Ready to claim"
      />
      <ReferralsOverviewSummaryItem
        value={summaryStatus?.claimed}
        title="Claimed"
      />
    </div>
  );
};

ReferralsOverviewSummary.propTypes = {
  summaryStatus: PropTypes.shape({
    referred: PropTypes.number,
    readyToClaim: PropTypes.number,
    claimed: PropTypes.number,
  }),
};

export default ReferralsOverviewSummary;

import PropTypes from "prop-types";
import { Radar } from "react-chartjs-2";

import { capitalize, sortSkillsByCategoryAndName } from "utils.js";

const SkillBalanceChart = (props) => {
  const {
    summary: { self, requested, expectedSkillProficiencyLevel },
  } = props;
  const datasets = [];
  const labels = [];
  const selfSkills = sortSkillsByCategoryAndName(self, "asc");
  const requestedSkills = sortSkillsByCategoryAndName(requested, "asc");

  // Set datasets
  if (selfSkills.length) {
    datasets.push({
      label: "Self assessment",
      backgroundColor: "rgba(4, 164, 145, 0.4)",
      borderWidth: 0,
      data: selfSkills.map((skill) => skill.value),
    });
  } else {
    return null;
  }
  if (requestedSkills.length) {
    datasets.push({
      label: "Requested assessment",
      data: requestedSkills.map((skill) => skill.value),
      backgroundColor: "rgba(0, 0, 0, 0)",
      borderColor: "rgb(11, 68, 170)",
      borderWidth: 1,
      borderDash: [2],
    });
  }
  if (expectedSkillProficiencyLevel) {
    datasets.push({
      label: "Expected Proficency Level",
      data: selfSkills.map(() => expectedSkillProficiencyLevel),
      backgroundColor: "rgba(0, 0, 0, 0)",
      borderColor: "rgb(249, 93, 119)",
      borderWidth: 1,
      borderDash: [5],
    });
  }

  // Set labels
  selfSkills.forEach((skill) => {
    labels.push(`${capitalize(skill.category)} - ${skill.name}`);
  });

  const data = {
    labels,
    datasets,
  };

  const options = {
    scales: {
      r: {
        angleLines: {
          display: false,
        },
        min: -1,
        max: 4,
        ticks: {
          stepSize: 1,
          z: 1,
          backdropColor: "rgba(0, 0, 0, 0)",
          callback: (value) => (value === 0 ? "NA" : value),
        },
      },
    },
  };

  return <Radar data={data} options={options} className="p-3" />;
};

SkillBalanceChart.propTypes = {
  summary: PropTypes.shape({
    userName: PropTypes.string.isRequired,
    userRole: PropTypes.string.isRequired,
    self: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      })
    ),
    requested: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      })
    ),
    expectedSkillProficiencyLevel: PropTypes.any,
  }).isRequired,
};

export default SkillBalanceChart;

import { Country, State } from "country-state-city";

export const getCountryNameByCode = (countryCode) => {
  if (!countryCode) return "";
  const country = Country.getCountryByCode(countryCode);
  return country?.name;
};

export const getStateNameByCodeAndCountry = (stateCode, countryCode) => {
  if (!stateCode) return "";
  if (!countryCode) return "";
  const state = State.getStateByCodeAndCountry(stateCode, countryCode);
  return state?.name;
};

import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { PositionStatusEnum, ReferralStatusEnum } from "@micoach/js-sdk";
import { ReferApi } from "micoach-api";
import { getReferralStatusLabel } from "utils";

import SelectedFiltersV2 from "Components/Common/SelectedFiltersV2";
import ConfirmModal from "Components/ConfirmModal";
import ReferralHeader from "Components/Refer/ReferralHeader";
import ReferralPaymentConfirmationModal from "Components/Refer/ReferralPaymentConfirmationModal";
import ReferralsFilterButton from "Components/Refer/ReferralsFilterButton";
import ReferralsOverviewCard from "Components/Refer/ReferralsOverviewCard";
import { useFilters, useSelectedFilters } from "Hooks/useFilters";
import { useJobPositions } from "Hooks/usePositions";
import { useReferrals } from "Hooks/useReferrals";

import styles from "Screens/Refer/styles/ListReferrals.module.css";

const referralStatus = [
  ReferralStatusEnum.Hired,
  ReferralStatusEnum.Claimed,
  ReferralStatusEnum.Reviewing,
  ReferralStatusEnum.PendingPayment,
  ReferralStatusEnum.ReadyToClaim,
  ReferralStatusEnum.Referred,
  ReferralStatusEnum.InHiringProcess,
];

const DEFAULT_STATUS = referralStatus.map((statusValue) => ({
  value: statusValue,
  label: getReferralStatusLabel(statusValue, true),
}));

const ListReferrals = () => {
  const { referrals, setReferrals, loadReferrals, loadingReferrals } =
    useReferrals();
  const { jobPositions, loadJobPositions } = useJobPositions();

  const { filters, setFilters, removeFilter } = useFilters({
    status: DEFAULT_STATUS,
  });
  const { selectedFilters } = useSelectedFilters(filters);

  const getQuery = useCallback((data) => {
    const query = {};
    data.status?.length &&
      (query.status = data.status.map((status) => status.value));
    data.position && (query.position = data.position.value);

    return query;
  }, []);

  const [showPaymentConfirmationModal, setShowPaymentConfirmationModal] =
    useState(false);
  const [selectedReferralId, setSelectedReferralId] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showConfirmDismiss, setShowConfirmDismiss] = useState(false);

  const updateReferral = (referral) => {
    setReferrals((prevReferrals) => {
      const index = prevReferrals.findIndex(
        (prevReferral) => prevReferral._id === referral._id
      );
      const newReferrals = [...prevReferrals];

      newReferrals[index] = referral;

      return newReferrals;
    });
  };

  const handleChangeStatus = async (referralId, status) => {
    try {
      setIsSaving(true);

      const { data: updatedReferral } = await ReferApi.updateReferralStatus(
        referralId,
        {
          status,
        }
      );

      updateReferral(updatedReferral);

      setShowConfirmDismiss(false);
      toast.success("Success! Referral has been updated.");
    } catch (error) {
      const status = error.response?.status;
      const errorMessage = error.response?.data?.errors?.[0]?.message;

      if (errorMessage) {
        toast.error(errorMessage);
      } else if (status === 404) {
        toast.error("Referral not found.");
      } else {
        toast.error("Error, please try again.");
      }

      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleClosePaymentConfirmationModal = () => {
    setSelectedReferralId(null);
    setShowPaymentConfirmationModal(false);
  };

  const handleUpdateStatus = async (referralId, status) => {
    switch (status) {
      case ReferralStatusEnum.Dismissed:
        setSelectedReferralId(referralId);
        setShowConfirmDismiss(true);
        break;

      case ReferralStatusEnum.PendingPayment:
        setSelectedReferralId(referralId);
        await handleChangeStatus(referralId, status);
        break;

      case ReferralStatusEnum.Claimed:
        setSelectedReferralId(referralId);
        setShowPaymentConfirmationModal(true);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    loadReferrals(getQuery(filters));
  }, [loadReferrals, filters, getQuery]);

  useEffect(() => {
    loadJobPositions({ status: [PositionStatusEnum.Open] });
  }, [loadJobPositions]);

  return (
    <>
      <ReferralPaymentConfirmationModal
        show={showPaymentConfirmationModal}
        referralId={selectedReferralId}
        onClose={handleClosePaymentConfirmationModal}
        onSaved={(updatedReferral) => updateReferral(updatedReferral)}
      />

      <ConfirmModal
        title="Dismiss referral"
        confirmButtonText="Dismiss"
        show={showConfirmDismiss}
        isLoading={isSaving}
        onClose={() => setShowConfirmDismiss(false)}
        onConfirm={() =>
          handleChangeStatus(selectedReferralId, ReferralStatusEnum.Dismissed)
        }
      >
        <span>
          Are you sure you want to dismiss this referral? <br />
          This action cannot be undone.
        </span>
      </ConfirmModal>
      <div className={styles.Header}>
        <ReferralHeader isHumanResources />
      </div>

      <div className={styles.Filters}>
        <div>
          <SelectedFiltersV2
            filterValues={selectedFilters}
            icon={faTimes}
            iconPosition="right"
            onClickDeleteBadge={(option) => removeFilter(option)}
          />
        </div>
        <ReferralsFilterButton
          filterValues={filters}
          positions={jobPositions}
          loading={loadingReferrals}
          onApplyFilters={(filters) => setFilters(filters)}
        />
      </div>

      <ReferralsOverviewCard
        referrals={referrals}
        loading={loadingReferrals}
        isSaving={isSaving}
        isHumanResources
        onUpdateStatus={handleUpdateStatus}
      />
    </>
  );
};

export default ListReferrals;

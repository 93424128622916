import { useCallback, useEffect, useState } from "react";
import { UserApi } from "micoach-api";

export const useEvaluations = (userId) => {
  const [evaluations, setEvaluations] = useState([]);
  const [loadingEvaluations, setLoadingEvaluations] = useState(false);

  const loadEvaluations = useCallback(async () => {
    try {
      setLoadingEvaluations(true);
      const { data } = await UserApi.listUserEvaluations(userId);
      setEvaluations(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEvaluations(false);
    }
  }, [userId]);

  useEffect(() => {
    loadEvaluations();
  }, [loadEvaluations]);

  return { evaluations, loadingEvaluations };
};

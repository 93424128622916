import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GoBackButton = (props) => {
  const { className, path, label } = props;
  const history = useHistory();

  return (
    <Button
      variant="link"
      className={`px-0 py-3 ${className || ""}`}
      onClick={() => {
        !path ? history.goBack() : history.push(path);
      }}
    >
      <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
      {label}
    </Button>
  );
};

GoBackButton.propTypes = {
  className: PropTypes.string,
  path: PropTypes.string,
  label: PropTypes.string,
};

GoBackButton.defaultProps = {
  label: "Back",
};

export default GoBackButton;

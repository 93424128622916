import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { toast } from "react-toastify";

import AchievementAddEditModal from "Components/Admin/Achievements/AchievementAddEditModal";
import AchievementPreviewModal from "Components/Admin/Achievements/AchievementPreviewModal";
import AchievementTable from "Components/Admin/Achievements/AchievementTable";
import GoBackButton from "Components/GoBackButton";
import { useAchievements } from "Hooks/useAchievements";
import { SUBMIT_TYPES } from "constants.js";

const Achievements = () => {
  const [showAchievementAddEditModal, setShowAchievementAddEditModal] =
    useState(false);
  const [showAchievementPreviewModal, setShowAchievementPreviewModal] =
    useState(false);

  const {
    achievement,
    achievements,
    isLoading,
    getAchievement,
    getAchivements,
    sendAchievement,
    isSendingAchievement,
  } = useAchievements();

  const handleSavedAchievement = async (achievementId, submitType) => {
    await getAchivements();

    if (submitType === SUBMIT_TYPES.PREVIEW) {
      await getAchievement({
        achievementId,
      });
      setShowAchievementAddEditModal(false);
      setShowAchievementPreviewModal(true);
    }
  };

  const handleClickSendAchievement = async (achievementId) => {
    await sendAchievement({ achievementId });
    await getAchivements();
    setShowAchievementPreviewModal(false);
    toast.success("Success! The achievement has been sent.");
  };

  useEffect(() => {
    getAchivements();
  }, [getAchivements]);

  return (
    <>
      <GoBackButton label="Admin" path="/admin" />

      <Row>
        <Col>
          <h1 className="ScreenTitle">Achievements</h1>
        </Col>
        <Col className="text-end">
          <Button
            variant="secondary"
            className="AddButton"
            onClick={() => setShowAchievementAddEditModal(true)}
          >
            Create Achievement
          </Button>
        </Col>
      </Row>

      <Card className="Card">
        <Card.Body>
          <AchievementTable achievements={achievements} loading={isLoading} />
        </Card.Body>
      </Card>

      <AchievementAddEditModal
        show={showAchievementAddEditModal}
        onSaved={handleSavedAchievement}
        onClose={() => setShowAchievementAddEditModal(false)}
      />

      <AchievementPreviewModal
        show={showAchievementPreviewModal}
        achievement={achievement}
        isSending={isSendingAchievement}
        onClickSend={handleClickSendAchievement}
        onClose={() => setShowAchievementPreviewModal(false)}
      />
    </>
  );
};

export default Achievements;

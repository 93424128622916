import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import micoach from "micoach-api";

import RecentActivityItem from "Components/Candidate/RecentActivity/RecentActivityItem";

import styles from "Components/Candidate/RecentActivity/styles/RecentActivityList.module.css";

const RecentActivityList = (props) => {
  const { actions } = props;

  const [actionsWithDescriptionAndImage, setActionsWithDescriptionAndImage] =
    useState([]);

  const getActionsWithDescriptionAndImage = useCallback((actions) => {
    return actions.map((action) => {
      let description, imageUrl;
      switch (action.name) {
        case micoach.CandidateActionsEnum.CompleteEvent:
          description = `Event completed: ${action.metaData.name}`;
          imageUrl =
            "https://micoach-prod-bucket.s3.amazonaws.com/assets/top-of-class.jpg";
          break;
        case micoach.CandidateActionsEnum.UploadResume:
          description = "Resume uploaded";
          imageUrl =
            "https://micoach-prod-bucket.s3.amazonaws.com/assets/task-complete.jpg";
          break;
        case micoach.CandidateActionsEnum.CompleteTraining:
          description = `Training completed: ${action.metaData.name}`;
          imageUrl =
            "https://micoach-prod-bucket.s3.amazonaws.com/assets/top-of-class.jpg";
          break;
        case micoach.CandidateActionsEnum.CompleteEventProject:
          description = `Event project completed: ${action.metaData.name}`;
          imageUrl =
            "https://micoach-prod-bucket.s3.amazonaws.com/assets/task-complete.jpg";
          break;
        default:
          break;
      }

      return { ...action, description, imageUrl };
    });
  }, []);

  useEffect(() => {
    setActionsWithDescriptionAndImage(
      getActionsWithDescriptionAndImage(actions)
    );
  }, [actions, getActionsWithDescriptionAndImage]);

  return (
    <>
      <div className={styles.Container}>
        {actionsWithDescriptionAndImage.map(
          ({ description, points, imageUrl, date }, key) => {
            return (
              <RecentActivityItem
                key={key}
                description={description}
                date={date}
                points={points}
                imageUrl={imageUrl}
              />
            );
          }
        )}
      </div>
    </>
  );
};

RecentActivityList.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      points: PropTypes.number.isRequired,
      date: PropTypes.string.isRequired,
      metaData: PropTypes.object,
    })
  ).isRequired,
};

export default RecentActivityList;

import PropTypes from "prop-types";

import styles from "Components/Ranking/styles/UserPointsByPeriod.module.css";

const UserPointsByPeriod = (props) => {
  const { points } = props;

  return (
    <div className={styles.UserPointsByPeriod}>
      {points > 0
        ? `You earned ${points} pts in the last week`
        : "Review our content on demand or complete your profile on MiCoach and earn points!"}
    </div>
  );
};

UserPointsByPeriod.propTypes = {
  points: PropTypes.number,
};

UserPointsByPeriod.defaultProps = {
  points: 0,
};

export default UserPointsByPeriod;

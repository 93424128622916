import { useCallback, useContext } from "react";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { Flip, toast } from "react-toastify";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { App } from "@itj-micoach/micoach-common";
import { NotificationSourceEnum } from "@micoach/js-sdk";
import { NotificationApi } from "micoach-api";

import AlertBody from "Components/Common/AlertBody";
import { NotificationsContext } from "Context/NotificationsProvider";

const TOAST_CONTAINERS_IDS = {
  ALERTS: "alerts",
};
const SOURCES = {
  [NotificationSourceEnum.CompleteProfile]: {
    BUTTON_TEXT: "Complete your profile",
    APP: App.React,
  },
  [NotificationSourceEnum.NewTraining]: {
    BUTTON_TEXT: "Explore Training Now",
    APP: App.Next,
  },
  [NotificationSourceEnum.NewEvent]: {
    BUTTON_TEXT: "Explore Event Now",
    APP: App.Next,
  },
};

export const useAlert = () => {
  const { reloadNotifications } = useContext(NotificationsContext);

  const history = useHistory();

  const readAlert = useCallback(
    async (id) => {
      try {
        await NotificationApi.updateNotifications({
          notificationIds: [id],
          read: true,
        });
        reloadNotifications();
      } catch (error) {
        console.error("Error: Could not update notification.", error);
      }
    },
    [reloadNotifications]
  );

  const handleClick = useCallback(
    async (toastId, url, source) => {
      await readAlert(toastId);

      if (SOURCES[source].APP === App.React) {
        history.push(new URL(url)?.pathname);
      } else {
        window.location.href = url;
      }
    },
    [readAlert, history]
  );

  const handleClose = useCallback(
    async (toastId) => {
      await readAlert(toastId);

      toast.dismiss(toastId);
    },
    [readAlert]
  );

  const showAlert = useCallback(
    ({ _id, source, title, content, actionUrl }) => {
      if (!source) {
        console.warn("The source is required to display an alert.");
        return;
      }

      if (!title && !content && !actionUrl) {
        console.warn(
          "The alert has no title, content, or action URL to display."
        );
        return;
      }

      const alertBodyProps = {
        title,
        content,
        buttonText: SOURCES[source]?.BUTTON_TEXT,
        actionUrl,
        onClick: () => handleClick(_id, actionUrl, source),
      };
      const toastOptions = {
        containerId: TOAST_CONTAINERS_IDS.ALERTS,
        toastId: _id,
        type: toast.TYPE.INFO,
        transition: Flip,
        icon: () => <img src="/assets/icon-micoach-white.png" alt="micoach" />,
        style: {
          backgroundColor: "var(--blue-light-color)",
        },
        autoClose: false,
        closeOnClick: false,
        closeButton: (
          <Button
            variant="link"
            className="p-1 h-100 text-light"
            onClick={() => handleClose(_id)}
          >
            <FontAwesomeIcon icon={faXmark} />
          </Button>
        ),
      };

      toast(<AlertBody {...alertBodyProps} />, toastOptions);
    },
    [handleClick, handleClose]
  );

  return { showAlert, TOAST_CONTAINERS_IDS };
};

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import { SeniorityEnum } from "@micoach/js-sdk";
import { PositionTemplateApi } from "micoach-api";

import FormControlErrorMessage from "Components/Common/FormControlErrorMessage";
import CustomSelect from "Components/CustomSelect";
import { getCategorizedSkills } from "utils.js";

import styles from "Components/Job/Template/styles/JobTemplateAddEditModal.module.css";

const JobTemplateAddEditModal = (props) => {
  const { show, skills, template, onClose, onSaved } = props;

  const isEditing = !!template?._id;

  const [isSaving, setIsSaving] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    reset,
  } = useForm({
    mode: "onChange",
  });

  const about = watch("about");
  const responsibilities = watch("responsibilities");
  const qualifications = watch("qualifications");

  const {
    hardSkills: selectedHardSkills,
    softSkills: selectedSoftSkills,
    techSkills: selectedTechSkills,
  } = getCategorizedSkills(template?.skills);

  const { hardSkills, softSkills, techSkills } = getCategorizedSkills(skills);

  const getFormatedOptions = (options) => {
    if (options.length) {
      return options.map((option) => {
        return { ...option, value: option.name, label: option.name };
      });
    }
    return undefined;
  };

  const onSubmit = async (formValues) => {
    try {
      setIsSaving(true);

      const positionTemplate = {
        title: formValues.title,
        about,
        responsibilities,
        qualifications,
        skills: [
          ...formValues.hardSkills,
          ...formValues.softSkills,
          ...formValues.techSkills,
        ],
        seniority: formValues.seniority,
      };
      let response;

      if (isEditing) {
        response = await PositionTemplateApi.updatePositionTemplate(
          template?._id,
          positionTemplate
        );
      } else {
        response =
          await PositionTemplateApi.createPositionTemplate(positionTemplate);
      }

      toast.success(
        `Success! The Job Template has been ${isEditing ? "saved" : "added"}.`
      );

      onSaved?.(response.data, isEditing);
      onClose();
    } catch (error) {
      const status = error.response.status;

      if (status === 409) {
        toast.info(
          "Unable to create the Job Template, there is already a Job Template with the same title and seniority."
        );
      } else if (status === 404) {
        toast.error("Job Template not found.");
      } else {
        toast.error("Error, please try again.");
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handleChangeQuill = ({ text, editor, source, name }) => {
    setValue(name, editor.getLength() > 1 ? text : "");

    if (editor.getLength() === 1 && source === "user") {
      setError(name, { type: "required", shouldFocus: true });
    } else {
      clearErrors(name);
    }
  };

  useEffect(() => {
    if (show) {
      const quillValues = ["about", "responsibilities", "qualifications"];

      clearErrors(quillValues);

      if (template && isEditing) {
        quillValues.forEach((name) => {
          setValue(name, template[name]);
        });

        ["title", "seniority"].forEach((key) => {
          setValue(key, template[key]);
        });

        const {
          hardSkills: selectedHardSkills,
          softSkills: selectedSoftSkills,
          techSkills: selectedTechSkills,
        } = getCategorizedSkills(template?.skills);

        setValue("hardSkills", getFormatedOptions(selectedHardSkills));
        setValue("softSkills", getFormatedOptions(selectedSoftSkills));
        setValue("techSkills", getFormatedOptions(selectedTechSkills));
      }
    } else {
      clearErrors();
      reset({
        title: "",
        about: "",
        responsibilities: "",
        qualifications: "",
        hardSkills: [],
        softSkills: [],
        techSkills: [],
        seniority: "",
      });
    }
  }, [show, template, isEditing, setValue, clearErrors, reset]);

  return (
    <Modal show={show} onHide={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <div className={styles.Header}>
            <Modal.Title>{isEditing ? "Edit" : "Create"} Template</Modal.Title>
            {!isEditing && (
              <span>
                Set predefined common characteristics to facilitate publishing
                positions.
              </span>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form.Group
            controlId="title"
            className={`form-group ${!isEditing ? "required" : ""}`}
          >
            <Form.Label>Job Title</Form.Label>
            {isEditing ? (
              <div>{template.title}</div>
            ) : (
              <Form.Control
                type="text"
                name="title"
                {...register("title", { required: true })}
                isInvalid={!!errors.title}
                disabled={isSaving}
              />
            )}
            <FormControlErrorMessage
              show={errors.title?.type === "required"}
              message="Job Title is required"
            />
          </Form.Group>

          <Form.Group controlId="about" className="form-group required">
            <Form.Label>About the job</Form.Label>
            <ReactQuill
              value={about}
              className={errors.about ? "ql-error" : ""}
              readOnly={isSaving}
              ref={() => register("about", { required: true })}
              onChange={(text, _delta, source, editor) =>
                handleChangeQuill({ text, editor, source, name: "about" })
              }
            />
            <FormControlErrorMessage
              show={errors.about?.type === "required"}
              message="About is required"
            />
          </Form.Group>

          <Form.Group
            controlId="responsibilities"
            className="form-group required"
          >
            <Form.Label>What will you do?</Form.Label>
            <ReactQuill
              value={responsibilities}
              className={errors.responsibilities ? "ql-error" : ""}
              readOnly={isSaving}
              ref={() => register("responsibilities", { required: true })}
              onChange={(text, _delta, source, editor) =>
                handleChangeQuill({
                  text,
                  editor,
                  source,
                  name: "responsibilities",
                })
              }
            />
            <FormControlErrorMessage
              show={errors.responsibilities?.type === "required"}
              message="What will you do? is required"
            />
          </Form.Group>

          <Form.Group
            controlId="qualifications"
            className="form-group required"
          >
            <Form.Label>Qualifications</Form.Label>
            <ReactQuill
              value={qualifications}
              className={errors.qualifications ? "ql-error" : ""}
              readOnly={isSaving}
              ref={() => register("qualifications", { required: true })}
              onChange={(text, _delta, source, editor) =>
                handleChangeQuill({
                  text,
                  editor,
                  source,
                  name: "qualifications",
                })
              }
            />
            <FormControlErrorMessage
              show={errors.qualifications?.type === "required"}
              message="Qualifications are required"
            />
          </Form.Group>

          <Form.Group controlId="hardSkills" className="form-group required">
            <Form.Label>Hard Skills</Form.Label>
            <CustomSelect
              isMulti
              name="hardSkills"
              isInvalid={!!errors.hardSkills}
              defaultValue={
                isEditing ? getFormatedOptions(selectedHardSkills) : undefined
              }
              disabled={isSaving}
              control={control}
              options={getFormatedOptions(hardSkills)}
              isRequired
            />
            <FormControlErrorMessage
              show={
                errors.hardSkills?.type === "required" ||
                errors.hardSkills?.type === "validate"
              }
              message="Hard Skills are required"
            />
          </Form.Group>

          <Form.Group controlId="softSkills" className="form-group required">
            <Form.Label>Soft Skills</Form.Label>
            <CustomSelect
              isMulti
              name="softSkills"
              isInvalid={!!errors.softSkills}
              defaultValue={
                isEditing ? getFormatedOptions(selectedSoftSkills) : undefined
              }
              disabled={isSaving}
              control={control}
              options={getFormatedOptions(softSkills)}
              isRequired
            />
            <FormControlErrorMessage
              show={
                errors.softSkills?.type === "required" ||
                errors.softSkills?.type === "validate"
              }
              message="Soft Skills are required"
            />
          </Form.Group>

          <Form.Group controlId="techSkills" className="form-group required">
            <Form.Label>Tech Skills</Form.Label>
            <CustomSelect
              isMulti
              name="techSkills"
              isInvalid={!!errors.techSkills}
              defaultValue={
                isEditing ? getFormatedOptions(selectedTechSkills) : undefined
              }
              disabled={isSaving}
              control={control}
              options={getFormatedOptions(techSkills)}
              isRequired
            />
            <FormControlErrorMessage
              show={
                errors.techSkills?.type === "required" ||
                errors.techSkills?.type === "validate"
              }
              message="Tech Skills are required"
            />
          </Form.Group>

          <Form.Group
            controlId="seniority"
            className={`form-group ${!isEditing ? "required" : ""}`}
          >
            <Form.Label>Seniority</Form.Label>
            {isEditing ? (
              <Form.Check
                type="radio"
                name="seniority"
                value={template.seniority}
                label={template.seniority}
                {...register("seniority", { required: false })}
                disabled
                isInvalid={!!errors.seniority}
                checked
              />
            ) : (
              <div className={styles.SeniorityLevels}>
                {Object.keys(SeniorityEnum).map((key) => (
                  <Form.Check
                    type="radio"
                    name="seniority"
                    key={key}
                    id={SeniorityEnum[key]}
                    value={SeniorityEnum[key]}
                    label={SeniorityEnum[key]}
                    {...register("seniority", { required: true })}
                    disabled={isSaving}
                    isInvalid={!!errors.seniority}
                  />
                ))}
              </div>
            )}
            <FormControlErrorMessage
              show={errors.seniority?.type === "required"}
              message="Seniority is required"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="CancelButton"
            disabled={isSaving}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button type="submit" className="SaveButton" disabled={isSaving}>
            {isEditing ? "Save" : "Create"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

JobTemplateAddEditModal.propTypes = {
  show: PropTypes.bool,
  skills: PropTypes.array,
  template: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSaved: PropTypes.func,
};

JobTemplateAddEditModal.defaultProps = {
  show: false,
  skills: [],
  template: {},
  loadingSkills: false,
};

export default JobTemplateAddEditModal;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";

import { getFormattedLocalDate } from "utils.js";

import "Components/styles/Card.css";
import styles from "Components/Task/styles/TaskDueDateIndicator.module.css";

const TaskDueDateIndicator = (props) => {
  const { task } = props;

  const [indicatorColorClass, setIndicatorColorClass] = useState();

  const getTimeLeftPercentage = (dueDate, startDate, measurementDate) => {
    let percentage = 0;

    if (dueDate.diff(measurementDate)) {
      const result = measurementDate.diff(startDate) / dueDate.diff(startDate);

      percentage = (1 - result) * 100;
    }

    return percentage;
  };

  const getIndicatorColor = (timeLeftPercentage) => {
    if (timeLeftPercentage >= 50) {
      return styles.Green;
    }

    if (timeLeftPercentage >= 1) {
      return styles.Orange;
    }

    return styles.Red;
  };

  useEffect(() => {
    if (!task) {
      return;
    }

    const dueDate = dayjs(task.dueDate);
    const startDate = dayjs(task.createdAt);
    const measurementDate = task.completed ? dayjs(task.updatedAt) : dayjs();
    const timeLeftPercentage = getTimeLeftPercentage(
      dueDate,
      startDate,
      measurementDate
    );
    const newIndicatorColor = getIndicatorColor(timeLeftPercentage);

    setIndicatorColorClass(newIndicatorColor);
  }, [task]);

  if (!task || !task.dueDate) {
    return null;
  }

  return (
    <span
      data-testid="indicator"
      className={`${styles.TaskDueDateIndicator} ${indicatorColorClass}`}
    >
      <FontAwesomeIcon
        icon={faClock}
        className={styles.Icon}
        data-testid="icon"
      />
      {getFormattedLocalDate(task.dueDate)}
    </span>
  );
};

TaskDueDateIndicator.propTypes = {
  task: PropTypes.shape({
    dueDate: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    completed: PropTypes.bool,
  }),
};

export default TaskDueDateIndicator;

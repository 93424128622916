import { useParams } from "react-router";
import { useKeycloak } from "@react-keycloak/web";

import Achievement from "Components/Candidate/Achievement";
import StateCard from "Components/Common/StateCard";
import GoBackButton from "Components/GoBackButton";
import Loading from "Components/Loading";
import {
  useCandidateAchievementCertificate,
  usePublicCandidateAchievement,
} from "Hooks/useCandidateAchievements";
import { assets } from "constants.js";

const ViewAchievement = () => {
  const { id: achievementId } = useParams();
  const { keycloak } = useKeycloak();

  const candidateId = keycloak.tokenParsed?.candidateId;

  const { candidateAchievement, isLoading } = usePublicCandidateAchievement(
    candidateId,
    achievementId
  );

  const { getCertificate, isDownloadingCertificate } =
    useCandidateAchievementCertificate();

  if (isLoading) {
    return <Loading />;
  }

  if (!candidateAchievement) {
    return (
      <StateCard imageUrl={assets.ZERO_STATE} title="Achievement not found!" />
    );
  }

  return (
    <>
      <GoBackButton path="/achievements" />

      <Achievement
        achievement={candidateAchievement}
        isDownloadingCertificate={isDownloadingCertificate}
        isShareable
        getCertificate={getCertificate}
      />
    </>
  );
};

export default ViewAchievement;

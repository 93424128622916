import PropTypes from "prop-types";

import EventCard from "Components/Candidate/EventCard";
import StateCard from "Components/Common/StateCard";
import Loading from "Components/Loading";
import TrainingCardList from "Components/Training/TrainingCardList";
import { assets } from "constants.js";

const EventList = (props) => {
  const { events, isTraining, variant, loading } = props;

  if (loading) {
    return <Loading />;
  }

  const renderEvents = () => {
    if (isTraining) {
      return <TrainingCardList events={events} />;
    }

    if (!events?.length) {
      return (
        <StateCard
          imageUrl={assets.ZERO_STATE}
          imageWidth={300}
          title="No events found!"
        />
      );
    }

    return (
      <>
        {events.map((event) => (
          <EventCard key={event._id} event={event} variant={variant} />
        ))}
      </>
    );
  };

  return renderEvents();
};

EventList.propTypes = {
  events: PropTypes.array,
  isTraining: PropTypes.bool,
  loading: PropTypes.bool,
  variant: PropTypes.string,
};

EventList.defaultProps = {
  isTraining: false,
};

export default EventList;

import { useCallback, useEffect, useState } from "react";
import { EmailApi } from "micoach-api";

export const useEmailTemplates = (type) => {
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [loadingEmailTemplates, setLoadingEmailTemplates] = useState(false);

  const loadEmailTemplates = useCallback(async () => {
    if (type) {
      setLoadingEmailTemplates(true);
      try {
        const { data } = await EmailApi.listEmailTemplates(type);
        setEmailTemplates(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingEmailTemplates(false);
      }
    }
  }, [type]);

  useEffect(() => {
    loadEmailTemplates();
  }, [loadEmailTemplates]);

  return { emailTemplates, loadingEmailTemplates, setEmailTemplates };
};

import { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link, useHistory } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import CoachOverviewCard from "Components/Evaluation/Skill/CoachOverviewCard";
import EvaluationList from "Components/Evaluation/Skill/EvaluationList";
import EvaluationRequestCounter from "Components/Evaluation/Skill/EvaluationRequestsCounter";
import ProficiencyLevelsInstructionsCard from "Components/Evaluation/Skill/ProficiencyLevelsInstructionsCard";
import RequestAssessmentModal from "Components/Evaluation/Skill/RequestAssessmentModal";
import ResetEvaluationButton from "Components/Evaluation/Skill/ResetEvaluationButton";
import SkillBalanceCard from "Components/Evaluation/Skill/SkillBalanceCard";
import FormattedDate from "Components/FormattedDate";
import Loading from "Components/Loading";
import { useCandidate } from "Hooks/useCandidates";
import { useEvaluations } from "Hooks/useEvaluations";
import { useQuery } from "Hooks/useQuery";
import { useSkillEvaluation, useSkillSummary } from "Hooks/useSkillEvaluation";
import { useSkillEvaluationRequest } from "Hooks/useSkillEvaluationRequest";
import { useCoaches } from "Hooks/useUsers";
import { MAX_EVALUATION_REQUESTS } from "constants.js";
import { getNextQuarterYear, getQuarterYear } from "utils.js";

import styles from "Screens/Evaluation/Skill/styles/EvaluationDashboard.module.css";

const EvaluationDashboard = () => {
  const queryParams = useQuery();
  const { keycloak } = useKeycloak();
  const userId = keycloak.tokenParsed.sub;
  const userEmail = keycloak.tokenParsed.email;
  const userCandidateId = keycloak.tokenParsed.candidateId;

  const { coaches, loadingCoaches } = useCoaches();
  const [loadingCandidate, userCandidate] = useCandidate(userCandidateId);
  const { skillsByRole } = useSkillEvaluation(queryParams);

  const balanceDate = {
    year: new Date().getFullYear(),
    quarter: getQuarterYear(),
    nextQuarter: getNextQuarterYear(),
  };

  const { evaluationRequests } = useSkillEvaluationRequest(
    balanceDate.quarter,
    balanceDate.year
  );

  const [isRoleValid, setIsRoleValid] = useState(false);
  const [hasRoleSkills, setHasRoleSkills] = useState(false);
  const [showAssessmentModal, setShowAssessmentModal] = useState(false);
  const [disabledSelfAssessment, setDisabledSelfAssessment] = useState(false);
  const [currentEvaluationRequests, setCurrentEvaluationRequests] = useState(
    []
  );
  const [candidate, setCandidate] = useState({});

  const updateEvaluationRequests = (newEvaluationRequests) => {
    setCurrentEvaluationRequests((prevEvaluationRequests) =>
      prevEvaluationRequests.concat(newEvaluationRequests)
    );
  };

  const history = useHistory();
  const { summary, loadingSummary } = useSkillSummary(
    userId,
    balanceDate.year,
    balanceDate.quarter
  );

  const { evaluations, loadingEvaluations } = useEvaluations(userId);

  const evaluationInProgress = evaluations?.find(
    (evaluation) => evaluation.completed === false
  );

  const selfEvaluation = evaluations.length && evaluations[0];

  const isPeriodCompleted = summary?.evaluationPeriod?.completed;

  const handleDeletedRequestEvaluation = (id) => {
    const newCurrentEvaluationRequests = [...currentEvaluationRequests];
    const index = newCurrentEvaluationRequests.findIndex(
      (evaluation) => evaluation._id === id
    );

    if (index > -1) {
      newCurrentEvaluationRequests.splice(index, 1);
      setCurrentEvaluationRequests(newCurrentEvaluationRequests);
    }
  };

  const handleClickStartEvaluation = () => {
    if (evaluationInProgress) {
      history.push(`/evaluation/skill?id=${evaluationInProgress._id}`);
    } else {
      history.push("/evaluation/skill");
    }
  };

  useEffect(() => {
    if (summary?.self) {
      // If there is at least one item in self, it means that there is already an evaluation in this period
      summary.self.length && setDisabledSelfAssessment(true);
    }
  }, [summary]);

  useEffect(() => {
    setCurrentEvaluationRequests(evaluationRequests);
  }, [evaluationRequests]);

  useEffect(() => {
    if (userCandidate) {
      setCandidate(userCandidate);
      setIsRoleValid(!!userCandidate.role);
    }
  }, [userCandidate]);

  useEffect(() => {
    setHasRoleSkills(skillsByRole.length > 0);
  }, [skillsByRole]);

  const startEvaluation = () => {
    if (loadingSummary) {
      return <Loading />;
    } else if (summary?.self) {
      return <SkillBalanceCard balanceDate={balanceDate} summary={summary} />;
    } else {
      return (
        <Card className={`Card ${styles.StartEvaluationCard}`}>
          <Card.Body>
            <img
              src="https://micoach-prod-bucket.s3.amazonaws.com/assets/assessment.png"
              alt="Start self assessment"
            />

            <Card.Title className={styles.StartEvaluationTitle}>
              {`${
                evaluationInProgress ? "Continue" : "Do today"
              } your first skills assessment!`}
            </Card.Title>

            <Card.Subtitle className={styles.StartEvaluationSubtitle}>
              Complete the first step of your continuous learning path by
              measuring your proficiency in the different skills the industry
              requires for your role. You can also invite others to contribute
              to assess you and we’ll show you a complete analisis to know where
              to start to go to the next level.
            </Card.Subtitle>
            <Button
              variant="primary"
              className={styles.StartEvaluationButton}
              onClick={handleClickStartEvaluation}
              disabled={!isRoleValid || !hasRoleSkills}
            >
              {`${evaluationInProgress ? "Continue" : "Start"} self assessment`}
            </Button>
          </Card.Body>
        </Card>
      );
    }
  };

  const showAssessmentActions = () => {
    if (!isPeriodCompleted) {
      return (
        <div className="text-end">
          {disabledSelfAssessment && (
            <div className={styles.SelfButtonSection}>
              <span className="fs-12 me-3">
                Self assessment available until{" "}
                <FormattedDate>{balanceDate.nextQuarter}</FormattedDate>
              </span>
              {selfEvaluation && !summary?.requested?.length > 0 && (
                <ResetEvaluationButton evaluationId={selfEvaluation._id} />
              )}
            </div>
          )}

          <div className={styles.RequestsSection}>
            <EvaluationRequestCounter
              nextQuarter={balanceDate.nextQuarter}
              evaluationRequests={currentEvaluationRequests}
              onDeleted={(id) => handleDeletedRequestEvaluation(id)}
            />
            {!loadingCoaches && (
              <Button
                variant="primary"
                className="w-100"
                onClick={() => setShowAssessmentModal(true)}
                disabled={
                  !isRoleValid ||
                  !hasRoleSkills ||
                  currentEvaluationRequests.length >= MAX_EVALUATION_REQUESTS
                }
              >
                Request assessment
              </Button>
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <RequestAssessmentModal
        show={showAssessmentModal}
        onClose={() => setShowAssessmentModal(false)}
        evaluationRequests={currentEvaluationRequests}
        updateEvaluationRequests={updateEvaluationRequests}
        userEmail={userEmail}
        coaches={coaches}
      />
      <div className="d-md-flex justify-content-between align-items-end ScreenTitle">
        <div className="flex-column flex-d-md-flex align-items-center">
          <h1>Skills proficiency</h1>
          {loadingCandidate ? (
            <Loading />
          ) : candidate.role ? (
            <h2 className={styles.RoleSubtitle}>{`${candidate.name} - ${
              candidate.role || ""
            }`}</h2>
          ) : (
            <Alert variant="danger">
              A role is necessary to be assessed, please select a role in
              your&nbsp;<Link to={`/profile`}>profile</Link>.
            </Alert>
          )}
          {isRoleValid && !hasRoleSkills && (
            <Alert variant="danger">
              Selected role has no skills to be assessed, please select another
              role in your&nbsp;<Link to={`/profile`}>profile</Link>.
            </Alert>
          )}
          {isPeriodCompleted && (
            <Badge pill className="GreenBadge">
              Cycle closed
            </Badge>
          )}
        </div>
        {showAssessmentActions()}
      </div>

      <div className={styles.ProficiencyLevelsInstructions}>
        <ProficiencyLevelsInstructionsCard />
      </div>

      <Row className="mt-3">
        <Col>{startEvaluation()}</Col>
        {summary.evaluationPeriod && (
          <Col sm={4}>
            <CoachOverviewCard evaluationPeriod={summary.evaluationPeriod} />
          </Col>
        )}
      </Row>
      <Row className="mt-3">
        <Col>
          {loadingEvaluations ? (
            <Loading />
          ) : (
            <EvaluationList
              evaluations={evaluations}
              balanceDate={balanceDate}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default EvaluationDashboard;

import { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import "./styles/Checklist.css";

import { getChecklistItemName } from "../utils";

function Checklist(props) {
  const { title, items, applicationProcessId, isEditable = false } = props;

  const { register, reset } = useForm();

  const getInitialValues = useCallback(() => {
    return (items ?? []).reduce(
      (accumulator, item) => ({ ...accumulator, [item.key]: item.checked }),
      {}
    );
  }, [items]);

  useEffect(() => {
    reset(getInitialValues());
  }, [reset, getInitialValues]);

  const onItemChange = (event) => {
    props.onItemChange({
      key: event.target.name,
      value: event.target.checked,
    });
  };

  return items ? (
    <div className="Checklist pt-4">
      <h2>{title}</h2>
      <Form>
        <ListGroup variant="flush">
          {items.map((item) => {
            const label =
              isEditable && applicationProcessId && item.key === "INTAKE_FORM"
                ? ""
                : getChecklistItemName(item.key);

            return (
              <ListGroup.Item
                key={item.key}
                className={`${item.key === "INTAKE_FORM" ? "d-flex" : ""}`}
              >
                <Form.Check
                  name={item.key}
                  type="checkbox"
                  id={item.key}
                  label={label}
                  {...register(item.key)}
                  onChange={onItemChange}
                  className="Check"
                  disabled={!isEditable}
                />
                {isEditable &&
                  applicationProcessId &&
                  item.key === "INTAKE_FORM" && (
                    <Link
                      to={`/application-process/${applicationProcessId}/prescreening`}
                    >
                      {getChecklistItemName(item.key)}
                    </Link>
                  )}
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Form>
    </div>
  ) : (
    <>Checklist not found!</>
  );
}

Checklist.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array,
  applicationProcessId: PropTypes.string,
  isEditable: PropTypes.bool,
  onItemChange: PropTypes.func.isRequired,
};

export default Checklist;

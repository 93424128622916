import PropTypes from "prop-types";
import { useKeycloak } from "@react-keycloak/web";

import { getRoles } from "utils.js";

const Restricted = (props) => {
  const { roles, children } = props;

  const { keycloak } = useKeycloak();

  const userRoles = getRoles(keycloak);

  const isAllowedTo = userRoles.some((userRole) => roles.includes(userRole));

  return <>{isAllowedTo ? children : null}</>;
};

Restricted.propTypes = {
  roles: PropTypes.array,
  children: PropTypes.element.isRequired,
};

Restricted.defaultProps = {
  roles: [],
};

export default Restricted;

import PropTypes from "prop-types";
import Carousel from "react-bootstrap/Carousel";

const CarouselWrapper = (props) => {
  const { items } = props;

  return (
    <Carousel indicators controls={false}>
      {items.map((item, key) => (
        <Carousel.Item key={key}>{item}</Carousel.Item>
      ))}
    </Carousel>
  );
};

CarouselWrapper.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node),
};

CarouselWrapper.propTypes = {
  items: [],
};

export default CarouselWrapper;

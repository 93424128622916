import PropTypes from "prop-types";

import StateCard from "Components/Common/StateCard";
import TaskManagementCard from "Components/Task/TaskManagementCard";
import { assets } from "constants.js";
import { sortByProperty } from "utils.js";

const MyTaskList = (props) => {
  const { tasks } = props;

  if (!tasks?.length) {
    return (
      <StateCard
        imageUrl={assets.ZERO_STATE}
        title="You have no tasks assigned yet"
      />
    );
  }

  sortByProperty(tasks, "createdAt", "desc");

  return (
    <>
      {tasks.map((task) => (
        <TaskManagementCard
          key={task._id}
          task={task}
          onConfirmDelete={props.onConfirmDelete}
          onEdit={props.onEdit}
          onClick={props.onClick}
          onUpdated={props.onUpdated}
        />
      ))}
    </>
  );
};

MyTaskList.propTypes = {
  tasks: PropTypes.array.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onUpdated: PropTypes.func,
};

export default MyTaskList;

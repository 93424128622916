import PropTypes from "prop-types";
import Badge from "react-bootstrap/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEqual } from "utils";

import styles from "Components/Common/styles/BadgeList.module.css";

const BadgeList = (props) => {
  const {
    options,
    selectedOptions,
    disabledOptions,
    label,
    variant,
    textColor,
    className,
    icon,
    iconPosition,
    onClick,
  } = props;

  if (!options.length) {
    return null;
  }

  const isFound = (option, options = []) => {
    return options.some((selectedOption) => isEqual(selectedOption, option));
  };

  const getOptionStatus = (option) => {
    const selected = isFound(option, selectedOptions);
    const disabled = isFound(option, disabledOptions);
    const testId = selected
      ? "badge-selected"
      : disabled
        ? "badge-not-selected"
        : "";

    return {
      selected,
      disabled,
      testId,
    };
  };

  return (
    <div className="BadgeList">
      {options.map((option, index) => {
        const { selected, disabled, testId } = getOptionStatus(option);

        return (
          <Badge
            key={index}
            pill
            bg={variant}
            text={textColor}
            className={`${styles.Badge} ${className} ${
              selected ? "Selected" : disabled ? styles.Disabled : ""
            }`}
            role={onClick ? "button" : ""}
            data-testid={testId}
            onClick={() => onClick?.(option)}
          >
            {iconPosition === "left" && icon && (
              <FontAwesomeIcon
                icon={icon}
                className="me-2"
                aria-hidden={false}
              />
            )}
            {option[label]}
            {iconPosition === "right" && icon && (
              <FontAwesomeIcon icon={icon} className="ms-2" />
            )}
          </Badge>
        );
      })}
    </div>
  );
};

BadgeList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  selectedOptions: PropTypes.arrayOf(PropTypes.object),
  disabledOptions: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  variant: PropTypes.string,
  textColor: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.object,
  iconPosition: PropTypes.string,
  onClick: PropTypes.func,
};

BadgeList.defaultProps = {
  options: [],
  selectedOptions: [],
  disabledOptions: [],
  variant: "light",
  textColor: "dark",
  iconPosition: "left",
  className: "me-1 fw-normal fs-13",
};

export default BadgeList;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import BadgeSelect from "Components/Common/BadgeSelect";
import StepProgressBar from "Components/Common/StepProgressBar";
import StepButton from "Components/ProfileBuilder/StepButton";
import { roleQuiz } from "constants.js";

import styles from "Components/ProfileBuilder/Quiz/styles/RoleQuizStep.module.css";

const RoleQuizStep = (props) => {
  const {
    step,
    totalSteps,
    options,
    onClickNext,
    onClickFindRole,
    onClickCompleted,
    onClickRestart,
    onClickExit,
  } = props;

  const [selectedOptions, setSeletedOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);

  const title = step.title || "Answer to find your role";
  const subTitle =
    step.subtitle ||
    `Select from ${step.minSelection} to ${step.maxSelection} options that fits you.`;

  const handleSelectedOptions = () => {
    if (!selectedOptions.length) {
      setErrorMessage(true);
      return;
    }

    switch (step.stepNumber) {
      case roleQuiz.QUESTION_ONE.stepNumber:
      case roleQuiz.QUESTION_TWO.stepNumber:
        onClickNext(selectedOptions);
        setErrorMessage(false);
        break;
      case roleQuiz.QUESTION_THREE.stepNumber:
        onClickFindRole(selectedOptions);
        setErrorMessage(false);
        break;
      case roleQuiz.ANSWER.stepNumber:
        onClickCompleted(selectedOptions);
        setErrorMessage(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setSeletedOptions([]);
    setErrorMessage(false);
  }, [options]);

  useEffect(() => {
    setErrorMessage(
      (prevErrorMessage) => !selectedOptions.length && prevErrorMessage
    );
  }, [selectedOptions]);

  return (
    <>
      <Card.Title className={styles.Title}>{title}</Card.Title>
      <Card.Subtitle className={styles.Subtitle}>{subTitle}</Card.Subtitle>
      {step.stepNumber < roleQuiz.ANSWER.stepNumber && (
        <StepProgressBar current={step.stepNumber + 1} total={totalSteps} />
      )}
      <Card.Text className={styles.Question}>{step.question}</Card.Text>

      <BadgeSelect
        label="name"
        options={options}
        className={styles.BadgeList}
        maxSelection={step.maxSelection}
        icon={step.icon}
        iconPosition={step.iconPosition}
        onChange={(options) => setSeletedOptions(options)}
      />

      {errorMessage && (
        <div className="invalid-feedback d-block text-center fs-14">
          Select at least one item to continue.
        </div>
      )}

      {step.stepNumber <= roleQuiz.QUESTION_TWO.stepNumber && (
        <div className={styles.NextButton}>
          <Button variant="outline-primary" onClick={handleSelectedOptions}>
            Next
          </Button>
        </div>
      )}

      {step.stepNumber === roleQuiz.QUESTION_THREE.stepNumber && (
        <StepButton label="Find my role" onClick={handleSelectedOptions} />
      )}

      {step.stepNumber === roleQuiz.ANSWER.stepNumber && (
        <div className={styles.Buttons}>
          <div className={styles.BackButton}>
            <Button variant="outline-primary" onClick={onClickRestart}>
              Answer again
            </Button>
          </div>

          <StepButton label="Continue" onClick={handleSelectedOptions} />
        </div>
      )}

      {step.exitQuiz && (
        <div className={styles.KnowRoleContainer}>
          <span className={styles.Label}>
            Know your role?{" "}
            <Button
              variant="link"
              className={styles.LinkButton}
              onClick={onClickExit}
            >
              Go back
            </Button>
          </span>
        </div>
      )}
    </>
  );
};

RoleQuizStep.propTypes = {
  step: PropTypes.shape({
    stepNumber: PropTypes.number,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    question: PropTypes.string,
    exitQuiz: PropTypes.bool,
    minSelection: PropTypes.number,
    maxSelection: PropTypes.number,
    icon: PropTypes.string,
    iconPosition: PropTypes.string,
  }),
  totalSteps: PropTypes.number,
  options: PropTypes.array,
  errorOptions: PropTypes.bool,
  onClickNext: PropTypes.func,
  onClickRestart: PropTypes.func,
  onClickFindRole: PropTypes.func,
  onClickCompleted: PropTypes.func,
  onClickExit: PropTypes.func,
};

RoleQuizStep.defaultProps = {
  options: [],
};

export default RoleQuizStep;

import { useState } from "react";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PositionStatusEnum } from "@micoach/js-sdk";

import { positionStatus } from "constants.js";
import { getPositionStatus } from "utils.js";

import styles from "Components/Job/Position/styles/JobPositionStatusButton.module.css";

const JobPositionStatusButton = (props) => {
  const { status, isSaving, onChange } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const getStatusOptions = () => {
    if (status === "CLOSED") {
      return [];
    }
    return positionStatus.filter(
      (positionStatusItem) => positionStatusItem.value !== status
    );
  };

  const getStatusButtonClassName = (status) => {
    switch (status) {
      case PositionStatusEnum.Open:
        return styles.Open;

      case PositionStatusEnum.OnHold:
        return styles.OnHold;

      default:
        return styles.Closed;
    }
  };

  return (
    <DropdownButton
      title={
        <span>
          {getPositionStatus(status)}
          {isMenuOpen ? (
            <FontAwesomeIcon icon={faChevronUp} className={styles.Icon} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} className={styles.Icon} />
          )}
        </span>
      }
      variant="success"
      disabled={status === "CLOSED" || isSaving}
      className={`${styles.JobPositionStatusButton} ${getStatusButtonClassName(
        status
      )}`}
      onToggle={() => setIsMenuOpen(!isMenuOpen)}
    >
      {getStatusOptions().map((status) => {
        return (
          <Dropdown.Item
            key={status.value}
            onClick={() => onChange?.(status.value)}
          >
            {status.label}
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
};

JobPositionStatusButton.propTypes = {
  status: PropTypes.string,
  isSaving: PropTypes.bool,
  onChange: PropTypes.func,
};

JobPositionStatusButton.defaultProps = {
  isSaving: false,
};

export default JobPositionStatusButton;

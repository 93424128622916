import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import { ReferralStatusEnum } from "@micoach/js-sdk";
import {
  customSort,
  getFormattedLocalDate,
  getReferralStatusLabel,
  getRelativeTime,
} from "utils";

import ReferralActionsButton from "Components/Refer/ReferralActionsButton";
import ReferralClaimButton from "Components/Refer/ReferralClaimButton";

import styles from "Components/Refer/styles/ReferralsOverviewTable.module.css";

const ReferralsOverviewTable = (props) => {
  const { referrals, isHumanResources, isSaving, onUpdateStatus } = props;

  const columns = [
    {
      name: <strong>Action</strong>,
      cell: (referral) => (
        <div className={styles.Actions}>
          <ReferralActionsButton
            status={referral.status}
            isSaving={isSaving}
            onChange={(status) => onUpdateStatus(referral._id, status)}
          />
        </div>
      ),
      width: "166px",
      omit: !isHumanResources,
    },
    {
      name: <strong>Referrer</strong>,
      selector: (referral) => (
        <div className={styles.UserData}>
          <span className={styles.Name}>{referral.referrerName}</span>
          <span className={styles.Email}>{referral.referrerEmail}</span>
        </div>
      ),
      wrap: true,
      width: "300px",
      omit: !isHumanResources,
      sortable: true,
      sortFunction: (object1, object2) =>
        customSort({ object1, object2, property: "referrerName" }),
    },
    {
      name: (
        <strong>{isHumanResources ? "Referred" : "Referred Friend"}</strong>
      ),
      cell: (referral) => (
        <div className={styles.UserData}>
          <span className={styles.Name}>{referral.name}</span>
          <span className={styles.Email}>{referral.email}</span>
        </div>
      ),
      wrap: true,
      width: "300px",
      sortable: true,
      sortFunction: (object1, object2) =>
        customSort({ object1, object2, property: "name" }),
    },
    {
      name: <strong>Job Position</strong>,
      selector: (referral) => referral.position,
      wrap: true,
      sortable: true,
    },
    {
      name: <strong>When was referred</strong>,
      selector: (referral) => `${getRelativeTime(referral.createdAt)} ago`,
      wrap: true,
      width: "160px",
      sortable: true,
      sortFunction: (object1, object2) =>
        customSort({
          object1,
          object2,
          property: "createdAt",
        }),
    },
    {
      name: <strong>Hired</strong>,
      selector: (referral) =>
        referral.hiredDate ? getFormattedLocalDate(referral.hiredDate) : "",
      wrap: true,
      sortable: true,
      sortFunction: (object1, object2) =>
        customSort({
          object1,
          object2,
          property: "hiredDate",
        }),
    },
    {
      name: <strong>Status</strong>,
      selector: (referral) =>
        getReferralStatusLabel(referral.status, isHumanResources),
      wrap: true,
      sortable: true,
    },
    {
      name: <strong>TA Member</strong>,
      selector: (referral) => referral.recruiterName,
      wrap: true,
      omit: !isHumanResources,
      sortable: true,
    },
    {
      name: <strong>Reward</strong>,
      cell: (referral) => (
        <>
          {!isHumanResources && (
            <ReferralClaimButton
              isSaving={isSaving}
              referralStatus={referral.status}
              onClick={() => {
                handleClaimReward(referral._id, referral.status);
              }}
            />
          )}
          {<span className={styles.Reward}>{referral.reward}</span>}
        </>
      ),
      wrap: true,
      sortable: true,
      sortFunction: (object1, object2) =>
        customSort({ object1, object2, property: "reward" }),
    },
  ];

  const dataTable = referrals.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  const handleClaimReward = (id, status) => {
    if (status === ReferralStatusEnum.ReadyToClaim) {
      onUpdateStatus(id, ReferralStatusEnum.Reviewing);
    }
  };

  return (
    <DataTable
      columns={columns}
      data={dataTable}
      pagination
      highlightOnHover
      defaultSortFieldId={5}
      defaultSortAsc={false}
      paginationPerPage={isHumanResources ? 30 : 10}
    />
  );
};

ReferralsOverviewTable.propTypes = {
  referrals: PropTypes.array,
  isHumanResources: PropTypes.bool,
  isSaving: PropTypes.bool,
  onUpdateStatus: PropTypes.func,
};

ReferralsOverviewTable.defaultValues = {
  referrals: [],
  isHumanResources: false,
  isSaving: false,
};

export default ReferralsOverviewTable;

import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import Select from "react-select";
import {
  faDownload,
  faInfoCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SourceEnum, UserRoleEnum } from "@micoach/js-sdk";
import keycloak from "keycloak";

import EllipsisMenu from "Components/Common/EllipsisMenu";
import StateCard from "Components/Common/StateCard";
import Loading from "Components/Loading";
import { assets, MENU_PROSPECT_PROCESS } from "constants.js";
import {
  capitalizeEnum,
  getFormattedLocalDate,
  getRoles,
  mapToSelectOptions,
} from "utils.js";

import styles from "./styles/ProspectsTable.module.css";

const ProspectsTable = (props) => {
  const {
    prospects,
    totalProspects,
    recruiters,
    currentPage,
    loading,
    paginationPerPage,
    onSelectRecruiter,
    onChangePage,
    onChangeSort,
    onClickPreviewDocument,
    onClickDownloadDocument,
    onClickSelectedMenuOption,
    onClickPreviewReferralDetail,
  } = props;

  const PDF_MIMETYPE = "application/pdf";

  const userRoles = getRoles(keycloak);

  const isRecruiterAdmin = userRoles.some(
    (userRole) => UserRoleEnum.AdminRecruiter === userRole
  );

  const getMenuByProspectStatus = (status) => {
    const menu = [
      { ...MENU_PROSPECT_PROCESS.MOVE_TO_KANBAN },
      { ...MENU_PROSPECT_PROCESS.TAGS },
    ];

    if (status !== "CANCELLED") {
      menu.push({
        ...MENU_PROSPECT_PROCESS.REJECT,
        iconClassName: styles.RejectMenuOption,
      });
    }

    return menu;
  };

  const columns = [
    {
      name: <strong>Date</strong>,
      selector: (prospect) => prospect.createdAt,
      sortable: true,
      minWidth: "120px",
      wrap: true,
      columnName: "createdAt",
      cell: (prospect) => getFormattedLocalDate(prospect.createdAt),
    },
    {
      name: <strong>Full name</strong>,
      selector: (prospect) => prospect.name,
      sortable: true,
      minWidth: "240px",
      columnName: "name",
      wrap: true,
    },
    {
      name: <strong>Email</strong>,
      selector: (prospect) => prospect.email,
      sortable: true,
      minWidth: "240px",
      columnName: "email",
      wrap: true,
    },
    {
      name: <strong>Client</strong>,
      minWidth: "240px",
      wrap: true,
      cell: (prospect) => prospect.company,
    },
    {
      name: <strong>Position</strong>,
      minWidth: "240px",
      wrap: true,
      cell: (prospect) => prospect.positionName,
    },
    {
      name: <strong>Resume</strong>,
      minWidth: "120px",
      center: true,
      wrap: true,
      cell: (prospect) => (
        <>
          {prospect.resumeContentType === PDF_MIMETYPE && (
            <Button
              variant="link"
              className={styles.Icon}
              data-testid="search-resume"
              onClick={() =>
                onClickPreviewDocument?.({
                  key: prospect.resumeKey,
                  fileName: prospect.resumeName,
                })
              }
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          )}
          <Button
            variant="link"
            className={`${styles.Icon} ${prospect.resumeContentType !== PDF_MIMETYPE && styles.SingleIcon}`}
            data-testid="download-resume"
            onClick={() =>
              onClickDownloadDocument?.({
                key: prospect.resumeKey,
                fileName: prospect.resumeName,
              })
            }
          >
            <FontAwesomeIcon icon={faDownload} />
          </Button>
        </>
      ),
    },
    {
      name: <strong>Source Flow</strong>,
      minWidth: "130px",
      wrap: true,
      cell: (prospect) => {
        return prospect.sourceFlow === SourceEnum.Referral ? (
          <>
            <span className={styles.SourceFlowLabel}>
              {capitalizeEnum(prospect.sourceFlow)}
            </span>
            <Button
              variant="link"
              className={styles.Icon}
              data-testid="search-referral"
              onClick={() =>
                onClickPreviewReferralDetail?.(prospect.sourceFlowId)
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} aria-label="Referral info" />
            </Button>
          </>
        ) : (
          capitalizeEnum(prospect.sourceFlow)
        );
      },
    },
    {
      name: <strong>Assigned TA</strong>,
      minWidth: "240px",
      wrap: true,
      cell: (prospect) =>
        isRecruiterAdmin ? (
          <Select
            classNamePrefix="ReactSelect"
            placeholder="Select..."
            menuPosition="fixed"
            className={styles.RecruitersSelect}
            value={{
              label: prospect.recruiterName,
              value: prospect.recruiterId,
            }}
            options={mapToSelectOptions(recruiters, "_id", "name")}
            onChange={(recruiter) => {
              if (recruiter.value !== prospect.recruiterId) {
                onSelectRecruiter(
                  {
                    _id: recruiter.value,
                    name: recruiter.label,
                  },
                  prospect._id
                );
              }
            }}
          />
        ) : (
          prospect.recruiterName
        ),
    },
    {
      cell: (prospect) => (
        <EllipsisMenu
          options={getMenuByProspectStatus(prospect.status)}
          onSelectedOption={(option) =>
            onClickSelectedMenuOption(option, prospect)
          }
        />
      ),
      minWidth: "120px",
      wrap: true,
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={prospects}
      pagination
      sortServer
      paginationServer
      highlightOnHover
      defaultSortFieldId={1}
      defaultSortAsc={false}
      progressPending={loading}
      progressComponent={<Loading />}
      paginationTotalRows={totalProspects}
      paginationDefaultPage={currentPage}
      paginationPerPage={paginationPerPage}
      paginationRowsPerPageOptions={[paginationPerPage]}
      noDataComponent={
        <StateCard
          imageUrl={assets.PROSPECTS_ZERO_STATE}
          imageWidth={120}
          title="There are no records to display"
          subtitle="Once you had someone who has applied or has been referred for a job position, you&lsquo;ll see them listed here."
        />
      }
      onSort={onChangeSort}
      onChangePage={onChangePage}
    />
  );
};

ProspectsTable.propTypes = {
  prospects: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      candidateId: PropTypes.string,
      company: PropTypes.string,
      createdAt: PropTypes.string,
      email: PropTypes.string,
      name: PropTypes.string,
      position: PropTypes.string,
      recruiterId: PropTypes.string,
      recruiterName: PropTypes.string,
      resumeContentType: PropTypes.string,
      resumeName: PropTypes.string,
      resumeKey: PropTypes.string,
      sourceFlow: PropTypes.string,
      sourceFlowId: PropTypes.string,
      status: PropTypes.string,
    })
  ),
  totalProspects: PropTypes.number,
  loading: PropTypes.bool,
  recruiters: PropTypes.array,
  currentPage: PropTypes.number,
  paginationPerPage: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeSort: PropTypes.func,
  onSelectRecruiter: PropTypes.func,
  onClickPreviewDocument: PropTypes.func,
  onClickDownloadDocument: PropTypes.func,
  onClickSelectedMenuOption: PropTypes.func,
  onClickPreviewReferralDetail: PropTypes.func,
};

ProspectsTable.defaultProps = {
  prospects: [],
  recruiters: [],
  currentPage: 1,
  totalProspects: 0,
  loading: false,
  paginationPerPage: 50,
  onSelectRecruiter: () => {},
  onClickPreviewDocument: () => {},
  onClickDownloadDocument: () => {},
  onClickSelectedMenuOption: () => {},
};

export default ProspectsTable;

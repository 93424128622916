import { useCallback, useEffect, useState } from "react";
import { EvaluationApi } from "micoach-api";

export const useSkillEvaluationRequest = (quarter, year) => {
  const [evaluationRequests, setEvaluationRequests] = useState([]);
  const [loadingEvaluationRequests, setLoadingEvaluationRequests] =
    useState(false);

  const loadEvaluationRequests = useCallback(async () => {
    try {
      setLoadingEvaluationRequests(true);
      const { data } = await EvaluationApi.listUserEvaluationRequests(
        quarter,
        year
      );
      setEvaluationRequests(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEvaluationRequests(false);
    }
  }, [quarter, year]);

  useEffect(() => {
    loadEvaluationRequests();
  }, [loadEvaluationRequests]);

  return { evaluationRequests, loadingEvaluationRequests };
};

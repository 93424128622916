import Container from "react-bootstrap/Container";

import "Layouts/styles/Default.css";

export default function blankLayout(WrappedComponent) {
  // eslint-disable-next-line react/display-name
  return () => {
    return (
      <>
        <Container className="p-0 m-0" fluid>
          <WrappedComponent />
        </Container>
      </>
    );
  };
}

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserApi } from "micoach-api";

const AboutMeCard = (props) => {
  const { description } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (formValues) => {
    setIsSaving(true);
    try {
      const response = await UserApi.updateProfile({
        aboutMe: formValues.description?.trim(),
      });
      props.onSaved?.(response.data);
    } catch (error) {
      toast.error("Error, please try again.");
    } finally {
      setIsSaving(false);
      setIsEditing(false);
    }
  };

  const handleClose = () => {
    setIsEditing(false);
    reset();
  };

  useEffect(() => {
    reset({
      description,
    });
  }, [description, reset]);

  return (
    <Card className="Card">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card.Body>
          <Card.Title className="Title">About me</Card.Title>
          <Card.Text className="Text">
            Get everyone&apos;s attention to showcase who you are and what you
            are looking for.
          </Card.Text>

          {isEditing ? (
            <Form.Group controlId="description" className="form-group">
              <Form.Label>Add description (max 500 characters)</Form.Label>
              <Form.Control
                className="fs-13 h-auto"
                as="textarea"
                rows={5}
                {...register("description", {
                  maxLength: 500,
                })}
                isInvalid={!!errors.description}
              />
              {errors.description && (
                <Form.Control.Feedback type="invalid">
                  {errors.description.type === "maxLength" &&
                    "Max length exceeded"}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          ) : (
            <>
              <p className="fs-13 WordWrap">{description}</p>
            </>
          )}
        </Card.Body>

        <Card.Footer>
          {isEditing ? (
            <>
              <Button
                className="Cancel"
                variant="secondary"
                disabled={isSaving}
                onClick={handleClose}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                className="Save"
                variant="primary"
                disabled={isSaving}
              >
                Save
              </Button>
            </>
          ) : (
            <Button
              variant="link"
              className="Edit"
              onClick={() => setIsEditing(true)}
            >
              <FontAwesomeIcon icon={faPen} className="Icon" />
              Edit description
            </Button>
          )}
        </Card.Footer>
      </Form>
    </Card>
  );
};

AboutMeCard.propTypes = {
  description: PropTypes.string,
  onSaved: PropTypes.func,
};

export default AboutMeCard;

import { useMemo } from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";

import "Components/styles/UserImage.css";

const MAX_INITIALS_TO_SHOW = 2;

function UserImage(props) {
  const getUserInitials = (userName) => {
    if (!userName) return "?";
    const splitUserName = userName.split(" ");
    const initials = splitUserName.map((name) => name[0]);
    return initials.slice(0, MAX_INITIALS_TO_SHOW).join("");
  };

  let className = "UserImage";
  if (props.large) {
    className = "UserImageLarge";
  }
  if (props.isProfilePicture) {
    className = "UserImageProfile";
  }

  if (props.className) {
    className += " " + props.className;
  }

  const initials = useMemo(
    () => getUserInitials(props.userName),
    [props.userName]
  );

  const image = () => {
    if (!props.userImageUrl) {
      return (
        <div className="Initials" style={{ backgroundColor: props.userColor }}>
          {initials}
        </div>
      );
    } else if (props.withoutCache) {
      const date = new Date();
      return <Image src={`${props.userImageUrl}?ver=${date.getTime()}`} />;
    }
    return <Image src={props.userImageUrl} />;
  };

  return (
    <div title={props.userName} className={className}>
      {image()}
    </div>
  );
}

UserImage.propTypes = {
  large: PropTypes.bool,
  isProfilePicture: PropTypes.bool,
  withoutCache: PropTypes.bool,
  userName: PropTypes.string.isRequired,
  userImageUrl: PropTypes.string,
  userColor: PropTypes.string,
  className: PropTypes.string,
};

export default UserImage;

import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import micoach, { EvaluationApi } from "micoach-api";

import StateCard from "Components/Common/StateCard";
import Evaluator from "Components/Evaluation/Skill/Evaluator";
import Summary from "Components/Evaluation/Skill/Summary";
import { assets } from "constants.js";

import styles from "Components/Evaluation/Skill/styles/Handler.module.css";

const Handler = (props) => {
  const { evaluation } = props;
  const skills = evaluation?.skills;
  const doRoleHasNoSkills = skills?.length === 0;
  const isRequest =
    evaluation?.evaluatorRelation !== micoach.EvaluatorRelationEnum.Self;
  const history = useHistory();
  const commentsRef = useRef(null);
  const [comments, setComments] = useState(evaluation?.comments || "");
  const [showSummary, setShowSummary] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [areSkillsValid, setAreSkillsValid] = useState(false);
  const [skillToEvaluate, setSkillToEvaluate] = useState();
  const [skillToEvaluateIndex, setSkillToEvaluateIndex] = useState(-1);
  const [focusOnComments, setFocusOnComments] = useState(false);

  // Summary handlers
  const handleClickSummary = (skill) => {
    setSkillToEvaluate(skill);
    setShowSummary(false);
    setFocusOnComments(false);
  };

  const handleClickCancel = () => {
    history.push("/evaluation/skill/dashboard");
  };

  const handleClickSave = async () => {
    setIsSaving(true);
    try {
      let response;
      const evaluationData = {
        ...evaluation,
        comments,
      };

      if (evaluation._id) {
        if (isRequest) {
          response = await EvaluationApi.updatePublicEvaluation(
            evaluation._id,
            evaluationData
          );
        } else {
          response = await EvaluationApi.updateEvaluation(
            evaluation._id,
            evaluationData
          );
        }
      } else {
        response = await EvaluationApi.createEvaluation(evaluationData);
      }
      toast.success("Success! The assessment has been saved.");
      props.onSavedEvaluation?.(response.data);
    } catch (error) {
      console.error(error);
      toast.error("Error, please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleClickSubmit = async () => {
    setIsSaving(true);
    try {
      const evaluationData = {
        ...evaluation,
        comments,
        status: micoach.EvaluationStatusEnum.Closed,
        completed: true,
      };

      if (isRequest) {
        const response = await EvaluationApi.updatePublicEvaluation(
          evaluation._id,
          evaluationData
        );
        props.onSavedEvaluation?.(response.data);
      } else {
        if (evaluation._id) {
          await EvaluationApi.updateEvaluation(evaluation._id, evaluationData);
        } else {
          await EvaluationApi.createEvaluation(evaluationData);
        }
        toast.success("Success! The assessment has been submitted.");
        history.push("/evaluation/skill/dashboard");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error, please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  // Evaluator handlers
  const handleClickEvaluator = (skill) => {
    props.onEvaluatedSkill?.(skill);
  };

  const handleClickPreviousSkill = () => {
    setSkillToEvaluate(skills[skillToEvaluateIndex - 1]);
  };

  const handleClickNextSkill = () => {
    setSkillToEvaluate(skills[skillToEvaluateIndex + 1]);
  };

  const handleClickContinue = () => {
    setShowSummary(true);
    setFocusOnComments(true);
  };

  useEffect(() => {
    focusOnComments && commentsRef?.current?.focus?.();
  }, [focusOnComments]);

  useEffect(() => {
    setAreSkillsValid(
      !skills?.some(
        (skill) => skill.value === undefined || skill.value === null
      )
    );
  }, [skills]);

  useEffect(() => {
    setSkillToEvaluateIndex(
      skills?.findIndex(
        (skill) =>
          skill.category === skillToEvaluate?.category &&
          skill.name === skillToEvaluate?.name
      )
    );
  }, [skills, skillToEvaluate]);

  useEffect(() => {
    setIsValid(
      !skills?.some(
        (skill) => skill.value === undefined || skill.value === null
      ) && comments.trim().length > 0
    );
  }, [skills, comments]);

  if (!evaluation) {
    return (
      <StateCard
        imageUrl={assets.ZERO_STATE}
        title="Skills assessment not found or expired"
      />
    );
  }

  if (evaluation.completed) {
    return (
      <StateCard imageUrl={assets.THANKS} title="Skills assessment completed" />
    );
  }

  return (
    <div className={styles.Handler}>
      {showSummary ? (
        <>
          {doRoleHasNoSkills && !isRequest && (
            <Alert variant="danger">
              Selected role has no skills to be assessed, please select another
              role in your&nbsp;<Link to={`/profile`}>profile</Link>.
            </Alert>
          )}
          {doRoleHasNoSkills && isRequest && (
            <Alert variant="danger">
              The role belonging to this skill assessment has no skills to be
              assessed.
            </Alert>
          )}
          <div className={styles.Title}>{evaluation.name}</div>
          <div className={styles.Subtitle}>
            {evaluation.userName} {isRequest ? "as" : "-"} {evaluation.userRole}
          </div>

          <div className={styles.Instructions}>
            <strong>Instructions:</strong> <br />
            {isRequest
              ? `Select the proficiency level you see in ${evaluation.userName} for each of the skills 
          of a ${evaluation.userRole}. Be sure to select a level for each skill before you submit it. 
          This assessment takes 15 min.`
              : `Select the proficiency level you have on each skills expected as ${evaluation.userRole}. 
          Be sure to select a level for each skill before you submit it. 
          Self assesments can be done every quarter of the year to allow you mesure your progress.`}
          </div>
        </>
      ) : (
        <Row className="mt-1 mb-3">
          <Col md={6}>
            <Button
              variant="link"
              className="p-0"
              onClick={() => setShowSummary(true)}
              disabled={isSaving}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="me-2" /> Back
            </Button>
          </Col>
          <Col md={6} className="text-end">
            skill {skillToEvaluateIndex + 1} of {skills.length}
          </Col>
        </Row>
      )}
      <div className={styles.Container}>
        {showSummary ? (
          <Summary skills={evaluation.skills} onClick={handleClickSummary} />
        ) : (
          <Evaluator
            skill={skillToEvaluate}
            roleName={evaluation.userRole}
            onClick={handleClickEvaluator}
          />
        )}
      </div>

      {showSummary ? (
        <>
          <div className={styles.Comments}>
            <Row>
              <Col md={6} sm={12}>
                <Form>
                  <Form.Group
                    controlId="comments"
                    className="form-group required"
                  >
                    <Form.Label>Comments</Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      ref={commentsRef}
                      name="comments"
                      rows={6}
                      required={true}
                      maxLength={2000}
                      disabled={isSaving || doRoleHasNoSkills}
                      value={comments}
                      className="h-100"
                      onBlur={(event) => setComments(event.target.value.trim())}
                      onChange={(event) => setComments(event.target.value)}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col md={6} sm={12}>
                <div className="pt-5">
                  {isRequest
                    ? "Before submitting, provide an overview of the assessment, highlighting the biggest achievements and recommend areas to develop to continue growing. The recipient won’t see individual comments, if not the general overview from the evaluators."
                    : "Provide an overview of your self assessment, considering your biggest achievements and your desirable areas to develop to continue growing."}
                </div>
              </Col>
            </Row>
          </div>
          <div className={styles.SummaryButtons}>
            {!isRequest && (
              <Button
                variant="link"
                className={styles.Button}
                disabled={isSaving}
                onClick={handleClickCancel}
              >
                Cancel
              </Button>
            )}
            <Button
              variant="secondary"
              className={styles.Button}
              disabled={isSaving || doRoleHasNoSkills}
              onClick={handleClickSave}
            >
              Save
            </Button>
            <Button
              className={styles.Button}
              disabled={isSaving || !isValid || doRoleHasNoSkills}
              onClick={handleClickSubmit}
            >
              Submit
            </Button>
          </div>
        </>
      ) : (
        <div className={styles.EvaluatorButtons}>
          <Button
            variant="link"
            className={styles.Button}
            disabled={skillToEvaluateIndex === 0}
            onClick={handleClickPreviousSkill}
          >
            Previous skill
          </Button>
          <Button
            variant="primary"
            className={styles.Button}
            disabled={skillToEvaluateIndex === skills.length - 1}
            onClick={handleClickNextSkill}
          >
            Next skill
          </Button>
          {areSkillsValid && (
            <Button
              variant="secondary"
              className={styles.Button}
              onClick={handleClickContinue}
            >
              Continue
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

Handler.propTypes = {
  evaluation: PropTypes.object,
  onEvaluatedSkill: PropTypes.func,
  onSavedEvaluation: PropTypes.func,
};

export default Handler;

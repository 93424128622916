import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import micoach, { HiringEventApi } from "micoach-api";
import { getFormattedTimeZoneDate, isDuplicate } from "utils";

import InterviewPanelistsList from "Components/Interview/InterviewPanelistsList";

import styles from "Components/Interview/styles/InterviewPanelistsModal.module.css";

const InterviewPanelistsModal = (props) => {
  const { show, hiringEvent, interview, onClose, onUpdate } = props;

  const [isSaving, setIsSaving] = useState(false);

  const hiringEventIsAfterToday = dayjs(hiringEvent?.date).isAfter(dayjs());

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
    reset,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });

  const formProps = {
    register,
    control,
    errors,
    getValues,
    setValue,
    reset,
    clearErrors,
  };

  const getFormatedDate = () => {
    const startDate = getFormattedTimeZoneDate(
      interview?.startDate,
      "dddd, MMMM DD, YYYY • h:mma"
    );
    const endDate = getFormattedTimeZoneDate(interview?.endDate, "h:mma");

    return `${startDate} - ${endDate}`;
  };

  const getMetingStatusColor = (status) => {
    switch (status) {
      case micoach.MeetingStatusEnum.Open:
        return styles.Open;
      case micoach.MeetingStatusEnum.Confirmed:
        return styles.Confirmed;
      case micoach.MeetingStatusEnum.Reschedule:
        return styles.Reschedule;
      case micoach.MeetingStatusEnum.Cancelled:
        return styles.Cancelled;
      default:
        break;
    }
  };

  const getPanelists = () => {
    const panelists = interview?.panelists ?? [];

    for (const panelist of panelists) {
      if (panelist?.startDate) {
        panelist.startDate = new Date(panelist?.startDate);
      }
      if (panelist?.endDate) {
        panelist.endDate = new Date(panelist?.endDate);
      }
      panelist.isDefaultPanelist = true;
    }

    return panelists;
  };

  const onSubmit = async (formValues) => {
    try {
      const panelists = formValues?.panelists ?? [];

      if (isDuplicate(panelists, "email")) {
        toast.error("Error! Each panelist email must be unique.");
        return;
      }

      setIsSaving(true);

      for (const panelist of panelists) {
        panelist.startDate = dayjs(hiringEvent?.date)
          .tz("America/Tijuana")
          .set("hour", panelist.startDate.getHours())
          .set("minute", panelist.startDate.getMinutes())
          .set("seconds", 0);
        panelist.endDate = dayjs(hiringEvent?.date)
          .tz("America/Tijuana")
          .set("hour", panelist.endDate.getHours())
          .set("minute", panelist.endDate.getMinutes())
          .set("seconds", 0);
      }

      const { data } = await HiringEventApi.updateHiringEventInterviewPanelists(
        interview.hiringEvent,
        interview._id,
        panelists
      );

      toast.success("Success! The Panelists have been updated.");
      onUpdate?.(data);
    } catch (error) {
      const status = error.response?.status;
      const errorMessage = error.response?.data?.errors?.[0]?.message;

      if ((status === 409 || status === 404) && errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error("Error, please try again.");
      }

      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className={styles.Header}></Modal.Header>
        <Modal.Body className={styles.Body}>
          <span
            className={`${styles.Status} ${getMetingStatusColor(
              interview?.meetingStatus
            )}`}
          >
            <FontAwesomeIcon icon={faSquare} className={styles.StatusIcon} />
            {interview?.meetingStatus}
          </span>
          <a
            className={styles.Title}
            href={`/candidate/view/${interview?.applicationProcess?.candidate?._id}`}
            target="_blank"
            rel="noreferrer"
          >
            {`${interview?.applicationProcess?.candidate?.name} -
              ${interview?.applicationProcess?.position?.title}`}
          </a>
          <div className={styles.Subtitle}>
            <span>{getFormatedDate()}</span>
            <span>{`${interview?.workModel} • ${interview?.employment}`}</span>
          </div>
          <div className={styles.MeetingLink}>
            <span> Meeting Link</span>
            <a href={interview?.meetingLink} target="_blank" rel="noreferrer">
              {interview?.meetingLink}
            </a>
          </div>
          <div className={styles.MeetingAccount}>
            <span>Meeting Account</span>
            <span>{`${interview?.meetingAccount} ITJ email`}</span>
          </div>
          <span className={styles.Panelists}>Panelists</span>
          <InterviewPanelistsList
            isSaving={isSaving}
            formProps={formProps}
            defaultPanelists={getPanelists()}
            interview={interview}
            isDisabled={!hiringEventIsAfterToday}
          />
          <div className={styles.Host}>
            <span>Host</span>
            <span>{interview?.recruiterName}</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            className="SaveButton"
            disabled={isSaving || !hiringEventIsAfterToday}
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

InterviewPanelistsModal.propTypes = {
  show: PropTypes.bool,
  hiringEvent: PropTypes.object,
  interview: PropTypes.object,
  onUpdate: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

InterviewPanelistsModal.defaultProps = {
  show: false,
};

export default InterviewPanelistsModal;

import { createContext, useMemo } from "react";
import { useKeycloak } from "@react-keycloak/web";

import { useProfileCompletion } from "Hooks/useUsers";

export const UserProfileContext = createContext({
  profileCompletion: 0,
  isLoadingProfileCompletion: false,
  loadProfileCompletion: () => {},
});

const UserProfileContextProvider = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children } = props;

  const { keycloak } = useKeycloak();
  const {
    profileCompletion,
    isLoadingProfileCompletion,
    loadProfileCompletion,
  } = useProfileCompletion(keycloak);

  return (
    <UserProfileContext.Provider
      value={useMemo(
        () => ({
          profileCompletion,
          isLoadingProfileCompletion,
          loadProfileCompletion,
        }),
        [profileCompletion, isLoadingProfileCompletion, loadProfileCompletion]
      )}
    >
      {children}
    </UserProfileContext.Provider>
  );
};

export default UserProfileContextProvider;

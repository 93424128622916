import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import micoach, { ReportApi } from "micoach-api";

import CustomSelect from "Components/CustomSelect";
import Loading from "Components/Loading";
import { useCompanies } from "Hooks/useCompanies";
import { useUsersByRole } from "Hooks/useUsers";
import { getEndOfTime, getStartOfTime } from "utils.js";

import styles from "Screens/Report/styles/Report.module.css";

const CandidateApplicationProcess = () => {
  const [generatingReport, setGeneratingReport] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const [companies, , loadingCompanies] = useCompanies();
  const { users: recruiters, loadingUsers: loadingRecruiters } = useUsersByRole(
    {
      role: micoach.UserRoleEnum.Recruiter,
      enabled: true,
    }
  );

  const today = new Date();
  const watchStartDate = watch("startDate");

  const onSubmit = async (formValues) => {
    setGeneratingReport(true);

    const companyId =
      formValues.company !== "" ? formValues.company : undefined;
    const companyName = companyId
      ? companies?.find((company) => company._id === companyId)?.name
      : null;
    const recruiterId =
      formValues.recruiter !== "" ? formValues.recruiter : undefined;
    const recruiterName = recruiterId
      ? recruiters?.find((recruiter) => recruiter._id === recruiterId)?.name
      : null;
    const startDate = formValues.startDate
      ? getStartOfTime(formValues.startDate, "day").toISOString()
      : undefined;
    const endDate = formValues.endDate
      ? getEndOfTime(formValues.endDate, "day").toISOString()
      : undefined;

    try {
      const params = [startDate, endDate, companyId, recruiterId, "CSV"];
      const { data } = await ReportApi.getApplicationProcessCandidate(
        ...params,
        {
          responseType: "arraybuffer",
        }
      );
      const blob = new Blob([data]);
      const fileName = `${companyName ? `${companyName} - ` : ""}${
        recruiterName ? `${recruiterName} - ` : ""
      }HiringProcesses.csv`;
      saveAs(blob, fileName);
    } catch (error) {
      toast.error("Error!, please try again.");
    } finally {
      setGeneratingReport(false);
    }
  };

  const companiesOptions = [
    { value: "", label: "All" },
    ...companies?.map((company) => {
      return {
        value: company._id,
        label: company.name,
      };
    }),
  ];

  const recruitersOptions = [
    { value: "", label: "All" },
    ...recruiters?.map((recruiter) => {
      return {
        value: recruiter._id,
        label: recruiter.name,
      };
    }),
  ];

  if (loadingCompanies || loadingRecruiters) {
    return <Loading />;
  }

  return (
    <>
      <h1 className="ScreenTitle">TA hiring process report</h1>
      <p className={styles.Description}>
        Lists candidate totals for each step from presented and afterwards for
        the selected company, recruiter and date range.
      </p>
      <Row>
        <Col sm={8} md={6} xl={4}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="company" className="form-group">
              <Form.Label>Select a company</Form.Label>
              <CustomSelect
                name="company"
                control={control}
                options={companiesOptions}
                defaultValue={companiesOptions[0]}
                placeholder="Start typing to search in companies..."
                isClearable={false}
              />
            </Form.Group>

            <Form.Group controlId="recruiter" className="form-group">
              <Form.Label>Select a recruiter</Form.Label>
              <CustomSelect
                name="recruiter"
                control={control}
                options={recruitersOptions}
                defaultValue={recruitersOptions[0]}
                placeholder="Start typing to search in recruier..."
                isClearable={false}
              />
            </Form.Group>

            <Row>
              <Form.Group
                as={Col}
                controlId="starDate"
                className="required form-group"
              >
                <Form.Label>Start date</Form.Label>
                <Controller
                  name="startDate"
                  control={control}
                  defaultValue={today}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      showPopperArrow={false}
                      selected={value}
                      onChange={onChange}
                      dateFormat="MMM dd, yyyy"
                      className={`form-control ${
                        errors.endDate ? "is-invalid" : ""
                      }`}
                      endDate={today}
                      maxDate={today}
                      selectsStart
                      title="Start date"
                    />
                  )}
                />
                {errors.startDate && (
                  <div className="invalid-feedback d-block">
                    {errors.startDate.type === "required" &&
                      "Start date is required"}
                  </div>
                )}
              </Form.Group>

              <Form.Group
                as={Col}
                controlId="endDate"
                className="required form-group"
              >
                <Form.Label>End date</Form.Label>
                <Controller
                  name="endDate"
                  control={control}
                  defaultValue={today}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      showPopperArrow={false}
                      selected={value}
                      onChange={onChange}
                      dateFormat="MMM dd, yyyy"
                      className={`form-control ${
                        errors.endDate ? "is-invalid" : ""
                      }`}
                      minDate={watchStartDate}
                      startDate={watchStartDate}
                      maxDate={today}
                      selectsEnd
                      title="End date"
                    />
                  )}
                />
                {errors.endDate && (
                  <div className="invalid-feedback d-block">
                    {errors.endDate.type === "required" &&
                      "End date is required"}
                  </div>
                )}
              </Form.Group>
            </Row>

            <div className={styles.Buttons}>
              <Button type="submit" disabled={generatingReport}>
                <FontAwesomeIcon icon={faFileDownload} /> Download report
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default CandidateApplicationProcess;

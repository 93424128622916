import { useState } from "react";
import { toast } from "react-toastify";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { ShareModal } from "@itj-micoach/micoach-common";

import ActionCard from "Components/Candidate/ActionCard";

const InviteFriendCard = (props) => {
  const [showShareModal, setShowShareModal] = useState(false);

  const url = process.env.REACT_APP_HOST;

  return (
    <div className="InviteFriendCard">
      <div className="btn-link" onClick={() => setShowShareModal(true)}>
        <ActionCard
          title="Invite a friend to join us"
          subtitle="Link"
          icon={faUserFriends}
        />
      </div>

      <ShareModal
        show={showShareModal}
        title="Share Invite"
        description="Invite a friend to join us"
        url={url}
        centered
        onClose={() => setShowShareModal(false)}
        onUrlCopied={() => toast.info("Link copied to clipboard")}
      />
    </div>
  );
};

export default InviteFriendCard;

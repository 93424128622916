import axios from "axios";

import keycloak from "../keycloak";

let backendUrl = "/api/micoach";

// You can override the value of backendUrl by creating a file
// named `.env.local` in the root of this repo and set the
// the following environment variable to your desired value.
if (process.env.REACT_APP_BACKEND_URL) {
  backendUrl = process.env.REACT_APP_BACKEND_URL;
}

/******************************************************************
 *                    Section for candidates                      *
 *****************************************************************/

async function fetchAvailableCandidates(queryParams) {
  const response = await axios.get(`${backendUrl}/candidate/available`, {
    params: queryParams,
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data;
}

async function getCandidate(id) {
  const response = await axios.get(`${backendUrl}/candidate/${id}`, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data;
}

async function deleteCandidate(id) {
  await axios.delete(`${backendUrl}/candidate/${id}`, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
}

async function updateChecklistItem(id, item) {
  await axios.put(`${backendUrl}/candidate/${id}/checklist/item`, item, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
}

async function getCandidateDocumentUploadUrl(id, document) {
  const response = await axios.post(
    `${backendUrl}/candidate/${id}/document`,
    document,
    {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    }
  );
  return response.data;
}

async function confirmUploadDocument(id, document) {
  const response = await axios.put(
    `${backendUrl}/candidate/${id}/document`,
    document,
    {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    }
  );
  return response.data;
}

async function getCandidateDocuments(candidateId) {
  const response = await axios.get(
    `${backendUrl}/candidate/${candidateId}/document`,
    {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    }
  );
  return response.data;
}

/******************************************************************
 *             Section for upload documents request               *
 *****************************************************************/
async function requestDocuments(body) {
  const response = await axios.post(`${backendUrl}/document-request`, body, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });

  return response.data;
}

/******************************************************************
 *                    Section for companies                       *
 *****************************************************************/
async function fetchCompanies() {
  const response = await axios.get(`${backendUrl}/company`, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data;
}

async function fetchCompaniesByName(name) {
  const response = await axios.get(`${backendUrl}/company/name/${name}`, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data;
}

async function getCompany(id) {
  const response = await axios.get(`${backendUrl}/company/${id}`, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data;
}

async function saveCompany(company) {
  const response = await axios.post(`${backendUrl}/company`, company, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data._id;
}

async function updateCompany(id, company) {
  await axios.put(`${backendUrl}/company/${id}`, company, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
}

async function deleteCompany(id) {
  await axios.delete(`${backendUrl}/company/${id}`, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
}

async function saveContact(id, contact) {
  const response = await axios.post(
    `${backendUrl}/company/${id}/contact/`,
    contact,
    {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    }
  );
  return response.data._id;
}

async function deleteContact(companyId, contactId) {
  await axios.delete(
    `${backendUrl}/company/${companyId}/contact/${contactId}`,
    {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    }
  );
}

/******************************************************************
 *               Section for application process                  *
 *****************************************************************/
async function getApplicationProcess(id) {
  const response = await axios.get(`${backendUrl}/application-process/${id}`, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data;
}

async function getApplicationProcesses(candidateId) {
  const response = await axios.get(
    `${backendUrl}/candidate/${candidateId}/application-process`,
    {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    }
  );
  return response.data;
}

async function completeApplicationProcess(applicationProcessId, positionId) {
  const response = await axios.put(
    `${backendUrl}/application-process/${applicationProcessId}/complete`,
    positionId,
    {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    }
  );

  return response.data;
}

async function updatePrescreening(applicationProcessId, prescreening) {
  const response = await axios.put(
    `${backendUrl}/application-process/${applicationProcessId}/prescreening`,
    prescreening,
    {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    }
  );
  return response.data;
}

async function downloadPrescreening(applicationProcessId, sections) {
  const response = await axios.get(
    `${backendUrl}/application-process/${applicationProcessId}/prescreening/pdf`,
    {
      params: { sections },
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
        Authorization: `Bearer ${keycloak.token}`,
      },
    }
  );
  return response.data;
}

/******************************************************************
 *                   Section for comments                         *
 *****************************************************************/

async function saveComment(comment) {
  const response = await axios.post(`${backendUrl}/comment`, comment, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data;
}

async function updateComment(id, comment) {
  const response = await axios.put(`${backendUrl}/comment/${id}`, comment, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data;
}

async function deleteComment(id) {
  const response = await axios.delete(`${backendUrl}/comment/${id}`, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data;
}

/******************************************************************
 *                    Section for users                           *
 *****************************************************************/
async function fetchUsersByRole(role) {
  const response = await axios.get(`${backendUrl}/user/role/${role}`, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data;
}

async function getProfile() {
  const response = await axios.get(`${backendUrl}/user/profile`, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data;
}

async function updateProfile(profile) {
  const response = await axios.put(`${backendUrl}/user/profile`, profile, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data;
}

async function getProfilePictureUploadUrl(file) {
  const response = await axios.post(
    `${backendUrl}/user/profile/picture`,
    file,
    {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    }
  );
  return response.data;
}

async function confirmUploadProfilePicture(file) {
  const response = await axios.put(`${backendUrl}/user/profile/picture`, file, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data;
}

async function deleteProfilePicture() {
  const response = await axios.delete(`${backendUrl}/user/profile/picture`, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data;
}

/******************************************************************
 *                      Section for roles                         *
 *****************************************************************/
async function fetchRoles() {
  const response = await axios.get(`${backendUrl}/role`, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data;
}

/******************************************************************
 *                      Section for skills                        *
 *****************************************************************/
async function fetchSkills() {
  const response = await axios.get(`${backendUrl}/skill`, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data;
}

/******************************************************************
 *                   Section for industries                       *
 *****************************************************************/
async function fetchIndustries() {
  const response = await axios.get(`${backendUrl}/industry`, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data;
}

/******************************************************************
 *                       Section for jobs                         *
 *****************************************************************/

async function saveApplication(application) {
  const response = await axios.post(`${backendUrl}/application`, application, {
    headers: { Authorization: `Bearer ${keycloak.token}` },
  });
  return response.data;
}

async function fetchJobs(queryParams) {
  const response = await axios.get(`${backendUrl}/job`, {
    params: queryParams,
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data;
}

async function getJob(jobId) {
  const response = await axios.get(`${backendUrl}/job/${jobId}`, {
    headers: { Authorization: `Bearer ${keycloak.token}` },
  });
  return response.data;
}

async function saveLikedJob(jobId) {
  const likedJob = { jobId };
  const response = await axios.post(
    `${backendUrl}/candidate/liked-job`,
    likedJob,
    {
      headers: { Authorization: `Bearer ${keycloak.token}` },
    }
  );
  return response.data;
}

async function deleteLikedJob(jobId) {
  const response = await axios.delete(
    `${backendUrl}/candidate/liked-job/${jobId}`,
    {
      headers: { Authorization: `Bearer ${keycloak.token}` },
    }
  );
  return response.data;
}

const exports = {
  fetchAvailableCandidates,
  getCandidate,
  updateChecklistItem,
  deleteCandidate,
  getCandidateDocumentUploadUrl,
  confirmUploadDocument,
  getCandidateDocuments,
  requestDocuments,

  fetchCompanies,
  fetchCompaniesByName,
  saveCompany,
  getCompany,
  updateCompany,
  deleteCompany,
  saveContact,
  deleteContact,

  getApplicationProcess,
  getApplicationProcesses,
  completeApplicationProcess,
  updatePrescreening,
  downloadPrescreening,

  saveComment,
  updateComment,
  deleteComment,

  fetchUsersByRole,
  getProfile,
  updateProfile,
  getProfilePictureUploadUrl,
  confirmUploadProfilePicture,
  deleteProfilePicture,

  fetchRoles,
  fetchSkills,
  fetchIndustries,

  saveApplication,
  fetchJobs,
  getJob,
  saveLikedJob,
  deleteLikedJob,
};

export default exports;

import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";

function EditContact() {
  const { register } = useForm();

  return (
    <div>
      <h1>Update contact</h1>
      <hr />
      <Form>
        {/* onSubmit={handleSubmit(onSubmit)} */}
        <Form.Group controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" {...register("name")} />
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" {...register("email")} />
        </Form.Group>
        <Form.Group controlId="phone">
          <Form.Label>Phone number</Form.Label>
          <Form.Control type="text" {...register("phone")} />
        </Form.Group>
        <Form.Group controlId="jobTitle">
          <Form.Label>Job Title</Form.Label>
          <Form.Control type="text" {...register("jobTitle")} />
        </Form.Group>
        <Button type="submit">Update</Button>
      </Form>
    </div>
  );
}

export default EditContact;

import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";

import UserImageDetails from "Components/Common/UserImageDetails";
import Loading from "Components/Loading";
import UserPointsByPeriod from "Components/Ranking/UserPointsByPeriod";

import "Components/styles/Card.css";

const UserPosition = (props) => {
  const { position } = props;

  if (!position) return null;

  return (
    <div className="d-flex align-items-center justify-content-between my-3">
      <div className="d-flex align-items-center">
        <span>
          <strong>{position.position}</strong>
        </span>
        <div className="ps-3">
          <UserImageDetails
            userImageUrl={position.imageUrl}
            userName={position.userName}
            title={position.userName}
            subtitle={position.role}
          />
        </div>
      </div>

      <span className="fs-14">{position.points} pts</span>
    </div>
  );
};

const UserPointsRanking = (props) => {
  const { ranking } = props;

  if (!ranking?.length) return null;

  return ranking?.map((position) => {
    return <UserPosition key={position.userId} position={position} />;
  });
};

const UserPointsRankingCard = (props) => {
  const { ranking, points, loading } = props;

  return (
    <Card className="Card">
      <Card.Body>
        <Card.Title className="pb-3">Ranking</Card.Title>
        {loading ? (
          <Loading />
        ) : (
          ranking.ranking &&
          ranking.userRanking && (
            <>
              <UserPointsRanking ranking={ranking.ranking} />
              <hr />
              <UserPointsRanking ranking={[ranking.userRanking]} />
              <UserPointsByPeriod points={points} />
            </>
          )
        )}
      </Card.Body>
    </Card>
  );
};

UserPosition.propTypes = {
  position: PropTypes.shape({
    position: PropTypes.number.isRequired,
    points: PropTypes.number.isRequired,
    userName: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
};

UserPointsRanking.propTypes = {
  ranking: PropTypes.arrayOf(PropTypes.object),
};

UserPointsRankingCard.propTypes = {
  ranking: PropTypes.shape({
    ranking: PropTypes.arrayOf(PropTypes.object),
    userRanking: PropTypes.object,
  }).isRequired,
  points: PropTypes.number,
  loading: PropTypes.bool,
};

UserPointsRankingCard.defaultProps = {
  ranking: {},
  loading: false,
};

export default UserPointsRankingCard;

import { useCallback, useEffect, useState } from "react";
import { UserApi } from "micoach-api";

export const useUsersByRole = (props) => {
  const { role, enabled } = props;

  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const loadUsers = useCallback(async () => {
    setLoadingUsers(true);
    try {
      const { data } = await UserApi.listUsersByRole(role, enabled);
      setUsers(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUsers(false);
    }
  }, [role, enabled]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  return { users, loadingUsers, loadUsers, setUsers };
};

export const useProfile = () => {
  const [profile, setProfile] = useState(null);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [error, setError] = useState(null);

  const loadProfile = useCallback(async () => {
    setLoadingProfile(true);
    try {
      const response = await UserApi.getProfile();
      setProfile(response.data);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoadingProfile(false);
    }
  }, []);

  return {
    profile,
    setProfile,
    loadingProfile,
    loadProfile,
    error,
  };
};

export const useUpdateProfile = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState(null);
  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);

  const updateProfile = useCallback(async (profile) => {
    setStatus(0);
    setIsUpdating(true);
    setError(null);
    setErrorCode("");
    setErrorMessage("");

    try {
      const { data: updatedProfile, status: httpStatus } =
        await UserApi.updateProfile(profile);

      setStatus(httpStatus);
      setIsSuccess(true);

      return updatedProfile;
    } catch (error) {
      console.error(error);
      const {
        response: { data: errorData, status: httpStatus },
      } = error;

      setStatus(httpStatus);
      setIsSuccess(false);
      setError(errorData);

      const errorObj = errorData?.errors?.[0];

      if (errorObj) {
        setErrorCode(errorObj.errorCode);
        setErrorMessage(errorObj.message);
      } else {
        setErrorCode("unknown.error");
        setErrorMessage("Error, please try again.");
      }
    } finally {
      setIsUpdating(false);
    }
  }, []);

  return {
    updateProfile,
    error,
    errorCode,
    errorMessage,
    status,
    isUpdating,
    isSuccess,
  };
};

export const useProfileCompletion = (keycloak) => {
  const [isLoadingProfileCompletion, setIsLoadingProfileCompletion] =
    useState(false);
  const [profileCompletion, setProfileCompletion] = useState(0);

  const loadProfileCompletion = useCallback(async () => {
    if (keycloak.authenticated) {
      setIsLoadingProfileCompletion(true);
      try {
        const { data } = await UserApi.getAverageByUserProfileSections();
        const completion = data?.reduce(
          (acummulator, section) => acummulator + section.average,
          0
        );
        setProfileCompletion(completion);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingProfileCompletion(false);
      }
    }
  }, [keycloak.authenticated]);

  useEffect(() => {
    loadProfileCompletion();
  }, [loadProfileCompletion]);

  return {
    profileCompletion,
    isLoadingProfileCompletion,
    loadProfileCompletion,
  };
};

export const useCoachees = (userId, period) => {
  const [coachees, setCoachees] = useState([]);
  const [loadingCoachees, setLoadingCoachees] = useState(false);

  const loadCoachees = useCallback(async () => {
    setLoadingCoachees(true);
    const quarter = period?.quarter;
    const year = period?.year;

    try {
      const { data } = await UserApi.listUserCoachees(userId, quarter, year);
      setCoachees(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCoachees(false);
    }
  }, [userId, period]);

  useEffect(() => {
    loadCoachees();
  }, [loadCoachees]);

  return { loadingCoachees, coachees, loadCoachees, setCoachees };
};

export const useUserPoints = (userId, startDate, endDate) => {
  const [userPoints, setUserPoints] = useState([]);
  const [loadingUserPoints, setLoadingUserPoints] = useState(false);

  const loadUserPoints = useCallback(async () => {
    setLoadingUserPoints(true);
    try {
      const { data } = await UserApi.getUserPoints(userId, startDate, endDate);
      setUserPoints(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUserPoints(false);
    }
  }, [userId, startDate, endDate]);

  useEffect(() => {
    loadUserPoints();
  }, [loadUserPoints]);

  return { userPoints, loadingUserPoints };
};

export const useCoaches = () => {
  const [coaches, setCoaches] = useState([]);
  const [loadingCoaches, setLoadingCoaches] = useState(false);

  const loadCoaches = useCallback(async () => {
    setLoadingCoaches(true);
    try {
      const { data } = await UserApi.listUserCoach();
      setCoaches(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCoaches(false);
    }
  }, []);

  useEffect(() => {
    loadCoaches();
  }, [loadCoaches]);

  return { coaches, loadingCoaches };
};

import React from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import { useHistory } from "react-router-dom";

import StepBadge from "Components/StepBadge";
import { kanbanColumns } from "constants.js";

import "./styles/KanbanColumn.css";
import "Components/styles/Card.css";

import KanbanItemList from "./KanbanItemList";

const KanbanColumn = (props) => {
  const {
    step,
    candidates,
    userId,
    isExternalRecruiter,
    onClickRequestDocuments,
  } = props;

  const history = useHistory();

  const handleClickStepBadge = () => {
    if (step === kanbanColumns.TECHNICAL_INTERVIEW.key) {
      history.push("/hiring-events");
    }
  };

  return (
    <Card className="Card KanbanColumn">
      <Card.Header>
        <StepBadge step={step} onClick={handleClickStepBadge} />
      </Card.Header>
      <Card.Body>
        <KanbanItemList
          step={step}
          candidates={candidates}
          userId={userId}
          isExternalRecruiter={isExternalRecruiter}
          onClickHire={props.onClickHire}
          onClickDiscard={props.onClickDiscard}
          onClickMessage={props.onClickMessage}
          onClickMove={props.onClickMove}
          onClickRequestDocuments={onClickRequestDocuments}
        />
      </Card.Body>
    </Card>
  );
};

KanbanColumn.propTypes = {
  step: PropTypes.string.isRequired,
  candidates: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  isExternalRecruiter: PropTypes.bool,
  onClickHire: PropTypes.func,
  onClickDiscard: PropTypes.func,
  onClickMessage: PropTypes.func,
  onClickMove: PropTypes.func,
  onClickRequestDocuments: PropTypes.func,
};

export default React.memo(KanbanColumn);

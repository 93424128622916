import { useCallback, useState } from "react";
import { CandidateApi } from "micoach-api";

import { candidateStatus, kanbanColumns } from "constants.js";
import { isCandidateEmployee, sortCandidatesByStatusDate } from "utils.js";

export function useKanban() {
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState(false);

  const createColumns = useCallback(async (query) => {
    try {
      setError(false);
      setLoading(true);

      const search = query?.search
        ? encodeURIComponent(query.search)
        : undefined;
      const recruiter = query?.recruiter || undefined;
      const tagId = query?.tagId || undefined;
      const source = query?.source || undefined;
      const companyId = query?.companyId || undefined;
      const status = query?.status || undefined;
      const email = query?.email || undefined;
      const applicants = query?.applicants || undefined;
      const trainee =
        typeof query?.trainee === "boolean" ? query?.trainee : undefined;
      const limit = query?.limit || undefined;

      const { data: candidates } = await CandidateApi.listCandidates(
        search,
        recruiter,
        tagId,
        source,
        companyId,
        status,
        email,
        applicants,
        trainee,
        limit
      );

      const newColumns = Object.keys(kanbanColumns)
        // Filter columns by candidate status parameter
        .filter((columnKey) => {
          return (
            query.status?.includes(columnKey) ||
            (columnKey === kanbanColumns.LEADS.key &&
              query.status?.includes(candidateStatus.LEAD.key))
          );
        })
        .map((columnKey) => {
          // Filter candidates by column
          const filteredCandidates = candidates.filter((candidate) => {
            // BLOCKED
            if (
              columnKey === kanbanColumns.BLOCKED.key &&
              candidate.status === candidateStatus.BLOCKED.key
            ) {
              return true;
            }

            // LEADS
            if (
              columnKey === kanbanColumns.LEADS.key &&
              candidate.status === candidateStatus.LEAD.key &&
              !isCandidateEmployee(candidate)
            ) {
              return true;
            }

            // OTHERS
            return candidate.status === columnKey;
          });

          // Sort candidates by status date
          const sortedCandidates =
            sortCandidatesByStatusDate(filteredCandidates);

          return {
            key: columnKey,
            candidates: sortedCandidates,
          };
        });

      setColumns(newColumns);
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    error,
    columns,
    createColumns,
    setColumns,
  };
}

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import micoach from "micoach-api";

import { getFormattedLocalDate } from "utils.js";

import styles from "Components/Task/styles/TaskDetailCard.module.css";

const TaskDetailCardDescription = (props) => {
  const { type, lecture, event, title, description, dueDate } = props;

  if (type === micoach.TaskTypeEnum.Watch) {
    const taskName = lecture?.name ?? event?.name;
    const taskUrl = `/trainings/${event?._id}/lectures/${lecture?._id ?? ""}`;

    return (
      <>
        <div className={styles.TaskName}>Watch the {taskName} training</div>

        <Link to={taskUrl}>Training link</Link>
      </>
    );
  }

  if (type === micoach.TaskTypeEnum.General) {
    const taskDueDate = getFormattedLocalDate(dueDate, "MMM D, YYYY");

    return (
      <>
        <div className={styles.TaskName}>{title}</div>
        {dueDate && <div>Due date: {taskDueDate}</div>}
        <br />
        <pre className="WordWrap">{description}</pre>
        <br />
        <div>
          This task has been assigned so that you can strengthen your skills,
          work together and continue building your career path.
        </div>
      </>
    );
  }

  return null;
};

TaskDetailCardDescription.propTypes = {
  type: PropTypes.string,
  lecture: PropTypes.object,
  event: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  dueDate: PropTypes.string,
};

export default TaskDetailCardDescription;

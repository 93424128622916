import { useCallback, useEffect, useState } from "react";
import { CandidateApi } from "micoach-api";
import { encodeItemsInArray } from "utils";

import BackendService from "Services/BackendService";

export const useCandidates = (defaultQueryParams) => {
  const [loadingCandidates, setLoadingCandidates] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [queryParams, setQueryParams] = useState(defaultQueryParams);

  const loadCandidates = useCallback(async () => {
    setLoadingCandidates(true);

    try {
      const search = queryParams?.search
        ? encodeURI(queryParams.search)
        : undefined;
      const recruiter = queryParams?.recruiter || undefined;
      const tagId = queryParams?.tagId || undefined;
      const source = queryParams?.source || undefined;
      const status = queryParams?.status || undefined;
      const email = queryParams?.email || undefined;
      const applicants = queryParams?.applicants || undefined;
      const trainee = queryParams?.trainee || undefined;
      const limit = queryParams?.limit || undefined;

      const response = await CandidateApi.listCandidates(
        search,
        recruiter,
        tagId,
        source,
        status,
        email,
        applicants,
        trainee,
        limit
      );

      setCandidates(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCandidates(false);
    }
  }, [queryParams]);

  useEffect(() => {
    loadCandidates();
  }, [loadCandidates]);

  return {
    loadingCandidates,
    candidates,
    loadCandidates,
    setQueryParams,
  };
};

export const useAvailableCandidates = (
  load = true,
  defaultQueryParams = { search: "", limit: 10 }
) => {
  const [loading, setLoading] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [queryParams, setQueryParams] = useState(defaultQueryParams);

  const loadCandidates = useCallback(async () => {
    setLoading(true);
    try {
      const data = load
        ? await BackendService.fetchAvailableCandidates(queryParams)
        : [];
      setCandidates(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [load, queryParams]);

  useEffect(() => {
    loadCandidates();
  }, [loadCandidates]);

  return [loading, candidates, setQueryParams];
};

export const useCandidate = (id) => {
  const [candidate, setCandidate] = useState(null);
  const [candidateId, setCandidateId] = useState(id);
  const [loading, setLoading] = useState(false);

  const loadCandidate = useCallback(async () => {
    setLoading(true);
    try {
      const response = candidateId
        ? await CandidateApi.getCandidate(candidateId)
        : null;
      setCandidate(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [candidateId]);

  useEffect(() => {
    loadCandidate();
  }, [loadCandidate]);

  return [loading, candidate, loadCandidate, setCandidateId];
};

export const useCandidatesHistorical = () => {
  const [loadingCandidatesHistorical, setLoadingCandidatesHistorical] =
    useState(false);
  const [candidatesHistorical, setCandidatesHistorical] = useState([]);
  const [totalCandidatesHistorical, setTotalCandidatesHistorical] = useState(0);

  const loadCandidatesHistorical = useCallback(async (query) => {
    setLoadingCandidatesHistorical(true);

    try {
      const search = query?.search ? encodeURI(query.search) : undefined;
      const role = query?.role ? encodeItemsInArray(query.role) : undefined;
      const company = query?.company
        ? encodeItemsInArray(query.company)
        : undefined;
      const recruiter = query?.recruiter
        ? encodeItemsInArray(query.recruiter)
        : undefined;
      const status = query?.status
        ? encodeItemsInArray(query.status)
        : undefined;
      const tags = query?.tags ? encodeItemsInArray(query.tags) : undefined;
      const limit = query?.limit || undefined;
      const page = query?.page || undefined;
      const sort = query?.sort ? encodeURIComponent(query.sort) : undefined;
      const response = await CandidateApi.listCandidatesHistorical(
        search,
        role,
        status,
        company,
        recruiter,
        tags,
        limit,
        page,
        sort
      );

      setCandidatesHistorical(response.data);
      setTotalCandidatesHistorical(parseInt(response.headers.total));
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCandidatesHistorical(false);
    }
  }, []);

  return {
    candidatesHistorical,
    totalCandidatesHistorical,
    loadingCandidatesHistorical,
    loadCandidatesHistorical,
    setCandidatesHistorical,
    setTotalCandidatesHistorical,
  };
};

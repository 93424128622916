import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Loading from "Components/Loading";
import { getAbbreviatedNumberFormat } from "utils.js";

import "Components/Candidate/styles/AchievementsSummary.css";

const AchievementSummary = (props) => {
  const { quantity, unit, label, image } = props;

  const formattedQuantity =
    quantity < 10 ? `0${quantity}` : getAbbreviatedNumberFormat(quantity);

  return (
    <div className="AchievementSummary">
      <div className="Image">
        <img src={`/assets/${image}`} alt="Achievement type decoration" />
      </div>
      <div>
        <div className="Content">
          <div className="Quantity">{formattedQuantity}</div>
          {unit && <div className="Unit">{unit}</div>}
        </div>
        <div className="Label">{label}</div>
      </div>
    </div>
  );
};

const AchievementsSummary = (props) => {
  const { points, certifications, hours, loading } = props;

  return (
    <Card className="AchievementsSummary Card">
      <Card.Body>
        <Card.Title>My achievements</Card.Title>
        {loading ? (
          <div className="text-center">
            <Loading />
          </div>
        ) : (
          <Row>
            <Col xs={6} md={4}>
              <AchievementSummary
                image="medal_blue.svg"
                quantity={points}
                unit="pts"
                label="Points earned"
              />
            </Col>
            <Col xs={6} md={4}>
              <AchievementSummary
                image="ribbon_blue.svg"
                quantity={certifications}
                label={`Certification${certifications > 1 ? "s" : ""}`}
              />
            </Col>
            <Col xs={6} md={4}>
              <AchievementSummary
                image="star_blue.svg"
                quantity={hours}
                label="Hours of training"
              />
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

AchievementSummary.propTypes = {
  quantity: PropTypes.number,
  unit: PropTypes.string,
  label: PropTypes.string,
  image: PropTypes.string,
};

AchievementsSummary.propTypes = {
  points: PropTypes.number,
  certifications: PropTypes.number,
  hours: PropTypes.number,
  loading: PropTypes.bool,
};

AchievementsSummary.defaultProps = {
  points: 0,
  certifications: 0,
  hours: 0,
  loading: false,
};

export default AchievementsSummary;

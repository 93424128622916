import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import {
  capitalizeEnum,
  getFormattedLocalDate,
  getStepFromCandidateStatus,
} from "utils";

import EllipsisMenu from "Components/Common/EllipsisMenu";
import Loading from "Components/Loading";
import SourceImage from "Components/SourceImage";
import { kanbanColumns } from "constants.js";

import styles from "Components/CandidateHistorical/styles/CandidateHistoricalTable.module.css";

const MENU_OPTIONS = [
  { name: "Reactivate", icon: faPowerOff, eventKey: "Reactivate" },
];

const CandidateHistoricalTable = (props) => {
  const {
    candidates,
    totalCandidatesHistorical,
    currentPage,
    loading,
    paginationPerPage,
    onChangePage,
    onChangeSort,
    onClickReactivate,
  } = props;

  const columns = [
    {
      name: <strong>Name</strong>,
      selector: (candidate) => (
        <a
          href={`/candidate/view/${candidate._id}`}
          target="_blank"
          rel="noreferrer"
          className={styles.Name}
        >
          {candidate.name}
        </a>
      ),
      sortable: true,
      wrap: true,
      columnName: "name",
    },
    {
      name: <strong>Email</strong>,
      selector: (candidate) => candidate.email,
      sortable: true,
      wrap: true,
      columnName: "email",
    },
    {
      name: <strong>Source</strong>,
      selector: (candidate) => (
        <div className={styles.Source}>
          <SourceImage source={candidate.source} />
          {capitalizeEnum(candidate.source)}
        </div>
      ),
      sortable: true,
      wrap: true,
      columnName: "source",
    },
    {
      name: <strong>Created date</strong>,
      selector: (candidate) =>
        candidate.createdAt && getFormattedLocalDate(candidate.createdAt),
      sortable: true,
      wrap: true,
      columnName: "createdAt",
    },
    {
      name: <strong>Last Role</strong>,
      selector: (candidate) => candidate.role,
      sortable: true,
      wrap: true,
      columnName: "role",
    },
    {
      name: <strong>Last TA Process</strong>,
      selector: (candidate) =>
        candidate.statusUpdatedAt &&
        getFormattedLocalDate(candidate.statusUpdatedAt),
      sortable: true,
      wrap: true,
      columnName: "statusUpdatedAt",
    },
    {
      name: <strong>Last Open Position</strong>,
      selector: (candidate) => candidate.positionName,
      sortable: true,
      wrap: true,
      columnName: "positionName",
    },
    {
      name: <strong>Company</strong>,
      selector: (candidate) => candidate?.companyName,
      sortable: true,
      wrap: true,
      columnName: "companyName",
    },
    {
      name: <strong>Kanban Status</strong>,
      selector: (candidate) =>
        kanbanColumns[getStepFromCandidateStatus(candidate.status)]?.label,
      sortable: true,
      wrap: true,
      columnName: "status",
    },
    {
      name: <strong>Last TA Member</strong>,
      selector: (candidate) => candidate.recruiterName,
      sortable: true,
      wrap: true,
      columnName: "recruiterName",
    },
    {
      cell: ({ _id }) => (
        <EllipsisMenu
          options={MENU_OPTIONS}
          onSelectedOption={() => onClickReactivate(_id)}
        />
      ),
      width: "110px",
      wrap: true,
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={candidates}
      pagination
      sortServer
      paginationServer
      defaultSortFieldId={1}
      progressPending={loading}
      progressComponent={<Loading />}
      paginationTotalRows={totalCandidatesHistorical}
      paginationDefaultPage={currentPage}
      paginationPerPage={paginationPerPage}
      paginationRowsPerPageOptions={[paginationPerPage]}
      highlightOnHover
      onSort={onChangeSort}
      onChangePage={onChangePage}
    />
  );
};

CandidateHistoricalTable.propTypes = {
  candidates: PropTypes.array,
  currentPage: PropTypes.number,
  totalCandidatesHistorical: PropTypes.number,
  loading: PropTypes.bool,
  paginationPerPage: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeSort: PropTypes.func,
  onClickReactivate: PropTypes.func,
};

CandidateHistoricalTable.defaultValues = {
  candidates: [],
  currentPage: 1,
  totalCandidatesHistorical: 0,
  loading: false,
  paginationPerPage: 30,
};

export default CandidateHistoricalTable;

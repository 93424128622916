import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import styles from "Components/Common/styles/FilterButton.module.css";

const FilterButton = (props) => {
  const {
    totalFilters,
    show,
    children,
    isMobile,
    setShowPopover,
    onClickReset,
  } = props;

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom-end"
      show={show}
      onToggle={() => setShowPopover(true)}
      overlay={
        <Popover data-testid="filter" className={styles.Popover}>
          <Popover.Header className={styles.Title}>
            <span>Filters</span>
            <Button variant="link" onClick={onClickReset}>
              Reset filters
            </Button>
          </Popover.Header>
          <Popover.Body className={styles.Content}>{children}</Popover.Body>
        </Popover>
      }
    >
      <Button
        variant="link"
        className={styles.FilterButton}
        onClick={() => setShowPopover(true)}
      >
        <Image src="/assets/icon_filter.svg" alt="Filters" />
        <span>
          {`Filters ${isMobile && totalFilters > 0 ? `(${totalFilters})` : ""}`}
        </span>
      </Button>
    </OverlayTrigger>
  );
};

FilterButton.propTypes = {
  totalFilters: PropTypes.number,
  show: PropTypes.bool,
  isMobile: PropTypes.bool,
  children: PropTypes.element,
  setShowPopover: PropTypes.func,
  onClickReset: PropTypes.func,
};

FilterButton.defaultProps = {
  totalFilters: 0,
  show: false,
  isMobile: false,
};

export default FilterButton;

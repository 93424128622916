import PropTypes from "prop-types";

import FormattedDate from "Components/FormattedDate";

import "Components/Candidate/styles/EventCard.css";

const EventCard = (props) => {
  const { event, variant } = props;

  let variantClass = "";
  if (variant === "small") {
    variantClass = "Small";
  }

  return (
    <div className={`Card EventCard ${variantClass}`}>
      <div className="CoverImage Desktop d-none">
        <img src={event.coverImageUrl} alt={event.name} />
      </div>

      <div className="Desktop w-100">
        <div className="Content">
          <div className="Details">
            <div className="Company">
              <img src={event.companyLogoUrl} alt={event.companyName} />
            </div>
            <div className="Info">
              <div className="Title">{event.name}</div>
              <div>
                {event.companyName} {event.topic && ` | ${event.topic}`}
              </div>
              <div>
                {event.type && event.type.toUpperCase()}
                {event.type && event.modality && " | "}
                {event.modality && event.modality.toUpperCase()}
              </div>
            </div>
          </div>
          <div className="Date">
            <FormattedDate>{event.startDate}</FormattedDate>
          </div>
        </div>

        <div className="CoverImage">
          <img src={event.coverImageUrl} alt={event.name} />
        </div>

        <div
          className="Description"
          dangerouslySetInnerHTML={{
            __html: `${event.description?.substring(0, 165)}...`,
          }}
        />

        <div className="SeeEvent">
          <a href={`/events/${event.slug}`}>See event </a>
        </div>
      </div>
    </div>
  );
};

EventCard.propTypes = {
  event: PropTypes.object.isRequired,
  variant: PropTypes.string,
};

export default EventCard;

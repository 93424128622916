import { useCallback, useEffect, useState } from "react";

import BackendService from "Services/BackendService";
import PublicBackendService from "Services/PublicBackendService";
import { getChecklistItemName } from "utils.js";

export function useDocuments(candidateId) {
  const [documents, setDocuments] = useState([]);

  const loadDocuments = useCallback(async () => {
    const data = await BackendService.getCandidateDocuments(candidateId);
    setDocuments(data);
  }, [candidateId]);

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments]);

  return { documents, setDocuments, loadDocuments };
}

export function useRequestedDocuments(uploadDocumentRequestId) {
  const [requestedDocuments, setRequestedDocuments] = useState([]);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [uploadDocumentRequest, setUploadDocumentRequest] = useState();
  const [isRequestValid, setIsRequestValid] = useState(false);
  const [loading, setLoading] = useState(true);

  const getUploadDocumentRequest = useCallback(async () => {
    if (uploadDocumentRequest) {
      return uploadDocumentRequest;
    }
    let newUploadDocumentRequest;

    setLoading(true);
    try {
      newUploadDocumentRequest =
        await PublicBackendService.getUploadDocumentRequest(
          uploadDocumentRequestId
        );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }

    setUploadDocumentRequest(newUploadDocumentRequest);
    if (newUploadDocumentRequest) {
      setIsRequestValid(true);
    }
    return newUploadDocumentRequest;
  }, [uploadDocumentRequestId, uploadDocumentRequest]);

  const getDocuments = useCallback(
    (request) => {
      if (!request || uploadedDocuments.length) {
        return uploadedDocuments;
      }

      return request.uploadedDocuments;
    },
    [uploadedDocuments]
  );

  const getRequestedDocuments = useCallback(async () => {
    const request = await getUploadDocumentRequest();
    const documents = getDocuments(request);

    const result = request?.documents
      .split(",")
      .filter((d) => d)
      .map((requestedDocument) => {
        const document = documents.find(
          (doc) => doc.checklistType === requestedDocument
        );

        return {
          _id: document?._id,
          key: requestedDocument,
          checklistItem: getChecklistItemName(requestedDocument),
          name: document?.fileName ?? "",
          size: document?.size,
          uploadDate: document?.updatedAt ?? "",
        };
      });

    setRequestedDocuments(result);
  }, [getUploadDocumentRequest, getDocuments]);

  useEffect(() => {
    if (!uploadDocumentRequestId) {
      return;
    }

    getRequestedDocuments();
  }, [uploadDocumentRequestId, getRequestedDocuments]);

  return [
    requestedDocuments,
    setUploadedDocuments,
    uploadDocumentRequest,
    isRequestValid,
    loading,
  ];
}

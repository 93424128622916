import { useCallback, useState } from "react";
import { HiringEventApi } from "micoach-api";

export const useHiringEvents = () => {
  const [hiringEvents, setHiringEvents] = useState([]);
  const [loadingHiringEvents, setLoadingHiringEvents] = useState(false);

  const loadHiringEvents = useCallback(async () => {
    setLoadingHiringEvents(true);
    try {
      const { data } = await HiringEventApi.listHiringEvents();

      setHiringEvents(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingHiringEvents(false);
    }
  }, []);

  return {
    hiringEvents,
    loadingHiringEvents,
    loadHiringEvents,
    setHiringEvents,
  };
};

export const useHiringEvent = () => {
  const [hiringEvent, setHiringEvent] = useState();
  const [loadingHiringEvent, setLoadingHiringEvent] = useState(false);
  const [hiringEventError, setHiringEventError] = useState({});

  const loadHiringEvent = useCallback(async (id) => {
    setLoadingHiringEvent(true);
    setHiringEventError(false);
    try {
      const { data } = await HiringEventApi.getHiringEvent(id);

      setHiringEvent(data);
    } catch (error) {
      console.error(error);
      const status = error.response?.status;
      const message =
        error.response?.data?.errors?.[0]?.message || "Unknown error";

      setHiringEventError({
        status,
        message,
      });
    } finally {
      setLoadingHiringEvent(false);
    }
  }, []);

  return {
    hiringEvent,
    loadingHiringEvent,
    loadHiringEvent,
    setHiringEvent,
    hiringEventError,
  };
};

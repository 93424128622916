import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import StepAccordion from "./StepAccordion";
import UserImage from "./UserImage";

function ApplicationAccordion(props) {
  const { applicationProcess, isEditable } = props;

  const [isShowingAllSteps, setIsShowingAllSteps] = useState(false);

  const getStepsToShow = () => {
    return isShowingAllSteps
      ? applicationProcess.steps
      : [applicationProcess.steps[0]];
  };

  return (
    <div>
      <div className="d-block pb-2">{applicationProcess.role}</div>
      {applicationProcess.position && (
        <div className="d-block pb-2">
          {applicationProcess.position.title} |{" "}
          {applicationProcess.position.company?.name}
        </div>
      )}
      <div className="d-block pb-2">
        <UserImage
          userName={applicationProcess?.recruiterName}
          userImageUrl={applicationProcess?.recruiterImageUrl}
          userColor={applicationProcess?.recruiterColor}
        />{" "}
        {applicationProcess.recruiterName}
        {" | "}
        {isEditable && applicationProcess.prescreening ? (
          <Link
            to={`/application-process/${applicationProcess._id}/prescreening`}
          >
            Pre-screening form
          </Link>
        ) : (
          <span className="fs-12">
            <i>Pre-screening form</i> not available.
          </span>
        )}
      </div>
      <div className="d-flex justify-content-between align-items-start">
        <div className="w-100">
          {getStepsToShow().map((step) => (
            <StepAccordion
              key={step.step}
              applicationProcessId={applicationProcess._id}
              step={step}
              isEditable={false}
            />
          ))}
        </div>
        <Button
          variant="link"
          onClick={() => {
            setIsShowingAllSteps(!isShowingAllSteps);
          }}
        >
          <FontAwesomeIcon
            icon={isShowingAllSteps ? faChevronUp : faChevronDown}
          />
        </Button>
      </div>
      <hr />
    </div>
  );
}

ApplicationAccordion.propTypes = {
  applicationProcess: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
};

export default ApplicationAccordion;

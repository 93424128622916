import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify";
import micoach from "micoach-api";

import CommentsButton from "Components/Common/CommentsButton";
import CommentsPopover from "Components/Common/CommentsPopover";
import StateCard from "Components/Common/StateCard";
import TaskDetailCardDescription from "Components/Task/TaskDetailCardDescription";
import TaskDetailCardTitle from "Components/Task/TaskDetailCardTitle";
import TaskStatusHandlerButton from "Components/Task/TaskStatusHandlerButton";
import UserImage from "Components/UserImage";
import { useTaskComments } from "Hooks/useTaskComments";
import { assets } from "constants.js";
import { getFormattedLocalDate } from "utils.js";

import styles from "Components/Task/styles/TaskDetailCard.module.css";

const TaskDetailCard = (props) => {
  const { task, userName } = props;

  const [hasNewComments, setHasNewComments] = useState(false);
  const [showComments, setShowComments] = useState(false);

  const {
    comments,
    loadComments,
    loadingComments,
    setComments,
    saveComment,
    savingComment,
  } = useTaskComments();

  useEffect(() => {
    if (task) {
      setHasNewComments(task.hasNewCoachComments);
    }
  }, [task]);

  if (!task) {
    return (
      <StateCard
        imageUrl={assets.ZERO_STATE}
        imageWidth={240}
        title="No task selected, select a task from the list."
      />
    );
  }

  const handleClickCommentsButton = async () => {
    if (hasNewComments) {
      setHasNewComments(false);
      props.onClick?.(task._id);
    }

    if (!showComments) {
      setComments([]);
      await loadComments(task?._id);
    }

    setShowComments(!showComments);
  };

  const handleAddedComment = async (commentValue) => {
    try {
      await saveComment(task._id, commentValue);
    } catch (error) {
      toast.error("Error! The comment was not added.");
    }
  };

  return (
    <Card className={`Card ${styles.TaskDetailCard}`}>
      <Card.Header className={styles.Header}>
        <div className={styles.Top}>
          <div className={styles.ImageTitleContainer}>
            <UserImage
              userName={task.metadata?.coachName}
              userImageUrl={task.metadata?.coachImageUrl}
              className={styles.CoachImage}
              large
            />
            <TaskDetailCardTitle
              title={task.title}
              type={task.type}
              lecture={task.lecture}
              event={task.event}
            />
          </div>
          {task.type === micoach.TaskTypeEnum.General && (
            <div className={styles.Actions}>
              <TaskStatusHandlerButton
                id={task._id}
                status={task.status}
                onUpdated={props.onUpdated}
              />
              <CommentsPopover
                comments={comments}
                isLoading={loadingComments}
                isSaving={savingComment}
                isEditable={task.status !== micoach.TaskStatusEnum.Done}
                setShowComments={setShowComments}
                onAddComment={handleAddedComment}
              >
                <CommentsButton
                  hasNewComments={hasNewComments}
                  tooltipMessage="Task interaction available"
                  onClick={handleClickCommentsButton}
                />
              </CommentsPopover>
            </div>
          )}
        </div>

        <div className={styles.Date}>
          <span>{`Created on ${getFormattedLocalDate(task.createdAt)}`}</span>
        </div>
      </Card.Header>
      <Card.Body>
        <section className={styles.TaskGreetingContainer}>
          <p>Hi {userName},</p>
          <p>{`Your coach ${task.metadata?.coachName} has assigned you the following task:`}</p>
        </section>
        <TaskDetailCardDescription
          type={task.type}
          lecture={task.lecture}
          event={task.event}
          title={task.title}
          description={task.description}
          dueDate={task.dueDate}
        />
      </Card.Body>
    </Card>
  );
};

TaskDetailCard.propTypes = {
  task: PropTypes.object,
  userName: PropTypes.string,
  onClick: PropTypes.func,
  onUpdated: PropTypes.func,
};

export default TaskDetailCard;

import PropTypes from "prop-types";

import { getCategorizedSkills } from "utils.js";

import styles from "Components/Job/Common/styles/JobBody.module.css";

const JobBody = (props) => {
  const { job } = props;

  if (!job) {
    return null;
  }

  const { hardSkills, softSkills, techSkills } = getCategorizedSkills(
    job.skills
  );

  return (
    <div className={styles.JobBody}>
      {job.about && (
        <>
          <h2>About the job</h2>
          <p
            dangerouslySetInnerHTML={{
              __html: job.about,
            }}
          />
        </>
      )}
      {job.responsibilities && (
        <>
          <h2>What will you do?</h2>
          <p
            dangerouslySetInnerHTML={{
              __html: job.responsibilities,
            }}
          />
        </>
      )}
      {job.qualifications && (
        <>
          <h2>Qualifications</h2>
          <p
            dangerouslySetInnerHTML={{
              __html: job.qualifications,
            }}
          />
        </>
      )}
      {job.role && (
        <>
          <h2>Related Role</h2>
          <p>{job.role}</p>
        </>
      )}
      {job.skills?.length > 0 && (
        <>
          <h2>Skills</h2>
          {hardSkills.length > 0 && (
            <>
              <h3>Hard Skills</h3>
              <ul>
                {hardSkills.map((skill) => (
                  <li key={skill.name}>{skill.name}</li>
                ))}
              </ul>
            </>
          )}
          {softSkills.length > 0 && (
            <>
              <h3>Soft Skills</h3>
              <ul>
                {softSkills.map((skill) => (
                  <li key={skill.name}>{skill.name}</li>
                ))}
              </ul>
            </>
          )}
          {techSkills.length > 0 && (
            <>
              <h3>Technical Skills</h3>
              <ul>
                {techSkills.map((skill) => (
                  <li key={skill.name}>{skill.name}</li>
                ))}
              </ul>
            </>
          )}
        </>
      )}
    </div>
  );
};

JobBody.propTypes = {
  job: PropTypes.shape({
    about: PropTypes.string,
    responsibilities: PropTypes.string,
    qualifications: PropTypes.string,
    role: PropTypes.string,
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
      })
    ),
  }),
};

export default JobBody;

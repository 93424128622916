import PropTypes from "prop-types";
import Badge from "react-bootstrap/Badge";

import { kanbanColumns } from "constants.js";
import { getApplicationProcessStepName } from "utils.js";

import "Components/styles/StepBadge.css";

const StepBadge = (props) => {
  const { step, onClick } = props;

  return (
    <Badge
      pill
      className={`StepBadge ${step} ${
        step === kanbanColumns.TECHNICAL_INTERVIEW.key &&
        typeof onClick === "function"
          ? "Clickable"
          : ""
      }`}
      onClick={onClick}
    >
      {getApplicationProcessStepName(step)}
    </Badge>
  );
};

StepBadge.propTypes = {
  step: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default StepBadge;

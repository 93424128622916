import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import { faBriefcase, faUserTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApplicationProcessApi } from "micoach-api";

import ApplicationAddModal from "Components/ApplicationAddModal";
import DiscardModal from "Components/DiscardModal";
import StepAccordion from "Components/StepAccordion";
import {
  applicationProcessStatus,
  kanbanColumns,
  PROCESSES,
} from "constants.js";

import "Components/styles/HiringProcess.css";

function HiringProcess(props) {
  const { applicationProcess, candidate, isEditable, isRejectable } = props;

  const { keycloak } = useKeycloak();
  const userId = keycloak.subject;

  const [showApplicationAddModal, setShowApplicationModal] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [isDiscarding, setIsDiscarding] = useState(false);

  const handleClickDiscard = () => {
    setShowDiscardModal(true);
  };

  const handleSubmitDiscard = async (formValues) => {
    try {
      setIsDiscarding(true);

      await ApplicationProcessApi.cancelApplicationProcess(
        applicationProcess._id,
        {
          discardReason: formValues.discardReason,
          emailTemplateId: formValues.emailTemplateId,
        }
      );

      setShowDiscardModal(false);

      if (props.onDiscarded) {
        props.onDiscarded();
      }

      toast.success("Success! The candidate has been rejected.");
    } catch (error) {
      console.error(error);
      toast.error("Error. Unable to reject candidate, try again.");
    } finally {
      setIsDiscarding(false);
    }
  };

  const handleSaveApplication = () => {
    toast.success("Success! The hiring process has started.");
    setShowApplicationModal(false);
    if (props.onSaved) {
      props.onSaved();
    }
  };

  const handleSaveApplicationError = (message) => {
    toast.error(message);
    setShowApplicationModal(false);
  };

  return (
    <>
      <ApplicationAddModal
        show={showApplicationAddModal}
        candidate={candidate}
        onSave={handleSaveApplication}
        onError={handleSaveApplicationError}
        onClose={() => setShowApplicationModal(false)}
      />

      <DiscardModal
        show={showDiscardModal}
        process={PROCESSES.APPLICATION}
        candidateName={candidate?.name}
        candidateStatus={candidate?.status}
        destinationColumn={kanbanColumns.LEADS.key}
        isLoading={isDiscarding}
        onSubmit={handleSubmitDiscard}
        onClose={() => setShowDiscardModal(false)}
      />

      <div className="HiringProcess">
        {applicationProcess?.status !== applicationProcessStatus.active && (
          <div>
            <Button
              variant="link"
              className="StartProcessButton"
              onClick={() => setShowApplicationModal(true)}
            >
              <FontAwesomeIcon icon={faBriefcase} className="me-2" />
              Start hiring process
            </Button>
          </div>
        )}

        {applicationProcess?.status === applicationProcessStatus.active && (
          <div className="Header">
            <h2>Hiring process</h2>
            {applicationProcess.recruiter === userId && isRejectable && (
              <Button
                variant="link"
                className="DiscardButton"
                onClick={handleClickDiscard}
              >
                Reject
                <FontAwesomeIcon
                  icon={faUserTimes}
                  className="DiscardIcon ms-2"
                />
              </Button>
            )}
          </div>
        )}

        {applicationProcess?.status === applicationProcessStatus.active && (
          <ListGroup variant="flush">
            {applicationProcess?.steps.map((step) => (
              <StepAccordion
                key={step.step}
                applicationProcessId={applicationProcess._id}
                step={step}
                isEditable={isEditable}
              />
            ))}
          </ListGroup>
        )}
      </div>
    </>
  );
}

HiringProcess.propTypes = {
  applicationProcess: PropTypes.object,
  candidate: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  isRejectable: PropTypes.bool,
  onSaved: PropTypes.func,
  onDiscarded: PropTypes.func,
};

export default HiringProcess;

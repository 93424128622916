import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import RoleFiltersForm from "Components/Admin/Roles/RoleFiltersForm";
import RoleModal from "Components/Admin/Roles/RoleModal";
import RoleTable from "Components/Admin/Roles/RoleTable";
import StateCard from "Components/Common/StateCard";
import GoBackButton from "Components/GoBackButton";
import { useRoles } from "Hooks/useRoles";
import { assets } from "constants.js";
import { sortByProperty } from "utils.js";

const Roles = () => {
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [resetPagination, setResetPagination] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [role, setRole] = useState();

  const { roles, loadingRoles, setRoles } = useRoles();

  const getFilteredRoles = (roles, filters) => {
    let newRoles = [...roles];

    if (filters.hasSkills?.length) {
      const withSkills = filters.hasSkills.some(
        (hasSkills) => hasSkills === true
      );
      const withoutSkills = filters.hasSkills.some(
        (hasSkills) => hasSkills === false
      );

      if (withSkills && withoutSkills) return newRoles;

      if (withSkills) {
        newRoles = newRoles.filter((role) => role.skills?.length);
      } else if (withoutSkills) {
        newRoles = newRoles.filter((role) => !role.skills?.length);
      }
    }

    return newRoles;
  };

  const handleShowModal = () => {
    setIsEditing(false);
    setShowRoleModal(!showRoleModal);
  };

  const handleSavedRole = (newRole) => {
    const updatedRoles = [...roles, newRole];
    sortByProperty(updatedRoles, "name", "asc");
    setRoles(updatedRoles);
  };

  const handleUpdatedRole = (updatedRole) => {
    const rolesCopy = [...roles];
    const index = rolesCopy.findIndex((role) => role._id === updatedRole._id);
    rolesCopy[index] = { ...updatedRole };

    sortByProperty(rolesCopy, "name", "asc");
    setRoles(rolesCopy);
  };

  const handleEditRole = (role) => {
    setRole({ ...role });
    setIsEditing(true);
    setShowRoleModal(true);
  };

  const handleSubmitFilters = (filters) => {
    setResetPagination(!resetPagination);
    setFilteredRoles(getFilteredRoles(roles, filters));
  };

  useEffect(() => {
    if (roles?.length) {
      setFilteredRoles(roles);
    }
  }, [roles]);

  if (!filteredRoles) {
    return <StateCard imageUrl={assets.ZERO_STATE} title="Roles not found!" />;
  }

  return (
    <div className="Roles">
      <GoBackButton label="Admin" path="/admin" />

      <Row>
        <Col>
          <h1 className="ScreenTitle">Roles</h1>
        </Col>
        <Col className="text-end">
          <Button
            variant="secondary"
            className="AddButton"
            onClick={handleShowModal}
          >
            Create Role
          </Button>
        </Col>
      </Row>

      <Row>
        <Col md={3}>
          <Card className="Card">
            <Card.Body>
              <Card.Title className="Title">Filters</Card.Title>
              <RoleFiltersForm onSubmit={handleSubmitFilters} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={9}>
          <Card className="Card">
            <Card.Body>
              <RoleTable
                roles={filteredRoles}
                loading={loadingRoles}
                resetPagination={resetPagination}
                onEdit={handleEditRole}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <RoleModal
        show={showRoleModal}
        role={role}
        isEditing={isEditing}
        onClose={handleShowModal}
        onSaved={handleSavedRole}
        onUpdated={handleUpdatedRole}
      />
    </div>
  );
};

export default Roles;

import { useCallback, useState } from "react";
import { TaskApi } from "micoach-api";

export const useTaskComments = () => {
  const [comments, setComments] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [savingComment, setSavingComment] = useState(false);

  const loadComments = useCallback(async (taskId) => {
    try {
      setLoadingComments(true);
      const { data } = await TaskApi.getComments(taskId);
      setComments(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingComments(false);
    }
  }, []);

  const saveComment = useCallback(async (taskId, comment) => {
    try {
      setSavingComment(true);

      const { data: newComment } = await TaskApi.createComment(taskId, {
        comment,
      });

      setComments((comments) => [...comments, newComment]);
    } catch (error) {
      console.error(error);
    } finally {
      setSavingComment(false);
    }
  }, []);

  return {
    comments,
    loadComments,
    loadingComments,
    setComments,
    saveComment,
    savingComment,
  };
};

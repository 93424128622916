import PropTypes from "prop-types";

import UserImage from "Components/UserImage";

import styles from "Components/Common/styles/UserImageDetails.module.css";

const UserImageDetails = (props) => {
  const { userImageUrl, userName, title, subtitle } = props;

  return (
    <div className={styles.UserImageDetails}>
      {(userImageUrl || userName) && (
        <UserImage userName={userName} userImageUrl={userImageUrl} large />
      )}
      <div className={styles.Container}>
        <div className={styles.Title}>{title}</div>
        <div className={styles.Subtitle}>{subtitle}</div>
      </div>
    </div>
  );
};

UserImageDetails.propTypes = {
  userImageUrl: PropTypes.string,
  userName: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default UserImageDetails;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import { faCompressAlt, faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SkillCategoryEnum } from "@micoach/js-sdk";
import { SkillApi } from "micoach-api";

import CustomSelect from "Components/CustomSelect";
import { DUPLICATED } from "error-constants.js";
import { getObjectKeyByValue } from "utils.js";

const SkillModal = (props) => {
  const { show, skill, isEditing } = props;

  const [fullScreen, setFullScreen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [description, setDescription] = useState(null);
  const [proficiencyLevels, setProficiencyLevels] = useState([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    register,
  } = useForm({
    mode: "onChange",
  });

  const getCategoryOptions = () => {
    return Object.keys(SkillCategoryEnum).map((label) => {
      return {
        value: SkillCategoryEnum[label],
        label,
      };
    });
  };

  const getCategoryDefaultValue = (skill) => {
    return skill?.category
      ? {
          value: skill.category,
          label: getObjectKeyByValue(SkillCategoryEnum, skill.category),
        }
      : null;
  };

  const handleChangeDescription = (text, _delta, _source, editor) => {
    setDescription(editor.getLength() > 1 ? text : null);
  };

  const handleChangeProficiencyLevel = (text, editor, index) => {
    setProficiencyLevels((prevProficiencyLevels) => {
      const newProficiencyLevels = [...prevProficiencyLevels];
      newProficiencyLevels[index].description =
        editor.getLength() > 1 ? text : null;
      return newProficiencyLevels;
    });
  };

  const onSubmit = async (formValues) => {
    setIsSaving(true);

    let skillData = {
      ...formValues,
      description,
      proficiencyLevels,
    };

    try {
      if (isEditing) {
        const { data } = await SkillApi.updateSkill(skill._id, skillData);
        skillData = data;
      } else {
        const { data } = await SkillApi.createSkill(skillData);
        skillData = data;
      }

      props.onSaved?.(skillData);
      toast.success(
        `Success! The skill has been ${isEditing ? "updated" : "created"}.`
      );
      props.onClose?.();
    } catch (error) {
      const { status, data } = error.response;

      if (status === 409 && data.error === DUPLICATED) {
        toast.info(`Error, skill "${formValues.name}" is already been added.`);
      } else {
        toast.error("Error, please try again.");
      }
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (show && skill) {
      reset({
        name: skill.name ?? "",
        category: skill.category ?? "",
        description: skill.description ?? "",
      });
      setDescription(skill.description ?? "");
      setProficiencyLevels(skill.proficiencyLevels ?? []);
    }
  }, [show, skill, reset]);

  return (
    <Modal
      show={show}
      onHide={props.onClose}
      contentClassName={fullScreen && "FullScreen"}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? "Edit" : "Create"} Skill</Modal.Title>
          <button
            type="button"
            className="ActionButton"
            onClick={() => setFullScreen(!fullScreen)}
          >
            <FontAwesomeIcon icon={!fullScreen ? faExpandAlt : faCompressAlt} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="category" className="form-group required">
            <Form.Label>Category</Form.Label>
            <CustomSelect
              name="category"
              control={control}
              options={getCategoryOptions()}
              defaultValue={getCategoryDefaultValue(skill)}
              isInvalid={!!errors.category}
              isRequired
            />
            {errors.category && (
              <div className="invalid-feedback d-block">
                Skill category is required
              </div>
            )}
          </Form.Group>

          <Form.Group controlId="name" className="form-group required">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              {...register("name", { required: true })}
              isInvalid={!!errors.name}
            />
            {errors.name && (
              <div className="invalid-feedback d-block">
                {errors.name.type === "required" && "Skill name is required"}
              </div>
            )}
          </Form.Group>

          <Form.Group controlId="description" className="form-group">
            <Form.Label>Description</Form.Label>
            <ReactQuill
              value={description}
              onChange={handleChangeDescription}
            />
          </Form.Group>

          {skill?.proficiencyLevels?.map?.((proficiencyLevel, index) => (
            <Form.Group
              controlId="proficiencyLevel"
              key={proficiencyLevel.level}
              className="form-group"
            >
              <Form.Label>
                {`Proficiency level ${proficiencyLevel.level} - ${proficiencyLevel.levelText}`}
              </Form.Label>
              <ReactQuill
                value={proficiencyLevels[index]?.description}
                onChange={(text, _delta, _source, editor) =>
                  handleChangeProficiencyLevel(text, editor, index)
                }
              />
            </Form.Group>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="CancelButton"
            onClick={props.onClose}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button type="submit" className="SaveButton" disabled={isSaving}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

SkillModal.propTypes = {
  show: PropTypes.bool,
  isEditing: PropTypes.bool,
  skill: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
    proficiencyLevels: PropTypes.array,
  }),
  onClose: PropTypes.func,
  onSaved: PropTypes.func,
};

SkillModal.defaultValues = {
  show: false,
  isEditing: false,
};

export default SkillModal;

import { useState } from "react";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReferralStatusEnum } from "@micoach/js-sdk";

import styles from "Components/Refer/styles/ReferallActionsButton.module.css";

const ReferralActionsButton = (props) => {
  const { status, isSaving, onChange } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const REFERRAL_ACTIONS = {
    DISMISSED: { value: ReferralStatusEnum.Dismissed, label: "Dismissed" },
    REVIEWING: { value: ReferralStatusEnum.Reviewing, label: "Pending" },
    PENDING_PAYMENT: {
      value: ReferralStatusEnum.PendingPayment,
      label: "Pending payment",
    },
    CLAIMED: { value: ReferralStatusEnum.Claimed, label: "Paid" },
  };

  const getActionOptionsByStatus = (status) => {
    switch (status) {
      case ReferralStatusEnum.Referred:
      case ReferralStatusEnum.InHiringProcess:
      case ReferralStatusEnum.Claimed:
      case ReferralStatusEnum.Dismissed:
        return [];
      case ReferralStatusEnum.Reviewing:
        return [REFERRAL_ACTIONS.DISMISSED, REFERRAL_ACTIONS.PENDING_PAYMENT];
      case ReferralStatusEnum.PendingPayment:
        return [REFERRAL_ACTIONS.DISMISSED, REFERRAL_ACTIONS.CLAIMED];
      default:
        return [REFERRAL_ACTIONS.DISMISSED];
    }
  };

  const getActionButtonClassNameByStatus = (status) => {
    switch (status) {
      case ReferralStatusEnum.Claimed:
        return styles.Claimed;
      case ReferralStatusEnum.Reviewing:
        return styles.Reviewing;
      case ReferralStatusEnum.PendingPayment:
        return styles.PendingPayment;
      default:
        return styles.Dismissed;
    }
  };

  const isDisabled =
    isSaving ||
    status === ReferralStatusEnum.Referred ||
    status === ReferralStatusEnum.InHiringProcess ||
    status === ReferralStatusEnum.Dismissed ||
    status === ReferralStatusEnum.Claimed;

  return (
    <DropdownButton
      title={
        <span>
          {REFERRAL_ACTIONS[status]?.label ?? "-"}
          {isMenuOpen ? (
            <FontAwesomeIcon icon={faChevronUp} className={styles.Icon} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} className={styles.Icon} />
          )}
        </span>
      }
      variant="success"
      disabled={isDisabled}
      className={`${
        styles.ReferallActionsButton
      } ${getActionButtonClassNameByStatus(status)}`}
      onToggle={() => setIsMenuOpen(!isMenuOpen)}
    >
      {getActionOptionsByStatus(status).map((option) => {
        return (
          <Dropdown.Item
            key={option.value}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
};

ReferralActionsButton.propTypes = {
  status: PropTypes.string,
  isSaving: PropTypes.bool,
  onChange: PropTypes.func,
};

ReferralActionsButton.defaultProps = {
  isSaving: false,
};

export default ReferralActionsButton;

import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";

import UserImageDetails from "Components/Common/UserImageDetails";
import { EVENT_TYPES } from "constants.js";

import styles from "Components/Training/styles/TrainingCard.module.css";

const TrainingCard = (props) => {
  const { event } = props;

  if (!event) {
    return null;
  }

  const hasMultipleLectures = event.lectures?.length > 1;
  const speaker = event.speakers?.[0];
  const path = hasMultipleLectures
    ? `/trainings/${event.slug}/lectures`
    : `/trainings/${event.slug}/lectures/${event.lectures[0]?._id}`;
  const coverImageUrl = event.thumbnailImageUrl ?? event.coverImageUrl;

  const getTypeLabelFromKey = (key) => {
    const eventType = Object.values(EVENT_TYPES).find(
      (eventItem) => eventItem.key === key
    );

    return eventType ? eventType.label : null;
  };

  return (
    <Card className={`Card ${styles.TrainingCard}`}>
      {coverImageUrl && (
        <a href={path}>
          <Card.Img variant="top" src={coverImageUrl} />
        </a>
      )}
      <Card.Body>
        <a href={path}>
          <UserImageDetails
            userImageUrl={speaker?.imageUrl}
            userName={speaker?.name}
            title={event.name}
            subtitle={speaker?.name}
          />
        </a>
        <div className={styles.Footer}>
          <div className={styles.Tags}>
            <div>{getTypeLabelFromKey(event.type)}</div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

TrainingCard.propTypes = {
  event: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    lectures: PropTypes.array.isRequired,
    speakers: PropTypes.array.isRequired,
    coverImageUrl: PropTypes.string.isRequired,
    thumbnailImageUrl: PropTypes.string,
    startDate: PropTypes.string.isRequired,
    points: PropTypes.number.isRequired,
    completedByUser: PropTypes.bool.isRequired,
  }),
};

export default TrainingCard;

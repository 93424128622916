import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import CoacheeList from "Components/Candidate/CoacheeList";
import StateCard from "Components/Common/StateCard";
import PeriodSelect from "Components/Evaluation/Skill/PeriodSelect";
import Loading from "Components/Loading";
import { useCoachees } from "Hooks/useUsers";
import { assets } from "constants.js";
import { getQuarterYear } from "utils.js";

const ListCoachees = () => {
  const balanceDate = {
    year: new Date().getFullYear(),
    quarter: getQuarterYear(),
  };

  const { keycloak } = useKeycloak();
  const [selectedPeriod, setSelectedPeriod] = useState(balanceDate);
  const { loadingCoachees, coachees } = useCoachees(
    keycloak?.subject,
    selectedPeriod
  );

  const handleChangePeriod = (option) => {
    const {
      value: { year, quarter },
    } = option;
    setSelectedPeriod({ year, quarter });
  };

  return (
    <div className="ListCoachees">
      <div className="d-flex justify-content-between align-items-end mb-3">
        <h1 className="ScreenTitle">My coachees</h1>
        <Link to="evaluation-period" className="btn btn-primary">
          Evaluation progress
        </Link>
      </div>
      <Row>
        <Col sm={3}>
          <Form.Group controlId="PeriodSelect" className="form-group">
            <Form.Label>Period</Form.Label>
            <PeriodSelect
              balanceDate={balanceDate}
              onChange={handleChangePeriod}
            />
          </Form.Group>
        </Col>
      </Row>

      {loadingCoachees ? (
        <Loading />
      ) : coachees.length ? (
        <CoacheeList balanceDate={selectedPeriod} coachees={coachees} />
      ) : (
        <StateCard
          imageUrl={assets.ZERO_STATE}
          title="You don't have coachees."
        />
      )}
    </div>
  );
};

export default ListCoachees;

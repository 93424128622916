import { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { toast } from "react-toastify";
import micoach, { EmailApi } from "micoach-api";

import EmailTemplateCard from "Components/Admin/EmailTemplateCard";
import EmailTemplateModal from "Components/Admin/EmailTemplateModal";
import ConfirmModal from "Components/ConfirmModal";
import GoBackButton from "Components/GoBackButton";
import Loading from "Components/Loading";
import { useEmailTemplates } from "Hooks/useEmailTemplates";

const EmailTemplates = () => {
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingEmailTemplate, setDeletingEmailTemplate] = useState(false);

  const { emailTemplates, loadingEmailTemplates, setEmailTemplates } =
    useEmailTemplates(micoach.EmailTypeEnum.FirstContact);

  const handleUpdatedTemplate = (updatedTemplate) => {
    const updatedEmailTemplates = emailTemplates.map((template) => {
      if (template._id === updatedTemplate._id) {
        return { ...updatedTemplate };
      } else {
        return template;
      }
    });
    setEmailTemplates(updatedEmailTemplates);
  };

  const handleCreatedTemplate = (createdTemplate) => {
    const newEmailTemplates = [createdTemplate, ...emailTemplates].sort(
      (templateA, templateB) => {
        const compareCompany = templateA.metadata?.company.localeCompare(
          templateB.metadata?.company
        );
        const compareRole = templateA.metadata?.role.localeCompare(
          templateB.metadata?.role
        );

        return compareCompany || compareRole;
      }
    );
    setEmailTemplates(newEmailTemplates);
  };

  const handleEditTemplate = (template) => {
    setEmailTemplate(template);
    setIsEditing(true);
    setShowTemplateModal(true);
  };

  const handleCloneTemplate = (template) => {
    setEmailTemplate(template);
    setIsEditing(false);
    setShowTemplateModal(true);
  };

  const handleDeleteTemplate = (template) => {
    setEmailTemplate(template);
    setShowDeleteModal(true);
  };

  const handleConfirmDeleteTemplate = async () => {
    setDeletingEmailTemplate(true);
    try {
      const newItems = [...emailTemplates];
      const emailTemplateId = emailTemplate?._id;
      const emailTemplateIndex = emailTemplates?.findIndex(
        (template) => template._id === emailTemplateId
      );

      await EmailApi.deleteEmailTemplate(emailTemplateId);
      newItems.splice(emailTemplateIndex, 1);
      setEmailTemplates(newItems);
      toast.success("Success! The email template has been deleted.");
    } catch (error) {
      toast.error("Error! Unable to delete the email template, try again.");
    } finally {
      setDeletingEmailTemplate(false);
      setShowDeleteModal(false);
    }
  };

  return (
    <div className="EmailTemplates">
      <GoBackButton label="Admin" path="/admin" />

      <Row>
        <Col>
          <h1 className="ScreenTitle">Email templates</h1>
        </Col>
      </Row>
      {loadingEmailTemplates ? (
        <Loading />
      ) : (
        <Row>
          {emailTemplates.map((template) => (
            <EmailTemplateCard
              key={template._id}
              template={template}
              onEdit={handleEditTemplate}
              onClone={handleCloneTemplate}
              onDelete={handleDeleteTemplate}
            />
          ))}
        </Row>
      )}

      <EmailTemplateModal
        show={showTemplateModal}
        emailTemplate={emailTemplate}
        emailTemplates={emailTemplates}
        isEditing={isEditing}
        onClose={() => setShowTemplateModal(false)}
        onUpdated={handleUpdatedTemplate}
        onCreated={handleCreatedTemplate}
      />

      <ConfirmModal
        show={showDeleteModal}
        title="Delete email template"
        confirmButtonText="Yes, delete"
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDeleteTemplate}
        isLoading={deletingEmailTemplate}
      >
        <div>
          Are you sure you want to delete this email template?
          <div className="mt-3"> This action cannot be undone.</div>
        </div>
      </ConfirmModal>
    </div>
  );
};

export default EmailTemplates;

import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "Components/Common/styles/EllipsisMenu.module.css";

const EllipsisMenu = (props) => {
  const { options = [], onSelectedOption, disabledOptions = [] } = props;

  const disabledOptionsSet = new Set(disabledOptions);

  return (
    <div className={styles.EllipsisMenu}>
      <DropdownButton
        variant="link"
        title={
          <FontAwesomeIcon
            data-testid="ellipsis-menu"
            icon={faEllipsisH}
            className={styles.EllipsisMenuIcon}
          />
        }
        onSelect={onSelectedOption}
      >
        {options.map((option) => {
          return (
            <Dropdown.Item
              as="button"
              data-testid="ellipsis-option"
              key={option.name}
              eventKey={option.key}
              className={styles.MenuItem}
              disabled={disabledOptionsSet.has(option.key)}
            >
              <FontAwesomeIcon
                data-testid="option-icon"
                icon={option.icon}
                className={option.iconClassName}
              />
              {option.name}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
    </div>
  );
};

EllipsisMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.object.isRequired,
      iconClassName: PropTypes.string,
      name: PropTypes.string.isRequired,
      eventKey: PropTypes.string,
    })
  ),
  disabledOptions: PropTypes.array,
  onSelectedOption: PropTypes.func,
};

export default EllipsisMenu;

import { useEffect } from "react";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import Address from "Components/Address";
import BusinessEmailLabel from "Components/Common/BusinessEmailLabel";
import PhoneNumberInput from "Components/PhoneNumberInput";
import StepButton from "Components/ProfileBuilder/StepButton";
import { useUpdateProfile } from "Hooks/useUsers";
import { EMAIL_PATTERN } from "constants.js";

import styles from "Components/ProfileBuilder/styles/ContactInfoStep.module.css";

const ContactInfoStep = (props) => {
  const { userEmail, onSaved } = props;

  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });
  const formProps = { register, errors, control, watch, setValue };

  const { updateProfile, isUpdating, isSuccess, status, errorMessage } =
    useUpdateProfile();

  useEffect(() => {
    if (isSuccess) {
      onSaved?.();
    } else {
      if (status && errorMessage) {
        const toastType = status >= 500 ? "error" : "info";
        toast[toastType](errorMessage);
      }
    }
  }, [isSuccess, onSaved, status, errorMessage]);

  const onSubmit = async (formValues) => {
    const updatedProfile = {
      secondaryEmail: formValues.secondaryEmail,
      phone: formValues.phone ?? "",
      callingCode: "",
      address: {
        city: formValues.city ?? "",
        state: formValues.state?.value,
        country: formValues.country?.value,
      },
    };

    if (formValues.phone) {
      updatedProfile.callingCode = formValues.callingCode;
    }

    await updateProfile(updatedProfile);
  };

  return (
    <>
      <span className={styles.EmailLabel}>Your Personal Email</span>
      <span className={styles.Email}>{userEmail}</span>
      <BusinessEmailLabel />
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Row>
          <Col md={12} className={styles.SecondaryEmail}>
            <Form.Group controlId="secondaryEmail" className="form-group">
              <Form.Label>Business Email</Form.Label>
              <Form.Control
                type="email"
                name="secondaryEmail"
                isInvalid={!!errors.secondaryEmail}
                disabled={isUpdating}
                label="Business-email"
                {...register("secondaryEmail", { pattern: EMAIL_PATTERN })}
              />
              {errors.secondaryEmail && (
                <div className="invalid-feedback d-block">
                  {errors.secondaryEmail.type === "pattern" &&
                    "Business email must be a valid email"}
                </div>
              )}
            </Form.Group>
          </Col>
          <Form.Group as={Col} className={`form-group ${styles.PhoneNumber}`}>
            <PhoneNumberInput formProps={formProps} disabled={isUpdating} />
          </Form.Group>
        </Row>
        <Address
          requireContry
          requireCity
          profileBuilderFormat
          isEditing={true}
          formProps={formProps}
          disabled={isUpdating}
        />
        <StepButton label="Continue" type="submit" disabled={isUpdating} />
      </Form>
    </>
  );
};

ContactInfoStep.propTypes = {
  userEmail: PropTypes.string,
  onSaved: PropTypes.func,
};

ContactInfoStep.defaultProps = {
  userEmail: "",
};

export default ContactInfoStep;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SkillControl from "Components/Candidate/Skills/SkillControl";
import { usePublicSkills } from "Hooks/useSkills";
import { useSuggestedSkills } from "Hooks/useSuggestions";
import BackendService from "Services/BackendService";
import { skillCategories } from "constants.js";
import { filterSkills } from "utils.js";

const SkillsCard = (props) => {
  const [selectedSkills, setSelectedSkills] = useState(props.skills);
  const [prevSelectedSkills, setPrevSelectedSkills] = useState([]);
  const [prevSuggestedSkills, setPrevSuggestedSkills] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { skills } = usePublicSkills();
  const { suggestedSkills, setSuggestedSkills, loadSuggestedSkills } =
    useSuggestedSkills();
  const { handleSubmit, control, setValue } = useForm();

  // Form handlers
  const handleClickEdit = () => {
    setPrevSelectedSkills([...selectedSkills]);
    setPrevSuggestedSkills([...suggestedSkills]);
    setIsEditing(true);
  };

  const handleClickCancel = () => {
    setSelectedSkills([...prevSelectedSkills]);
    setSuggestedSkills([...prevSuggestedSkills]);
    setIsEditing(false);
  };

  const onSubmit = async (formValues) => {
    setIsSaving(true);
    let combinedSkills = [];

    for (const category of Object.keys(skillCategories)) {
      combinedSkills = [...combinedSkills, ...formValues[category]];
    }

    try {
      const data = await BackendService.updateProfile({
        skills: combinedSkills,
      });

      props.onSaved?.(data);
    } catch (error) {
      toast.error("Error, please try again.");
    } finally {
      setIsSaving(false);
      setIsEditing(false);
    }
  };

  // SkillControl handlers

  const handleSelectChange = (category, skills) => {
    // Update selected skill
    const newSelectedSkills = [
      ...selectedSkills.filter((skill) => skill.category !== category),
      ...skills,
    ];

    setSelectedSkills(newSelectedSkills);
    setValue(category, filterSkills(newSelectedSkills, category));
  };

  const handleAddSuggestion = (skill) => {
    // Update suggested skills
    setSuggestedSkills((prevSuggestedSkills) =>
      prevSuggestedSkills.filter(
        (suggestedSkill) => suggestedSkill._id !== skill._id
      )
    );

    // Update selected skills
    if (
      !selectedSkills.some(
        (selectedSkill) =>
          selectedSkill.category === skill.category &&
          selectedSkill.name === skill.name
      )
    ) {
      const newSelectedSkills = [
        ...selectedSkills,
        { name: skill.name, category: skill.category },
      ];
      setSelectedSkills(newSelectedSkills);
      setValue(skill.category, filterSkills(newSelectedSkills, skill.category));
    }
  };

  const handleSkillValueChange = (skill) => {
    const updatedSkillsValues = selectedSkills.map((selectedSkill) => {
      if (
        selectedSkill.category === skill.category &&
        skill.name === selectedSkill.name
      ) {
        selectedSkill.value = skill.value;
      }

      return selectedSkill;
    });

    setValue(skill.category, filterSkills(updatedSkillsValues, skill.category));
  };

  const handleRemoveSkill = (skill) => {
    const newSelectedSkills = selectedSkills.filter((selectedSkill) => {
      return selectedSkill.name !== skill.name;
    });

    setSelectedSkills(newSelectedSkills);
    setValue(skill.category, filterSkills(newSelectedSkills, skill.category));
  };

  useEffect(() => {
    loadSuggestedSkills();
  }, [props.skills, loadSuggestedSkills]);

  return (
    <Card className="SkillsCard Card">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card.Body>
          <Card.Title className="Title">Skills</Card.Title>
          <Card.Text className="Text">
            Providing details about your skills as professional and industry
            knowledge, allows us to offer you more personalized information.
          </Card.Text>

          {Object.keys(skillCategories).map((category) => (
            <SkillControl
              key={category}
              control={control}
              category={category}
              selectOptions={filterSkills(skills, category)}
              defaultValues={filterSkills(selectedSkills, category)}
              suggestedSkills={filterSkills(suggestedSkills, category)}
              isEditing={isEditing}
              onSelectChange={handleSelectChange}
              onAddSuggestion={handleAddSuggestion}
              onSelectValueChange={handleSkillValueChange}
              onSelectSkillRemove={handleRemoveSkill}
            />
          ))}
        </Card.Body>

        <Card.Footer>
          {isEditing ? (
            <>
              <div>
                <Button
                  className="Cancel"
                  variant="secondary"
                  disabled={isSaving}
                  onClick={handleClickCancel}
                >
                  Cancel
                </Button>
              </div>

              <Button
                type="submit"
                className="Save"
                variant="primary"
                disabled={isSaving}
              >
                Save
              </Button>
            </>
          ) : (
            <Button variant="link" className="Edit" onClick={handleClickEdit}>
              <FontAwesomeIcon icon={faPen} className="Icon" />
              Edit skills
            </Button>
          )}
        </Card.Footer>
      </Form>
    </Card>
  );
};

SkillsCard.propTypes = {
  skills: PropTypes.arrayOf(PropTypes.object),
  onSaved: PropTypes.func,
};

SkillsCard.defaultProps = {
  skills: [],
};

export default SkillsCard;

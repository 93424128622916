import { useCallback, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { UserRoleEnum } from "@micoach/js-sdk";
import { CandidateApi } from "micoach-api";
import { combineArraysWithoutDuplicates, removeElementInArray } from "utils";

import CandidateHistoricalTable from "Components/CandidateHistorical/CandidateHistoricalTable";
import SelectedFiltersV2 from "Components/Common/SelectedFiltersV2";
import ConfirmModal from "Components/ConfirmModal";
import GoBackButton from "Components/GoBackButton";
import HistoricalFilterButton from "Components/Historical/HistoricalFilterButton";
import SearchBar from "Components/SearchBar";
import { useCandidatesHistorical } from "Hooks/useCandidates";
import { useCompanies } from "Hooks/useCompanies";
import { useFilters, useSelectedFilters } from "Hooks/useFilters";
import { useRoles } from "Hooks/useRoles";
import { useTags } from "Hooks/useTags";
import { useUsersByRole } from "Hooks/useUsers";

import styles from "Screens/CandidateHistorical/styles/ListCandidateHistorical.module.css";

const DEFAULT_QUERY_PARAMS = {
  limit: 30,
  page: 1,
  sort: "name:asc",
  search: "",
  filters: {},
};

const ListCandidateHistorical = () => {
  const [query, setQuery] = useState({
    search: DEFAULT_QUERY_PARAMS.search,
    currentPage: DEFAULT_QUERY_PARAMS.page,
    sort: DEFAULT_QUERY_PARAMS.sort,
    filters: DEFAULT_QUERY_PARAMS.filters,
  });
  const [showConfirmReactivateCandidate, setShowConfirmReactivateCandidate] =
    useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedCandidateId, setSetselectedCandidateId] = useState(null);

  const { filters, setFilters, removeFilter } = useFilters();
  const { selectedFilters } = useSelectedFilters(filters);
  const { users: recruiters } = useUsersByRole({
    role: UserRoleEnum.Recruiter,
  });
  const { users: externalRecruiters } = useUsersByRole({
    role: UserRoleEnum.ExternalRecruiter,
  });

  const { roles } = useRoles();
  const [companies] = useCompanies();
  const { tags } = useTags();

  const {
    loadingCandidatesHistorical,
    candidatesHistorical,
    loadCandidatesHistorical,
    totalCandidatesHistorical,
    setCandidatesHistorical,
    setTotalCandidatesHistorical,
  } = useCandidatesHistorical();

  const getQuery = useCallback((data) => {
    const query = {
      limit: DEFAULT_QUERY_PARAMS.limit,
      sort: DEFAULT_QUERY_PARAMS.sort,
      page: DEFAULT_QUERY_PARAMS.page,
    };

    data.search && (query.search = data.search);
    data.currentPage && (query.page = data.currentPage);
    data.currentSort && (query.sort = data.currentSort);
    data.role && (query.role = [data.role.value]);
    data.company && (query.company = [data.company.value]);
    data.recruiter && (query.recruiter = [data.recruiter.value]);
    data.status && (query.status = [data.status.value]);
    data.tags?.length && (query.tags = data.tags.map((tag) => tag.value));

    return query;
  }, []);

  const handleSearch = ({ search }) => {
    setQuery((prev) => ({
      ...prev,
      search,
      currentPage: DEFAULT_QUERY_PARAMS.page,
    }));
  };

  const handleApplyFilters = (filters) => {
    setFilters(filters);

    setQuery((prev) => ({
      ...prev,
      role: filters?.role,
      company: filters?.company,
      status: filters?.status,
      recruiter: filters?.recruiter,
      tags: filters?.tags,
      currentPage: DEFAULT_QUERY_PARAMS.page,
    }));
  };

  const handleDeleteBadge = (option) => {
    const newFilters = removeFilter(option);

    setQuery((prev) => ({
      ...prev,
      role: newFilters?.role,
      company: newFilters?.company,
      status: newFilters?.status,
      recruiter: newFilters?.recruiter,
      currentPage: DEFAULT_QUERY_PARAMS.page,
    }));
  };

  const handleChangePage = (page) => {
    if (query.currentPage !== page) {
      setQuery((prev) => ({
        ...prev,
        currentPage: page,
      }));
    }
  };

  const handleChangeSort = async ({ columnName }, sortDirection) => {
    setQuery((prev) => ({
      ...prev,
      currentSort: `${columnName}:${sortDirection}`,
      currentPage: DEFAULT_QUERY_PARAMS.page,
    }));
  };

  const handleClickReactivate = (candidateId) => {
    setSetselectedCandidateId(candidateId);
    setShowConfirmReactivateCandidate(true);
  };

  const handleClickConfirmReactivate = async () => {
    try {
      setIsSaving(true);

      await CandidateApi.reactivateCandidate(selectedCandidateId);

      const updatedCandidatesHistorical = removeElementInArray(
        candidatesHistorical,
        (candidate) => candidate._id === selectedCandidateId
      );

      setCandidatesHistorical(updatedCandidatesHistorical);
      setTotalCandidatesHistorical(totalCandidatesHistorical - 1);
      setShowConfirmReactivateCandidate(false);

      toast.success("Success! The candidate has been reactivated.");
    } catch (error) {
      const status = error.response?.status;
      const errorMessage = error.response?.data?.errors?.[0]?.message;

      if ((status === 404 || status === 409) && errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error("Error, please try again.");
      }

      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    loadCandidatesHistorical(getQuery(query));
  }, [loadCandidatesHistorical, getQuery, query]);

  return (
    <>
      <GoBackButton path="/kanban" label="Kanban" />

      <SearchBar
        disabled={loadingCandidatesHistorical}
        onSearch={handleSearch}
      />

      <h1 className="ScreenTitle">Historical Candidates / Leads Information</h1>

      <div className={styles.Filters}>
        <div>
          <SelectedFiltersV2
            filterValues={selectedFilters}
            icon={faTimes}
            iconPosition="right"
            onClickDeleteBadge={handleDeleteBadge}
          />
        </div>

        <HistoricalFilterButton
          filterValues={filters}
          roles={roles}
          companies={companies}
          recruiters={combineArraysWithoutDuplicates(
            recruiters,
            externalRecruiters
          )}
          tags={tags}
          onApplyFilters={handleApplyFilters}
        />
      </div>

      <Card className="Card">
        <Card.Body>
          <CandidateHistoricalTable
            candidates={candidatesHistorical}
            currentPage={query.currentPage}
            totalCandidatesHistorical={totalCandidatesHistorical}
            loading={loadingCandidatesHistorical}
            paginationPerPage={DEFAULT_QUERY_PARAMS.limit}
            onChangePage={handleChangePage}
            onChangeSort={handleChangeSort}
            onClickReactivate={handleClickReactivate}
          />
        </Card.Body>
      </Card>

      <ConfirmModal
        title="Reactivate candidate"
        confirmButtonText="Reactivate"
        show={showConfirmReactivateCandidate}
        isLoading={isSaving}
        onClose={() => setShowConfirmReactivateCandidate(false)}
        onConfirm={handleClickConfirmReactivate}
      >
        <span>
          Are you sure you want to reactivate this candidate?
          <div className="mt-3"> This action cannot be undone.</div>
        </span>
      </ConfirmModal>
    </>
  );
};

export default ListCandidateHistorical;

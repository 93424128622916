import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

const PositionInterestsForm = (props) => {
  const { register, disabled } = props;

  return (
    <>
      <Card.Text className="Text">Employment Type</Card.Text>
      <Form.Check
        inline
        type="checkbox"
        id="fullTime"
        name="fullTime"
        label="Full-time"
        disabled={disabled}
        {...register("fullTime")}
      />
      <Form.Check
        inline
        type="checkbox"
        id="partTime"
        name="partTime"
        label="Part-time"
        disabled={disabled}
        {...register("partTime")}
      />
      <Form.Check
        inline
        type="checkbox"
        id="internship"
        name="internship"
        label="Internship"
        disabled={disabled}
        {...register("internship")}
      />

      <hr />

      <Card.Text className="Text">Work Model</Card.Text>
      <Form.Check
        inline
        type="checkbox"
        id="onSite"
        name="onSite"
        label="On-site"
        disabled={disabled}
        {...register("onSite")}
      />
      <Form.Check
        inline
        type="checkbox"
        id="remote"
        name="remote"
        label="Remote"
        disabled={disabled}
        {...register("remote")}
      />
      <Form.Check
        inline
        type="checkbox"
        id="hybrid"
        name="hybrid"
        label="Hybrid"
        disabled={disabled}
        {...register("hybrid")}
      />

      <hr />

      <Card.Text className="Text">Mobility</Card.Text>
      <Form.Check
        type="checkbox"
        id="isWillingToRelocate"
        name="isWillingToRelocate"
        label="Open to relocate"
        {...register("isWillingToRelocate")}
        disabled={disabled}
      />
      <Form.Check
        type="checkbox"
        id="isWillingToTravel"
        name="isWillingToTravel"
        label="Open to travel"
        {...register("isWillingToTravel")}
        disabled={disabled}
      />
    </>
  );
};

PositionInterestsForm.propTypes = {
  register: PropTypes.func,
  disabled: PropTypes.bool,
};

export default PositionInterestsForm;

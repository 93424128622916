import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { ReferralStatusEnum } from "@micoach/js-sdk";
import {
  getReferralStatusLabel,
  mapToSelectOptions,
  sortByProperty,
} from "utils";

import FilterButton from "Components/Common/FilterButton";

import styles from "Components/Common/styles/Filters.module.css";

const ReferralsFilterButton = (props) => {
  const { filterValues, positions, loading, onApplyFilters } = props;

  const [showPopover, setShowPopover] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState({});

  const orderedPositions = sortByProperty(positions, "title", "asc");

  const handleClickApply = (event) => {
    event.preventDefault();
    const newFilters = {};

    selectedStatus?.length && (newFilters.status = selectedStatus);
    selectedPosition && (newFilters.position = selectedPosition);

    setShowPopover(false);
    onApplyFilters?.(newFilters);
  };

  const handleClickReset = () => {
    setSelectedStatus(null);
    setSelectedPosition(null);
    setShowPopover(false);
    onApplyFilters?.({});
  };

  const handleClickClose = () => {
    setSelectedStatus(filterValues?.status ?? null);
    setSelectedPosition(filterValues?.position ?? null);
    setShowPopover(false);
  };

  useEffect(() => {
    setSelectedStatus(filterValues?.status);
    setSelectedPosition(filterValues?.position);
  }, [filterValues]);

  const enumToSelectReferralStatusOptions = (enumProperties) => {
    return Object.entries(enumProperties).map(([_label, value]) => ({
      label: getReferralStatusLabel(value, true),
      value,
    }));
  };

  return (
    <FilterButton
      show={showPopover}
      setShowPopover={setShowPopover}
      onClickReset={handleClickReset}
    >
      <Form onSubmit={handleClickApply}>
        <Form.Group controlId="status" className="form-group">
          <Form.Label>Status</Form.Label>
          <Select
            isClearable={false}
            isMulti
            name="status"
            classNamePrefix="ReactSelect"
            options={enumToSelectReferralStatusOptions(ReferralStatusEnum)}
            placeholder="Select..."
            defaultValue={filterValues?.status}
            onChange={(status) => {
              setSelectedStatus(status);
            }}
          />
        </Form.Group>
        <Form.Group controlId="position" className="form-group">
          <Form.Label>Job position</Form.Label>
          <Select
            name="postion"
            classNamePrefix="ReactSelect"
            options={mapToSelectOptions(orderedPositions, "_id", "title")}
            placeholder="Select..."
            defaultValue={filterValues?.position}
            onChange={(postion) => {
              setSelectedPosition(postion);
            }}
          />
        </Form.Group>
        <div className={styles.Buttons}>
          <Button variant="secondary" onClick={handleClickClose}>
            Close
          </Button>
          <Button disabled={loading} variant="primary" type="submit">
            Apply
          </Button>
        </div>
      </Form>
    </FilterButton>
  );
};

ReferralsFilterButton.propTypes = {
  filterValues: PropTypes.shape({
    status: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    position: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  }),
  positions: PropTypes.array,
  loading: PropTypes.bool,
  onApplyFilters: PropTypes.func,
};

ReferralsFilterButton.defaultProps = {
  positions: [],
  loading: false,
};

export default ReferralsFilterButton;

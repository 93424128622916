import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useKeycloak } from "@react-keycloak/web";
import { NotificationApi } from "micoach-api";

/**
 * The defaultValue is only used when a component does not have a matching Provider
 * above it in the tree. This default value can be helpful for testing components
 * in isolation without wrapping them.
 */
const defaultValue = {
  notifications: [],
  reloadNotifications: () => {},
  loadingNotifications: false,
};

/**
 * Creates a Context object. When React renders a component that subscribes to this Context
 * it will read the current context value from the closest matching Provider above it in the tree.
 */
export const NotificationsContext = createContext(defaultValue);

/**
 * Every Context object comes with a Provider React component that allows consuming
 * components to subscribe to context changes.
 */
const NotificationsContextProvider = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children } = props;

  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  const { keycloak, initialized } = useKeycloak();

  const loadNotifications = useCallback(
    async (read = false, source = undefined) => {
      if (initialized && keycloak.authenticated) {
        try {
          setLoading(true);
          const response = await NotificationApi.listNotifications(
            read,
            source
          );
          setNotifications(response.data);
        } finally {
          setLoading(false);
        }
      }
    },
    [initialized, keycloak.authenticated]
  );

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  return (
    <NotificationsContext.Provider
      value={useMemo(
        () => ({
          notifications,
          loadingNotifications: loading,
          reloadNotifications: loadNotifications,
        }),
        [notifications, loading, loadNotifications]
      )}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsContextProvider;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "Components/Candidate/styles/DocumentExampleModal.css";

function DocumentExampleModal(props) {
  const { show, documents, selectedDocument } = props;
  const [documentExample, setDocumentExample] = useState(null);
  const [showBackButton, setShowBackButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);

  useEffect(() => {
    if (documents.length) {
      setDocumentExample(documents[selectedDocument]);
      setShowBackButton(selectedDocument > 0);
      setShowNextButton(selectedDocument < documents.length - 1);
    }
  }, [documents, selectedDocument]);

  const carouselOptions = () => {
    const options = {};

    if (documentExample?.images?.length > 1) {
      options.prevIcon = <FontAwesomeIcon icon={faChevronLeft} size={"lg"} />;
      options.nextIcon = <FontAwesomeIcon icon={faChevronRight} size={"lg"} />;
      options.interval = 1500;
      options.slide = false;
    }

    return options;
  };

  const captionImage = (image) => {
    return image?.helpLink ? (
      <Button variant="link" href={image?.helpLink} target="_blank">
        {image?.name}
      </Button>
    ) : (
      image?.name
    );
  };

  const documentImage = (image) => {
    return (
      <div className="m-auto ImageDocumentExample">
        <Image
          className="d-block mx-auto img-fluid w-50"
          src={image.url}
          alt={image.name}
          title={image.name}
        />
      </div>
    );
  };

  if (!documentExample) return null;

  return (
    <Modal show={show} onHide={props.onClose} className="DocumentExampleModal">
      <Modal.Header closeButton>
        <Modal.Title>{documentExample?.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>{documentExample?.description}</div>
        <div className="mt-3">
          {documentExample?.images?.length > 1 ? (
            <Carousel {...carouselOptions()}>
              {documentExample?.images?.map((image) => {
                return (
                  <Carousel.Item key={image.name}>
                    {documentImage(image)}
                    <Carousel.Caption>
                      <p className="DocumentExampleName">
                        {captionImage(image)}
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          ) : (
            <>
              {documentImage(documentExample?.images[0])}
              <p className="text-center">
                {captionImage(documentExample?.images[0])}
              </p>
            </>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        {showBackButton && (
          <Button variant="secondary" onClick={props.setPreviousDocument}>
            Back
          </Button>
        )}
        {showNextButton && (
          <Button variant="primary" onClick={props.setNextDocument}>
            Next document
          </Button>
        )}
        {!showNextButton && (
          <Button variant="primary" onClick={props.onClose}>
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

DocumentExampleModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedDocument: PropTypes.number,
  setPreviousDocument: PropTypes.func.isRequired,
  setNextDocument: PropTypes.func.isRequired,
};

DocumentExampleModal.defaultProps = {
  selectedDocument: 0,
};

export default DocumentExampleModal;

import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useForm } from "react-hook-form";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CommentItem from "Components/Common/CommentItem";
import Loading from "Components/Loading";

import styles from "Components/Common/styles/CommentsHandler.module.css";

const CommentsHandler = (props) => {
  const { comments, commentsToShow, isSaving, isLoading, isEditable } = props;

  const [isShowingAllComments, setIsShowingAllComments] = useState(false);

  const { register, handleSubmit, reset } = useForm();

  const handleSubmitComment = async (formValues) => {
    props.onAdded?.(formValues.comment);
    reset();
  };

  const handleCommentsToShow = () => {
    return comments.length < commentsToShow || isShowingAllComments
      ? comments
      : comments.slice(-commentsToShow);
  };

  return (
    <>
      <div className={styles.CommentList}>
        {handleCommentsToShow().map((comment) => (
          <CommentItem key={comment._id} comment={comment} />
        ))}
      </div>

      {(isSaving || isLoading) && <Loading />}

      {comments.length > commentsToShow && (
        <Button
          variant="link"
          className={styles.CommentShowButton}
          onClick={() => setIsShowingAllComments(!isShowingAllComments)}
        >
          {isShowingAllComments
            ? "View less"
            : `View all ${comments.length} comments`}
        </Button>
      )}

      {isEditable && (
        <Form
          onSubmit={handleSubmit(handleSubmitComment)}
          className={styles.CommentForm}
        >
          <InputGroup>
            <InputGroup.Text className={styles.InputGroupIcon}>
              <FontAwesomeIcon icon={faCommentAlt} />
            </InputGroup.Text>
            <Form.Control
              className={styles.InputGroupText}
              type="text"
              name="comment"
              {...register("comment", { required: true })}
              disabled={isSaving}
            />
          </InputGroup>
        </Form>
      )}

      {!comments.length && !isLoading && <p>No message were found</p>}
    </>
  );
};

CommentsHandler.propTypes = {
  comments: PropTypes.array.isRequired,
  isEditable: PropTypes.bool,
  isSaving: PropTypes.bool,
  isLoading: PropTypes.bool,
  commentsToShow: PropTypes.number,
  onAdded: PropTypes.func,
};

CommentsHandler.defaultProps = {
  commentsToShow: 5,
};

export default CommentsHandler;

import PropTypes from "prop-types";

import ColoredCircle from "Components/Common/ColoredCircle";

import styles from "Components/Refer/styles/ReferralProcessStepItem.module.css";

const ReferralProcessStepItem = (props) => {
  const { title, subtitle, stepNumber } = props;

  return (
    <div className={styles.Step}>
      <ColoredCircle
        value={stepNumber.toString()}
        colorClassName={styles.ColorCircle}
      />
      <div className={styles.TitleContainer}>
        <span className={styles.Title}>{title}</span>
        <span className={styles.Subtitle}>{subtitle}</span>
      </div>
    </div>
  );
};

ReferralProcessStepItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  stepNumber: PropTypes.number,
};

ReferralProcessStepItem.defaultProps = {
  stepNumber: 0,
};

export default ReferralProcessStepItem;

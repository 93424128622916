import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

import FormattedDate from "Components/FormattedDate";
import SourceImage from "Components/SourceImage";
import StepBadge from "Components/StepBadge";

import styles from "Components/styles/ApplicationProcessesList.module.css";

const ApplicationProcessesList = (props) => {
  const { applicationProcesses } = props;

  const APPLICATIONS_TO_SHOW = 5;

  const [isShowingAllApplications, setIsShowingAllApplications] =
    useState(false);

  const getApplicationsToShow = () => {
    return applicationProcesses.length < APPLICATIONS_TO_SHOW ||
      isShowingAllApplications
      ? applicationProcesses
      : applicationProcesses.slice(-APPLICATIONS_TO_SHOW);
  };

  return (
    <>
      <div className={styles.CandidateHeader}>
        <h2>Candidates</h2>
      </div>

      {applicationProcesses.length ? (
        <>
          {getApplicationsToShow().map((application) => {
            return (
              <Link
                to={`/candidate/view/${application.candidate?._id}`}
                key={application._id}
              >
                <div className={styles.RowCandidate}>
                  <div className={styles.CandidateTitle}>
                    <SourceImage source={application.candidate?.source} />
                    {application.candidate?.name}
                  </div>
                  <div>
                    <StepBadge step={application.steps[0].step}></StepBadge>
                  </div>
                  <div className={styles.ApplicationDate}>
                    <FormattedDate>{application.applicationDate}</FormattedDate>
                  </div>
                </div>
              </Link>
            );
          })}
        </>
      ) : (
        <>
          <span>No candidates</span>
        </>
      )}

      {applicationProcesses.length > APPLICATIONS_TO_SHOW && (
        <div className={styles.ShowMore}>
          <Button
            variant="link"
            onClick={() =>
              setIsShowingAllApplications(!isShowingAllApplications)
            }
          >
            {isShowingAllApplications
              ? "View less candidates"
              : `Show all ${applicationProcesses.length} candidates`}
          </Button>
        </div>
      )}
    </>
  );
};

ApplicationProcessesList.propTypes = {
  applicationProcesses: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      applicationDate: PropTypes.string,
      steps: PropTypes.arrayOf(
        PropTypes.shape({
          step: PropTypes.string,
        })
      ),
      candidate: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        source: PropTypes.string,
      }),
    })
  ),
};

ApplicationProcessesList.defaultProps = {
  applicationProcesses: [],
};

export default ApplicationProcessesList;

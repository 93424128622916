import PropTypes from "prop-types";
import { faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "Components/Common/styles/CommentsIcon.module.css";

const CommentsIcon = (props) => {
  const { hasNewComments } = props;

  return (
    <div className={styles.CommentsIcon}>
      <FontAwesomeIcon
        data-testid="icon"
        icon={faCommentAlt}
        size="lg"
        className={styles.CommentsIconColor}
      />
      {hasNewComments && (
        <span
          data-testid="newCommentsIndicator"
          className={styles.NewCommentsIndicator}
        />
      )}
    </div>
  );
};

CommentsIcon.propTypes = {
  hasNewComments: PropTypes.bool,
};

export default CommentsIcon;

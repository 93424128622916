import axios from "axios";

const getBlobFile = async (url) => {
  const response = await axios.get(url, {
    responseType: "arraybuffer",
  });

  return response.data;
};

const exports = {
  getBlobFile,
};

export default exports;

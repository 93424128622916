import { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { toast } from "react-toastify";
import { PrivacyPolicyAndReferralTermsConditionsLabel } from "@itj-micoach/micoach-common";
import { saveAs } from "file-saver";

import DocumentCard from "Components/Candidate/DocumentCard";
import DocumentExampleModal from "Components/Candidate/DocumentExampleModal";
import UploadDocumentsMessage from "Components/Candidate/UploadDocuments/UploadDocumentsMessage";
import StateCard from "Components/Common/StateCard";
import ConfirmModal from "Components/ConfirmModal";
import Loading from "Components/Loading";
import UploadDocumentModal from "Components/UploadDocumentModal";
import { useRequestedDocuments } from "Hooks/useDocuments";
import { useQuery } from "Hooks/useQuery";
import PublicBackendService from "Services/PublicBackendService";
import { assets } from "constants.js";

const ALLOWED_EXTENSIONS = [
  "pdf",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "png",
  "jpg",
  "jpeg",
];

function UploadDocuments() {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showDeleteDocumentModal, setShowDeleteDocumentModal] = useState(false);
  const [showDocumentExampleModal, setShowDocumentExampleModal] =
    useState(false);
  const [checklistType, setChecklistType] = useState();
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedDocumentExample, setSelectedDocumentExample] = useState(null);

  const queryParams = useQuery();
  const documentRequestId = queryParams.get("p");
  const [
    requestedDocuments,
    setUploadedDocuments,
    uploadDocumentRequest,
    isRequestValid,
    loading,
  ] = useRequestedDocuments(documentRequestId);

  // Upload document

  const handleClickUpload = (checklistType) => {
    setChecklistType(checklistType);
    setShowUploadModal(true);
  };

  const handleSavedUpload = (documents) => {
    setUploadedDocuments(documents);
  };

  // Delete document

  const handleClickDelete = (document) => {
    setSelectedDocument(document);
    setShowDeleteDocumentModal(true);
  };

  const handleCloseDeleteDocument = () => {
    setShowDeleteDocumentModal(false);
  };

  const handleConfirmDeleteDocument = async () => {
    try {
      const documents = await PublicBackendService.deleteCandidateDocument(
        documentRequestId,
        selectedDocument._id
      );
      setUploadedDocuments(documents);
      setShowDeleteDocumentModal(false);
      toast.success("Success! The document has been deleted.");
    } catch (error) {
      toast.error(error.response.data?.message ?? "Error");
    }
  };

  // Documents examples

  const hasDocumentExample = (document) => {
    return uploadDocumentRequest.documentsExamples.some(
      (docExample) => docExample.key === document.key
    );
  };

  const handleClickDocumentExample = (document) => {
    let documentExampleIndex = null;
    uploadDocumentRequest.documentsExamples.forEach((docExample, index) => {
      if (docExample.key === document.key) documentExampleIndex = index;
    });
    setSelectedDocumentExample(documentExampleIndex);
    setShowDocumentExampleModal(true);
  };

  const handleCloseDocumentExample = () => {
    setShowDocumentExampleModal(false);
  };

  const handleClickPreviousDocument = () => {
    setSelectedDocumentExample(selectedDocumentExample - 1);
  };

  const handleClickNextDocument = () => {
    setSelectedDocumentExample(selectedDocumentExample + 1);
  };

  // Documents templates

  const hasDocumentTemplate = (document) => {
    return uploadDocumentRequest.documentsTemplates.some(
      (docTemplate) => docTemplate.key === document.key
    );
  };

  const handleClickDownloadTemplate = (document) => {
    const documentTemplate = uploadDocumentRequest.documentsTemplates.find(
      (docTemplate) => docTemplate.key === document.key
    );

    saveAs(documentTemplate.url, documentTemplate.name);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : isRequestValid ? (
        <>
          <h1 className="ScreenTitle">
            Welcome {uploadDocumentRequest?.candidateName},
          </h1>

          <section className="py-3">
            <UploadDocumentsMessage
              uploadDocumentRequest={uploadDocumentRequest}
            />
          </section>

          <section className="py-3 fs-14 fw-300">
            <ul>
              <li>Accepted file formats: .{ALLOWED_EXTENSIONS.join(" | .")}</li>
              <li>Maximum file size: 4MB.</li>
            </ul>
          </section>

          <Row>
            {requestedDocuments.map((document) => {
              return (
                <Col xs={12} md={6} lg={4} xl={4} key={document.key}>
                  <DocumentCard
                    document={document}
                    hasDocumentExample={hasDocumentExample(document)}
                    hasDocumentTemplate={hasDocumentTemplate(document)}
                    onClickUpload={() => handleClickUpload(document.key)}
                    onClickDelete={() => handleClickDelete(document)}
                    onClickExample={() => handleClickDocumentExample(document)}
                    onClickDownloadTemplate={() =>
                      handleClickDownloadTemplate(document)
                    }
                  />
                </Col>
              );
            })}
          </Row>

          <section className="pt-3 pb-5">
            <PrivacyPolicyAndReferralTermsConditionsLabel leadText="By uploading your personal data, you agree to our " />
          </section>

          <UploadDocumentModal
            isPublic={true}
            allowedExtensions={ALLOWED_EXTENSIONS}
            show={showUploadModal}
            checklistType={checklistType}
            documentRequestId={documentRequestId}
            candidateId={uploadDocumentRequest?.candidateId}
            onSaved={handleSavedUpload}
            onClose={() => setShowUploadModal(false)}
          />

          <ConfirmModal
            show={showDeleteDocumentModal}
            onClose={handleCloseDeleteDocument}
            onConfirm={handleConfirmDeleteDocument}
            title="Delete document"
            confirmButtonText="Yes, delete"
          >
            <div>
              Are you sure you want to delete the document{" "}
              <strong>{selectedDocument?.fileName}</strong>?
              <div className="mt-3"> This action cannot be undone.</div>
            </div>
          </ConfirmModal>

          <DocumentExampleModal
            show={showDocumentExampleModal}
            onClose={handleCloseDocumentExample}
            documents={uploadDocumentRequest.documentsExamples}
            selectedDocument={selectedDocumentExample}
            setPreviousDocument={handleClickPreviousDocument}
            setNextDocument={handleClickNextDocument}
          />
        </>
      ) : (
        <StateCard
          imageUrl={assets.ZERO_STATE}
          title="Upload document request not found or expired!"
        />
      )}
    </>
  );
}

export default UploadDocuments;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import micoach, { TaskApi } from "micoach-api";

import { getIsoEndDate, getLocalDate } from "utils.js";

import styles from "./styles/TaskModal.module.css";

const TaskModal = (props) => {
  const { show, userName, userEmail, isEditing, task } = props;

  const [hasDueDate, setHasDueDate] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { register, reset, handleSubmit, formState, control } = useForm({
    mode: "onChange",
  });
  const { isValid, errors } = formState;

  const today = new Date();

  const onSubmit = async (formValues) => {
    if (!isValid) {
      return;
    }

    try {
      setIsSaving(true);

      if (isEditing) {
        const newTask = {
          ...formValues,
          dueDate: getIsoEndDate(formValues.dueDate),
        };

        if (!hasDueDate) {
          newTask.dueDate = null;
        }

        const { data: updatedTask } = await TaskApi.updateTask(
          task._id,
          newTask
        );

        toast.success("Success! The task has been updated.");
        props.onEdit(task._id, updatedTask);
      } else {
        const task = {
          ...formValues,
          userEmail,
          type: micoach.TaskTypeEnum.General,
          dueDate: getIsoEndDate(formValues.dueDate),
        };

        if (!hasDueDate) {
          delete task.dueDate;
        }

        const { data: newTask } = await TaskApi.createTask(task);
        toast.success("Success! The task has been assigned.");
        props.onSaved?.(newTask);
      }
    } catch (error) {
      toast.error("Error, please try again.");
    } finally {
      setIsSaving(false);
      handleClose();
    }
  };

  const handleClose = () => {
    props.onClose();

    setHasDueDate(isEditing ? !!task.dueDate : false);
    reset();
  };

  const handleCheckboxChange = () => {
    setHasDueDate((hasDueDate) => !hasDueDate);
  };

  useEffect(() => {
    if (task) {
      reset({
        title: task.title,
        description: task.description,
        dueDate: task.dueDate ? getLocalDate(task.dueDate) : null,
      });

      if (task.dueDate) {
        setHasDueDate(true);
      }
    }
  }, [task, reset]);

  return (
    <Modal show={show} onHide={handleClose} className={styles.TaskModal}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Edit task" : `Assign task to ${userName}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.Description}>
            {isEditing
              ? "Please fill the inputs below to update the task."
              : "Please fill the inputs below to assign a task and make the commitments actionable."}
          </div>

          <Form.Group controlId="title" className="form-group required">
            <Form.Label>Task title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              isInvalid={!!errors.title}
              {...register("title", { required: true })}
            />
            {errors.title && (
              <div className="invalid-feedback d-block">
                {errors.title.type === "required" && "Task title is required"}
              </div>
            )}
          </Form.Group>
          <Form.Group controlId="description" className="form-group required">
            <Form.Label>Task description</Form.Label>
            <Form.Control
              className="h-100 fs-13"
              as="textarea"
              rows={5}
              type="text"
              maxLength={500}
              placeholder="Maximum 500 characters"
              name="description"
              {...register("description", { required: true, maxLength: 500 })}
              isInvalid={!!errors.description}
            />
            {errors.description && (
              <div className="invalid-feedback d-block">
                {errors.description.type === "required" &&
                  "Task description is required"}
                {errors.description.type === "maxLength" &&
                  "Max length exceeded"}
              </div>
            )}
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Check
              type="checkbox"
              label={<Form.Label id="dueDate">Due date</Form.Label>}
              checked={hasDueDate}
              onChange={handleCheckboxChange}
            />
            <Controller
              name="dueDate"
              defaultValue={""}
              control={control}
              rules={{
                required: hasDueDate,
              }}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  showPopperArrow={false}
                  ariaLabelledBy="dueDate"
                  disabled={!hasDueDate}
                  selected={value}
                  onChange={onChange}
                  dateFormat="MMM dd, yyyy"
                  minDate={today}
                  className={`form-control ${
                    errors.dueDate ? "is-invalid" : ""
                  }`}
                />
              )}
            />
            {errors.dueDate && (
              <div className="invalid-feedback d-block">
                {errors.dueDate.type === "required" && "Due date is required"}
              </div>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isSaving} variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={isSaving} type="submit" variant="primary">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

TaskModal.propTypes = {
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  show: PropTypes.bool.isRequired,
  task: PropTypes.object,
  isEditing: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSaved: PropTypes.func,
  onEdit: PropTypes.func,
};

TaskModal.defaultProps = {
  show: false,
};

export default TaskModal;

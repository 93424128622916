/* eslint-disable no-prototype-builtins */
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import {
  faCheckCircle,
  faTag,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CustomSelect from "Components/CustomSelect";
import SourceImage from "Components/SourceImage";
import {
  applicationProcessStatus,
  PERCENTAGE_PATTERN,
  sources,
} from "constants.js";
import { getCurrencyFormat, getSource } from "utils.js";

const Questionnaire = (props) => {
  const {
    control,
    errors,
    applicationProcess,
    isEditing,
    isExternalRecruiter,
    register,
  } = props;

  const sourcesOptions = sources.map((source) => {
    return {
      value: source.key,
      label: source.name,
    };
  });

  return (
    <Row>
      <Col xs={12} sm={6}>
        <Form.Group controlId="relevantExperience" className="form-group">
          <Form.Label>Relevant experience</Form.Label>

          {isEditing ? (
            <Form.Control
              type="text"
              name="relevantExperience"
              as="textarea"
              rows={16}
              className="fs-13 h-100"
              {...register("relevantExperience")}
            />
          ) : (
            <p>{applicationProcess.prescreening?.relevantExperience}</p>
          )}
        </Form.Group>
        <hr />
        <br />

        <Form.Group controlId="reasonForApplying" className="form-group">
          <Form.Label>Why are you looking for a new opportunity?</Form.Label>

          {isEditing ? (
            <Form.Control
              type="text"
              name="reasonForApplying"
              as="textarea"
              rows={7}
              className="fs-13 h-100"
              {...register("reasonForApplying")}
            />
          ) : (
            <p>{applicationProcess.prescreening?.reasonForApplying}</p>
          )}
        </Form.Group>
      </Col>

      <Col xs={12} sm={6}>
        <Form.Group controlId="currentCompany" className="form-group">
          <Form.Label>Current company</Form.Label>

          {isEditing ? (
            <Form.Control
              type="text"
              name="currentCompany"
              {...register("currentCompany")}
            />
          ) : (
            <p>{applicationProcess.prescreening?.currentCompany}</p>
          )}
        </Form.Group>
        <hr />
        <br />
        <Form.Label>
          Salary expectations{" "}
          <span className="fw-normal">(gross / monthly / MXN)</span>
        </Form.Label>
        {isEditing ? (
          <Row>
            <Form.Group
              as={Col}
              controlId="salaryExpectationMin"
              className="form-group"
            >
              <Form.Label className="fw-normal">Min</Form.Label>
              <InputGroup className="mb-2">
                <InputGroup.Text className="EditTag">
                  <FontAwesomeIcon className="fs-20" icon={faTag} />
                </InputGroup.Text>
                <Form.Control
                  name="salaryExpectationMin"
                  type="text"
                  maxLength="8"
                  {...register("salaryExpectationMin")}
                  onChange={(e) => props.onChangeSalary(e)}
                  onBlur={(e) => props.onBlurSalary(e)}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group
              as={Col}
              controlId="salaryExpectationMax"
              className="form-group"
            >
              <Form.Label className="fw-normal">Max</Form.Label>
              <InputGroup className="mb-2">
                <InputGroup.Text className="EditTag">
                  <FontAwesomeIcon className="fs-20" icon={faTag} />
                </InputGroup.Text>
                <Form.Control
                  name="salaryExpectationMax"
                  type="text"
                  maxLength="8"
                  {...register("salaryExpectationMax")}
                  onChange={(e) => props.onChangeSalary(e)}
                  onBlur={(e) => props.onBlurSalary(e)}
                />
              </InputGroup>
            </Form.Group>
          </Row>
        ) : (
          <p>
            <FontAwesomeIcon className="fs-20 me-3" icon={faTag} />
            {`${getCurrencyFormat(
              applicationProcess.prescreening?.salaryExpectationMin ?? 0
            )} - ${getCurrencyFormat(
              applicationProcess.prescreening?.salaryExpectationMax ?? 0
            )}`}
          </p>
        )}
        <Form.Group controlId="salaryBase" className="form-group">
          <Form.Label>
            Current salary{" "}
            <span className="fw-normal">(gross / monthly / MXN)</span>
          </Form.Label>

          {isEditing ? (
            <InputGroup className="mb-2">
              <InputGroup.Text className="EditTag">
                <FontAwesomeIcon className="fs-20" icon={faTag} />
              </InputGroup.Text>

              <Form.Control
                name="salaryBase"
                type="text"
                maxLength="8"
                {...register("salaryBase")}
                onChange={(e) => props.onChangeSalary(e)}
                onBlur={(e) => props.onBlurSalary(e)}
              />
            </InputGroup>
          ) : (
            <p>
              <FontAwesomeIcon className="fs-20 me-3" icon={faTag} />
              {getCurrencyFormat(
                applicationProcess.prescreening?.salaryBase ?? 0
              )}
            </p>
          )}
        </Form.Group>
        <hr />
        <br />
        <Form.Group controlId="noticePeriod" className="form-group">
          <Form.Label>Notice period</Form.Label>

          {isEditing ? (
            <Form.Control
              type="text"
              name="noticePeriod"
              {...register("noticePeriod")}
            />
          ) : (
            <p>{applicationProcess.prescreening?.noticePeriod}</p>
          )}
        </Form.Group>

        <hr />
        <br />

        <Form.Group controlId="hasVisa" className="form-group">
          <Form.Label className="me-4">US Visa</Form.Label>

          {isEditing ? (
            <>
              <Form.Check
                type="radio"
                name="hasVisa"
                id="hasVisaYes"
                value={true}
                inline
                label="Yes"
                defaultChecked={
                  applicationProcess.prescreening?.hasOwnProperty("hasVisa") &&
                  applicationProcess.prescreening?.hasVisa !== null
                    ? applicationProcess.prescreening?.hasVisa
                    : null
                }
                {...register("hasVisa")}
              />
              <Form.Check
                type="radio"
                name="hasVisa"
                id="hasVisaNo"
                value={false}
                inline
                label="No"
                defaultChecked={
                  applicationProcess.prescreening?.hasOwnProperty("hasVisa") &&
                  applicationProcess.prescreening?.hasVisa !== null
                    ? !applicationProcess.prescreening?.hasVisa
                    : null
                }
                {...register("hasVisa")}
              />
            </>
          ) : (
            <>
              {applicationProcess.prescreening?.hasOwnProperty("hasVisa") &&
              applicationProcess.prescreening?.hasVisa !== null ? (
                applicationProcess.prescreening?.hasVisa ? (
                  <span>
                    <FontAwesomeIcon className="fs-20" icon={faCheckCircle} />{" "}
                    Yes
                  </span>
                ) : (
                  <span>
                    <FontAwesomeIcon className="fs-20" icon={faTimesCircle} />{" "}
                    No
                  </span>
                )
              ) : null}
            </>
          )}
        </Form.Group>

        <hr />
        <br />

        <Form.Group controlId="hasPassport" className="form-group">
          <Form.Label className="me-4">Passport</Form.Label>

          {isEditing ? (
            <>
              <Form.Check
                type="radio"
                name="hasPassport"
                id="hasPassportYes"
                value={true}
                inline
                label="Yes"
                defaultChecked={
                  applicationProcess.prescreening?.hasOwnProperty(
                    "hasPassport"
                  ) && applicationProcess.prescreening?.hasPassport !== null
                    ? applicationProcess.prescreening?.hasPassport
                    : null
                }
                {...register("hasPassport")}
              />
              <Form.Check
                type="radio"
                name="hasPassport"
                id="hasPassportNo"
                value={false}
                inline
                label="No"
                defaultChecked={
                  applicationProcess.prescreening?.hasOwnProperty(
                    "hasPassport"
                  ) && applicationProcess.prescreening?.hasPassport !== null
                    ? !applicationProcess.prescreening?.hasPassport
                    : null
                }
                {...register("hasPassport")}
              />
            </>
          ) : (
            <>
              {applicationProcess.prescreening?.hasOwnProperty("hasPassport") &&
              applicationProcess.prescreening?.hasPassport !== null ? (
                applicationProcess.prescreening?.hasPassport ? (
                  <span>
                    <FontAwesomeIcon className="fs-20" icon={faCheckCircle} />{" "}
                    Yes
                  </span>
                ) : (
                  <span>
                    <FontAwesomeIcon className="fs-20" icon={faTimesCircle} />{" "}
                    No
                  </span>
                )
              ) : null}
            </>
          )}
        </Form.Group>

        <hr />
        <br />

        <Form.Group controlId="isWillingToRelocate" className="form-group">
          <Form.Label className="me-4">Willing to relocate to TJ?</Form.Label>

          {isEditing ? (
            <>
              <Form.Check
                type="radio"
                name="isWillingToRelocate"
                id="isWillingToRelocateYes"
                value={true}
                inline
                label="Yes"
                defaultChecked={
                  applicationProcess.prescreening?.hasOwnProperty(
                    "isWillingToRelocate"
                  ) &&
                  applicationProcess.prescreening?.isWillingToRelocate !== null
                    ? applicationProcess.prescreening?.isWillingToRelocate
                    : null
                }
                {...register("isWillingToRelocate")}
              />
              <Form.Check
                type="radio"
                name="isWillingToRelocate"
                id="isWillingToRelocateNo"
                value={false}
                inline
                label="No"
                defaultChecked={
                  applicationProcess.prescreening?.hasOwnProperty(
                    "isWillingToRelocate"
                  ) &&
                  applicationProcess.prescreening?.isWillingToRelocate !== null
                    ? !applicationProcess.prescreening?.isWillingToRelocate
                    : null
                }
                {...register("isWillingToRelocate")}
              />
            </>
          ) : (
            <>
              {applicationProcess.prescreening?.hasOwnProperty(
                "isWillingToRelocate"
              ) &&
              applicationProcess.prescreening?.isWillingToRelocate !== null ? (
                applicationProcess.prescreening?.isWillingToRelocate ? (
                  <span>
                    <FontAwesomeIcon className="fs-20" icon={faCheckCircle} />{" "}
                    Yes
                  </span>
                ) : (
                  <span>
                    <FontAwesomeIcon className="fs-20" icon={faTimesCircle} />{" "}
                    No
                  </span>
                )
              ) : null}
            </>
          )}
        </Form.Group>

        <hr />
        <br />

        <Form.Group controlId="candidateSource" className="form-group">
          <Form.Label>Candidate&apos;s source</Form.Label>
          {isEditing ? (
            <>
              <CustomSelect
                name="candidateSource"
                control={control}
                isRequired
                disabled={
                  applicationProcess.status !==
                    applicationProcessStatus.active || isExternalRecruiter
                }
                options={sourcesOptions}
                defaultValue={sourcesOptions.find(
                  (source) =>
                    source.value ===
                    (applicationProcess.prescreening?.candidateSource ??
                      applicationProcess.candidate.source)
                )}
              />
              {errors.candidateSource && (
                <div className="invalid-feedback d-block">
                  {errors.candidateSource.type === "required" &&
                    "Source is required"}
                </div>
              )}
            </>
          ) : (
            <p>
              <SourceImage
                source={
                  applicationProcess.prescreening?.candidateSource ??
                  applicationProcess.candidate.source
                }
              />
              <span className="ms-2">
                {getSource(
                  applicationProcess.prescreening?.candidateSource ??
                    applicationProcess.candidate.source
                )}
              </span>
            </p>
          )}
        </Form.Group>

        <hr />
        <br />

        <Form.Group controlId="englishLevel" className="form-group">
          <Form.Label>English level</Form.Label>

          {isEditing ? (
            <>
              <Form.Control
                type="text"
                name="englishLevel"
                isInvalid={!!props.errors.englishLevel}
                {...register("englishLevel", {
                  pattern: PERCENTAGE_PATTERN,
                })}
              />
              {errors.englishLevel && (
                <Form.Control.Feedback type="invalid">
                  {errors.englishLevel.type === "pattern" &&
                    "The valid range is from 0 to 100"}
                </Form.Control.Feedback>
              )}
            </>
          ) : (
            <p>
              {applicationProcess.prescreening?.englishLevel ||
              applicationProcess.prescreening?.englishLevel === 0
                ? `${applicationProcess.prescreening?.englishLevel}%`
                : ""}
            </p>
          )}
        </Form.Group>

        <hr />
        <br />

        <Form.Group controlId="codingChallenge" className="form-group">
          <Form.Label>Coding challenge</Form.Label>

          {isEditing ? (
            <>
              <Form.Control
                type="text"
                name="codingChallenge"
                {...register("codingChallenge", {
                  pattern: PERCENTAGE_PATTERN,
                })}
                isInvalid={!!props.errors.codingChallenge}
              />
              {errors.codingChallenge && (
                <Form.Control.Feedback type="invalid">
                  {errors.codingChallenge.type === "pattern" &&
                    "The valid range is from 0 to 100"}
                </Form.Control.Feedback>
              )}
            </>
          ) : (
            <p>
              {applicationProcess.prescreening?.codingChallenge ||
              applicationProcess.prescreening?.codingChallenge === 0
                ? `${applicationProcess.prescreening?.codingChallenge}%`
                : ""}
            </p>
          )}
        </Form.Group>
      </Col>
    </Row>
  );
};

Questionnaire.propTypes = {
  applicationProcess: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  isExternalRecruiter: PropTypes.bool,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  onBlurSalary: PropTypes.func.isRequired,
  onChangeSalary: PropTypes.func.isRequired,
};

Questionnaire.defaultValues = {
  isEditing: false,
  isExternalRecruiter: false,
};

export default Questionnaire;

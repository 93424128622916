import PropTypes from "prop-types";

import CoacheeCard from "Components/Candidate/CoacheeCard";

import styles from "Components/Candidate/styles/CoacheeList.module.css";

const CoacheeList = (props) => {
  const { coachees, balanceDate } = props;

  return (
    <div className={styles.CoacheeList}>
      {coachees
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((coachee) => (
          <CoacheeCard
            key={coachee.userId}
            balanceDate={balanceDate}
            coachee={coachee}
          />
        ))}
    </div>
  );
};

CoacheeList.propTypes = {
  coachees: PropTypes.arrayOf(PropTypes.object).isRequired,
  balanceDate: PropTypes.shape({
    year: PropTypes.string,
    quarter: PropTypes.string,
  }),
};

export default CoacheeList;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { toast } from "react-toastify";

import DocumentItem from "Components/Common/DocumentItem";
import DragDrop from "Components/Common/DragDrop";
import Loading from "Components/Loading";
import BackendService from "Services/BackendService";
import PublicBackendService from "Services/PublicBackendService";
import S3Service from "Services/S3Service";
import { checklistItems } from "constants.js";

import "Components/styles/UploadDocumentModal.css";

function UploadDocumentModal(props) {
  const {
    show,
    candidateId,
    documentRequestId,
    checklistType,
    isPublic,
    isMultiple,
    isProfilePicture,
    allowedExtensions,
  } = props;

  const [fileObjects, setFileObjects] = useState([]);
  const [validForm, setValidForm] = useState(false);
  const [uploading, setUploading] = useState(false);

  // Helpers

  const isSameFile = (file1, file2) => {
    return file1.name === file2.name && file1.size === file2.size;
  };

  const checklistOptions = checklistItems.map((checklistItem) => {
    return { value: checklistItem.key, label: checklistItem.value };
  });

  // Handlers

  const handleDropDocument = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      let newFiles = [];

      if (isMultiple) {
        for (const acceptedFile of acceptedFiles) {
          const exists = fileObjects.find((fileObject) =>
            isSameFile(fileObject.file, acceptedFile)
          );
          if (!exists) {
            newFiles.push({
              file: acceptedFile,
              options: checklistOptions,
            });
          }
        }
        newFiles = [...fileObjects, ...newFiles];
      } else {
        newFiles.push({
          file: acceptedFiles[0],
        });
      }
      setFileObjects(newFiles);
    }
  };

  const handleSelectChecklistType = (option, index) => {
    const newFileObjects = [...fileObjects];

    // Set value and checklistType from current select
    newFileObjects[index].value = option;
    newFileObjects[index].checklistType = option.value;

    // Update fileObjects
    setFileObjects(newFileObjects);
  };

  const handleClickDelete = (index) => {
    const newFileObjects = [...fileObjects];
    newFileObjects.splice(index, 1);

    // Update fileObjects
    setFileObjects(newFileObjects);
  };

  const handleSubmit = async () => {
    if (fileObjects.length > 0) {
      setUploading(true);
      const documents = [];

      for (const fileObject of fileObjects) {
        const document = {
          metadata: {
            fileName: fileObject.file.name,
            size: fileObject.file.size,
            contentType: fileObject.file.type,
            checklistType: fileObject.checklistType ?? checklistType ?? "OTHER",
            key: "",
          },
          file: fileObject.file,
        };
        documents.push(document);
      }

      let response;

      for await (const document of documents) {
        try {
          // Get a secure URL for document upload.
          if (isPublic) {
            response = await PublicBackendService.getCandidateDocumentUploadUrl(
              documentRequestId,
              document.metadata
            );
          } else if (isProfilePicture) {
            response = await BackendService.getProfilePictureUploadUrl(
              document.metadata
            );
          } else {
            response = await BackendService.getCandidateDocumentUploadUrl(
              candidateId,
              document.metadata
            );
          }

          // Upload the document to the remote server and update the key.
          await S3Service.uploadFileToS3(response.url, document.file);
          document.metadata.key = response.key;

          // Confirm to the Backend that the document was uploaded successfully.
          if (isPublic) {
            response = await PublicBackendService.confirmUploadDocument(
              documentRequestId,
              document.metadata
            );
          } else if (isProfilePicture) {
            response = await BackendService.confirmUploadProfilePicture();
          } else {
            response = await BackendService.confirmUploadDocument(
              candidateId,
              document.metadata
            );
          }
        } catch (error) {
          toast.error(error ?? "Error");
        }
      }

      props.onSaved?.(response);
      handleClose();
      !isProfilePicture &&
        toast.success("Success! The document has been uploaded.");
      setUploading(false);
    } else {
      toast.error("Error! You must select or drag a file.");
    }
  };

  const handleClose = () => {
    setFileObjects([]);
    props.onClose();
  };

  useEffect(() => {
    // If you have not selected the checklistType of any file (multiple upload), the form is invalid.
    const invalid =
      (isMultiple &&
        fileObjects.some((fileObject) => {
          // eslint-disable-next-line no-prototype-builtins
          return !fileObject.hasOwnProperty("checklistType");
        })) ||
      !fileObjects.length;
    setValidForm(!invalid);
  }, [fileObjects, isMultiple]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      className="UploadDocumentModal"
    >
      <Modal.Header closeButton={!uploading}>
        <Modal.Title>
          {isProfilePicture
            ? "Profile picture"
            : `Document${isMultiple ? "s" : ""}`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group controlId="resume" className="form-group required">
            <Form.Label>{`Upload ${
              isProfilePicture ? "photo" : isMultiple ? "documents" : "document"
            }`}</Form.Label>
            {(isMultiple || (!isMultiple && !fileObjects.length)) && (
              <DragDrop
                allowedExtensions={allowedExtensions}
                isMultiple={isMultiple}
                onDrop={handleDropDocument}
              />
            )}

            <div className={isMultiple ? "DocumentsList" : ""}>
              {fileObjects.map((fileObject, index) => {
                return (
                  <div className="Document" key={index + new Date().getTime()}>
                    {isMultiple && (
                      <Form.Group className="form-group SelectOption">
                        <Form.Label>Document</Form.Label>
                        <Select
                          classNamePrefix="ReactSelect"
                          options={fileObject.options}
                          isSearchable={true}
                          isDisabled={uploading}
                          onChange={(option) =>
                            handleSelectChecklistType(option, index)
                          }
                          defaultValue={fileObject.value}
                        />
                      </Form.Group>
                    )}

                    <DocumentItem
                      document={fileObject.file}
                      isMultiple={isMultiple}
                      isSaving={uploading}
                      onDelete={() => handleClickDelete(index)}
                    />
                  </div>
                );
              })}
            </div>
          </Form.Group>
        </Form>
        {uploading && (
          <div className="text-center mt-3">
            <Loading />
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={uploading}>
          Close
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={!validForm || uploading}
        >
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

UploadDocumentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  candidateId: PropTypes.string,
  documentRequestId: PropTypes.string,
  checklistType: PropTypes.string,
  isPublic: PropTypes.bool,
  isMultiple: PropTypes.bool,
  isProfilePicture: PropTypes.bool,
  allowedExtensions: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  onSaved: PropTypes.func,
};

UploadDocumentModal.defaultProps = {
  isProfilePicture: false,
  isMultiple: false,
  allowedExtensions: ["pdf", "doc", "docx", "png", "jpg", "jpeg"],
};

export default UploadDocumentModal;

import PropTypes from "prop-types";

import CandidateCard from "Components/CandidateCard";
import { applicationProcessStatus } from "constants.js";

import "./styles/KanbanItem.css";

const getStyle = ({ draggableStyle, virtualStyle, isDragging }) => {
  const combined = {
    ...virtualStyle,
    ...draggableStyle,
  };

  const space = 10;

  // when dragging we want to use the draggable style for placement, otherwise use the virtual style
  const result = {
    ...combined,
    height: isDragging ? combined.height : combined.height - space,
    width: isDragging
      ? draggableStyle.width
      : `calc(${combined.width} - ${space}px)`,
    marginBottom: space,
  };

  return result;
};

const KanbanItem = (props) => {
  const {
    step,
    index,
    candidate,
    userId,
    provided,
    style,
    isDragging,
    isExternalRecruiter,
    onClickRequestDocuments,
  } = props;

  const showDropdownMenu =
    candidate?.applicationProcess?.status === applicationProcessStatus.active;

  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getStyle({
        draggableStyle: provided.draggableProps.style,
        virtualStyle: style,
        isDragging,
      })}
      className={`KanbanItem ${isDragging && "Dragging"}`}
    >
      <CandidateCard
        candidate={candidate}
        step={step}
        userId={userId}
        isExternalRecruiter={isExternalRecruiter}
        showDropdownMenu={showDropdownMenu}
        onClickHire={
          props.onClickHire && (() => props.onClickHire(candidate, step, index))
        }
        onClickDiscard={
          props.onClickDiscard && (() => props.onClickDiscard(step, index))
        }
        onClickMessage={
          props.onClickMessage && (() => props.onClickMessage(candidate))
        }
        onClickMove={
          props.onClickMove &&
          ((status) => props.onClickMove({ candidate, step, index, status }))
        }
        onClickRequestDocuments={onClickRequestDocuments}
      />
    </div>
  );
};

KanbanItem.propTypes = {
  step: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  candidate: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  isExternalRecruiter: PropTypes.bool,
  provided: PropTypes.object,
  style: PropTypes.object,
  isDragging: PropTypes.bool,
  onClickHire: PropTypes.func,
  onClickDiscard: PropTypes.func,
  onClickMessage: PropTypes.func,
  onClickMove: PropTypes.func,
  onClickRequestDocuments: PropTypes.func,
};

export default KanbanItem;

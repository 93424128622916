import PropTypes from "prop-types";

import FormattedDate from "Components/FormattedDate";
import UserImage from "Components/UserImage";

import styles from "Components/Common/styles/CommentItem.module.css";

const CommentItem = (props) => {
  const { comment } = props;

  return (
    <div className={styles.CommentItem}>
      <div className={styles.Info}>
        <UserImage
          className={styles.UserImage}
          userName={comment.userName}
          userImageUrl={comment.userImage}
        />
        <span className={styles.UserName}>{comment.userName} </span>
        <span className={styles.Date}>
          <FormattedDate>{comment.createdAt}</FormattedDate>
        </span>
      </div>
      <div className={styles.Text}>{comment.text}</div>
    </div>
  );
};

CommentItem.propTypes = {
  comment: PropTypes.object.isRequired,
};

export default CommentItem;

import PropTypes from "prop-types";

import styles from "Components/Common/styles/CategorySelectorBar.module.css";

const CategorySelectorBar = (props) => {
  const { active, barCategories } = props;

  return (
    <div className={styles.CategorySelectorBar}>
      {barCategories?.map((category) => (
        <button
          key={category.name}
          onClick={() => {
            props.onClick(category.value);
          }}
          className={`${styles.Filter} ${
            active === category.value ? styles.Active : ""
          }`}
        >
          {category.name}
        </button>
      ))}
    </div>
  );
};

CategorySelectorBar.propTypes = {
  barCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  active: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CategorySelectorBar;

import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Rating from "Components/Rating";

import styles from "Components/Candidate/Skills/styles/SkillRatingList.module.css";

const SkillRatingList = (props) => {
  const { skillList, isEditable } = props;

  const handleOnChangeValue = (name, value) => {
    props.onSelectValueChange?.({
      ...getSkill(name),
      value,
    });
  };

  const handleRemoveSkill = (name) => {
    props.onSelectSkillRemove?.({
      ...getSkill(name),
    });
  };

  const getSkill = (name) => {
    return skillList.find((skill) => skill.name === name);
  };

  return (
    <div className={styles.SkillRatingList}>
      {skillList.map((skill) => {
        return (
          <div key={skill.name} className={styles.SkillRating}>
            <div className={styles.SkillName}>{skill.name}</div>
            <Rating
              value={skill?.value ?? 1}
              isSmall
              isEditable={isEditable}
              name={skill.name}
              onSelectValueChange={handleOnChangeValue}
            />
            {isEditable && (
              <Button
                variant="link"
                className="Delete"
                onClick={() => handleRemoveSkill(skill.name)}
              >
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  style={{
                    color: "var(--blue-medium-color)",
                    fontSize: 14,
                  }}
                />
              </Button>
            )}
          </div>
        );
      })}
    </div>
  );
};

SkillRatingList.propTypes = {
  skillList: PropTypes.arrayOf(PropTypes.object).isRequired,
  isEditable: PropTypes.bool,
  onSelectValueChange: PropTypes.func,
  onSelectSkillRemove: PropTypes.func,
};

SkillRatingList.defaultProps = {
  isEditable: false,
};

export default SkillRatingList;

import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import { ReportApi } from "micoach-api";

import CustomSelect from "Components/CustomSelect";
import Loading from "Components/Loading";
import { useCompanies } from "Hooks/useCompanies";
import { getFormattedLocalDate } from "utils.js";

import styles from "Screens/Report/styles/Report.module.css";

const CompanyApplicationProcess = () => {
  const [generatingReport, setGeneratingReport] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [companies, , loadingCompanies] = useCompanies();

  const onSubmit = async (formValues) => {
    setGeneratingReport(true);

    const companyId = formValues.company;
    const company = companies?.find((company) => company._id === companyId);
    const date = getFormattedLocalDate(new Date(), "YYYY-MM-DD");

    try {
      const { data } = await ReportApi.getApplicationProcessCompany(
        companyId,
        date,
        "PDF",
        {
          responseType: "arraybuffer",
        }
      );

      const blob = new Blob([data]);
      const fileName = `${company.name} - HiringProcesses.pdf`;

      saveAs(blob, fileName);
    } catch (error) {
      toast.error("Error!, please try again.");
    } finally {
      setGeneratingReport(false);
    }
  };

  const companiesOptions = companies?.map((company) => {
    return {
      value: company._id,
      label: company.name,
    };
  });

  if (loadingCompanies) {
    return <Loading />;
  }

  return (
    <>
      <h1 className="ScreenTitle">Company hiring process report</h1>
      <p className={styles.Description}>
        Lists positions with candidate count by hiring process step from the
        selected company.
      </p>
      <Row>
        <Col sm={8} md={6} xl={4}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="company" className="required form-group">
              <Form.Label>Select a company</Form.Label>
              <CustomSelect
                name="company"
                isRequired
                control={control}
                options={companiesOptions}
                placeholder="Start typing to search in companies..."
                isInvalid={errors.company}
              />
              {errors.company && (
                <div className="invalid-feedback d-block">
                  {errors.company.type === "required" && "Company is required"}
                </div>
              )}
            </Form.Group>
            <div className={styles.Buttons}>
              <Button type="submit" disabled={generatingReport}>
                <FontAwesomeIcon icon={faFileDownload} /> Download report
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default CompanyApplicationProcess;

import styles from "Screens/Public/styles/PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <div className={styles.PrivacyPolicy}>
      <h1 className="ScreenTitle">miCoach by ITJ Inc. Privacy Policy</h1>

      <p>
        This Privacy Policy explains our policies and practices regarding the
        collection, use, storage, and disclosure of your personal information by
        miCoach app by ITJ Inc. We want you to feel secure when visiting our
        platform and are committed to maintaining your privacy when doing so.
        The miCoach app by ITJ Inc. may contain links to other websites or
        services. ITJ Inc. is not responsible for the privacy practices or the
        content of such websites. The information below provides an overview of
        how we protect your privacy.
      </p>

      <br />

      <ol>
        <li>
          What personal information do we gather? In general, you may interact
          with miCoach app by ITJ Inc. without providing us with any personal
          information about yourself. However, if you want to be part of miCoach
          Community and use miCoach tools and features, you are free to register
          and login, sharing personal information which includes your Internet
          address so that we can track unique visits to our site for analytic
          purposes. Information like name, location and email address in order
          to grant you access to protected and secured sites or provide you with
          the opportunity to register for an event, to fulfill your requests for
          information including online trainings, events, assessments and
          evaluations. Information uploaded to miCoach as your resume or job
          application for joining a hiring process is protected and secured.
        </li>
        <li>
          How do we collect your personal information? We collect information
          directly through miCoach&rsquo;s platform developed interfaces that
          you choose to complete. We may also collect information indirectly
          with localStorage elements that are stored on your computer and used
          in subsequent visits to miCoach&rsquo;s interfaces. Personal Data
          collected for the following purposes and using the following services:
          Analytics: Google Analytics Personal Data: Usage Data. Displaying
          content from external platforms: Google Fonts Personal Data: Usage
          Data, various types of Data as specified in the privacy policy of the
          service. Platform services and hosting: Webflow Personal Data: various
          types of Data as specified in the privacy policy of the service. Tag
          Management: Google Tag Manager Personal Data: Usage Data.
        </li>
        <li>
          How do we use your personal information? Information we collect is
          used to determine what is most effective about our platform, to help
          us identify ways to improve functionality, to personalize the visit
          for you with localStorage elements, and to determine how we can tailor
          our platform to make it more effective. We may also use data for other
          purposes, which we would describe to you at the point we collect the
          information (for example, if you are invited to register for an
          event). When you supply miCoach app by ITJ Inc. with your personal
          information, we may use the information to learn more about you so
          that we can develop better products and services or use the
          information, sharing it with our business partners, to inform you
          about new products, services and offers that may be of interest to
          you. Information uploaded to miCoach as a resume is used by your
          consent to participate in a hiring process for a certain open position
          posted in miCoach platform.
        </li>
        <li>
          Do we share your information with outside parties? Any and all
          information collected at this platform will be kept strictly
          confidential.
        </li>
        <li>
          What about sensitive data? We do not typically collect sensitive
          personal data through this platform. If we do seek to collect such
          data, we will inform you as to our intended use of such sensitive data
          and ask you to consent to our storing and the proposed uses of such
          data. We may also collect some sensitive personal data incidentally.
          For instance, we may collect data incidentally when a user uploads
          his/her resume, because the resume may contain this information. By
          providing us with unsolicited sensitive personal data, you consent to
          our using the data subject to applicable law as described in this
          privacy policy.
        </li>
        <li>
          How long will we keep this data? We limit the collection and use of
          personal information to that which is necessary for the identified
          purpose and will not be used for any other purposes. Personal
          information used will be retained as per legal or contractual
          requirements in a secure system unless you requested us to dispose of
          your information. We will delete your personal data as soon as we no
          longer have a need or a legal basis to process it. We&rsquo;ll always
          delete your data when we receive a request from you to do so. In the
          unlikely event that we are still obliged under the law to keep your
          data, we&rsquo;ll inform you about that.
        </li>
        <li>
          What about security? The security of your personal information is
          important to miCoach app by ITJ Inc. and we take appropriate steps to
          maintain the security of your personal information. The open nature of
          the Internet is such that data may flow over networks without security
          measures and may be accessed and used by people other than those for
          whom the data is intended. While this is out of our control, we aim to
          apply appropriate levels of security at all times. We use
          industry-standard technologies and maintain current security standards
          to ensure that your personal information is protected against
          unauthorized access, disclosure, inappropriate alteration, or misuse.
          We manage our server environment appropriately and our firewall
          infrastructure is strictly adhered to. We routinely employ current
          technologies to ensure that the confidentiality and privacy of your
          information are not compromised.
        </li>
        <li>
          Updating this privacy policy. Any changes to our privacy policy and
          information handling practices will be acknowledged in this privacy
          policy in a timely manner. We may add, modify or remove portions of
          this Privacy Policy when we believe it is appropriate to do so.
        </li>
        <li>
          General. Put simply, we value your trust in us. So we want to maintain
          your privacy and work with you to ensure that this platform and the
          information and services we provide to you are a valuable resource for
          you. For any more information about this website or your visit, please
          contact us at micoach@itj.com.
        </li>
      </ol>
    </div>
  );
};

export default PrivacyPolicy;

import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import CommentsHandler from "Components/Common/CommentsHandler";

const CommentsPopover = (props) => {
  const {
    comments,
    isLoading,
    isSaving,
    isEditable,
    setShowComments,
    onAddComment,
  } = props;

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom-end"
      rootClose
      overlay={
        <Popover>
          <Popover.Body>
            <CommentsHandler
              comments={comments}
              isEditable={isEditable}
              isLoading={isLoading}
              isSaving={isSaving}
              onAdded={onAddComment}
            />
          </Popover.Body>
        </Popover>
      }
      onExited={() => setShowComments(false)}
    >
      {props.children}
    </OverlayTrigger>
  );
};

CommentsPopover.propTypes = {
  comments: PropTypes.array,
  isEditable: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSaving: PropTypes.bool,
  children: PropTypes.element,
  setShowComments: PropTypes.func,
  onAddComment: PropTypes.func,
};

export default CommentsPopover;

import Card from "react-bootstrap/Card";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "Components/Common/styles/SkeletonCard.module.css";

const SkeletonCard = () => {
  return (
    <Card className={`Card ${styles.SkeletonCard}`}>
      <Card.Body className={styles.Body}>
        <div className={styles.Icon}>
          <FontAwesomeIcon icon={faStar} />
        </div>
        <div>
          <div className={styles.Text1}></div>
          <div className={styles.Text2}></div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SkeletonCard;

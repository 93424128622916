import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify";
import { PositionTemplateApi } from "micoach-api";

import ConfirmModal from "Components/ConfirmModal";
import GoBackButton from "Components/GoBackButton";
import JobPositionAddEditModal from "Components/Job/Position/JobPositionAddEditModal";
import JobTemplateAddEditModal from "Components/Job/Template/JobTemplateAddEditModal";
import JobTemplatePreviewModal from "Components/Job/Template/JobTemplatePreviewModal";
import JobTemplatesTable from "Components/Job/Template/JobTemplatesTable";
import Loading from "Components/Loading";
import SearchBar from "Components/SearchBar";
import { useCompanies } from "Hooks/useCompanies";
import { useJobTemplates } from "Hooks/useJobTemplate";
import { usePublicRoles } from "Hooks/useRoles";
import { usePublicSkills } from "Hooks/useSkills";

const ListJobTemplates = () => {
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [search, setSearch] = useState("");
  const [position, setPosition] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [showPositionModal, setShowPositionModal] = useState(false);
  const [showJobTemplatePreviewModal, setShowJobTemplatePreviewModal] =
    useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    jobTemplates,
    loadingJobTemplates,
    loadJobTemplates,
    setJobTemplates,
  } = useJobTemplates();
  const [companies] = useCompanies();
  const { skills, loadingSkills } = usePublicSkills();
  const { roles } = usePublicRoles();

  const handleSearch = (data) => {
    const newSearch = data.search?.trim().toLowerCase();
    setSearch(newSearch);
  };

  const handleSaved = (template, isEditing) => {
    if (isEditing) {
      setJobTemplates((prevTemplates) => {
        const index = prevTemplates.findIndex(
          (templateItem) => templateItem._id === template._id
        );

        const newTemplates = [...prevTemplates];
        newTemplates[index] = template;

        return newTemplates;
      });
    } else {
      setJobTemplates((prevTemplates) => [...prevTemplates, template]);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setIsDeleting(true);

      await PositionTemplateApi.deletePositionTemplate(selectedTemplate._id);

      setShowConfirmDelete(false);
      setJobTemplates((prevTemplates) =>
        prevTemplates.filter(
          (template) => template._id !== selectedTemplate._id
        )
      );

      toast.success("Success! The Job Template has been deleted.");
    } catch (error) {
      const status = error.response.status;

      if (status === 404) {
        toast.info("Job Template not found.");
      } else {
        toast.error("Error, please try again.");
      }
    } finally {
      setIsDeleting(false);
    }
  };

  const handleClickCreate = () => {
    setSelectedTemplate({});
    setShowTemplateModal(true);
  };

  const handleClickDelete = (template) => {
    setSelectedTemplate(template);
    setShowConfirmDelete(true);
  };

  const handleClickEdit = (template) => {
    setSelectedTemplate(template);
    setShowTemplateModal(true);
  };

  const handleClickOpenPosition = (template) => {
    setPosition({
      title: template.title,
      about: template.about,
      responsibilities: template.responsibilities,
      qualifications: template.qualifications,
      skills: template.skills,
      seniority: template.seniority,
    });
    setShowPositionModal(true);
  };

  const handleClickJobTitle = (position) => {
    setSelectedTemplate(position);
    setShowJobTemplatePreviewModal(true);
  };

  useEffect(() => {
    loadJobTemplates();
  }, [loadJobTemplates]);

  useEffect(() => {
    if (jobTemplates || search) {
      if (search === "") {
        setFilteredTemplates(jobTemplates);
      } else {
        setFilteredTemplates(
          jobTemplates?.filter((template) =>
            template.title.toLowerCase().includes(search)
          )
        );
      }
    }
  }, [jobTemplates, search]);

  if (loadingJobTemplates) {
    return <Loading />;
  }

  return (
    <>
      <JobTemplatePreviewModal
        show={showJobTemplatePreviewModal}
        template={selectedTemplate}
        onClose={() => {
          setShowJobTemplatePreviewModal(false);
        }}
      />

      <JobTemplateAddEditModal
        show={showTemplateModal}
        skills={skills}
        template={selectedTemplate}
        onClose={() => setShowTemplateModal(false)}
        onSaved={(template, isEditing) => handleSaved(template, isEditing)}
      />

      <JobPositionAddEditModal
        show={showPositionModal}
        companies={companies}
        roles={roles}
        skills={skills}
        position={position}
        onClose={() => setShowPositionModal(false)}
      />

      <ConfirmModal
        title="Delete Template"
        confirmButtonText="Delete"
        show={showConfirmDelete}
        isLoading={isDeleting}
        onClose={() => setShowConfirmDelete(false)}
        onConfirm={handleConfirmDelete}
      >
        <>
          Are you sure you want to delete this Job Template? Once delete you
          won&apos;t be able to recover it.
        </>
      </ConfirmModal>

      <GoBackButton path="/job-positions" label="Job Positions" />

      <SearchBar onSearch={handleSearch} />

      <div className="ScreenTitle d-flex align-items-center justify-content-between">
        <h1>Job Templates</h1>
        <Button
          variant="secondary"
          className="AddButton"
          disabled={loadingSkills}
          onClick={handleClickCreate}
        >
          Create Template
        </Button>
      </div>

      <Card className="Card">
        <Card.Body>
          <JobTemplatesTable
            templates={filteredTemplates}
            onClickEdit={handleClickEdit}
            onClickDelete={handleClickDelete}
            onClickOpen={handleClickOpenPosition}
            onClickJobTitle={handleClickJobTitle}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default ListJobTemplates;

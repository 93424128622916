import { useState } from "react";
import PropTypes from "prop-types";
import ReactRating from "react-rating";
import { faStar as farFaStar } from "@fortawesome/free-regular-svg-icons";
import { faStar as fasFaStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Rating = (props) => {
  const { value, isEditable, isSmall, name, setValue, register } = props;

  const [rating, setRating] = useState(value);

  const ratingParams = {
    initialRating: rating,
    readonly: !isEditable,
    emptySymbol: (
      <FontAwesomeIcon
        icon={farFaStar}
        style={{
          color: "var(--blue-medium-color)",
          fontSize: !isSmall ? 20 : 15,
        }}
        className="mx-1"
      />
    ),
    fullSymbol: (
      <FontAwesomeIcon
        icon={fasFaStar}
        style={{
          color: "var(--blue-medium-color)",
          fontSize: !isSmall ? 20 : 15,
        }}
        className="mx-1"
      />
    ),
    onChange: (value) => {
      setRating(value);
      props.onSelectValueChange?.(name, value);
      setValue?.(name, value);
    },
  };

  return (
    <>
      <input
        name={name}
        type="number"
        value={rating}
        hidden
        readOnly
        {...register?.(name)}
      />
      <ReactRating
        {...ratingParams}
        style={{
          marginRight: 10,
        }}
      />
    </>
  );
};

Rating.propTypes = {
  value: PropTypes.number.isRequired,
  isEditable: PropTypes.bool,
  name: PropTypes.string,
  isSmall: PropTypes.bool,
  register: PropTypes.func,
  setValue: PropTypes.func,
  onSelectValueChange: PropTypes.func,
};

Rating.defaultValues = {
  value: 0,
  isEditing: false,
  isSmall: false,
};

export default Rating;

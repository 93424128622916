import PropTypes from "prop-types";

import BadgeList from "Components/Common/BadgeList";

import styles from "./styles/SelectedFiltersV2.module.css";

const SelectedFiltersV2 = (props) => {
  const { filterValues, variant, icon, iconPosition } = props;

  if (!filterValues.length) {
    return null;
  }

  return (
    <div className={styles.Container}>
      <span className={styles.Title}>Filter by</span>
      <BadgeList
        options={filterValues}
        label="label"
        className={styles.Badge}
        variant={variant}
        icon={icon}
        iconPosition={iconPosition}
        onClick={(option) => props.onClickDeleteBadge?.(option)}
      />
    </div>
  );
};

SelectedFiltersV2.propTypes = {
  filterValues: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    }).isRequired
  ),
  variant: PropTypes.string,
  icon: PropTypes.object,
  iconPosition: PropTypes.string,
  onClickDeleteBadge: PropTypes.func,
};

SelectedFiltersV2.defaultProps = {
  filterValues: [],
  variant: "light",
  iconPosition: "left",
};

export default SelectedFiltersV2;

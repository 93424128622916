import { useEffect } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import PositionInterestsForm from "Components/Candidate/Profile/PositionInterestsForm";
import StepButton from "Components/ProfileBuilder/StepButton";
import { useUpdateProfile } from "Hooks/useUsers";

const InterestsStep = (props) => {
  const { onSaved } = props;

  const { register, handleSubmit } = useForm({
    mode: "onChange",
  });

  const { updateProfile, isUpdating, isSuccess, status, errorMessage } =
    useUpdateProfile();

  const onSubmit = async (formValues) => {
    await updateProfile(formValues);
  };

  useEffect(() => {
    if (isSuccess) {
      onSaved?.();
    } else {
      if (status && errorMessage) {
        const toastType = status >= 500 ? "error" : "info";
        toast[toastType](errorMessage);
      }
    }
  }, [isSuccess, onSaved, status, errorMessage]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <PositionInterestsForm register={register} disabled={isUpdating} />
      <StepButton label="Continue" type="submit" disabled={isUpdating} />
    </Form>
  );
};

InterestsStep.propTypes = {
  onSaved: PropTypes.func,
};

export default InterestsStep;

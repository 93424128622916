import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";

import styles from "Components/ProfileBuilder/styles/StepButton.module.css";

const StepButton = (props) => {
  const { label, type, disabled } = props;

  return (
    <div className={styles.StepButton}>
      <Button
        variant="primary"
        type={type}
        disabled={disabled}
        onClick={props.onClick}
      >
        {label}
      </Button>
    </div>
  );
};

StepButton.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

StepButton.defaultProps = {
  label: "Continue",
  type: "button",
  disabled: false,
};

export default StepButton;

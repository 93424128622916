import PropTypes from "prop-types";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getRelativeTime } from "utils";

import styles from "Components/Job/Common/styles/JobDetails.module.css";

const JobDetails = (props) => {
  const { job, isJobHeader } = props;

  const getSubtitle = (seniority, workModel) => {
    if (seniority && workModel) {
      return (
        <>
          {seniority} &#9679; {workModel}
        </>
      );
    }

    if (seniority && !workModel) {
      return seniority;
    }

    return workModel;
  };

  return (
    <div className={`${isJobHeader ? styles.Header : ""} ${styles.Details}`}>
      <span className={styles.Title}>{job.title}</span>
      <span className={styles.Subtitle}>
        {getSubtitle(job.seniority, job.workModel)}
      </span>
      {job.employment && (
        <span className={styles.Employment}>
          <FontAwesomeIcon
            icon={faBriefcase}
            title="faBriefcase"
            className={styles.Icon}
          />
          {job.employment}
        </span>
      )}
      <span className={styles.Date}>{getRelativeTime(job.createdAt)} ago</span>
    </div>
  );
};

JobDetails.propTypes = {
  job: PropTypes.shape({
    title: PropTypes.string,
    seniority: PropTypes.string,
    employment: PropTypes.string,
    workModel: PropTypes.string,
    createdAt: PropTypes.string,
  }),
  isJobHeader: PropTypes.bool,
};

JobDetails.defaultProps = {
  isJobHeader: false,
};

export default JobDetails;

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import StepButton from "Components/ProfileBuilder/StepButton";
import { assets } from "constants.js";

import styles from "Components/ProfileBuilder/styles/WelcomeStep.module.css";

const WelcomeStep = (props) => {
  const { userName } = props;

  return (
    <>
      <Row>
        <Col md={6}>
          <span className={styles.Title}>Welcome To</span>
          <div className={styles.MiCoachLogo}>
            <img src={assets.MICOACH_LOGO} alt="MiCoach" />
          </div>
          <div className={styles.WelcomeMessage}>
            <p>
              <span>{`Hello, ${userName}!`}</span>
              <br />
              <span>
                We’re very excited that you have joined our community.
              </span>
            </p>
            <p>
              Let’s start by creating your profile with which you can access to
              valuable features based on your interests. Happy journey!
            </p>
          </div>
        </Col>
        <Col md={6} className={styles.WelcomeImage}>
          <img src={assets.WELCOME} alt="Welcome" />
        </Col>
      </Row>

      <StepButton label="Start" onClick={props.onClickNext} />
    </>
  );
};

WelcomeStep.propTypes = {
  userName: PropTypes.string,
  onClickNext: PropTypes.func,
};

WelcomeStep.defaultProps = {
  userName: "",
};

export default WelcomeStep;

import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";

import "./styles/Card.css";

const ContactCard = (props) => {
  return (
    <Card className="Card">
      <Card.Body>
        <Card.Title>{props.contact.name}</Card.Title>
        <Card.Subtitle>{props.contact.jobTitle}</Card.Subtitle>
        <hr />
        <Card.Text>
          <Image src="/assets/email.svg" className="me-2"></Image>
          {props.contact.email}
        </Card.Text>
        <Card.Text>
          <Image src="/assets/phone.svg" className="me-2"></Image>
          {props.contact.phone}
        </Card.Text>
        <hr />
        <Button
          variant="link"
          onClick={() => props.onDelete(props.contact._id)}
        >
          Delete
        </Button>
      </Card.Body>
    </Card>
  );
};

ContactCard.propTypes = {
  contact: PropTypes.object,
  onDelete: PropTypes.func,
};

export default ContactCard;

import PropTypes from "prop-types";
import { faCheck, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "Components/Common/styles/PointsBadge.module.css";

const PointsBadge = (props) => {
  const { points, completedByUser, iconVariant } = props;

  const icon = iconVariant === "check" ? faCheck : faTrophy;

  return (
    <div
      className={`${styles.Points} ${completedByUser ? styles.Complete : ""}`}
    >
      <FontAwesomeIcon className={styles.Icon} icon={icon} />
      {points > 0 && `${points} pts`}
    </div>
  );
};

PointsBadge.propTypes = {
  points: PropTypes.number,
  completedByUser: PropTypes.bool,
  iconVariant: PropTypes.string,
};

PointsBadge.defaultProps = {
  points: 0,
  completedByUser: false,
  iconVariant: "trophy",
};

export default PointsBadge;

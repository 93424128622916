import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useHistory } from "react-router-dom";

import EarnedBagdeItem from "Components/Candidate/EarnedBadge/EarnedBadgeItem";
import { useWindowSize } from "Hooks/useWindowSize";

import styles from "Components/Candidate/EarnedBadge/styles/EarnedBadgeList.module.css";

const EarnedBadgeList = (props) => {
  const { achievements, badgesPerRow } = props;

  const columnWidth = 12 / badgesPerRow;

  const [numberOfBlanks, setNumberOfBlanks] = useState(0);

  const history = useHistory();
  const windowSize = useWindowSize();

  const handleClickBadge = (badge) => {
    if (!badge) return;

    windowSize.isMobile
      ? history.push(`/achievements/${badge._id}`)
      : history.push("achievements", {
          selectedAchievement: badge,
        });
  };

  useEffect(() => {
    if (achievements.length) {
      const blanks = badgesPerRow - (achievements.length % badgesPerRow);
      setNumberOfBlanks(blanks === badgesPerRow ? 0 : blanks);
    }
  }, [achievements, badgesPerRow]);

  return (
    <Row className={styles.EarnedBadgeList}>
      {achievements.map((badge) => (
        <Col xs={columnWidth} key={badge._id}>
          <EarnedBagdeItem
            badge={badge}
            onClick={() => handleClickBadge(badge)}
          />
        </Col>
      ))}

      {Array(numberOfBlanks)
        .fill()
        .map((_, i) => (
          <Col xs={columnWidth} key={`user-badge-item-${i}`}>
            <EarnedBagdeItem isBlank />
          </Col>
        ))}
    </Row>
  );
};

EarnedBadgeList.defaultValues = {
  achievements: [],
  badgesPerRow: 4,
};

EarnedBadgeList.propTypes = {
  achievements: PropTypes.arrayOf(PropTypes.object),
  badgesPerRow: PropTypes.number,
};

export default EarnedBadgeList;

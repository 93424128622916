import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Rating from "Components/Rating";

const Competency = (props) => {
  const {
    competency,
    index,
    name,
    rating,
    comment,
    register,
    setValue,
    isEditing,
  } = props;

  const defaultCompetencies = {
    LEARNING_AGILITY: {
      name: "learningAgility",
      ratingName: "learningAgilityRating",
      label: "Learning agility",
    },
    COLLABORATION: {
      name: "collaboration",
      ratingName: "collaborationRating",
      label: "Collaboration / Relationship building",
    },
    WORK_QUALITY: {
      name: "workQuality",
      ratingName: "workQualityRating",
      label: "Detail oriented / Quality of work",
    },
  };

  const haveIndex = index >= 0;

  return (
    <Form.Group
      className="form-group Line"
      controlId={
        haveIndex
          ? `competencies.${index}.name`
          : defaultCompetencies[name]?.name
      }
    >
      <Form.Label className="d-inline">
        <Row className="mb-3">
          <Col>
            <span>{competency?.name ?? defaultCompetencies[name]?.label}</span>
          </Col>
          <Col className="text-end">
            <Rating
              name={
                haveIndex
                  ? `competencies.${index}.rating`
                  : defaultCompetencies[name]?.ratingName
              }
              isEditable={isEditing}
              value={competency?.rating ?? rating ?? 0}
              setValue={setValue}
              register={register}
            />
          </Col>
        </Row>
      </Form.Label>

      {isEditing ? (
        <>
          {haveIndex && (
            <input
              hidden
              readOnly
              value={competency?.name}
              name={`competencies.${index}.name`}
              {...register?.(`competencies.${index}.name`)}
            />
          )}

          <div className="d-flex">
            <Form.Control
              name={
                haveIndex
                  ? `competencies.${index}.comment`
                  : defaultCompetencies[name]?.name
              }
              type="text"
              as="textarea"
              className="h-100 fs-13"
              rows={3}
              defaultValue={competency?.comment ?? comment}
              {...register?.(
                haveIndex
                  ? `competencies.${index}.comment`
                  : defaultCompetencies[name]?.name
              )}
            />

            {haveIndex && (
              <Button variant="link" onClick={props.onClickDelete}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            )}
          </div>
        </>
      ) : (
        <p>{competency?.comment ?? comment}</p>
      )}
    </Form.Group>
  );
};

Competency.propTypes = {
  // For new competencies
  competency: PropTypes.object,
  index: PropTypes.number,
  onClickDelete: PropTypes.func,

  // For default or static competencies
  name: PropTypes.string,
  rating: PropTypes.number,
  comment: PropTypes.string,

  isEditing: PropTypes.bool,
  setValue: PropTypes.func,
  register: PropTypes.func.isRequired,
};

Competency.defaultValues = {
  isEditing: false,
};

export default Competency;

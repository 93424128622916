import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import micoach, { UserApi } from "micoach-api";

import LanguageProficiencyHelpModal from "Components/Candidate/Profile/LanguageProficiencyHelpModal";
import { useWindowSize } from "Hooks/useWindowSize";

const formInitialState = { language: null, proficiency: null };

const LanguageModal = (props) => {
  const { language, languageList, show } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: formInitialState,
  });

  const windowSize = useWindowSize();

  const onSubmit = async (formValues) => {
    const newLanguage = {
      language: formValues.language.value,
      proficiency: formValues.proficiency.value,
    };
    try {
      setIsSaving(true);
      const updatedList = [...languageList];
      if (language) {
        const index = languageList.findIndex(
          (lang) => lang._id === language._id
        );
        updatedList[index] = {
          ...newLanguage,
        };
      } else {
        updatedList.push({ ...newLanguage });
      }

      const response = await UserApi.updateProfile({
        languages: updatedList,
      });

      props.onSaved?.(response.data);
      props.onClose?.();
      toast.success(
        `Success! The language has been ${language ? "updated" : "added"}.`
      );
    } catch (error) {
      console.error(error);
      toast.error("Error, please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  const languageOptions = () => {
    return [
      { value: "English", label: "English" },
      { value: "Chinese", label: "Chinese" },
      { value: "Spanish", label: "Spanish" },
      { value: "Arabic", label: "Arabic" },
      { value: "French", label: "French" },
      { value: "Japanese", label: "Japanese" },
      { value: "Hindi", label: "Hindi" },
      { value: "German", label: "German" },
      { value: "Russian", label: "Russian" },
      { value: "Portuguese", label: "Portuguese" },
      { value: "Dutch", label: "Dutch" },
      { value: "Polish", label: "Polish" },
      { value: "Italian", label: "Italian" },
    ];
  };

  const proficiencyOptions = () => {
    return Object.values(micoach.ProficiencyEnum).map((level) => {
      return { value: level, label: level };
    });
  };

  useEffect(() => {
    if (show) {
      if (language) {
        reset({
          language: { value: language.language, label: language.language },
          proficiency: {
            value: language.proficiency,
            label: language.proficiency,
          },
        });
      }
    } else {
      reset(formInitialState);
    }
  }, [language, show, reset]);

  return (
    <>
      <LanguageProficiencyHelpModal
        show={showHelpModal}
        onClose={() => setShowHelpModal(false)}
      />

      <Modal show={show} onHide={props.onClose}>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Modal.Header closeButton>
            <Modal.Title>Language</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="light">
              <span className="fs-14">
                <FontAwesomeIcon
                  style={{
                    color: "var(--blue-light-color)",
                    verticalAlign: "middle",
                  }}
                  icon={faInfoCircle}
                />
                See our
                <span
                  className="btn-link"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowHelpModal(true)}
                >
                  {` language proficiency levels reference `}
                </span>
                for a better assessment.
              </span>
            </Alert>
            <Form.Group controlId="language" className="form-group required">
              <Form.Label>Language</Form.Label>
              <Controller
                name="language"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { value, onChange } }) => (
                  <Select
                    options={languageOptions()}
                    value={value}
                    isClearable
                    classNamePrefix="ReactSelect"
                    onChange={onChange}
                  />
                )}
              />
              {errors.language && (
                <div className="invalid-feedback d-block">
                  {errors.language.type === "required" &&
                    "Language is required"}
                </div>
              )}
            </Form.Group>

            <Form.Group controlId="proficiency" className="form-group required">
              <Form.Label>Proficiency</Form.Label>
              <Controller
                name="proficiency"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { value, onChange } }) => (
                  <Select
                    options={proficiencyOptions()}
                    value={value}
                    isClearable
                    classNamePrefix="ReactSelect"
                    onChange={onChange}
                  />
                )}
              />
              {errors.proficiency && (
                <div className="invalid-feedback d-block">
                  {errors.proficiency.type === "required" &&
                    "Proficiency is required"}
                </div>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {windowSize.isMobile ? (
              <div className="d-grid gap-2 w-100 ps-3 pe-3">
                <Button
                  variant="secondary"
                  size="lg"
                  onClick={props.onClose}
                  disabled={isSaving}
                >
                  Cancel
                </Button>
                <Button size="lg" type="submit" disabled={isSaving}>
                  Save language
                </Button>
              </div>
            ) : (
              <>
                <Button
                  variant="secondary"
                  onClick={props.onClose}
                  disabled={isSaving}
                >
                  Cancel
                </Button>
                <Button type="submit" disabled={isSaving}>
                  Save language
                </Button>
              </>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

LanguageModal.propTypes = {
  language: PropTypes.object,
  languageList: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSaved: PropTypes.func,
};

LanguageModal.defaultProps = {
  languageList: [],
};

export default LanguageModal;

import { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useKeycloak } from "@react-keycloak/web";
import { faArrowLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import micoach, { TaskApi } from "micoach-api";

import SelectedFiltersV2 from "Components/Common/SelectedFiltersV2";
import SortButton from "Components/Common/SortButton";
import StateCard from "Components/Common/StateCard";
import Loading from "Components/Loading";
import TaskCard from "Components/Task/TaskCard";
import TaskDetailCard from "Components/Task/TaskDetailCard";
import TaskFilterButton from "Components/Task/TaskFilterButton";
import UnreadTasksContext from "Context/UnreadTasksProvider";
import { useFilterTasks, useSelectedFilters, useTasks } from "Hooks/useTask";
import { useWindowSize } from "Hooks/useWindowSize";
import { assets } from "constants.js";

const ListTasks = () => {
  const [selectedTask, setSelectedTask] = useState();
  const [coaches, setCoaches] = useState([]);
  const [filters, setFilters] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [filteredByTypeTasks, setFilteredByTypeTasks] = useState([]);

  const windowSize = useWindowSize();
  const [height, setHeight] = useState(window.innerHeight - 280);

  const updateHeight = () => {
    setHeight(window.innerHeight - 280);
  };

  const unreadTasksContext = useContext(UnreadTasksContext);

  const { keycloak } = useKeycloak();
  const userId = keycloak?.tokenParsed?.sub;

  const { tasks, loadingTasks, setTasks } = useTasks({
    userId,
  });

  const { selectedFilters } = useSelectedFilters(filters);
  const { filteredTasks } = useFilterTasks(
    filteredByTypeTasks,
    filters,
    sortOrder
  );

  const handleAppliedFilters = (newFilters) => {
    setFilters(newFilters);
    setSelectedTask(null);
  };

  const handleClickDeleteBadge = (option) => {
    const newFilters = { ...filters };

    if (newFilters[option.name]) {
      delete newFilters[option.name];
    }

    handleAppliedFilters(newFilters);
  };

  const handleClickSort = (_, sortOrder) => {
    setSortOrder(sortOrder);
  };

  const handleUpdatedTask = (updatedTask) => {
    const updatedTasks = [...tasks];

    const taskIndex = updatedTasks.findIndex(
      (task) => task._id === updatedTask._id
    );

    if (taskIndex !== -1) {
      updatedTasks[taskIndex] = updatedTask;

      setTasks(updatedTasks);
      setSelectedTask(updatedTasks[taskIndex]);
    }
  };

  const handleClickTask = async (clickedTask) => {
    try {
      if (!clickedTask.read) {
        unreadTasksContext.setUnreadTasks(
          (prevUnreadTasks) => prevUnreadTasks - 1
        );

        const { data } = await TaskApi.readTask(clickedTask._id);

        handleUpdatedTask(data);
      } else {
        setSelectedTask(clickedTask);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickTaskDetail = async (taskId) => {
    try {
      await TaskApi.readComments(taskId);

      const taskIndex = tasks.findIndex((task) => task._id === taskId);

      if (taskIndex !== -1) {
        const updatedTasks = [...tasks];

        updatedTasks[taskIndex] = {
          ...updatedTasks[taskIndex],
          hasNewCoachComments: false,
        };

        setTasks(updatedTasks);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  });

  useEffect(() => {
    const uniqueCoaches = [
      ...new Map(
        [...tasks].map((task) => [task.metadata?.coachName, task])
      ).values(),
    ].map((task) => ({
      label: task.metadata?.coachName,
      value: task.metadata?.coachName,
    }));

    setCoaches(uniqueCoaches);
  }, [tasks]);

  useEffect(() => {
    setFilteredByTypeTasks(
      tasks?.filter((task) => task.type !== micoach.TaskTypeEnum.WatchLater)
    );
  }, [tasks]);

  if (loadingTasks) {
    return <Loading />;
  }

  return (
    <div>
      <h1 className="ScreenTitle">My tasks</h1>
      <div className="d-flex justify-content-between">
        <SortButton
          items={filteredTasks}
          property="createdAt"
          onClick={handleClickSort}
        />
        <TaskFilterButton
          filterValues={filters}
          coaches={coaches}
          isMobile={windowSize.isMobile}
          onApplyFilters={handleAppliedFilters}
        />
      </div>
      {!windowSize.isMobile && (
        <div>
          <SelectedFiltersV2
            filterValues={selectedFilters}
            icon={faTimes}
            iconPosition="right"
            onClickDeleteBadge={handleClickDeleteBadge}
          />
        </div>
      )}

      {!filteredTasks.length ? (
        <StateCard
          imageUrl={assets.ZERO_STATE}
          title="You don't have any tasks yet!"
        />
      ) : (
        <Row>
          {(!windowSize.isMobile || (windowSize.isMobile && !selectedTask)) && (
            <Col
              md={5}
              style={{
                height,
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              {filteredTasks.map((task) => {
                return (
                  <TaskCard
                    key={task._id}
                    task={task}
                    selected={selectedTask?._id === task._id}
                    onClick={handleClickTask}
                  />
                );
              })}
            </Col>
          )}
          {(!windowSize.isMobile || (windowSize.isMobile && selectedTask)) && (
            <Col
              md={7}
              className="d-md-inline"
              style={{
                height,
                overflow: "auto",
              }}
            >
              {windowSize.isMobile && (
                <Button
                  variant="link"
                  className={`px-0 py-3`}
                  onClick={() => setSelectedTask()}
                >
                  <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                  Go Back
                </Button>
              )}
              <TaskDetailCard
                task={selectedTask}
                userName={keycloak.tokenParsed.name}
                onClick={handleClickTaskDetail}
                onUpdated={handleUpdatedTask}
              />
            </Col>
          )}
        </Row>
      )}
    </div>
  );
};

export default ListTasks;

import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";

import FormattedDate from "Components/FormattedDate";
import { useWindowSize } from "Hooks/useWindowSize";

import "Components/Candidate/styles/AchievementCard.css";

function AchievementCard(props) {
  const { achievement, isSelected, seeCertificate } = props;

  const windowSize = useWindowSize();

  return (
    <Card
      className={`Card AchievementCard ${
        isSelected && !windowSize.isMobile && "ActiveCard"
      }`}
      onClick={() => {
        if (props.onClick) {
          props.onClick(achievement);
        }
      }}
    >
      <Card.Body>
        <Row>
          {achievement.companyLogoUrl && (
            <Col xs={3} className="CompanyLogo">
              <Image src={achievement.companyLogoUrl} alt="Company logo" />
            </Col>
          )}
          <Col>
            <Row>
              <Col xs={12} className="AchievementDate">
                <FormattedDate>{achievement.earnedDate}</FormattedDate>
              </Col>
            </Row>

            <h2 className="AchievementName">{achievement.name}</h2>

            {seeCertificate && (
              <span className="GetCertificate btn-link">Get certificate</span>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

AchievementCard.propTypes = {
  achievement: PropTypes.shape({
    name: PropTypes.string.isRequired,
    earnedDate: PropTypes.string.isRequired,
    companyLogoUrl: PropTypes.string,
  }),
  isSelected: PropTypes.bool,
  seeCertificate: PropTypes.bool,
  onClick: PropTypes.func,
};

export default AchievementCard;

import PropTypes from "prop-types";

import { getFormattedTimeZoneDate } from "utils.js";

import styles from "Components/Interview/Event/styles/EventPanelistsList.module.css";

const EventPanelistsList = (props) => {
  const { panelists } = props;

  return (
    <ul className={styles.Panelists}>
      {panelists.map((panelist) => {
        return (
          <li key={panelist.email}>
            {panelist.startDate && panelist.endDate
              ? `${getFormattedTimeZoneDate(
                  panelist.startDate,
                  "h:mm a"
                )} - ${getFormattedTimeZoneDate(panelist.endDate, "h:mm a")}, ${
                  panelist.email
                }`
              : `${panelist.email}`}
          </li>
        );
      })}
    </ul>
  );
};

EventPanelistsList.propTypes = {
  panelists: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      email: PropTypes.string,
    })
  ),
};

EventPanelistsList.defaultProps = {
  panelists: [],
};

export default EventPanelistsList;

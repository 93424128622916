import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import micoach from "micoach-api";

import { getRoles } from "utils.js";

import styles from "Screens/Report/styles/ListReports.module.css";

const ListReports = () => {
  const { keycloak } = useKeycloak();

  const userRoles = getRoles(keycloak);

  return (
    <>
      <h1 className="ScreenTitle">Hiring process reports</h1>
      <Row className={styles.Rows}>
        {userRoles?.includes(micoach.UserRoleEnum.Recruiter) && (
          <>
            <Link to={"/reports/application-process/history"}>
              <Card className={`Card CardShadow ${styles.ReportCard}`}>
                <Card.Body>
                  <Card.Title className={styles.Title}>History</Card.Title>
                  <Card.Text className={styles.Description}>
                    Lists candidates hiring processes history, started in the
                    selected month (Includes recruiter & steps).
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to={"/reports/application-process/company"}>
              <Card className={`Card CardShadow ${styles.ReportCard}`}>
                <Card.Body>
                  <Card.Title className={styles.Title}>Companies</Card.Title>
                  <Card.Text className={styles.Description}>
                    Lists positions with the candidate count by hiring process
                    step from the selected company.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to={"/reports/application-process/candidate"}>
              <Card className={`Card CardShadow ${styles.ReportCard}`}>
                <Card.Body>
                  <Card.Title className={styles.Title}>
                    TA candidates
                  </Card.Title>
                  <Card.Text className={styles.Description}>
                    Lists candidate totals for each step for the selected
                    company, recruiter and date range.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to={"/reports/referred-candidates"}>
              <Card className={`Card CardShadow ${styles.ReportCard}`}>
                <Card.Body>
                  <Card.Title className={styles.Title}>Referrals</Card.Title>
                  <Card.Text className={styles.Description}>
                    Lists referred candidates and referred by, created between
                    the selected dates.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </>
        )}

        <Link to={"/reports/candidate-become-employee"}>
          <Card className={`Card CardShadow ${styles.ReportCard}`}>
            <Card.Body>
              <Card.Title className={styles.Title}>Human resources</Card.Title>
              <Card.Text className={styles.Description}>
                Lists candidates becoming employees information with first day
                of work and referred by.
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
      </Row>
    </>
  );
};

export default ListReports;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { UserApi } from "micoach-api";
import { isDateAfter } from "utils";

import { useWindowSize } from "Hooks/useWindowSize";
import { URL_PATTERN } from "constants.js";

const formInitialState = {
  name: "",
  institution: "",
  expeditionDate: null,
  expirationDate: null,
  doNotExpire: false,
  certificationId: "",
  certificationUrl: "",
};

const CertificationModal = (props) => {
  const { certification, certificationsList, show } = props;
  const [isSaving, setIsSaving] = useState(false);

  const windowSize = useWindowSize();
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: formInitialState,
  });

  const today = new Date();
  const expirationDate = watch("expirationDate");
  const expeditionDate = watch("expeditionDate");
  const doNotExpire = watch("doNotExpire", false);

  const onSubmit = async (formValues) => {
    const hasExpiration = !formValues.doNotExpire;
    const cert = {
      ...formValues,
      hasExpiration,
      expirationDate: doNotExpire
        ? undefined
        : formValues.expirationDate.toISOString(),
      expeditionDate: formValues.expeditionDate.toISOString(),
    };

    try {
      setIsSaving(true);
      const updatedList = [...certificationsList];

      if (certification) {
        const index = certificationsList.findIndex(
          (cert) => cert._id === certification._id
        );
        updatedList[index] = cert;
      } else {
        updatedList.push(cert);
      }

      const response = await UserApi.updateProfile({
        certifications: updatedList,
      });

      props.onSaved?.(response.data);
      props.onClose?.();
      toast.success(
        `Success! The certification has been ${
          certification ? "updated" : "added"
        }.`
      );
    } catch (error) {
      console.error(error);
      toast.error("Error, please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (isDateAfter(expeditionDate, expirationDate)) {
      setValue("expirationDate", null);
    }
  }, [expeditionDate, expirationDate, setValue]);

  useEffect(() => {
    if (show) {
      if (certification) {
        const expeditionDate = certification.expeditionDate
          ? new Date(certification.expeditionDate)
          : null;
        const expirationDate = certification.expirationDate
          ? new Date(certification.expirationDate)
          : null;

        reset({
          ...certification,
          doNotExpire: !certification.hasExpiration,
          expeditionDate,
          expirationDate,
        });
      }
    } else {
      reset(formInitialState);
    }
  }, [certification, show, reset]);

  return (
    <Modal show={show} onHide={props.onClose}>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Header closeButton>
          <Modal.Title>Certification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="doNotExpire" className="form-group">
            <Form.Check
              type="checkbox"
              defaultChecked={false}
              label={
                <span className="fs-13 fw-bold">
                  This certification does not expire
                </span>
              }
              {...register("doNotExpire")}
            />
          </Form.Group>

          <Form.Group controlId="name" className="form-group required">
            <Form.Label>Certification name</Form.Label>
            <Form.Control
              type="text"
              maxLength={100}
              placeholder="e.g., Microsoft Certified Network"
              {...register("name", { required: true, maxLength: 100 })}
              isInvalid={!!errors.name}
            />
            {errors.name && (
              <div className="invalid-feedback d-block">
                {errors.name.type === "required" &&
                  "Certification name is required"}
                {errors.name.type === "maxLength" && "Max length exceeded"}
              </div>
            )}
          </Form.Group>
          <Form.Group controlId="institution" className="form-group required">
            <Form.Label>Institute</Form.Label>
            <Form.Control
              type="text"
              name="institution"
              maxLength={100}
              placeholder="e.g., Cambridge University"
              {...register("institution", { required: true, maxLength: 100 })}
              isInvalid={!!errors.institution}
            />
            {errors.institution && (
              <div className="invalid-feedback d-block">
                {errors.institution.type === "required" &&
                  "Institute is required"}
                {errors.institution.type === "maxLength" &&
                  "Max length exceeded"}
              </div>
            )}
          </Form.Group>

          <div className="row">
            <Form.Group
              controlId="expeditionDate"
              className="form-group required col-6  pe-1"
            >
              <Form.Label>Expedition date</Form.Label>
              <Controller
                name="expeditionDate"
                control={control}
                defaultValue={null}
                rules={{
                  required: true,
                }}
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    showPopperArrow={false}
                    selected={value}
                    onChange={onChange}
                    dateFormat="MMM, yyyy"
                    placeholderText="Select a date"
                    showMonthYearPicker
                    endDate={today}
                    maxDate={today}
                    className={`form-control ${
                      errors.expeditionDate && "is-invalid"
                    }`}
                  />
                )}
              />
              {errors.expeditionDate && (
                <div className="invalid-feedback d-block">
                  {errors.expeditionDate.type === "required" &&
                    "Expedition date is required"}
                </div>
              )}
            </Form.Group>

            <Form.Group
              controlId="expirationDate"
              className="form-group required col-6 ps-1"
            >
              {!doNotExpire && (
                <>
                  <Form.Label>Expiration date</Form.Label>
                  <Controller
                    name="expirationDate"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    defaultValue={null}
                    render={({ field: { value, onChange } }) => (
                      <DatePicker
                        showPopperArrow={false}
                        selected={value}
                        onChange={onChange}
                        dateFormat="MMM, yyyy"
                        showMonthYearPicker
                        startDate={expeditionDate}
                        minDate={expeditionDate}
                        className={`form-control ${
                          errors.expirationDate && "is-invalid"
                        }`}
                        placeholderText="Select a date"
                      />
                    )}
                  />
                  {errors.expirationDate && (
                    <div className="invalid-feedback d-block">
                      {errors.expirationDate.type === "required" &&
                        "Expiration date is required"}
                    </div>
                  )}
                </>
              )}
            </Form.Group>
          </div>

          <Form.Group controlId="certificationId" className="form-group">
            <Form.Label>Certification ID</Form.Label>
            <Form.Control
              type="text"
              name="certificationId"
              maxLength={100}
              {...register("certificationId", { maxLength: 100 })}
              isInvalid={!!errors.certificationId}
            />
            {errors.certificationId && (
              <div className="invalid-feedback d-block">
                {errors.certificationId.type === "maxLength" &&
                  "Max length exceeded"}
              </div>
            )}
          </Form.Group>

          <Form.Group controlId="certificationUrl" className="form-group">
            <Form.Label>Certification URL</Form.Label>
            <Form.Control
              type="text"
              maxLength={250}
              name="certificationUrl"
              {...register("certificationUrl", {
                maxLength: 250,
                pattern: URL_PATTERN,
              })}
              isInvalid={!!errors.certificationUrl}
            />
            {errors.certificationUrl && (
              <div className="invalid-feedback d-block">
                {errors.certificationUrl.type === "maxLength" &&
                  "Max length exceeded"}
                {errors.certificationUrl.type === "pattern" &&
                  "Certification URL must be a valid URL"}
              </div>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {windowSize.isMobile ? (
            <div className="d-grid gap-2 w-100 ps-3 pe-3">
              <Button
                variant="secondary"
                size="lg"
                onClick={props.onClose}
                disabled={isSaving}
              >
                Cancel
              </Button>
              <Button size="lg" type="submit" disabled={isSaving}>
                Save certification
              </Button>
            </div>
          ) : (
            <>
              <Button
                variant="secondary"
                onClick={props.onClose}
                disabled={isSaving}
              >
                Cancel
              </Button>
              <Button type="submit" disabled={isSaving}>
                Save certification
              </Button>
            </>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

CertificationModal.propTypes = {
  certification: PropTypes.object,
  certificationsList: PropTypes.array,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSaved: PropTypes.func,
};

export default CertificationModal;

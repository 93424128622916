/* eslint-disable simple-import-sort/imports */
import React from "react";
import ReactDOM from "react-dom";

import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";

import NotificationsContextProvider from "Context/NotificationsProvider";
import UserProfileContextProvider from "Context/UserProfileProvider";
import { UnreadTasksContextProvider } from "Context/UnreadTasksProvider";

import "bootstrap/dist/css/bootstrap.min.css";
import "./fonts.css";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import App from "./App";
import { SWRConfig } from "swr";
import { handleOnError } from "utils";
import { ToastContainer, toast } from "react-toastify";

TagManager.initialize({
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
});

ReactDOM.render(
  <React.StrictMode>
    <ToastContainer
      theme="colored"
      position={toast.POSITION.TOP_RIGHT}
      icon={false}
      enableMultiContainer
    />
    <ReactKeycloakProvider authClient={keycloak}>
      <SWRConfig
        value={{
          errorRetryCount: 3,
          onError: handleOnError,
        }}
      >
        <NotificationsContextProvider>
          <UserProfileContextProvider>
            <BrowserRouter>
              <UnreadTasksContextProvider>
                <App />
              </UnreadTasksContextProvider>
            </BrowserRouter>
          </UserProfileContextProvider>
        </NotificationsContextProvider>
      </SWRConfig>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

import PropTypes from "prop-types";

import { getAbbreviatedNumberFormat } from "utils.js";

import styles from "Components/Refer/styles/ReferralsOverviewSummaryItem.module.css";

const ReferralsOverviewSummaryItem = (props) => {
  const { value, title } = props;

  return (
    <div className={styles.Item}>
      <span className={`${styles.Value} ${value === 0 ? styles.Zero : ""}`}>
        {getAbbreviatedNumberFormat(value)}
      </span>
      <span className={styles.Title}>{title}</span>
    </div>
  );
};

ReferralsOverviewSummaryItem.propTypes = {
  value: PropTypes.number,
  title: PropTypes.string,
};

ReferralsOverviewSummaryItem.defaultProps = {
  value: 0,
};

export default ReferralsOverviewSummaryItem;

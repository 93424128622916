import { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { Controller } from "react-hook-form";
import Select from "react-select";
import useSWR from "swr";

import FormControlErrorMessage from "Components/Common/FormControlErrorMessage";
import { API, CATALOG_TYPES } from "constants.js";
import { fetcher, sortByProperty } from "utils.js";

const TagSelectInput = (props) => {
  const {
    name = "tagSelect",
    defaultTags = [],
    isRequired = false,
    formProps: { control, setValue, errors },
  } = props;

  const { data: catalogs, isValidating: loadingCatalogs } = useSWR(
    [API.PUBLIC_CATALOG, { type: CATALOG_TYPES.TAG }],
    (path) => fetcher(path),
    {
      revalidateOnFocus: false,
    }
  );

  const getDefaultOptions = useCallback((defaultTags, tags) => {
    return tags.filter((tag) => defaultTags.includes(tag._id));
  }, []);

  useEffect(() => {
    if (catalogs?.data && defaultTags.length) {
      const defaultOptions = getDefaultOptions(defaultTags, catalogs.data);

      setValue(name, defaultOptions);
    }
  }, [catalogs?.data, defaultTags, name, setValue, getDefaultOptions]);

  return (
    <Form.Group
      controlId={name}
      className={`form-group ${isRequired ? "required" : ""}`}
    >
      <Form.Label>Tags</Form.Label>
      <Controller
        name={name}
        control={control}
        rules={{
          required: isRequired,
        }}
        render={({ field: { value, onChange } }) => (
          <Select
            name={name}
            isMulti
            isClearable
            value={value}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.key}
            aria-label={name}
            isLoading={loadingCatalogs}
            options={sortByProperty(catalogs?.data ?? [], "label", "asc")}
            classNamePrefix="ReactSelect"
            className={errors?.[name] ? "is-invalid" : ""}
            onChange={onChange}
          />
        )}
      />
      <FormControlErrorMessage
        show={errors?.[name]?.type === "required"}
        message="At least one tag is required"
      />
    </Form.Group>
  );
};

TagSelectInput.propTypes = {
  name: PropTypes.string,
  defaultTags: PropTypes.arrayOf(PropTypes.string),
  isRequired: PropTypes.bool,
  formProps: PropTypes.shape({
    control: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    errors: PropTypes.object,
  }),
};

export default TagSelectInput;

import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import {
  faEnvelope,
  faGraduationCap,
  faPen,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Address from "Components/Address";
import BadgeList from "Components/Common/BadgeList";
import FormattedDate from "Components/FormattedDate";
import SourceImage from "Components/SourceImage";
import { applicationProcessStatus, skillCategories } from "constants.js";
import { filterSkills } from "utils.js";

import "Components/styles/CandidateViewInfo.css";

const CandidateViewInfo = (props) => {
  const { candidate, isEditable } = props;

  const isApplicationProcessActive =
    candidate?.applicationProcess?.status === applicationProcessStatus.active;
  const isReferral = candidate?.source === "REFERRAL";

  // Skills
  const hardSkills = filterSkills(candidate.skills, skillCategories.HARD.key);
  const softSkills = filterSkills(candidate.skills, skillCategories.SOFT.key);
  const techSkills = filterSkills(candidate.skills, skillCategories.TECH.key);

  const renderPositionTypes = (profile) => {
    const positionTypes = [];
    profile?.fullTime && positionTypes.push("Full-time");
    profile?.partTime && positionTypes.push("Part-time");
    profile?.onSite && positionTypes.push("On-site");
    profile?.remote && positionTypes.push("Remote");
    profile?.isWillingToRelocate && positionTypes.push("Open to relocate");
    profile?.isWillingToTravel && positionTypes.push("Open to travel");

    return positionTypes.join(", ");
  };

  return (
    <>
      <Card className="Card CandidateView Editable CandidateViewInfo">
        <Card.Body>
          <Row>
            <Col>
              <div className="CandidateSource">
                <SourceImage source={candidate.source} />
                {candidate.isTrainee && (
                  <div>
                    <FontAwesomeIcon
                      icon={faGraduationCap}
                      className="ms-2"
                      title="Trainee"
                    />
                  </div>
                )}
                <h1 className="ms-2">{candidate.name}</h1>
              </div>
              {isReferral && <>Referred by - {candidate?.referrerEmail}</>}
            </Col>

            <Col className="text-end fs-12" md="auto">
              <Card.Subtitle className="d-inline">
                {candidate.role}
                {isApplicationProcessActive && (
                  <>
                    {" | "}
                    <FormattedDate>
                      {candidate.applicationProcess?.applicationDate}
                    </FormattedDate>
                  </>
                )}
              </Card.Subtitle>
            </Col>
          </Row>

          <Row>
            <Col>
              {isApplicationProcessActive &&
                candidate.applicationProcess?.position &&
                `${candidate.applicationProcess?.position?.title} | ${candidate.applicationProcess?.position?.company?.name}`}
            </Col>

            {isApplicationProcessActive &&
              candidate.applicationProcess?.position && (
                <Col className="text-end" md={3} lg={6}>
                  <Image
                    className="CompanyLogo"
                    src={candidate.applicationProcess?.position?.company?.logo}
                  />
                </Col>
              )}
          </Row>

          <hr />

          <Row>
            <Col xs={12} sm={6}>
              <div className="Email">
                <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                {candidate.email}
              </div>
              {candidate.secondaryEmail && (
                <div className="SecondaryEmail">
                  <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                  {candidate.secondaryEmail}
                </div>
              )}
            </Col>

            <Col xs={12} sm={6}>
              <>
                <FontAwesomeIcon icon={faPhoneAlt} className="me-2" />
                {candidate?.callingCode} {candidate?.phone}
              </>
            </Col>
          </Row>

          <>
            <Address
              isEditing={false}
              address={candidate?.address}
              formProps={{}}
            />
          </>

          <hr />

          <>
            <Card.Title className="Title">Skills</Card.Title>
            <Card.Text className="Subtitle">Hard</Card.Text>
            <BadgeList
              variant="light"
              textColor="dark"
              options={hardSkills}
              label="name"
            />
            <Card.Text className="Subtitle">Soft</Card.Text>
            <BadgeList
              variant="light"
              textColor="dark"
              options={softSkills}
              label="name"
            />
            <Card.Text className="Subtitle">Technical</Card.Text>
            <BadgeList
              variant="light"
              textColor="dark"
              options={techSkills}
              label="name"
            />
            <hr />

            <Card.Title className="Title">Interests</Card.Title>
            <Card.Text className="Subtitle">Industry</Card.Text>
            <BadgeList
              variant="light"
              textColor="dark"
              options={candidate.industryInterests}
              label="name"
            />
            <Card.Text className="Subtitle">Job roles</Card.Text>
            <BadgeList
              variant="light"
              textColor="dark"
              options={candidate.roleInterests}
              label="name"
            />
            <div className="mt-3">
              <span className="Subtitle">Position type:&nbsp;</span>
              <span className="Text">{renderPositionTypes(candidate)}</span>
            </div>
            <hr />

            <Card.Title className="Title">Certifications</Card.Title>
            <BadgeList
              variant="light"
              textColor="dark"
              options={candidate.events}
              label="name"
            />
            <hr />
          </>

          <>
            <h2 className="BlueText pb-2">Highlights</h2>
            <p className="fs-13 WordWrap">{candidate.highlights}</p>
          </>

          {isEditable && (
            <div className="text-end">
              <Button
                variant="outline-primary"
                className="EditButton mt-2"
                onClick={props.onEdit}
              >
                <FontAwesomeIcon icon={faPen}></FontAwesomeIcon>
              </Button>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

CandidateViewInfo.propTypes = {
  candidate: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  onEdit: PropTypes.func,
};

export default CandidateViewInfo;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { CompanyApi, FileApi } from "micoach-api";

import DocumentItem from "Components/Common/DocumentItem";
import DragDrop from "Components/Common/DragDrop";
import S3Service from "Services/S3Service";

import styles from "Components/Company/styles/CompanyModal.module.css";

const CompanyModal = (props) => {
  const { show, company, isEditing } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [fileObject, setFileObject] = useState(null);
  const [preview, setPreview] = useState(null);
  const LOGO_MAX_SIZE = 1;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  // Form handlers
  const handleClose = () => {
    setFileObject(null);
    setPreview(null);
    props.onClose?.();
  };

  const onSubmit = async (formValues) => {
    let companyData = {
      name: formValues.name,
    };

    setIsSaving(true);
    try {
      if (fileObject?.size) {
        const { data: file } = await FileApi.createFile({
          name: fileObject.name,
          contentType: fileObject.type,
          size: fileObject.size,
          isPublic: true,
        });
        companyData.fileKey = file.key;

        await S3Service.uploadFileToS3(file.uploadUrl, fileObject);
      }

      if (isEditing) {
        companyData = await CompanyApi.updateCompany(company._id, companyData);
      } else {
        companyData = await CompanyApi.createCompany(companyData);
      }

      props.onSaved?.(companyData);
      toast.success(
        `Success! The company has been ${isEditing ? "updated" : "created"}.`
      );
      handleClose();
    } catch (error) {
      console.error(error);
      toast.error(
        `Error! The company was not ${isEditing ? "updated" : "created"}.`
      );
    } finally {
      setIsSaving(false);
    }
  };

  // DragDrop handlers
  const handleDropFile = (acceptedFiles) => {
    if (acceptedFiles?.length) {
      const file = acceptedFiles[0];

      setFileObject(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  const handleDeleteFile = () => {
    setFileObject(null);
    setPreview(null);
  };

  useEffect(() => {
    if (show && company) {
      const companyData = { name: company.name };

      reset(companyData);
      setPreview(company.logo);
      setFileObject({ ...companyData, type: "image/png" });
    } else {
      reset({ name: "" });
      handleDeleteFile();
    }
  }, [show, company, reset]);

  return (
    <Modal backdrop="static" show={show} onHide={props.onClose}>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? "Edit" : "Add"} company</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group controlId="name" className="form-group required">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              disabled={isSaving}
              isInvalid={!!errors.name}
              {...register("name", { required: true })}
            />
            {errors.name && (
              <div className="invalid-feedback d-block">
                {errors.name.type === "required" && "Name is required"}
              </div>
            )}
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label>Upload logo</Form.Label>
            {!fileObject && (
              <DragDrop
                allowedExtensions={["png", "jpg", "jpeg"]}
                maxSize={LOGO_MAX_SIZE}
                onDrop={handleDropFile}
              />
            )}

            {preview && (
              <div className={styles.Preview}>
                <img src={preview} alt="Company logo" />
              </div>
            )}

            {fileObject && (
              <DocumentItem
                document={fileObject}
                isSaving={isSaving}
                onDelete={handleDeleteFile}
              />
            )}
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="CancelButton"
            disabled={isSaving}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button type="submit" className="SaveButton" disabled={isSaving}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

CompanyModal.propTypes = {
  show: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool,
  company: PropTypes.object,
  onClose: PropTypes.func,
  onSaved: PropTypes.func,
};

export default CompanyModal;

import PropTypes from "prop-types";

import JobDetails from "Components/Job/Common/JobDetails";
import { assets } from "constants.js";

import styles from "Components/Job/styles/JobItem.module.css";

const JobItem = (props) => {
  const { job, isSelected } = props;

  if (!job) {
    return null;
  }

  return (
    <div
      data-testid="jobItem"
      className={`${styles.JobItem} ${isSelected ? styles.Selected : ""}`}
      onClick={() => {
        props.setActiveJob?.(job.slug);
      }}
    >
      <div>
        <img
          className={styles.JobIcon}
          data-testid="jobIcon"
          alt="Other"
          src={assets.jobCategories.OTHER}
        />
      </div>
      <JobDetails job={job} />
    </div>
  );
};

JobItem.propTypes = {
  job: PropTypes.object,
  isSelected: PropTypes.bool,
  setActiveJob: PropTypes.func,
};

export default JobItem;

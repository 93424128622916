import PropTypes from "prop-types";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import { Controller } from "react-hook-form";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { getZonedDate } from "utils";

import FormControlErrorMessage from "Components/Common/FormControlErrorMessage";
import { EMAIL_PATTERN } from "constants.js";

import styles from "Components/Interview/styles/InterviewPanelistsItem.module.css";

const InterviewPanelistItem = (props) => {
  const {
    index,
    panelist,
    isSaving,
    isDisabled,
    formProps,
    interview,
    onClickDelete,
  } = props;

  const { control, register, getValues, setValue, errors, clearErrors } =
    formProps;

  const isEndDateBeforeStartDate = (endateValue) => {
    const startDate = dayjs(getValues(`panelists[${index}].startDate`));
    const endDate = dayjs(endateValue);

    return (
      startDate.isBefore(endDate) &&
      startDate.format("h:mm A") !== endDate.format("h:mm A")
    );
  };

  const handleChangeStartTime = (startDateValue) => {
    const startDate = dayjs(startDateValue);
    const endDate = dayjs(getValues(`panelists[${index}].endDate`));

    if (!startDateValue || startDate.isAfter(endDate)) {
      setValue(`panelists[${index}].endDate`, null);
      clearErrors(`panelists[${index}].endDate`);
    }

    if (startDate.isBefore(endDate)) {
      clearErrors(`panelists[${index}].endDate`);
    }
  };

  return (
    <>
      <Row>
        <Form.Group as={Col} controlId="panelist" className="form-group" sm={5}>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="text"
            name={`panelists[${index}].email`}
            defaultValue={panelist?.email}
            disabled={isSaving || isDisabled || panelist?.isDefaultPanelist}
            readOnly={panelist?.isDefaultPanelist}
            isInvalid={!!errors.panelists?.[index]?.email}
            {...register(`panelists[${index}].email`, {
              required: true,
              pattern: EMAIL_PATTERN,
            })}
          />
          <FormControlErrorMessage
            show={errors.panelists?.[index]?.email?.type === "required"}
            message="Panelist email is required"
          />
          <FormControlErrorMessage
            show={errors.panelists?.[index]?.email?.type === "pattern"}
            message="Panelist email must be a valid email"
          />
        </Form.Group>

        <Form.Group controlId="startDate" className="required" as={Col} sm={3}>
          <Form.Label id="startDate">Start time</Form.Label>
          <Controller
            name={`panelists[${index}].startDate`}
            control={control}
            defaultValue={
              panelist?.startDate && getZonedDate(panelist?.startDate)
            }
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                showPopperArrow={false}
                ariaLabelledBy="startDate"
                placeholderText="Select a date"
                showTimeSelect
                showTimeSelectOnly
                calendarClassName="RemoveDisabledTimes"
                timeIntervals={15}
                minTime={getZonedDate(interview?.startDate)}
                maxTime={getZonedDate(interview?.endDate)}
                timeCaption="Start time"
                dateFormat="h:mm aa"
                timeFormat="h:mm aa"
                disabled={isSaving || isDisabled}
                className={`form-control ${
                  errors.panelists?.[index]?.startDate && "is-invalid"
                }`}
                selected={value}
                onChange={(value) => {
                  onChange(value);
                  handleChangeStartTime(value);
                }}
              />
            )}
          />
          <FormControlErrorMessage
            show={errors.panelists?.[index]?.startDate?.type === "required"}
            message="Start time is required"
          />
        </Form.Group>
        <Form.Group controlId="endDate" className="required" as={Col} sm={3}>
          <Form.Label id="endDate">End time</Form.Label>
          <Controller
            name={`panelists[${index}].endDate`}
            control={control}
            defaultValue={panelist?.endDate && getZonedDate(panelist?.endDate)}
            rules={{
              required: true,
              validate: isEndDateBeforeStartDate,
            }}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                showPopperArrow={false}
                ariaLabelledBy="endDate"
                placeholderText="Select a date"
                showTimeSelect
                showTimeSelectOnly
                calendarClassName="RemoveDisabledTimes"
                timeIntervals={15}
                minTime={getZonedDate(interview?.startDate)}
                maxTime={getZonedDate(interview?.endDate)}
                timeCaption="End time"
                dateFormat="h:mm aa"
                timeFormat="h:mm aa"
                disabled={isSaving || isDisabled}
                className={`form-control ${
                  errors.panelists?.[index]?.endDate && "is-invalid"
                }`}
                selected={value}
                onChange={onChange}
              />
            )}
          />
          <FormControlErrorMessage
            show={errors.panelists?.[index]?.endDate?.type === "required"}
            message="End time is required"
          />
          <FormControlErrorMessage
            show={errors.panelists?.[index]?.endDate?.type === "validate"}
            message="End time must be after the start time"
          />
        </Form.Group>

        <Form.Group className={styles.Delete} as={Col} sm={1}>
          <Button
            variant="link"
            disabled={isSaving || isDisabled}
            onClick={onClickDelete}
          >
            <FontAwesomeIcon icon={faTrashAlt} className={styles.Icon} />
          </Button>
        </Form.Group>
      </Row>
    </>
  );
};

InterviewPanelistItem.propTypes = {
  index: PropTypes.number,
  formProps: PropTypes.shape({
    control: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    getValues: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    clearErrors: PropTypes.func.isRequired,
  }),
  panelist: PropTypes.object,
  interview: PropTypes.object,
  isSaving: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClickDelete: PropTypes.func,
};

InterviewPanelistItem.defaultProps = {
  index: 0,
  isSaving: false,
};

export default InterviewPanelistItem;

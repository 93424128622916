import React from "react";
import PropTypes from "prop-types";

const FormControlErrorMessage = (props) => {
  const { show, message } = props;

  if (show) {
    return <div className="invalid-feedback d-block">{message}</div>;
  }

  return null;
};

FormControlErrorMessage.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string,
};

FormControlErrorMessage.defaultProps = {
  show: false,
  message: "This is required",
};

export default FormControlErrorMessage;

import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router";
import { useKeycloak } from "@react-keycloak/web";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import micoach from "micoach-api";

import ApplicationHistory from "Components/ApplicationHistory";
import ApplicationViewInfo from "Components/ApplicationViewInfo";
import CandidateEditInfo from "Components/CandidateEditInfo";
import RecruiterViewEditCard from "Components/CandidateInfo/RecruiterViewEditCard";
import CandidateTagsViewInfo from "Components/CandidateTagsViewInfo";
import CandidateViewInfo from "Components/CandidateViewInfo";
import Checklist from "Components/Checklist";
import DocumentRequestModal from "Components/DocumentRequestModal";
import Documents from "Components/Documents";
import HiringProcess from "Components/HiringProcess";
import Loading from "Components/Loading";
import StepAccordion from "Components/StepAccordion";
import { useHistoricalApplicationProcesses } from "Hooks/useApplicationProcesses";
import { useCandidate } from "Hooks/useCandidates";
import { useUsersByRole } from "Hooks/useUsers";
import BackendService from "Services/BackendService";
import {
  applicationProcessStatus,
  candidateStatus,
  kanbanColumns,
} from "constants.js";
import { filterCommentsByStatus, getRoles } from "utils.js";

import "Screens/styles/ViewCandidate.css";
import "Components/styles/Card.css";

const ViewCandidate = () => {
  const [userRoles, setUserRoles] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [isEditableHR, setIsEditableHR] = useState(false);
  const [isEditableAdmin, setIsEditableAdmin] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [TAChecklist, setTAChecklist] = useState([]);
  const [HRChecklist, setHRChecklist] = useState([]);
  const [showRequestDocumentModal, setShowRequestDocumentModal] =
    useState(false);

  const { id } = useParams();
  const [loading, candidate, loadCandidate] = useCandidate(id);
  const { users: recruiters } = useUsersByRole({
    role: micoach.UserRoleEnum.Recruiter,
    enabled: true,
  });
  const { users: externalRecruiters } = useUsersByRole({
    role: micoach.UserRoleEnum.ExternalRecruiter,
    enabled: true,
  });
  const [
    loadingApplicationProcesses,
    applicationProcesses,
    loadApplicationProcesses,
  ] = useHistoricalApplicationProcesses(id);
  const { keycloak } = useKeycloak();

  /*
   * Leads and Do not contact Comments
   */
  // Comments filtered by Lead and Blocked
  const leadComments = filterCommentsByStatus(
    candidate?.comments,
    kanbanColumns.LEADS.key
  );
  const blockedComments = filterCommentsByStatus(
    candidate?.comments,
    kanbanColumns.BLOCKED.key
  );

  // Show comments if candidate status is "BLOCKED" or there are comments in
  // the blockedComments array
  const showBlockedComments =
    candidate?.status === candidateStatus.BLOCKED.key ||
    blockedComments.length > 0;
  // Show comments if candidate status is "LEAD" or there are comments in
  // the leadComments array
  const showLeadComments =
    candidate?.status === candidateStatus.LEAD.key || leadComments.length > 0;

  /*
   * Checklist
   */
  // TODO: Move this method to checklist component
  const handleItemChange = async (data) => {
    try {
      await BackendService.updateChecklistItem(id, data);
    } catch (error) {}
  };

  const handleSavedCandidateInfo = () => {
    loadCandidate();
    setIsEditing(false);
  };

  const loadFullCandidate = async () => {
    await loadCandidate();
    await loadApplicationProcesses();
  };

  useEffect(() => {
    const roles = getRoles(keycloak);
    setUserRoles(roles);
    setIsEditable(
      roles?.includes(micoach.UserRoleEnum.Recruiter) ||
        roles?.includes(micoach.UserRoleEnum.ExternalRecruiter)
    );
    setIsEditableHR(userRoles?.includes(micoach.UserRoleEnum.HumanResources));

    if (candidate?.status && candidate?.status !== candidateStatus.HIRED.key) {
      setIsEditableAdmin(roles?.includes(micoach.UserRoleEnum.AdminRecruiter));
    }
  }, [keycloak, userRoles, candidate]);

  const isRecruiterOrHR =
    userRoles?.includes(micoach.UserRoleEnum.Recruiter) ||
    userRoles?.includes(micoach.UserRoleEnum.HumanResources);

  useEffect(() => {
    const TAItems = ["RESUME", "INTAKE_FORM", "CODE_TEST", "PAY_STUB"];
    setTAChecklist(
      candidate?.checklist?.filter((item) => {
        return TAItems.includes(item.key);
      })
    );
    setHRChecklist(
      candidate?.checklist?.filter((item) => {
        return !TAItems.includes(item.key);
      })
    );
  }, [candidate]);

  return (
    <>
      {loading ? (
        <div className="text-center">
          <Loading />
        </div>
      ) : !candidate ? (
        <h2>Candidate not found</h2>
      ) : (
        <>
          <DocumentRequestModal
            show={showRequestDocumentModal}
            onClose={() => setShowRequestDocumentModal(false)}
            checklist={candidate?.checklist}
            candidateId={candidate?._id}
          />

          <Row>
            <Col sm={8}>
              {isEditing ? (
                <CandidateEditInfo
                  candidate={candidate}
                  onSaved={handleSavedCandidateInfo}
                  onCancel={() => setIsEditing(false)}
                />
              ) : (
                <CandidateViewInfo
                  candidate={candidate}
                  onEdit={() => setIsEditing(true)}
                  isEditable={isEditable}
                />
              )}

              <CandidateTagsViewInfo
                candidateId={candidate._id}
                defaultTags={candidate?.tags}
              />

              {candidate?.applicationProcess &&
                candidate?.applicationProcess?.status !==
                  applicationProcessStatus.cancelled && (
                  <ApplicationViewInfo
                    applicationProcess={candidate.applicationProcess}
                    onUpdated={loadCandidate}
                    isEditable={isEditable}
                  />
                )}

              {candidate?.status &&
                candidate?.status !== candidateStatus.LEAD.key && (
                  <RecruiterViewEditCard
                    recruiters={[...recruiters, ...externalRecruiters]}
                    recruiter={{
                      _id: candidate.applicationProcess?.recruiter,
                      name: candidate.applicationProcess?.recruiterName,
                    }}
                    applicationProcessId={candidate?.applicationProcess?._id}
                    isEditable={isEditableAdmin}
                  />
                )}
            </Col>

            <Col sm={4}>
              <Card className="Card">
                <Card.Body>
                  {isRecruiterOrHR && (
                    <ApplicationHistory
                      applicationProcesses={applicationProcesses}
                      loading={loadingApplicationProcesses}
                    />
                  )}

                  {candidate.status !== candidateStatus.HIRED.key && (
                    <>
                      <HiringProcess
                        applicationProcess={candidate?.applicationProcess}
                        candidate={candidate}
                        isEditable={isEditable}
                        isRejectable={isEditable}
                        onSaved={loadCandidate}
                        onDiscarded={loadFullCandidate}
                      />

                      <hr />
                    </>
                  )}

                  {showLeadComments && (
                    <StepAccordion
                      key={kanbanColumns.LEADS.key}
                      candidateId={candidate._id}
                      isEditable={
                        isEditable &&
                        candidate?.status === candidateStatus.LEAD.key
                      }
                      defaultActiveKey={
                        leadComments.length === 0
                          ? kanbanColumns.LEADS.key
                          : null
                      }
                      step={{
                        step: kanbanColumns.LEADS.key,
                        comments: leadComments,
                      }}
                    />
                  )}

                  {showBlockedComments && (
                    <StepAccordion
                      key={kanbanColumns.BLOCKED.key}
                      candidateId={candidate._id}
                      isEditable={
                        isEditable &&
                        candidate?.status === candidateStatus.BLOCKED.key
                      }
                      defaultActiveKey={
                        blockedComments.length === 0
                          ? kanbanColumns.BLOCKED.key
                          : null
                      }
                      step={{
                        step: kanbanColumns.BLOCKED.key,
                        comments: blockedComments,
                      }}
                    />
                  )}

                  {(showBlockedComments || showLeadComments) && <hr />}

                  <Documents
                    candidate={candidate}
                    isEditable={isEditable}
                    onUpdated={loadCandidate}
                  />
                  {(isEditable || isEditableHR) &&
                    !userRoles?.includes(
                      micoach.UserRoleEnum.ExternalRecruiter
                    ) && (
                      <Button
                        variant="secondary"
                        onClick={() => setShowRequestDocumentModal(true)}
                        className="w-100 mt-4"
                      >
                        <FontAwesomeIcon icon={faFile} className="me-2" />
                        Request documents
                      </Button>
                    )}

                  <hr />

                  <Checklist
                    title={"TA checklist"}
                    items={TAChecklist}
                    applicationProcessId={candidate?.applicationProcess?._id}
                    isEditable={isEditable}
                    onItemChange={handleItemChange}
                  />

                  <hr />

                  <Checklist
                    title={"HR checklist"}
                    items={HRChecklist}
                    isEditable={isEditable || isEditableHR}
                    onItemChange={handleItemChange}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ViewCandidate;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import {
  EmploymentEnum,
  PositionStatusEnum,
  WorkModelEnum,
} from "@micoach/js-sdk";
import { PositionApi } from "micoach-api";

import FormControlErrorMessage from "Components/Common/FormControlErrorMessage";
import CustomSelect from "Components/CustomSelect";
import {
  getCategorizedSkills,
  getIsoEndDate,
  getLocalDate,
  getZonedDate,
  mapToSelectOptions,
} from "utils.js";

import styles from "Components/Job/Position/styles/JobPositionAddEditModal.module.css";

const JobPositionAddEditModal = (props) => {
  const { show, companies, roles, skills, position, onClose, onUpdate } = props;

  const isEditing = !!position?._id;

  const [isSaving, setIsSaving] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    reset,
  } = useForm({
    mode: "onChange",
  });

  const about = watch("about");
  const responsibilities = watch("responsibilities");
  const qualifications = watch("qualifications");

  const { hardSkills, softSkills, techSkills } = getCategorizedSkills(skills);
  const {
    hardSkills: selectedHardSkills,
    softSkills: selectedSoftSkills,
    techSkills: selectedTechSkills,
  } = getCategorizedSkills(position?.skills);

  const getFormatedOptions = (options) => {
    if (options.length) {
      return options.map((option) => {
        return { ...option, value: option.name, label: option.name };
      });
    }
    return undefined;
  };

  const onSubmit = async (formValues) => {
    try {
      setIsSaving(true);

      const jobPosition = {
        title: formValues.title,
        priority: formValues.priority,
        about,
        responsibilities,
        qualifications,
        company: formValues.company?.value || formValues.company,
        skills: [
          ...formValues.hardSkills,
          ...formValues.softSkills,
          ...formValues.techSkills,
        ],
        role: formValues.role?.value || formValues.role || "",
        seniority: formValues.seniority,
        employment: formValues.employment,
        workModel: formValues.workModel,
        status: isEditing ? position.status : PositionStatusEnum.Open,
        ...(formValues.deadline && {
          deadline: getIsoEndDate(formValues.deadline),
        }),
      };

      if (isEditing) {
        onUpdate(position?._id, jobPosition);
      } else {
        await PositionApi.createPosition(jobPosition);
        toast.success(`Success! The job position has been opened`);
      }
      onClose();
    } catch (error) {
      const status = error.response.status;

      if (status === 404) {
        toast.error("Job position not found.");
      } else {
        toast.error("Error, please try again.");
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handleChangeQuill = ({ text, editor, source, name }) => {
    setValue(name, editor.getLength() > 1 ? text : "");

    if (editor.getLength() === 1 && source === "user") {
      setError(name, { type: "required", shouldFocus: true });
    } else {
      clearErrors(name);
    }
  };

  useEffect(() => {
    if (show) {
      const quillValues = ["about", "responsibilities", "qualifications"];

      clearErrors(quillValues);

      if (position) {
        quillValues.forEach((name) => {
          setValue(name, position[name]);

          register(name, {
            required: true,
          });
        });

        ["title", "priority", "seniority", "employment", "status"].forEach(
          (key) => {
            setValue(key, position[key]);
          }
        );

        if (position.company) {
          setValue("company", {
            label: position.company.name,
            value: position.company._id,
          });
        }

        if (position.role) {
          setValue("role", {
            label: position.role,
            value: position.role,
          });
        }

        setValue("deadline", getZonedDate(position?.deadline));
      }
    } else {
      reset();
    }
  }, [show, position, setValue, clearErrors, register, reset]);

  return (
    <Modal show={show} onHide={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <div className={styles.Header}>
            <Modal.Title>
              {isEditing ? "Edit" : "Open"} Job Position
            </Modal.Title>
            <span>
              Edit the current set information as you need. This position will
              be visible in the job section once it is opened.
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="priority" className="form-group">
            <Form.Label className={styles.PriorityLabel}>Priority</Form.Label>
            <div className={styles.PriorityInput}>
              <span>
                Highlight this position in order to grant relevance in other
                posted jobs.
              </span>
              <Form.Check
                type="switch"
                name="priority"
                id="priority"
                defaultChecked={position.priority}
                {...register("priority")}
              />
            </div>
          </Form.Group>

          <Form.Group controlId="title" className="required form-group">
            <Form.Label>Job Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              isInvalid={!!errors.title}
              disabled={isSaving}
              defaultValue={position.title}
              {...register("title", { required: true })}
            />
            <FormControlErrorMessage
              show={errors.title?.type === "required"}
              message="Title is required"
            />
          </Form.Group>

          <Form.Group controlId="about" className="required form-group">
            <Form.Label>About the job</Form.Label>
            <ReactQuill
              value={about}
              className={errors.about ? "ql-error" : ""}
              readOnly={isSaving}
              onChange={(text, _delta, source, editor) =>
                handleChangeQuill({ text, editor, source, name: "about" })
              }
            />
            <FormControlErrorMessage
              show={errors.about?.type === "required"}
              message="About is required"
            />
          </Form.Group>

          <Form.Group
            controlId="responsibilities"
            className="required form-group"
          >
            <Form.Label>What will you do?</Form.Label>
            <ReactQuill
              value={responsibilities}
              className={errors.responsibilities ? "ql-error" : ""}
              readOnly={isSaving}
              onChange={(text, _delta, source, editor) =>
                handleChangeQuill({
                  text,
                  editor,
                  source,
                  name: "responsibilities",
                })
              }
            />
            <FormControlErrorMessage
              show={errors.responsibilities?.type === "required"}
              message="What will you do? is required"
            />
          </Form.Group>

          <Form.Group
            controlId="qualifications"
            className="required form-group"
          >
            <Form.Label>Qualifications</Form.Label>
            <ReactQuill
              value={qualifications}
              className={errors.qualifications ? "ql-error" : ""}
              readOnly={isSaving}
              onChange={(text, _delta, source, editor) =>
                handleChangeQuill({
                  text,
                  editor,
                  source,
                  name: "qualifications",
                })
              }
            />
            <FormControlErrorMessage
              show={errors.qualifications?.type === "required"}
              message="Qualifications are required"
            />
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Group
              as={Col}
              controlId="company"
              className="required form-group"
            >
              <Form.Label>Company</Form.Label>
              <CustomSelect
                name="company"
                isInvalid={!!errors.company}
                disabled={isSaving}
                control={control}
                options={mapToSelectOptions(companies, "_id", "name")}
                defaultValue={
                  position.company
                    ? {
                        value: position.company._id,
                        label: position.company.name,
                      }
                    : null
                }
                isRequired
              />
              <FormControlErrorMessage
                show={
                  errors.company?.type === "required" ||
                  errors.company?.type === "validate"
                }
                message="Company is required"
              />
            </Form.Group>
            <Form.Group as={Col} controlId="deadline" className="form-group">
              <Form.Label>Deadline</Form.Label>
              <Controller
                name="deadline"
                control={control}
                defaultValue={getLocalDate(position.deadline)}
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    showPopperArrow={false}
                    selected={value}
                    minDate={new Date()}
                    dateFormat="MMM dd, yyyy"
                    placeholderText="Select a date"
                    className={`form-control ${
                      errors.deadline ? "is-invalid" : ""
                    }`}
                    onChange={onChange}
                  />
                )}
              />
              <FormControlErrorMessage
                show={errors.deadline?.type === "required"}
                message="Deadline is required"
              />
            </Form.Group>
          </Form.Group>

          <Form.Group controlId="hardSkills" className="required form-group">
            <Form.Label>Hard Skills</Form.Label>
            <CustomSelect
              isMulti
              name="hardSkills"
              isInvalid={!!errors.hardSkills}
              defaultValue={getFormatedOptions(selectedHardSkills)}
              disabled={isSaving}
              control={control}
              options={getFormatedOptions(hardSkills)}
              isRequired
            />
            <FormControlErrorMessage
              show={
                errors.hardSkills?.type === "required" ||
                errors.hardSkills?.type === "validate"
              }
              message="Hard Skills are required"
            />
          </Form.Group>

          <Form.Group controlId="softSkills" className="required form-group">
            <Form.Label>Soft Skills</Form.Label>
            <CustomSelect
              isMulti
              name="softSkills"
              isInvalid={!!errors.softSkills}
              defaultValue={getFormatedOptions(selectedSoftSkills)}
              disabled={isSaving}
              control={control}
              options={getFormatedOptions(softSkills)}
              isRequired
            />
            <FormControlErrorMessage
              show={
                errors.softSkills?.type === "required" ||
                errors.softSkills?.type === "validate"
              }
              message="Soft Skills are required"
            />
          </Form.Group>

          <Form.Group controlId="techSkills" className="required form-group">
            <Form.Label>Tech Skills</Form.Label>
            <CustomSelect
              isMulti
              name="techSkills"
              isInvalid={!!errors.techSkills}
              defaultValue={getFormatedOptions(selectedTechSkills)}
              disabled={isSaving}
              control={control}
              options={getFormatedOptions(techSkills)}
              isRequired
            />
            <FormControlErrorMessage
              show={
                errors.techSkills?.type === "required" ||
                errors.techSkills?.type === "validate"
              }
              message="Tech Skills are required"
            />
          </Form.Group>

          <Form.Group controlId="role" className="form-group">
            <Form.Label>Related Role</Form.Label>
            <CustomSelect
              name="role"
              disabled={isSaving}
              control={control}
              options={mapToSelectOptions(roles, "name", "name")}
              defaultValue={
                position.role
                  ? { value: position.role, label: position.role }
                  : null
              }
            />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label>Seniority</Form.Label>
            <Form.Check
              type="radio"
              name="seniority"
              label={position.seniority}
              disabled
              checked
              {...register("seniority")}
            />
          </Form.Group>

          <Form.Group controlId="employment" className="required form-group">
            <Form.Label>Employment</Form.Label>
            <div className={styles.Radios}>
              {Object.keys(EmploymentEnum).map((key) => (
                <Form.Check
                  type="radio"
                  name="employment"
                  key={key}
                  id={EmploymentEnum[key]}
                  value={EmploymentEnum[key]}
                  label={EmploymentEnum[key]}
                  disabled={isSaving}
                  isInvalid={!!errors.employment}
                  defaultChecked={EmploymentEnum[key] === position.employment}
                  {...register("employment", { required: true })}
                />
              ))}
            </div>
            <FormControlErrorMessage
              show={errors.employment?.type === "required"}
              message="Employment is required"
            />
          </Form.Group>

          <Form.Group controlId="workModel" className="required form-group">
            <Form.Label>Work Model</Form.Label>
            <div className={styles.Radios}>
              {Object.keys(WorkModelEnum).map((key) => (
                <Form.Check
                  type="radio"
                  name="workModel"
                  key={key}
                  id={WorkModelEnum[key]}
                  value={WorkModelEnum[key]}
                  label={WorkModelEnum[key]}
                  disabled={isSaving}
                  isInvalid={!!errors.workModel}
                  defaultChecked={WorkModelEnum[key] === position.workModel}
                  {...register("workModel", { required: true })}
                />
              ))}
            </div>
            <FormControlErrorMessage
              show={errors.workModel?.type === "required"}
              message="Work Model is required"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="CancelButton"
            disabled={isSaving}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button type="submit" className="SaveButton" disabled={isSaving}>
            {isEditing ? "Save" : "Open"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

JobPositionAddEditModal.propTypes = {
  show: PropTypes.bool,
  companies: PropTypes.array,
  roles: PropTypes.array,
  skills: PropTypes.array,
  position: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
};

JobPositionAddEditModal.defaultProps = {
  show: false,
  companies: [],
  roles: [],
  skills: [],
  position: {},
};

export default JobPositionAddEditModal;

import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import micoach from "micoach-api";

import { sortSkillsByCategoryAndName, sortSkillsByValue } from "utils.js";

import styles from "Components/Evaluation/Skill/styles/SkillBalanceTable.module.css";

const SkillBalanceTable = (props) => {
  const {
    summary: { self, requested },
  } = props;

  const labels = [];
  const category = [];
  const datasets = [];

  const selfSkills = sortSkillsByCategoryAndName(self);
  const requestedSkills = sortSkillsByCategoryAndName(requested);

  if (selfSkills.length) {
    datasets.push({
      label: "Self",
      data: selfSkills.map((skill) => skill.value),
    });
  } else {
    return null;
  }

  if (requestedSkills.length) {
    datasets.push({
      label: "Requested",
      data: requestedSkills.map((skill) => skill.value),
    });
  }

  selfSkills.forEach((skill) => {
    labels.push(skill.name);
  });
  selfSkills.forEach((skill) => {
    category.push(skill.category.toUpperCase());
  });

  const data = {
    labels,
    category,
    datasets,
  };

  const mapToRowTable = (data, category) => {
    const newArray = [];
    for (let index = 0; index < data.category.length; index++) {
      const dataModel = createDataModel(
        data.category[index],
        category,
        data,
        index
      );
      dataModel !== false && newArray.push(dataModel);
    }
    return newArray;
  };

  const createDataModel = (dataCategory, category, data, index) => {
    return dataCategory === category
      ? {
          name: data.labels[index],
          selfValue: getValue(data.datasets, "Self", index),
          requestedValue: getValue(data.datasets, "Requested", index),
        }
      : false;
  };

  const getValue = (datasets = [], label, key) => {
    const value = "-";
    for (const dataset of datasets) {
      if (dataset.label === label) {
        return dataset.data?.[key];
      }
    }
    return value;
  };

  const hardSkillsValues = mapToRowTable(data, micoach.SkillCategoryEnum.Hard);
  const softSkillsValues = mapToRowTable(data, micoach.SkillCategoryEnum.Soft);
  sortSkillsByValue(hardSkillsValues, "requestedValue", "desc");
  sortSkillsByValue(softSkillsValues, "requestedValue", "desc");

  const skillRowTableResponse = (skill, name) => {
    return (
      <tr key={name}>
        <td>
          {name + 1 + ". "}
          {skill.name}
        </td>
        <td className={styles.RowStyle}>
          {parseInt(skill.selfValue) === 0 ? "N/A" : skill.selfValue}
        </td>
        <td className={styles.RowStyle}>
          {parseInt(skill.requestedValue) === 0 ? "N/A" : skill.requestedValue}
        </td>
      </tr>
    );
  };

  const headerTableResponse = (title) => {
    return (
      <tr>
        <th className={styles.HeaderTitle}>{title}</th>
        <th className={styles.HeaderSubTitle}>Self</th>
        <th className={styles.HeaderSubTitle}>Requested</th>
      </tr>
    );
  };

  return (
    <Table>
      {hardSkillsValues.length > 0 && (
        <>
          <thead className={styles.HeaderStyle}>
            {headerTableResponse("Hard Skills")}
          </thead>
          <tbody>{hardSkillsValues.map(skillRowTableResponse)}</tbody>
        </>
      )}
      {softSkillsValues.length > 0 && (
        <>
          <thead className={styles.HeaderStyle}>
            {headerTableResponse("Soft Skills")}
          </thead>
          <tbody>{softSkillsValues.map(skillRowTableResponse)}</tbody>
        </>
      )}
    </Table>
  );
};

SkillBalanceTable.propTypes = {
  summary: PropTypes.shape({
    userName: PropTypes.string.isRequired,
    userRole: PropTypes.string.isRequired,
    self: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      })
    ),
    requested: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      })
    ),
    feedback: PropTypes.arrayOf(
      PropTypes.shape({
        evaluatorRelation: PropTypes.string.isRequired,
        evaluatorEmail: PropTypes.string.isRequired,
        userName: PropTypes.string.isRequired,
        comments: PropTypes.string.isRequired,
      })
    ),
    expectedProficiencyLevel: PropTypes.number,
  }).isRequired,
};

export default SkillBalanceTable;

import { useCallback, useEffect, useState } from "react";
import { SkillApi } from "micoach-api";

import PublicBackendService from "Services/PublicBackendService";

export const useSkills = (category) => {
  const [skills, setSkills] = useState([]);
  const [loadingSkills, setLoadingSkills] = useState(false);

  const loadSkills = useCallback(async () => {
    try {
      setLoadingSkills(true);
      const { data } = await SkillApi.listSkills(category);
      setSkills(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSkills(false);
    }
  }, [category]);

  useEffect(() => {
    loadSkills();
  }, [loadSkills]);

  return { skills, loadingSkills, loadSkills };
};

export const usePublicSkills = (category) => {
  const [skills, setSkills] = useState([]);
  const [loadingSkills, setLoadingSkills] = useState(false);

  const loadSkills = useCallback(async () => {
    try {
      setLoadingSkills(true);
      const data = await PublicBackendService.fetchSkills(category);
      setSkills(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSkills(false);
    }
  }, [category]);

  useEffect(() => {
    loadSkills();
  }, [loadSkills]);

  return { skills, loadingSkills, loadSkills };
};

import { useCallback, useEffect, useState } from "react";
import { CandidateApi, SuggestionApi } from "micoach-api";

export const useSuggestedSkills = () => {
  const [suggestedSkills, setSuggestedSkills] = useState([]);
  const [loadingSuggestedSkills, setLoadingSuggestedSkills] = useState(false);

  const loadSuggestedSkills = useCallback(async () => {
    setLoadingSuggestedSkills(true);
    try {
      const response = await CandidateApi.listSuggestedSkills();
      setSuggestedSkills(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSuggestedSkills(false);
    }
  }, []);

  useEffect(() => {
    loadSuggestedSkills();
  }, [loadSuggestedSkills]);

  return {
    suggestedSkills,
    loadSuggestedSkills,
    loadingSuggestedSkills,
    setSuggestedSkills,
  };
};

export const useSuggestedJobs = () => {
  const [suggestedJobs, setSuggestedJobs] = useState([]);
  const [loadingSuggestedJobs, setLoadingSuggestedJobs] = useState(false);

  const loadSuggestedJobs = useCallback(async () => {
    setLoadingSuggestedJobs(true);
    try {
      const response = await CandidateApi.listSuggestedJobs();
      setSuggestedJobs(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSuggestedJobs(false);
    }
  }, []);

  useEffect(() => {
    loadSuggestedJobs();
  }, [loadSuggestedJobs]);

  return { suggestedJobs, loadingSuggestedJobs };
};

export const useSuggestedRoles = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);

  const getSuggestedRoles = useCallback(async (knowledge) => {
    setStatus(0);
    setIsLoading(true);
    setError(null);
    setErrorCode("");
    setErrorMessage("");

    try {
      const { data: roles, status: httpStatus } =
        await SuggestionApi.listSuggestedRoles(knowledge);

      setStatus(httpStatus);
      setIsSuccess(true);

      return roles;
    } catch (error) {
      console.error(error);
      const {
        response: { data: errorData, status: httpStatus },
      } = error;

      setStatus(httpStatus);
      setIsSuccess(false);
      setError(errorData);

      const errorObj = errorData?.errors?.[0];

      if (errorObj) {
        setErrorCode(errorObj.errorCode);
        setErrorMessage(errorObj.message);
      } else {
        setErrorCode("unknown.error");
        setErrorMessage("Error, please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    getSuggestedRoles,
    error,
    errorCode,
    errorMessage,
    status,
    isLoading,
    isSuccess,
  };
};

import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Loading from "Components/Loading";
import { getFormattedLocalDate } from "utils.js";

const CandidateBecomeEmployeeTable = (props) => {
  const { candidates, generatingReport, generatingExcelReport } = props;

  const customSort = (rows, selector, direction) => {
    const isDate = selector?.toString().includes("firstDayOfWorkAt"); // TODO: Find a more appropriate solution

    return rows.sort((a, b) => {
      const aField = isDate ? new Date(selector(a)) : selector(a).toLowerCase();
      const bField = isDate ? new Date(selector(b)) : selector(b).toLowerCase();
      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  const actions = () => {
    if (!candidates?.length || typeof props.onExportExcel === "undefined") {
      return null;
    }

    return (
      <Button
        variant="secondary"
        onClick={props.onExportExcel}
        disabled={generatingExcelReport || generatingReport}
      >
        Export Excel
      </Button>
    );
  };

  const columns = [
    {
      name: <strong>Name</strong>,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: <strong>Position</strong>,
      selector: (row) => row.position,
      sortable: true,
    },
    {
      name: <strong>Company</strong>,
      selector: (row) => row.company,
      sortable: true,
    },
    {
      name: <strong>Referred by</strong>,
      selector: (row) => row.referredBy,
      sortable: true,
    },
    {
      name: <strong>First day of work</strong>,
      selector: (row) => row.firstDayOfWorkAt,
      cell: (row) => (
        <>{getFormattedLocalDate(row.firstDayOfWorkAt, "MMM DD, YYYY")}</>
      ),
      sortable: true,
    },
    {
      name: <strong>Profile</strong>,
      selector: (row) => row.actions,
      cell: (row) => (
        <Link to={`/candidate/view/${row._id}`} target="_blank">
          <FontAwesomeIcon
            icon={faFolderOpen}
            size="2x"
            title="See the candidate's profile"
          />
        </Link>
      ),
      center: true,
    },
  ];

  return (
    <Card className="Card">
      <Card.Body>
        <DataTable
          columns={columns}
          data={candidates}
          pagination
          actions={actions()}
          sortFunction={customSort}
          progressPending={generatingReport}
          progressComponent={<Loading />}
        />
      </Card.Body>
    </Card>
  );
};

CandidateBecomeEmployeeTable.propTypes = {
  candidates: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string,
      position: PropTypes.string,
      company: PropTypes.string,
      referredBy: PropTypes.string,
      firstDayOfWorkAt: PropTypes.string,
    })
  ),
  generatingReport: PropTypes.bool,
  generatingExcelReport: PropTypes.bool,
  onExportExcel: PropTypes.func,
};

export default CandidateBecomeEmployeeTable;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import {
  getCandidateStatusFromStep,
  mapToSelectOptions,
  sortByProperty,
} from "utils";

import FilterButton from "Components/Common/FilterButton";
import { kanbanColumns } from "constants.js";

import styles from "Components/Common/styles/Filters.module.css";

const KANBAN_STATUS_OPTIONS = Object.values(kanbanColumns).map((column) => ({
  label: kanbanColumns[column.key].label,
  value: getCandidateStatusFromStep(column.key),
}));

const HistoricalFilterButton = (props) => {
  const {
    filterValues,
    companies,
    roles,
    recruiters,
    tags,
    loading,
    onApplyFilters,
  } = props;

  const [showPopover, setShowPopover] = useState(false);
  const [selectedRole, setSelectedRole] = useState({});
  const [selectedKanbanStatus, setSelectedKanbanSatus] = useState({});
  const [selectedRecruiter, setSelectedRecruiter] = useState({});
  const [selectedCompany, setSelectedCompany] = useState({});
  const [selectedTags, setSelectedTags] = useState([]);

  const handleClickApply = (event) => {
    event.preventDefault();
    const newFilters = {};

    selectedRole && (newFilters.role = selectedRole);
    selectedKanbanStatus && (newFilters.status = selectedKanbanStatus);
    selectedCompany && (newFilters.company = selectedCompany);
    selectedRecruiter && (newFilters.recruiter = selectedRecruiter);
    selectedTags && (newFilters.tags = selectedTags);

    setShowPopover(false);
    onApplyFilters?.(newFilters);
  };

  const handleClickReset = () => {
    setSelectedRole(null);
    setSelectedKanbanSatus(null);
    setSelectedCompany(null);
    setSelectedTags(null);
    setShowPopover(false);
    onApplyFilters?.({});
  };

  const handleClickClose = () => {
    setSelectedRole(filterValues?.role ?? null);
    setSelectedKanbanSatus(filterValues?.status ?? null);
    setSelectedCompany(filterValues?.company ?? null);
    setSelectedRecruiter(filterValues?.recruiter ?? null);
    setSelectedTags(filterValues?.tags ?? null);
    setShowPopover(false);
  };

  useEffect(() => {
    setSelectedRole(filterValues?.role);
    setSelectedKanbanSatus(filterValues?.status);
    setSelectedCompany(filterValues?.company);
    setSelectedRecruiter(filterValues?.recruiter);
    setSelectedTags(filterValues?.tags);
  }, [filterValues]);

  return (
    <FilterButton
      show={showPopover}
      setShowPopover={setShowPopover}
      onClickReset={handleClickReset}
    >
      <Form onSubmit={handleClickApply}>
        <Form.Group controlId="recruiter" className="form-group">
          <Form.Label>TA Member</Form.Label>
          <Select
            isClearable
            name="recruiter"
            aria-label="recruiter"
            classNamePrefix="ReactSelect"
            options={sortByProperty(
              mapToSelectOptions(recruiters, "_id", "name"),
              "label",
              "asc"
            )}
            defaultValue={filterValues?.recruiter}
            onChange={(recruiter) => setSelectedRecruiter(recruiter)}
          />
        </Form.Group>

        <Form.Group controlId="role" className="form-group">
          <Form.Label>Last Role</Form.Label>
          <Select
            isClearable
            name="role"
            aria-label="role"
            classNamePrefix="ReactSelect"
            options={sortByProperty(
              mapToSelectOptions(roles, "name", "name"),
              "label",
              "asc"
            )}
            defaultValue={filterValues?.role}
            onChange={(role) => setSelectedRole(role)}
          />
        </Form.Group>

        <Form.Group controlId="status" className="form-group">
          <Form.Label>Kanban Status</Form.Label>
          <Select
            isClearable
            name="status"
            aria-label="status"
            classNamePrefix="ReactSelect"
            options={sortByProperty(KANBAN_STATUS_OPTIONS, "label", "asc")}
            defaultValue={filterValues?.status}
            onChange={(position) => setSelectedKanbanSatus(position)}
          />
        </Form.Group>

        <Form.Group controlId="company" className="form-group">
          <Form.Label>Company</Form.Label>
          <Select
            isClearable
            name="company"
            aria-label="company"
            classNamePrefix="ReactSelect"
            placeholder="Select..."
            options={sortByProperty(
              mapToSelectOptions(companies, "name", "name"),
              "label",
              "asc"
            )}
            defaultValue={filterValues?.company}
            onChange={(company) => setSelectedCompany(company)}
          />
        </Form.Group>

        <Form.Group controlId="tags" className="form-group">
          <Form.Label>Tags (Exact match filter)</Form.Label>
          <Select
            isClearable
            isMulti
            name="tags"
            aria-label="tags"
            classNamePrefix="ReactSelect"
            options={sortByProperty(
              mapToSelectOptions(tags, "_id", "label"),
              "label",
              "asc"
            )}
            defaultValue={filterValues?.tags}
            onChange={(tags) => setSelectedTags(tags)}
          />
        </Form.Group>

        <div className={styles.Buttons}>
          <Button variant="secondary" onClick={handleClickClose}>
            Close
          </Button>
          <Button disabled={loading} variant="primary" type="submit">
            Apply
          </Button>
        </div>
      </Form>
    </FilterButton>
  );
};

HistoricalFilterButton.propTypes = {
  filterValues: PropTypes.shape({
    role: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    status: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    company: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    recruiter: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
  }),
  companies: PropTypes.array,
  recruiters: PropTypes.array,
  roles: PropTypes.array,
  tags: PropTypes.array,
  loading: PropTypes.bool,
  onApplyFilters: PropTypes.func,
};

HistoricalFilterButton.defaultProps = {
  companies: [],
  recruiters: [],
  roles: [],
  tags: [],
  loading: false,
};

export default HistoricalFilterButton;

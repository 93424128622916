import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { UserRoleEnum } from "@micoach/js-sdk";

import { getRoles } from "utils.js";

const Home = () => {
  const { keycloak } = useKeycloak();
  const history = useHistory();

  useEffect(() => {
    const userRoles = getRoles(keycloak);

    const roleToRoute = {
      [UserRoleEnum.Recruiter]: "/kanban",
      [UserRoleEnum.ExternalRecruiter]: "/kanban-v2",
      [UserRoleEnum.HumanResources]: "/reports",
      [UserRoleEnum.Candidate]: "/dashboard",
    };

    const mainRole = Object.keys(roleToRoute).find((role) =>
      userRoles.includes(role)
    );

    history.push(roleToRoute[mainRole]);
  }, [keycloak, history]);

  return null;
};

export default Home;

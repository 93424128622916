import PropTypes from "prop-types";
import { getFormattedLocalDate } from "utils";

import styles from "Components/Candidate/RecentActivity/styles/RecentActivityItem.module.css";

const RecentActivityItem = (props) => {
  const { description, points, date, imageUrl } = props;

  const formattedDate = getFormattedLocalDate(date, "MMMM DD, YYYY");
  return (
    <div className={styles.Container}>
      <img className={styles.Icon} src={imageUrl} alt="task-icon" />
      <div className={styles.NameAndDateContainer}>
        <div className={styles.Name}>{description}</div>
        <div className={styles.Date}>{formattedDate}</div>
      </div>
      <div className={styles.Points}>{`${points} pts`}</div>
    </div>
  );
};

RecentActivityItem.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
};

export default RecentActivityItem;

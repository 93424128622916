import { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import micoach, { TaskApi } from "micoach-api";

import CategorySelectorBar from "Components/Common/CategorySelectorBar";
import CoachOverviewCard from "Components/Evaluation/Skill/CoachOverviewCard";
import CoachOverviewEditCard from "Components/Evaluation/Skill/CoachOverviewEditCard";
import FeedbackList from "Components/Evaluation/Skill/FeedbackList";
import MyTaskList from "Components/Evaluation/Skill/MyTaskList";
import PeriodSelect from "Components/Evaluation/Skill/PeriodSelect";
import SkillBalanceCard from "Components/Evaluation/Skill/SkillBalanceCard";
import GoBackButton from "Components/GoBackButton";
import Loading from "Components/Loading";
import TaskModal from "Components/Task/TaskModal";
import { useQuery } from "Hooks/useQuery";
import { useSkillSummary } from "Hooks/useSkillEvaluation";
import { useTasks } from "Hooks/useTask";
import { useCoachees } from "Hooks/useUsers";
import { assets, taskBarCategoryTypes } from "constants.js";
import { getNextQuarterYear } from "utils.js";

import styles from "Screens/Evaluation/Skill/styles/ViewCoacheeSkillEvaluation.module.css";

const ViewCoacheeSkillEvaluation = () => {
  const [evaluationPeriod, setEvaluationPeriod] = useState(null);
  const [isEditingCoachOverview, setIsEditingCoachOverview] = useState(false);
  const [activeTaskCategory, setActiveTaskCategory] = useState(
    taskBarCategoryTypes.FEEDBACK.value
  );
  const { id: userId } = useParams();
  const queryParams = useQuery();
  const year = queryParams.get("year") ?? "";
  const quarter = queryParams.get("quarter") ?? "";

  const balanceDate = {
    year,
    quarter,
    nextQuarter: getNextQuarterYear(),
  };

  const [selectedPeriod, setSelectedPeriod] = useState({
    year: balanceDate.year,
    quarter: balanceDate.quarter,
  });
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [user, setUser] = useState({});

  const { tasks, loadingTasks, setTasks } = useTasks({
    userId,
  });

  const { summary, loadingSummary } = useSkillSummary(
    userId,
    selectedPeriod.year,
    selectedPeriod.quarter
  );

  const { keycloak } = useKeycloak();
  const { coachees } = useCoachees(keycloak?.subject, selectedPeriod);
  const isCoach = coachees.some((coachee) => coachee.userId === userId);

  const isPeriodCompleted = summary?.evaluationPeriod?.completed;

  const handleSavedOverview = (data) => {
    setEvaluationPeriod(data);
    setIsEditingCoachOverview(false);
  };

  const handleSavedTask = (newTask) => {
    const newTasks = [...tasks, newTask];
    setTasks(newTasks);
  };

  const handleEditTask = (taskId, updatedTask) => {
    const tasksCopy = [...tasks];

    const taskIndex = tasksCopy.findIndex((task) => task._id === taskId);
    tasksCopy[taskIndex] = { ...tasksCopy[taskIndex], ...updatedTask };

    setTasks(tasksCopy);
  };

  useEffect(() => {
    if (summary) {
      const user = {
        userId,
        userName: summary.userName ?? "",
        userEmail: summary.userEmail ?? "",
        userRole: summary.userRole ?? "",
      };
      setUser(user);
    }

    if (summary?.evaluationPeriod) {
      setEvaluationPeriod(summary.evaluationPeriod);
      setIsEditingCoachOverview(false);
    } else {
      setEvaluationPeriod();
      setIsEditingCoachOverview(true);
    }
  }, [summary, userId]);

  const evaluationResponse = () => {
    if (!isCoach) {
      return (
        <Card className={`Card ${styles.EvaluationZeroStateCard}`}>
          <Card.Body>
            <img src={assets.NO_EVALUATIONS} alt="not authorized" />
            <Card.Title className={styles.EvaluationZeroStateCardTitle}>
              The Coachee has no evaluation data from the current Coach
            </Card.Title>
          </Card.Body>
        </Card>
      );
    }
    if (summary?.self) {
      return (
        <SkillBalanceCard balanceDate={selectedPeriod} summary={summary} />
      );
    } else {
      return (
        <Card className={`Card ${styles.EvaluationZeroStateCard}`}>
          <Card.Body>
            <img src={assets.NO_EVALUATIONS} alt="Start self assessment" />
            <Card.Title className={styles.EvaluationZeroStateCardTitle}>
              There&apos;s no evaluation responses to show yet
            </Card.Title>
          </Card.Body>
        </Card>
      );
    }
  };

  const handlePeriodChange = (option) => {
    const {
      value: { year, quarter },
    } = option;
    setSelectedPeriod({ year, quarter });
  };

  const doEvaluationsExist = summary?.self || summary?.requested?.length !== 0;

  const handleClickMenu = (value) => {
    setActiveTaskCategory(value);
  };

  const handleDeleteTask = async (taskId) => {
    const index = tasks.findIndex((task) => task._id === taskId);

    if (index === -1) {
      return toast.error("Error, please try again.");
    }

    try {
      await TaskApi.deleteTask(taskId);

      const newTasks = [...tasks];
      newTasks.splice(index, 1);
      setTasks(newTasks);

      toast.success("Success! The task has been deleted.");
    } catch (error) {
      const errors = error.response.data.errors;
      toast.error(errors[0].message);
    }
  };

  const handleClickTaskManagementCard = async (taskId) => {
    try {
      await TaskApi.readComments(taskId);

      const taskIndex = tasks.findIndex((task) => task._id === taskId);

      if (taskIndex !== -1) {
        const updatedTasks = [...tasks];

        updatedTasks[taskIndex] = {
          ...updatedTasks[taskIndex],
          hasNewUserComments: false,
        };

        setTasks(updatedTasks);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdatedTask = (updatedTask) => {
    const updatedTasks = [...tasks];

    const taskIndex = updatedTasks.findIndex(
      (task) => task._id === updatedTask._id
    );

    if (taskIndex !== -1) {
      updatedTasks[taskIndex] = updatedTask;
      setTasks(updatedTasks);
    }
  };

  return (
    <>
      <div className={styles.ViewCoacheeSkillEvaluation}>
        <TaskModal
          userName={user.userName}
          userEmail={user.userEmail}
          show={showTaskModal}
          onClose={() => setShowTaskModal(false)}
          onSaved={handleSavedTask}
        />

        <GoBackButton path="/coachees" label="Coach dashboard" />

        <Row hidden={loadingSummary || loadingTasks}>
          <Col sm={8}>
            <div className={styles.CoacheeName}>
              {summary.userName}
              {isPeriodCompleted && (
                <Badge pill className="GreenBadge">
                  Cycle closed
                </Badge>
              )}
            </div>
            <div>{summary.userRole}</div>
          </Col>

          <Col sm={2}>
            {isCoach && (
              <Button
                onClick={() => setShowTaskModal(true)}
                className={styles.AssignTaskButton}
              >
                <FontAwesomeIcon
                  icon={faCheckSquare}
                  className={styles.AssignTaskIcon}
                />
                Assign task
              </Button>
            )}
          </Col>
          <Col sm={2}>
            <Form.Group controlId="PeriodSelect" className="form-group">
              <Form.Label>Period</Form.Label>
              <PeriodSelect
                defaultValue={balanceDate}
                isFirstSelected={false}
                onChange={handlePeriodChange}
              />
            </Form.Group>
          </Col>
        </Row>
        {loadingSummary || loadingTasks ? (
          <Loading />
        ) : (
          <>
            <Row className={styles.Row}>
              <Col sm={8}>{evaluationResponse()}</Col>
              {isCoach && (
                <Col sm={4}>
                  {doEvaluationsExist && (
                    <>
                      <h2 className="pt-3">Coach overview</h2>
                      {isEditingCoachOverview ? (
                        <CoachOverviewEditCard
                          evaluationPeriod={evaluationPeriod}
                          balanceDate={selectedPeriod}
                          user={user}
                          onSaved={handleSavedOverview}
                          onCancel={setIsEditingCoachOverview}
                        />
                      ) : isPeriodCompleted ? (
                        <CoachOverviewCard
                          evaluationPeriod={evaluationPeriod}
                        />
                      ) : (
                        <CoachOverviewCard
                          evaluationPeriod={evaluationPeriod}
                          onEdit={setIsEditingCoachOverview}
                        />
                      )}
                    </>
                  )}
                </Col>
              )}
            </Row>
            {isCoach && (
              <Row>
                <Col sm={8}>
                  <CategorySelectorBar
                    active={activeTaskCategory}
                    barCategories={[
                      taskBarCategoryTypes.FEEDBACK,
                      taskBarCategoryTypes.MY_TASKS,
                    ]}
                    onClick={handleClickMenu}
                  />
                  {activeTaskCategory ===
                    taskBarCategoryTypes.MY_TASKS.value && (
                    <MyTaskList
                      tasks={tasks.filter(
                        (task) => task.type !== micoach.TaskTypeEnum.WatchLater
                      )}
                      onConfirmDelete={(id) => handleDeleteTask(id)}
                      onEdit={handleEditTask}
                      onClick={handleClickTaskManagementCard}
                      onUpdated={handleUpdatedTask}
                    />
                  )}
                  {activeTaskCategory === taskBarCategoryTypes.FEEDBACK.value &&
                    doEvaluationsExist && (
                      <FeedbackList feedback={summary.feedback} />
                    )}
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ViewCoacheeSkillEvaluation;

import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";

import ActionCard from "Components/Candidate/ActionCard";

const TakeTrainingCard = (props) => {
  return (
    <div className="TakeTrainingCard">
      <a href="/trainings">
        <ActionCard
          title="Take a training"
          subtitle="Link"
          icon={faGraduationCap}
        />
      </a>
    </div>
  );
};

export default TakeTrainingCard;

import Button from "react-bootstrap/Button";

const LoginAndRegisterButtons = () => {
  const handleClick = (type) => {
    window.location.href = `/?action=${type}`;
  };

  return (
    <div className="LoginAndRegisterButtons">
      <Button
        className="Login"
        variant="secondary"
        onClick={() => handleClick("signin")}
      >
        Login
      </Button>
      <Button variant="primary" onClick={() => handleClick("signup")}>
        Sign up
      </Button>
    </div>
  );
};

export default LoginAndRegisterButtons;

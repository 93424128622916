import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle as faCheckCircleSolid } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ProficiencyLevelDescriptionsModal from "Components/Evaluation/Skill/ProficiencyLevelDescriptionsModal";
import { capitalize, getProficiencyLevelFromValue } from "utils.js";

import styles from "Components/Evaluation/Skill/styles/Evaluator.module.css";

const LevelCard = (props) => {
  const { skill, value } = props;
  const isSelected = skill.value === value;
  const isValueZero = value === 0;
  const proficiencyLevel = getProficiencyLevelFromValue(value);

  const title = isValueZero ? "NA" : `PL${value}`;
  const subtitle = proficiencyLevel?.level;
  const description = proficiencyLevel?.description;
  const evaluatedSkill = { ...skill };

  evaluatedSkill.value = value;
  evaluatedSkill.valueText = subtitle;

  return (
    <Card
      className={`${styles.LevelCard} ${isSelected && styles.Selected}`}
      onClick={() => props.onClick?.(evaluatedSkill)}
    >
      <Card.Header className={styles.Header}>
        <div className={styles.CheckIcon}>
          <FontAwesomeIcon
            icon={isSelected ? faCheckCircleSolid : faCheckCircle}
          />
        </div>
        <div className={styles.Title}>{title}</div>
        {!isValueZero && <div className={styles.Subtitle}>{subtitle}</div>}
      </Card.Header>
      <Card.Body className={styles.Body}>
        <div className={styles.Description}>{description}</div>
      </Card.Body>
    </Card>
  );
};

const Evaluator = (props) => {
  const { skill, roleName } = props;
  const [showPlDescriptionModal, setShowPlDescriptionModal] = useState(false);
  if (!skill) return null;
  const handleShowModal = () => {
    setShowPlDescriptionModal(!showPlDescriptionModal);
  };

  return (
    <>
      <ProficiencyLevelDescriptionsModal
        skill={skill}
        roleName={roleName}
        showModal={showPlDescriptionModal}
        onClose={handleShowModal}
      />
      <div className={styles.Evaluator}>
        <h1 className={styles.SkillName}>{skill.name}</h1>
        <div className={styles.SkillCategory}>{`${capitalize(
          skill.category
        )} skill`}</div>
        {skill.description && (
          <div
            className={styles.SkillDescription}
            dangerouslySetInnerHTML={{ __html: skill.description }}
          />
        )}
        <Row>
          <Col xs={12} lg={8}>
            <strong className="fs-18">Proficiency levels</strong> | Select the
            option that better describes the proficiency level.
          </Col>
          {skill.proficiencyLevels?.some(
            (element) => element.description.length
          ) && (
            <Col xs={12} lg={4} className={styles.PlButtonContainer}>
              <Button
                className={styles.PlDecriptionsButton}
                variant="link"
                onClick={handleShowModal}
              >
                View full PL descriptions
              </Button>
            </Col>
          )}
        </Row>
        <Row className={styles.Levels}>
          <Col sm className="mb-3">
            <LevelCard skill={skill} value={1} onClick={props.onClick} />
          </Col>
          <Col sm className="mb-3">
            <LevelCard skill={skill} value={2} onClick={props.onClick} />
          </Col>
          <div className="w-100 d-none d-sm-block d-lg-none mt-4" />
          <Col sm className="mb-3">
            <LevelCard skill={skill} value={3} onClick={props.onClick} />
          </Col>
          <Col sm className="mb-3">
            <LevelCard skill={skill} value={4} onClick={props.onClick} />
          </Col>
          <div className="w-100 d-none d-sm-block d-lg-none mt-4" />
          <Col sm className="mb-3">
            <LevelCard skill={skill} value={0} onClick={props.onClick} />
          </Col>
        </Row>
      </div>
    </>
  );
};

LevelCard.propTypes = {
  skill: PropTypes.object.isRequired,
  value: PropTypes.number,
  onClick: PropTypes.func,
};

Evaluator.propTypes = {
  skill: PropTypes.object.isRequired,
  roleName: PropTypes.string,
  onClick: PropTypes.func,
};

export default Evaluator;

import { useCallback, useEffect, useState } from "react";
import { removeElementInArray } from "utils";

export const useFilters = (defaultFilters = {}) => {
  const [filters, setFilters] = useState(defaultFilters);

  const removeFilter = (filter) => {
    const newFilters = { ...filters };

    Object.entries(newFilters).forEach(([filterName]) => {
      if (Array.isArray(newFilters[filterName])) {
        newFilters[filterName] = removeElementInArray(
          newFilters[filterName],
          (element) => element.label === filter.label
        );
      } else {
        if (newFilters[filter.name]) {
          delete newFilters[filter.name];
        }
      }
    });

    setFilters(newFilters);

    return newFilters;
  };

  const removeJobPositionFilter = (filter) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };

      switch (filter.type) {
        case "status": {
          newFilters.status = removeElementInArray(
            newFilters.status,
            (status) => status.label === filter.label
          );
          break;
        }
        case "company": {
          newFilters.company = removeElementInArray(
            newFilters.company,
            (company) => company.label === filter.label
          );
          break;
        }
        case "seniority": {
          newFilters.seniority = removeElementInArray(
            newFilters.seniority,
            (seniority) => seniority.label === filter.label
          );
          break;
        }
        default:
          break;
      }

      return newFilters;
    });
  };

  return { filters, setFilters, removeFilter, removeJobPositionFilter };
};

export const useSelectedFilters = (newFilters) => {
  const [selectedFilters, setSelectedFilters] = useState([]);

  const getSelectedFilters = useCallback(() => {
    const newSelectedFilters = [];

    if (newFilters) {
      Object.entries(newFilters).forEach(([filterName, filterValue]) => {
        if (Array.isArray(newFilters[filterName])) {
          newSelectedFilters.push(...newFilters[filterName]);
        } else {
          newSelectedFilters.push({
            name: filterName,
            ...filterValue,
          });
        }
      });
    }
    setSelectedFilters(newSelectedFilters);
  }, [newFilters]);

  useEffect(() => {
    getSelectedFilters();
  }, [getSelectedFilters]);

  return { selectedFilters };
};

export const useJobPositionSelectedFilters = (newFilters) => {
  const [selectedFilters, setSelectedFilters] = useState([]);

  const getSelectedFilters = useCallback(() => {
    const newSelectedFilters = [];

    if (newFilters) {
      const { status, company, seniority } = newFilters;

      if (status) {
        newSelectedFilters.push(...status);
      }

      if (company) {
        newSelectedFilters.push(...company);
      }

      if (seniority) {
        newSelectedFilters.push(...seniority);
      }
    }

    setSelectedFilters(newSelectedFilters);
  }, [newFilters]);

  useEffect(() => {
    getSelectedFilters();
  }, [getSelectedFilters]);

  return { selectedFilters };
};

import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useFieldArray } from "react-hook-form";
import Select from "react-select";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Competency from "Components/Prescreening/Competency";
import Rating from "Components/Rating";

import "Components/Prescreening/styles/CompetencyRating.css";

const CompetencyRating = (props) => {
  const {
    applicationProcess,
    softSkills,
    isEditing,
    control,
    register,
    setValue,
  } = props;

  const MAX_COMPETENCIES = 3;

  const [canAddCompetency, setCanAddCompetency] = useState(false);
  const [newCompetencies, setNewCompetencies] = useState([]);
  const [currentOption, setCurrentOption] = useState(null);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "competencies",
  });

  const getNewSoftSkills = useCallback((skills) => {
    return skills
      .filter((skill) => {
        if (
          skill.name === "Learning Agility" ||
          skill.name === "Collaboration" ||
          skill.name === "Relationship and Team Building" ||
          skill.name === "Detail Oriented" ||
          skill.name === "Results Oriented"
        ) {
          return false;
        }
        return true;
      })
      .map((skill) => {
        return { value: skill.name, label: skill.name };
      });
  }, []);

  const handleAddCompetency = () => {
    if (currentOption?.value) {
      append({ name: currentOption.value, rating: 0, comment: "" });
    }
    setCurrentOption(null);
  };

  useEffect(() => {
    if (fields.length < MAX_COMPETENCIES && isEditing) {
      setCanAddCompetency(true);
    } else {
      setCanAddCompetency(false);
    }
  }, [fields, isEditing]);

  useEffect(() => {
    setNewCompetencies(getNewSoftSkills(softSkills));
  }, [softSkills, getNewSoftSkills]);

  const renderCompetencies = () => {
    if (fields?.length) {
      return fields.map((competency, index) => {
        return (
          <div key={competency.id}>
            <Competency
              index={index}
              isEditing={isEditing}
              competency={competency}
              register={register}
              setValue={setValue}
              onClickDelete={() => remove(index)}
            />
          </div>
        );
      });
    }
  };

  return (
    <Row className="CompetencyRating">
      <Col xs={12} className="fs-13">
        <div className="mb-4">
          <span className="fs-18 fw-bold">Competency rating grid</span>
        </div>

        <div className="fs-11">
          <div className="mb-2">
            <Rating value={1} isSmall />
            <span>
              <b>Poor</b> | Much less than acceptable
            </span>
          </div>

          <div className="mb-2">
            <Rating value={2} isSmall />
            <span>
              <b>Weak</b> | Less than acceptable
            </span>
          </div>

          <div className="mb-2">
            <Rating value={3} isSmall />
            <span>
              <b>Good</b> | Acceptable
            </span>
          </div>

          <div className="mb-2">
            <Rating value={4} isSmall />
            <span>
              <b>Very good</b> | More than acceptable
            </span>
          </div>

          <div className="mb-2">
            <Rating value={5} isSmall />
            <span>
              <b>Outstanding</b> | Much more than acceptable
            </span>
          </div>
        </div>

        <Competency
          name="LEARNING_AGILITY"
          rating={applicationProcess.prescreening?.learningAgilityRating}
          comment={applicationProcess.prescreening?.learningAgility}
          isEditing={isEditing}
          setValue={setValue}
          register={register}
        />

        <Competency
          name="COLLABORATION"
          rating={applicationProcess.prescreening?.collaborationRating}
          comment={applicationProcess.prescreening?.collaboration}
          isEditing={isEditing}
          setValue={setValue}
          register={register}
        />

        <Competency
          name="WORK_QUALITY"
          rating={applicationProcess.prescreening?.workQualityRating}
          comment={applicationProcess.prescreening?.workQuality}
          isEditing={isEditing}
          setValue={setValue}
          register={register}
        />
        {renderCompetencies()}

        {canAddCompetency && (
          <Form.Group className="form-group Line">
            <Form.Label>Add competency</Form.Label>
            <div className="AddCompetency">
              <Select
                id="CompetencySelect"
                classNamePrefix="ReactSelect"
                options={newCompetencies}
                value={currentOption}
                isSearchable={true}
                onChange={(option) => setCurrentOption(option)}
              />
              <Button variant="link" onClick={handleAddCompetency}>
                <FontAwesomeIcon icon={faPlus} className="Icon" />
              </Button>
            </div>
          </Form.Group>
        )}
      </Col>
    </Row>
  );
};

CompetencyRating.propTypes = {
  applicationProcess: PropTypes.object.isRequired,
  softSkills: PropTypes.array.isRequired,
  isEditing: PropTypes.bool,
  control: PropTypes.object.isRequired,
  setValue: PropTypes.func,
  register: PropTypes.func.isRequired,
};

CompetencyRating.defaultValues = {
  isEditing: false,
};

export default CompetencyRating;

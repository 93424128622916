import ReferralProcessStepItem from "Components/Refer/ReferralProcessStepItem";

import styles from "Components/Refer/styles/ReferralProcessStepList.module.css";

const ReferralProcessStepsList = () => {
  const steps = [
    {
      title: "Refer a friend",
      subtitle:
        "If you know a friend that could be a great fit for a job position, click on Refer a friend and send the required information.",
      stepNumber: 1,
    },
    {
      title: "Review the hiring process",
      subtitle:
        "Once your friend has been hired you must wait until accomplish 3 months working for our company.",
      stepNumber: 2,
    },
    {
      title: "Claim your reward!",
      subtitle:
        "We'll let you know when you earned the reward. You only have to click on Claim reward to contact our HR team.",
      stepNumber: 3,
    },
  ];

  return (
    <div className={styles.Steps}>
      {steps.map((step) => (
        <ReferralProcessStepItem
          key={step.stepNumber}
          title={step.title}
          subtitle={step.subtitle}
          stepNumber={step.stepNumber}
        />
      ))}
    </div>
  );
};

export default ReferralProcessStepsList;

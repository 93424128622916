import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import micoach from "micoach-api";

import SkillSummaryCard from "Components/Evaluation/Skill/SkillSummaryCard";
import { filterSkills } from "utils.js";

const Summary = (props) => {
  const { skills } = props;
  const hardSkills = filterSkills(skills, micoach.SkillCategoryEnum.Hard);
  const softSkills = filterSkills(skills, micoach.SkillCategoryEnum.Soft);

  // SkillSummaryCard handlers
  const handleClickSummaryCard = (skill) => {
    props.onClick?.(skill);
  };

  return (
    <>
      <div className="fs-18 fw-bold my-3">Hard skills</div>
      <Row>
        {hardSkills.map((skill) => (
          <Col key={skill.name} md={6}>
            <SkillSummaryCard skill={skill} onClick={handleClickSummaryCard} />
          </Col>
        ))}
      </Row>

      <div className="fs-18 fw-bold my-3">Soft skills</div>
      <Row>
        {softSkills.map((skill) => (
          <Col key={skill.name} md={6}>
            <SkillSummaryCard skill={skill} onClick={handleClickSummaryCard} />
          </Col>
        ))}
      </Row>
    </>
  );
};

Summary.propTypes = {
  skills: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
};

Summary.defaultProps = {
  skills: [],
};

export default Summary;

import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";

import { sources } from "constants.js";
import { getSource } from "utils.js";

import "./styles/SourceImage.css";

const SourceImage = (props) => {
  if (!props.source) {
    return null;
  }
  const source = sources.find((el) => el.key === props.source);
  return (
    <Image
      className="SourceImage"
      src={source?.icon}
      alt={getSource(props.source)}
      roundedCircle
      title={getSource(props.source)}
    />
  );
};

SourceImage.propTypes = {
  source: PropTypes.string,
};

export default SourceImage;

import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useSWR from "swr";
import { fetcher } from "utils";

import BadgeList from "Components/Common/BadgeList";
import TagModal from "Components/Common/TagModal";
import { API, CATALOG_TYPES } from "constants.js";

import styles from "Components/styles/CandidateTagsViewInfo.module.css";

const CandidateTagsViewInfo = (props) => {
  const { candidateId, defaultTags } = props;

  const [showTagsModal, setShowTagsModal] = useState(false);
  const [tags, setTags] = useState(defaultTags);

  const { data: catalogs, isValidating: loadingCatalogs } = useSWR(
    [API.PUBLIC_CATALOG, { type: CATALOG_TYPES.TAG }],
    (path) => fetcher(path),
    {
      revalidateOnFocus: false,
    }
  );

  const mapTagOptions = (catalogs) => {
    if (!catalogs) {
      return [];
    }

    return catalogs
      .filter((catalog) => tags.includes(catalog._id))
      .map((catalog) => ({ key: catalog._id, label: catalog.label }));
  };

  const handleModalSaved = (tags) => {
    setTags(tags);
    toast.success("Success! Tags added to your candidate.");
  };

  return (
    <>
      <TagModal
        show={showTagsModal}
        candidateId={candidateId}
        defaultTags={tags}
        onClose={() => setShowTagsModal(false)}
        onSaved={handleModalSaved}
      />

      <Card className="Card CandidateView Editable mt-4">
        <Card.Body>
          <h2 className="BlueText pb-2">Tags</h2>
          <BadgeList
            variant="light"
            textColor="dark"
            options={mapTagOptions(catalogs?.data)}
            label="label"
          />
          <div className={styles.TagButton}>
            <Button
              aria-label="edit"
              variant="outline-primary"
              className="EditButton opacity-100"
              onClick={() => {
                setShowTagsModal(true);
              }}
              disabled={loadingCatalogs}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

CandidateTagsViewInfo.propTypes = {
  candidateId: PropTypes.string.isRequired,
  defaultTags: PropTypes.array,
};

CandidateTagsViewInfo.defaultProps = {
  defaultTags: [],
};

export default CandidateTagsViewInfo;

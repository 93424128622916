import PropTypes from "prop-types";

import ApplicationAccordion from "Components/ApplicationAccordion";
import CustomAccordion from "Components/Common/CustomAccordion";
import Loading from "Components/Loading";

function ApplicationHistory(props) {
  const { applicationProcesses, loading, isEditable } = props;

  return (
    <CustomAccordion title="History">
      <div>
        {loading ? (
          <Loading />
        ) : applicationProcesses.length ? (
          applicationProcesses.map((ap) => (
            <ApplicationAccordion
              key={ap._id}
              applicationProcess={ap}
              isEditable={isEditable}
            />
          ))
        ) : (
          <div>
            There are no historical positions
            <hr />
          </div>
        )}
      </div>
    </CustomAccordion>
  );
}

ApplicationHistory.propTypes = {
  applicationProcesses: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool,
};

export default ApplicationHistory;

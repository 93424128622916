import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { filterSkills } from "utils";

import BadgeSelect from "Components/Common/BadgeSelect";
import CustomAccordion from "Components/Common/CustomAccordion";
import Loading from "Components/Loading";
import StepButton from "Components/ProfileBuilder/StepButton";
import { usePublicSkills } from "Hooks/useSkills";
import { useSuggestedSkills } from "Hooks/useSuggestions";
import { useUpdateProfile } from "Hooks/useUsers";
import { skillCategories } from "constants.js";

import styles from "Components/ProfileBuilder/styles/SkillsStep.module.css";

const SkillsStep = (props) => {
  const { onSaved } = props;

  const [selectedHardSkills, setSeletedHardSkills] = useState([]);
  const [selectedSoftSkills, setSeletedSoftSkills] = useState([]);
  const [selectedTechSkills, setSelectedTechSkills] = useState([]);

  const history = useHistory();
  const { updateProfile, isUpdating, isSuccess, status, errorMessage } =
    useUpdateProfile();

  const { suggestedSkills, loadingSuggestedSkills } = useSuggestedSkills();
  const { skills, loadingSkills } = usePublicSkills();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const target = queryParams.get("target") ?? "";

  const hardSkills = filterSkills(suggestedSkills, skillCategories.HARD.key);
  const softSkills = filterSkills(suggestedSkills, skillCategories.SOFT.key);
  const techSkills = filterSkills(skills, skillCategories.TECH.key);

  const handleOnSubmit = async () => {
    const newSkills = [
      ...selectedHardSkills,
      ...selectedSoftSkills,
      ...selectedTechSkills,
    ];

    await updateProfile({ skills: newSkills });
  };

  useEffect(() => {
    if (isSuccess) {
      onSaved?.();

      if (target === "trainings") {
        history.push("/trainings");
      } else {
        history.push("/profile", {
          showProfileCompletionModal: true,
        });
      }
    } else {
      if (status && errorMessage) {
        const toastType = status >= 500 ? "error" : "info";
        toast[toastType](errorMessage);
      }
    }
  }, [isSuccess, onSaved, history, status, errorMessage, target]);

  return (
    <>
      {loadingSuggestedSkills && loadingSkills ? (
        <Loading />
      ) : (
        <>
          {hardSkills.length > 0 && (
            <CustomAccordion title="Hard Skills" isOpen>
              <BadgeSelect
                label="name"
                className={styles.BadgeList}
                options={hardSkills}
                onChange={(newValues) => {
                  setSeletedHardSkills(newValues);
                }}
              />
            </CustomAccordion>
          )}

          {softSkills.length > 0 && (
            <CustomAccordion title="Soft Skills">
              <BadgeSelect
                label="name"
                className={styles.BadgeList}
                options={softSkills}
                onChange={(newValues) => {
                  setSeletedSoftSkills(newValues);
                }}
              />
            </CustomAccordion>
          )}

          {techSkills.length > 0 && (
            <CustomAccordion title="Tech Skills">
              <BadgeSelect
                label="name"
                className={styles.BadgeList}
                options={techSkills}
                onChange={(newValues) => {
                  setSelectedTechSkills(newValues);
                }}
              />
            </CustomAccordion>
          )}

          <StepButton
            label="Complete"
            type="submit"
            disabled={isUpdating}
            onClick={handleOnSubmit}
          />
        </>
      )}
    </>
  );
};

SkillsStep.propTypes = {
  onSaved: PropTypes.func,
};

export default SkillsStep;

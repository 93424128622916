import { useCallback, useEffect, useState } from "react";
import { EmailApi } from "micoach-api";

export const useSendEmail = () => {
  const [sendingEmail, setSendingEmail] = useState(false);

  const sendEmail = useCallback(async (emailProps) => {
    if (emailProps) {
      setSendingEmail(true);
      try {
        await EmailApi.sendEmail(emailProps);
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        setSendingEmail(false);
      }
    }
  }, []);

  useEffect(() => {
    sendEmail();
  }, [sendEmail]);

  return { sendEmail, sendingEmail };
};

import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PointsBadge from "Components/Common/PointsBadge";

import styles from "Components/Candidate/styles/ActionCard.module.css";

const ActionCard = (props) => {
  const { title, subtitle, complete, points, icon } = props;

  if (!title) return null;

  return (
    <Card className={`Card  ${styles.ActionCard} `}>
      <Card.Body>
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={icon} className={styles.Icon} size="lg" />
          {points > 0 && (
            <PointsBadge completedByUser={complete} points={points} />
          )}
          <div>
            <h2 className={`${styles.Title}`}>{title}</h2>
            <span className={styles.Subtitle}>{subtitle}</span>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

ActionCard.defaultProps = {
  subtitle: "",
  complete: false,
  points: 0,
  icon: {},
};

ActionCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  complete: PropTypes.bool,
  points: PropTypes.number,
  icon: PropTypes.object,
};

export default ActionCard;

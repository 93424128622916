import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { faTable } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import { ReportApi } from "micoach-api";

import CandidateBecomeEmployeeTable from "Components/Report/CandidateBecomeEmployeeTable";
import { getFormattedLocalDate, getIsoStartDate } from "utils.js";

import styles from "Screens/Report/styles/Report.module.css";

const CandidateBecomeEmployee = () => {
  const [generatingReport, setGeneratingReport] = useState(false);
  const [generatingExcelReport, setGeneratingExcelReport] = useState(false);
  const [candidates, setCandidates] = useState();

  const { control, formState, handleSubmit, watch } = useForm({
    mode: "onChange",
  });

  const today = new Date();
  const watchStartDate = watch("startDate");
  const { isValid, errors } = formState;

  const onSubmit = async (formValues) => {
    if (isValid) {
      setGeneratingReport(true);

      try {
        const { data } = await ReportApi.getCandidatesBecomeEmployees(
          getIsoStartDate(formValues.startDate),
          "json"
        );

        setCandidates(data);
      } catch (error) {
        toast.error("Error!, please try again.");
      } finally {
        setGeneratingReport(false);
      }
    }
  };

  const handleExportExcel = async () => {
    if (isValid) {
      setGeneratingExcelReport(true);

      try {
        const { data } = await ReportApi.getCandidatesBecomeEmployees(
          getIsoStartDate(watchStartDate),
          "excel",
          {
            responseType: "arraybuffer",
          }
        );
        const blob = new Blob([data]);
        const fileName = `Candidates becoming employees - ${getFormattedLocalDate(
          watchStartDate,
          "MMM DD, YYYY"
        )}.xlsx`;
        saveAs(blob, fileName);
      } catch (error) {
        toast.error("Error!, please try again.");
      } finally {
        setGeneratingExcelReport(false);
      }
    }
  };

  return (
    <>
      <h1 className="ScreenTitle">Candidates becoming employees report</h1>
      <p className={styles.Description}>
        Lists candidates becoming employees information with first day of work
        and referred by.
      </p>
      <Row>
        <Col sm={4}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="starDate" className="required form-group">
              <Form.Label>Start date</Form.Label>
              <Controller
                name="startDate"
                control={control}
                defaultValue={today}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    showPopperArrow={false}
                    selected={value}
                    onChange={onChange}
                    dateFormat="MMM dd, yyyy"
                    className={`form-control ${
                      errors.startDate ? "is-invalid" : ""
                    }`}
                    title="Start date"
                  />
                )}
              />
              {errors.startDate && (
                <div className="invalid-feedback d-block">
                  {errors.startDate.type === "required" &&
                    "Start date is required"}
                </div>
              )}
            </Form.Group>

            <div className={styles.Buttons}>
              <Button type="submit" disabled={generatingReport}>
                <FontAwesomeIcon icon={faTable} /> Generate report
              </Button>
            </div>
          </Form>
        </Col>

        <Col sm={8}>
          <CandidateBecomeEmployeeTable
            candidates={candidates}
            generatingReport={generatingReport}
            generatingExcelReport={generatingExcelReport}
            onExportExcel={handleExportExcel}
          />
        </Col>
      </Row>
    </>
  );
};

export default CandidateBecomeEmployee;

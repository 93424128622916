import { useCallback, useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { JobApi } from "micoach-api";

export const useJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [loadingJobs, setLoadingJobs] = useState(false);
  const { keycloak } = useKeycloak();

  const loadJobs = useCallback(
    async (query) => {
      try {
        setLoadingJobs(true);

        const search = query?.search
          ? encodeURIComponent(query?.search)
          : undefined;
        const seniority = query?.seniority?.split(",") || undefined;
        const workModel = query?.workModel?.split(",") || undefined;
        const employment = query?.employment?.split(",") || undefined;
        const limit = query?.limit || undefined;
        const offset = query?.offset || undefined;
        const sort = query?.sort || undefined;
        let result;

        if (keycloak?.authenticated) {
          ({ data: result } = await JobApi.listPersonalizedJobs(
            search,
            limit,
            offset,
            sort,
            seniority,
            workModel,
            employment
          ));
        } else {
          ({ data: result } = await JobApi.listJobs(
            search,
            limit,
            offset,
            sort,
            seniority,
            workModel,
            employment
          ));
        }

        setJobs(result);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingJobs(false);
      }
    },
    [keycloak.authenticated]
  );

  return {
    jobs,
    loadingJobs,
    loadJobs,
    setJobs,
  };
};

export const useJob = (id) => {
  const [job, setJob] = useState(null);
  const [jobId, setJobId] = useState(id);
  const [loadingJob, setLoadingJob] = useState(false);
  const { keycloak } = useKeycloak();

  const loadJob = useCallback(async () => {
    try {
      if (!jobId) {
        return;
      }

      setLoadingJob(true);

      let result;

      if (keycloak?.authenticated) {
        ({ data: result } = await JobApi.getPersonalizedJob(jobId));
      } else {
        ({ data: result } = await JobApi.getJob(jobId));
      }

      setJob(result);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingJob(false);
    }
  }, [jobId, keycloak.authenticated]);

  return {
    job,
    loadingJob,
    loadJob,
    setJob,
    setJobId,
  };
};

export const useSelectedFilters = (newFilters) => {
  const [selectedFilters, setSeletedFilters] = useState([]);

  const getSelectedFilters = useCallback(() => {
    const newSelectedFilters = [];

    if (newFilters) {
      if (newFilters.seniority) {
        newSelectedFilters.push({
          name: "seniority",
          ...newFilters.seniority,
        });
      }

      if (newFilters.workModel) {
        newSelectedFilters.push({
          name: "workModel",
          ...newFilters.workModel,
        });
      }

      if (newFilters.employment) {
        newSelectedFilters.push({
          name: "employment",
          ...newFilters.employment,
        });
      }
    }

    setSeletedFilters(newSelectedFilters);
  }, [newFilters]);

  useEffect(() => {
    getSelectedFilters();
  }, [getSelectedFilters]);

  return { selectedFilters };
};

import PropTypes from "prop-types";

import styles from "Components/Refer/styles/ReferralHeader.module.css";

const ReferralHeader = (props) => {
  const { isHumanResources } = props;

  return (
    <div className={styles.Container}>
      <h1>Referrals</h1>
      <span className={styles.Subtitle}>
        {isHumanResources
          ? "Review all referrals status, details and manage rewards accord to the position."
          : "Refer a friend and you’ll get a reward in cash when they accomplish 3 months working for our company."}
      </span>
      <span className={styles.Terms}>
        Read our referral program{" "}
        <a href="/referral-program-terms-and-conditions">
          Terms and Conditions
        </a>{" "}
        to learn more.
      </span>
    </div>
  );
};

ReferralHeader.propTypes = {
  isHumanResources: PropTypes.bool,
};

ReferralHeader.defaultValues = {
  isHumanResources: false,
};

export default ReferralHeader;

import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";

import JobItem from "Components/Job/JobItem";

import styles from "Components/Job/styles/JobsCard.module.css";

const JobsCard = (props) => {
  const { jobs, setActiveJob, activeJobId, bordered } = props;

  if (!jobs?.length) {
    return null;
  }

  const getIsSelected = (id) => {
    return activeJobId === id;
  };

  return (
    <Card
      data-testid="jobsCard"
      className={`Card ${styles.JobsCard} ${bordered ? styles.Bordered : ""}`}
    >
      <Card.Body>
        {jobs.map((job) => {
          return (
            <JobItem
              key={job._id}
              job={job}
              setActiveJob={setActiveJob}
              isSelected={getIsSelected(job._id)}
            />
          );
        })}
      </Card.Body>
    </Card>
  );
};

JobsCard.propTypes = {
  jobs: PropTypes.arrayOf(PropTypes.object),
  setActiveJob: PropTypes.func,
  activeJobId: PropTypes.string,
  bordered: PropTypes.bool,
};

export default JobsCard;

import { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import micoach, { CandidateApi } from "micoach-api";

import Address from "Components/Address";
import CustomSelect from "Components/CustomSelect";
import PhoneNumberInput from "Components/PhoneNumberInput";
import { useAvailablePositions } from "Hooks/usePositions";
import { useRoles } from "Hooks/useRoles";
import {
  getCountryNameByCode,
  getStateNameByCodeAndCountry,
} from "Services/CountryService";
import {
  applicationProcessStatus,
  EMAIL_PATTERN,
  kanbanColumns,
} from "constants.js";
import {
  CANDIDATE_EMAIL_DUPLICATED,
  CANDIDATE_SECONDARY_EMAIL_DUPLICATED,
} from "error-constants.js";

const CandidateEditInfo = (props) => {
  const { candidate } = props;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    control,
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
  });

  // Save form hook in constant to pass down as props
  const formProps = { register, errors, control, watch, setValue };

  const isApplicationProcessActive =
    candidate?.applicationProcess?.status === applicationProcessStatus.active;
  const isReferral = candidate?.source === micoach.SourceEnum.Referral;

  const onSubmit = async (formValues) => {
    if (isValid) {
      try {
        const updatedCandidate = {
          ...formValues,
          role: formValues.role ?? null,
          callingCode: "",
          address: {
            city: formValues.city ?? "",
            country: formValues.country?.value ?? "",
            state: formValues.state?.value ?? "",
          },
        };

        if (formValues.phone) {
          updatedCandidate.callingCode = formValues.callingCode;
        }

        if (isApplicationProcessActive) {
          updatedCandidate.applicationProcess = {
            _id: candidate.applicationProcess._id,
            role: formValues.role,
            position: formValues.positionId ?? null,
          };
        }

        await CandidateApi.updateCandidate(candidate._id, updatedCandidate);
        toast.success("Success! The candidate has been updated.");
        props.onSaved?.();
      } catch (error) {
        const status = error.response.status;
        const data = error.response.data;

        if (status === 409) {
          if (data.error === CANDIDATE_EMAIL_DUPLICATED) {
            toast.info(
              `Error, email "${formValues.email}" is used by another account, review the spelling.`
            );
          } else if (data.error === CANDIDATE_SECONDARY_EMAIL_DUPLICATED) {
            toast.info(
              `Error, business email "${formValues.secondaryEmail}" is used by another account, review the spelling.`
            );
          }
        } else {
          toast.error("Error, please try again.");
        }
      }
    }
  };

  // Roles
  const { roles, loadingRoles } = useRoles();
  const rolesOptions = roles.map((role) => {
    return {
      value: role.name,
      label: role.name,
    };
  });

  // Positions
  const [loadingPositions, positions] = useAvailablePositions();
  const positionsOptions = positions.map((position) => {
    return {
      value: position?._id,
      label: `${position?.title} - ${position?.company?.name ?? ""}`,
    };
  });

  // Logic to determine when a position is required based on AP steps
  const isPositionRequired = () => {
    return [
      kanbanColumns.TECHNICAL_INTERVIEW.key,
      kanbanColumns.OFFER_PROCESS.key,
      kanbanColumns.OFFER_ACCEPTED.key,
      kanbanColumns.IMMIGRATION_PROCESS.key,
    ].includes(candidate.status);
  };

  useEffect(() => {
    if (candidate) {
      reset({
        name: candidate.name,
        email: candidate.email,
        secondaryEmail: candidate.secondaryEmail,
        referrerEmail: candidate.referrerEmail,
        phone: candidate.phone,
        country: candidate.address?.country && {
          label: getCountryNameByCode(candidate.address?.country),
          value: candidate.address?.country,
        },
        state: candidate.address?.state && {
          value: candidate.address?.state,
          label: getStateNameByCodeAndCountry(
            candidate.address?.state,
            candidate.address?.country
          ),
        },
        city: candidate.address?.city,
        highlights: candidate.highlights,
      });
    }
  }, [candidate, reset]);

  return (
    <>
      <Card className="Card CandidateView Editable">
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Row className="mb-3">
              <Form.Group
                as={Col}
                controlId="name"
                className="form-group required"
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  {...register("name", { required: true })}
                  isInvalid={!!errors.name}
                />
                {errors.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name.type === "required" &&
                      "Candidate name is required"}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group
                as={Col}
                controlId="referrerEmail"
                className={`form-group ${isReferral ? "required" : ""}`}
              >
                <Form.Label>Referrer email</Form.Label>
                <Form.Control
                  type="text"
                  name="referrerEmail"
                  {...register("referrerEmail", {
                    pattern: EMAIL_PATTERN,
                    required: isReferral,
                  })}
                  isInvalid={!!errors.referrerEmail}
                />
                {errors.referrerEmail && (
                  <div className="invalid-feedback d-block">
                    {errors.referrerEmail.type === "pattern" &&
                      "Referrer email must be a valid email"}
                    {errors.referrerEmail.type === "required" &&
                      "Referrer email is required"}
                  </div>
                )}
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="role" className="form-group">
                <Form.Label>Role</Form.Label>
                <CustomSelect
                  name="role"
                  control={control}
                  options={rolesOptions}
                  loading={loadingRoles}
                  isRequired={!!candidate.applicationProcess}
                  defaultValue={
                    candidate.role
                      ? {
                          value: candidate.role,
                          label: candidate.role,
                        }
                      : null
                  }
                  placeholder="Start typing to search in roles..."
                />
                {errors.role && (
                  <div className="invalid-feedback d-block">
                    Role is required, candidate has an active hiring process
                  </div>
                )}
              </Form.Group>

              {isApplicationProcessActive && (
                <Form.Group
                  as={Col}
                  controlId="positionId"
                  className={`form-group ${
                    isPositionRequired() ? "required" : ""
                  }`}
                >
                  <Form.Label>Position</Form.Label>
                  <CustomSelect
                    name="positionId"
                    control={control}
                    options={positionsOptions}
                    loading={loadingPositions}
                    isRequired={isPositionRequired()}
                    defaultValue={
                      candidate.applicationProcess?.position
                        ? {
                            value: candidate?.applicationProcess?.position?._id,
                            label: `${candidate.applicationProcess?.position?.title} - ${candidate.applicationProcess?.position?.company?.name}`,
                          }
                        : null
                    }
                    placeholder="Start typing to search in positions..."
                  />
                  {errors.positionId && (
                    <div className="invalid-feedback d-block">
                      Position is required on technical interview and further
                      hiring steps.
                    </div>
                  )}
                </Form.Group>
              )}
            </Row>

            <Row>
              <Form.Group
                as={Col}
                controlId="isTrainee"
                className="form-group required"
              >
                <Form.Label>Trainee</Form.Label>
                <div className="d-block">
                  <Form.Check
                    type="radio"
                    name="isTrainee"
                    id="isTrainee"
                    value={true}
                    inline
                    defaultChecked={
                      // eslint-disable-next-line no-prototype-builtins
                      candidate?.hasOwnProperty("isTrainee")
                        ? candidate?.isTrainee
                        : null
                    }
                    label="Yes"
                    {...register("isTrainee", { required: true })}
                    isInvalid={!!errors.isTrainee}
                  />
                  <Form.Check
                    type="radio"
                    name="isTrainee"
                    id="isTraineeNo"
                    value={false}
                    inline
                    defaultChecked={
                      // eslint-disable-next-line no-prototype-builtins
                      candidate?.hasOwnProperty("isTrainee")
                        ? !candidate?.isTrainee
                        : null
                    }
                    label="No"
                    {...register("isTrainee", { required: true })}
                    isInvalid={!!errors.isTrainee}
                  />
                  {errors.isTrainee && (
                    <Form.Control.Feedback type="invalid">
                      {errors.isTrainee.type === "required" &&
                        "This field is required."}
                    </Form.Control.Feedback>
                  )}
                </div>
              </Form.Group>
            </Row>

            <hr />

            <Row className="mb-3">
              <Form.Group
                as={Col}
                controlId="email"
                className="form-group required"
              >
                <Form.Label>Personal email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  {...register("email", {
                    pattern: EMAIL_PATTERN,
                    required: true,
                  })}
                  isInvalid={!!errors.email}
                />
                {errors.email && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email.type === "required" &&
                      "Candidate personal email is required"}
                    {errors.email.type === "pattern" &&
                      "Candidate personal email must be a valid email"}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group as={Col} controlId="phone" className="form-group">
                <PhoneNumberInput
                  formProps={formProps}
                  userCallingCode={candidate.callingCode}
                />
              </Form.Group>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group controlId="secondaryEmail" className="form-group">
                  <Form.Label>Business email</Form.Label>
                  <Form.Control
                    type="email"
                    name="secondaryEmail"
                    {...register("secondaryEmail", {
                      pattern: EMAIL_PATTERN,
                    })}
                    isInvalid={!!errors.secondaryEmail}
                  />
                  {errors.secondaryEmail && (
                    <Form.Control.Feedback type="invalid">
                      {errors.secondaryEmail.type === "pattern" &&
                        "Candidate business email must be a valid email"}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <div>
              <Address
                isEditing={true}
                address={candidate?.address}
                formProps={formProps}
              />
            </div>

            <hr />

            <h2 className="BlueText pb-2">Highlights</h2>
            <Form.Group controlId="highlights" className="form-group">
              <Form.Control
                className="h-100 fs-13"
                as="textarea"
                rows={5}
                name="highlights"
                {...register("highlights")}
                placeholder="Add your comments here."
              />
            </Form.Group>

            <div className="text-end">
              <Button
                variant="secondary"
                className="me-3"
                onClick={props.onCancel}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

CandidateEditInfo.propTypes = {
  candidate: PropTypes.object.isRequired,
  onSaved: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CandidateEditInfo;

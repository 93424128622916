import { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import micoach from "micoach-api";

import CoachSelect from "Components/Evaluation/Skill/CoachSelect";
import CompanySelect from "Components/Evaluation/Skill/CompanySelect";
import EvaluationPeriodTable from "Components/Evaluation/Skill/EvaluationPeriodTable";
import PeriodSelect from "Components/Evaluation/Skill/PeriodSelect";
import GoBackButton from "Components/GoBackButton";
import Loading from "Components/Loading";
import { useCompanies } from "Hooks/useCompanies";
import { useProgressEvaluationPeriod } from "Hooks/useEvaluationPeriod";
import { useCoaches } from "Hooks/useUsers";
import { getQuarterYear, getRoles } from "utils.js";

const EvaluationPeriod = () => {
  const balanceDate = {
    year: new Date().getFullYear(),
    quarter: getQuarterYear(),
  };

  const { keycloak } = useKeycloak();
  const userRoles = getRoles(keycloak);
  const [selectedPeriod, setSelectedPeriod] = useState(balanceDate);
  const [employer, setEmployer] = useState();
  const [coachEmail, setCoachEmail] = useState();

  const { users, loadingEvaluationPeriod } = useProgressEvaluationPeriod(
    selectedPeriod,
    coachEmail,
    employer ? encodeURIComponent(employer) : undefined // TODO: Find an alternative to encoding
  );

  const [companies] = useCompanies();

  const { coaches, loadingCoaches } = useCoaches();

  const handleChangePeriod = (option) => {
    const {
      value: { year, quarter },
    } = option;
    setSelectedPeriod({ year, quarter });
  };

  const handleChangeCompany = (option) => {
    setEmployer(option?.value);
  };

  const handleChangeCoach = (option) => {
    setCoachEmail(option?.value);
  };

  const hasRecruiterRole = userRoles?.includes(micoach.UserRoleEnum.Recruiter);

  const hasHrRole = userRoles?.includes(micoach.UserRoleEnum.HumanResources);

  const hasSiteLeaderRole = userRoles?.includes(
    micoach.UserRoleEnum.SiteLeader
  );

  return (
    <div className="EvaluationPeriod">
      <GoBackButton path="/coachees" label="Coach dashboard" />
      <div className="d-flex justify-content-between align-items-end ScreenTitle">
        <div>
          <h1>My Coachees</h1>
          <h2>Overall evaluation progress</h2>
        </div>
        <Link to="evaluation-period/performance">
          <Button className="btn btn-primary">Team performance</Button>
        </Link>
      </div>

      <Row>
        <Col sm={3}>
          <Form.Group controlId="PeriodSelect" className="form-group">
            <Form.Label>Period</Form.Label>
            <PeriodSelect
              balanceDate={balanceDate}
              onChange={handleChangePeriod}
            />
          </Form.Group>

          {hasHrRole && hasRecruiterRole && (
            <Form.Group controlId="EmployerSelect" className="form-group">
              <Form.Label>Employer</Form.Label>
              <CompanySelect
                companies={companies}
                onChange={handleChangeCompany}
              />
            </Form.Group>
          )}

          {((hasHrRole && hasRecruiterRole) || hasSiteLeaderRole) &&
            (loadingCoaches ? (
              <Loading />
            ) : (
              <Form.Group controlId="CoachSelect" className="form-group">
                <Form.Label>Coach</Form.Label>
                <CoachSelect coaches={coaches} onChange={handleChangeCoach} />
              </Form.Group>
            ))}
        </Col>
        <Col sm={9} className="mt-4">
          <Card className="Card CardShadow">
            <Card.Body>
              <EvaluationPeriodTable
                users={users}
                loadingEvaluationPeriod={loadingEvaluationPeriod}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EvaluationPeriod;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApplicationProcessApi } from "micoach-api";

import FormattedDate from "../Components/FormattedDate";

import { getIsoStartDate, getLocalDate } from "../utils";

const formInitialState = {};

const ApplicationViewInfo = (props) => {
  const { applicationProcess, isEditable } = props;

  // Application process form
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: formInitialState,
  });

  useEffect(() => {
    if (isEditing && applicationProcess) {
      reset({
        technicalInterviewAt: getLocalDate(
          applicationProcess.technicalInterviewAt
        ),
        firstDayOfWorkAt: getLocalDate(applicationProcess.firstDayOfWorkAt),
      });
    } else {
      reset(formInitialState);
    }
  }, [isEditing, applicationProcess, reset]);

  const handleSubmitApplicationProcess = async (formValues) => {
    try {
      setIsSaving(true);

      await ApplicationProcessApi.updateApplicationProcess(
        applicationProcess._id,
        {
          technicalInterviewAt: getIsoStartDate(
            formValues.technicalInterviewAt
          ),
          firstDayOfWorkAt: getIsoStartDate(formValues.firstDayOfWorkAt),
        }
      );

      setIsEditing(false);
      props.onUpdated();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Card className="Card CandidateView Editable mt-4">
      <Card.Body>
        <div>
          <h2 className="BlueText pb-2">Due dates</h2>
        </div>

        <Form onSubmit={handleSubmit(handleSubmitApplicationProcess)}>
          <Row>
            <Col>
              {isEditing ? (
                <Form.Group
                  controlId="technicalInterviewAt"
                  className="form-group required"
                >
                  <Form.Label>Technical interview</Form.Label>
                  <Controller
                    name="technicalInterviewAt"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        showPopperArrow={false}
                        selected={value}
                        onChange={onChange}
                        dateFormat="MMM dd, yyyy"
                        className={`form-control ${
                          errors.technicalInterviewAt ? "is-invalid" : ""
                        }`}
                      />
                    )}
                  />
                  {errors.technicalInterviewAt && (
                    <div className="invalid-feedback d-block">
                      {errors.technicalInterviewAt.type === "required" &&
                        "Technical interview date is required"}
                    </div>
                  )}
                </Form.Group>
              ) : (
                <span>
                  Technical interview:{" "}
                  {applicationProcess.technicalInterviewAt && (
                    <FormattedDate>
                      {applicationProcess.technicalInterviewAt}
                    </FormattedDate>
                  )}
                </span>
              )}
            </Col>
            <Col>
              {isEditing ? (
                <Form.Group controlId="firstDayOfWorkAt" className="form-group">
                  <Form.Label>First day of work</Form.Label>
                  <Controller
                    name="firstDayOfWorkAt"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        showPopperArrow={false}
                        selected={value}
                        onChange={onChange}
                        dateFormat="MMM dd, yyyy"
                        className="form-control"
                      />
                    )}
                  />
                </Form.Group>
              ) : (
                <span>
                  First day of work:{" "}
                  {applicationProcess.firstDayOfWorkAt && (
                    <FormattedDate>
                      {applicationProcess.firstDayOfWorkAt}
                    </FormattedDate>
                  )}
                </span>
              )}
            </Col>
          </Row>

          {isEditable && (
            <>
              <div className="text-end">
                {!isEditing && (
                  <Button
                    variant="outline-primary"
                    className="EditButton opacity-100"
                    onClick={() => setIsEditing(true)}
                    disabled={isEditing}
                  >
                    <FontAwesomeIcon icon={faPen}></FontAwesomeIcon>
                  </Button>
                )}
              </div>

              {isEditing && (
                <>
                  <hr />
                  <div className="text-end">
                    <Button
                      variant="secondary"
                      className="me-3"
                      disabled={isSaving}
                      onClick={() => setIsEditing(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={!isValid || isSaving}
                    >
                      Save
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

ApplicationViewInfo.propTypes = {
  applicationProcess: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  onUpdated: PropTypes.func,
};

export default ApplicationViewInfo;

import { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import FormControlErrorMessage from "Components/Common/FormControlErrorMessage";
import { useTags } from "Hooks/useTags";
import { CATALOG_TYPES } from "constants.js";

const TagAddEditModal = (props) => {
  const { tag, show, onSaved, onUpdated, onClose } = props;

  const { createTag, updateTag, isSaving, isUpdating } = useTags();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
  });

  const isEditing = !!tag?._id;

  const generateKey = (label) => {
    const formattedText = label
      .replace(/[^a-zA-Z0-9\s]/g, "")
      .replace(/\s/g, "_");
    return formattedText.toUpperCase();
  };

  const onSubmit = async (formValues) => {
    const { label } = formValues;
    const body = {
      type: CATALOG_TYPES.TAG,
      key: generateKey(label),
      label,
    };

    try {
      if (isEditing) {
        await updateTag({
          pathParams: { _id: tag._id },
          body,
        });
        onUpdated?.();
        toast.success("Success! The tag has been updated.");
      } else {
        await createTag({ body });
        onSaved?.();
        toast.success("Success! The tag has been created.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      onClose?.();
    }
  };

  useEffect(() => {
    if (show) {
      reset({
        label: tag?.label || "",
      });
    }
  }, [show, tag, reset]);

  return (
    <Modal show={show} onHide={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? "Edit" : "Create"} Tag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="label" className="form-group required">
            <Form.Label>Tag name</Form.Label>
            <Form.Control
              type="text"
              name="label"
              disabled={isSaving || isUpdating}
              {...register("label", { required: true })}
              isInvalid={!!errors.label}
            />
            <FormControlErrorMessage
              show={!!errors.label}
              message="Name is required"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="CancelButton"
            disabled={isSaving || isUpdating}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="SaveButton"
            disabled={isSaving || isUpdating}
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

TagAddEditModal.propTypes = {
  tag: PropTypes.shape({
    _id: PropTypes.string,
    label: PropTypes.string,
  }),
  show: PropTypes.bool,
  onSaved: PropTypes.func,
  onUpdated: PropTypes.func,
  onClose: PropTypes.func,
};

TagAddEditModal.defaultProps = {
  show: false,
};

export default TagAddEditModal;

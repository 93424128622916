import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { UserApi } from "micoach-api";
import { isDateAfter } from "utils";

import { useWindowSize } from "Hooks/useWindowSize";

const formInitialState = {
  isCurrent: false,
  companyName: "",
  role: "",
  location: "",
  description: "",
  startDate: null,
  endDate: null,
};

const ExperienceModal = (props) => {
  const { workExperience, workExperienceList, show } = props;
  const [isSaving, setIsSaving] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: formInitialState,
  });

  const today = new Date();
  const isCurrent = watch("isCurrent");
  const windowSize = useWindowSize();
  const startDate = watch("startDate");
  const endDate = watch("endDate");

  useEffect(() => {
    if (show) {
      if (workExperience) {
        const startDate = new Date(workExperience.startDate);
        const endDate = workExperience.endDate
          ? new Date(workExperience.endDate)
          : null;
        reset({
          ...workExperience,
          startDate,
          endDate,
        });
      }
    } else {
      reset(formInitialState);
    }
  }, [workExperience, show, reset]);

  const onSubmit = async (formValues) => {
    try {
      setIsSaving(true);
      const updatedList = [...workExperienceList];
      const newExperience = {
        ...formValues,
        endDate: isCurrent ? undefined : formValues.endDate.toISOString(),
        startDate: formValues.startDate.toISOString(),
      };

      if (workExperience) {
        const index = workExperienceList.findIndex(
          (experience) => experience._id === workExperience._id
        );
        updatedList[index] = newExperience;
      } else {
        updatedList.push(newExperience);
      }

      const response = await UserApi.updateProfile({
        workExperience: updatedList,
      });

      props.onSaved?.(response.data);
      props.onClose?.();
      toast.success(
        `Success! The work experience has been ${
          workExperience ? "updated" : "added"
        }.`
      );
    } catch (error) {
      console.error(error);
      toast.error("Error, please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (isDateAfter(startDate, endDate)) {
      setValue("endDate", null);
    }
  }, [startDate, endDate, setValue]);

  return (
    <Modal show={show} onHide={props.onClose}>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Header closeButton>
          <Modal.Title>Experience</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="isCurrent" className="form-group">
            <Form.Check
              type="checkbox"
              defaultChecked={false}
              label={<span className="fs-13 fw-bold">Current position</span>}
              name="isCurrent"
              {...register("isCurrent")}
            />
          </Form.Group>

          <Form.Group controlId="companyName" className="form-group required">
            <Form.Label>Company</Form.Label>
            <Form.Control
              type="text"
              maxLength={100}
              placeholder="e.g., Google"
              name="companyName"
              {...register("companyName", { required: true, maxLength: 100 })}
              isInvalid={!!errors.companyName}
            />
            {errors.companyName && (
              <div className="invalid-feedback d-block">
                {errors.companyName.type === "required" &&
                  "Company is required"}
                {errors.companyName.type === "maxLength" &&
                  "Max length exceeded"}
              </div>
            )}
          </Form.Group>

          <Form.Group controlId="role" className="form-group required">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              maxLength={100}
              placeholder="e.g., Software engineer"
              name="role"
              {...register("role", { required: true, maxLength: 100 })}
              isInvalid={!!errors.role}
            />
            {errors.role && (
              <div className="invalid-feedback d-block">
                {errors.role.type === "required" && "Title is required"}
                {errors.role.type === "maxLength" && "Max length exceeded"}
              </div>
            )}
          </Form.Group>

          <Form.Group controlId="location" className="form-group">
            <Form.Label>Location</Form.Label>
            <Form.Control
              type="text"
              maxLength={100}
              placeholder="e.g., Mexico City, Mexico"
              name="location"
              {...register("location", { maxLength: 100 })}
              isInvalid={!!errors.location}
            />
            {errors.location && (
              <div className="invalid-feedback d-block">
                {errors.location.type === "maxLength" && "Max length exceeded"}
              </div>
            )}
          </Form.Group>

          <div className="row">
            <Form.Group
              controlId="startDate"
              className="form-group required col-6 pe-1"
            >
              <Form.Label>Start date</Form.Label>
              <Controller
                name="startDate"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={null}
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    showPopperArrow={false}
                    selected={value}
                    onChange={onChange}
                    dateFormat="MMM, yyyy"
                    showMonthYearPicker
                    endDate={today}
                    maxDate={today}
                    className={`form-control ${
                      errors.startDate && "is-invalid"
                    }`}
                    placeholderText="Select a date"
                  />
                )}
              />
              {errors.startDate && (
                <div className="invalid-feedback d-block">
                  {errors.startDate.type === "required" &&
                    "Start date is required"}
                </div>
              )}
            </Form.Group>

            <Form.Group
              controlId="endDate"
              className="form-group required col-6 ps-1"
            >
              {!isCurrent && (
                <>
                  <Form.Label>End date</Form.Label>
                  <Controller
                    name="endDate"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    defaultValue={null}
                    render={({ field: { value, onChange } }) => (
                      <DatePicker
                        showPopperArrow={false}
                        selected={value}
                        onChange={onChange}
                        dateFormat="MMM, yyyy"
                        showMonthYearPicker
                        startDate={startDate}
                        minDate={startDate}
                        endDate={today}
                        maxDate={today}
                        className={`form-control ${
                          errors.endDate && "is-invalid"
                        }`}
                        placeholderText="Select a date"
                      />
                    )}
                  />
                  {errors.endDate && (
                    <div className="invalid-feedback d-block">
                      {errors.endDate.type === "required" &&
                        "End date is required"}
                    </div>
                  )}
                </>
              )}
            </Form.Group>
          </div>

          <Form.Group controlId="description" className="form-group required">
            <Form.Label>Description</Form.Label>
            <Form.Control
              className="fs-13 h-auto"
              as="textarea"
              rows={5}
              type="text"
              maxLength={1500}
              placeholder="Review and outline your prior responsibilities and achievements"
              name="description"
              {...register("description", { required: true, maxLength: 1500 })}
              isInvalid={!!errors.description}
            />
            {errors.description && (
              <div className="invalid-feedback d-block">
                {errors.description.type === "required" &&
                  "Description is required"}
                {errors.description.type === "maxLength" &&
                  "Max length exceeded"}
              </div>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {windowSize.isMobile ? (
            <div className="d-grid gap-2 w-100 ps-3 pe-3">
              <Button
                variant="secondary"
                size="lg"
                onClick={props.onClose}
                disabled={isSaving}
              >
                Cancel
              </Button>
              <Button type="submit" size="lg" disabled={isSaving}>
                Save experience
              </Button>
            </div>
          ) : (
            <>
              <Button
                variant="secondary"
                onClick={props.onClose}
                disabled={isSaving}
              >
                Cancel
              </Button>
              <Button type="submit" disabled={isSaving}>
                Save experience
              </Button>
            </>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

ExperienceModal.propTypes = {
  workExperience: PropTypes.object,
  workExperienceList: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSaved: PropTypes.func,
};

export default ExperienceModal;

import { useCallback, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import micoach, { PositionApi } from "micoach-api";

import SelectedFiltersV2 from "Components/Common/SelectedFiltersV2";
import JobPositionAddEditModal from "Components/Job/Position/JobPositionAddEditModal";
import JobPositionFilterButton from "Components/Job/Position/JobPositionFilterButton";
import JobPositionPreviewModal from "Components/Job/Position/JobPositionPreviewModal";
import JobPositionsTable from "Components/Job/Position/JobPositionsTable";
import Loading from "Components/Loading";
import SearchBar from "Components/SearchBar";
import { useCompanies } from "Hooks/useCompanies";
import { useFilters, useJobPositionSelectedFilters } from "Hooks/useFilters";
import { useJobPositions } from "Hooks/usePositions";
import { usePublicRoles } from "Hooks/useRoles";
import { usePublicSkills } from "Hooks/useSkills";
import { getRecruiter } from "utils.js";

const ListJobPositions = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [search, setSearch] = useState("");
  const [recruiter, setRecruiter] = useState();
  const [showJobPositionPreviewModal, setShowJobPositionPreviewModal] =
    useState(false);
  const [showPositionModal, setShowPositionModal] = useState(false);
  const [position, setPosition] = useState({});
  const [jobPositionId, setJobPositionId] = useState();

  const { keycloak } = useKeycloak();
  const [companies] = useCompanies();
  const { skills } = usePublicSkills();
  const { roles } = usePublicRoles();
  const { filters, setFilters, removeJobPositionFilter } = useFilters({
    status: [{ value: "OPEN", label: "Open", type: "status" }],
  });
  const { selectedFilters } = useJobPositionSelectedFilters(filters);
  const {
    jobPositions,
    loadingJobPositions,
    loadJobPositions,
    setJobPositions,
  } = useJobPositions();

  const getQuery = useCallback((data) => {
    const query = {};

    data.search && (query.search = data.search);
    data.recruiter && (query.recruiter = data.recruiter);
    data.status?.length &&
      (query.status = data.status.map((status) => status.value));
    data.seniority?.length &&
      (query.seniority = data.seniority.map((seniority) => seniority.value));
    data.company?.length &&
      (query.company = data.company.map((company) => company.value));

    return query;
  }, []);

  const handleSearch = (data) => {
    setSearch(data.search);
    setRecruiter(getRecruiter(data.myData, keycloak));
  };

  const handleUpdateJobPosition = async (id, jobPosition) => {
    try {
      setIsSaving(true);

      const { data: updatedJobPosition } = await PositionApi.updatePosition(
        id,
        jobPosition
      );

      updatedJobPosition.companyName = updatedJobPosition.company.name;
      setJobPositions((prevJobPositions) => {
        const index = prevJobPositions.findIndex(
          (prevJobPosition) => prevJobPosition._id === updatedJobPosition._id
        );

        const updatedJobPositions = [...jobPositions];
        updatedJobPositions[index] = updatedJobPosition;

        return updatedJobPositions;
      });

      toast.success("Success! The position has been updated.");
    } catch (error) {
      toast.error(
        "Error! An error occurred while updating the position information, please try again."
      );
    } finally {
      setIsSaving(false);
    }
    return true;
  };

  const handleClickJobPositionTitle = (positionId) => {
    setJobPositionId(positionId);
    setShowJobPositionPreviewModal(true);
  };

  const handleAppliedFilters = (filters) => {
    setFilters(filters);
  };

  const handleClickDeleteBadge = (option) => {
    removeJobPositionFilter(option);
  };

  const handleClickEdit = (jobPosition) => {
    setPosition(jobPosition);
    setShowPositionModal(true);
  };

  useEffect(() => {
    loadJobPositions(getQuery({ search, recruiter, ...filters }));
  }, [loadJobPositions, search, recruiter, filters, getQuery]);

  useEffect(() => {
    loadJobPositions({ status: [micoach.PositionStatusEnum.Open] });
  }, [loadJobPositions]);

  return (
    <>
      <JobPositionPreviewModal
        show={showJobPositionPreviewModal}
        jobPositionId={jobPositionId}
        onClose={() => {
          setShowJobPositionPreviewModal(false);
        }}
      />

      <JobPositionAddEditModal
        show={showPositionModal}
        companies={companies}
        roles={roles}
        skills={skills}
        position={position}
        onClose={() => setShowPositionModal(false)}
        onUpdate={handleUpdateJobPosition}
      />

      <SearchBar
        myDataLabel="My Job Positions"
        disabled={loadingJobPositions}
        onSearch={handleSearch}
      />

      <div className="ScreenTitle d-flex align-items-center justify-content-between">
        <h1>Job Positions</h1>
        <Link className="btn btn-secondary AddButton" to="/job-templates">
          Job Templates
        </Link>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <SelectedFiltersV2
            filterValues={selectedFilters}
            icon={faTimes}
            iconPosition="right"
            onClickDeleteBadge={handleClickDeleteBadge}
          />
        </div>
        <JobPositionFilterButton
          filterValues={filters}
          companies={companies}
          onApplyFilters={handleAppliedFilters}
        />
      </div>

      {loadingJobPositions ? (
        <Loading />
      ) : (
        <Card className="Card">
          <Card.Body>
            <JobPositionsTable
              jobPositions={jobPositions}
              isSaving={isSaving}
              onUpdate={handleUpdateJobPosition}
              onClickJobPositionTitle={handleClickJobPositionTitle}
              onClickEdit={handleClickEdit}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default ListJobPositions;

import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";

import JobBody from "Components/Job/Common/JobBody";

import styles from "Components/Job/Template/styles/JobTemplatePreviewModal.module.css";

const JobTemplatePreviewModal = (props) => {
  const { show, template, onClose } = props;

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton className={styles.Header}>
        <div>
          <Modal.Title className={styles.Title}>{template.title}</Modal.Title>
          <p className={styles.Seniority}>{template.seniority}</p>
        </div>
      </Modal.Header>
      <Modal.Body className={styles.Body}>
        <hr />
        <JobBody job={template} />
      </Modal.Body>
    </Modal>
  );
};

JobTemplatePreviewModal.propTypes = {
  show: PropTypes.bool,
  template: PropTypes.shape({
    title: PropTypes.string.isRequired,
    seniority: PropTypes.string.isRequired,
  }),
  onClose: PropTypes.func.isRequired,
};

JobTemplatePreviewModal.defaultProps = {
  show: false,
};

export default JobTemplatePreviewModal;

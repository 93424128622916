import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const FilterInput = (props) => {
  const { filterText, placeholder } = props;

  return (
    <div className="Filter">
      <InputGroup>
        <Form.Control
          id="search"
          type="text"
          placeholder={placeholder}
          value={filterText}
          onChange={props.onFilter}
        />
        <Button
          variant="outline-secondary"
          id="btn-reset-filter"
          onClick={props.onClear}
        >
          X
        </Button>
      </InputGroup>
    </div>
  );
};

FilterInput.propTypes = {
  filterText: PropTypes.string,
  placeholder: PropTypes.string,
  onFilter: PropTypes.func,
  onClear: PropTypes.func,
};

export default FilterInput;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Select from "react-select";

const CoachSelect = (props) => {
  const { coaches } = props;

  const { control } = useForm();

  const [coachOptions, setCoachOptions] = useState([]);

  useEffect(() => {
    setCoachOptions(
      coaches.map((coach) => {
        return {
          label: coach.name,
          value: coach.email,
        };
      })
    );
  }, [coaches]);

  const handleChangeCoach = (option) => {
    props.onChange(option);
  };

  return (
    <Select
      name="CoachSelect"
      classNamePrefix="ReactSelect"
      control={control}
      options={coachOptions}
      placeholder="Select coach ..."
      onChange={handleChangeCoach}
      defaultValue={null}
      isClearable
    />
  );
};

CoachSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  coaches: PropTypes.array.isRequired,
};

export default CoachSelect;

import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";

import styles from "Components/ProfileBuilder/styles/StepCard.module.css";
import stylesAnimations from "Components/styles/Animations.module.css";

const StepCard = (props) => {
  const { step, totalSteps } = props;

  if (!step || !step.component) {
    return null;
  }

  return (
    <Card data-testid="StepCard" className={styles.Card}>
      <div className={stylesAnimations.FlipContent}>
        {step.stepNumber > 0 && totalSteps > 0 && (
          <span className={styles.StepNumber}>
            {`${step.stepNumber} of ${totalSteps}`}
          </span>
        )}

        {step.title && (
          <Card.Title className={styles.Title}>{step.title}</Card.Title>
        )}

        {step.subtitle && (
          <Card.Subtitle className={styles.Subtitle}>
            {step.subtitle}
          </Card.Subtitle>
        )}

        {step.component}
      </div>
    </Card>
  );
};

StepCard.propTypes = {
  step: PropTypes.shape({
    stepNumber: PropTypes.number,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    component: PropTypes.element.isRequired,
  }),
  totalSteps: PropTypes.number,
};

StepCard.defaultProps = {
  totalSteps: 0,
};

export default StepCard;

import { useState } from "react";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Country, State } from "country-state-city";

import {
  getCountryNameByCode,
  getStateNameByCodeAndCountry,
} from "Services/CountryService";
import { joinStringWithCommas } from "utils.js";

import styles from "Components/styles/Address.module.css";

const Address = (props) => {
  const {
    address,
    isEditing,
    compact,
    requireContry,
    requireState,
    requireCity,
    disabled,
    profileBuilderFormat,
    formProps: { register, control, setValue, errors },
  } = props;

  const [countries] = useState(Country.getAllCountries());
  const [states, setStates] = useState(
    State.getStatesOfCountry(address?.country)
  );

  const city = address?.city || "";
  const countryName = getCountryNameByCode(address?.country);
  const stateName = getStateNameByCodeAndCountry(
    address?.state,
    address?.country
  );

  const handleCountryChange = (country) => {
    const isoCode = country?.value;
    const countryStates = State.getStatesOfCountry(isoCode);
    setStates(countryStates);
    setValue("state", null);
  };

  return (
    <>
      {isEditing && (
        <>
          <Row className={styles.Address}>
            <Col
              md={compact ? 12 : 6}
              lg={compact ? 12 : profileBuilderFormat ? 12 : 4}
              className={styles.Country}
            >
              <Form.Group
                controlId="country"
                className={`form-group ${requireContry ? "required" : ""}`}
              >
                <Form.Label>Country</Form.Label>
                <Controller
                  name="country"
                  control={control}
                  defaultValue={null}
                  rules={{
                    required: requireContry,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      isClearable
                      classNamePrefix="ReactSelect"
                      value={value}
                      onChange={(country) => {
                        onChange(country);
                        handleCountryChange(country);
                      }}
                      options={countries.map((country) => ({
                        value: country.isoCode,
                        label: country.name,
                      }))}
                      className={errors.country ? "is-invalid" : ""}
                      isDisabled={disabled}
                    />
                  )}
                />
                {errors.country && (
                  <div className="invalid-feedback d-block">
                    {errors.country.type === "required" &&
                      "The country is required"}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col
              md={compact ? 12 : 6}
              lg={compact ? 12 : profileBuilderFormat ? 6 : 4}
              className={styles.State}
            >
              <Form.Group
                controlId="state"
                className={`form-group ${requireState ? "required" : ""}`}
              >
                <Form.Label>State</Form.Label>
                <Controller
                  name="state"
                  control={control}
                  defaultValue={null}
                  rules={{
                    required: requireState,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      isClearable
                      classNamePrefix="ReactSelect"
                      value={value}
                      onChange={(state) => onChange(state)}
                      options={states.map((state) => ({
                        value: state.isoCode,
                        label: state.name,
                      }))}
                      className={errors.state ? "is-invalid" : ""}
                      isDisabled={disabled}
                    />
                  )}
                />
                {errors.state && (
                  <div className="invalid-feedback d-block">
                    {errors.state.type === "required" &&
                      "The state is required"}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col
              lg={compact ? 12 : profileBuilderFormat ? 6 : 4}
              className={styles.City}
            >
              <Form.Group
                controlId="city"
                className={`form-group ${requireCity ? "required" : ""}`}
              >
                <Form.Label>City / Municipality</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  {...register("city", { required: requireCity })}
                  rules={{
                    required: requireCity,
                  }}
                  className={errors.city ? "is-invalid" : ""}
                  disabled={disabled}
                />
                {errors.city && (
                  <div className="invalid-feedback d-block">
                    {errors.city.type === "required" &&
                      "The city / municipality is required"}
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>
        </>
      )}

      {!isEditing && (
        <div className={compact ? "fs-13" : ""}>
          {compact ? (
            <b>Address: </b>
          ) : (
            <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" />
          )}
          {joinStringWithCommas([city, stateName, countryName])}
        </div>
      )}
    </>
  );
};

Address.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  formProps: PropTypes.object.isRequired,
  address: PropTypes.object,
  compact: PropTypes.bool,
  requireContry: PropTypes.bool,
  requireState: PropTypes.bool,
  requireCity: PropTypes.bool,
  disabled: PropTypes.bool,
  profileBuilderFormat: PropTypes.bool,
};

export default Address;

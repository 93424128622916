import PropTypes from "prop-types";
import DataTable from "react-data-table-component";

import styles from "Components/Evaluation/Skill/styles/PerformanceEvaluationPeriodTable.module.css";

const roundToTwoDecimalsOrNa = (number) => {
  if (!number || isNaN(number)) {
    return "NA";
  }

  return Math.round((number + Number.EPSILON) * 100) / 100;
};

const getAveragedSkills = (users) => {
  if (!users.length) {
    return [];
  }

  const skillAccumulators = users[0].skills.map((skill) => {
    return {
      ...skill,
      value: 0,
      evaluationsCount: 0,
      isTotal: true,
    };
  });

  for (const user of users) {
    for (const skill of user.skills) {
      if (skill.value === 0) {
        continue;
      }

      const skillAccumulator = skillAccumulators.find(
        (skillAccumulator) => skillAccumulator.name === skill.name
      );

      if (skillAccumulator) {
        skillAccumulator.value += skill.value;
        skillAccumulator.evaluationsCount++;
      }
    }
  }

  const averagedSkills = skillAccumulators.map((skillAccumulator) => {
    return {
      ...skillAccumulator,
      value: roundToTwoDecimalsOrNa(
        skillAccumulator.value / skillAccumulator.evaluationsCount
      ),
    };
  });

  return averagedSkills ?? [];
};

const getColumnTitle = (skill) => {
  return (
    <div
      className={`${styles.SkillTitle} ${
        skill.category === "HARD"
          ? styles.HardTitle
          : skill.category === "SOFT"
            ? styles.SoftTitle
            : ""
      }`}
      title={skill.name}
    >
      {skill.name}
    </div>
  );
};

const getSkillColumns = (users) => {
  if (!users.length) {
    return [];
  }

  return users[0].skills?.map((skill, index) => {
    const selector = (user) =>
      roundToTwoDecimalsOrNa(user.skills[index]?.value);
    const isTotalSelector = (user) => user.skills[index]?.isTotal;

    return {
      name: getColumnTitle(skill),
      selector: (user) => selector(user),
      sortable: true,
      center: true,
      reorder: true,
      minWidth: "110px",
      conditionalCellStyles: [
        {
          when: (user) => selector(user) === 1,
          style: {
            backgroundColor: "#ffa063",
          },
        },
        {
          when: (user) => selector(user) > 1 && selector(user) < 2,
          style: {
            backgroundColor: "#fbbf4d",
          },
        },
        {
          when: (user) => selector(user) === 2,
          style: {
            backgroundColor: "#fbe64d",
          },
        },
        {
          when: (user) => selector(user) > 2 && selector(user) < 3,
          style: {
            backgroundColor: "#c4e649",
          },
        },
        {
          when: (user) => selector(user) === 3,
          style: {
            backgroundColor: "#84e591",
          },
        },
        {
          when: (user) => selector(user) > 3 && selector(user) < 4,
          style: {
            backgroundColor: "#10ca57",
          },
        },
        {
          when: (user) => selector(user) === 4,
          style: {
            backgroundColor: "var(--green-medium-color)",
          },
        },
        {
          when: (user) => isTotalSelector(user),
          style: {
            backgroundColor: "#869cad",
            color: "#ffffff",
            height: "24px",
          },
        },
      ],
    };
  });
};

const getData = (users) => {
  return [
    ...users,
    { userId: "", userName: "", skills: getAveragedSkills(users) },
  ];
};

const customSort = (rows, selector, direction) => {
  const sortedRows = rows
    .filter((row) => row.userId)
    .sort((a, b) => {
      const aField = selector(a);
      const bField = selector(b);
      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });

  return getData(sortedRows);
};

const PerformanceEvaluationPeriodTable = (props) => {
  const { role, users } = props;

  const data = getData(users);

  const columns = [
    {
      name: <strong>User</strong>,
      selector: (user) => user.userName,
      sortable: true,
      reorder: true,
      minWidth: "180px",
    },
    ...getSkillColumns(users),
  ];

  return (
    <DataTable
      title={<div className={styles.Title}>{role}</div>}
      columns={columns}
      data={data}
      dense
      highlightOnHover
      sortFunction={customSort}
    />
  );
};

PerformanceEvaluationPeriodTable.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      userName: PropTypes.string.isRequired,
      skills: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          category: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  role: PropTypes.string.isRequired,
};

export default PerformanceEvaluationPeriodTable;

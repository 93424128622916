import { useParams } from "react-router";

import StateCard from "Components/Common/StateCard";
import Loading from "Components/Loading";
import { useCompleteEvaluationPeriod } from "Hooks/useEvaluationPeriod";
import { assets } from "constants.js";

const EvaluationPeriodComplete = () => {
  const { id } = useParams();
  const { isSaving } = useCompleteEvaluationPeriod(id);

  if (isSaving) {
    return <Loading />;
  }

  return (
    <StateCard
      imageUrl={assets.THANKS}
      title="Thank you, we receive your answer!"
      subtitle="Your assessment review is completed, we encourage you to work hard for the next period."
    />
  );
};

export default EvaluationPeriodComplete;

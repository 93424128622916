import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Loading from "Components/Loading";
import RoleQuizStep from "Components/ProfileBuilder/Quiz/RoleQuizStep";
import { useKnowledge } from "Hooks/useKnowledge";
import { useSuggestedRoles } from "Hooks/useSuggestions";
import { roleQuiz } from "constants.js";
import { removeDuplicates } from "utils.js";

const RoleQuizHandler = (props) => {
  const { onExit, onCompleted } = props;

  const { knowledge, isLoading, loadKnowledge } = useKnowledge();
  const { getSuggestedRoles } = useSuggestedRoles();

  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [stepQuizCounter, setStepQuizCounter] = useState(0);

  const quizSteps = [
    {
      ...roleQuiz.QUESTION_ONE,
    },
    {
      ...roleQuiz.QUESTION_TWO,
    },
    {
      ...roleQuiz.QUESTION_THREE,
    },
    {
      ...roleQuiz.ANSWER,
    },
  ];

  const handleClickExitQuiz = () => {
    if (
      stepQuizCounter === roleQuiz.QUESTION_ONE.stepNumber ||
      stepQuizCounter === roleQuiz.ANSWER.stepNumber
    ) {
      onExit(false);
    }
  };

  const handleClickRestartQuiz = () => {
    setOptions(knowledge);
    setStepQuizCounter(0);
  };

  const handleClickNext = (newSelectedOptions) => {
    const newOptions = [].concat(
      ...newSelectedOptions.map((option) => option.children)
    );

    const removedDuplicatesOptions = removeDuplicates(newOptions, "name");

    setOptions(removedDuplicatesOptions);
    setStepQuizCounter((stepCounter) => stepCounter + 1);
    setSelectedOptions((selectedOptions) => [
      ...selectedOptions,
      ...newSelectedOptions.map((option) => option.name),
    ]);
  };

  const handleClickFindRole = async (options) => {
    const knowledge = [
      ...selectedOptions,
      ...options.map((option) => option.name),
    ];

    const roles = await getSuggestedRoles({ knowledge });

    setOptions(roles);
    setStepQuizCounter((stepCounter) => stepCounter + 1);
  };

  const handleClickCompletedQuiz = (roles) => {
    onCompleted?.({ role: roles?.[0].name });
  };

  useEffect(() => {
    loadKnowledge();
  }, [loadKnowledge]);

  useEffect(() => {
    if (knowledge.length) {
      setOptions(knowledge);
    }
  }, [knowledge]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <RoleQuizStep
          step={quizSteps[stepQuizCounter]}
          totalSteps={quizSteps.length - 1}
          options={options}
          onClickNext={handleClickNext}
          onClickFindRole={handleClickFindRole}
          onClickCompleted={handleClickCompletedQuiz}
          onClickRestart={handleClickRestartQuiz}
          onClickExit={handleClickExitQuiz}
        />
      )}
    </>
  );
};

RoleQuizHandler.propTypes = {
  onExit: PropTypes.func,
  onCompleted: PropTypes.func,
};

export default RoleQuizHandler;

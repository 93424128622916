import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";

import JobTemplateMenu from "Components/Job/Template/JobTemplateMenu";
import { getFormattedLocalDate } from "utils.js";

const JobTemplatesTable = (props) => {
  const {
    templates,
    onClickEdit,
    onClickDelete,
    onClickOpen,
    onClickJobTitle,
  } = props;

  const columns = [
    {
      name: <strong>Job Title</strong>,
      selector: (template) => template.title,
      sortable: true,
      minWidth: "200px",
      wrap: true,
      cell: (position) => (
        <Button
          variant="link"
          className="Clickable"
          onClick={() => onClickJobTitle(position)}
        >
          {position.title}
        </Button>
      ),
    },
    {
      name: <strong>Seniority</strong>,
      selector: (template) => template.seniority,
      sortable: true,
      width: "110px",
      wrap: true,
    },
    {
      name: <strong>Created</strong>,
      selector: (template) => template.createdAt,
      cell: (template) => <>{getFormattedLocalDate(template.createdAt)}</>,
      sortable: true,
      width: "110px",
      wrap: true,
    },
    {
      name: <strong>Updated</strong>,
      selector: (template) => template.updatedAt,
      cell: (template) => <>{getFormattedLocalDate(template.updatedAt)}</>,
      sortable: true,
      width: "110px",
      wrap: true,
    },
    {
      cell: (template) => (
        <JobTemplateMenu
          onClickEdit={() => onClickEdit(template)}
          onClickDelete={() => onClickDelete(template)}
          onClickOpen={() => onClickOpen(template)}
        />
      ),
      width: "110px",
      wrap: true,
    },
  ];

  return (
    <DataTable columns={columns} data={templates} pagination highlightOnHover />
  );
};

JobTemplatesTable.propTypes = {
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      seniority: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
    })
  ),
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickOpen: PropTypes.func.isRequired,
  onClickJobTitle: PropTypes.func.isRequired,
};

export default JobTemplatesTable;

import { useCallback, useState } from "react";
import { KnowledgeApi } from "micoach-api";

export const useKnowledge = () => {
  const [knowledge, setKnowledge] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);

  const loadKnowledge = useCallback(async () => {
    setStatus(0);
    setIsLoading(true);
    setError(null);
    setErrorCode("");
    setErrorMessage("");

    try {
      const { data, status: httpStatus } =
        await KnowledgeApi.listPublicKnowledge();

      setStatus(httpStatus);
      setIsSuccess(true);
      setKnowledge(data);
    } catch (error) {
      console.error(error);
      const {
        response: { data: errorData, status: httpStatus },
      } = error;

      setStatus(httpStatus);
      setIsSuccess(false);
      setError(errorData);

      const errorObj = errorData?.errors?.[0];

      if (errorObj) {
        setErrorCode(errorObj.errorCode);
        setErrorMessage(errorObj.message);
      } else {
        setErrorCode("unknown.error");
        setErrorMessage("Error, please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    knowledge,
    loadKnowledge,
    setKnowledge,
    error,
    errorCode,
    errorMessage,
    status,
    isLoading,
    isSuccess,
  };
};

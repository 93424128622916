import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";

import styles from "Components/Common/styles/StateCard.module.css";

const StateCard = (props) => {
  const { imageUrl, imageWidth, title, subtitle, children } = props;

  const imageStyle = { width: imageWidth };

  return (
    <Card className={`Card ${styles.StateCard}`}>
      <Card.Body>
        {imageUrl && (
          <div className={styles.Image}>
            <img src={imageUrl} alt="No information found" style={imageStyle} />
          </div>
        )}
        {title && <div className={styles.Title}>{title}</div>}
        {subtitle && <div className={styles.Subtitle}>{subtitle}</div>}
        {children}
      </Card.Body>
    </Card>
  );
};

StateCard.propTypes = {
  imageUrl: PropTypes.string,
  imageWidth: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
};

export default StateCard;

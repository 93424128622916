import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { isEarlyStep, mapToSelectOptions } from "utils";

import FormControlErrorMessage from "Components/Common/FormControlErrorMessage";
import { useCatalog } from "Hooks/useCatalog";
import { useEmailTemplates } from "Hooks/useEmailTemplates";
import {
  CATALOG_TYPES,
  EMAIL_TEMPLATES_TYPES,
  kanbanColumns,
  PROCESSES,
} from "constants.js";

import styles from "Components/styles/DiscardModal.module.css";

const PROCESS_TO_CATALOG = {
  [PROCESSES.APPLICATION]: CATALOG_TYPES.APPLICATION_PROCESS_REJECTION_REASON,
  [PROCESSES.PROSPECT]: CATALOG_TYPES.PROSPECT_PROCESS_REJECTION_REASON,
};

const formInitialState = {
  discardReasonSelect: "",
  discardReasonText: "",
  sendEmail: false,
  emailTemplate: "",
};

const DiscardModal = (props) => {
  const {
    candidateName,
    candidateStatus,
    destinationColumn,
    emailTemplateType = EMAIL_TEMPLATES_TYPES.DISCARDED,
    process,
    show = false,
    isLoading = false,
    onClose,
    onSubmit,
  } = props;

  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(null);
  const selectedEmailTemplateRef = useRef(null);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue,
  } = useForm({
    mode: "onSubmit",
  });

  const sendEmail = watch("sendEmail");
  const otherReason = watch("discardReasonSelect")?.value === "Other";
  const destinationName = kanbanColumns[destinationColumn]?.label;

  const { catalog, loadingCatalog } = useCatalog(PROCESS_TO_CATALOG[process]);

  const { emailTemplates, loadingEmailTemplates } =
    useEmailTemplates(emailTemplateType);

  const handleSubmitForm = (formValues) => {
    const { discardReasonSelect, discardReasonText, emailTemplate } =
      formValues;

    const data = {
      emailTemplateId: emailTemplate?.value,
      discardReason: discardReasonSelect?.value,
    };

    if (otherReason) {
      data.discardReason = discardReasonText;
    }

    onSubmit?.(data);
  };

  const isDiscardReasonRequired = () => {
    if (process === PROCESSES.PROSPECT) {
      return true;
    }

    if (sendEmail) {
      return true;
    }

    if (!candidateStatus) {
      return false;
    }

    // Discarding reasons required from Technical Interview and further
    return !isEarlyStep(candidateStatus);
  };

  const emailTemplateOptions = (emailTemplates) => {
    return emailTemplates.map((template) => ({
      value: template._id,
      label: template.metadata.name,
    }));
  };

  const handleReasonChange = (option) => {
    setValue("discardReasonSelect", option);
    setValue("discardReasonText", "");
    trigger("discardReasonSelect");
  };

  const handleChangeSendEmail = (e) => {
    setValue("sendEmail", e.target.checked);
    setValue("emailTemplate", null);
    setSelectedEmailTemplate(null);
  };

  const handleChangeEmailTemplate = (option) => {
    const emailTemplate = emailTemplates.find(
      (emailTemplate) => emailTemplate._id === option?.value
    );

    setValue("emailTemplate", option);
    setSelectedEmailTemplate(emailTemplate);
    selectedEmailTemplateRef.current?.scrollTo?.(0, 0);
  };

  const handleCloseModal = () => {
    onClose();
  };

  useEffect(() => {
    reset(formInitialState);
    setSelectedEmailTemplate(null);
  }, [show, reset]);

  return (
    <Modal show={show} onHide={handleCloseModal} backdrop="static">
      <Form onSubmit={handleSubmit(handleSubmitForm)}>
        <Modal.Header closeButton>
          <Modal.Title>Reject &apos;{candidateName}&apos;</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            {process === PROCESSES.PROSPECT && (
              <div>
                {candidateName} will be removed from the current prospects
                process. Please add the rejection reasons.
              </div>
            )}
            {process === PROCESSES.APPLICATION && (
              <div>
                {candidateName} will be removed from the current hiring process
                and moved into the <b>{destinationName}</b> column. Please add
                the rejection reasons.
              </div>
            )}
          </div>

          <Form.Group
            controlId="discardReasonSelect"
            className={`${
              isDiscardReasonRequired() ? "required" : ""
            } mt-2 mb-2 form-group`}
          >
            <Form.Label>Rejection reasons</Form.Label>

            <Controller
              name="discardReasonSelect"
              control={control}
              rules={{
                required: isDiscardReasonRequired(),
              }}
              render={({ field: { value } }) => (
                <Select
                  aria-label="discardReasonSelect"
                  isLoading={loadingCatalog}
                  options={mapToSelectOptions(catalog, "label", "label")}
                  value={value}
                  isClearable
                  classNamePrefix="ReactSelect"
                  className={errors.discardReasonSelect ? "is-invalid" : ""}
                  onChange={handleReasonChange}
                />
              )}
            />
            <FormControlErrorMessage
              show={errors.discardReasonSelect?.type === "required"}
              message={
                process === PROCESSES.PROSPECT
                  ? "Rejection reasons are required"
                  : "Rejection reasons are required from Technical Interview and further or when selecting send email option"
              }
            />
          </Form.Group>

          {otherReason && (
            <Form.Group
              controlId="discardReasonText"
              className={`${
                isDiscardReasonRequired() ? "required" : ""
              } form-group`}
            >
              <Form.Label>Describe the reason for rejection</Form.Label>
              <Form.Control
                type="text"
                arian-label="discardReasonText"
                name="discardReasonText"
                {...register("discardReasonText", { required: otherReason })}
                isInvalid={errors.discardReasonText}
              />
              <FormControlErrorMessage
                show={errors.discardReasonText?.type === "required"}
                message="Rejection reason text is required"
              />
            </Form.Group>
          )}

          <Form.Group controlId="sendEmail" className="form-group">
            <Form.Check
              inline
              type="checkbox"
              id="sendEmail"
              name="sendEmail"
              label="Send rejection email"
              {...register("sendEmail")}
              onChange={handleChangeSendEmail}
            />
          </Form.Group>

          {sendEmail && (
            <Form.Group
              controlId="emailTemplate"
              className="form-group required"
            >
              <Form.Label>Email template</Form.Label>
              <Controller
                name="emailTemplate"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { value } }) => (
                  <Select
                    aria-label="emailTemplate"
                    isLoading={loadingEmailTemplates}
                    options={emailTemplateOptions(emailTemplates)}
                    value={value}
                    isClearable
                    className={errors.emailTemplate ? "is-invalid" : ""}
                    classNamePrefix="ReactSelect"
                    onChange={handleChangeEmailTemplate}
                  />
                )}
              />
              <FormControlErrorMessage
                show={errors.emailTemplate?.type === "required"}
                message="Email template is required"
              />
            </Form.Group>
          )}
          {selectedEmailTemplate && (
            <div
              ref={selectedEmailTemplateRef}
              dangerouslySetInnerHTML={{
                __html: selectedEmailTemplate.message,
              }}
              className={styles.EmailTemplateMessage}
            />
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="CancelButton"
            disabled={isLoading}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button type="submit" className="SaveButton" disabled={isLoading}>
            Reject
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

DiscardModal.propTypes = {
  emailTemplateType: PropTypes.oneOf(Object.values(EMAIL_TEMPLATES_TYPES))
    .isRequired,
  process: PropTypes.oneOf(Object.values(PROCESSES)).isRequired,
  candidateName: PropTypes.string,
  candidateStatus: PropTypes.string,
  destinationColumn: PropTypes.string,
  isLoading: PropTypes.bool,
  show: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default DiscardModal;

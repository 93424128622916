import PropTypes from "prop-types";

import { getFormattedTimeZoneDate } from "utils.js";

import styles from "Components/Interview/styles/InterviewsCalendarHeader.module.css";

const InterviewsCalendarHeader = (props) => {
  const { date, timezone } = props;

  return (
    <div className={styles.InterviewsCalendarHeader}>
      <div className={styles.Date}>
        <span className={styles.Day}>
          {getFormattedTimeZoneDate(date, "ddd", timezone).toUpperCase()}
        </span>
        <div className={styles.Circle}>
          {getFormattedTimeZoneDate(date, "DD", timezone)}
        </div>
      </div>

      <span className={styles.Timezone}>
        {timezone === "America/Tijuana" ? "Shown in Pacific Time" : ""}
      </span>
    </div>
  );
};

InterviewsCalendarHeader.propTypes = {
  date: PropTypes.string,
  timezone: PropTypes.string,
};

InterviewsCalendarHeader.defaultProps = {
  date: new Date().toISOString(),
  timezone: "America/Tijuana",
};

export default InterviewsCalendarHeader;

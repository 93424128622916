import { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import StepsHandler from "Components/ProfileBuilder/StepsHandler";
import { UserProfileContext } from "Context/UserProfileProvider";
import { usePublicRoles } from "Hooks/useRoles";

const ProfileBuilder = () => {
  const MININUM_PROFILE_REQUIRED = 6;

  const { roles } = usePublicRoles();
  const { keycloak } = useKeycloak();

  const history = useHistory();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const target = queryParams.get("target") ?? "";

  const { profileCompletion } = useContext(UserProfileContext);

  useEffect(() => {
    if (profileCompletion > MININUM_PROFILE_REQUIRED) {
      history.push(`/${target}`);
    }
  }, [profileCompletion, target, history]);

  if (!profileCompletion) {
    return null;
  }

  return (
    <StepsHandler
      userName={keycloak.tokenParsed?.name}
      userEmail={keycloak.tokenParsed.email}
      roles={roles}
    />
  );
};

export default ProfileBuilder;

import * as micoach from "@micoach/js-sdk";
import axios from "axios";
import keycloak from "keycloak";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((request) => {
  request.headers.Authorization = `Bearer ${keycloak.token}`;
  return request;
});

// Configuration, base path, axios
const apiParams = [null, process.env.REACT_APP_BACKEND_URL, axiosInstance];

export const ApplicationApi = new micoach.ApplicationApi(...apiParams);
export const ApplicationProcessApi = new micoach.ApplicationProcessApi(
  ...apiParams
);
export const CandidateApi = new micoach.CandidateApi(...apiParams);
export const CatalogApi = new micoach.CatalogApi(...apiParams);
export const CompanyApi = new micoach.CompanyApi(...apiParams);
export const EmailApi = new micoach.EmailApi(...apiParams);
export const EvaluationApi = new micoach.EvaluationApi(...apiParams);
export const EvaluationPeriodApi = new micoach.EvaluationPeriodApi(
  ...apiParams
);
export const EventApi = new micoach.EventApi(...apiParams);
export const FileApi = new micoach.FileApi(...apiParams);
export const HiringEventApi = new micoach.HiringEventApi(...apiParams);
export const JobApi = new micoach.JobApi(...apiParams);
export const LikeApi = new micoach.LikeApi(...apiParams);
export const KnowledgeApi = new micoach.KnowledgeApi(...apiParams);
export const NotificationApi = new micoach.NotificationApi(...apiParams);
export const PositionApi = new micoach.PositionApi(...apiParams);
export const PositionTemplateApi = new micoach.PositionTemplateApi(
  ...apiParams
);
export const RankingApi = new micoach.RankingApi(...apiParams);
export const ReferApi = new micoach.ReferralApi(...apiParams);
export const ReportApi = new micoach.ReportApi(...apiParams);
export const RoleApi = new micoach.RoleApi(...apiParams);
export const SkillApi = new micoach.SkillApi(...apiParams);
export const TaskApi = new micoach.TaskApi(...apiParams);
export const TrainingApi = new micoach.TrainingApi(...apiParams);
export const UserApi = new micoach.UserApi(...apiParams);
export const SuggestionApi = new micoach.SuggestionApi(...apiParams);

export default micoach;

import React from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Achievement from "Components/Candidate/Achievement";
import { useAchievements } from "Hooks/useAchievements";
import { ACHIEVEMENT_CERTIFICATE_DUMMY_DATA } from "constants.js";

const AchievementPreviewModal = (props) => {
  const { show = false, achievement, onClickSend, isSending, onClose } = props;

  const { getDummyCertificatePdf } = useAchievements();

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Preview Achievement</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3 fs-14">
          Validate the information and assets included in the achievement
          creation/edition modal and verify if it is ready to be send.
        </div>
        <div>
          <Achievement
            achievement={{
              ...achievement,
              points: ACHIEVEMENT_CERTIFICATE_DUMMY_DATA.points,
              candidateName: ACHIEVEMENT_CERTIFICATE_DUMMY_DATA.name,
              hasCertificate:
                !!achievement?.certificateBackgroundFile?.publicUrl,
              achievementId: achievement._id,
            }}
            isShareable={false}
            getCertificate={getDummyCertificatePdf}
          />
        </div>
        <Alert variant="light" className="fs-13">
          <FontAwesomeIcon icon={faInfoCircle} className="BlueTextMedium" />
          <span>Can&apos;t be undone</span>
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="CancelButton"
          disabled={isSending}
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          className="SaveButton"
          disabled={isSending}
          onClick={() => {
            onClickSend?.(achievement._id);
          }}
        >
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AchievementPreviewModal.propTypes = {
  show: PropTypes.bool,
  achievement: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    message: PropTypes.string,
    points: PropTypes.number,
    companyLogoUrl: PropTypes.string,
    badgeImageUrl: PropTypes.string,
    certificateBackgroundFile: PropTypes.shape({
      publicUrl: PropTypes.string,
    }),
  }),
  isSending: PropTypes.bool,
  onClickSend: PropTypes.func,
  onSaved: PropTypes.func,
  onClose: PropTypes.func,
};

export default AchievementPreviewModal;

import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";

import "Components/Company/styles/CompanyCard.css";

const CompanyCard = (props) => {
  const { company } = props;

  const handleClickEdit = () => {
    props.onEdit?.(company);
  };

  const handleClickDelete = () => {
    props.onDelete?.(company._id);
  };

  return (
    <Card className="Card CardShadow CompanyCard">
      <Card.Body className="CompanyDetails">
        <span className="CompanyLogo">
          <Image src={company.logo} className="CompanyImage" />
        </span>
        <Card.Title>{company.name}</Card.Title>
      </Card.Body>
      <Card.Footer>
        <Button variant="link" onClick={handleClickEdit}>
          Edit
        </Button>
        <Link
          className="btn btn-link"
          to={`/company/view/${props.company._id}`}
        >
          Contacts
        </Link>
        <Button variant="link" onClick={handleClickDelete}>
          Delete
        </Button>
      </Card.Footer>
    </Card>
  );
};

CompanyCard.propTypes = {
  company: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

export default CompanyCard;

import { useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ApplicationProcessesList from "Components/ApplicationProcessesList";
import JobBody from "Components/Job/Common/JobBody";
import Loading from "Components/Loading";
import { useJobPosition } from "Hooks/usePositions";
import { getFormattedLocalDate, getPositionStatus } from "utils.js";

import styles from "Components/Job/Position/styles/JobPositionPreviewModal.module.css";

const JobPositionPreviewModal = (props) => {
  const { show, jobPositionId, onClose } = props;

  const { jobPosition, loadingJobPosition, loadJobPosition, setJobPositionId } =
    useJobPosition(jobPositionId);

  useEffect(() => {
    loadJobPosition();
  }, [loadJobPosition]);

  useEffect(() => {
    setJobPositionId(jobPositionId);
  }, [setJobPositionId, jobPositionId]);

  if (!jobPosition) {
    return null;
  }

  return (
    <>
      <Modal show={show} onHide={onClose}>
        {loadingJobPosition ? (
          <Loading />
        ) : (
          <>
            <Modal.Header closeButton className={styles.Header} />
            <Modal.Body className={styles.Body}>
              <div>
                <p className={styles.CompanyName}>
                  {jobPosition.company?.name}
                </p>

                <Modal.Title>{jobPosition.title}</Modal.Title>

                <div className={styles.PositionInfo}>
                  <p>{getPositionStatus(jobPosition.status)}</p>
                  <p>{jobPosition.workModel}</p>
                  <p>{jobPosition.seniority}</p>
                </div>

                <div className={styles.DatesAndPriority}>
                  <div className={styles.Dates}>
                    <p>Open - {getFormattedLocalDate(jobPosition.createdAt)}</p>
                    <p>
                      Deadline - {getFormattedLocalDate(jobPosition.deadline)}
                    </p>
                  </div>
                  {jobPosition.priority && (
                    <p className={styles.Priority}>
                      <FontAwesomeIcon icon={faCircle} />
                      Priority
                    </p>
                  )}
                </div>
              </div>
              <hr />

              <ApplicationProcessesList
                applicationProcesses={jobPosition.applicationProcesses}
              />

              <hr />
              <JobBody job={jobPosition} />
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
};

JobPositionPreviewModal.propTypes = {
  show: PropTypes.bool,
  jobPositionId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

JobPositionPreviewModal.defaultProps = {
  show: false,
};

export default JobPositionPreviewModal;

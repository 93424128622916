import { createContext, useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";

import { useTasksTotal } from "Hooks/useTask";

const UnreadTasksContext = createContext({
  unreadTasks: 0,
  setUnreadTasks: () => {},
});

export const UnreadTasksContextProvider = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children } = props;

  const [unreadTasks, setUnreadTasks] = useState(0);

  const { keycloak } = useKeycloak();

  const { tasksTotal: totalUnreadTasks } = useTasksTotal({
    userId: keycloak?.tokenParsed?.sub,
    read: false,
  });

  useEffect(() => {
    if (totalUnreadTasks) setUnreadTasks(totalUnreadTasks);
  }, [totalUnreadTasks]);

  return (
    <UnreadTasksContext.Provider
      value={{
        unreadTasks,
        setUnreadTasks,
      }}
    >
      {children}
    </UnreadTasksContext.Provider>
  );
};

export default UnreadTasksContext;

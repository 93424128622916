import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";
import {
  faInfoCircle,
  faPaperPlane,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import micoach, { EvaluationApi } from "micoach-api";

import ConfirmModal from "Components/ConfirmModal";
import FormattedDate from "Components/FormattedDate";
import { MAX_EVALUATION_REQUESTS } from "constants.js";
import {
  capitalize,
  getEvaluationStatusFromKey,
  getFormattedLocalDate,
} from "utils.js";

import styles from "Components/Evaluation/Skill/styles/EvaluationRequestsCounter.module.css";
const EvaluationRequestsIcon = (props) => {
  const { totalEvaluationRequests } = props;

  if (totalEvaluationRequests >= MAX_EVALUATION_REQUESTS) {
    return (
      <FontAwesomeIcon icon={faInfoCircle} size="2x" className={styles.Icon} />
    );
  }

  return <div className={styles.Circle}>{totalEvaluationRequests}</div>;
};

const EvaluationRequestsCounter = (props) => {
  const { nextQuarter, evaluationRequests } = props;

  const [currentEvaluation, setCurrentEvaluation] = useState();
  const [showPopover, setShowPopover] = useState(false);
  const [showResendModal, setShowResendModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);

  const handleClickResendRequest = (evaluation) => {
    setCurrentEvaluation(evaluation);
    setShowResendModal(true);
  };

  const handleClickDeleteRequest = (evaluation) => {
    setCurrentEvaluation(evaluation);
    setShowDeleteModal(true);
  };

  const handleConfirmResendRequest = async (evaluation) => {
    try {
      setLoadingAction(true);
      await EvaluationApi.resendEvaluation(evaluation._id);
      setShowResendModal(false);
      toast.success(
        `Success! An email has been sent to ${evaluation.evaluatorEmail}.`
      );
    } catch (error) {
      console.error(error);
      toast.error("Error, please try again.");
    } finally {
      setLoadingAction(false);
    }
  };

  const handleConfirmDeleteRequest = async (evaluation) => {
    try {
      setLoadingAction(true);
      await EvaluationApi.deleteEvaluation(evaluation._id);
      setShowDeleteModal(false);
      props.onDeleted?.(evaluation._id);
      toast.success("Success! An evaluation request has been deleted.");
    } catch (error) {
      console.error(error);
      toast.error("Error, please try again.");
    } finally {
      setLoadingAction(false);
    }
  };

  const getEvaluationRequestsPopover = () => {
    const totalEvaluationRequests = evaluationRequests.length;
    let title = "You have not sent any evaluation requests yet.";

    if (totalEvaluationRequests > 0) {
      if (totalEvaluationRequests >= MAX_EVALUATION_REQUESTS) {
        title = `You already reached ${MAX_EVALUATION_REQUESTS} requests in this period. The next period starts on ${getFormattedLocalDate(
          nextQuarter,
          "MMM DD, YYYY"
        )}.`;
      } else {
        title = "Requests sent in this period";
      }
    }

    return (
      <Popover className={styles.PopoverRequests}>
        <Popover.Header>{title}</Popover.Header>
        <Popover.Body>
          {totalEvaluationRequests > 0 && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Relation</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                {evaluationRequests?.map((evaluation) => (
                  <tr key={evaluation._id}>
                    <td>{evaluation.evaluatorEmail}</td>
                    <td>{capitalize(evaluation.evaluatorRelation)}</td>
                    <td>
                      <FormattedDate>{evaluation.createdAt}</FormattedDate>
                    </td>
                    <td>{getEvaluationStatusFromKey(evaluation.status)}</td>
                    <td>
                      {evaluation.status !==
                        micoach.EvaluationStatusEnum.Closed && (
                        <div className={styles.RequestActions}>
                          {evaluation.status !==
                            micoach.EvaluationStatusEnum.Expired && (
                            <FontAwesomeIcon
                              title="Resend this request"
                              icon={faPaperPlane}
                              onClick={() =>
                                handleClickResendRequest(evaluation)
                              }
                            />
                          )}

                          <FontAwesomeIcon
                            title="Delete this request"
                            icon={faTrashAlt}
                            onClick={() => handleClickDeleteRequest(evaluation)}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          <div className={styles.PopoverFooter}>
            <Button onClick={() => setShowPopover(false)}>Close</Button>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <>
      <OverlayTrigger
        trigger="click"
        placement="bottom-end"
        show={showPopover}
        onToggle={() => setShowPopover(true)}
        overlay={getEvaluationRequestsPopover()}
      >
        <Button variant="link" title="Requests sent">
          <EvaluationRequestsIcon
            totalEvaluationRequests={evaluationRequests.length}
          />
        </Button>
      </OverlayTrigger>

      <ConfirmModal
        show={showResendModal}
        title="Resend evaluation request"
        confirmButtonText="Yes, resend"
        onClose={() => setShowResendModal(false)}
        onConfirm={() => handleConfirmResendRequest(currentEvaluation)}
        isLoading={loadingAction}
      >
        <div>
          Are you sure you want to resend this evaluation request{" "}
          <strong>
            {currentEvaluation?.evaluatorEmail} |{" "}
            {capitalize(currentEvaluation?.evaluatorRelation)}
          </strong>
        </div>
      </ConfirmModal>

      <ConfirmModal
        show={showDeleteModal}
        title="Delete evaluation request"
        confirmButtonText="Yes, delete"
        onClose={() => setShowDeleteModal(false)}
        onConfirm={() => handleConfirmDeleteRequest(currentEvaluation)}
        isLoading={loadingAction}
      >
        <div>
          Are you sure you want to delete this evaluation request{" "}
          <strong>
            {currentEvaluation?.evaluatorEmail} |{" "}
            {capitalize(currentEvaluation?.evaluatorRelation)}
          </strong>
          ?<div className="mt-3"> This action cannot be undone.</div>
        </div>
      </ConfirmModal>
    </>
  );
};

EvaluationRequestsIcon.propTypes = {
  totalEvaluationRequests: PropTypes.number.isRequired,
};

EvaluationRequestsCounter.defaultProps = {
  evaluationRequests: [],
};

EvaluationRequestsCounter.propTypes = {
  nextQuarter: PropTypes.object.isRequired,
  evaluationRequests: PropTypes.array.isRequired,
  onDeleted: PropTypes.func,
};

export default EvaluationRequestsCounter;

import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import CompanyCard from "Components/Company/CompanyCard";
import CompanyModal from "Components/Company/CompanyModal";
import Loading from "Components/Loading";
import { useCompanies } from "Hooks/useCompanies";
import BackendService from "Services/BackendService";

const ListCompanies = () => {
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [isModalEditing, setIsModalEditing] = useState(null);
  const [company, setCompany] = useState(null);
  const [companies, loadCompanies, loadingCompanies] = useCompanies();
  const history = useHistory();

  // Screen handlers
  const handleShowModal = () => {
    setShowCompanyModal(!showCompanyModal);
  };

  const handleAddCompanyModal = () => {
    setIsModalEditing(false);
    setCompany(null);
    handleShowModal();
  };

  const handleEditCompanyModal = (company) => {
    setIsModalEditing(true);
    setCompany(company);
    handleShowModal();
  };

  const handleSavedCompany = (company) => {
    loadCompanies();
  };

  // CompanyList handlers
  const handleDeleteCompany = async (id) => {
    const ok = window.confirm("Are you sure you wish to delete this Company?");
    if (ok) {
      try {
        await BackendService.deleteCompany(id);
        history.push("/companies");
        toast.success("Success! The company has been deleted.");
        await loadCompanies();
      } catch (error) {
        toast.error("Failed to delete company");
        await loadCompanies();
      }
    }
  };

  if (loadingCompanies) {
    return (
      <div className="text-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="ListCompanies">
      <Row className="py-3">
        <Col>
          <div className="d-flex">
            <h1>Companies</h1>
            <span className="TotalResults ms-3">
              &quot;{companies.length} companies&quot;
            </span>
          </div>
        </Col>
        <Col className="text-end">
          <Button
            variant="secondary"
            className="AddButton"
            onClick={handleAddCompanyModal}
          >
            Add company
          </Button>
        </Col>
      </Row>

      <Row>
        {companies.map((company) => {
          return (
            <Col xs={12} md={6} lg={4} xl={3} key={company._id}>
              <CompanyCard
                company={company}
                onDelete={handleDeleteCompany}
                onEdit={handleEditCompanyModal}
              />
            </Col>
          );
        })}
      </Row>

      <CompanyModal
        show={showCompanyModal}
        isEditing={isModalEditing}
        company={company}
        onClose={handleShowModal}
        onSaved={handleSavedCompany}
      />
    </div>
  );
};

export default ListCompanies;

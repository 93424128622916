import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { SkillCategoryEnum } from "@micoach/js-sdk";

import CustomSelect from "Components/CustomSelect";

const SkillFiltersForm = (props) => {
  const { roles } = props;

  const { register, handleSubmit, control } = useForm();

  const onSubmit = (formValues) => {
    const filters = {};

    const filteredCategory = formValues.category?.filter?.(
      (category) => category
    );
    if (filteredCategory.length) {
      filters.category = filteredCategory;
    }

    if (formValues.role) {
      filters.role = formValues.role;
    }

    props.onSubmit?.(filters);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Form.Group controlId="category" label="Category" className="form-group">
        <Form.Label className="fs-16">Category</Form.Label>
        {Object.keys(SkillCategoryEnum).map((label, index) => (
          <Form.Check
            key={label}
            type="checkbox"
            id={`category-${index}`}
            name={`category[${index}]`}
            label={label}
            value={SkillCategoryEnum[label]}
            {...register(`category[${index}]`)}
          />
        ))}
      </Form.Group>
      <Form.Group controlId="role" label="Role" className="form-group">
        <Form.Label className="fs-16">Role</Form.Label>
        <CustomSelect
          name="role"
          control={control}
          options={roles.map((role) => {
            return { value: role._id, label: role.name };
          })}
        />
      </Form.Group>
      <Button variant="primary" type="submit" className="w-100">
        Apply filters
      </Button>
    </Form>
  );
};

SkillFiltersForm.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  onSubmit: PropTypes.func,
};

export default SkillFiltersForm;

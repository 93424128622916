import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "Components/Evaluation/Skill/styles/CoachOverviewCard.module.css";

const CoachOverviewCard = (props) => {
  const { evaluationPeriod } = props;

  if (!evaluationPeriod) return null;

  return (
    <Card className={`Card ${styles.CoachOverviewCard}`}>
      <Card.Body className={styles.Body}>
        <>
          <div className={styles.Subtitle}>Goals</div>
          <div>
            <pre className="WordWrap">{evaluationPeriod?.goals}</pre>
          </div>

          <div className={`${styles.Subtitle} mt-4`}>Summary evaluation</div>
          <div>
            <pre className="WordWrap">{evaluationPeriod?.summary}</pre>
          </div>
        </>
      </Card.Body>

      {props.onEdit && (
        <Card.Footer>
          <Button
            variant="link"
            onClick={() => props.onEdit?.(true)}
            className={styles.Edit}
          >
            <FontAwesomeIcon icon={faPen} className={styles.Icon} />
            Edit
          </Button>
        </Card.Footer>
      )}
    </Card>
  );
};

CoachOverviewCard.propTypes = {
  evaluationPeriod: PropTypes.shape({
    goals: PropTypes.string,
    summary: PropTypes.string,
  }),
  onEdit: PropTypes.func,
};

export default CoachOverviewCard;

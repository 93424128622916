import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

import { capitalize } from "utils.js";

import styles from "Components/Evaluation/Skill/styles/ProficiencyLevelDescriptionsModal.module.css";

const ProficiencyLevelDescriptionsModal = (props) => {
  const { skill, roleName, showModal } = props;

  return (
    <Modal
      show={showModal}
      onHide={props.onClose}
      contentClassName="FullScreen"
    >
      <Modal.Header closeButton>
        <Modal.Title className={styles.Title}>
          <Row>
            <Col>
              <div className={styles.SkillTitle}>{skill.name}</div>
              <div className={styles.Subtitle}>
                {`${capitalize(skill.category)} | ${roleName}`}
              </div>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.Body}>
        {skill.description && (
          <div
            className={styles.SkillDescription}
            dangerouslySetInnerHTML={{ __html: skill.description }}
          />
        )}

        <div className={styles.PlIntroductionMessage}>
          Behaviour expected at proficiency levels:
        </div>
        {skill.proficiencyLevels?.map?.((level) => {
          return (
            <Row key={level.level} className={styles.PlRow}>
              <Col xs={3} className={styles.PlLevelContainer}>
                <div className={styles.PlLevel}>{`PL${level.level}`}</div>
                <div className={styles.PlLevelText}>{level.levelText}</div>
              </Col>
              <Col className={styles.PlDescriptionContainer}>
                <div dangerouslySetInnerHTML={{ __html: level.description }} />
              </Col>
            </Row>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ProficiencyLevelDescriptionsModal.propTypes = {
  skill: PropTypes.object.isRequired,
  roleName: PropTypes.string.isRequired,
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
};

ProficiencyLevelDescriptionsModal.defaultProps = {
  showModal: false,
};

export default ProficiencyLevelDescriptionsModal;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import CustomSelect from "Components/CustomSelect";
import RoleQuizHandler from "Components/ProfileBuilder/Quiz/RoleQuizHandler";
import StepButton from "Components/ProfileBuilder/StepButton";
import { useUpdateProfile } from "Hooks/useUsers";
import { mapToSelectOptions } from "utils.js";

import styles from "Components/ProfileBuilder/styles/RoleStep.module.css";
import stylesAnimations from "Components/styles/Animations.module.css";

const RoleStep = (props) => {
  const { roles, onSaved, onOpenQuiz } = props;

  const [startQuiz, setStartQuiz] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });

  const { updateProfile, isUpdating, isSuccess, status, errorMessage } =
    useUpdateProfile();

  const rolesOptions = mapToSelectOptions(roles, "name", "name");

  const handleSelectedRole = async ({ role }) => {
    await updateProfile({
      role,
    });
  };

  const handleStartQuiz = () => {
    onOpenQuiz?.(stylesAnimations.FlipHide);

    setTimeout(
      () => {
        setStartQuiz(true);
        onOpenQuiz?.(stylesAnimations.Flip);
      },
      onOpenQuiz ? 500 : 0
    );
  };

  const handleExitQuiz = () => {
    onOpenQuiz?.(stylesAnimations.FlipHide);

    setTimeout(
      () => {
        setStartQuiz(false);
        onOpenQuiz?.(stylesAnimations.UnFlip);
      },
      onOpenQuiz ? 500 : 0
    );
  };

  useEffect(() => {
    let animationTimer;

    if (isSuccess) {
      if (startQuiz && onOpenQuiz) {
        onOpenQuiz(stylesAnimations.FlipHide);

        animationTimer = setTimeout(() => {
          onSaved?.();
          onOpenQuiz(stylesAnimations.UnFlip);
        }, 500);
      } else {
        onSaved?.();
      }
    } else {
      if (status && errorMessage) {
        const toastType = status >= 500 ? "error" : "info";
        toast[toastType](errorMessage);
      }
    }

    return () => clearTimeout(animationTimer);
  }, [isSuccess, status, startQuiz, errorMessage, onSaved, onOpenQuiz]);

  return (
    <>
      {startQuiz ? (
        <RoleQuizHandler
          onExit={handleExitQuiz}
          onCompleted={handleSelectedRole}
        />
      ) : (
        <>
          <Card.Title className={styles.Title}>
            What best describes your role
          </Card.Title>
          <Card.Subtitle className={styles.Subtitle}>
            Select the one that fits you.
          </Card.Subtitle>
          <Form onSubmit={handleSubmit(handleSelectedRole)} noValidate>
            <Form.Group className="form-group required" controlId="role">
              <Form.Label>Role</Form.Label>
              <CustomSelect
                name="role"
                control={control}
                options={rolesOptions}
                placeholder="Start typing to search in roles..."
                isInvalid={!!errors.role}
                disabled={isUpdating}
                isRequired
              />
              {errors.role && (
                <div className="invalid-feedback d-block">
                  {errors.role.type === "required" && "The role is required"}
                </div>
              )}
            </Form.Group>

            <div className={styles.QuizAndButtonContainer}>
              <StepButton
                label="Continue"
                type="submit"
                disabled={isUpdating}
              />

              <div className={styles.AnswerQuizContainer}>
                <span className={styles.Label}>
                  Can&apos;t find your role?{" "}
                  <Button
                    variant="link"
                    className={styles.LinkButton}
                    onClick={handleStartQuiz}
                  >
                    Answer Quiz
                  </Button>
                </span>
              </div>
            </div>
          </Form>
        </>
      )}
    </>
  );
};

RoleStep.propTypes = {
  roles: PropTypes.array,
  onSaved: PropTypes.func,
  onOpenQuiz: PropTypes.func,
};

RoleStep.defaultProps = {
  roles: [],
};

export default RoleStep;

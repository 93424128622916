import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { UserApi } from "micoach-api";
import { isDateAfter } from "utils";

import { useWindowSize } from "Hooks/useWindowSize";

const formInitialState = {
  isCurrent: false,
  degree: "",
  institution: "",
  startDate: null,
  endDate: null,
};

const EducationModal = (props) => {
  const { education, educationList, show } = props;
  const [isSaving, setIsSaving] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: formInitialState,
  });
  const windowSize = useWindowSize();
  const today = new Date();
  const isCurrent = watch("isCurrent", false);
  const startDate = watch("startDate");
  const endDate = watch("endDate");

  useEffect(() => {
    if (show) {
      if (education) {
        const startDate = education.startDate
          ? new Date(education.startDate)
          : null;
        const endDate = education.endDate ? new Date(education.endDate) : null;
        reset({
          ...education,
          startDate,
          endDate,
        });
      }
    } else {
      reset(formInitialState);
    }
  }, [education, show, reset]);

  const onSubmit = async (formValues) => {
    try {
      setIsSaving(true);
      const updatedList = [...educationList];
      const newEducation = {
        ...formValues,
        endDate: isCurrent ? undefined : formValues.endDate.toISOString(),
        startDate: formValues.startDate.toISOString(),
      };

      if (education) {
        const index = educationList.findIndex(
          (educationElement) => educationElement._id === education._id
        );
        updatedList[index] = newEducation;
      } else {
        updatedList.push(newEducation);
      }

      const response = await UserApi.updateProfile({
        education: updatedList,
      });

      props.onSaved?.(response.data);
      props.onClose?.();
      toast.success("Success! The education has been added.");
    } catch (error) {
      console.error(error);
      toast.error("Error, please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (isDateAfter(startDate, endDate)) {
      setValue("endDate", null);
    }
  }, [startDate, endDate, setValue]);

  return (
    <Modal show={show} onHide={props.onClose}>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Header closeButton>
          <Modal.Title>Education</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="isCurrent" className="form-group">
            <Form.Check
              type="checkbox"
              defaultChecked={false}
              label={<span className="fs-13 fw-bold">Current study</span>}
              name="isCurrent"
              {...register("isCurrent")}
            />
          </Form.Group>

          <Form.Group controlId="degree" className="form-group required">
            <Form.Label>Degree</Form.Label>
            <Form.Control
              type="text"
              name="degree"
              maxLength={100}
              placeholder="e.g., Bachelor Degree"
              {...register("degree", { required: true, maxLength: 100 })}
              isInvalid={!!errors.degree}
            />
            {errors.degree && (
              <div className="invalid-feedback d-block">
                {errors.degree.type === "required" && "Degree is required"}
                {errors.degree.type === "maxLength" && "Max length exceeded"}
              </div>
            )}
          </Form.Group>
          <Form.Group controlId="institution" className="form-group required">
            <Form.Label>Institution</Form.Label>
            <Form.Control
              type="text"
              maxLength={100}
              placeholder="e.g., Cambridge University"
              name="institution"
              {...register("institution", { required: true, maxLength: 100 })}
              isInvalid={!!errors.institution}
            />
            {errors.institution && (
              <div className="invalid-feedback d-block">
                {errors.institution.type === "required" &&
                  "Institution is required"}
                {errors.institution.type === "maxLength" &&
                  "Max length exceeded"}
              </div>
            )}
          </Form.Group>
          <div className="row">
            <Form.Group
              controlId="startDate"
              className="form-group required col-6 pe-1"
            >
              <Form.Label>Start date</Form.Label>
              <Controller
                name="startDate"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={null}
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    showPopperArrow={false}
                    selected={value}
                    onChange={onChange}
                    dateFormat="MMM, yyyy"
                    showMonthYearPicker
                    endDate={today}
                    maxDate={today}
                    className={`form-control ${
                      errors.startDate && "is-invalid"
                    }`}
                    placeholderText="Select a date"
                  />
                )}
              />
              {errors.startDate && (
                <div className="invalid-feedback d-block">
                  {errors.startDate.type === "required" &&
                    "Start date is required"}
                </div>
              )}
            </Form.Group>

            <Form.Group
              controlId="endDate"
              className="form-group required col-6 ps-1"
            >
              {!isCurrent && (
                <>
                  <Form.Label>End date</Form.Label>
                  <Controller
                    name="endDate"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    defaultValue={null}
                    render={({ field: { value, onChange } }) => (
                      <DatePicker
                        showPopperArrow={false}
                        selected={value}
                        onChange={onChange}
                        dateFormat="MMM, yyyy"
                        showMonthYearPicker
                        startDate={startDate}
                        minDate={startDate}
                        endDate={today}
                        maxDate={today}
                        className={`form-control ${
                          errors.endDate && "is-invalid"
                        }`}
                        placeholderText="Select a date"
                      />
                    )}
                  />
                  {errors.endDate && (
                    <div className="invalid-feedback d-block">
                      {errors.endDate.type === "required" &&
                        "End date is required"}
                    </div>
                  )}
                </>
              )}
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {windowSize.isMobile ? (
            <div className="d-grid gap-2 w-100 ps-3 pe-3">
              <Button
                variant="secondary"
                size="lg"
                onClick={props.onClose}
                disabled={isSaving}
              >
                Cancel
              </Button>
              <Button size="lg" type="submit" disabled={isSaving}>
                Save education
              </Button>
            </div>
          ) : (
            <>
              <Button
                variant="secondary"
                onClick={props.onClose}
                disabled={isSaving}
              >
                Cancel
              </Button>
              <Button type="submit" disabled={isSaving}>
                Save education
              </Button>
            </>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

EducationModal.propTypes = {
  education: PropTypes.array,
  educationList: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSaved: PropTypes.func,
};

export default EducationModal;

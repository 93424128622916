import PropTypes from "prop-types";

import StateCard from "Components/Common/StateCard";
import Loading from "Components/Loading";
import ReferralsOverviewSummary from "Components/Refer/ReferralsOverviewSummary";
import ReferralsOverviewTable from "Components/Refer/ReferralsOverviewTable";
import { assets } from "constants.js";

import styles from "Components/Refer/styles/ReferralsOverviewCard.module.css";

const ReferralsOverviewCard = (props) => {
  const {
    summaryStatus,
    referrals,
    isSaving,
    isHumanResources,
    loading,
    onUpdateStatus,
  } = props;

  return (
    <div className={styles.Card}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className={styles.Header}>
            <p>Referrals Overview</p>
            {!isHumanResources && (
              <ReferralsOverviewSummary summaryStatus={summaryStatus} />
            )}
          </div>

          {referrals.length > 0 ? (
            <ReferralsOverviewTable
              referrals={referrals}
              isSaving={isSaving}
              isHumanResources={isHumanResources}
              onUpdateStatus={onUpdateStatus}
            />
          ) : (
            <StateCard
              imageUrl={assets.REFERRAL_ZERO_STATE}
              imageWidth={120}
              title="No Referrals yet"
              subtitle="Once you&lsquo;ve referred someone for a job position, you&lsquo;ll see them listed here."
            />
          )}
        </>
      )}
    </div>
  );
};

ReferralsOverviewCard.propTypes = {
  summaryStatus: PropTypes.shape({
    referred: PropTypes.number,
    readyToClaim: PropTypes.number,
    claimed: PropTypes.number,
  }),
  referrals: PropTypes.array,
  loading: PropTypes.bool,
  isSaving: PropTypes.bool,
  isHumanResources: PropTypes.bool,
  onUpdateStatus: PropTypes.func,
};

ReferralsOverviewCard.defaultProps = {
  loading: false,
  isSaving: false,
  isHumanResources: false,
};

export default ReferralsOverviewCard;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { faInfoCircle, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PositionInterestsForm from "Components/Candidate/Profile/PositionInterestsForm";
import BadgeList from "Components/Common/BadgeList";
import SelectList from "Components/Common/SelectList";
import { useIndustries } from "Hooks/useIndustries";
import { usePublicRoles } from "Hooks/useRoles";
import BackendService from "Services/BackendService";
import { prioritizeArray } from "utils.js";

function ProfileInterests(props) {
  const { profile, onSaved } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { handleSubmit, reset, register } = useForm();

  useEffect(() => {
    reset({
      fullTime: profile.fullTime,
      partTime: profile.partTime,
      internship: profile.internship,
      onSite: profile.onSite,
      remote: profile.remote,
      hybrid: profile.hybrid,
      isWillingToRelocate: profile.isWillingToRelocate,
      isWillingToTravel: profile.isWillingToTravel,
    });
  }, [profile, reset]);

  // Industries
  const [, industries] = useIndustries();
  const [industryInterests, setIndustryInterests] = useState(
    profile.industryInterests ?? []
  );

  const handleChangeIndustryList = (selectedValues) => {
    setIndustryInterests(selectedValues);
  };

  // Roles
  const { roles } = usePublicRoles();
  const [roleInterests, setRoleInterests] = useState(
    profile.roleInterests ?? []
  );

  const handleChangeRoleList = (selectedValues) => {
    setRoleInterests(selectedValues);
  };

  // Form
  const onSubmit = async (formValues) => {
    setIsSaving(true);

    try {
      const updatedProfile = {
        industryInterests: prioritizeArray(industryInterests),
        roleInterests: prioritizeArray(roleInterests),
        fullTime: formValues.fullTime,
        partTime: formValues.partTime,
        internship: formValues.internship,
        onSite: formValues.onSite,
        remote: formValues.remote,
        hybrid: formValues.hybrid,
        isWillingToRelocate: formValues.isWillingToRelocate,
        isWillingToTravel: formValues.isWillingToTravel,
      };

      const data = await BackendService.updateProfile(updatedProfile);

      setIsEditing(false);

      if (onSaved) {
        onSaved(data);
      }
    } catch (error) {
      toast.error("Error, please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsEditing(false);
    reset();
  };

  const renderJobPreferences = (profile) => {
    const positionTypes = [];
    profile?.fullTime && positionTypes.push("Full-time");
    profile?.partTime && positionTypes.push("Part-time");
    profile?.internship && positionTypes.push("internship");
    profile?.onSite && positionTypes.push("On-site");
    profile?.remote && positionTypes.push("Remote");
    profile?.hybrid && positionTypes.push("hybrid");
    profile?.isWillingToRelocate && positionTypes.push("Open to relocate");
    profile?.isWillingToTravel && positionTypes.push("Open to travel");

    return positionTypes.join(", ");
  };

  return (
    <Card className="Card ProfileInterests">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card.Body>
          <Card.Title className="Title">Interests</Card.Title>
          <Card.Text className="Text">
            Providing details about your professional area allows us to offer
            you more personalized information.
          </Card.Text>

          {isEditing && (
            <Alert variant="light">
              <FontAwesomeIcon icon={faInfoCircle} />
              <span className="fs-14">
                You can drag and drop by the three dots at the left, to indicate
                your highest interests at the top.
              </span>
            </Alert>
          )}

          <Card.Text className="Subtitle">Industry</Card.Text>

          {isEditing ? (
            <SelectList
              selectTitle="Industry of your interest"
              selectOptions={industries}
              defaultValue={profile.industryInterests}
              optionLabel="name"
              optionValue="name"
              onChange={handleChangeIndustryList}
            />
          ) : (
            <BadgeList
              options={profile.industryInterests}
              label="name"
              variant="light"
              textColor="dark"
            />
          )}

          <hr />

          <Card.Text className="Subtitle">Job roles</Card.Text>

          {isEditing ? (
            <SelectList
              selectTitle="Job role of your interest"
              selectOptions={roles}
              defaultValue={profile.roleInterests}
              optionLabel="name"
              optionValue="name"
              onChange={handleChangeRoleList}
            />
          ) : (
            <BadgeList
              options={profile.roleInterests}
              label="name"
              variant="light"
              textColor="dark"
            />
          )}

          <hr />

          {isEditing ? (
            <>
              <Card.Text className="Subtitle">Job Preferences</Card.Text>
              <PositionInterestsForm register={register} />
            </>
          ) : (
            <>
              <span className="Subtitle">Job Preferences:&nbsp;</span>
              <span className="Text">{renderJobPreferences(profile)}</span>
            </>
          )}
        </Card.Body>

        <Card.Footer>
          {isEditing ? (
            <>
              <Button
                className="Cancel"
                variant="secondary"
                disabled={isSaving}
                onClick={handleClose}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                className="Save"
                variant="primary"
                disabled={isSaving}
              >
                Save
              </Button>
            </>
          ) : (
            <Button
              variant="link"
              className="Edit"
              onClick={() => setIsEditing(true)}
            >
              <FontAwesomeIcon icon={faPen} className="Icon" />
              Edit interests
            </Button>
          )}
        </Card.Footer>
      </Form>
    </Card>
  );
}

ProfileInterests.propTypes = {
  profile: PropTypes.object,
  onSaved: PropTypes.func,
};

export default ProfileInterests;

import PropTypes from "prop-types";
import micoach from "micoach-api";

import { filterSkills, getObjectKeyByValue } from "utils.js";

const SkillList = (props) => {
  const { category, skills } = props;

  if (!skills?.length) return null;

  return (
    <div>
      <p>{category} skills</p>
      <ul>
        {skills.map((skill) => (
          <li key={skill.name}>{skill.name}</li>
        ))}
      </ul>
    </div>
  );
};

const SkillsList = (props) => {
  const {
    data: { skills },
  } = props;

  if (!skills?.length) return null;

  const hardSkills = filterSkills(skills, micoach.SkillCategoryEnum.Hard);
  const softSkills = filterSkills(skills, micoach.SkillCategoryEnum.Soft);
  const techSkills = filterSkills(skills, micoach.SkillCategoryEnum.Tech);

  return (
    <div className="fs-13 m-3">
      <SkillList
        category={getObjectKeyByValue(
          micoach.SkillCategoryEnum,
          micoach.SkillCategoryEnum.Hard
        )}
        skills={hardSkills}
      />
      <SkillList
        category={getObjectKeyByValue(
          micoach.SkillCategoryEnum,
          micoach.SkillCategoryEnum.Soft
        )}
        skills={softSkills}
      />
      <SkillList
        category={getObjectKeyByValue(
          micoach.SkillCategoryEnum,
          micoach.SkillCategoryEnum.Tech
        )}
        skills={techSkills}
      />
    </div>
  );
};

SkillList.propTypes = {
  category: PropTypes.string,
  skills: PropTypes.array,
};

SkillsList.propTypes = {
  // Notice: data = role
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
      })
    ),
  }),
};

export default SkillsList;

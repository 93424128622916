import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Calendar, dayjsLocalizer, Views } from "react-big-calendar";
import Card from "react-bootstrap/Card";
import dayjs from "dayjs";

import InterviewCalendarEvent from "Components/Interview/Event/InterviewCalendarEvent";
import InterviewsCalendarHeader from "Components/Interview/InterviewsCalendarHeader";
import { getFormattedTimeZoneDate, getZonedDate } from "utils.js";

import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = dayjsLocalizer(dayjs);

const InterviewsCalendar = (props) => {
  const { interviews, date, onSelectMenuOption } = props;

  const [events, setEvents] = useState([]);

  const eventStyleGetter = (_event, _start, _end, _isSelected) => {
    return {
      style: {
        marginTop: "8px",
        backgroundColor: "#28A745",
        color: "#ffffff",
        borderRadius: "4px",
        padding: "6px",
        fontSize: "12px",
        fontWeight: "700",
        lineHeight: "15px",
        border: "none",
      },
    };
  };

  const getEvents = (newInterviews) => {
    return newInterviews.map((interview) => {
      return {
        interview,
        title: `${interview.applicationProcess.candidate.name} - ${
          interview.applicationProcess.position.title
        } - ${getFormattedTimeZoneDate(
          interview.startDate,
          "h:mm a"
        )} - ${getFormattedTimeZoneDate(interview.endDate, "h:mm a")}`,
        start: getZonedDate(interview.startDate),
        end: getZonedDate(interview.endDate),
      };
    });
  };

  const components = useMemo(
    () => ({
      toolbar: () => <InterviewsCalendarHeader date={date} />,
      event: ({ title, event }) => (
        <InterviewCalendarEvent
          title={title}
          interview={event.interview}
          onSelectMenuOption={onSelectMenuOption}
        />
      ),
    }),
    [onSelectMenuOption, date]
  );

  useEffect(() => {
    setEvents([...getEvents(interviews)]);
  }, [interviews]);

  return (
    <Card className="Card" data-testid="interviews-calendar">
      <Card.Body>
        <Calendar
          localizer={localizer}
          events={events}
          defaultView={Views.DAY}
          defaultDate={dayjs(date)
            .tz("America/Tijuana")
            .startOf("day")
            .add(0, "hour")
            .toDate()}
          getNow={() => getZonedDate(dayjs().toISOString())}
          min={dayjs(date).startOf("day").add(8, "hour").toDate()}
          max={dayjs(date).startOf("day").add(22, "hour").toDate()}
          dayLayoutAlgorithm="no-overlap"
          formats={{
            timeGutterFormat: "h A",
            eventTimeRangeFormat: () => null,
          }}
          eventPropGetter={eventStyleGetter}
          components={components}
        />
      </Card.Body>
    </Card>
  );
};

InterviewsCalendar.propTypes = {
  interviews: PropTypes.array,
  date: PropTypes.string,
  onSelectMenuOption: PropTypes.func,
};

InterviewsCalendar.defaultProps = {
  interviews: [],
  date: new Date().toISOString(),
};

export default InterviewsCalendar;

import { useParams } from "react-router";

import Achievement from "Components/Candidate/Achievement";
import StateCard from "Components/Common/StateCard";
import Loading from "Components/Loading";
import { usePublicCandidateAchievement } from "Hooks/useCandidateAchievements";
import { assets } from "constants.js";

const ViewPublicAchievement = () => {
  const { candidateId, achievementId } = useParams();
  const { candidateAchievement, isLoading } = usePublicCandidateAchievement(
    candidateId,
    achievementId
  );

  if (isLoading) {
    return <Loading />;
  }

  if (!candidateAchievement) {
    return (
      <StateCard imageUrl={assets.ZERO_STATE} title="Achievement not found!" />
    );
  }

  return <Achievement achievement={candidateAchievement} isPublic />;
};

export default ViewPublicAchievement;

import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
  faEllipsisH,
  faExternalLinkAlt,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "Components/Job/Template/styles/JobTemplateMenu.module.css";

const JobTemplateMenu = (props) => {
  const { onClickEdit, onClickDelete, onClickOpen } = props;

  return (
    <div className={styles.JobTemplateMenu}>
      <DropdownButton
        variant="link"
        title={
          <FontAwesomeIcon
            title="faEllipsisH"
            icon={faEllipsisH}
            className={styles.JobTemplateMenuIcon}
          />
        }
      >
        <Dropdown.Item
          as="button"
          className={styles.MenuItem}
          onClick={onClickOpen}
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} />
          Open
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          className={styles.MenuItem}
          onClick={onClickEdit}
        >
          <FontAwesomeIcon icon={faPen} />
          Edit
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          className={styles.MenuItem}
          onClick={onClickDelete}
        >
          <FontAwesomeIcon icon={faTrash} className={styles.DeleteIcon} />
          Delete
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
};

JobTemplateMenu.propTypes = {
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickOpen: PropTypes.func.isRequired,
};

export default JobTemplateMenu;

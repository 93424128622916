import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useForm } from "react-hook-form";
import Select from "react-select";
import micoach from "micoach-api";

import PeriodSelect from "Components/Evaluation/Skill/PeriodSelect";

import styles from "Components/Task/styles/TaskFilterButton.module.css";

const TaskFilterButton = (props) => {
  const { filterValues, coaches, isMobile } = props;

  const [showPopover, setShowPopover] = useState(false);
  const [filters, setFilters] = useState();
  const [totalFilters, setTotalFilters] = useState(0);
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [selectedCoach, setSelectedCoach] = useState();

  const { register, handleSubmit, reset, control } = useForm();

  const handleChangePeriod = (period) => {
    if (period) {
      const {
        value: { year, quarter },
      } = period;
      setSelectedPeriod({ year, quarter });
    } else {
      setSelectedPeriod(null);
    }
  };

  const handleChangeCoach = (coach) => {
    setSelectedCoach(coach);
  };

  const handleClickApply = (formValues) => {
    const newFilters = { ...formValues };

    selectedPeriod && (newFilters.period = selectedPeriod);
    selectedCoach && (newFilters.coach = selectedCoach);

    setFilters(newFilters);
    setTotalFilters(Object.keys(newFilters).length);
    setShowPopover(false);
    props.onApplyFilters?.(newFilters);
  };

  const handleClickReset = () => {
    setFilters(null);
    setTotalFilters(0);
    setSelectedPeriod(null);
    setSelectedCoach(null);
    setShowPopover(false);
    props.onApplyFilters?.({});
  };

  const handleClickClose = () => {
    setSelectedPeriod(filters?.period ?? null);
    setSelectedCoach(filters?.coach ?? null);
    setShowPopover(false);
  };

  useEffect(() => {
    setFilters(filterValues);
    setTotalFilters(Object.keys(filterValues ?? {}).length);
    setSelectedPeriod(filterValues?.period ?? null);
    setSelectedCoach(filterValues?.coach ?? null);
  }, [filterValues]);

  useEffect(() => {
    reset({
      status: filters?.status ?? "",
    });
  }, [reset, filters]);

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom-end"
      show={showPopover}
      onToggle={() => setShowPopover(true)}
      overlay={
        <Popover className={styles.Popover}>
          <Popover.Header className={styles.Title}>
            <span>Filters</span>
            <Button variant="link" onClick={handleClickReset}>
              Reset filters
            </Button>
          </Popover.Header>
          <Popover.Body className={styles.Content}>
            <Form onSubmit={handleSubmit(handleClickApply)}>
              <Form.Group controlId="period" className="form-group">
                <Form.Label>Period</Form.Label>
                <PeriodSelect
                  startPeriod={{ year: 2022, quarter: 3 }}
                  isClearable
                  isFirstSelected={false}
                  defaultValue={filters?.period}
                  onChange={handleChangePeriod}
                />
              </Form.Group>
              <Form.Group controlId="status" className="form-group">
                <Form.Label>Status</Form.Label>
                <div className={styles.Radios}>
                  <Form.Check
                    type="radio"
                    name="status"
                    id="status-todo"
                    data-testid="status-todo"
                    value={micoach.TaskStatusEnum.ToDo}
                    label={micoach.TaskStatusEnum.ToDo}
                    defaultChecked={
                      filters?.status === micoach.TaskStatusEnum.ToDo
                    }
                    {...register("status")}
                    inline
                  />
                  <Form.Check
                    type="radio"
                    name="status"
                    id="status-verify"
                    data-testid="status-verify"
                    value={micoach.TaskStatusEnum.Verify}
                    label={micoach.TaskStatusEnum.Verify}
                    defaultChecked={
                      filters?.status === micoach.TaskStatusEnum.Verify
                    }
                    {...register("status")}
                    inline
                  />
                  <Form.Check
                    type="radio"
                    name="status"
                    id="status-done"
                    data-testid="status-done"
                    value={micoach.TaskStatusEnum.Done}
                    label={micoach.TaskStatusEnum.Done}
                    defaultChecked={
                      filters?.status === micoach.TaskStatusEnum.Done
                    }
                    {...register("status")}
                    inline
                  />
                </div>
              </Form.Group>
              <Form.Group controlId="coach" className="form-group">
                <Form.Label>Coach</Form.Label>
                <Select
                  name="CoachSelect"
                  classNamePrefix="ReactSelect"
                  control={control}
                  options={coaches}
                  placeholder="Select coach"
                  onChange={handleChangeCoach}
                  defaultValue={filters?.coach}
                  isClearable
                />
              </Form.Group>

              <div className={styles.Buttons}>
                <Button variant="secondary" onClick={handleClickClose}>
                  Close
                </Button>
                <Button variant="primary" type="submit">
                  Apply
                </Button>
              </div>
            </Form>
          </Popover.Body>
        </Popover>
      }
    >
      <Button
        variant="link"
        onClick={() => setShowPopover(true)}
        className={styles.FiltersButton}
      >
        <img src="/assets/icon_filter.svg" alt="Filters" />
        <span>
          {`Filters ${isMobile && totalFilters > 0 ? `(${totalFilters})` : ""}`}
        </span>
      </Button>
    </OverlayTrigger>
  );
};

TaskFilterButton.propTypes = {
  filterValues: PropTypes.shape({
    period: PropTypes.object,
    status: PropTypes.string,
    coach: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }),
  coaches: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    })
  ),
  isMobile: PropTypes.bool,
  onApplyFilters: PropTypes.func,
};

TaskFilterButton.defaultProps = {
  isMobile: false,
};

export default TaskFilterButton;

import PropTypes from "prop-types";
import Highlighter from "react-highlight-words";
import { Controller } from "react-hook-form";
import Select from "react-select";
import AsyncSelect from "react-select/async";

function CustomSelect(props) {
  const formatOptionLabel = ({ label }, { inputValue }) => {
    return (
      <Highlighter
        searchWords={[inputValue]}
        textToHighlight={label}
        highlightClassName="fw-bold"
        highlightStyle={{ backgroundColor: "transparent", padding: 0 }}
      />
    );
  };

  let defaultValue = null;
  if (props.defaultValue) {
    // If defaultValue is an object, we set it to defaultValue.value.
    // Otherwise defaultValue will be an array and we use it as it is.
    defaultValue = props.defaultValue.value ?? props.defaultValue;
  }

  const customSelect = (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={defaultValue}
      rules={{
        required: props.isRequired,
        validate: (value) =>
          props.isMulti && props.isRequired && value.length > 0,
      }}
      render={({ field: { onChange } }) =>
        props.isAsync ? (
          <AsyncSelect
            loadOptions={props.options}
            defaultOptions={props.defaultOptions}
            onChange={(option) => {
              if (props.onChange) {
                props.onChange(option);
              }
              if (props.isMulti) {
                onChange(option);
              } else {
                onChange(option?.value);
              }
            }}
            isMulti={props.isMulti}
            isClearable={props.isClearable ?? true}
            isDisabled={props.disabled}
            isInvalid={props.isInvalid}
            classNamePrefix="ReactSelect"
            className={props.isInvalid ? "is-invalid" : ""}
            aria-labelledby={props.name}
            placeholder={props.placeholder ?? "Select..."}
            isLoading={props.isLoading}
            formatOptionLabel={formatOptionLabel}
            defaultValue={props.defaultValue ?? null}
            components={
              !props.defaultOptions && {
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }
            }
          />
        ) : (
          <Select
            id={props.name}
            options={props.options}
            onChange={(option) => {
              if (props.onChange) {
                props.onChange(option);
              }
              if (props.isMulti) {
                onChange(option);
              } else {
                onChange(option?.value);
              }
            }}
            isMulti={props.isMulti}
            isClearable={props.isClearable ?? true}
            isDisabled={props.disabled}
            isInvalid={props.isInvalid}
            classNamePrefix="ReactSelect"
            aria-labelledby={props.name}
            className={props.isInvalid ? "is-invalid" : ""}
            placeholder={props.placeholder ?? "Select..."}
            formatOptionLabel={props.formatOptionLabel || formatOptionLabel}
            defaultValue={props.defaultValue ?? null}
          />
        )
      }
    />
  );

  return customSelect;
}

CustomSelect.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  isAsync: PropTypes.bool,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any.isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
  ]),
  options: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any.isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
  ]),
  defaultOptions: PropTypes.bool,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  formatOptionLabel: PropTypes.func,
};

export default CustomSelect;

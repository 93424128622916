import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ProfileCompletionModal = (props) => {
  const { show, percentage } = props;

  const handleClose = () => {
    props.onClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>You are all set!</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          You have completed your basic profile information at a
          <strong> {percentage}%</strong>. You can update it whenever you need.
        </p>
        <p>
          It&apos;s important to include information regarding your experience,
          education and upload your resume. This help us to offer you accurate
          job recommendations, suggestions and help you in your career path!
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" variant="primary" onClick={handleClose}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ProfileCompletionModal.propTypes = {
  show: PropTypes.bool,
  percentage: PropTypes.number,
  onClose: PropTypes.func,
};

ProfileCompletionModal.defaultProps = {
  show: false,
  percentage: 0,
};

export default ProfileCompletionModal;

import { useState } from "react";
import PropTypes from "prop-types";

import ContactInfoStep from "Components/ProfileBuilder/ContactInfoStep";
import InterestsStep from "Components/ProfileBuilder/InterestsStep";
import RoleStep from "Components/ProfileBuilder/RoleStep";
import SkillsStep from "Components/ProfileBuilder/SkillsStep";
import StepCard from "Components/ProfileBuilder/StepCard";
import WelcomeStep from "Components/ProfileBuilder/WelcomeStep";

import styles from "Components/ProfileBuilder/styles/StepsHandler.module.css";

const StepsHandler = (props) => {
  const { userName, userEmail, roles } = props;

  const [stepCounter, setStepCounter] = useState(0);
  const [animationClass, setAnimationClass] = useState("");

  const updateStepCounter = () => {
    if (stepCounter + 1 < steps.length) {
      setStepCounter((stepCounter) => stepCounter + 1);
    }
  };

  const steps = [
    {
      component: (
        <WelcomeStep userName={userName} onClickNext={updateStepCounter} />
      ),
    },
    {
      stepNumber: 1,
      component: (
        <RoleStep
          roles={roles}
          onSaved={updateStepCounter}
          onOpenQuiz={(className) => setAnimationClass(className)}
        />
      ),
    },
    {
      stepNumber: 2,
      component: (
        <ContactInfoStep userEmail={userEmail} onSaved={updateStepCounter} />
      ),
      title: "Let’s stay in sync!",
      subtitle:
        "By sharing your contact information, we can stay in touch and keep you up to date.",
    },
    {
      stepNumber: 3,
      component: <InterestsStep onSaved={updateStepCounter} />,
      title: "Tell us about your desired job!",
      subtitle:
        "Feel free to select the conditions that you would like to have in a job.",
    },
    {
      stepNumber: 4,
      component: <SkillsStep onSaved={updateStepCounter} />,
      title: "Your Skills",
      subtitle:
        "We would like to know about your skills, interests and be able to offer you a personalized experience.",
    },
  ];

  return (
    <div className={`${styles.Wrapper} ${animationClass ?? ""}`}>
      <StepCard step={steps[stepCounter]} totalSteps={steps.length - 1} />
    </div>
  );
};

StepsHandler.propTypes = {
  roles: PropTypes.array,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
};

export default StepsHandler;

import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import EventPanelistsList from "Components/Interview/Event/EventPanelistsList";

import styles from "Components/Interview/Event/styles/EventTitle.module.css";

const EventTitle = (props) => {
  const { title, panelists, candidateId } = props;

  return (
    <>
      <OverlayTrigger
        placement={"top"}
        overlay={
          <Tooltip className={styles.Tooltip}>
            <EventPanelistsList panelists={panelists} />
          </Tooltip>
        }
      >
        <div className={styles.Title}>
          <a
            href={`/candidate/view/${candidateId}`}
            target="_blank"
            rel="noreferrer"
          >
            {title}
          </a>
        </div>
      </OverlayTrigger>
    </>
  );
};

EventTitle.propTypes = {
  title: PropTypes.string.isRequired,
  panelists: PropTypes.array,
  candidateId: PropTypes.string,
};

export default EventTitle;

import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Select from "react-select";

import { getQuarterYear } from "utils.js";

const PeriodSelect = (props) => {
  const {
    balanceDate,
    startPeriod,
    isClearable,
    isFirstSelected,
    defaultValue,
  } = props;

  const { control } = useForm();

  const getDefaultValue = () => {
    if (!isFirstSelected) {
      return defaultValue?.year
        ? {
            value: defaultValue,
            label: `Q${defaultValue.quarter} - ${defaultValue.year}`,
          }
        : null;
    }
    return periodOptions[0];
  };

  const getEvaluationPeriods = () => {
    const periods = [];

    for (let year = startPeriod.year; year <= balanceDate.year; year++) {
      // Start iterating from the START_PERIOD.quarter if year === START_PERIOD.year
      if (year === startPeriod.year) {
        for (let quarter = startPeriod.quarter; quarter <= 4; quarter++) {
          periods.push({ quarter, year });
          // Stop iterating if year equals current year and quarter = current quarter
          if (year === balanceDate.year && quarter === balanceDate.quarter) {
            break;
          }
        }
      } else {
        for (let quarter = 1; quarter <= 4; quarter++) {
          periods.push({ quarter, year });
          // Stop iterating if year equals current year and quarter = current quarter
          if (year === balanceDate.year && quarter === balanceDate.quarter) {
            break;
          }
        }
      }
    }

    const sortedPeriods = periods.sort((a, b) => {
      return b.year - a.year || b.quarter - a.quarter;
    });

    return sortedPeriods;
  };

  const periodOptions = getEvaluationPeriods().map((period) => {
    return {
      value: period,
      label: `Q${period.quarter} - ${period.year}`,
    };
  });

  const handlePeriodChange = (option) => {
    props.onChange?.(option);
  };

  return (
    <div data-testid="PeriodSelect">
      <Select
        name="PeriodSelect"
        classNamePrefix="ReactSelect"
        control={control}
        options={periodOptions}
        defaultValue={getDefaultValue()}
        onChange={handlePeriodChange}
        isClearable={isClearable}
      />
    </div>
  );
};

PeriodSelect.propTypes = {
  balanceDate: PropTypes.shape({
    year: PropTypes.number.isRequired,
    quarter: PropTypes.number.isRequired,
  }),
  startPeriod: PropTypes.shape({
    year: PropTypes.number.isRequired,
    quarter: PropTypes.number.isRequired,
  }),
  defaultValue: PropTypes.object,
  isClearable: PropTypes.bool,
  isFirstSelected: PropTypes.bool,
  onChange: PropTypes.func,
};

PeriodSelect.defaultProps = {
  balanceDate: {
    year: new Date().getFullYear(),
    quarter: getQuarterYear(),
  },
  startPeriod: { year: 2021, quarter: 4 },
  isClearable: false,
  isFirstSelected: true,
};

export default PeriodSelect;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import { faCompressAlt, faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CustomSelect from "Components/CustomSelect";
import { useCompanies } from "Hooks/useCompanies";
import { useSendEmail } from "Hooks/useEmail";
import { useEmailsSent } from "Hooks/useEmailsSent";
import { useEmailTemplates } from "Hooks/useEmailTemplates";
import { useRoles } from "Hooks/useRoles";
import { EMAIL_PATTERN, emailSubjectOptions } from "constants.js";
import { getEmailTemplateSubject, getFormattedLocalDate } from "utils.js";

import "react-quill/dist/quill.snow.css";

const EmailSendModal = (props) => {
  const { emailConfig, show } = props;
  const [isSending, setIsSending] = useState(false);
  const [message, setMessage] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [lastEmailSent, setLastEmailSent] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const { roles } = useRoles();
  const [companies] = useCompanies();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    formState,
    watch,
  } = useForm({ mode: "onChange" });

  const subjectValue = emailSubjectOptions[0]?.value;
  const roleValue = watch("role");
  const companyValue = watch("company");
  const { emailTemplates } = useEmailTemplates(subjectValue);
  const { emailsSent, loadEmailSents } = useEmailsSent();
  const { sendEmail } = useSendEmail();
  const { isValid } = formState;

  const onSubmit = async (formValues) => {
    if (isValid) {
      try {
        setIsSending(true);

        const emailProps = {
          to: formValues.email,
          subject: formValues.subject,
          message: message ?? "",
          data: { name: formValues.name },
          type: subjectValue,
          metadata,
        };

        await sendEmail(emailProps);
        props.onClose?.();
        toast.success(
          `The email to ${formValues.name} has been sent successfully.`
        );
      } catch (error) {
        console.error(error);
        toast.error("Error, please try again.");
      } finally {
        setIsSending(false);
      }
    }
  };

  const getTemplate = (role, company, templates) => {
    let template = templates.find(
      (template) =>
        template.metadata.company === company && template.metadata.role === role
    );

    if (!template) {
      template = templates.find(
        (template) =>
          template.metadata.company === company &&
          template.metadata.role === "None"
      );
    }

    if (!template) {
      template = templates.find(
        (template) =>
          template.metadata.role === role &&
          template.metadata.company === "None"
      );
    }

    if (!template) {
      template = templates.find(
        (template) =>
          template.metadata.company === "None" &&
          template.metadata.role === "None"
      );
    }
    return template;
  };

  const handleChangeMessage = (text, _delta, _source, editor) => {
    // getLength returns 1 when the textbox is empty, in that
    // case, message is set to null to disable the submit button.
    const message = editor.getLength() > 1 ? text : null;
    setMessage(message);
  };

  useEffect(() => {
    if (emailConfig && show) {
      reset({
        email: emailConfig.to,
        name: emailConfig.name,
        subject: getEmailTemplateSubject(subjectValue),
      });
    }
    setMessage(null);
    setFullScreen(false);
  }, [show, emailConfig, subjectValue, reset]);

  useEffect(() => {
    const template = getTemplate(
      roleValue ?? emailConfig.role,
      companyValue ?? emailConfig.company,
      emailTemplates
    );
    if (show && template) {
      setMessage(template.message);
    } else {
      setMessage(null);
    }

    if (emailConfig?.candidateId) {
      const newMetadata = {
        candidateId: emailConfig.candidateId,
        role: roleValue ?? emailConfig.role,
        company: companyValue ?? emailConfig.company,
      };
      setMetadata(newMetadata);
      loadEmailSents(subjectValue, newMetadata);
    }
  }, [
    companyValue,
    emailTemplates,
    roleValue,
    emailConfig,
    show,
    subjectValue,
    setMetadata,
    loadEmailSents,
  ]);

  useEffect(() => {
    if (emailsSent.length) {
      setLastEmailSent(emailsSent[0]);
    } else {
      setLastEmailSent(null);
    }
  }, [emailsSent]);

  // Roles
  const roleOptions = roles.map((role) => {
    return {
      value: role.name,
      label: role.name,
    };
  });

  // Companies
  const companyOptions = companies.map((company) => {
    return {
      value: company.name,
      label: company.name,
    };
  });

  return (
    <Modal
      show={show}
      onHide={props.onClose}
      contentClassName={fullScreen && "FullScreen"}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Send message{" "}
            <p className="mt-2 fs-16 fw-normal">
              Choose an option and customize your message if needed.
            </p>
          </Modal.Title>
          <button
            type="button"
            className="ActionButton"
            onClick={() => setFullScreen(!fullScreen)}
          >
            <FontAwesomeIcon icon={!fullScreen ? faExpandAlt : faCompressAlt} />
          </button>
        </Modal.Header>

        <Modal.Body>
          {lastEmailSent && (
            <Alert variant="warning" className="fs-14">
              <div>
                <p>{`Last email sent to this candidate on ${getFormattedLocalDate(
                  lastEmailSent.createdAt,
                  "MMMM DD, YYYY"
                )} with the following settings:`}</p>
                <ul>
                  <li>To: {lastEmailSent.to}</li>
                  <li>From: {lastEmailSent.from}</li>
                  {lastEmailSent.metadata?.role && (
                    <li>Role: {lastEmailSent.metadata.role}</li>
                  )}
                  {lastEmailSent.metadata?.company && (
                    <li>Company: {lastEmailSent.metadata.company}</li>
                  )}
                </ul>
              </div>
            </Alert>
          )}

          <Row>
            <Col xs={7}>
              <Form.Group controlId="email" className="form-group required">
                <Form.Label>Candidate Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  {...register("email", {
                    required: true,
                    pattern: EMAIL_PATTERN,
                  })}
                  isInvalid={!!errors.email}
                />

                {errors.email && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email.type === "pattern" &&
                      "Candidate email must be a valid email"}
                    {errors.email.type === "required" &&
                      "Candidate email is required"}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>

            <Col xs={5}>
              <Form.Group controlId="name" className="form-group required">
                <Form.Label>Candidate name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  {...register("name", { required: true })}
                  isInvalid={!!errors.name}
                />
                {errors.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name.type === "required" &&
                      "Candidate name is required"}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Form.Group controlId="subject" className="form-group required">
            <Form.Label>Subject</Form.Label>
            <Form.Control
              type="text"
              name="subject"
              {...register("subject", { required: true })}
              isInvalid={!!errors.subject}
            />
            {errors.subject && (
              <div className="invalid-feedback d-block">
                {errors.subject.type === "required" && "Subject is required"}
              </div>
            )}
          </Form.Group>

          <Form.Group controlId="company" className="form-group required">
            <Form.Label>Company</Form.Label>

            <CustomSelect
              name="company"
              isRequired
              control={control}
              options={companyOptions}
              defaultValue={companyOptions.find(
                (option) => option.value === emailConfig.company
              )}
            />
            {errors.company && (
              <div className="invalid-feedback d-block">
                {errors.company.type === "required" && "Company is required"}
              </div>
            )}
          </Form.Group>

          <Form.Group controlId="role" className="form-group required">
            <Form.Label>Role</Form.Label>

            <CustomSelect
              name="role"
              isRequired
              control={control}
              options={roleOptions}
              defaultValue={roleOptions.find(
                (option) => option.value === emailConfig.role
              )}
            />
            {errors.role && (
              <div className="invalid-feedback d-block">
                {errors.role.type === "required" && "Role is required"}
              </div>
            )}
          </Form.Group>

          <Form.Group controlId="message" className="form-group required">
            <Form.Label>Message template</Form.Label>
            <ReactQuill value={message} onChange={handleChangeMessage} />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="CancelButton"
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="SaveButton"
            disabled={isSending || !message}
          >
            Send
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

EmailSendModal.propTypes = {
  emailConfig: PropTypes.object,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EmailSendModal;

import { useMemo } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

import { formattedSize, getFileErrorMessage, mapToMimeType } from "utils.js";

import styles from "Components/Common/styles/DragDrop.module.css";

const activeStyle = {
  backgroundColor: "var(--snow-color)",
};

const acceptStyle = {
  borderColor: "var(--blue-medium-color)",
};

const rejectStyle = {
  borderColor: "var(--warning-color)",
};

const DragDrop = (props) => {
  const {
    allowedExtensions = ["pdf", "doc", "docx", "png", "jpg", "jpeg"],
    maxSize = 4,
    maxFiles = 10,
    isMultiple = false,
    isRequired = false,
    onDrop,
  } = props;

  const handleDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      for (const rejectedFile of rejectedFiles) {
        toast.error(
          <div>
            <span>
              There are some errors in the file <i>{rejectedFile.file.name}</i>
            </span>
            <ul>
              {rejectedFile.errors.map((error) => (
                <li key={error.code}>
                  {getFileErrorMessage(
                    error,
                    formattedSize(1024 * 1024 * maxSize)
                  )}
                </li>
              ))}
            </ul>
          </div>
        );
      }

      return;
    }

    onDrop(acceptedFiles);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: mapToMimeType(allowedExtensions),
    multiple: isMultiple,
    maxSize: 1024 * 1024 * maxSize, // maxSize prop is in MB, total is in bytes.
    maxFiles,
    onDrop: handleDrop,
  });
  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(isRequired && !isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept, isRequired]
  );

  return (
    <>
      <div
        className={styles.DragDrop}
        {...getRootProps({ style })}
        data-testid="drag-drop"
      >
        <input
          id="DragDropFileUpload"
          data-testid="drag-drop-input"
          {...getInputProps()}
        />
        <div>
          <span>Drag and drop files here or</span>
          <Button className={styles.BrowseButton} variant="link">
            browse
          </Button>
        </div>

        <div className={styles.AllowedExtensionsLabel}>
          {`* File formats accepted ${allowedExtensions.join(
            ", "
          )} maximum size ${formattedSize(1024 * 1024 * maxSize)}.`}
        </div>
      </div>
    </>
  );
};

DragDrop.propTypes = {
  allowedExtensions: PropTypes.arrayOf(PropTypes.string),
  maxSize: PropTypes.number, // In MB
  maxFiles: PropTypes.number,
  isMultiple: PropTypes.bool,
  isRequired: PropTypes.bool,
  onDrop: PropTypes.func,
};

export default DragDrop;

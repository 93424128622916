import { useState } from "react";
import { Accordion, Card } from "react-bootstrap";

import ReferralProcessStepList from "Components/Refer/ReferralProcessStepList";

import styles from "Components/Refer/styles/ReferralProcessAccordion.module.css";

const ReferralProcessAccordion = () => {
  const [showOpen, setShowOpen] = useState(false);

  return (
    <Card className="Card mt-0 mb-3">
      <Accordion>
        <Accordion.Header
          onClick={() => setShowOpen(!showOpen)}
          className={styles.Title}
        >
          How it works
        </Accordion.Header>
        <Accordion.Body>
          <ReferralProcessStepList />
        </Accordion.Body>
      </Accordion>
    </Card>
  );
};

export default ReferralProcessAccordion;

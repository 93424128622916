import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useHistory, useParams } from "react-router-dom/";
import { toast } from "react-toastify";

import ContactCard from "../Components/ContactCard";
import { useCompany } from "../Hooks/useCompanies";
import BackendService from "../Services/BackendService";

function ViewCompany() {
  const history = useHistory();
  const { id } = useParams();
  const [company, loadCompany] = useCompany(id);

  if (!company) {
    return null;
  }

  const onClickDelete = async (contactId) => {
    const ok = window.confirm("Are you sure you wish to delete this Contact?");
    if (ok) {
      try {
        await BackendService.deleteContact(id, contactId);
        toast.success("Success! The contact has been deleted.");
        await loadCompany();
      } catch (error) {
        toast.error("Failed to delete contact");
      }
    }
  };

  return (
    <div>
      <h1>{company.name}</h1>
      <hr />
      <br />
      <h4>Company contacts</h4>
      <Button
        variant="link"
        onClick={() => {
          history.push(`/company/${id}/contact/add`);
        }}
      >
        Add Contact
      </Button>
      <br />
      <Row>
        {company.contacts.map((contact) => {
          return (
            <Col xs={12} md={6} lg={4} key={contact._id}>
              <ContactCard contact={contact} onDelete={onClickDelete} />
            </Col>
          );
        })}
      </Row>
      <br />
    </div>
  );
}

export default ViewCompany;

import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import { ReportApi } from "micoach-api";

import { getIsoEndDate, getIsoStartDate } from "utils.js";

import styles from "Screens/Report/styles/Report.module.css";

const ReferredCandidates = () => {
  const [generatingReport, setGeneratingReport] = useState(false);

  const { control, formState, watch, handleSubmit } = useForm({
    mode: "onChange",
  });

  const today = new Date();

  const { isValid, errors } = formState;
  const watchStartDate = watch("startDate");

  const onSubmit = async (formValues) => {
    if (isValid) {
      setGeneratingReport(true);

      try {
        const { data } = await ReportApi.getReferredCandidates(
          getIsoStartDate(formValues.startDate),
          getIsoEndDate(formValues.endDate)
        );

        const blob = new Blob([data]);
        const fileName = "Referral Report.csv";
        saveAs(blob, fileName);
      } catch (error) {
        toast.error("Error!, please try again.");
      } finally {
        setGeneratingReport(false);
      }
    }
  };

  return (
    <>
      <h1 className="ScreenTitle">Candidate referral report</h1>
      <p className={styles.Description}>
        Lists referred candidates created between the selected dates.
      </p>
      <Row>
        <Col sm={8} md={6} xl={4}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Form.Group
                as={Col}
                controlId="starDate"
                className="required form-group"
              >
                <Form.Label>Start date</Form.Label>
                <Controller
                  name="startDate"
                  control={control}
                  defaultValue={today}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      showPopperArrow={false}
                      selected={value}
                      dateFormat="MMM dd, yyyy"
                      className={`form-control ${
                        errors.startDate ? "is-invalid" : ""
                      }`}
                      endDate={today}
                      maxDate={today}
                      selectsStart
                      title="Start date"
                      onChange={onChange}
                    />
                  )}
                />
                {errors.startDate && (
                  <div className="invalid-feedback d-block">
                    {errors.startDate.type === "required" &&
                      "Start date is required"}
                  </div>
                )}
              </Form.Group>

              <Form.Group
                as={Col}
                controlId="endDate"
                className="required form-group"
              >
                <Form.Label>End date</Form.Label>
                <Controller
                  name="endDate"
                  control={control}
                  defaultValue={today}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      showPopperArrow={false}
                      selected={value}
                      dateFormat="MMM dd, yyyy"
                      className={`form-control ${
                        errors.endDate ? "is-invalid" : ""
                      }`}
                      minDate={watchStartDate}
                      startDate={watchStartDate}
                      maxDate={today}
                      selectsEnd
                      title="End date"
                      onChange={onChange}
                    />
                  )}
                />
                {errors.endDate && (
                  <div className="invalid-feedback d-block">
                    {errors.endDate.type === "required" &&
                      "End date is required"}
                  </div>
                )}
              </Form.Group>
            </Row>

            <div className={styles.Buttons}>
              <Button type="submit" disabled={generatingReport}>
                <FontAwesomeIcon icon={faFileDownload} /> Download report
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ReferredCandidates;

import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import micoach from "micoach-api";

import { useWindowSize } from "Hooks/useWindowSize";

const LanguageProficiencyHelpModal = (props) => {
  const { show } = props;
  const windowSize = useWindowSize();
  const levels = [
    {
      title: micoach.ProficiencyEnum.Beginner,
      description:
        "The beginner language skill level covers those who are just starting to learn a new language. They know some basic words and phrases, but would not be able to create a grammatically correct sentence or carry on a conversation with someone in that language.",
    },
    {
      title: micoach.ProficiencyEnum.Intermediate,
      description:
        "An intermediate language speaker can hold a basic conversation in the language while speaking at a slower pace than a native speaker and requiring some repetition to understand the conversation. They have limited vocabulary knowledge, understand grammatical rules and have adequate reading abilities.",
    },
    {
      title: micoach.ProficiencyEnum.Proficient,
      description:
        "A proficient language ability involves the ability to speak, read and write the language with minimal difficulty. Proficient speakers can hold a conversation with a native speaker easily but may need some things repeated or colloquialisms explained. A proficient skill level means they are able to converse in the language.",
    },
    {
      title: micoach.ProficiencyEnum.Fluent,
      description:
        "A fluent language speaker can comfortably speak, write and understand the language with ease. They have full knowledge of the language, including colloquialisms, but are not native speakers of the language.",
    },
    {
      title: micoach.ProficiencyEnum.Native,
      description:
        "A native language ability refers to a language you grew up speaking and have mastered all aspects of, including grammar, complex concepts and extensive vocabulary.",
    },
  ];

  const renderLevels = () => {
    return levels.map((level) => {
      return (
        <div className="mt-4" key={level.title}>
          <strong>{level.title}</strong>
          <p className="mt-2">{level.description}</p>
        </div>
      );
    });
  };

  return (
    <Modal show={show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Language proficiency level</Modal.Title>
      </Modal.Header>
      <Modal.Body className="fs-13">
        <p>
          Before listing languages on your resume, review your speaking, reading
          and listening comprehension of the language. The language level
          descriptions below can help you determine whether you are a beginner,
          intermediate or fluent language speaker.
        </p>
        <hr className="my-4" />
        {renderLevels()}
      </Modal.Body>
      <Modal.Footer>
        {windowSize.isMobile ? (
          <div className="d-grid gap-2 w-100 ps-3 pe-3">
            <Button variant="primary" size="lg" onClick={props.onClose}>
              Close
            </Button>
          </div>
        ) : (
          <Button variant="primary" size="lg" onClick={props.onClose}>
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

LanguageProficiencyHelpModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default LanguageProficiencyHelpModal;

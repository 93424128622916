import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formattedSize, getMimeTypeIcon } from "utils";

import styles from "Components/Common/styles/DocumentItem.module.css";

const DocumentItem = (props) => {
  const {
    document: { name, type, size },
    isMultiple,
    isSaving,
    onDelete,
  } = props;

  if (name?.length === 0) {
    return null;
  }

  return (
    <div
      className={`${styles.DocumentItem} ${
        isMultiple ? styles.isMultiple : ""
      }`}
      data-testid="document-item"
    >
      <div className={styles.Container}>
        <FontAwesomeIcon
          size="lg"
          icon={getMimeTypeIcon(type)}
          aria-label="Type"
        />
        <div className={styles.Name} title={name}>
          {name}
        </div>
        {size && <span className={styles.Size}> | {formattedSize(size)}</span>}
      </div>
      <Button
        variant="link"
        className={styles.Delete}
        onClick={onDelete}
        disabled={isSaving}
      >
        <FontAwesomeIcon icon={faTrashAlt} size="lg" aria-label="Delete" />
      </Button>
    </div>
  );
};

DocumentItem.propTypes = {
  document: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.number,
  }).isRequired,
  isMultiple: PropTypes.bool,
  isSaving: PropTypes.bool,
  onDelete: PropTypes.func,
};

DocumentItem.defaultProps = {
  isMultiple: false,
  isSaving: false,
};

export default DocumentItem;

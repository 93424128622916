import { useCallback, useEffect, useState } from "react";
import { PositionApi } from "micoach-api";
import { encodeItemsInArray } from "utils";

export const useJobPositions = () => {
  const [jobPositions, setJobPositions] = useState([]);
  const [loadingJobPositions, setLoadingJobPositions] = useState(false);

  const loadJobPositions = useCallback(async (query) => {
    try {
      setLoadingJobPositions(true);

      const search = query?.search
        ? encodeURIComponent(query?.search)
        : undefined;
      const status = query?.status || undefined;
      const seniority = query?.seniority || undefined;
      const workModel = query?.workModel || undefined;
      const employment = query?.employment || undefined;
      const company = query?.company
        ? encodeItemsInArray(query?.company)
        : undefined;
      const recruiter = query?.recruiter || undefined;
      const limit = query?.limit || undefined;
      const offset = query?.offset || undefined;
      const sort = query?.sort || undefined;

      const { data } = await PositionApi.listPositions(
        search,
        limit,
        offset,
        sort,
        status,
        company,
        seniority,
        workModel,
        employment,
        recruiter
      );

      setJobPositions(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingJobPositions(false);
    }
  }, []);

  return {
    jobPositions,
    loadingJobPositions,
    loadJobPositions,
    setJobPositions,
  };
};

export const useAvailablePositions = (defaultQueryParams) => {
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [queryParams, setQueryParams] = useState(defaultQueryParams);

  const loadPositions = useCallback(async () => {
    try {
      setLoading(true);

      const search = queryParams?.search || undefined;
      const company = queryParams?.company || undefined;
      const seniority = queryParams?.seniority?.split(",") || undefined;
      const workModel = queryParams?.workModel?.split(",") || undefined;
      const employment = queryParams?.employment?.split(",") || undefined;
      const limit = queryParams?.limit || undefined;
      const offset = queryParams?.offset || undefined;
      const sort = queryParams?.sort || undefined;

      const { data } = await PositionApi.listAvailablePositions(
        search,
        limit,
        offset,
        sort,
        company,
        seniority,
        workModel,
        employment
      );

      setPositions(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [queryParams]);

  useEffect(() => {
    loadPositions();
  }, [loadPositions]);

  return [loading, positions, setQueryParams, loadPositions];
};

export const useJobPosition = (id) => {
  const [jobPosition, setJobPosition] = useState(null);
  const [loadingJobPosition, setLoadingJobPosition] = useState(false);
  const [jobPositionId, setJobPositionId] = useState(id);

  const loadJobPosition = useCallback(async () => {
    if (jobPositionId) {
      setLoadingJobPosition(true);
      try {
        const { data } = await PositionApi.getPosition(jobPositionId);

        setJobPosition(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingJobPosition(false);
      }
    }
  }, [jobPositionId]);

  return {
    jobPosition,
    loadingJobPosition,
    loadJobPosition,
    setJobPositionId,
    setJobPosition,
  };
};

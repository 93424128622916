import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";

import { capitalize } from "utils.js";

import styles from "Components/Evaluation/Skill/styles/FeedbackCard.module.css";

const FeedbackCard = (props) => {
  const { feedback } = props;

  return (
    <Card className="Card mb-3">
      <Card.Body>
        <div className={styles.Relation}>
          {capitalize(feedback.evaluatorRelation)}
        </div>
        <div className={styles.Evaluator}>
          {feedback.userName && `${feedback.userName} - `}{" "}
          {feedback.evaluatorEmail}
        </div>
        <div className={styles.Comment}>{feedback.comments}</div>
      </Card.Body>
    </Card>
  );
};

FeedbackCard.propTypes = {
  feedback: PropTypes.object.isRequired,
};

export default FeedbackCard;

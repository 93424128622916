import React from "react";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";
import { areEqual } from "react-window";

import { kanbanColumns } from "constants.js";

import KanbanItem from "./KanbanItem";

// This component is required by react-windows, the props data, index, style are provided by default
const KanbanRow = (props) => {
  const { data, index, style } = props;

  const {
    candidates,
    step,
    userId,
    isExternalRecruiter,
    onClickHire,
    onClickDiscard,
    onClickMessage,
    onClickMove,
    onClickRequestDocuments,
  } = data;

  const candidate = candidates[index];
  // NOTE: Cards cannot be moved from Hired to avoid erros
  const isDragDisabled = step === kanbanColumns.HIRED.key;

  // This is necessary because we are rendering an extra item for the placeholder
  if (!candidate) {
    return null;
  }

  return (
    <Draggable
      draggableId={candidate._id}
      index={index}
      key={candidate._id}
      isDragDisabled={isDragDisabled}
    >
      {(provided) => (
        <KanbanItem
          step={step}
          index={index}
          candidate={candidate}
          provided={provided}
          style={style}
          userId={userId}
          isExternalRecruiter={isExternalRecruiter}
          onClickHire={onClickHire}
          onClickDiscard={onClickDiscard}
          onClickMessage={onClickMessage}
          onClickMove={onClickMove}
          onClickRequestDocuments={onClickRequestDocuments}
        />
      )}
    </Draggable>
  );
};

KanbanRow.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  onClickRequestDocuments: PropTypes.func,
};

export default React.memo(KanbanRow, areEqual);

import { useCallback, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { ReferApi, UserApi } from "micoach-api";

export const useUserReferrals = () => {
  const [userReferrals, setUserReferrals] = useState([]);
  const [loadingUserReferrals, setLoadingUserReferrals] = useState(false);
  const { keycloak } = useKeycloak();

  const loadUserReferrals = useCallback(async () => {
    try {
      setLoadingUserReferrals(true);

      const userId = keycloak.tokenParsed.sub;

      const { data } = await UserApi.listUserReferrals(userId);

      setUserReferrals(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUserReferrals(false);
    }
  }, [keycloak.tokenParsed.sub]);

  return {
    userReferrals,
    loadingUserReferrals,
    loadUserReferrals,
    setUserReferrals,
  };
};

export const useReferrals = () => {
  const [referrals, setReferrals] = useState([]);
  const [loadingReferrals, setLoadingReferrals] = useState(false);

  const loadReferrals = useCallback(async (query) => {
    try {
      setLoadingReferrals(true);
      const status = query?.status;
      const position = query?.position;

      const { data } = await ReferApi.listReferrals(status, position);

      setReferrals(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReferrals(false);
    }
  }, []);

  return {
    referrals,
    loadingReferrals,
    loadReferrals,
    setReferrals,
  };
};

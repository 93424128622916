import { matchPath } from "react-router";
import { Route, Switch, useLocation } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import micoach from "micoach-api";
import routes from "routes.js";

import candidateLayout from "Layouts/CandidateLayout";
import recruiterLayout from "Layouts/RecruiterLayout";
import NotFound from "Screens/NotFound";
import { getRoles } from "utils.js";

const App = () => {
  let userRoles = [];
  let userRoutes = [];

  const { keycloak, initialized } = useKeycloak();
  const location = useLocation();

  library.add(fas);

  keycloak.onTokenExpired = async () => {
    try {
      await keycloak.updateToken();
    } catch (error) {
      console.error("Failed to refresh the token, or the session has expired");
      window.location.href = "/logout";
    }
  };

  if (!initialized) {
    return null;
  }

  if (keycloak.authenticated) {
    window.parent.postMessage("authenticated", "*");
  }

  userRoutes = routes.filter((route) => route.roles.includes("public"));
  const isCurrentPathPublic = matchPath(location.pathname, {
    path: userRoutes.map((route) => route.path),
    exact: true,
  });

  if (!(isCurrentPathPublic && !keycloak.authenticated)) {
    if (!keycloak.authenticated && process.env.REACT_APP_ENV_NAME !== "local") {
      window.location.href = "/logout";
      return;
    }

    userRoles = getRoles(keycloak);
    userRoutes = routes?.filter((route) =>
      route.roles.some((role) => userRoles?.includes(role))
    );
  }

  let layout = candidateLayout;

  if (
    userRoles?.includes(micoach.UserRoleEnum.Recruiter) ||
    userRoles?.includes(micoach.UserRoleEnum.HumanResources) ||
    userRoles?.includes(micoach.UserRoleEnum.ExternalRecruiter)
  ) {
    layout = recruiterLayout;
  }

  return (
    <Switch>
      {userRoutes.map((route) => {
        return (
          <Route
            key={route.path}
            path={route.path}
            exact
            component={
              route.layout
                ? route.layout(route.component, userRoles)
                : layout(route.component, userRoles)
            }
          />
        );
      })}

      <Route component={NotFound} />
    </Switch>
  );
};

export default App;

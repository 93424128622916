import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { ApplicationProcessApi } from "micoach-api";

import CustomSelect from "Components/CustomSelect";
import { useAvailablePositions } from "Hooks/usePositions";
import { kanbanColumns } from "constants.js";
import { getIsoStartDate } from "utils.js";

const ApplicationHireModal = (props) => {
  const { show, candidate, onClose } = props;
  const applicationProcess = candidate?.applicationProcess;

  const [isSaving, setIsSaving] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });
  const [loadingPositions, positions, setQueryPositionParams] =
    useAvailablePositions();

  const onSubmit = async (formValues) => {
    if (isValid) {
      try {
        setIsSaving(true);

        const requestBody = {
          firstDayOfWorkAt: getIsoStartDate(formValues.firstDayOfWorkAt),
        };

        if (!applicationProcess.position && formValues.positionId) {
          requestBody.position = formValues.positionId;
        }

        const response = await ApplicationProcessApi.updateApplicationProcess(
          candidate?.applicationProcess?._id,
          requestBody
        );

        await ApplicationProcessApi.createOrUpdateStep(
          candidate?.applicationProcess?._id,
          {
            step: kanbanColumns.OFFER_ACCEPTED.key,
          }
        );

        props.onSaved?.(response.data);
      } catch (error) {
        console.error(error);
        props.onError?.("Error, please try again.");
      } finally {
        setIsSaving(false);
      }
    }
  };

  const positionsOptions = (inputValue) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        setQueryPositionParams({ search: inputValue, limit: 10 });
        const newValues = positions.map((position) => {
          return {
            value: position._id,
            label: `${position.title} - ${position.company?.name ?? ""}`,
          };
        });
        resolve(newValues);
      }, 100);
    });
  };

  useEffect(() => {
    if (show) {
      reset({
        positionId: applicationProcess?.position ?? null,
        firstDayOfWorkAt: applicationProcess?.firstDayOfWorkAt
          ? new Date(applicationProcess?.firstDayOfWorkAt)
          : new Date(),
      });
    }
  }, [show, applicationProcess, reset]);

  return (
    <Modal show={show} onHide={onClose} backdrop="static">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Offer accepted by &apos;{candidate?.name}&apos;
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            {candidate?.name} offer will be set as accepted after confirmation.
          </div>
          {!applicationProcess?.position && (
            <Form.Group
              controlId="positionId"
              className="form-group required mb-0 mt-3"
            >
              <Form.Label>Search position</Form.Label>
              <CustomSelect
                name="positionId"
                isRequired
                isInvalid={!!errors.positionId}
                isAsync
                defaultOptions
                control={control}
                options={positionsOptions}
                loading={loadingPositions}
                placeholder="Start typing to search in positions..."
              />
              {errors.positionId && (
                <div className="invalid-feedback d-block">
                  {errors.positionId.type === "required" &&
                    "Position is required"}
                </div>
              )}
            </Form.Group>
          )}

          <Form.Group
            controlId="firstDayOfWorkAt"
            className="form-group required mb-0 mt-3"
          >
            <Form.Label>First day of work</Form.Label>
            <Controller
              name="firstDayOfWorkAt"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  showPopperArrow={false}
                  selected={value}
                  onChange={onChange}
                  dateFormat="MMM dd, yyyy"
                  className={`form-control ${
                    errors.firstDayOfWorkAt ? "is-invalid" : ""
                  }`}
                />
              )}
            />
            {errors.firstDayOfWorkAt && (
              <div className="invalid-feedback d-block">
                {errors.firstDayOfWorkAt.type === "required" &&
                  "First day of work is required"}
              </div>
            )}
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="CancelButton"
            disabled={isSaving}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button type="submit" className="SaveButton" disabled={isSaving}>
            Confirm
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

ApplicationHireModal.propTypes = {
  show: PropTypes.bool.isRequired,
  candidate: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSaved: PropTypes.func,
  onError: PropTypes.func,
};

export default ApplicationHireModal;

import PropTypes from "prop-types";
import dayjs from "dayjs";

function FormattedDate(props) {
  const { variant, children } = props;

  let format = "MMM DD, YYYY";

  if (variant === "dateTime") {
    format = "MMM DD, YYYY, h:mm a";
  } else if (variant === "monthDay") {
    format = "MMM DD";
  } else if (variant === "monthYear") {
    format = "MMM YYYY";
  } else if (variant === "year") {
    format = "YYYY";
  }

  return <span>{dayjs(children).format(format)}</span>;
}

FormattedDate.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node,
};

export default FormattedDate;

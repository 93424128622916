import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";

import styles from "Components/Task/styles/TaskDescription.module.css";

const TaskDescription = (props) => {
  const { description } = props;

  const MAX_CHARS_DESCRIPTION_TO_SHOW = 200;

  const [showFullDescription, setShowFullDescription] = useState(false);

  const handleShowFullDescription = () => {
    return description.length < MAX_CHARS_DESCRIPTION_TO_SHOW ||
      showFullDescription
      ? description
      : description.slice(0, MAX_CHARS_DESCRIPTION_TO_SHOW) + "...";
  };

  return (
    <>
      <p className={styles.Description} data-testid="task-description">
        {handleShowFullDescription()}
      </p>

      {description.length > MAX_CHARS_DESCRIPTION_TO_SHOW && (
        <Button
          variant="link"
          className={styles.DescriptionShowButton}
          onClick={() => setShowFullDescription(!showFullDescription)}
        >
          {showFullDescription ? "View less" : "View more"}
        </Button>
      )}
    </>
  );
};

TaskDescription.propTypes = {
  description: PropTypes.string,
};

export default TaskDescription;

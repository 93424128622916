import { useCallback, useEffect, useState } from "react";
import { RankingApi } from "micoach-api";

export const useTrainingsRankingByViews = (limit) => {
  const [ranking, setRanking] = useState([]);
  const [loadingRanking, setLoadingRanking] = useState(false);

  const loadRanking = useCallback(async () => {
    setLoadingRanking(true);
    try {
      const { data } = await RankingApi.getTrainingsRankingByViews(limit);
      setRanking(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRanking(false);
    }
  }, [limit]);

  useEffect(() => {
    loadRanking();
  }, [loadRanking]);

  return { ranking, loadingRanking };
};

export const useUsersRankinkgByPoints = (limit, userId) => {
  const [ranking, setRanking] = useState();
  const [loadingRanking, setLoadingRanking] = useState(false);

  const loadRanking = useCallback(async () => {
    setLoadingRanking(true);
    try {
      const { data } = await RankingApi.getUsersRankingByPoints(limit, userId);
      setRanking(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRanking(false);
    }
  }, [limit, userId]);

  useEffect(() => {
    loadRanking();
  }, [loadRanking]);

  return { ranking, loadingRanking };
};

import { toast } from "react-toastify";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import micoach from "micoach-api";
import qs from "qs";

import {
  applicationProcessSteps,
  candidateStatus,
  checklistItems,
  companiesDomains,
  countries,
  EMAIL_PATTERN,
  emailSubjectOptions,
  eventResourceIcons,
  kanbanColumns,
  MICOACH_API_URL,
  mimeTypesIcons,
  positionStatus,
  positionTypes,
  sources,
} from "constants.js";

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

export const getApplicationProcessStepName = (key) => {
  return applicationProcessSteps.find((step) => step.value === key)?.label;
};

export const getChecklistItemName = (key) => {
  return checklistItems.find((item) => item.key === key)?.value;
};

export const getBooleanParamValue = (queryParamValue, localStorageKey) => {
  if (queryParamValue === null || queryParamValue === "") {
    return localStorage.getItem(`micoach_${localStorageKey}`) === "true";
  }
  return queryParamValue === "true";
};

export const getLocalStorageItem = (key) => {
  return localStorage.getItem(`micoach_${key}`);
};
export const setLocalStorageItem = (key, value) => {
  localStorage.setItem(`micoach_${key}`, value);
};

export const getTitle = (myData, title) => {
  return myData ? `My ${title.toLowerCase()}` : title;
};

export const getRecruiter = (myData, keycloak) => {
  return myData ? keycloak.subject : "";
};

export const getSource = (key) => {
  return sources.find((type) => type.key === key)?.name;
};

export const getPositionType = (key) => {
  return positionTypes.find((type) => type.key === key)?.value;
};

export const getPositionStatus = (key) => {
  return positionStatus.find((type) => type.value === key)?.label;
};

export const getCountry = (key) => {
  if (!key) return "-";
  return countries.find((type) => type.isoCode === key)?.name;
};

export const getState = (countryCode, stateCode) => {
  if (!countryCode || !stateCode) return "-";
  const country = countries.find((country) => country.isoCode === countryCode);
  if (!country) return "-";
  const state = country.states.find((state) => state.isoCode === stateCode);
  if (!state) return "-";
  return state.name;
};

export const getStatesFromSelectedCountry = (selectedCountryCode) => {
  if (!selectedCountryCode) return [];
  const country = countries.find(
    (country) => country.isoCode === selectedCountryCode
  );
  if (!country) return [];
  return country.states;
};

export const filterSkills = (skills, category) => {
  if (!skills) return [];
  return skills.filter((skill) => skill.category === category);
};

export const getObjectKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

// Dates
/**
 * Checks if a date is after another date.
 * @param {Date|string} startDate - The start date.
 * @param {Date|string} endDate - The end date.
 * @returns {boolean} `true` if the start date is after the end date, `false` otherwise.
 */
export const isDateAfter = (startDate, endDate) => {
  return dayjs(startDate).isAfter(dayjs(endDate));
};

export const formattedDate = (format, date) => {
  return dayjs(date ?? new Date())
    .utc()
    .format(format);
};

export const getFormattedDate = (date, format) => {
  const parsedDate = dayjs(date);
  return parsedDate.isValid() ? parsedDate.utc(true).format(format) : "";
};

export const getFormattedLocalDate = (date, format = "MMM D, YYYY") => {
  const parsedDate = dayjs(date);
  return parsedDate.isValid() ? parsedDate.format(format) : "";
};

export const getFormattedTimeZoneDate = (
  date,
  format = "MMM D, YYYY",
  timezone = "America/Tijuana"
) => {
  const parsedDate = dayjs(date);
  return parsedDate.isValid() ? parsedDate.tz(timezone).format(format) : "";
};

export const getDate = (stringDate, format = "YYYY-MM-DDTHH:mm:ss.SSS") => {
  const parsedDate = dayjs(stringDate, format);
  return parsedDate.isValid() ? parsedDate.toDate() : null;
};

export const getLocalDate = (stringDate) => {
  const parsedDate = dayjs(stringDate);
  return parsedDate.isValid() ? parsedDate.toDate() : null;
};

export const getDateWithTimeZone = (date, timezone = "America/Tijuana") => {
  const parsedDate = dayjs(date);
  return parsedDate.isValid() ? parsedDate.tz(timezone).toDate() : "";
};

export const getStartOfTime = (stringDate, unit) => {
  const parsedDate = dayjs(stringDate).startOf(unit);
  return parsedDate.isValid() ? parsedDate.toDate() : null;
};

export const getEndOfTime = (stringDate, unit) => {
  const parsedDate = dayjs(stringDate);
  return parsedDate.isValid() ? parsedDate.endOf(unit).toDate() : null;
};

export const getIsoStartDate = (date) => {
  return getStartOfTime(date, "day")?.toISOString?.() || "";
};

export const getIsoEndDate = (date) => {
  return getEndOfTime(date, "day")?.toISOString?.() || "";
};

export const getDurationDatesText = (date1, date2) => {
  const dateA = dayjs(date1);
  const dateB = dayjs(date2);
  const differenceInMths = dateB.diff(dateA, "month");
  const years = Math.floor(differenceInMths / 12);
  const months = differenceInMths % 12;

  // Duration text for 0 months and 0 years
  if (differenceInMths === 0) {
    return "";
  }

  const mthsText = months === 1 ? "mth" : "mths";
  const yrsText = years === 1 ? "yr" : "yrs";

  // Duration text for the first 11 months
  if (differenceInMths >= 1 && differenceInMths <= 11) {
    return ` - ${months} ${mthsText}`;
  }

  // Duration text after 11 months
  if (differenceInMths >= 12) {
    return ` - ${years} ${yrsText}, ${months} ${mthsText}`;
  }
};

export const getDateOfCurrentWeekday = (date, weekDayNumber) => {
  return dayjs(date).day(weekDayNumber).endOf("day").toISOString();
};

export const getDateOfLastWeekday = (date, weekDayNumber) => {
  return dayjs(date)
    .day(weekDayNumber)
    .subtract(1, "week")
    .startOf("day")
    .toISOString();
};

export const getRelativeTime = (date) => {
  const today = dayjs();
  const dateA = dayjs(date);

  const result = dayjs.duration(today.diff(dateA)).humanize();

  return result.charAt(0).toUpperCase() + result.slice(1);
};

/**
 * Returns a date object in the specified timezone based on the provided date string.
 * If no timezone is specified, the default timezone is "America/Tijuana".
 * @param {string} dateString - The date string (e.g. "2022-05-01T12:00:00.000Z").
 * @param {string} [timezone='America/Tijuana'] - The timezone identifier (e.g. "America/Tijuana").
 * @returns {Date} - A date object in the specified timezone.
 */
export const getZonedDate = (dateString, timezone = "America/Tijuana") =>
  dayjs(
    dayjs(dateString).tz(timezone).format("YYYY-MM-DDTHH:mm:ss.SSS")
  ).toDate();

// Documents (Files)

export const formattedSize = (bytes) => {
  const sizes = ["Bytes", "kB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Bytes";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 4) + " " + sizes[i];
};

export const getFileNameWithoutExtension = (filename) => {
  return filename?.substr?.(0, filename?.lastIndexOf?.("."));
};

export const getFileExtension = (filename) => {
  return filename?.substr?.(filename?.lastIndexOf?.(".") + 1, filename?.length);
};

export const getMimeTypeIcon = (contentType) => {
  const icon = mimeTypesIcons.find((icon) => icon.key === contentType);
  if (icon === undefined) return "";
  return icon.value;
};

export const getEventResourceIcon = (type) => {
  return eventResourceIcons.find((icon) => icon.type === type.toLowerCase());
};

/**
 * Map an array of extension to its mimetype
 * @param {Object[]} extensions - Array of strings with extensions without dot (i.e. ["doc", "docx"])
 * @returns New array with strings of mime type of provided extensions
 */
export const mapToMimeType = (extensions) => {
  return extensions.map((extension) => {
    switch (extension) {
      case "doc":
        return "application/msword";
      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case "xls":
        return "application/vnd.ms-excel";
      case "xlsx":
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      case "pdf":
        return "application/pdf";
      case "png":
        return "image/png";
      case "jpeg":
        return "image/jpeg";
      case "jpg":
        return "image/jpeg";
      case "csv":
        return "text/csv";
      default:
        return "";
    }
  });
};

export const getFileErrorMessage = (error, formattedSize) => {
  switch (error.code) {
    case "file-too-large":
      return `The file is larger than ${formattedSize} allowed.`;
    case "file-invalid-type":
      return "The file format is not accepted.";
    default:
      return error.message;
  }
};

/**
 * Retrieves the document based on the type provided.
 *
 * @param {Array} documents - An array of documents to filter.
 * @returns {Object|undefined} - The first resume document if found, or undefined if none exists.
 */
export const getDocumentByType = (documents, type) => {
  return documents?.find((document) => document.checklistType === type);
};

// Candidates

export const sortCandidatesByStepDate = (candidates) => {
  return candidates.sort((a, b) => {
    if (!a.applicationProcess?.steps || !b.applicationProcess?.steps) {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    }

    const stepA = a.applicationProcess.steps[0];
    const stepB = b.applicationProcess.steps[0];

    return new Date(stepB.updatedAt) - new Date(stepA.updatedAt);
  });
};

export const sortCandidatesByStatusDate = (candidates) => {
  return candidates.sort((a, b) => {
    return new Date(b.statusUpdatedAt) - new Date(a.statusUpdatedAt);
  });
};

export const getCandidateStatusFromStep = (step) => {
  return step === kanbanColumns.LEADS.key ? candidateStatus.LEAD.key : step;
};

export const getStepFromCandidateStatus = (status) => {
  return status === candidateStatus.LEAD.key ? kanbanColumns.LEADS.key : status;
};

export const filterCommentsByStatus = (comments, status) => {
  if (!comments) {
    return [];
  }

  // Filter candidate comments to those that match the provided status
  return comments.filter((comment) => comment.metadata?.step === status);
};

// Kanban

export const columnIsStep = (column) => {
  return kanbanColumns[column]?.isStep;
};

// Function to sort an array by property string type
export const sortByProperty = (array, property, order) => {
  return array.sort((a, b) => {
    const propertyA =
      typeof a[property] === "number"
        ? a[property]
        : a?.[property]?.toUpperCase?.();
    const propertyB =
      typeof b[property] === "number"
        ? b[property]
        : b?.[property]?.toUpperCase?.();

    if (order === "asc") {
      if (propertyA < propertyB) {
        return -1;
      }
      if (propertyA > propertyB) {
        return 1;
      }
    }
    if (order === "desc") {
      if (propertyA > propertyB) {
        return -1;
      }
      if (propertyA < propertyB) {
        return 1;
      }
    }
    return 0;
  });
};

// List of early steps (all steps before Teachnical Interview):
// in these steps, both the Position and the Discarding Reasons
// are NOT mandatory

export const isEarlyStep = (step) => {
  const earlySteps = [
    candidateStatus.PIPELINE.key,
    candidateStatus.RESUME_REVIEW.key,
    candidateStatus.PRE_SCREEN.key,
    candidateStatus.CODE_TEST.key,
  ];

  return earlySteps.includes(step);
};

export const isCandidateEmployee = (candidate) => {
  for (const domain of companiesDomains) {
    if (
      candidate.email?.includes?.(domain) ||
      candidate.secondaryEmail?.includes?.(domain)
    ) {
      return true;
    }
  }
  return false;
};

export const filterCandidateStatus = ({
  allColumns,
  isExternalRecruiter,
  currentStatus,
}) => {
  const keys = Object.keys(candidateStatus);
  let filterKeys = [];

  if (currentStatus) filterKeys.push(currentStatus);

  if (isExternalRecruiter) {
    filterKeys = [
      ...filterKeys,
      candidateStatus.BLOCKED.key,
      candidateStatus.HIRED.key,
      candidateStatus.LEAD.key,
    ];
  } else {
    if (!allColumns) {
      filterKeys = [
        ...filterKeys,
        candidateStatus.BLOCKED.key,
        candidateStatus.HIRED.key,
      ];
    }
  }

  return keys.filter((key) => !filterKeys.includes(key));
};

// Numbers

export const getOnlyDigits = (string) => {
  return string.replace(/\D+/g, "");
};

export const getCurrencyFormat = (number) => {
  if (number === "") {
    return "";
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  }).format(number);
};

export const getAbbreviatedNumberFormat = (number = 0) => {
  if (number > 999 && number < 1000000) {
    return `${(number / 1000).toFixed(1)}K`;
  } else if (number >= 1000000) {
    return `${(number / 1000000).toFixed(1)}M`;
  }
  return number;
};

// General

export const sumByProperty = (array, property) => {
  return array?.reduce((a, b) => +a + +b[property], 0) || 0;
};

export const reorderArray = (array, startIndex, endIndex) => {
  const newArray = [...array];
  const [removedElement] = newArray.splice(startIndex, 1);
  newArray.splice(endIndex, 0, removedElement);
  return newArray;
};

export const prioritizeArray = (array) => {
  const newArray = [...array];
  for (let index = 0; index < newArray.length; index++) {
    const element = newArray[index];
    element.priority = newArray.length - index;
  }
  return newArray;
};

export const capitalize = (value) => {
  if (typeof value !== "string") return "";
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

export const capitalizeEnum = (value) => {
  if (typeof value !== "string") return "";

  const result = value.replace(/_/g, " ");

  return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
};

export const getEmailTemplateSubject = (key) => {
  return emailSubjectOptions.find((subject) => subject.value === key)?.subject;
};

export const joinStringWithCommas = (array) => {
  const words = array.filter(
    (item) => item !== undefined && item !== null && item.length > 1
  );
  return words.join(", ");
};

export const mapToSelectOptions = (optionsArray, valueKey, labelKey) => {
  return optionsArray.map((option) => {
    return {
      value: option[valueKey],
      label: option[labelKey],
    };
  });
};

export const enumToSelectOptions = (enumProperties) => {
  return Object.entries(enumProperties).map(([_label, value]) => ({
    label: capitalizeEnum(value),
    value,
  }));
};

// Security

export const getRoles = (keycloak) => {
  return (
    keycloak.tokenParsed?.resource_access?.["micoach-backend"]?.roles ?? []
  );
};

// Evaluation

export const getEvaluationStatusFromKey = (key) => {
  let status;

  switch (key) {
    case micoach.EvaluationStatusEnum.Open:
      status = "Pending";
      break;

    case micoach.EvaluationStatusEnum.Closed:
      status = "Evaluated";
      break;

    case micoach.EvaluationStatusEnum.Expired:
      status = "Expired";
      break;

    default:
      status = key;
  }

  return status;
};

export const getProficiencyLevelFromValue = (value) => {
  switch (value) {
    case 0:
      return {
        level: "NA",
        description:
          "If you are not sure about the level of this skill, select this option.",
      };
    case 1:
      return {
        level: "Beginner",
        description:
          'Common knowledge and understanding of basic techniques and concepts. This level of experience is gained in training and experimental scenarios or "on the job".',
      };
    case 2:
      return {
        level: "Practitioner",
        description:
          "Able to complete tasks in this competency as requested. Help from an expert may be required from time to time, but can usually perform the skill independently.",
      };
    case 3:
      return {
        level: "Specialist",
        description:
          'Recognized within the immediate organization as "a person to ask" when difficult questions arise regarding this skill.',
      };
    case 4:
      return {
        level: "Expert",
        description:
          "Known as an expert in this area. Can provide guidance, troubleshoot and answer questions related to this area of expertise and the field where the skill is applied.",
      };
    default:
      return null;
  }
};

/**
 * Generates a period string based on the given year and quarter.
 *
 * @param {number} year - The year for which the period string is generated.
 * @param {number} quarter - The quarter for which the period string is generated (1 to 4).
 * @returns {string} - The period string in the format "Q<quarter>-<year>".
 *
 */
export const getPeriodString = (year, quarter) => {
  return `Q${quarter}-${year}`;
};

export const getQuarterYear = (date = new Date()) => {
  return dayjs(date).quarter();
};

export const getNextQuarterYear = (date = new Date()) => {
  return dayjs(date).add(1, "quarter").startOf("quarter");
};

export const getStartAndEndDateFromYearAndQuarter = (year, quarter) => {
  const quarterDate = dayjs().utc().year(year).quarter(quarter);
  const quarterPeriod = [
    quarterDate.startOf("quarter").toDate(),
    quarterDate.endOf("quarter").toDate(),
  ];

  return quarterPeriod;
};

export const sortSkillsByCategoryAndName = (skills, order) => {
  if (!skills || !skills.length) return [];
  const hardSkills = filterSkills(skills, micoach.SkillCategoryEnum.Hard);
  const softSkills = filterSkills(skills, micoach.SkillCategoryEnum.Soft);
  const sortedSkills = [
    ...sortByProperty(hardSkills, "name", order),
    ...sortByProperty(softSkills, "name", order),
  ];
  return sortedSkills;
};

export const sortSkillsByValue = (skills, property, order) => {
  if (!skills || !skills.length) return [];
  const sortedSkills = [...sortByProperty(skills, property, order)];
  return sortedSkills;
};
// Language

export const getLanguageProficiencyValue = (text) => {
  switch (text) {
    case micoach.ProficiencyEnum.Beginner:
      return 1;
    case micoach.ProficiencyEnum.Intermediate:
      return 2;
    case micoach.ProficiencyEnum.Proficient:
      return 3;
    case micoach.ProficiencyEnum.Fluent:
      return 4;
    case micoach.ProficiencyEnum.Native:
      return 5;
    default:
      return -1;
  }
};

/**
 * Compares two JavaScript objects to check if they have equal values.
 * The comparison is done by checking if all properties in both objects
 * have the same values, regardless of the order of properties.
 *
 * @param {Object} firstObject - The first object to be compared.
 * @param {Object} secondObject - The second object to be compared.
 * @return {Boolean} - Returns true if both objects have equal values, false otherwise.
 */
export const isEqual = (firstObject, secondObject) => {
  const firstObjectKeys = Object.keys(firstObject);
  const secondObjectKeys = Object.keys(secondObject);

  // Return false if the number of properties in both objects is different
  if (firstObjectKeys.length !== secondObjectKeys.length) {
    return false;
  }

  // Iterate over the keys of the first object
  for (const key of firstObjectKeys) {
    // Return false if a key in the first object is not found in the second object
    if (!secondObjectKeys.includes(key)) {
      return false;
    }

    // Get the values at the current key in both objects
    const firstValue = firstObject[key];
    const secondValue = secondObject[key];

    // If both values are objects, perform a recursive comparison
    if (typeof firstValue === "object" && typeof secondValue === "object") {
      if (!isEqual(firstValue, secondValue)) {
        return false;
      }
    } else if (firstValue !== secondValue) {
      // Return false if the values at the current key are not equal
      return false;
    }
  }

  return true;
};

/**
 * Function to remove duplicates from an array of objects based on a specific property.
 * @param {Array} objects - The array of objects to remove duplicates from.
 * @param {string} property - The property name to use to determine duplicates.
 * @param {boolean} [insensitive=false] - Indicates whether the comparison should be case-insensitive.
 * @returns {Array} - The filtered array of objects with duplicates removed.
 */
export const removeDuplicates = (objects, property, insensitive = false) => {
  const uniqueValues = {};

  return objects?.filter((object) => {
    let propValue = object[property];

    if (insensitive) {
      propValue = object[property].toLowerCase();
    }

    if (!uniqueValues[propValue]) {
      uniqueValues[propValue] = true;
      return true;
    }
    return false;
  });
};

/**
 * Check if an array contains objects with duplicate values for a given property.
 * @param {Array} array - The array to check.
 * @param {string} property - The name of the property to check for duplicates.
 * @returns {boolean} - True if the array contains objects with duplicate values for the given property, false otherwise.
 */
export const isDuplicate = (array, property) => {
  const values = new Set();

  for (const obj of array) {
    const value = obj[property].toLowerCase();

    if (values.has(value)) {
      return true;
    }

    values.add(value);
  }

  return false;
};

/**
 * Adds or replaces an element in an array based on its ID.
 * @param {Array} array - The array to add or replace the element in.
 * @param {Object} element - The element to add or replace.
 * @returns {Array} - The updated array with the element added or replaced.
 */
export const addOrReplaceElementInArray = (array, element) => {
  const index = array.findIndex(
    (prevElement) => prevElement._id === element._id
  );

  if (index === -1) {
    return [element, ...array];
  }

  const newItems = [...array];
  newItems[index] = element;

  return newItems;
};

/**
 * Removes an element from an array based on a given condition.
 * @param {Array} array - The array from which to remove an element.
 * @param {Function} condition - A condition function that takes an array element as argument and returns a boolean value.
 * @returns {Array} - The updated array with the element removed if found, otherwise the original array.
 */
export const removeElementInArray = (array, condition) => {
  const index = array.findIndex(condition);

  if (index !== -1) {
    array.splice(index, 1);
  }

  return array;
};

export const getCategorizedSkills = (skills) => {
  const hardSkills = filterSkills(skills, micoach.SkillCategoryEnum.Hard);
  const softSkills = filterSkills(skills, micoach.SkillCategoryEnum.Soft);
  const techSkills = filterSkills(skills, micoach.SkillCategoryEnum.Tech);

  return {
    hardSkills,
    softSkills,
    techSkills,
  };
};

export const isEmailFormatValid = (email) => {
  return EMAIL_PATTERN.test(email);
};

/**
 * Encodes each item in an array using encodeURIComponent.
 * @param {Array} array - The array to encode.
 * @returns {Array} A new array with the encoded items.
 */
export const encodeItemsInArray = (array) => {
  if (!array) {
    return [];
  }

  return array.map((item) => encodeURIComponent(item));
};

/**
 * Retrieves the referral status label based on the given status and isHumanResources flag.
 * @param {string} status - The referral status.
 * @param {boolean} [isHumanResources=false] - Indicates whether the user is in the human resources.
 * @returns {string} - The label corresponding to the status, or the original status value if not found.
 */
export const getReferralStatusLabel = (status, isHumanResources = false) => {
  const referralStatus = micoach.ReferralStatusEnum;

  const statusLabels = {
    [referralStatus.InHiringProcess]: "TA Review",
    [referralStatus.Reviewing]: isHumanResources ? "Pending" : null,
    [referralStatus.Claimed]: isHumanResources ? "Paid" : null,
    [referralStatus.Hired]: "3 months wait",
  };

  return statusLabels[status] ?? capitalizeEnum(status);
};

/**
 * Retrieves 1, -1 or 0 based on what property is bigger.
 * @param {object} data - The object1, object2 and property to compair
 * @returns {number} - The value corresponding to the comparation, 1 when object1.property > object2.property, -1 when object1.property < object2.property or 0 by default
 */
export const customSort = (data) => {
  const { object1, object2, property } = data;
  let a = object1[property];
  let b = object2[property];

  if (typeof object1[property] === "string") {
    a = a.toUpperCase();
  }

  if (typeof object2[property] === "string") {
    b = b.toUpperCase();
  }

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const handleOnError = (error) => {
  const errorMessage = error?.errors?.[0]?.message || error?.message;

  if (errorMessage) {
    toast.error(errorMessage);
  } else {
    toast.error("Error, please try again.");
  }
};

/**
 * Combines multiple arrays into a single array, removing any duplicate elements.
 *
 * @param {...Array} arrays - Arrays to be combined.
 * @returns {Array} The combined array without duplicates.
 *
 */
export const combineArraysWithoutDuplicates = (...arrays) => {
  return Array.from(new Set(arrays.flat()));
};

/**
 * Performs an HTTP GET request to the specified URL with optional query parameters.
 * @param {Array} requestParams - An array containing the URL and query parameters.
 * @returns {Object} - An object containing the fetched data or an error.
 */
export const fetcher = async ([path, queryParams], accessToken) => {
  try {
    const response = await axios.get(`${MICOACH_API_URL}${path}`, {
      headers: {
        Authorization: accessToken && `Bearer ${accessToken}`,
      },
      params: queryParams,
      paramsSerializer: (params) => qs.stringify(params),
    });

    return { data: response.data, headers: response.headers };
  } catch (error) {
    console.error(error);
    throw error.response?.data;
  }
};

/**
 * Sends a request to the specified URL using the provided arguments.
 * @param {string} url - The URL to send the request to.
 * @param {Object} options - The options for the request.
 * @param {string} options.method - The HTTP method to use for the request (default: "GET").
 * @param {Object} options.body - The request body data.
 * @param {string} options.accessToken - The access token for authorization.
 * @param {Object} options.queryParams - The query parameters for the request.
 * @param {string} options.responseType - The response type (default: "json").
 * @returns {Promise<any>} - A promise that resolves to the response data.
 * @throws {any} - Throws the error response data if the request fails.
 */
export async function sendRequest(path, options) {
  const {
    method = "GET",
    body,
    accessToken,
    responseType = "json",
    queryParams,
  } = options;

  try {
    const response = await axios({
      method,
      url: `${MICOACH_API_URL}${path}`,
      data: body,
      responseType,
      params: queryParams,
      paramsSerializer: (params) => qs.stringify(params),
      headers: {
        Authorization: accessToken && `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error.response?.data;
  }
}

/**
 * Transforms form values into a structured filters object where each key's value
 * is an array of items, each item enhanced with a 'type' property corresponding to its key.
 * @param {Object} formValues - The form values to transform.
 * @returns {Object} A new filters object with added 'type' properties.
 */
export const transformFormValuesToFilters = (formValues) => {
  const newFilters = {};

  Object.keys(formValues).forEach((key) => {
    if (formValues[key]?.length) {
      newFilters[key] = formValues[key].map((item) => ({
        ...item,
        type: key,
      }));
    }
  });

  return newFilters;
};

import PropTypes from "prop-types";
import { useMediaQuery } from "usehooks-ts";

import styles from "Components/Candidate/styles/ImageBanner.module.css";

const ImageBanner = (props) => {
  const { image, alt, onClick } = props;

  const isMobile = useMediaQuery("(max-width: 991px)");
  const isTablet = useMediaQuery("(max-width: 767px)");

  const getImageSource = () => {
    if (isMobile) {
      return image.mobile;
    } else if (isTablet) {
      return image.tablet;
    } else {
      return image.desktop;
    }
  };

  return (
    <div
      className={`${styles.Container} ${onClick ? styles.Clickable : ""}`}
      onClick={() => onClick?.()}
    >
      <img className={styles.Image} src={getImageSource()} alt={alt} />
    </div>
  );
};

ImageBanner.propTypes = {
  image: PropTypes.shape({
    mobile: PropTypes.string,
    tablet: PropTypes.string,
    desktop: PropTypes.string,
  }),
  alt: PropTypes.string,
  onClick: PropTypes.func,
};

export default ImageBanner;

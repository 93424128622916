import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import micoach from "micoach-api";

import CheckboxListModal from "Components/Common/CheckboxListModal";
import FormattedDate from "Components/FormattedDate";
import GoBackButton from "Components/GoBackButton";
import CompetencyRating from "Components/Prescreening/CompetencyRating";
import Questionnaire from "Components/Prescreening/Questionnaire";
import SourceImage from "Components/SourceImage";
import UserImage from "Components/UserImage";
import { useApplicationProcess } from "Hooks/useApplicationProcesses";
import { usePublicSkills } from "Hooks/useSkills";
import BackendService from "Services/BackendService";
import {
  applicationProcessStatus,
  prescreeningSections,
  skillCategories,
} from "constants.js";
import { APPLICATION_PROCESS_CLOSED } from "error-constants.js";
import {
  getCurrencyFormat,
  getDate,
  getFormattedDate,
  getOnlyDigits,
  getPositionType,
  getRoles,
} from "utils.js";

import "Components/styles/Card.css";
import "Screens/styles/ViewPrescreening.css";

const ViewPrescreening = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isExternalRecruiter, setIsExternalRecruiter] = useState(false);
  const [showSectionsPrescreeningModal, setShowSectionsPrescreeningModal] =
    useState(false);
  const [downloadingPrescreening, setDownloadingPrescreening] = useState(false);

  const { id } = useParams();
  const { keycloak } = useKeycloak();
  const [applicationProcess, loadApplicationProcess] =
    useApplicationProcess(id);
  const { skills: softSkills } = usePublicSkills(skillCategories.SOFT.key);
  const { formState, register, handleSubmit, reset, setValue, control } =
    useForm({
      mode: "onChange",
    });

  const { isValid, errors } = formState;

  const validateSalaryMax = (salary) => {
    let newSalary = salary;
    if (salary > 500000) {
      newSalary = 500000;
    }
    return newSalary;
  };

  const handleChangeSalary = (e) => {
    const salary = getOnlyDigits(e.target.value);

    setValue(e.target.getAttribute("name"), getCurrencyFormat(salary));
  };

  const handleBlurSalary = (e) => {
    if (e.target.value !== "") {
      const salary = getOnlyDigits(e.target.value);
      setValue(
        e.target.getAttribute("name"),
        getCurrencyFormat(validateSalaryMax(salary))
      );
    }
  };

  const handleSubmitPrescreening = async (formValues) => {
    if (isValid) {
      setIsSaving(true);
      if (formValues.salaryExpectationMax)
        formValues.salaryExpectationMax = validateSalaryMax(
          getOnlyDigits(formValues.salaryExpectationMax)
        );
      if (formValues.salaryExpectationMin)
        formValues.salaryExpectationMin = validateSalaryMax(
          getOnlyDigits(formValues.salaryExpectationMin)
        );
      if (formValues.salaryBase)
        formValues.salaryBase = validateSalaryMax(
          getOnlyDigits(formValues.salaryBase)
        );
      if (formValues.hasVisa === "") formValues.hasVisa = null;
      if (formValues.hasPassport === "") formValues.hasPassport = null;
      if (formValues.isWillingToRelocate === "")
        formValues.isWillingToRelocate = null;

      formValues.recruiterName = applicationProcess.recruiterName;
      formValues.recruiterImageUrl = applicationProcess.recruiterImageUrl;
      formValues.recruiterColor = applicationProcess.recruiterColor;
      formValues.callMadeAt = getFormattedDate(formValues.callMadeAt);

      try {
        await BackendService.updatePrescreening(
          applicationProcess._id,
          formValues
        );
        setIsEditing(false);
        loadApplicationProcess();
        toast.success("Success! The pre-screening form has been updated.");
      } catch (error) {
        const status = error.response.status;
        const data = error.response.data;
        let message = "Error, please try again.";

        if (status === 409) {
          if (data.error === APPLICATION_PROCESS_CLOSED) {
            message =
              "Unable to update the pre-screening form! The application process is closed, since the candidate has been hired or rejected.";
          }
        }

        toast.error(message);
      } finally {
        setIsSaving(false);
      }
    } else {
      toast.error("Error! An error has occurred, check all input fields.");
    }
  };

  const handleClickCancel = () => {
    setIsEditing(false);
    reset();
  };

  const handleClickDownload = async (sections) => {
    setDownloadingPrescreening(true);
    try {
      if (applicationProcess) {
        const blob = await BackendService.downloadPrescreening(
          applicationProcess._id,
          sections.join(",")
        );

        saveAs(
          new Blob([blob]),
          `${applicationProcess.candidate?.name} - Prescreening form - ${
            applicationProcess.position?.title ?? ""
          }.pdf`
        );
      }
    } catch (error) {
      toast.error(
        "Error! An error occurred while generating the pre-screening form, please try again."
      );
    } finally {
      setShowSectionsPrescreeningModal(false);
      setDownloadingPrescreening(false);
    }
  };

  useEffect(() => {
    const roles = getRoles(keycloak);
    setIsExternalRecruiter(
      roles?.includes(micoach.UserRoleEnum.ExternalRecruiter)
    );
  }, [keycloak]);

  useEffect(() => {
    if (applicationProcess) {
      reset({
        relevantExperience: applicationProcess.prescreening?.relevantExperience,
        reasonForApplying: applicationProcess.prescreening?.reasonForApplying,
        englishLevel: applicationProcess.prescreening?.englishLevel,
        codingChallenge: applicationProcess.prescreening?.codingChallenge,
        currentCompany: applicationProcess.prescreening?.currentCompany,
        salaryExpectationMax: applicationProcess.prescreening
          ?.salaryExpectationMax
          ? getCurrencyFormat(
              applicationProcess.prescreening?.salaryExpectationMax
            )
          : "",
        salaryExpectationMin: applicationProcess.prescreening
          ?.salaryExpectationMin
          ? getCurrencyFormat(
              applicationProcess.prescreening?.salaryExpectationMin
            )
          : "",
        salaryBase: applicationProcess.prescreening?.salaryBase
          ? getCurrencyFormat(applicationProcess.prescreening?.salaryBase)
          : "",
        noticePeriod: applicationProcess.prescreening?.noticePeriod,
        candidateSource:
          applicationProcess.prescreening?.candidateSource ??
          applicationProcess.candidate.source,
        learningAgility: applicationProcess.prescreening?.learningAgility,
        collaboration: applicationProcess.prescreening?.collaboration,
        workQuality: applicationProcess.prescreening?.workQuality,
        learningAgilityRating:
          applicationProcess.prescreening?.learningAgilityRating,
        collaborationRating:
          applicationProcess.prescreening?.collaborationRating,
        workQualityRating: applicationProcess.prescreening?.workQualityRating,
        strengths: applicationProcess.prescreening?.strengths,
        developmentOpportunities:
          applicationProcess.prescreening?.developmentOpportunities,
        comments: applicationProcess.prescreening?.comments,
        callMadeAt: getDate(applicationProcess.prescreening?.callMadeAt),
        recommendation: applicationProcess.prescreening?.recommendation,
        competencies: applicationProcess.prescreening?.competencies,
      });
    }
  }, [applicationProcess, reset]);

  return (
    <>
      <CheckboxListModal
        show={showSectionsPrescreeningModal}
        disabled={downloadingPrescreening}
        title="Pre-screening"
        subtitle="Select all the sections you want to include in the pre-screening form"
        options={prescreeningSections}
        defaultValue={prescreeningSections.filter(
          (value) => value.value !== "SALARY_EXPECTATIONS"
        )}
        id="value"
        label="label"
        value="value"
        confirmButtonText="Download"
        onClose={() => setShowSectionsPrescreeningModal(false)}
        onConfirm={handleClickDownload}
      />

      <Row>
        <Col>
          <GoBackButton />
        </Col>
      </Row>

      {applicationProcess ? (
        <div className="ViewPrescreening">
          <Form onSubmit={handleSubmit(handleSubmitPrescreening)} noValidate>
            <Row>
              <Col sm={8}>
                <Card className="Card">
                  <Card.Body>
                    <Row>
                      <Col xs={12} sm={7}>
                        <div className="CandidateSource">
                          <SourceImage
                            source={applicationProcess?.candidate.source}
                          />
                          <h1 className="ms-2">
                            {applicationProcess?.candidate.name}
                          </h1>
                        </div>
                        <span className="fs-18 fw-light">
                          {applicationProcess.position?.title}
                        </span>
                        <div className="PositionDetails mt-3">
                          <ListGroup horizontal>
                            {applicationProcess.position?.companyPositionId && (
                              <ListGroup.Item>
                                {applicationProcess.position?.companyPositionId}
                              </ListGroup.Item>
                            )}
                            {applicationProcess.position?.location && (
                              <ListGroup.Item>
                                {applicationProcess.position?.location}
                              </ListGroup.Item>
                            )}
                            {applicationProcess.position?.remote && (
                              <ListGroup.Item>Remote</ListGroup.Item>
                            )}
                            <ListGroup.Item>
                              {getPositionType(
                                applicationProcess.position?.type
                              )}
                            </ListGroup.Item>
                          </ListGroup>
                        </div>
                      </Col>
                      <Col xs={12} sm={5} className="text-end">
                        {isEditing ? (
                          <Form.Group as={Row} controlId="callMadeAt">
                            <Form.Label column xs={4} className="mt-2 pe-0">
                              Call made at
                            </Form.Label>
                            <Col xs={8}>
                              <Controller
                                name="callMadeAt"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <DatePicker
                                    showPopperArrow={false}
                                    selected={value}
                                    onChange={onChange}
                                    dateFormat="MMM dd, yyyy"
                                    className="form-control"
                                  />
                                )}
                              />
                            </Col>
                          </Form.Group>
                        ) : (
                          applicationProcess.prescreening?.callMadeAt && (
                            <span className="text-muted fs-13 tex">
                              Call made at:{" "}
                              <FormattedDate>
                                {applicationProcess.prescreening?.callMadeAt}
                              </FormattedDate>
                            </span>
                          )
                        )}
                        {applicationProcess.prescreening?.recruiterName && (
                          <Row className="align-items-end">
                            <Col xs={10}>
                              <h2 className="BlueText py-2">Interviewer</h2>
                              <span>
                                <b>
                                  {
                                    applicationProcess.prescreening
                                      ?.recruiterName
                                  }
                                </b>
                              </span>
                              <br />
                              <span>TA account lead</span>
                            </Col>
                            <Col xs={2}>
                              <UserImage
                                userName={
                                  applicationProcess.prescreening
                                    ?.recruiterName ?? ""
                                }
                                userImageUrl={
                                  applicationProcess.prescreening
                                    ?.recruiterImageUrl
                                }
                              />
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              <Col sm={4}>
                <Card className="Card">
                  <Card.Body className="mt-2">
                    <Row>
                      <Col>
                        <Form.Group controlId="recommendation">
                          <Form.Label>
                            <span className="fs-18 ">Recommendation</span>
                          </Form.Label>

                          {isEditing ? (
                            <>
                              <Form.Control
                                type="text"
                                isInvalid={!!errors.recommendation}
                                {...register("recommendation", {
                                  maxLength: 150,
                                })}
                              />
                              {errors.recommendation && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.recommendation.type === "maxLength" &&
                                    "The maximum size must be less than 150 characters"}
                                </Form.Control.Feedback>
                              )}
                            </>
                          ) : (
                            <p>
                              {applicationProcess.prescreening?.recommendation}
                            </p>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <div className="my-4 ps-3">
              <Row>
                <Col sm={12}>
                  <h1 className="fs-30">Pre-screening questionnaire</h1>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <span className="fs-13">
                    <b>Instructions:</b> Complete this Rating Grid after you’ve
                    interviewed the candidate and evaluated the candidate data.
                  </span>
                </Col>
                <Col sm={6} className="text-end">
                  {applicationProcess.prescreening && (
                    <Button
                      variant="link"
                      className="me-3"
                      onClick={() => setShowSectionsPrescreeningModal(true)}
                      disabled={isEditing}
                    >
                      <FontAwesomeIcon icon={faDownload} /> Generate PDF
                    </Button>
                  )}

                  {applicationProcess.status ===
                    applicationProcessStatus.active && (
                    <>
                      <Button
                        variant="secondary"
                        className="me-3 CancelButton"
                        onClick={handleClickCancel}
                        hidden={!isEditing || isSaving}
                      >
                        Cancel
                      </Button>

                      <Button
                        variant="secondary"
                        className="me-3 SaveButton"
                        onClick={() => setIsEditing(true)}
                        hidden={isEditing}
                      >
                        Edit
                      </Button>

                      <Button
                        variant="primary"
                        className="SaveButton"
                        type="submit"
                        disabled={!isEditing || isSaving}
                      >
                        Save
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </div>

            <Row>
              <Col sm={8}>
                <Card className="Card">
                  <Card.Body className="fs-13">
                    <Questionnaire
                      applicationProcess={applicationProcess}
                      isEditing={isEditing}
                      isExternalRecruiter={isExternalRecruiter}
                      control={control}
                      errors={errors}
                      register={register}
                      onBlurSalary={handleBlurSalary}
                      onChangeSalary={handleChangeSalary}
                    />
                  </Card.Body>
                </Card>
              </Col>

              <Col sm={4}>
                <Card className="Card mb-4">
                  <Card.Body>
                    <CompetencyRating
                      applicationProcess={applicationProcess}
                      softSkills={softSkills}
                      isEditing={isEditing}
                      control={control}
                      setValue={setValue}
                      register={register}
                    />
                  </Card.Body>
                </Card>
                <Card className="Card">
                  <Card.Body className="fs-13">
                    <Row>
                      <Col xs={12}>
                        <div className="mb-4">
                          <span className="fs-18 fw-bold">
                            Additional Information
                          </span>
                        </div>
                        <Form.Group
                          controlId="strengths"
                          className="form-group"
                        >
                          <Form.Label>Strengths</Form.Label>
                          {isEditing ? (
                            <Form.Control
                              type="text"
                              as="textarea"
                              rows={3}
                              className="fs-13 h-100"
                              {...register("strengths")}
                            />
                          ) : (
                            <p>{applicationProcess.prescreening?.strengths}</p>
                          )}
                        </Form.Group>
                        <hr />
                        <br />

                        <Form.Group
                          controlId="developmentOpportunities"
                          className="form-group"
                        >
                          <Form.Label>Opportunities for development</Form.Label>
                          {isEditing ? (
                            <Form.Control
                              type="text"
                              as="textarea"
                              rows={3}
                              className="fs-13 h-100"
                              {...register("developmentOpportunities")}
                            />
                          ) : (
                            <p>
                              {
                                applicationProcess.prescreening
                                  ?.developmentOpportunities
                              }
                            </p>
                          )}
                        </Form.Group>
                        <hr />
                        <br />

                        <Form.Group controlId="comments" className="form-group">
                          <Form.Label>Additional comments</Form.Label>
                          {isEditing ? (
                            <Form.Control
                              type="text"
                              as="textarea"
                              rows={3}
                              className="fs-13 h-100"
                              {...register("comments")}
                            />
                          ) : (
                            <p>{applicationProcess.prescreening?.comments}</p>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
        </div>
      ) : null}
    </>
  );
};

export default ViewPrescreening;

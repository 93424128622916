import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import { ReportApi } from "micoach-api";

import styles from "Screens/Report/styles/Report.module.css";

function RecruiterApplicationProcess() {
  const [generatingReport, setGeneratingReport] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formValues) => {
    setGeneratingReport(true);

    const selectedDate = new Date(formValues.reportDate);
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth() + 1; // months are zero-based index

    try {
      const { data } = await ReportApi.getApplicationProcessRecruiter(
        year,
        month
      );

      const blob = new Blob([data]);
      const fileName = `HiringProcesses-${month}-${year}.csv`;

      saveAs(blob, fileName);
    } catch (error) {
      toast.error("Error!, please try again.");
    } finally {
      setGeneratingReport(false);
    }
  };

  return (
    <>
      <h1 className="ScreenTitle">Hiring processes history</h1>
      <p className={styles.Description}>
        Lists candidates hiring processes history started in the selected month
        (Includes recruiter & steps).
      </p>
      <Row>
        <Col sm={8} md={6} xl={4}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="reportDate" className="required form-group">
              <Form.Label>Select a month and a year</Form.Label>
              <Controller
                name="reportDate"
                control={control}
                defaultValue={new Date()}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    showPopperArrow={false}
                    selected={value}
                    onChange={onChange}
                    dateFormat="MMMM yyyy"
                    className={`form-control ${
                      errors.reportDate ? "is-invalid" : ""
                    }`}
                    showMonthYearPicker
                    title="Report date"
                  />
                )}
              />
              {errors.reportDate && (
                <div className="invalid-feedback d-block">
                  {errors.reportDate.type === "required" &&
                    "Report date is required"}
                </div>
              )}
            </Form.Group>
            <div className={styles.Buttons}>
              <Button type="submit" disabled={generatingReport}>
                <FontAwesomeIcon icon={faFileDownload} /> Download report
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default RecruiterApplicationProcess;

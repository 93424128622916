import { useCallback, useEffect, useState } from "react";
import { RoleApi } from "micoach-api";

export const useRoles = () => {
  const [roles, setRoles] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(false);

  const loadRoles = useCallback(async () => {
    setLoadingRoles(true);
    try {
      const { data } = await RoleApi.listRoles();
      setRoles(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRoles(false);
    }
  }, []);

  useEffect(() => {
    loadRoles();
  }, [loadRoles]);

  return { roles, loadingRoles, setRoles };
};

export const usePublicRoles = (includeSkills) => {
  const [roles, setRoles] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(false);

  const loadRoles = useCallback(async () => {
    setLoadingRoles(true);
    try {
      const { data } = await RoleApi.listPublicRoles(includeSkills);
      setRoles(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRoles(false);
    }
  }, [includeSkills]);

  useEffect(() => {
    loadRoles();
  }, [loadRoles]);

  return { roles, loadingRoles };
};

import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import micoach, { TaskApi, UserApi } from "micoach-api";

import { getStartAndEndDateFromYearAndQuarter, sortByProperty } from "utils.js";

export const useTasks = ({
  userId,
  type,
  entity,
  read,
  completed,
  status,
  startDate,
  endDate,
}) => {
  const [tasks, setTasks] = useState([]);
  const [loadingTasks, setLoadingTasks] = useState(false);

  const loadTasks = useCallback(async () => {
    setLoadingTasks(true);
    try {
      const params = [
        userId,
        type,
        entity,
        read,
        completed,
        status,
        startDate,
        endDate,
      ];
      const { data } = await TaskApi.listTasks(...params);
      setTasks(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTasks(false);
    }
  }, [userId, type, entity, read, completed, status, startDate, endDate]);

  useEffect(() => {
    loadTasks();
  }, [loadTasks]);

  return { tasks, loadingTasks, setTasks };
};

export const useTasksTotal = ({
  userId,
  type,
  entity,
  read,
  completed,
  status,
  startDate,
  endDate,
  limit,
}) => {
  const [tasksTotal, setTasksTotal] = useState(0);
  const [loadingTasksTotal, setLoadingTasksTotal] = useState(false);

  const location = useLocation();

  const loadTasksTotal = useCallback(async () => {
    if (!userId) {
      return;
    }
    setLoadingTasksTotal(true);
    try {
      const params = [
        userId,
        type,
        entity,
        read,
        completed,
        status,
        startDate,
        endDate,
        limit,
      ];

      const { data } = await TaskApi.listTasks(...params);

      const filteredTasks = data.filter(
        (task) => task.type !== micoach.TaskTypeEnum.WatchLater
      );
      setTasksTotal(filteredTasks.length);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTasksTotal(false);
    }
  }, [
    userId,
    type,
    entity,
    read,
    completed,
    status,
    startDate,
    endDate,
    limit,
  ]);

  useEffect(() => {
    loadTasksTotal();
  }, [loadTasksTotal, location]);

  return { tasksTotal, loadingTasksTotal };
};

export const useWatchLaterTasks = (userId) => {
  const [watchLaterTasks, setWatchLaterTasks] = useState([]);
  const [loadingWatchLaterTasks, setLoadingWatchLaterTasks] = useState(false);

  const loadWatchLaterTasks = useCallback(async () => {
    if (!userId) {
      return;
    }

    setLoadingWatchLaterTasks(true);

    try {
      const { data } = await UserApi.listUserWatchLaterTrainings(userId);

      setWatchLaterTasks(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingWatchLaterTasks(false);
    }
  }, [userId]);

  useEffect(() => {
    loadWatchLaterTasks();
  }, [loadWatchLaterTasks]);

  return { watchLaterTasks, loadingWatchLaterTasks };
};

export const useFilterTasks = (originalTasks, newFilters, sortOrder) => {
  const [filteredTasks, setFilteredTasks] = useState([]);

  const getFilteredTasks = useCallback(() => {
    let newFilteredTasks = [...originalTasks];

    try {
      if (newFilters) {
        if (newFilters.period) {
          const [startDate, endDate] = getStartAndEndDateFromYearAndQuarter(
            newFilters.period.year,
            newFilters.period.quarter
          );

          newFilteredTasks = newFilteredTasks.filter((task) => {
            const createdAt = new Date(task.createdAt);
            return createdAt >= startDate && createdAt <= endDate;
          });
        }

        if (newFilters.status) {
          newFilteredTasks = newFilteredTasks.filter(
            (task) => task.status === newFilters.status
          );
        }

        if (newFilters.coach) {
          newFilteredTasks = newFilteredTasks.filter(
            (task) => task.metadata?.coachName === newFilters.coach.value
          );
        }
      }

      if (sortOrder) {
        sortByProperty(newFilteredTasks, "createdAt", sortOrder);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setFilteredTasks(newFilteredTasks);
    }
  }, [originalTasks, newFilters, sortOrder]);

  useEffect(() => {
    getFilteredTasks();
  }, [getFilteredTasks]);

  return { filteredTasks };
};

export const useSelectedFilters = (newFilters) => {
  const [selectedFilters, setSeletedFilters] = useState([]);

  const getSelectedFilters = useCallback(() => {
    const newSelectedFilters = [];

    try {
      if (newFilters) {
        if (newFilters.status) {
          newSelectedFilters.push({
            name: "status",
            label: newFilters.status,
            value: newFilters.status,
          });
        }

        if (newFilters.period) {
          newSelectedFilters.push({
            name: "period",
            label: `Q${newFilters.period.quarter} - ${newFilters.period.year}`,
            value: newFilters.period,
          });
        }

        if (newFilters.coach) {
          newSelectedFilters.push({
            name: "coach",
            label: `Coach ${newFilters.coach.label}`,
            value: newFilters.coach,
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSeletedFilters(newSelectedFilters);
    }
  }, [newFilters]);

  useEffect(() => {
    getSelectedFilters();
  }, [getSelectedFilters]);

  return { selectedFilters };
};

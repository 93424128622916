import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";

import styles from "Components/Common/styles/ActiveLink.module.css";

const ActiveLink = (props) => {
  const { href, isNextLink, children } = props;

  const location = useLocation();

  const isActive = location.pathname.includes(href);

  const className = isActive ? styles.Active : "";

  return isNextLink ? (
    <a href={href} className={className}>
      {children}
    </a>
  ) : (
    <Link to={href} className={className}>
      {children}
    </Link>
  );
};

ActiveLink.propTypes = {
  href: PropTypes.string.isRequired,
  isNextLink: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
};

ActiveLink.defaultProps = {
  isNextLink: false,
};

export default ActiveLink;

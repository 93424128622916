import { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import { ApplicationProcessApi } from "micoach-api";

import ApplicationAddModal from "Components/ApplicationAddModal";
import ApplicationOfferAcceptedModal from "Components/ApplicationOfferAcceptedModal";
import CandidateAddModal from "Components/CandidateAddModal";
import StateCard from "Components/Common/StateCard";
import DiscardModal from "Components/DiscardModal";
import EmailSendModal from "Components/EmailSendModal";
import KanbanColumn from "Components/Kanban/KanbanColumn";
import Loading from "Components/Loading";
import SearchBar from "Components/SearchBar";
import UploadDocumentModal from "Components/UploadDocumentModal";
import { useKanban } from "Hooks/useKanban";
import { useQuery } from "Hooks/useQuery";
import { assets, kanbanColumns, PROCESSES } from "constants.js";
import {
  filterCandidateStatus,
  getBooleanParamValue,
  getCandidateStatusFromStep,
  getRecruiter,
  getStepFromCandidateStatus,
  isEarlyStep,
  setLocalStorageItem,
} from "utils.js";

import "Screens/styles/KanbanBoard.css";

const KanbanBoardV2 = () => {
  const queryParams = useQuery();
  const history = useHistory();
  const { keycloak } = useKeycloak();

  const myData = getBooleanParamValue(
    queryParams.get("myData"),
    "myCandidates"
  );

  const userId = keycloak.subject;

  const {
    loading,
    error: kanbanError,
    columns,
    setColumns,
    setQueryParams,
  } = useKanban({
    search: queryParams.get("search"),
    recruiter: myData ? userId : "",
    source: queryParams.get("source"),
    trainee: queryParams.get("trainee"),
    status: filterCandidateStatus({ isExternalRecruiter: true }),
    limit: queryParams.get("limit"),
  });

  // SearchBar
  const handleSearch = (data) => {
    history.push({
      pathname: "/kanban-v2",
      search: new URLSearchParams({
        ...Object.fromEntries(queryParams),
        search: data.search,
        // myData: data.myData,
      }).toString(),
    });

    setLocalStorageItem("myCandidates", data.myData);
    setQueryParams({
      ...Object.fromEntries(queryParams),
      search: data.search,
      recruiter: getRecruiter(data.myData, keycloak),
    });
  };

  /*************************************************
   *                 Kanban section                *
   *************************************************/

  // States
  const [lastSourceColumnIndex, setLastSourceColumnIndex] = useState(-1);
  const [lastSourceCardIndex, setLastSourceCardIndex] = useState(-1);
  const [lastDestinationColumnIndex, setLastDestinationColumnIndex] =
    useState(-1);
  const [lastDestinationCardIndex, setLastDestinationCardIndex] = useState(-1);
  const [lastDestinationColumn, setLastDestinationColumn] = useState("");
  const [lastCandidate, setLastCandidate] = useState(null);
  const [isDiscarding, setIsDiscarding] = useState(false);

  // Helpers
  const moveCard = (
    sourceColumnIndex,
    sourceCardIndex,
    destinationColumnIndex,
    destinationCardIndex
  ) => {
    try {
      const newColumns = [...columns];

      // Candidate to move
      const candidate = columns[sourceColumnIndex].candidates[sourceCardIndex];

      // Delete candidate from original position
      newColumns[sourceColumnIndex].candidates.splice(sourceCardIndex, 1);

      // Move candidate to new position
      newColumns[destinationColumnIndex].candidates.splice(
        destinationCardIndex,
        0,
        candidate
      );

      // Set state values
      setColumns(newColumns);
      setLastSourceColumnIndex(sourceColumnIndex);
      setLastSourceCardIndex(sourceCardIndex);
      setLastDestinationColumnIndex(destinationColumnIndex);
      setLastDestinationCardIndex(destinationCardIndex);
      setLastDestinationColumn(newColumns[destinationColumnIndex].key);
      setLastCandidate(candidate);
    } catch (error) {
      console.error(error);
    }
  };

  const undoMoveCard = (
    sourceColumnIndex,
    sourceCardIndex,
    destinationColumnIndex,
    destinationCardIndex
  ) => {
    // Move to the original position
    moveCard(
      destinationColumnIndex ?? lastDestinationColumnIndex,
      destinationCardIndex ?? lastDestinationCardIndex,
      sourceColumnIndex ?? lastSourceColumnIndex,
      sourceCardIndex ?? lastSourceCardIndex
    );
  };

  const updateCard = (candidate, columnIndex, cardIndex) => {
    const newColumns = [...columns];
    newColumns[columnIndex ?? lastDestinationColumnIndex].candidates[
      cardIndex ?? lastDestinationCardIndex
    ] = candidate;

    // Set state values
    setColumns(newColumns);
    setLastCandidate(candidate);
  };

  const removeCard = (sourceColumnIndex, sourceCardIndex) => {
    try {
      const newColumns = [...columns];

      // Delete candidate from original position
      newColumns[sourceColumnIndex].candidates.splice(sourceCardIndex, 1);

      // Set state values
      setColumns(newColumns);
      setLastSourceColumnIndex(-1);
      setLastSourceCardIndex(-1);
      setLastDestinationColumnIndex(-1);
      setLastDestinationCardIndex(-1);
      setLastDestinationColumn("");
      setLastCandidate(null);
    } catch (error) {
      console.error(error);
    }
  };

  const saveStep = async (candidate, step, movement) => {
    try {
      // Save or update the step in the DB
      await ApplicationProcessApi.createOrUpdateStep(
        candidate?.applicationProcess?._id,
        {
          step,
        }
      );

      updateCard(
        { ...candidate, status: getCandidateStatusFromStep(step) },
        movement.destinationColumnIndex,
        movement.destinationCardIndex
      );
    } catch (error) {
      // Move to the original position
      // NOTE: We can't use state values because the method is used inside handleDragEnd and the values are set there
      moveCard(
        movement.destinationColumnIndex,
        movement.destinationCardIndex,
        movement.sourceColumnIndex,
        movement.sourceCardIndex
      );
      toast.error("Error. Unable to move the candidate, try again.");
    }
  };

  // ##### Handlers Drag End #####
  const handleDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // Column's info
    const sourceColumnIndex = columns.findIndex(
      (column) => column.key === source.droppableId
    );
    const destinationColumnIndex = columns.findIndex(
      (column) => column.key === destination.droppableId
    );

    const destinationColumn = destination.droppableId;

    // Card's (Candidate) info
    const sourceCardIndex = source.index;
    const destinationCardIndex = destination.index;
    const candidate = columns[sourceColumnIndex].candidates[sourceCardIndex];

    moveCard(
      sourceColumnIndex,
      sourceCardIndex,
      destinationColumnIndex,
      destinationCardIndex
    );

    if (destinationColumn === kanbanColumns.BLOCKED.key) {
      return;
    }

    if (destinationColumn === kanbanColumns.LEADS.key) {
      return;
    }

    if (destinationColumn === kanbanColumns.OFFER_ACCEPTED.key) {
      handleDragEndOnOfferAccepted(
        candidate,
        sourceColumnIndex,
        sourceCardIndex,
        destinationColumnIndex,
        destinationCardIndex
      );
      return;
    }

    if (destinationColumn === kanbanColumns.HIRED.key) {
      return;
    }

    if (destinationColumn === kanbanColumns.TECHNICAL_INTERVIEW.key) {
      setShowApplicationModal(true);
      if (candidate.applicationProcess) {
        setUpdateAP(true);
      }
      return;
    }

    if (
      kanbanColumns[destinationColumn].isStep &&
      !candidate.applicationProcess
    ) {
      setShowApplicationModal(true);
      return;
    }

    if (
      kanbanColumns[destinationColumn].isStep &&
      !isEarlyStep(kanbanColumns[destinationColumn].key) &&
      !candidate.applicationProcess.position
    ) {
      setUpdateAP(true);
      setShowApplicationModal(true);
      return;
    }

    // Save the step in the DB
    await saveStep(candidate, destinationColumn, {
      sourceColumnIndex,
      sourceCardIndex,
      destinationColumnIndex,
      destinationCardIndex,
    });
  };

  const handleDragEndOnOfferAccepted = (
    candidate,
    sourceColumnIndex,
    sourceCardIndex,
    destinationColumnIndex,
    destinationCardIndex
  ) => {
    if (candidate.applicationProcess) {
      setShowOfferAcceptedModal(true);
    } else {
      // NOTE: We can't use state values because the method is used inside handleDragEnd and the values are set there
      undoMoveCard(
        sourceColumnIndex,
        sourceCardIndex,
        destinationColumnIndex,
        destinationCardIndex
      );
      toast.error("Error. Candidate has no active hiring process.");
    }
  };

  // ##### OfferAccepted #####
  const [showOfferAcceptedModal, setShowOfferAcceptedModal] = useState(false);

  const handleCloseOfferAccepted = () => {
    undoMoveCard();
    setShowOfferAcceptedModal(false);
  };

  const handleSavedOfferAccepted = async (applicationProcess) => {
    if (lastDestinationColumnIndex !== lastSourceColumnIndex) {
      updateCard({ ...lastCandidate, applicationProcess });
    } else {
      removeCard(lastSourceColumnIndex, lastSourceCardIndex);
    }
    toast.success("Success! The candidate's offer has been set as accepted.");
    setShowOfferAcceptedModal(false);
  };

  const handleSavedOfferAcceptedError = (message) => {
    toast.error(message);
    undoMoveCard();
    setShowOfferAcceptedModal(false);
  };

  // ######### Move from menu #########
  const handleClickMove = ({ candidate, step, index, status }) => {
    // This object simulates the movement of a card to another column
    const result = {
      destination: {
        droppableId: getStepFromCandidateStatus(status),
        index: 0,
      },
      source: {
        droppableId: step,
        index,
      },
      draggableId: candidate._id,
    };

    handleDragEnd(result);
  };

  /*************************************************
   *        Add Candidate Modal section            *
   *************************************************/

  const [showCandidateModal, setShowCandidateModal] = useState(false);

  const handleClickAddCandidate = () => {
    setShowCandidateModal(true);
  };

  const handleCloseCandidate = () => {
    setShowCandidateModal(false);
  };

  const handleSavedCandidate = (candidate, options) => {
    // Add candidate to the proper column
    const newColumns = [...columns];
    const step = getStepFromCandidateStatus(candidate.status);
    const destinationColumnIndex = columns.findIndex(
      (column) => column.key === step
    );
    newColumns[destinationColumnIndex].candidates.unshift(candidate);

    // Set state values
    setColumns(newColumns);
    setLastCandidate(candidate);
    setShowCandidateModal(false);
    options?.addDocuments && setShowUploadDocumentModal(true);
  };

  /*************************************************
   *       Upload Document Modal section           *
   *************************************************/

  const [showUploadDocumentModal, setShowUploadDocumentModal] = useState(false);

  const handleUploadedDocuments = (documents) => {
    // Update candidate
    const updatedCandidate = {
      ...lastCandidate,
      totalDocuments: documents?.length ?? 0,
    };
    const step = getStepFromCandidateStatus(updatedCandidate?.status);
    const destinationColumnIndex = columns.findIndex(
      (column) => column.key === step
    );

    updateCard(updatedCandidate, destinationColumnIndex, 0);
  };

  /*************************************************
   *          Add Application Modal section       *
   *************************************************/
  const [updateAP, setUpdateAP] = useState(false);
  const [showApplicationModal, setShowApplicationModal] = useState(false);

  const handleCloseApplicationModal = () => {
    undoMoveCard();
    setShowApplicationModal(false);
    setUpdateAP(false);
  };

  const handleSaveApplication = async (applicationProcess) => {
    const updatedCandidate = { ...lastCandidate };
    updatedCandidate.applicationProcess = applicationProcess;
    updatedCandidate.status =
      applicationProcess.steps?.[applicationProcess?.steps?.length - 1].step;

    updateCard(updatedCandidate);
    setShowApplicationModal(false);
    setUpdateAP(false);
    toast.success("Success! The candidate has been linked to the position.");
  };

  const handleSaveApplicationError = (message) => {
    toast.error(message);
    undoMoveCard();
    setShowApplicationModal(false);
  };

  /*************************************************
   *           Send Email Modal section            *
   *************************************************/

  const [showEmailSendModal, setShowEmailSendModal] = useState(false);
  const [emailConfig, setEmailConfig] = useState({});
  const handleClickMessage = (candidate) => {
    setEmailConfig({
      candidateId: candidate._id,
      to: candidate.email,
      name: candidate.name,
      role: candidate.applicationProcess?.role,
      company: candidate.applicationProcess?.position?.company?.name,
    });
    setShowEmailSendModal(true);
  };

  /*************************************************
   *           Discard Candidate Modal section     *
   *************************************************/

  const [showDiscardModal, setShowDiscardModal] = useState(false);

  const handleClickDiscard = (step, sourceCardIndex) => {
    const sourceColumnIndex = columns.findIndex(
      (column) => column.key === step
    );

    setLastSourceColumnIndex(sourceColumnIndex);
    setLastSourceCardIndex(sourceCardIndex);

    const candidate = columns[sourceColumnIndex].candidates[sourceCardIndex];

    setLastCandidate(candidate);
    setShowDiscardModal(true);
  };

  const handleCloseDiscard = () => {
    setShowDiscardModal(false);
  };

  const handleSubmitDiscard = async (formValues) => {
    try {
      setIsDiscarding(true);

      // Update application process status
      if (lastCandidate.applicationProcess) {
        await ApplicationProcessApi.cancelApplicationProcess(
          lastCandidate.applicationProcess?._id,
          {
            discardReason: formValues.discardReason,
            emailTemplateId: formValues.emailTemplateId,
          }
        );
      }

      removeCard(lastSourceColumnIndex, lastSourceCardIndex);

      toast.success("Success! The candidate has been rejected.");
      setShowDiscardModal(false);
    } catch (error) {
      toast.error("Error! Unable to reject the candidate, try again.");
    } finally {
      setIsDiscarding(false);
    }
  };

  /*************************************************
   *    Main render (Kanban - Zero/Error state)    *
   *************************************************/
  const [emptyData, setEmptyData] = useState(false);

  useEffect(() => {
    setEmptyData(columns.every((column) => column.candidates?.length === 0));
  }, [columns]);

  const mainRender = () => {
    if (loading) return <Loading />;

    if (kanbanError) {
      return (
        <div className="w-100 px-3">
          <StateCard
            imageUrl={assets.GENERIC_ERROR}
            imageWidth={340}
            title="Oops! Looks like something went wrong on your end"
            subtitle="Please refresh and try again"
          >
            <Button
              variant="primary"
              className="AddButton mt-2"
              onClick={() => history.push("/kanban")}
            >
              Refresh Kanban
            </Button>
          </StateCard>
        </div>
      );
    }

    if (emptyData) {
      return (
        <div className="w-100 px-3">
          <StateCard
            imageUrl={assets.ZERO_STATE}
            title="Candidates not found"
          />
        </div>
      );
    }
    return (
      <Col>
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className="KanbanBoard">
            {columns.map((column) => {
              return (
                <KanbanColumn
                  key={column.key}
                  step={column.key}
                  userId={userId}
                  candidates={column.candidates}
                  isExternalRecruiter={true}
                  onClickDiscard={handleClickDiscard}
                  onClickMove={handleClickMove}
                  onClickMessage={handleClickMessage}
                />
              );
            })}
          </div>
        </DragDropContext>
      </Col>
    );
  };

  return (
    <>
      <ApplicationAddModal
        show={showApplicationModal}
        updateAP={updateAP}
        candidate={lastCandidate}
        step={lastDestinationColumn}
        onClose={handleCloseApplicationModal}
        onSave={handleSaveApplication}
        onError={handleSaveApplicationError}
      />
      <CandidateAddModal
        show={showCandidateModal}
        isExternalRecruiter={true}
        onClose={handleCloseCandidate}
        onSaved={handleSavedCandidate}
      />
      <EmailSendModal
        show={showEmailSendModal}
        emailConfig={emailConfig}
        onClose={() => setShowEmailSendModal(false)}
      />
      <DiscardModal
        show={showDiscardModal}
        process={PROCESSES.APPLICATION}
        candidateName={lastCandidate?.name}
        candidateStatus={lastCandidate?.status}
        destinationColumn={kanbanColumns.LEADS.key}
        isLoading={isDiscarding}
        onClose={handleCloseDiscard}
        onSubmit={handleSubmitDiscard}
      />
      <UploadDocumentModal
        show={showUploadDocumentModal}
        candidateId={lastCandidate?._id}
        isMultiple={true}
        onClose={() => setShowUploadDocumentModal(false)}
        onSaved={handleUploadedDocuments}
      />
      <ApplicationOfferAcceptedModal
        show={showOfferAcceptedModal}
        candidate={lastCandidate}
        onClose={handleCloseOfferAccepted}
        onSaved={handleSavedOfferAccepted}
        onError={handleSavedOfferAcceptedError}
      />
      <SearchBar
        localStorageKey="myCandidates"
        queryParams={queryParams}
        disabled={loading}
        onSearch={handleSearch}
      />
      <Row className="pt-3 pb-3">
        <Col>
          <h1 className="d-inline me-3">My candidates life-cycle</h1>
        </Col>
        <Col className="text-end">
          <Button
            variant="secondary"
            className="AddButton"
            onClick={handleClickAddCandidate}
          >
            Add candidate
          </Button>
        </Col>
      </Row>
      <Row>{mainRender()}</Row>
    </>
  );
};

export default KanbanBoardV2;

import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SkillCategoryEnum } from "@micoach/js-sdk";

import Loading from "Components/Loading";
import { getObjectKeyByValue } from "utils.js";

const SkillTable = (props) => {
  const { skills, loading, resetPagination } = props;

  const columns = [
    {
      name: <strong>Category</strong>,
      selector: (row) => getObjectKeyByValue(SkillCategoryEnum, row.category),
      sortable: true,
      width: "90px",
    },
    {
      name: <strong>Name</strong>,
      selector: (row) => row.name,
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: <strong>Roles</strong>,
      selector: (row) => getConcatenetedRoles(row.roles),
      sortable: true,
      wrap: true,
    },
    {
      name: <strong>Desc</strong>,
      selector: (row) => (row.description ? "Yes" : "No"),
      sortable: true,
      width: "70px",
    },
    {
      name: <strong>PL1</strong>,
      selector: (row) => getPLLabel(row, 0),
      sortable: true,
      width: "60px",
    },
    {
      name: <strong>PL2</strong>,
      selector: (row) => getPLLabel(row, 1),
      sortable: true,
      width: "60px",
    },
    {
      name: <strong>PL3</strong>,
      selector: (row) => getPLLabel(row, 2),
      sortable: true,
      width: "60px",
    },
    {
      name: <strong>PL4</strong>,
      selector: (row) => getPLLabel(row, 3),
      sortable: true,
      width: "60px",
    },
    {
      name: <strong>Actions</strong>,
      cell: (row) => (
        <Button
          variant="link"
          onClick={() => {
            props.onEdit?.(row);
          }}
        >
          <FontAwesomeIcon icon={faPen}></FontAwesomeIcon>
        </Button>
      ),
      width: "80px",
      center: true,
    },
  ];

  const getPLLabel = (row, index) => {
    return row.proficiencyLevels?.[index]?.description ? "Yes" : "No";
  };

  const getConcatenetedRoles = (roles) => {
    return roles.map((role) => role.name).join(", ");
  };

  return (
    <DataTable
      columns={columns}
      data={skills}
      progressPending={loading}
      progressComponent={<Loading />}
      paginationResetDefaultPage={resetPagination}
      persistTableHead
      highlightOnHover
      pagination
    />
  );
};

SkillTable.propTypes = {
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      category: PropTypes.string,
      description: PropTypes.string,
      roles: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        })
      ),
      proficiencyLevels: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  loading: PropTypes.bool,
  resetPagination: PropTypes.bool,
  onEdit: PropTypes.func,
};

SkillTable.defaultValues = {
  loading: false,
  resetPagination: false,
};

export default SkillTable;

import { useCallback, useEffect, useState } from "react";

import BackendService from "../Services/BackendService";

export function useCompanies() {
  const [companies, setCompanies] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);

  const loadCompanies = useCallback(async () => {
    setLoadingCompanies(true);
    try {
      const data = await BackendService.fetchCompanies();
      setCompanies(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCompanies(false);
    }
  }, []);

  useEffect(() => {
    loadCompanies();
  }, [loadCompanies]);

  return [companies, loadCompanies, loadingCompanies];
}

export function useCompany(id) {
  const [company, setCompany] = useState(null);

  const loadCompany = useCallback(async () => {
    const data = await BackendService.getCompany(id);
    setCompany(data);
  }, [id]);

  useEffect(() => {
    loadCompany();
  }, [loadCompany]);

  return [company, loadCompany];
}

import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { EvaluationApi } from "micoach-api";

import ConfirmModal from "Components/ConfirmModal";

const ResetEvaluationButton = (props) => {
  const { evaluationId } = props;

  const [saving, setSaving] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const history = useHistory();

  const handleClickConfirm = async (id) => {
    setSaving(true);
    try {
      await EvaluationApi.resetEvaluation(id);
      toast.success("Success! The assessment has been reseted.");
      history.push(`/evaluation/skill?id=${id}`);
    } catch (error) {
      console.error(error);
      toast.error("Error, please try again.");
    } finally {
      setSaving(false);
      setShowConfirmModal(false);
    }
  };

  if (!evaluationId) return null;

  return (
    <>
      <Button
        variant="secondary"
        onClick={() => setShowConfirmModal(true)}
        disabled={saving}
        className="w-100"
      >
        <strong>Reset self assessment</strong>
      </Button>

      <ConfirmModal
        show={showConfirmModal}
        title="Reset self assessment"
        confirmButtonText="Yes, reset"
        onClose={() => setShowConfirmModal(false)}
        onConfirm={() => handleClickConfirm(evaluationId)}
        isLoading={saving}
      >
        <div>
          Are you sure you want to reset your self assessment? All your answers
          will be deleted.
          <div className="mt-3"> This action cannot be undone.</div>
        </div>
      </ConfirmModal>
    </>
  );
};

ResetEvaluationButton.propTypes = {
  evaluationId: PropTypes.string.isRequired,
};

export default ResetEvaluationButton;

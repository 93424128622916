import { useCallback, useEffect, useState } from "react";
import { EvaluationPeriodApi } from "micoach-api";

export const useProgressEvaluationPeriod = (period, coachEmail, employer) => {
  const [users, setUsers] = useState([]);
  const [loadingEvaluationPeriod, setLoadingProgressEvaluationPeriod] =
    useState(false);

  const loadEvaluationPeriod = useCallback(async () => {
    setLoadingProgressEvaluationPeriod(true);
    const year = period?.year;
    const quarter = period?.quarter;

    try {
      if (year && quarter) {
        const { data } = await EvaluationPeriodApi.getProgressEvaluationPeriod(
          quarter,
          year,
          coachEmail,
          employer
        );

        setUsers(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProgressEvaluationPeriod(false);
    }
  }, [period, coachEmail, employer]);

  useEffect(() => {
    loadEvaluationPeriod();
  }, [loadEvaluationPeriod]);

  return { users, loadingEvaluationPeriod };
};

export const usePerformanceEvaluationPeriod = (
  period,
  format,
  coachEmail,
  employer
) => {
  const [roles, setRoles] = useState([]);
  const [
    loadingPerformanceEvaluationPeriod,
    setLoadingPerformanceEvaluationPeriod,
  ] = useState(false);

  const loadEvaluationPeriod = useCallback(async () => {
    setLoadingPerformanceEvaluationPeriod(true);
    const year = period?.year;
    const quarter = period?.quarter;

    try {
      if (year && quarter) {
        const { data } =
          await EvaluationPeriodApi.getPerformanceEvaluationPeriod(
            quarter,
            year,
            format,
            coachEmail,
            employer
          );

        setRoles(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPerformanceEvaluationPeriod(false);
    }
  }, [period, format, coachEmail, employer]);

  useEffect(() => {
    loadEvaluationPeriod();
  }, [loadEvaluationPeriod]);

  return { roles, loadingPerformanceEvaluationPeriod };
};

export const useCompleteEvaluationPeriod = (id) => {
  const [isSaving, setIsSaving] = useState(false);

  const completeEvaluationPeriod = useCallback(async () => {
    setIsSaving(true);
    try {
      await EvaluationPeriodApi.completeEvaluationPeriod(id);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  }, [id]);

  useEffect(() => {
    completeEvaluationPeriod();
  }, [completeEvaluationPeriod]);

  return { isSaving };
};

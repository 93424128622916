import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import StateCard from "Components/Common/StateCard";
import TrainingCard from "Components/Training/TrainingCard";
import { assets } from "constants.js";

const TrainingCardList = (props) => {
  const { events, columns } = props;

  if (!events.length) {
    return (
      <StateCard imageUrl={assets.ZERO_STATE} title="No trainings found!" />
    );
  }

  const columnWidth = 12 / columns;

  return (
    <Row>
      {events?.map((event) => (
        <Col key={event._id} md={columnWidth}>
          <TrainingCard event={event} />
        </Col>
      ))}
    </Row>
  );
};

TrainingCardList.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.number,
};

TrainingCardList.defaultProps = {
  events: [],
  columns: 1,
};

export default TrainingCardList;

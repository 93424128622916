import { useState } from "react";
import { useLocation } from "react-router-dom";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "Components/Home/styles/ResumeBuilderBanner.module.css";

const ResumeBuilderBanner = () => {
  const location = useLocation();
  const [hideBanner, setHideBanner] = useState(false);

  if (hideBanner || location.pathname !== "/") {
    return null;
  }

  return (
    <div className={styles.ResumeBanner}>
      <span className={styles.Title} data-testid="title">
        <strong>Create</strong> your <strong>RESUME</strong> with our new
        feature
        <strong> in 10 min!!</strong>
      </span>

      <a href="/resume-builder" className="btn btn-secondary">
        <span className={styles.TryButton}>Try Free Resume Builder</span>
        <span className={styles.MobileTryButton}>Try for Free</span>
      </a>
      <FontAwesomeIcon
        icon={faXmark}
        className={styles.CloseBanner}
        onClick={() => {
          setHideBanner(true);
        }}
      />
    </div>
  );
};

export default ResumeBuilderBanner;

import { useCallback, useState } from "react";
import { PositionTemplateApi } from "micoach-api";

export const useJobTemplates = () => {
  const [jobTemplates, setJobTemplates] = useState([]);
  const [loadingJobTemplates, setLoadingJobTemplates] = useState(false);

  const loadJobTemplates = useCallback(async () => {
    setLoadingJobTemplates(true);
    try {
      const { data } = await PositionTemplateApi.listPositionTemplates();

      setJobTemplates(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingJobTemplates(false);
    }
  }, []);

  return {
    jobTemplates,
    loadingJobTemplates,
    loadJobTemplates,
    setJobTemplates,
  };
};

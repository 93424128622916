import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import micoach, { EvaluationPeriodApi } from "micoach-api";

import { getStartAndEndDateFromYearAndQuarter } from "utils.js";

import styles from "Components/Evaluation/Skill/styles/CoachOverviewCard.module.css";

const CoachOverviewEditCard = (props) => {
  const { evaluationPeriod, balanceDate, user } = props;

  const [isSaving, setIsSaving] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (formValues) => {
    setIsSaving(true);

    if (evaluationPeriod) {
      // Edition
      try {
        const { data } = await EvaluationPeriodApi.updateEvaluationPeriod(
          evaluationPeriod._id,
          formValues
        );
        props.onSaved?.(data);
      } catch (error) {
        console.error(error);
      }
    } else {
      // Creation
      const [startDate, endDate] = getStartAndEndDateFromYearAndQuarter(
        balanceDate?.year,
        balanceDate?.quarter
      );

      const newEvaluationPeriod = {
        ...formValues,
        startDate,
        endDate,
        type: micoach.EvaluationTypeEnum.Skills,
        userId: user?.userId,
        userName: user?.userName,
        userRole: user?.userRole,
      };

      try {
        const { data } =
          await EvaluationPeriodApi.createEvaluationPeriod(newEvaluationPeriod);
        props.onSaved?.(data);
      } catch (error) {
        console.error(error);
      }
    }

    setIsSaving(false);
  };

  useEffect(() => {
    if (evaluationPeriod) {
      reset({
        goals: evaluationPeriod?.goals,
        summary: evaluationPeriod?.summary,
      });
    }
  }, [evaluationPeriod, reset]);

  return (
    <Card className={`Card ${styles.CoachOverviewCard}`}>
      <Card.Body className={styles.Body}>
        <>
          <div className={styles.Instructions}>
            Set the goal for the next period for your coachee, based on the
            skill assessments results and add your overall evaluation. Once you
            save it, it will be shared by email with your coachee.
          </div>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Form.Group controlId="goals" className="form-group required">
              <Form.Label>Goals</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                maxLength={4000}
                name="goals"
                placeholder="Describe what the goal will be."
                {...register("goals", { required: true, maxLength: 4000 })}
                isInvalid={!!errors.goals}
              />
              {errors.goals && (
                <div className="invalid-feedback d-block">
                  {errors.goals.type === "required" &&
                    "Coach evaluation is required"}
                  {errors.goals.type === "maxLength" && "Max length exceeded"}
                </div>
              )}
            </Form.Group>

            <Form.Group controlId="summary" className="form-group required">
              <Form.Label>Coach evaluation</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                rows={8}
                name="summary"
                maxLength={4000}
                placeholder="Add your comments here."
                {...register("summary", { required: true, maxLength: 4000 })}
                isInvalid={!!errors.summary}
              />
              {errors.summary && (
                <div className="invalid-feedback d-block">
                  {errors.summary.type === "required" &&
                    "Coach evaluation is required"}
                  {errors.summary.type === "maxLength" && "Max length exceeded"}
                </div>
              )}
            </Form.Group>

            <div className={styles.Actions}>
              {evaluationPeriod && (
                <Button
                  variant="secondary"
                  disabled={isSaving}
                  className="w-100 mb-2"
                  onClick={() => props.onCancel?.(false)}
                >
                  Cancel
                </Button>
              )}

              <Button
                type="submit"
                variant="primary"
                disabled={isSaving}
                className="w-100"
              >
                Save and share
              </Button>
            </div>
          </Form>
        </>
      </Card.Body>
    </Card>
  );
};

CoachOverviewEditCard.propTypes = {
  evaluationPeriod: PropTypes.shape({
    _id: PropTypes.string,
    goals: PropTypes.string,
    summary: PropTypes.string,
  }),
  balanceDate: PropTypes.shape({
    year: PropTypes.string.isRequired,
    quarter: PropTypes.string.isRequired,
  }),
  user: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    userRole: PropTypes.string,
  }),
  onSaved: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CoachOverviewEditCard;

import PropTypes from "prop-types";

import { DOCUMENT_REQUEST_ORIGINS } from "constants.js";
import { getFormattedLocalDate } from "utils.js";

const UploadDocumentsMessage = (props) => {
  const { uploadDocumentRequest } = props;

  const helpMessage = "we need your help uploading the following document(s)";
  const formattedExpirationDate = getFormattedLocalDate(
    uploadDocumentRequest.expirationDate
  );

  const expirationMessage = (
    <>
      You can come back as many times as you need to complete and upload all the
      requested documentation until <strong>{formattedExpirationDate}</strong>.
    </>
  );

  const getContactMessage = (
    name = uploadDocumentRequest.recruiterName,
    email = uploadDocumentRequest.recruiterEmail
  ) => {
    return (
      <>
        If you have any doubt, please feel free to reach {name} -{" "}
        <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
          {email}
        </a>
        .
      </>
    );
  };

  const renderMessage = () => {
    let mainMessage;
    let contactMessage = getContactMessage();

    switch (uploadDocumentRequest.origin) {
      case DOCUMENT_REQUEST_ORIGINS.OFFER_PROCESS:
        mainMessage = (
          <>
            To continue with the final stages of the current ITJ hiring process
            - {uploadDocumentRequest.metadata?.positionName}, {helpMessage}.
          </>
        );
        break;
      case DOCUMENT_REQUEST_ORIGINS.OFFER_ACCEPTED_PROCESS:
        mainMessage = (
          <>
            To prepare your arrival as a new ITJ Team member for the{" "}
            {uploadDocumentRequest.metadata?.companyName} COE, please provide
            the necessary document(s) listed below. Ensuring these are submitted
            in advance will help streamline the onboarding process.
          </>
        );
        contactMessage = getContactMessage(
          "the ITJ HR Team",
          process.env.REACT_APP_HR_EMAIL
        );
        break;
      default:
        mainMessage = (
          <>To continue the current process with ITJ, {helpMessage}.</>
        );
        break;
    }

    return (
      <>
        <p>{mainMessage}</p>
        <p>{expirationMessage}</p>
        <p>{contactMessage}</p>
      </>
    );
  };

  return <>{renderMessage()}</>;
};

UploadDocumentsMessage.propTypes = {
  uploadDocumentRequest: PropTypes.shape({
    expirationDate: PropTypes.string.isRequired,
    recruiterName: PropTypes.string,
    recruiterEmail: PropTypes.string,
    origin: PropTypes.oneOf([
      DOCUMENT_REQUEST_ORIGINS.MANUAL_PROCESS,
      DOCUMENT_REQUEST_ORIGINS.OFFER_PROCESS,
      DOCUMENT_REQUEST_ORIGINS.OFFER_ACCEPTED_PROCESS,
    ]).isRequired,
    metadata: PropTypes.shape({
      positionName: PropTypes.string,
      companyName: PropTypes.string,
    }),
  }).isRequired,
};

export default UploadDocumentsMessage;

import PropTypes from "prop-types";

import styles from "Components/Candidate/EarnedBadge/styles/EarnedBadgeItem.module.css";

const EarnedBagdeItem = (props) => {
  const { isBlank, badge } = props;

  return (
    <div className={styles.Item}>
      {isBlank ? (
        <div className={styles.Blank}></div>
      ) : !badge ? null : (
        <img
          src={badge.badgeImageUrl}
          alt={badge.name}
          title={badge.name}
          onClick={() => props.onClick?.(badge)}
        />
      )}
    </div>
  );
};

EarnedBagdeItem.propTypes = {
  badge: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    badgeImageUrl: PropTypes.string.isRequired,
    name: PropTypes.string,
  }),
  isBlank: PropTypes.bool,
  onClick: PropTypes.func,
};

export default EarnedBagdeItem;

import { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useKeycloak } from "@react-keycloak/web";
import useSWRMutation from "swr/mutation";

import TagSelectInput from "Components/Common/TagSelectInput";
import { API } from "constants.js";
import { sendRequest } from "utils.js";

const formInitialState = {
  tagSelect: "",
};

const TagModal = (props) => {
  const {
    show = false,
    defaultTags = [],
    candidateId,
    onSaved,
    onClose,
  } = props;

  const {
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
  });
  const formProps = { control, setValue, errors };

  const { keycloak } = useKeycloak();

  const { trigger: updateCandidate, isMutating: loadingUpdateCandidate } =
    useSWRMutation(`${API.CANDIDATE}/${candidateId}`, (path, { arg }) =>
      sendRequest(path, {
        ...arg,
        method: "PUT",
        accessToken: keycloak.token,
      })
    );

  const handleSubmitForm = async (formValues) => {
    const { tagSelect } = formValues;

    const tags = tagSelect?.map((tag) => tag._id);

    await updateCandidate({
      body: { tags },
    });

    onSaved?.(tags);
    onClose?.();
  };

  useEffect(() => {
    if (show) {
      reset(formInitialState);
    }
  }, [reset, show]);

  return (
    <Modal show={show} onHide={onClose} backdrop="static">
      <Form onSubmit={handleSubmit(handleSubmitForm)}>
        <Modal.Header closeButton>
          <Modal.Title>Tags</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Find easily a prospect on Kanban adding keywords. You will see the
            tags on your future candidate&apos;s profile.
          </p>

          <TagSelectInput
            name="tagSelect"
            defaultTags={defaultTags}
            formProps={formProps}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="CancelButton"
            disabled={loadingUpdateCandidate}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button type="submit" disabled={loadingUpdateCandidate}>
            Save changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

TagModal.propTypes = {
  show: PropTypes.bool,
  candidateId: PropTypes.string.isRequired,
  defaultTags: PropTypes.array,
  onClose: PropTypes.func,
  onSaved: PropTypes.func,
};

export default TagModal;

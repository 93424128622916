import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import {
  faEllipsisV,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { reorderArray } from "utils.js";

import "Components/Common/styles/SelectList.css";

function SelectList(props) {
  const {
    selectTitle,
    selectOptions,
    defaultValue,
    optionLabel,
    optionValue,
    limit,
  } = props;

  const [selects, setSelects] = useState([]);

  // Helpers

  const getSelectedValues = (selectsArray) => {
    const selectsWithValue = selectsArray.filter((select) => select.value);
    const values = selectsWithValue.map((select) => select.value);
    return values;
  };

  /**
   * Returns a list of options that have not been selected.
   * If the selectedValue is specified, that option is kept.
   */
  const getSelectOptions = useCallback(
    (selectedValue, selectedValues) => {
      const usedOptions = selectedValues.map((value) => value[optionValue]);
      const newOptions = selectOptions.filter((option) => {
        return (
          (selectedValue &&
            option[optionValue] === selectedValue[optionValue]) ||
          !usedOptions.includes(option[optionValue])
        );
      });
      return newOptions;
    },
    [optionValue, selectOptions]
  );

  const updateSelects = (selectsArray) => {
    const newSelectedValues = getSelectedValues(selectsArray);
    const updatedSelects = selectsArray.map((select) => {
      return {
        ...select,
        options: getSelectOptions(select.value, newSelectedValues),
      };
    });

    setSelects(updatedSelects);

    if (props.onChange) {
      props.onChange(newSelectedValues);
    }
  };

  useEffect(() => {
    if (defaultValue && defaultValue.length) {
      const newSelects = defaultValue.map((selectedValue) => {
        return {
          options: getSelectOptions(selectedValue, defaultValue),
          value: selectedValue,
        };
      });
      setSelects(newSelects);
    } else {
      setSelects([{ options: selectOptions, value: null }]);
    }
  }, [defaultValue, selectOptions, getSelectOptions]);

  // Event handlers

  const handleAddSelect = () => {
    setSelects((prevSelects) => [
      ...prevSelects,
      {
        options: getSelectOptions(null, getSelectedValues(prevSelects)),
        value: null,
      },
    ]);
  };

  const handleDeleteSelect = (index) => {
    const newSelects = [...selects];
    newSelects.splice(index, 1);
    updateSelects(newSelects);
  };

  const handleChangeSelect = (index, option) => {
    const newSelects = [...selects];
    newSelects[index].value = option;
    updateSelects(newSelects);
  };

  const handleDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) {
      return;
    }

    const newSelects = reorderArray(selects, source.index, destination.index);
    updateSelects(newSelects);
  };

  return (
    <div className="SelectList">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {selects.map((select, index) => (
                <Draggable
                  key={index + new Date().getTime()}
                  index={index}
                  draggableId={"item-" + index}
                >
                  {(provided) => (
                    <div
                      className="Item"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <div className="Drag" {...provided.dragHandleProps}>
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </div>

                      <div className="Select">
                        {selectTitle && <label>{selectTitle}</label>}
                        <Select
                          options={select.options}
                          defaultValue={select.value}
                          isSearchable={true}
                          classNamePrefix="ReactSelect"
                          getOptionLabel={(option) => option[optionLabel]}
                          getOptionValue={(option) => option[optionValue]}
                          onChange={(option) =>
                            handleChangeSelect(index, option)
                          }
                        />
                      </div>

                      <Button
                        variant="link"
                        className="Delete"
                        onClick={() => handleDeleteSelect(index)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} className="Icon" />
                      </Button>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {selects.length < limit && (
        <Button variant="link" className="mt-2 fs-13" onClick={handleAddSelect}>
          <FontAwesomeIcon icon={faPlus} className="Icon" /> Add {selectTitle}
        </Button>
      )}
    </div>
  );
}

SelectList.defaultProps = {
  limit: 5,
};

SelectList.propTypes = {
  selectTitle: PropTypes.string,
  selectOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.object),
  optionLabel: PropTypes.string.isRequired,
  optionValue: PropTypes.string.isRequired,
  limit: PropTypes.number,
  onChange: PropTypes.func,
};

export default SelectList;
